import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useMemo } from 'react';

import KycApplicantFrom from './KycApplicantFrom';

// import { useUpdateUserMutation } from '../../api/auth';
import { useUpdateUnitCartMutation } from '../../../../api/unitCart';
import { useAddOrUpdateApplicantMutation } from 'apps/booking/services/applicantsAPISlice';
import { getUnitCart, setUnitCart } from 'apps/booking/slices/selectedUnit';
import { APPLICANT_TYPE } from 'apps/booking/constants';
import { isEmpty } from 'utils/utils';
import useToast from 'hooks/useToast';
import { ERROR } from 'constants/status';

const PrimaryApplicant = (props) => {
  const { updateIsEditFormOpenStatus } = props;

  const unitCart = useSelector(getUnitCart);
  const user = unitCart.user;
  const selectedUnit = unitCart.unit;
  const applicantsGlobal = unitCart.applicants || [];

  const dispatch = useDispatch();

  const [addOrUpdateApplicantMutation] = useAddOrUpdateApplicantMutation();
  const [updateUnitCartAPI] = useUpdateUnitCartMutation();

  const [addToast] = useToast();

  const primaryApplicant = useMemo(() => {
    return applicantsGlobal.find(
      (applicant) => applicant.type == APPLICANT_TYPE.PRIMARY
    );
  }, [applicantsGlobal]);

  const mandatoryDetailsMissing =
    !user.email ||
    !user.mobile ||
    !primaryApplicant?.firstName ||
    !primaryApplicant?.lastName ||
    !primaryApplicant?.dob ||
    !primaryApplicant?.fatherName ||
    !primaryApplicant?.panNumber ||
    !primaryApplicant?.aadharNumber ||
    !primaryApplicant?.nationality ||
    !primaryApplicant?.indianResident;

  const updateApplicantOnServer = async (applicants) => {
    const unitCartBody = {
      id: unitCart.id,
      applicants,
      unit: unitCart.unit.id,
      user: unitCart.user.id,
    };
    const { data: unitCartdata, error: unitCartError } =
      await updateUnitCartAPI({ ...unitCartBody }).unwrap();

    if (!isEmpty(unitCartError)) {
      console.error('unitCartError', unitCartError);
      return false;
    }

    if (!isEmpty(unitCartdata)) {
      dispatch(setUnitCart(unitCartdata));
      // TODO: Update unit status or fetch the latest unit details
    }
  };

  const handleSave = async (data) => {
    // TODO
    try {
      const { data: newPrimaryApplicant } = await addOrUpdateApplicantMutation({
        applicant: {
          ...data,
          unit: selectedUnit.id,
          lead: user.id,
        },
      }).unwrap();

      let updatedApplicants = [];

      if (primaryApplicant) {
        updatedApplicants = applicantsGlobal.map((applicant) =>
          applicant.type == APPLICANT_TYPE.PRIMARY
            ? newPrimaryApplicant
            : applicant
        );
      } else {
        updatedApplicants = [...applicantsGlobal, newPrimaryApplicant];
      }
      await updateApplicantOnServer(updatedApplicants);
    } catch (error) {
      const errMsg = error.data?.message;
      addToast({
        type: ERROR,
        primaryMessage: errMsg,
        secondaryMessage: 'Applicant add failed!',
        timeout: 1000,
      });
    }
  };

  const handleSaveWrap = async (data) => {
    await handleSave(data);
  };

  return (
    <KycApplicantFrom
      key={user.id}
      applicant={
        isEmpty(primaryApplicant)
          ? { email: user.email, mobile: user.mobile }
          : primaryApplicant
      }
      handleSave={(data) => handleSaveWrap(data)}
      isPrimary={true}
      isEditFormOpenByDefault={mandatoryDetailsMissing}
      updateIsEditFormOpenStatus={updateIsEditFormOpenStatus}
    />
  );
};

export default PrimaryApplicant;
