import Icon from 'apps/booking/components/common/Icon';

import styles from './styles.module.css';

interface IRegistrationRenderer {
  value: number;
  growth: number;
  disabled: Boolean;
}

export const DUMMY_GROWTH = 10000;

const RegistrationRenderer = (props: IRegistrationRenderer) => {
  const { disabled, growth, value } = props;
  return (
    <div className={styles.registrationRenderer}>
      <h2
        className={`${styles.growth} ${
          disabled ? styles.projectDisabled : ''
        }`}>
        {value}
      </h2>

      {growth !== DUMMY_GROWTH &&
        (growth >= 0 ? (
          <div
            className={`${styles.positiveGrowth} ${styles.growthIndicatoreContainer}`}>
            <Icon name='arrow_upward' propStyles={styles.arrowIcon} />
            <p>{`${growth}%`}</p>
          </div>
        ) : (
          <div
            className={`${styles.negativeGrowth} ${styles.growthIndicatoreContainer}`}>
            <Icon name='arrow_downward' propStyles={styles.arrowIcon} />
            <p>{`${String(growth).substring(1)}%`}</p>
          </div>
        ))}
    </div>
  );
};

export default RegistrationRenderer;
