import { isAdminUserRole } from 'apps/admin/components/pages/AdminLogin/utils';
import { UserRole } from 'constants/userRoles';
import SuperAdminUserRoutes from 'apps/admin/routers/AdminRoutes/SuperAdminUserRoutes';
import AdminUserRoutes from 'apps/admin/routers/AdminRoutes/AdminUserRoutes';
import ExecutiveUserRoutes from 'apps/admin/routers/AdminRoutes/ExecutiveUserRoutes';
import ExecutiveHeadsUserRoutes from './ExecutiveHeadsUserRoutes';
import GreUserRoutes from './GreUersRoutes';

const AdminRoutes = () => {
  return (
    <>
      {isAdminUserRole(UserRole.SUPERADMIN) && <SuperAdminUserRoutes />}
      {isAdminUserRole(UserRole.ADMIN) && <AdminUserRoutes />}
      {/* {isAdminUserRole(UserRole.BUSINESS_HEAD) && <ExecutiveHeadsUserRoutes />} */}
      {/* {isAdminUserRole(UserRole.CLUSTER_HEAD) && <ExecutiveHeadsUserRoutes />} */}
      {isAdminUserRole(UserRole.SITE_HEAD) && <ExecutiveHeadsUserRoutes />}
      {/* {isAdminUserRole(UserRole.TEAM_LEAD) && <ExecutiveHeadsUserRoutes />} */}

      {isAdminUserRole(UserRole.EXECUTIVE) && <ExecutiveUserRoutes />}
      {isAdminUserRole(UserRole.GRE) && <GreUserRoutes />}
    </>
  );
};

export default AdminRoutes;
