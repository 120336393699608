import { useState } from 'react';

import { useForm } from 'react-hook-form';
import { skipToken } from '@reduxjs/toolkit/dist/query';

import { randomId } from 'utils/utils';
import { useAddProjectExecutiveMutation } from 'apps/admin/services/projectsAPISlice';
import { useGetUserByEmailMutation } from 'apps/admin/services/adminUserAPISlice';
import useToast from 'hooks/useToast';
import Modal from 'apps/booking/components/common/modal/Modal';
import TextInput from 'apps/booking/components/common/inputs/TextInput';
import Button from 'apps/booking/components/common/buttons/Button';
import Icon from 'apps/booking/components/common/Icon';

import { UserRole } from 'constants/userRoles';
import { NOT_FOUND } from 'constants/status';
import { IDemoRoom } from 'apps/admin/components/pages/SuperAdminDashboard/SuperAdminProjectList/modals/AddDemoRoomModal';
import { useAddCompanyUserMutation } from 'apps/admin/services/companyAPISlice';
import DropDownInput, {
  DropDownOptionType,
} from 'apps/booking/components/common/inputs/DropDownInput';
import { IProject } from 'apps/admin/components/pages/SuperAdminDashboard/SuperAdminProjectList';
import { ICompany, IUser } from 'apps/admin/interfaces';
import {
  useAddBusinessHeadMutation,
  useAddClusterHeadMutation,
  useAddGreToProjectMutation,
  useAddSiteHeadMutation,
  useAddTeamLeadMutation,
  useGetUsersByRoleByProjectIdQuery,
} from 'apps/admin/services/userRolesAPISlice';
import { CLUB_RHODIUM_COMPANY_ID } from 'apps/cp/constants/ids';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import styles from '../VsgLinkModal/styles.module.css';
import localStyles from './styles.module.css';

export interface IExecutivesWithDemoRoom {
  id?: string;
  executive: IUser;
  demoRoom: string | IDemoRoom;
  vrUrl: string;
}

interface IAddProjectExecutiveModal {
  project: IProject;

  onModalClose: Function;
  onAddExecutive: (updateProject: IProject) => void;
}

const userSchema = yup.object().shape({
  userRole: yup.string().required(),
  email: yup.string().required(),
});

export const jobTitleOptions: DropDownOptionType[] = [
  { value: 'Sourcing Manager', displayText: 'Sourcing Manager' },
  { value: 'GRE', displayText: 'GRE' },
  { value: 'Closing Manager', displayText: 'Closing Manager' },
  { value: 'TL Sourcing', displayText: 'TL Sourcing' },
  { value: 'TL Closing', displayText: 'TL Closing' },
  { value: 'MIS', displayText: 'MIS' },
  { value: 'Site Head', displayText: 'Site Head' },
  { value: 'Business Head', displayText: 'Business Head' },
  { value: 'Cluster Head', displayText: 'Cluster Head' },
];

const AddProjectExecutiveModal = (props: IAddProjectExecutiveModal) => {
  const { project, onModalClose, onAddExecutive } = props;

  const {
    register,
    unregister,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    // TODO: Add mobile number validation conditionally as mobile number input field is shown conditionally
    resolver: yupResolver(userSchema),
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });

  const [isCreatingNewExec, setIsCreatingNewExec] = useState(false);
  const [userRole, setUserRole] = useState('');
  const [addToast] = useToast();

  const [getUserByEmailAPI] = useGetUserByEmailMutation();
  const [addProjectExecutiveAPI] = useAddProjectExecutiveMutation();
  const [addCompanyUserAPI] = useAddCompanyUserMutation();
  const [addBusinessHeadAPI] = useAddBusinessHeadMutation();
  const [addClusterHeadAPI] = useAddClusterHeadMutation();
  const [addSiteHeadAPI] = useAddSiteHeadMutation();
  const [addTeamLeadAPI] = useAddTeamLeadMutation();
  const [addGreTpProjectAPI] = useAddGreToProjectMutation();

  const getParentUserRole = () => {
    let parentType = '';
    switch (userRole) {
      case UserRole.CLUSTER_HEAD:
        parentType = 'BUSINESSHEAD';
        break;
      case UserRole.SITE_HEAD:
        parentType = 'CLUSTERHEAD';
        break;
      case UserRole.TEAM_LEAD:
        parentType = 'SITEHEAD';
        break;
      case UserRole.EXECUTIVE:
        parentType = 'TEAMLEAD';
        break;
    }
    return parentType;
  };

  const { data: usersList, isLoading } = useGetUsersByRoleByProjectIdQuery(
    userRole
      ? { userRole: getParentUserRole(), projectId: project.id }
      : skipToken
  );

  const primaryCompanyId = project.company.id as string;
  const projectName = project.name;
  const projectId = project.id as string;

  const companies: ICompany[] = [
    project.company,
    ...project.secondaryCompanies.map((c) => c.company as ICompany),
  ];
  const companyDropdownOptions: DropDownOptionType[] =
    companies.map((company) => {
      return {
        value: company.id as string,
        displayText: `${company.displayName} - ${
          company.id === primaryCompanyId ? 'primary' : 'secondary'
        }`,
      };
    }) || [];

  const usersListDropdownOptions: DropDownOptionType[] =
    usersList?.map((user) => {
      return {
        value: user.id as string,
        displayText: user.name,
      };
    }) || [];

  const defaultSelectedCompany = {
    value: primaryCompanyId,
    displayText: `${
      companies.find((c) => c.id === primaryCompanyId)?.displayName as string
    } - primary`,
  };

  const usersRoleOptions: DropDownOptionType[] = [
    // {
    //   value: UserRole.BUSINESS_HEAD,
    //   displayText: 'Business Head',
    // },
    // {
    //   value: UserRole.CLUSTER_HEAD,
    //   displayText: 'Cluster Head',
    // },
    {
      value: UserRole.SITE_HEAD,
      displayText: 'Site Head',
    },
    // { value: UserRole.TEAM_LEAD, displayText: 'Team Lead' },
    {
      value: UserRole.EXECUTIVE,
      displayText: 'Executive',
    },
    {
      value: UserRole.GRE,
      displayText: 'GRE',
    },
  ];

  const handleAddBusinessHead = async (businessHeadId: string) => {
    try {
      const updatedProject = await addBusinessHeadAPI({
        projectId,
        userId: businessHeadId,
        role: userRole,
      }).unwrap();

      onAddExecutive(updatedProject.data);
      onModalClose();

      addToast({
        type: 'SUCCESS',
        primaryMessage: 'Business-head is created Successfully',
      });
    } catch (err) {
      const errMsg = (err as any).data.message;
      addToast({
        type: 'ERROR',
        primaryMessage: errMsg,
        secondaryMessage: 'Failed to add user to Project',
      });
    }
  };

  const handleAddClusterHead = async (
    clusterHeadId: string,
    businessHeadId?: string
  ) => {
    try {
      const updatedProject = await addClusterHeadAPI({
        projectId,
        businessHead: businessHeadId,
        clusterHead: clusterHeadId,
        role: userRole,
      }).unwrap();

      onAddExecutive(updatedProject.data);
      onModalClose();

      addToast({
        type: 'SUCCESS',
        primaryMessage: 'Cluster-head created Successfully',
      });
    } catch (err) {
      const errMsg = (err as any).data.message;
      addToast({
        type: 'ERROR',
        primaryMessage: errMsg,
        secondaryMessage: 'Failed to add user to Project',
      });
    }
  };

  const handleAddSiteHead = async (
    siteHeadId: string,
    clusterHeadId?: string
  ) => {
    try {
      const updatedProject = await addSiteHeadAPI({
        projectId,
        clusterHead: clusterHeadId,
        siteHead: siteHeadId,
        role: userRole,
      }).unwrap();

      onAddExecutive(updatedProject.data);
      onModalClose();

      addToast({
        type: 'SUCCESS',
        primaryMessage: 'Site-head created Successfully',
      });
    } catch (err) {
      const errMsg = (err as any).data.message;
      addToast({
        type: 'ERROR',
        primaryMessage: errMsg,
        secondaryMessage: 'Failed to add user to Project',
      });
    }
  };

  const handleAddTeamLead = async (teamLeadId: string, siteHeadId?: string) => {
    try {
      const updatedProject = await addTeamLeadAPI({
        projectId,
        siteHead: siteHeadId,
        teamLead: teamLeadId,
        role: userRole,
      }).unwrap();

      onAddExecutive(updatedProject.data);
      onModalClose();

      addToast({
        type: 'SUCCESS',
        primaryMessage: 'Teamlead created Successfully',
      });
    } catch (err) {
      const errMsg = (err as any).data.message;
      addToast({
        type: 'ERROR',
        primaryMessage: errMsg,
        secondaryMessage: 'Failed to add user to Project',
      });
    }
  };

  const handleAddGREToProject = async (greId: string) => {
    try {
      const updatedProject = await addGreTpProjectAPI({
        projectId,
        userId: greId,
        role: userRole,
      }).unwrap();

      onAddExecutive(updatedProject.data);
      onModalClose();

      addToast({
        type: 'SUCCESS',
        primaryMessage: 'GRE is created Successfully',
      });
    } catch (err) {
      const errMsg = (err as any).data.message;
      addToast({
        type: 'ERROR',
        primaryMessage: errMsg,
        secondaryMessage: 'Failed to add user to Project',
      });
    }
  };

  const handleAddUsers = (
    userId: string,
    formData: {
      email?: string;
      name?: string;
      mobile?: string;
      vrUrl: string;
      companyId?: string;
      parentId?: string;
    }
  ) => {
    switch (userRole) {
      case UserRole.BUSINESS_HEAD:
        handleAddBusinessHead(userId);
        break;
      case UserRole.CLUSTER_HEAD:
        handleAddClusterHead(userId, formData.parentId);
        break;
      case UserRole.SITE_HEAD:
        handleAddSiteHead(userId, formData.parentId);
        break;
      case UserRole.TEAM_LEAD:
        handleAddTeamLead(userId, formData.parentId);
        break;
      case UserRole.EXECUTIVE:
        handleAddExecutiveToProject(userId, formData.vrUrl, formData.parentId);
        break;

      case UserRole.GRE:
        handleAddGREToProject(userId);
        break;
    }
  };

  const handleAddNewExecutive = async (formData: {
    email: string;
    name: string;
    mobile: string;
    vrUrl: string;
    companyId: string;
    parentId?: string;
    jobTitle: string;
  }) => {
    // TODO: remove the hireracy for adding the User
    // if (
    //   project.company.id === CLUB_RHODIUM_COMPANY_ID &&
    //   (!usersList || !formData.parentId) &&
    //   userRole !== UserRole.BUSINESS_HEAD
    // ) {
    //   addToast({
    //     type: 'ERROR',
    //     primaryMessage: `Please add ${getParentUserRole()} for this user first`,
    //     secondaryMessage: 'Failed to add new User',
    //   });
    //   return;
    // }
    try {
      const executive = {
        email: formData.email,
        name: formData.name,
        mobile: formData.mobile,
        role: userRole,
        password: randomId(),
        jobTitle: formData.jobTitle,
      };
      // TODO: Remove any
      const executiveRes: any = await addCompanyUserAPI({
        user: executive,
        companyId: formData.companyId,
      }).unwrap();

      handleAddUsers(executiveRes.data.id, formData);
    } catch (err) {
      const errMsg = (err as any).data.message;
      addToast({
        type: 'ERROR',
        primaryMessage: errMsg,
        secondaryMessage: 'Failed to add new user',
      });
    }
  };

  const handleAddExecutiveToProject = async (
    executiveId: string,
    vrUrl: string,
    teamLeadId?: string
  ) => {
    try {
      const updatedProject = await addProjectExecutiveAPI({
        projectId,
        executiveId,
        vrUrl,
        teamLeadId,
      }).unwrap();

      onAddExecutive(updatedProject.data);
      onModalClose();

      addToast({
        type: 'SUCCESS',
        primaryMessage: 'Executive created Successfully',
      });
    } catch (err) {
      const errMsg = (err as any).data.message;
      addToast({
        type: 'ERROR',
        primaryMessage: errMsg,
        secondaryMessage: 'Failed to add Exec to Project',
      });
    }
  };

  const handleIsExecutiveExists = async ({
    email,
    vrUrl,
    parentId,
  }: {
    email: string;
    vrUrl: string;
    parentId?: string;
  }) => {
    // TODO: remove the hireracy for adding the User
    // if (
    //   project.company.id === CLUB_RHODIUM_COMPANY_ID &&
    //   (!usersList || !parentId) &&
    //   userRole !== UserRole.BUSINESS_HEAD
    // ) {
    //   addToast({
    //     type: 'ERROR',
    //     primaryMessage: `Please add ${getParentUserRole()} for this user first`,
    //     secondaryMessage: 'Failed to add new User',
    //   });
    //   return;
    // }
    try {
      const executive = await getUserByEmailAPI({ email }).unwrap();
      const executiveRole = executive.data.role;

      if (
        executiveRole !== UserRole.EXECUTIVE &&
        executiveRole !== UserRole.BUSINESS_HEAD &&
        executiveRole !== UserRole.CLUSTER_HEAD &&
        executiveRole !== UserRole.SITE_HEAD &&
        executiveRole !== UserRole.TEAM_LEAD &&
        executiveRole !== UserRole.GRE
      ) {
        addToast({
          type: 'ERROR',
          primaryMessage: `User already exists with ${executiveRole}.`,
          secondaryMessage:
            'Only users with executives, clusterHead, businessHead, siteHead, teamLead role can be added to a project',
        });
        return;
      }
      const formData = {
        vrUrl,
        parentId,
      };

      handleAddUsers(executive.data.id, formData);
    } catch (err) {
      const errCode = (err as any).data.code;
      const errMsg = (err as any).data.message;

      if (errCode === NOT_FOUND) {
        setIsCreatingNewExec(true);
        return;
      }

      addToast({
        type: 'ERROR',
        primaryMessage: errMsg,
        secondaryMessage: 'Failed to check Exec if exists',
      });
    }
  };

  const handleSelectedUserRole = (selectedOptions: DropDownOptionType[]) => {
    const userRole = selectedOptions[0].value;
    setUserRole(userRole as string);
  };

  return (
    <Modal
      onOutsideClick={() => onModalClose(false)}
      additionalClasses={styles.modalAdditionalClasses}>
      {/* Modal Header */}
      <header className={styles.modalHeader}>
        <div className={styles.modalHeaderLeftContent}>
          <h1>Add User for {projectName}</h1>
          <p>
            Fill in the following info to add a new user associated to the
            selected project. All users of a developer have access to all
            projects of the developer by default.
          </p>
        </div>
        <Icon
          name='close'
          propStyles={styles.modalCloseIcon}
          onClick={() => onModalClose(false)}
        />
      </header>

      {/* Modal Body */}
      <main>
        <div className={localStyles.dropDownContainer}>
          <DropDownInput
            options={usersRoleOptions}
            lable='Select User Role'
            register={register}
            setValue={setValue}
            unregister={unregister}
            name='userRole'
            onValueSelected={handleSelectedUserRole}
            errorMessage={errors.userRole ? `Please Select user role` : ''}
          />
        </div>
        {userRole && (
          <>
            <TextInput
              label='User Organisation EmailID'
              placeHolder='Add Email of the User'
              register={register}
              setValue={setValue}
              name='email'
              type='email'
              unregister={unregister}
              errorMessage={errors.email && 'Please enter valid email'}
            />
            {/* {project.company.id === CLUB_RHODIUM_COMPANY_ID &&
              !!usersListDropdownOptions.length &&
              userRole !== UserRole.BUSINESS_HEAD && (
                <div className={localStyles.dropDownContainer}>
                  <DropDownInput
                    options={usersListDropdownOptions}
                    lable={`Select ${getParentUserRole()}`}
                    register={register}
                    setValue={setValue}
                    unregister={unregister}
                    name='parentId'
                  />
                </div>
              )} */}
          </>
        )}
        {userRole === UserRole.EXECUTIVE && (
          <TextInput
            label='Executive VSG Link'
            placeHolder='Add Executive VSG Link'
            register={register}
            setValue={setValue}
            name='vrUrl'
            unregister={unregister}
          />
        )}
        {isCreatingNewExec && (
          <div>
            <div className={styles.projectIdInputContainer}>
              <div className={styles.projectIdInput}>
                <TextInput
                  label='Full Name'
                  placeHolder='Add Name'
                  register={register}
                  setValue={setValue}
                  name='name'
                  unregister={unregister}
                  validate={(value: string) => value.length > 0}
                  errorMessage={errors.name && 'Name is required'}
                />
              </div>
              <div className={styles.projectIdInput}>
                <TextInput
                  label='Mobile Number'
                  placeHolder='Add Mobile'
                  register={register}
                  setValue={setValue}
                  name='mobile'
                  unregister={unregister}
                  maxLength={10}
                  validate={(value: string) => value.length > 0}
                  errorMessage={errors.name && 'Mobile Number is required'}
                />
              </div>
            </div>
            <div className={styles.projectIdInputContainer}>
              <div className={styles.projectIdInput}>
                <DropDownInput
                  options={jobTitleOptions}
                  lable='Select JobTitle'
                  register={register}
                  setValue={setValue}
                  unregister={unregister}
                  name='jobTitle'
                  errorMessage={
                    errors.jobTitle ? `Please Select jobTiltle` : ''
                  }
                />
              </div>
            </div>
            <div className={localStyles.dropDownContainer}>
              <DropDownInput
                defaultSelectedOption={defaultSelectedCompany}
                options={companyDropdownOptions}
                lable='Select Company'
                register={register}
                setValue={setValue}
                unregister={unregister}
                name='companyId'
                errorMessage={
                  errors.companyId ? `Please Select ${getParentUserRole()}` : ''
                }
              />
            </div>
          </div>
        )}
      </main>

      {/* Modal Footer */}
      <footer className={styles.modalFooter}>
        <div className={styles.projectIdInput}>
          <p className={styles.footerDisclaimer}>
            By adding user, an autogenerated email will be sent to the user with
            their randomly generated password and URL for the dashboard. Do this
            step the last, just before going for UAT.
          </p>
        </div>
        <div className={styles.projectIdInput}>
          <Button
            type='submit'
            onClick={() =>
              handleSubmit(
                isCreatingNewExec
                  ? handleAddNewExecutive
                  : handleIsExecutiveExists
              )()
            }
            propStyles={`${styles.verifyNowBtn}`}>
            {isCreatingNewExec
              ? 'Create & Add Executive Now'
              : 'Add Executive Now'}
          </Button>
        </div>
      </footer>
    </Modal>
  );
};

export default AddProjectExecutiveModal;
