import { useState } from 'react';

import BookingFormModal, {
  IBookLeadReq,
} from 'apps/admin/components/pages/ProjectPartnerLeads/modals/BookingFormModal';
import { TObjectId } from 'interfaces';
import Button from 'apps/booking/components/common/buttons/Button';
import { IUser } from 'apps/admin/interfaces';

interface IBookingLeadsCellProps {
  projectId: TObjectId;
  activeLeadId?: TObjectId;
  bookedLead: IBookLeadReq;
  registering?: boolean;
  bookedLeadId?: TObjectId;
  closingManager?: IUser;
}

const BookingLeadsCell = (props: IBookingLeadsCellProps) => {
  const {
    projectId,
    activeLeadId,
    bookedLead,
    registering,
    bookedLeadId,
    closingManager,
  } = props;

  const [showBookingFormModal, setShowBookingFormModal] = useState(false);
  const [isBooked, setIsBooked] = useState(false);

  const handleOpenBookingForm = () => {
    setShowBookingFormModal(true);
  };

  return (
    <>
      {showBookingFormModal && (
        <BookingFormModal
          onModalClose={() => setShowBookingFormModal(false)}
          projectId={projectId}
          activeLeadId={activeLeadId}
          registering={registering}
          onSetIsBooked={setIsBooked}
          bookedLeadData={bookedLead}
          bookedLeadId={bookedLeadId}
          closingManager={closingManager}
        />
      )}
      <Button
        onClick={handleOpenBookingForm}
        accent='primary'
        disabled={!registering && (!!bookedLead || isBooked)}>
        {registering ? 'Register' : 'Booking Done'}
      </Button>
    </>
  );
};

export default BookingLeadsCell;
