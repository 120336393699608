import { useSelector, useDispatch } from 'react-redux';

import {
  getFilterPreferences,
  getSelectedPreferences,
  getUnitListById,
  toggleFilterPreferenceItem,
  resetFilterPreferenceType,
} from 'apps/booking/slices/unitSelectionHeader/unitSelectionHeaderNav';
import { getLanguageText } from 'constants/multiLanguageTexts';
import { getUserLanguage } from 'apps/booking/slices/userlanguage';
import { UNDEFINED } from 'constants/status';
import { PRICE_UNIT } from 'apps/booking/constants';
import { ONE_CRORE } from 'constants/index';

const FilterPreferences = (props) => {
  const dispatch = useDispatch();
  const preferences = useSelector(getFilterPreferences);
  const unitListById = useSelector(getUnitListById);
  const selectedPreferences = useSelector(getSelectedPreferences);
  const userLanguage = useSelector(getUserLanguage);

  const toggleIsSelected = (key, itemIndex) => {
    dispatch(toggleFilterPreferenceItem({ key, itemIndex }));
  };

  const resetPreferences = (preferenceType) => {
    dispatch(resetFilterPreferenceType(preferenceType));
  };

  const doesPreferenceSelectedForPreferenceType = (preferenceType) => {
    return preferences[preferenceType].items.some((item) => item.isSelected);
  };

  const getAvaiableUnitsCnt = (preferenceItem, preferenceContainer) => {
    const { isSelected: isItemSelected } = preferenceItem;
    const { key: preferenceContainerType } = preferenceContainer;
    return preferenceItem.unitIds.filter((unitId) => {
      for (const preferenceType in selectedPreferences) {
        const selectedPreferencesOfType = selectedPreferences[preferenceType];
        if (!isItemSelected && preferenceType === preferenceContainerType) {
          continue;
        }
        if (preferenceType === 'area') {
          if (
            selectedPreferencesOfType.length > 0 &&
            !selectedPreferencesOfType.some(
              (areaPreference) =>
                unitListById[unitId].area.value >= areaPreference.from &&
                unitListById[unitId].area.value <= areaPreference.to
            )
          ) {
            return false;
          }
        } else if (preferenceType === 'final_payable_amount') {
          if (
            selectedPreferencesOfType.length > 0 &&
            !selectedPreferencesOfType.some(
              (preference) =>
                unitListById[unitId].costSheet.totalInclusiveAmount /
                  ONE_CRORE >=
                  preference.from &&
                unitListById[unitId].costSheet.totalInclusiveAmount /
                  ONE_CRORE <=
                  preference.to
            )
          ) {
            return false;
          }
        } else if (preferenceType === 'type') {
          // Monky patching - Bedrooms
          if (
            selectedPreferencesOfType.length > 0 &&
            !selectedPreferencesOfType.includes(
              unitListById[unitId][preferenceType].split('-')[0]
            )
          ) {
            return false;
          }
        } else if (
          selectedPreferencesOfType.length > 0 &&
          !selectedPreferencesOfType.includes(
            unitListById[unitId][preferenceType]
          )
        ) {
          return false;
        }
      }

      return true;
    }).length;
  };

  return (
    <div className='filter-preference-container'>
      <ul className='preference-list'>
        {Object.values(preferences).map((preference, index) => {
          if (
            preference.items[0].name === UNDEFINED ||
            (!preference.items[0].name && preference.type == 'LIST')
          ) {
            return null;
          }
          return (
            <li className='preference' key={preference.key}>
              <header className='preference-header'>
                <span className='preference__title'>
                  {getLanguageText(userLanguage, preference.name)}
                </span>
                <span
                  className={`preference__reset ${
                    !doesPreferenceSelectedForPreferenceType(preference.key) &&
                    ' preference__reset--disabled'
                  }`}
                  onClick={() => resetPreferences(preference.key)}>
                  <span className='icon material-icons'></span>
                  <span>{getLanguageText(userLanguage, 'Reset')}</span>
                </span>
              </header>
              <div
                className={`preferance-body ${
                  preference.items &&
                  preference.items.length > 5 &&
                  'preferance-body--col-2'
                } ${
                  index === Object.values(preferences).length - 1 &&
                  'preferance-body--hide-right-border'
                }`}>
                {preference.items?.map((preferenceItem, itemIndex) => {
                  const availableUnitsOnThisPrefenceSelectCnt =
                    getAvaiableUnitsCnt(preferenceItem, preference);

                  return (
                    <div
                      className='preference__item'
                      key={`preference__item${itemIndex}`}>
                      <strong className='preference__item-title'>
                        {preference.type === 'LIST' && (
                          <button
                            className={`${
                              preferenceItem.isSelected &&
                              'preference__item-selected'
                            } ${
                              availableUnitsOnThisPrefenceSelectCnt === 0 &&
                              'preference__item-disabled'
                            }`}
                            onClick={() =>
                              toggleIsSelected(preference.key, itemIndex)
                            }>
                            {getLanguageText(userLanguage, preferenceItem.name)}
                          </button>
                        )}
                        {preference.type === 'PRICE' && (
                          <button
                            className={`${
                              preferenceItem.isSelected &&
                              'preference__item-selected'
                            } ${
                              availableUnitsOnThisPrefenceSelectCnt === 0 &&
                              'preference__item-disabled'
                            }`}
                            onClick={() =>
                              toggleIsSelected(preference.key, itemIndex)
                            }>
                            {getLanguageText(userLanguage, 'From')} ₹
                            <span className='f-bold'>
                              {getLanguageText(
                                userLanguage,
                                preferenceItem.from < 1
                                  ? preferenceItem.from * 100
                                  : preferenceItem.from
                              )}
                            </span>
                            {preferenceItem.from < 1
                              ? PRICE_UNIT.LAKH
                              : PRICE_UNIT.CRORE}{' '}
                            {getLanguageText(userLanguage, 'to')} ₹
                            <span className='f-bold'>
                              {getLanguageText(
                                userLanguage,
                                preferenceItem.to < 1
                                  ? preferenceItem.to * 100
                                  : preferenceItem.to
                              )}
                            </span>
                            {preferenceItem.to < 1
                              ? PRICE_UNIT.LAKH
                              : PRICE_UNIT.CRORE}{' '}
                          </button>
                        )}
                        {preference.type === 'AREA' && (
                          <button
                            className={`${
                              preferenceItem.isSelected &&
                              'preference__item-selected'
                            } ${
                              availableUnitsOnThisPrefenceSelectCnt === 0 &&
                              'preference__item-disabled'
                            }`}
                            onClick={() =>
                              toggleIsSelected(preference.key, itemIndex)
                            }>
                            {getLanguageText(userLanguage, 'From')}{' '}
                            <span className='f-bold'>
                              {getLanguageText(
                                userLanguage,
                                preferenceItem.from
                              )}
                            </span>{' '}
                            {getLanguageText(userLanguage, 'to')}{' '}
                            <span className='f-bold'>
                              {getLanguageText(userLanguage, preferenceItem.to)}
                            </span>
                            {` Sqmt`}
                          </button>
                        )}
                      </strong>
                      <span className='preference__item-desc'>
                        <span className='f-bold'>
                          {availableUnitsOnThisPrefenceSelectCnt === 0
                            ? getLanguageText(userLanguage, 'no')
                            : availableUnitsOnThisPrefenceSelectCnt}
                        </span>{' '}
                        {getLanguageText(userLanguage, 'Units')}{' '}
                        {getLanguageText(userLanguage, 'available')}
                      </span>
                    </div>
                  );
                })}
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default FilterPreferences;
