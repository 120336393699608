import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';

import { GridReadyEvent, ICellRendererParams } from 'ag-grid-community';
import { AgGridReact, AgGridColumn } from 'ag-grid-react';

import TextInput from 'apps/booking/components/common/inputs/TextInput';
import ExpandedDetailSection, {
  DEFAULT_NULL_VALUE,
} from 'apps/admin/components/common/ExpandedDetailSection';
import NameCard from 'apps/booking/components/common/admin/NameCard';
import { dateToDaythMonthYear } from 'utils/dateFormator';
import { awsBaseUrl } from 'constants/urls';
import { ISiteVisit } from 'apps/admin/components/pages/ProjectPartnerLeads';
import { IPartnerSitevisits } from '../../ProjectPartnerSitevisits/PartnerSiteVisits';
import DropDownInput from 'apps/booking/components/common/inputs/DropDownInput';
import Button from 'apps/booking/components/common/buttons/Button';
import { useGetProjectDirectSiteVisitsByProjectIdMutation } from 'apps/admin/services/projectLeadsAPI';
import { pageCount, searchFieldOptions } from '..';
import Pagination from 'apps/admin/components/common/Pagination';
import { getAdminUser } from 'apps/admin/slices/adminSlice';
import SpinLoader from 'apps/booking/components/common/SpinLoader';

import styles from './styles.module.css';

const DirectSitevisits = (props: IPartnerSitevisits) => {
  const { projectId, siteVisitType, siteVisitCount, isCountLoaded } = props;

  const [gridApi, setGridApi] = useState(null) as any;
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [siteVisitsLoaded, setSiteVisitsLoaded] = useState(false);
  const [partnerSiteVisits, setpartnerSiteVisits] = useState<ISiteVisit[]>([]);
  const [searchDetails, setSearchDetails] = useState({
    searchName: '',
    searchField: '',
    startDate: '',
    endDate: '',
  });

  const { register, unregister, setValue, handleSubmit } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });
  const user = useSelector(getAdminUser);
  const [getProjectDirectSiteVisitsByProjectIdAPI] =
    useGetProjectDirectSiteVisitsByProjectIdMutation();

  //AG-grid Default column definition
  const defaultColDef = {
    sortable: false,
    filter: false,
    resizable: false,
    suppressMenu: true,
    editable: false,
    flex: 1,
  };
  const totalPages = Math.ceil(siteVisitCount / pageCount);

  useEffect(() => {
    getSiteVisit(1);
  }, [projectId, siteVisitType]);

  // Ag-grid global search
  const handleGridSearch = (event: string) => {
    gridApi.setQuickFilter(event);
  };

  // This function will automatically be called when ag grid is ready
  const onGridReady = (params: GridReadyEvent) => {
    setGridApi(params.api);
  };

  const getPhoneNumber = (phoneNumber: string) => {
    if (phoneNumber && phoneNumber.length < 5) {
      return `XXXXXX${phoneNumber}`;
    }
    return phoneNumber;
  };

  const getLeadImageUrl = (params: ICellRendererParams) => {
    return `${awsBaseUrl}/common/AZAlphabets/${params.data.lead.name[0].toUpperCase()}0.png`;
  };

  const getDetails = (partnerLeads: ISiteVisit) => {
    return [
      {
        label: 'Registered on',
        value: dateToDaythMonthYear(partnerLeads.cpLead.registrationDate),
      },
    ];
  };

  const getSiteVisit = async (
    currentPageNumber: number,
    searchedData?: {
      searchName: string;
      searchField: string;
      startDate: string;
      endDate: string;
    }
  ) => {
    try {
      setSiteVisitsLoaded(true);
      const response = await getProjectDirectSiteVisitsByProjectIdAPI({
        projectId,
        pageNumber: currentPageNumber ? currentPageNumber : 1,
        pageCount,
        ...searchedData,
      }).unwrap();

      setpartnerSiteVisits(response.data);
    } catch (error) {
      console.log(error);
    } finally {
      setSiteVisitsLoaded(false);
    }
  };

  const handleNextPage = () => {
    getSiteVisit(currentPageNumber + 1, searchDetails);
    setCurrentPageNumber(currentPageNumber + 1);
  };

  const handleOnPageChange = () => {
    getSiteVisit(currentPageNumber - 1, searchDetails);
    setCurrentPageNumber(currentPageNumber - 1);
  };

  const handleNavigationToFirstPage = () => {
    if (currentPageNumber !== 1) {
      getSiteVisit(1, searchDetails);
      setCurrentPageNumber(1);
    }
  };

  const handleNavigationToLastPage = () => {
    if (currentPageNumber !== totalPages) {
      getSiteVisit(totalPages, searchDetails);
      setCurrentPageNumber(totalPages);
    }
  };

  const handleSearchSiteVisits = (searchedData: {
    searchName: string;
    searchField: string;
    startDate: string;
    endDate: string;
  }) => {
    setSearchDetails(searchedData);
    getSiteVisit(1, searchedData);
    setCurrentPageNumber(1);
  };

  return (
    <>
      <header>
        <form
          className={styles.searchContainer}
          data-site-type={siteVisitType}
          onSubmit={handleSubmit(handleSearchSiteVisits)}>
          <DropDownInput
            options={searchFieldOptions}
            defaultSelectedOption={searchFieldOptions[0]}
            lable='Select search type '
            register={register}
            setValue={setValue}
            unregister={unregister}
            name='searchField'
            propStyles={styles.inputBox}
          />
          <TextInput
            inputHTMLElementStyles={styles.searchInput}
            placeHolder='Search'
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              handleGridSearch(event.target.value);
            }}
            register={register}
            setValue={setValue}
            unregister={unregister}
            startIconName='search'
            startIconVariant='SECONDARY'
            label='Search Lead, Partner and SM name'
            name='searchName'
            propStyles={styles.inputBox}
          />
          <TextInput
            type='date'
            placeHolder='Select start date'
            register={register}
            setValue={setValue}
            unregister={unregister}
            label='From'
            name='startDate'
            propStyles={styles.inputBox}
          />
          <TextInput
            type='date'
            placeHolder='Select start date'
            register={register}
            setValue={setValue}
            unregister={unregister}
            label='To'
            name='endDate'
            propStyles={styles.inputBox}
          />
          <Button accent='primary' propStyles={styles.searchBtn} type='submit'>
            Search
          </Button>
        </form>
      </header>
      {siteVisitsLoaded ? (
        <SpinLoader />
      ) : (
        <div
          className={`ag-theme-alpine ${styles.gridContainer}`}
          style={{ width: '100%', height: '100%' }}>
          <AgGridReact
            rowData={partnerSiteVisits}
            defaultColDef={defaultColDef}
            onGridReady={onGridReady}
            domLayout={'autoHeight'}
            pagination={false}
            paginationPageSize={pageCount}
            masterDetail={true}
            animateRows={true}
            detailCellRenderer={'detailCellRenderer'}
            detailRowAutoHeight={true}
            frameworkComponents={{
              detailCellRenderer: ExpandedDetailSection,
            }}
            detailCellRendererParams={(params: ICellRendererParams) => ({
              details: getDetails(params.data),
            })}>
            <AgGridColumn flex={0.25} />
            <AgGridColumn
              flex={2.5}
              headerName='Name'
              field={'lead.name'}
              cellRendererFramework={(params: ICellRendererParams) => (
                <NameCard
                  {...{
                    imgUrl: `${getLeadImageUrl(params)}`,
                    title: params.data.lead.name,
                  }}
                />
              )}
            />
            <AgGridColumn
              flex={2.5}
              headerName='Details'
              field={'lead.mobile'}
              cellRendererFramework={(params: ICellRendererParams) => (
                <NameCard
                  {...{
                    title: getPhoneNumber(params.data.lead.mobile),
                    subtitle: params.data.lead.email,
                  }}
                />
              )}
            />
            <AgGridColumn
              flex={2}
              headerName='SiteVisit on'
              cellRendererFramework={(params: ICellRendererParams) => (
                <p className={styles.cpName}>
                  {params.data.visitDate
                    ? dateToDaythMonthYear(params.data.visitDate)
                    : dateToDaythMonthYear(params.data.createdAt)}
                </p>
              )}
            />
            <AgGridColumn
              flex={2}
              headerName='SiteVisits'
              field={'enquiryCount'}
              cellRendererFramework={(params: ICellRendererParams) => (
                <p className={styles.cpName}>{params.data.enquiryCount}</p>
              )}
            />
            <AgGridColumn
              flex={2}
              headerName='Registered on'
              cellRendererFramework={(params: ICellRendererParams) => (
                <p className={styles.cpName}>
                  {params.data.createdAt
                    ? dateToDaythMonthYear(params.data.createdAt)
                    : DEFAULT_NULL_VALUE}
                </p>
              )}
            />
          </AgGridReact>
          <Pagination
            totalRowCount={siteVisitCount}
            rowCount={partnerSiteVisits.length}
            currentPage={currentPageNumber - 1}
            onPageChange={handleOnPageChange}
            onNextPage={handleNextPage}
            onNavigateTofirstPage={handleNavigationToFirstPage}
            onNavigationToLastPage={handleNavigationToLastPage}
            loaded={isCountLoaded}
          />
        </div>
      )}
    </>
  );
};

export default DirectSitevisits;
