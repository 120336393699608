import { adminAPISlice } from 'apps/admin/services/index';
import { IProject } from 'apps/admin/components/pages/SuperAdminDashboard/SuperAdminProjectList';
import { IEoiDetails, IAddEoi, IUser } from 'apps/admin/interfaces';
import {
  IProjectEvent,
  IProjectEventReq,
} from 'apps/admin/components/pages/SuperAdminDashboard/SuperAdminProjectList/modals/ProjectEventsModal';
import { IBookingUser } from 'apps/admin/components/pages/ProjectEois/CancelBookingCell';
import { TObjectId } from 'interfaces';

export const projectsAPISlice = adminAPISlice.injectEndpoints({
  endpoints: (build) => ({
    getProjects: build.query<IProject[], any>({
      query: () => 'api/projects/',
      transformResponse: (response: {
        data: IProject[];
        code: String;
        error: Object;
        message: String;
      }) => {
        return response.data;
      },
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: 'Projects' as const, id })),
              { type: 'Projects', id: 'LIST' },
            ]
          : [{ type: 'Projects', id: 'LIST' }],
    }),
    getProjectsByProjectId: build.query({
      query: (projectId: string) => `api/projects/${projectId}`,
      transformResponse: (response: {
        data: IProject;
        code: String;
        error: Object;
        message: String;
      }) => {
        return response.data;
      },
    }),
    getProjectsByUserId: build.query<IProject[], any>({
      query: (userId: string) => `api/projects/users/${userId}`,
      transformResponse: (response: {
        data: IProject[];
        code: String;
        error: Object;
        message: String;
      }) => {
        return response.data;
      },
    }),
    addProject: build.mutation({
      query: (project: IProject) => ({
        url: 'api/projects/',
        method: 'POST',
        body: project,
      }),
      invalidatesTags: [{ type: 'Projects', id: 'LIST' }],
    }),
    updateProject: build.mutation({
      query: ({ projectId, ...fieldsToUpdate }) => ({
        url: `api/projects/${projectId}`,
        method: 'PUT',
        body: fieldsToUpdate,
      }),
      // invalidatesTags: [{ type: 'Projects', id: 'LIST' }]
    }),
    updateProjectDetails: build.mutation({
      query: ({ projectId, ...fieldsToUpdate }) => ({
        url: `api/projects/${projectId}/details`,
        method: 'PUT',
        body: fieldsToUpdate,
      }),
    }),

    addDemoRoom: build.mutation({
      query: ({ projectId, demoRoom }) => ({
        url: `api/projects/${projectId}/demo-rooms`,
        method: 'POST',
        body: demoRoom,
      }),
      // invalidatesTags: (result, error, { id }) => [{ type: 'Projects', id }]
    }),
    updateDemoRoom: build.mutation({
      query: ({ projectId, demoRoomId, demoRoom }) => ({
        url: `api/projects/${projectId}/demo-rooms/${demoRoomId}`,
        method: 'PUT',
        body: demoRoom,
      }),
      // invalidatesTags: (result, error, { id }) => [{ type: 'Projects', id }]
    }),
    deleteDemoRoom: build.mutation({
      query: ({ projectId, demoRoomId }) => ({
        url: `api/projects/${projectId}/demo-rooms/${demoRoomId}`,
        method: 'DELETE',
      }),

      // invalidatesTags: (result, error, { id }) => [{ type: 'Projects', id }]
    }),

    addProjectExecutive: build.mutation({
      query: ({
        projectId,
        executiveId,
        vrUrl,
        teamLeadId,
      }: {
        projectId: string;
        executiveId: string;
        vrUrl: string;
        teamLeadId?: string;
      }) => ({
        url: `api/projects/${projectId}/executivesWithDemoRoom`,
        method: 'POST',
        body: { executiveId, vrUrl, teamLeadId },
      }),
      // invalidatesTags: (result, error, { id }) => [{ type: 'Projects', id }]
    }),
    deleteProjectExecutive: build.mutation({
      query: ({ projectId, executiveWithDemoRoomId }) => ({
        url: `api/projects/${projectId}/executivesWithDemoRoom/${executiveWithDemoRoomId}`,
        method: 'DELETE',
      }),
      // invalidatesTags: (result, error, { id }) => [{ type: 'Projects', id }]
    }),

    getProjectEventsByProjectId: build.query<IProjectEvent[], any>({
      query: (projectId: string) => `api/projects/events/projects/${projectId}`,
      transformResponse: (response: {
        data: IProjectEvent[];
        code: String;
        error: Object;
        message: String;
      }) => {
        return response.data;
      },
    }),
    addProjectEvent: build.mutation({
      query: (data: IProjectEventReq) => ({
        url: 'api/projects/events/',
        method: 'POST',
        body: data,
      }),
    }),
    updateProjectEvent: build.mutation({
      query: ({ projectEventId, ...fieldsToUpdate }) => ({
        url: `api/projects/events/${projectEventId}`,
        method: 'PUT',
        body: fieldsToUpdate,
      }),
    }),

    cancelBooking: build.mutation({
      query: (requestPayload: IBookingUser) => ({
        url: 'api/units/eoi/release',
        method: 'POST',
        body: requestPayload,
      }),
    }),

    confirmBooking: build.mutation({
      query: (requestPayload: IBookingUser) => ({
        url: 'api/units/eoi/book/',
        method: 'POST',
        body: requestPayload,
      }),
    }),

    addProjectEoi: build.mutation({
      query: (data: IAddEoi) => ({
        url: 'api/projects/eoi/add',
        method: 'POST',
        body: data,
      }),
    }),

    updateEoiStatusByEoiId: build.mutation({
      query: ({ eoiId, bookingStatus, requestPayload }) => ({
        url: `api/projects/eoi/${eoiId}/status/${bookingStatus}`,
        method: 'PUT',
        body: requestPayload,
      }),
    }),

    getProjectEoiLeadByProjectId: build.query<IEoiDetails[], any>({
      query: (projectId: TObjectId) => `api/projects/eoi/project/${projectId}`,
      transformResponse: (response: {
        data: IEoiDetails[];
        code: String;
        error: Object;
        message: String;
      }) => {
        return response.data;
      },
    }),

    updateProjectUnitStatus: build.mutation({
      query: ({ unitId, visibilityStatus, requestPayload }) => ({
        url: `api/units/${unitId}/status/${visibilityStatus}`,
        method: 'PUT',
        body: requestPayload,
      }),
    }),
  }),

  overrideExisting: false,
});

export const {
  useGetProjectsQuery,
  useGetProjectsByProjectIdQuery,
  useGetProjectsByUserIdQuery,
  useAddProjectMutation,
  useUpdateProjectMutation,
  useUpdateProjectDetailsMutation,

  useAddDemoRoomMutation,
  useUpdateDemoRoomMutation,
  useDeleteDemoRoomMutation,

  useAddProjectExecutiveMutation,
  useDeleteProjectExecutiveMutation,

  useGetProjectEventsByProjectIdQuery,
  useAddProjectEventMutation,
  useUpdateProjectEventMutation,

  useCancelBookingMutation,
  useConfirmBookingMutation,
  useAddProjectEoiMutation,
  useUpdateEoiStatusByEoiIdMutation,

  useGetProjectEoiLeadByProjectIdQuery,
  useUpdateProjectUnitStatusMutation,
} = projectsAPISlice;
