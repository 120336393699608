import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import Button from 'apps/booking/components/common/buttons/Button';
import Icon from 'apps/booking/components/common/Icon';
import { UserRole } from 'constants/userRoles';
import { isAdminUserRole } from 'apps/admin/components/pages/AdminLogin/utils';
import useAdminLogOut from 'apps/admin/components/pages/AdminLogin/hooks/useAdminLogOut';
import { setAppSliceIsFetching } from 'slices/appSlice';

import styles from './styles.module.css';

const AdminMobileView = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { removeAdminUserLocal, logginOut } = useAdminLogOut();

  useEffect(() => {
    dispatch(setAppSliceIsFetching(logginOut));
  }, [logginOut]);

  const handleAdminLogout = async () => {
    await removeAdminUserLocal();
    history.push('/');
  };

  return (
    <div className={styles.container}>
      <div
        className={styles.contentWrap}
        data-role-executive={isAdminUserRole(UserRole.EXECUTIVE)}>
        {isAdminUserRole(UserRole.EXECUTIVE) ? (
          <main className={styles.iframWrapper}>
            <div className={styles.embedMessageContainer}>
              <iframe
                title='d'
                width='100%'
                height='100%'
                frameBorder='0'
                src='https://analytics.relata.io/public/dashboard/50c47216-74cb-444c-a687-7d4b1f4f5a37'
                style={{ border: 0 }}
                allowFullScreen></iframe>
            </div>
          </main>
        ) : (
          <div className={styles.textWrap}>
            <Icon name='task_alt' propStyles={styles.checkmark} />
            <p className={styles.title}>Login Successful!</p>
            <p className={styles.message}>
              You have logged in successfully from your mobile device. To access
              the site visit form please proceed to scan QR code from lead /
              channel partner using mobile / tablet camera.
            </p>
          </div>
        )}
        <div className={styles.buttonWrap}>
          {/* TODO: enable with functinality 
          <Button propStyles={styles.doneBtn}>Done</Button> */}
          <Button propStyles={styles.logoutBtn} onClick={handleAdminLogout}>
            Logout
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AdminMobileView;
