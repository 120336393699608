import { IconNames } from 'apps/booking/components/common/Icon';
import {
  TCpLeadStatus,
  ILeadProject,
  TCpLeadProjectStatus,
} from 'apps/cp/interfaces';
import { LEAD_STATUS, LEAD_PROJECT_STATUS } from 'constants/status';
import { getDateFormatInddmmyy } from 'utils/dateFormator';
import { getNumberOfDays } from 'utils/utils';

export const getLeadSubStatus = (leadStatus: TCpLeadStatus) => {
  let substatus = 'New Lead';
  switch (leadStatus) {
    case LEAD_STATUS.SHORTLISTING:
      substatus = 'Shortlisted';
      break;
    case LEAD_STATUS.SCHEDULED_SITE_VISIT:
      substatus = 'Visit Scheduled';
      break;
    case LEAD_STATUS.ASSIGN:
      substatus = 'Visits Done';
      break;
    case LEAD_STATUS.UNASSIGN:
      substatus = 'Visits Done';
      break;
    case LEAD_STATUS.BOOKED:
      substatus = 'Booking Done';
      break;
  }
  return substatus;
};

export const getLeadStatus = (leadStatus: TCpLeadStatus) => {
  let status = 'Just Added';
  switch (leadStatus) {
    case LEAD_STATUS.SHORTLISTING:
      status = 'Shortlisting';
      break;
    case LEAD_STATUS.SCHEDULED_SITE_VISIT:
      status = 'Scheduled Visit';
      break;
    case LEAD_STATUS.ASSIGN:
      status = 'Visiting Projects';
      break;
    case LEAD_STATUS.UNASSIGN:
      status = 'Visiting Projects';
      break;
    case LEAD_STATUS.BOOKED:
      status = 'Booking Projects';
      break;
  }
  return status;
};

export const getStatusClassName = (leadStatus: TCpLeadStatus) => {
  let status = 'justAdded';
  switch (leadStatus) {
    case LEAD_STATUS.SHORTLISTING:
      status = 'shortlisted';
      break;
    case LEAD_STATUS.SCHEDULED_SITE_VISIT:
      status = 'scheduled';
      break;
    case LEAD_STATUS.ASSIGN:
      status = 'visitDone';
      break;
    case LEAD_STATUS.UNASSIGN:
      status = 'visitDone';
      break;
  }
  return status;
};

export const getProjectCount = (
  leadProjects: ILeadProject[],
  leadStatus: TCpLeadStatus
) => {
  let status = '';
  switch (leadStatus) {
    case LEAD_STATUS.ASSIGN:
      status = LEAD_PROJECT_STATUS.ASSIGN;
      break;
    case LEAD_STATUS.UNASSIGN:
      status = LEAD_PROJECT_STATUS.UNASSIGN;
      break;
    case LEAD_STATUS.SHORTLISTING:
      status = LEAD_PROJECT_STATUS.SHORTLISTED;
      break;

    case LEAD_STATUS.SCHEDULED_SITE_VISIT:
      status = LEAD_PROJECT_STATUS.SCHEDULED_SITE_VISIT;
      break;
  }
  const projectLength = leadProjects?.length;
  const filteredProjects = leadProjects?.filter(
    (project) => project.status === status
  );
  if (projectLength === filteredProjects?.length) {
    return `${projectLength} `;
  }

  return `${filteredProjects.length}/${projectLength} `;
};

export const getleadPreferences = (
  preferences: string[],
  slicedLength: number
) => {
  if (preferences.length > slicedLength) {
    const configs = preferences.slice(0, slicedLength);
    const remainingLength = preferences.length - configs.length;
    configs.push(`+ ${remainingLength} others`);
    return configs.join(', ');
  }
  return preferences.join(', ');
};

export const getProjectStatus = (projectStatus?: TCpLeadProjectStatus) => {
  let status = '';
  switch (projectStatus) {
    case LEAD_PROJECT_STATUS.ASSIGN:
      status = 'Sitevisit Done';
      break;
    case LEAD_PROJECT_STATUS.UNASSIGN:
      status = 'Sitevisit Done';
      break;
    case LEAD_PROJECT_STATUS.SCHEDULED_SITE_VISIT:
      status = 'Scheduled Visit';
      break;
    default:
      status = 'Shortlisted';
  }

  return status;
};

export const getProjectStatusCta = (
  projectStatus: string,
  projectActivityDate?: Date
) => {
  let projectCta = 'Schedule Visit';
  const days = projectActivityDate ? getNumberOfDays(projectActivityDate) : 4;

  switch (projectStatus) {
    case LEAD_PROJECT_STATUS.ASSIGN:
      projectCta = 'Schedule Revisit';
      break;
    case LEAD_PROJECT_STATUS.UNASSIGN:
      projectCta = 'Schedule Revisit';
      break;
    case LEAD_PROJECT_STATUS.SCHEDULED_SITE_VISIT:
      projectCta =
        days <= 0
          ? 'Confirm Sitevisit'
          : `visit scheduled for ${getDateFormatInddmmyy(
              projectActivityDate as Date
            )}`;
      break;
    default:
      projectCta = 'Schedule Visit';
  }

  return projectCta;
};

export const getProjectctaIcon = (
  projectStatus: string,
  projectActivityDate?: Date
) => {
  let ctaIcon: IconNames = 'event_note';
  const days = projectActivityDate ? getNumberOfDays(projectActivityDate) : 4;

  switch (projectStatus) {
    case LEAD_PROJECT_STATUS.ASSIGN:
      ctaIcon = 'event_note';
      break;
    case LEAD_PROJECT_STATUS.UNASSIGN:
      ctaIcon = 'event_note';
      break;
    case LEAD_PROJECT_STATUS.SCHEDULED_SITE_VISIT:
      ctaIcon = days <= 0 ? 'check_circle' : 'access_time';
      break;
    default:
      ctaIcon = 'event_note';
  }

  return ctaIcon;
};

export const getProjectStatusClassName = (
  projectStatus?: TCpLeadProjectStatus
) => {
  let className = '';
  switch (projectStatus) {
    case LEAD_PROJECT_STATUS.ASSIGN:
      className = 'siteVisitDone';
      break;
    case LEAD_PROJECT_STATUS.UNASSIGN:
      className = 'siteVisitDone';
      break;
    case LEAD_PROJECT_STATUS.SCHEDULED_SITE_VISIT:
      className = 'scheduledVisit';
      break;
    default:
      className = 'shortlisted';
  }

  return className;
};

export const getProjectLastActivity = (activityDate: Date) => {
  const days = getNumberOfDays(activityDate, 'startDate');
  if (days > 45) {
    return `Last activity on ${getDateFormatInddmmyy(activityDate)}`;
  }

  if (days <= 0) {
    return 'Last Activity is Today';
  }

  return ` ${days} ${days == 1 ? 'day' : 'days'} since last activity`;
};

export const getVisitedLeadsLabel = (leadType: string) => {
  let label = '';
  switch (leadType) {
    case LEAD_STATUS.ASSIGN:
      label = 'Assigned';
      break;
    case LEAD_STATUS.BOOKED:
      label = 'Booked';
      break;
    case LEAD_STATUS.UNASSIGN:
      label = 'Pending';
      break;
  }

  return label;
};
