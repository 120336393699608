import FadeInFadeOutAnim from '../components/common/FadeInFadeOutAnim';

const BookingContent = (props) => {
  const {
    headerComponent: HeaderComponent,
    contentBodyComponent: ContentBodyComponent,
  } = props;
  return (
    <div className='content content_right'>
      {HeaderComponent && <HeaderComponent />}
      <div className='content-body'>
        <FadeInFadeOutAnim width='100%' height='100%'>
          {ContentBodyComponent && <ContentBodyComponent />}
        </FadeInFadeOutAnim>
      </div>
    </div>
  );
};

export default BookingContent;
