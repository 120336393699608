import { useState } from 'react';

import ExecutiveQrCodeModal from 'apps/admin/components/pages/ExecutiveDashboard/ExecutiveQrCodeModal';
import Icon from 'apps/booking/components/common/Icon';
import { TObjectId } from 'interfaces';
import { UserRole } from 'constants/userRoles';

interface IQrCodeCellProps {
  executiveId: TObjectId;
  projectId: TObjectId;
  userRole: string;
}

const QrCodeCell = (props: IQrCodeCellProps) => {
  const { executiveId, projectId, userRole } = props;

  const [executiveQrModalShown, setExecutiveQrModalShown] = useState(false);

  if (userRole !== UserRole.EXECUTIVE) {
    return null;
  }
  return (
    <>
      {executiveQrModalShown && (
        <ExecutiveQrCodeModal
          onClose={() => setExecutiveQrModalShown(false)}
          executiveId={executiveId}
          projectId={projectId}
          showModal={executiveQrModalShown}
        />
      )}
      <Icon
        propStyles={`actionIcon`}
        name='details'
        onClick={() => setExecutiveQrModalShown(true)}
      />
    </>
  );
};

export default QrCodeCell;
