import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import UnitFloorPlanPhoto from './UnitFloorPlanPhoto';
import { HELIOS_ORIGIN, URLS } from '../../../../constants/urls';
import {
  isEmpty,
  toIndianRupee,
  convertMeasureUnitToDisplayText,
} from '../../../../utils/utils';
import { getConfigDetailsFromPath } from './utils';
import { PRICE_UNIT } from 'apps/booking/constants';

import {
  getSelectedUnitConfig,
  getHierarchyUnitList,
  getUnitListById,
  getSelectedTower,
  getSelectedSection,
  setUnitListById,
} from 'apps/booking/slices/unitSelectionHeader/unitSelectionHeaderNav';
import {
  setSelectedUnit as setSelectedUnitSlice,
  getSelectedUnit,
  setSelectedScheme,
  setSelectedEOI,
} from '../../slices/selectedUnit';
import {
  BOOKED,
  ERROR,
  SUCCESS,
  UNDEFINED,
  UNIT_STATUSES,
} from 'constants/status';
import { getLanguageText } from 'constants/multiLanguageTexts';
import { getProject, getUserEOIs } from 'apps/booking/slices/projectSlice';
import { getUserLanguage } from 'apps/booking/slices/userlanguage';
import { useEoiBlockUnitByIdMutation } from 'apps/booking/services/unitsAPISlice';
import { getUser, getThirdPartyUserId } from 'apps/booking/slices/auth';
import useToast from 'hooks/useToast';
import { setBookingRoutesCurrentPage } from 'apps/booking/routers/BookingRoutes/slice';
import { PROJECT_TYPE, ONE_CRORE, ONE_LAKH } from 'constants/index';

const UnitDetails = () => {
  const dispatch = useDispatch();
  const unitListById = useSelector(getUnitListById);
  const configPath = useSelector(getSelectedUnitConfig);
  const userLanguage = useSelector(getUserLanguage);
  const thirdPartyUserId = useSelector(getThirdPartyUserId);
  const hierarchyUnitList = useSelector(getHierarchyUnitList);
  const project = useSelector(getProject);
  const user = useSelector(getUser);
  const eois = useSelector(getUserEOIs);
  const [eoiBlockUnitByIdAPI] = useEoiBlockUnitByIdMutation();

  const [addToast] = useToast();

  const redirectToUnitSelection = () => {
    dispatch(setBookingRoutesCurrentPage(URLS.UNIT_SELECTION));
  };

  if (!configPath || !hierarchyUnitList) {
    redirectToUnitSelection();
    return null;
  }

  const [selectedUnit, setSelectedUnit] = useState(
    useSelector(getSelectedUnit)
  );
  const towerName = useSelector(getSelectedTower);
  let section = useSelector(getSelectedSection);
  section = section.toLowerCase();
  const { blockName, unitNumberDigit } = getConfigDetailsFromPath(
    configPath,
    project
  );

  const floorUnitList = hierarchyUnitList?.[towerName]?.[section]?.[
    unitNumberDigit
  ]
    ?.filter(
      (unitId) =>
        unitListById[unitId].blockName.toLowerCase() === blockName ||
        !unitListById[unitId].blockName
    )
    .map((unitId) => unitListById[unitId]);

  if (!floorUnitList) {
    redirectToUnitSelection();
    return null;
  }

  const configUnitDetails = floorUnitList[0];
  const minFinalPayableAmount = floorUnitList?.reduce(
    (min, unit) =>
      unit.costSheet?.totalInclusiveAmount < min
        ? unit.costSheet.totalInclusiveAmount
        : min,
    floorUnitList[0].costSheet?.totalInclusiveAmount
  );
  const maxFinalPayableAmount = floorUnitList?.reduce(
    (max, unit) =>
      unit.costSheet?.totalInclusiveAmount > max
        ? unit.costSheet.totalInclusiveAmount
        : max,
    floorUnitList[0].costSheet?.totalInclusiveAmount
  );

  if (minFinalPayableAmount == maxFinalPayableAmount && isEmpty(selectedUnit)) {
    setSelectedUnit(floorUnitList[0]);
  }

  const unitDetailSummery = [
    { label: 'Unit Number', value: configUnitDetails.number },
    {
      label: project.type == PROJECT_TYPE.PLOTS ? 'Category' : 'Configuration',
      value: configUnitDetails.type,
    },
    { label: 'Zone', value: configUnitDetails.towerName },
    {
      label: 'Plot Size',
      value: `${configUnitDetails.area.value} ${convertMeasureUnitToDisplayText(
        configUnitDetails.area.unit
      )}`,
    },
    {
      label: 'All Inclusive Total',
      value: `₹ ${configUnitDetails.costSheet.totalInclusiveAmount}`,
    },
  ];

  const handleUnitSelect = async (unit) => {
    if (project.booking.thirdPartyIntegrationEnabled) {
      window.parent.postMessage(
        {
          code: 'UNIT_SELECTED',
          message: 'Unit is selected for booking',
          data: {
            id: selectedUnit.thirdPartyUnitId,
          },
        },
        HELIOS_ORIGIN // TODO: Make it generic
      );
    } else {
      try {
        const maxAmountEOI = eois.reduce(
          (maxEOI, eoi) => {
            if (eoi.amount > maxEOI.amount && unit.tags.includes(eoi.unitTag)) {
              return eoi;
            }
            return maxEOI;
          },
          { amount: 0 }
        );
        dispatch(setSelectedEOI(maxAmountEOI));
        dispatch(setSelectedScheme(selectedUnit.schemes[0]));
        dispatch(setSelectedUnitSlice(selectedUnit));
        dispatch(setBookingRoutesCurrentPage(URLS.COST_SHEET));
      } catch (error) {
        addToast({
          type: ERROR,
          primaryMessage: error.data.message,
          timeout: 1000,
        });
      }
    }
    // TODO: Make it generic, enable cost sheet for other projects
    // dispatch(setSelectedUnitSlice(selectedUnit));
    // history.push(URLS.COST_SHEET);
  };

  const updateUnitList = (unit) => {
    const newUnitListById = { ...unitListById };
    newUnitListById[unit.id] = { ...newUnitListById[unit.id], status: BOOKED };
    dispatch(setUnitListById(newUnitListById));
  };

  return (
    <div className='unit-details'>
      <UnitFloorPlanPhoto configUnitDetails={configUnitDetails} />
      <div className='unit-details-plank'>
        <header className='unit-details-plank__header'>
          {configUnitDetails.type && (
            <div className='details-card'>
              <span className='details-card__title'>
                {getLanguageText(
                  userLanguage,
                  project.type == PROJECT_TYPE.PLOTS
                    ? 'Category'
                    : 'Configuration'
                )}
              </span>
              <span className='icon icon--primary material-icons'></span>
              <span className='details-card__desc'>
                {getLanguageText(userLanguage, configUnitDetails.type)}
              </span>
            </div>
          )}
          {configUnitDetails.blockName && (
            <div className='details-card'>
              <span className='details-card__title'>
                {getLanguageText(userLanguage, 'Block')}
              </span>
              <span className='icon icon--primary material-icons'></span>
              <span className='details-card__desc'>
                {getLanguageText(userLanguage, configUnitDetails.blockName)}
              </span>
              {/*  TODO: hide zone details
              <span className='details-card__desc'>
                {getLanguageText(
                  userLanguage,
                  configUnitDetails.blockName ? 'Block ' : 'Zone '
                ) +
                  getLanguageText(
                    userLanguage,
                    configUnitDetails.blockName
                      ? configUnitDetails.blockName
                      : configUnitDetails.applicableFloorplan
                  )}
              </span>
            */}
            </div>
          )}
          <div className='details-card'>
            <span className='details-card__title'>
              {getLanguageText(
                userLanguage,
                project.booking?.title?.towerBtnTitle || 'Tower'
              )}
            </span>
            <span className='icon icon--primary material-icons'></span>
            <span className='details-card__desc'>
              {getLanguageText(userLanguage, configUnitDetails.towerName)}
            </span>
          </div>
          <div className='details-card'>
            <span className='details-card__title'>
              {getLanguageText(
                userLanguage,
                project.type == PROJECT_TYPE.PLOTS ? 'Plot Size' : 'Carpet Area'
              )}
            </span>
            <span className='icon icon--primary material-icons'></span>
            <span className='details-card__desc'>
              {getLanguageText(userLanguage, configUnitDetails.area.value)}
            </span>
            <span className='details-card__desc'>
              {configUnitDetails.area.unit === 'SQFEET'
                ? getLanguageText(userLanguage, 'Sq. Ft.')
                : getLanguageText(userLanguage, 'Sq. Meter.')}
            </span>
          </div>
          {configUnitDetails.facing !== UNDEFINED &&
            configUnitDetails.facing && (
              <div className='details-card'>
                <span className='details-card__title'>
                  {getLanguageText(userLanguage, 'Unit View')}
                </span>
                <span className='icon icon--primary material-icons'></span>
                <span className='details-card__desc'>
                  {getLanguageText(userLanguage, configUnitDetails.facing)}
                </span>
              </div>
            )}
          {configUnitDetails.view !== UNDEFINED && configUnitDetails.view && (
            <div className='details-card'>
              <span className='details-card__title'>
                {getLanguageText(userLanguage, 'Unit Facing')}
              </span>
              <span className='icon icon--primary material-icons'></span>
              <span className='details-card__desc'>
                {getLanguageText(userLanguage, configUnitDetails.view)}
              </span>
            </div>
          )}
        </header>
        <div className='unit-details-plank__body'>
          <div className='floor-list-container'>
            <div className='unit-details-plank__body-header'>
              <span className='unit-details-plank__body-header-title'>
                {getLanguageText(
                  userLanguage,
                  project.type == 'PLOTS'
                    ? 'Review Selected Unit Details'
                    : 'Choose Unit'
                )}
              </span>
              <span className='unit-details-plank__body-header-desc'>
                {getLanguageText(
                  userLanguage,
                  'Select from one of the available units to proceed'
                )}
              </span>
            </div>
            {project.type == 'PLOTS' ? (
              <div className='unit-detail-summery_wrapper'>
                {unitDetailSummery.map((summery, index) => (
                  <div className='unit-detail_summery' key={index}>
                    <p className='unit-detail-summery_type'>{summery.label}</p>
                    <p className='unit-detail-summery_value'>{summery.value}</p>
                  </div>
                ))}
              </div>
            ) : (
              <div>
                <div className='floor-list'>
                  {floorUnitList.map((floorUnit) => (
                    <button
                      data-unit-status={floorUnit.status}
                      data-unit-id={floorUnit.id}
                      data-selected-unit-id={selectedUnit.id}
                      className={`btn floor-list__item  ${
                        ((floorUnit.status === UNIT_STATUSES.AVAILABLE &&
                          floorUnit.publishedOnPortal === true) ||
                          floorUnit.status ===
                            UNIT_STATUSES.OUT_OF_PREFERENCE) &&
                        'available-unit-background'
                      } ${
                        !(
                          (floorUnit.status === UNIT_STATUSES.AVAILABLE &&
                            floorUnit.publishedOnPortal === true) ||
                          floorUnit.status === UNIT_STATUSES.OUT_OF_PREFERENCE
                        ) && 'unavailable-unit-background'
                      } ${
                        [
                          UNIT_STATUSES.AVAILABLE,
                          UNIT_STATUSES.OUT_OF_PREFERENCE,
                          UNIT_STATUSES.RESERVED,
                          UNIT_STATUSES.BLOCKED,
                        ].includes(floorUnit.status)
                          ? 'click-enabled'
                          : 'click-disabled'
                      } ${
                        floorUnit.status === UNIT_STATUSES.OUT_OF_PREFERENCE &&
                        'out-of-preference'
                      } ${
                        floorUnit.id === selectedUnit.id &&
                        'floor-list__item--selected'
                      } ${
                        floorUnit.status === UNIT_STATUSES.RESERVED &&
                        floorUnit.unitCart?.thirdPartyUserId ===
                          thirdPartyUserId &&
                        'blocked-for-otp-unit-background'
                      } ${
                        floorUnit.status === UNIT_STATUSES.BLOCKED &&
                        floorUnit.unitCart?.thirdPartyUserId ===
                          thirdPartyUserId &&
                        'blocked-unit-background'
                      } ${
                        floorUnit.status === UNIT_STATUSES.BOOKED &&
                        floorUnit.unitCart?.thirdPartyUserId ===
                          thirdPartyUserId &&
                        'booked-unit-background'
                      }`}
                      onClick={() => {
                        if (floorUnit.id === selectedUnit.id) {
                          setSelectedUnit({}); // Toggle off if already selected
                        } else {
                          setSelectedUnit(floorUnit); // Set to clicked floorUnit
                        }
                      }}>
                      <span className='icon icon--primary material-icons'>
                        
                      </span>
                      {floorUnit.status === UNIT_STATUSES.OUT_OF_PREFERENCE && (
                        <span className='icon icon--primary material-icons icon--out-of-preference'>
                          
                        </span>
                      )}
                      <span>
                        {floorUnit.blockName !== UNDEFINED &&
                          getLanguageText(
                            userLanguage,
                            floorUnit.blockName
                          )}{' '}
                        <span className='f-bold'>
                          {getLanguageText(userLanguage, floorUnit.number)}
                        </span>
                      </span>
                    </button>
                  ))}
                </div>
              </div>
            )}
          </div>
          {!project.eoi?.enabled && (
            <div className='unit-details-plank__legends'>
              <div className='unit-details-plank__body-header'>
                <span className='unit-details-plank__body-header-title'>
                  {getLanguageText(userLanguage, 'Floor Legend')}
                </span>
                <span className='unit-details-plank__body-header-desc'>
                  {getLanguageText(
                    userLanguage,
                    'Select from one of the available units'
                  )}
                </span>
              </div>
              <ul className='list__flow'>
                <li className='unit-details-plank__legends-item'>
                  <span className='icon-container available-unit-background'></span>
                  <span className='unit-details-plank__legends-item-desc'>
                    {getLanguageText(
                      userLanguage,
                      'Available for you to select and book right now!'
                    )}
                  </span>
                </li>
                <li className='unit-details-plank__legends-item'>
                  <span className='icon-container unavailable-unit-background'></span>
                  <span className='unit-details-plank__legends-item-desc'>
                    {getLanguageText(
                      userLanguage,
                      "Booked or Unavailable unit. It can't be selected"
                    )}
                  </span>
                </li>
                <li className='unit-details-plank__legends-item'>
                  <span className='icon-container out-of-preference'></span>
                  <span className='unit-details-plank__legends-item-desc'>
                    {getLanguageText(
                      userLanguage,
                      'Outside the preferences you have selected'
                    )}
                  </span>
                </li>
                <li className='unit-details-plank__legends-item'>
                  <span className='icon-container blocked-unit-background'></span>
                  <span className='unit-details-plank__legends-item-desc'>
                    {getLanguageText(
                      userLanguage,
                      'You have BLOCKED unit for OTP / PAYMENT'
                    )}
                  </span>
                </li>
                <li className='unit-details-plank__legends-item'>
                  <span className='icon-container booked-unit-background'></span>
                  <span className='unit-details-plank__legends-item-desc'>
                    {getLanguageText(userLanguage, 'You have BOOKED unit')}
                  </span>
                </li>
                <li className='unit-details-plank__legends-item'>
                  <span className='icon-container selected-unit-background'></span>
                  <span className='unit-details-plank__legends-item-desc'>
                    {getLanguageText(userLanguage, 'Selected by you')}
                  </span>
                </li>
              </ul>
            </div>
          )}
        </div>
        <footer className='unit-details-plank__footer'>
          {isEmpty(selectedUnit) &&
          minFinalPayableAmount != maxFinalPayableAmount ? (
            <div className='unit-details-plank__summery'>
              <span className='unit-details-plank__summery-title'>
                {getLanguageText(
                  userLanguage,
                  'A tentative range of all inclusive cost'
                )}
              </span>
              <span className='unit-details-plank__summery-value'>
                <span className='f-bold'>
                  ₹
                  {(minFinalPayableAmount < ONE_CRORE
                    ? minFinalPayableAmount / ONE_LAKH
                    : minFinalPayableAmount / ONE_CRORE
                  ).toFixed(2)}
                </span>{' '}
                {minFinalPayableAmount < ONE_CRORE
                  ? PRICE_UNIT.LAKH
                  : PRICE_UNIT.CRORE}{' '}
                to
                <span className='f-bold'>
                  {' '}
                  ₹
                  {(maxFinalPayableAmount < ONE_CRORE
                    ? maxFinalPayableAmount / ONE_LAKH
                    : maxFinalPayableAmount / ONE_CRORE
                  ).toFixed(2)}
                </span>{' '}
                {maxFinalPayableAmount < ONE_CRORE
                  ? PRICE_UNIT.LAKH
                  : PRICE_UNIT.CRORE}{' '}
                to
              </span>
            </div>
          ) : (
            <div className='unit-details-plank__summery'>
              <span className='unit-details-plank__summery-title'>
                {getLanguageText(
                  userLanguage,
                  'The price for selected unit is'
                )}
              </span>
              <span className='unit-details-plank__summery-value'>
                <span className='f-bold'>
                  {' '}
                  {toIndianRupee(selectedUnit.costSheet?.totalInclusiveAmount)}
                </span>{' '}
              </span>
            </div>
          )}
          <div className='unit-details-plank__action-btns'>
            <button
              className='btn btn--transparent'
              onClick={() => redirectToUnitSelection()}>
              {getLanguageText(userLanguage, 'Back to Floor Plan')}
            </button>
            <button
              className={`btn ${isEmpty(selectedUnit) && 'btn--disabled'} ${
                !isEmpty(selectedUnit) && 'btn--enabled'
              }`}
              onClick={() => handleUnitSelect(selectedUnit)}>
              {getLanguageText(userLanguage, 'Select Unit')}
            </button>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default UnitDetails;
