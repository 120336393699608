interface MULTILINGUAL_TEXTS_FORMAT {
  [key: string]: {
    [key: string]: string;
  };
}

export const MULTILINGUAL_TEXTS: MULTILINGUAL_TEXTS_FORMAT = {
  hi: {
    en: 'Hi',
    hi: 'नमस्ते',
    mr: 'नमस्कार',
  },
  'you are viewing': {
    en: 'You are viewing',
    hi: 'आप देख रहे हैं',
    mr: 'तुम्ही पहात आहात',
  },
  'select the unit you want to view on the floor map. click on it to view details & availability':
    {
      en: 'Select the unit you want to view on the floor map. Click on it to view details & availability',
      hi: 'फ्लोर मैप पर देखना चाहते हुए इकाई का चयन करें। उस पर क्लिक करके विवरण और उपलब्धता देखें।',
      mr: 'मजकूर नक्की करण्यासाठी तुम्ही फ्लोर मॅपवर बघण्यासाठी युनिट निवडा. त्यावर क्लिक करून तपशील आणि उपलब्धता पाहा.',
    },
  'master plan': {
    en: 'Master Plan',
    hi: 'मास्टर प्लान',
    mr: 'मास्टर प्लॅन',
  },
  no: {
    en: 'No',
    hi: 'शून्य',
    mr: 'शून्य',
  },
  available: {
    en: 'Available',
    hi: 'उपलब्ध',
    mr: 'उपलब्ध',
  },
  filtered: {
    en: 'Filtered',
    hi: 'फिल्टर',
    mr: 'फिल्टर',
  },
  filter: {
    en: 'Filter',
    hi: 'फिल्टर',
    mr: 'फिल्टर',
  },
  tower: {
    en: 'Tower',
    hi: 'टावर',
    mr: 'टॉवर',
  },
  'tower-1': {
    en: 'Tower-1',
    hi: 'टावर-1',
    mr: 'टॉवर-1',
  },
  'tower-2': {
    en: 'Tower-2',
    hi: 'टावर-2',
    mr: 'टॉवर-2',
  },
  'tower-3': {
    en: 'Tower-3',
    hi: 'टावर-3',
    mr: 'टॉवर-3',
  },
  'tower-4': {
    en: 'Tower-4',
    hi: 'टावर-4',
    mr: 'टॉवर-4',
  },
  'tower-5': {
    en: 'Tower-5',
    hi: 'टावर-5',
    mr: 'टॉवर-5',
  },
  'tower-6': {
    en: 'Tower-6',
    hi: 'टावर-6',
    mr: 'टॉवर-6',
  },
  'tower-7': {
    en: 'Tower-6',
    hi: 'टावर-6',
    mr: 'टॉवर-6',
  },
  'tower-8': {
    en: 'Tower-6',
    hi: 'टावर-6',
    mr: 'टॉवर-6',
  },
  'tower-9': {
    en: 'Tower-6',
    hi: 'टावर-6',
    mr: 'टॉवर-6',
  },
  'tower-10': {
    en: 'Tower-6',
    hi: 'टावर-6',
    mr: 'टॉवर-6',
  },
  'tower-11': {
    en: 'Tower-6',
    hi: 'टावर-6',
    mr: 'टॉवर-6',
  },
  'tower-12': {
    en: 'Tower-6',
    hi: 'टावर-6',
    mr: 'टॉवर-6',
  },
  units: {
    en: 'units',
    hi: 'इकाई',
    mr: 'एकक',
  },
  below_5: {
    en: 'Below_5',
    hi: 'नीचे-5',
    mr: 'खाली-5',
  },
  multiple_3: {
    en: 'Multiple_3',
    hi: '3 की गुणा-फल',
    mr: '३ चे गुणक',
  },
  normal: {
    en: 'Normal',
    hi: 'सामान्य',
    mr: 'सामान्य',
  },
  floor: {
    en: 'Floor',
    hi: 'मंज़िल',
    mr: 'मजला',
  },
  'clear filters': {
    en: 'Clear Filters',
    hi: 'फ़िल्टर साफ़ करें',
    mr: 'फिल्टर साफ करा',
  },
  bedrooms: {
    en: 'Bedrooms',
    hi: 'शयनकक्ष',
    mr: 'कमरा',
  },
  'unit view': {
    en: 'Unit View',
    hi: 'इकाई दृश्य',
    mr: 'एकक दृश्य',
  },
  'unit facing': {
    en: 'Unit Facing',
    hi: 'यूनिट का फेसिंग',
    mr: 'युनिटचा फेसिंग',
  },
  'price range': {
    en: 'Price Range',
    hi: 'मूल्य सीमा',
    mr: 'किंमतीचा श्रेणी',
  },
  'built up area': {
    en: 'Built Up Area',
    hi: 'निर्मित क्षेत्रफळ',
    mr: 'निर्मित क्षेत्राचा विस्तार',
  },
  config: {
    en: 'Config',
    hi: 'कॉन्फ़िग',
    mr: 'कॉनफ़िग',
  },
  block: {
    en: 'Block',
    hi: 'ब्लॉक',
    mr: 'ब्लॉक',
  },
  'super built-up': {
    en: 'Super built-up',
    hi: 'सुपर बिल्ट-अप',
    mr: 'सुपर बिल्ट-अप',
  },
  'select from one of the available units to proceed': {
    en: 'Select from one of the available units to proceed',
    hi: 'कृपया आगे बढ़ने के लिए उपलब्ध इकाइयों में से एक का चयन करें।',
    mr: 'आगंतुकांसाठी उपलब्ध अकके मधून एक निवडा करण्यात येता.',
  },
  'available for you to select and book right now!': {
    en: 'Available for you to select and book right now!',
    hi: 'अभी आपके लिए उपलब्ध है, चुनें और बुक करें!',
    mr: 'आता आपल्यासाठी उपलब्ध आहे, निवडा आणि बुक करा!',
  },
  "booked or unavailable unit. it can't be selected": {
    en: "Booked or Unavailable unit. It can't be selected",
    hi: 'बुक किया गया या अनुपलब्ध इकाई। यह चयन नहीं किया जा सकता।',
    mr: 'बुक केलेली किंवा उपलब्ध नसलेली एकक. ही निवडू शकत नाही.',
  },
  close: {
    en: 'Close',
    hi: 'बंद करें',
    mr: 'बंद करा',
  },
  'outside the preferences you have selected': {
    en: 'Outside the preferences you have selected',
    hi: 'आपके द्वारा चयनित प्राथमिकताओं के बाहर',
    mr: 'आपण निवडलेल्या प्राधान्यांपेक्षा बाहेर',
  },
  'a tentative range of all inclusive cost': {
    en: 'A tentative range of all inclusive cost',
    hi: 'सभी शामिल लागतों की एक अनुमानित श्रृंखला',
    mr: 'सर्व समावेशी खर्चाची एक अनुमती श्रृंखला',
  },
  'choose unit': {
    en: 'Choose Unit',
    hi: 'इकाई चुनें',
    mr: 'युनिट निवडा',
  },
  'floor legend': {
    en: 'Floor Legend',
    hi: 'मंज़िल विवरण',
    mr: 'मजला विवरण',
  },
  'select from one of the available units': {
    en: 'Select from one of the available units',
    hi: 'उपलब्ध इकाइयों में से एक का चयन करें',
    mr: 'उपलब्ध युनिटमधून निवडा',
  },
  'back to floor plan': {
    en: 'Back to Floor Plan',
    hi: 'फ़्लोर प्लान पर वापस जाएं',
    mr: 'मजला आराखड्यावर परत जा',
  },
  'select unit': {
    en: 'Select Unit',
    hi: 'इकाई चुनें',
    mr: 'युनिट निवडा',
  },
  'there are no units available for the current selection. please check other selection or try again later':
    {
      en: 'There are no units available for the current selection. Please check other selection or try again later',
      hi: 'वर्तमान चयन के लिए कोई इकाइयां उपलब्ध नहीं हैं। कृपया अन्य चयन की जाँच करें या बाद में पुन: प्रयास करें।',
      mr: 'वर्तमान निवडीसाठी कोणत्याही युनिट उपलब्ध नाहीत. कृपया इतर निवडीची तपासणी करा किंवा नंतर पुन्हा प्रयत्न करा.',
    },
  for: {
    en: 'for',
    hi: 'के लिए',
    mr: 'च्या साठी',
  },
  preferences: {
    en: 'Preferences',
    hi: 'रुचि',
    mr: 'प्राधान्ये',
  },
  // 'en' : {
  //     "hi" : "hi",
  //     "you_are_viewing" : "You are viewing",
  //     "select_the_unit_you_want" : "Select the unit you want to view on the floor map. Click on it to view details & availability",
  //     "master_plan" : "Master Plan",
  //     "available" : "Available",
  //     "filtered" : "Filtered",
  // },
  // 'hi' : {
  //     "hi" : "नमस्ते",
  //     "you_are_viewing" : "आप देख रहे हैं",
  //     "select_the_unit_you_want" : "फ्लोर मैप पर देखना चाहते हुए इकाई का चयन करें। उस पर क्लिक करके विवरण और उपलब्धता देखें।",
  //     "master_plan" : "मास्टर प्लान",
  //     "available" : "उपलब्ध",
  //     "filtered" : "फिल्टर",
  // },
  // 'mr' : {
  //     "hi" : "नमस्कार",
  //     "you_are_viewing" : "तुम्ही पहात आहात",
  //     "select_the_unit_you_want" : "फ्लोर मैप पर देखना चाहते हुए इकाई का चयन करें। उस पर क्लिक करके विवरण और उपलब्धता देखें।",
  //     "master_plan" : "मास्टर प्लान",
  //     "available" : "उपलब्ध",
  //     "filtered" : "फिल्टर",
  // }
};

export type LanguageTypes = 'en' | 'hi' | 'mr';

export const SUPPORTING_LANGUAGES: string[] = ['en', 'hi', 'mr'];

export const getLanguageText = (lang: LanguageTypes, text: string): string => {
  let useLanguage = lang;
  try {
    if (!SUPPORTING_LANGUAGES.includes(lang)) {
      useLanguage = 'en';
    }
    const lowercaseText = text.toLocaleLowerCase();
    const value = MULTILINGUAL_TEXTS[lowercaseText][useLanguage];
    return value;
  } catch (error) {
    // console.log(
    //   'selected langauge is: ' + useLanguage + ' for text: ' + text,
    //   error
    // );
    return text;
  }
};
