import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import Button from 'apps/booking/components/common/buttons/Button';
import FileUploadNew from 'apps/booking/components/common/inputs/FileUploadNew';
import MultiKeyValueInput from 'apps/booking/components/common/inputs/MultiKeyValueInput';
import MultiTextInput from 'apps/booking/components/common/inputs/MultiTextInput';
import TextInput from 'apps/booking/components/common/inputs/TextInput';
import useToast from 'hooks/useToast';
import { useUpdateProjectDetailsMutation } from 'apps/admin/services/projectsAPISlice';
import {
  IProject,
  IProjectReq,
} from 'apps/admin/components/pages/SuperAdminDashboard/SuperAdminProjectList';
import useUpdateProjectDetails from 'apps/admin/components/pages/SuperAdminDashboard/SuperAdminProjectList/modals/UpdateProjectDetailsModal/hooks/useUpdateProjectDetails';
import CollateralsInput from 'apps/admin/components/pages/SuperAdminDashboard/SuperAdminProjectList/modals/UpdateProjectDetailsModal/Inputs/CollateralsInput';
import NearByAreasInput from 'apps/admin/components/pages/SuperAdminDashboard/SuperAdminProjectList/modals/UpdateProjectDetailsModal/Inputs/NearByAreasInput';
import DropDownInput from 'apps/booking/components/common/inputs/DropDownInput';

import styles from './styles.module.css';

interface IProjectUpdateDetailsBodyProps {
  project: IProject;
  onProjectDetailsUpdate: (updatedProjectDetails: IProject) => void;
}

const addLeadFlagOptions = [
  { value: 'true', displayText: 'TRUE' },
  { value: 'false', displayText: 'FALSE' },
];

const ProjectUpdateDetailsBody = (props: IProjectUpdateDetailsBodyProps) => {
  const { project, onProjectDetailsUpdate } = props;

  const [updateDisabled, setUpdateDisabled] = useState(false);
  const [fileUploadingStatus, setFileUploadingStatus] = useState<{
    [fileId: string]: boolean;
  }>({});
  const {
    register,
    unregister,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });

  const [addToast] = useToast();
  const [updateProjectDetails] = useUpdateProjectDetailsMutation();

  const {
    posterImageUrls,
    galleryImageUrls,
    featureKeyValueStrings,
    priceRangeKeyValueStrings,
    unitTagTypeKeyValueStrings,
    nearByAreaKeyValueStrings,
    collateralTitles,
    handleAddGalleryImage,
    handleAddAmenity,
    handleRemoveGalleryImage,
    handleAddUnitTagType,
    handleRemoveUnitTagType,
    handleRemoveAmenity,
    handleAddArea,
    handleRemoveArea,
    handleAddFeature,
    handleRemoveFeature,
    handleAddPosterImage,
    handleRemovePosterImage,
    handleAddUpdateCollateral,
    handleRemoveCollateral,
    handleAddConfig,
    handleAddEoiType,
    handleRemoveEoiType,
    handleRemoveConfig,
    handleAddPriceRange,
    handleRemovePriceRange,
    updatedAmenities,
    updatedFeatures,
    updatedUnitTagType,
    updatedGallery,
    updatedNearByAreas,
    updatedPosters,
    updatedCollaterals,
    updatedConfigs,
    updatedEoiType,
    updatedPriceRanges,
  } = useUpdateProjectDetails({ project });

  const handleUpdateProjectDetails = async (
    projectDetailsData: IProjectReq
  ) => {
    const eoiEnabled = projectDetailsData.eoiEnabledTemp;
    delete projectDetailsData.eoiEnabledTemp;
    const projectDetailsUpdatedData = {
      ...projectDetailsData,
      posters: updatedPosters,
      gallery: updatedGallery,
      amenities: updatedAmenities,
      features: updatedFeatures,
      nearByAreas: updatedNearByAreas,
      collaterals: updatedCollaterals,
      configs: updatedConfigs,
      priceRanges: updatedPriceRanges,
      unitTagMapping: updatedUnitTagType,
      eoi: { enabled: eoiEnabled, types: updatedEoiType },
    };
    try {
      const updatedProjectDetails = await updateProjectDetails({
        ...projectDetailsUpdatedData,
        projectId: project.id,
      }).unwrap();
      onProjectDetailsUpdate(updatedProjectDetails.data);
      addToast({
        type: 'SUCCESS',
        primaryMessage: 'Project successfully Updated',
      });
    } catch (err) {
      const errMsg = (err as any).data.message;
      addToast({
        type: 'ERROR',
        primaryMessage: errMsg,
        secondaryMessage: 'Project creation failed',
      });
    }
  };

  const toogleFileUploadStatus = (
    fileId: string,
    isUploadInProgress: boolean
  ) => {
    setFileUploadingStatus((prevState) => {
      return {
        ...prevState,
        [fileId]: isUploadInProgress,
      };
    });
  };

  useEffect(() => {
    const isUploading = Object.values(fileUploadingStatus).some(
      (status) => status
    );
    setUpdateDisabled(isUploading);
  }, [fileUploadingStatus]);

  const getDefaultAddLeadFlag = () => {
    const flag = addLeadFlagOptions.find(
      (option) => option.value == project.addLeadEnabled.toString()
    );
    return flag || addLeadFlagOptions[0];
  };

  const getDefaultEoiBookingFlag = () => {
    const flag = addLeadFlagOptions.find(
      (option) => option.value == project.eoi.enabled.toString()
    );
    return flag || addLeadFlagOptions[0];
  };

  const getDefaultThirdPartyBookingFlag = () => {
    const flag = addLeadFlagOptions.find(
      (option) =>
        option.value == project.booking.thirdPartyIntegrationEnabled.toString()
    );
    return flag || addLeadFlagOptions[0];
  };

  return (
    <>
      <header className={styles.modalHeader}>
        <div className={styles.modalHeaderLeftContent}>
          <h1>Update Project Details!</h1>
          <p>Fill the following information to update Project Details</p>
        </div>
      </header>
      <main>
        <div className={styles.bodyIndividualdRow}>
          <FileUploadNew
            placeholder='Upload Thumbnail'
            label='Project Thumbnail'
            defaultValue={project.thumbnailUrl}
            s3DirPath={`projects/${project.id}/thumbnail`}
            onChangeFileUploadStatus={toogleFileUploadStatus}
            register={register}
            unregister={unregister}
            name='thumbnailUrl'
            setValue={setValue}
            errorMessage={errors.thumbnailUrl}
            appType='RELATA'
          />
        </div>

        <div className={styles.bodyIndividualdRow}>
          <FileUploadNew
            placeholder='Upload poster images'
            label='Project posters'
            s3DirPath={`projects/${project.id}/posters`}
            onChangeFileUploadStatus={toogleFileUploadStatus}
            appType='RELATA'
            multiple
            onMultipleFileUpload={handleAddPosterImage}
            showDisplayInputs
            displayInputs={posterImageUrls}
            onRemove={handleRemovePosterImage}
          />
        </div>

        <div className={styles.bodyIndividualdRow}>
          <FileUploadNew
            placeholder='Upload gallery images'
            label='Project gallery images'
            s3DirPath={`projects/${project.id}/gallery`}
            onChangeFileUploadStatus={toogleFileUploadStatus}
            appType='RELATA'
            multiple
            onMultipleFileUpload={handleAddGalleryImage}
            showDisplayInputs
            displayInputs={galleryImageUrls}
            onRemove={handleRemoveGalleryImage}
          />
        </div>

        <div className={styles.bodyIndividualdRow}>
          <TextInput
            placeHolder='About the Project'
            label='About the project'
            defaultValue={project.about}
            register={register}
            setValue={setValue}
            unregister={unregister}
            name='about'
            errorMessage={errors.about}
          />
        </div>

        <MultiTextInput
          label='Amenities'
          placeHolder='Add amenities'
          onAdd={handleAddAmenity}
          onRemove={handleRemoveAmenity}
          displayInputs={updatedAmenities}
        />
        <div className={styles.inputsContainer}>
          <DropDownInput
            lable='ADD Lead Flag'
            options={addLeadFlagOptions}
            setValue={setValue}
            defaultSelectedOption={getDefaultAddLeadFlag()}
            name='addLeadEnabled'
          />
        </div>

        <MultiKeyValueInput
          keyLabel='Feature'
          keyPlaceholder='Add Feature'
          valueLabel='Value'
          valuePlaceholder='Add Value'
          onAdd={handleAddFeature}
          onRemove={handleRemoveFeature}
          displayInputs={featureKeyValueStrings}
        />

        <MultiTextInput
          placeHolder='Add Project Configs'
          label='Project Configs'
          onAdd={handleAddConfig}
          onRemove={handleRemoveConfig}
          displayInputs={updatedConfigs}
        />

        <MultiKeyValueInput
          keyLabel='Price Ranges'
          keyPlaceholder='Add min value'
          valueLabel=' '
          valuePlaceholder='Add max value'
          onAdd={handleAddPriceRange}
          onRemove={handleRemovePriceRange}
          displayInputs={priceRangeKeyValueStrings}
          type='number'
        />
        <div className={styles.formDividerLineContainer}></div>
        <div className={styles.bodyIndividualdRow}>
          <div className={styles.inputsContainer}>
            <TextInput
              label='Google Maps Location Url'
              placeHolder='Add Google Maps url'
              defaultValue={project.location?.googleMapUrl}
              register={register}
              setValue={setValue}
              unregister={unregister}
              name='location.googleMapUrl'
              errorMessage={errors.location?.googleMapUrl}
            />
          </div>
          <div className={styles.inputsContainer}>
            <TextInput
              label='Google Share Url'
              placeHolder='Add Google Share url'
              defaultValue={project.location?.googleMapShareUrl}
              register={register}
              setValue={setValue}
              unregister={unregister}
              name='location.googleMapShareUrl'
              errorMessage={errors.location?.googleMapShareUrl}
            />
          </div>
          <div className={styles.inputsContainer}>
            <TextInput
              label='Location Name'
              placeHolder='Add location name'
              defaultValue={project.location?.name}
              register={register}
              setValue={setValue}
              unregister={unregister}
              name='location.name'
              errorMessage={errors.location?.name}
            />
          </div>
        </div>
        <div className={styles.bodyIndividualdRow}>
          <div className={styles.inputsContainer}>
            <TextInput
              label='Plot & Sector Number'
              placeHolder='Add Plot & Sector Number'
              defaultValue={project.location?.address?.flatNumber}
              register={register}
              setValue={setValue}
              unregister={unregister}
              name='location.address.flatNumber'
              errorMessage={errors.location?.address?.flatNumber}
            />
          </div>
          <div className={styles.inputsContainer}>
            <TextInput
              label='Street Name'
              placeHolder='Add street Name'
              defaultValue={project.location?.address?.street}
              register={register}
              setValue={setValue}
              unregister={unregister}
              name='location.address.street'
              errorMessage={errors.location?.address?.street}
            />
          </div>
          <div className={styles.inputsContainer}>
            <TextInput
              label='Locality Name'
              placeHolder='Add Locality Name'
              defaultValue={project.location?.address?.landmark}
              register={register}
              setValue={setValue}
              unregister={unregister}
              name='location.address.landmark'
              errorMessage={errors.location?.address?.landmark}
            />
          </div>
        </div>
        <div className={styles.bodyIndividualdRow}>
          <div className={styles.inputsContainer}>
            <TextInput
              label='City Name'
              placeHolder='Add City Name'
              defaultValue={project.location?.address?.city}
              register={register}
              setValue={setValue}
              unregister={unregister}
              name='location.address.city'
              errorMessage={errors.location?.address?.city}
            />
          </div>
          <div className={styles.inputsContainer}>
            <TextInput
              label='State Name'
              placeHolder='Add State Name'
              defaultValue={project.location?.address?.state}
              register={register}
              setValue={setValue}
              unregister={unregister}
              name='location.address.state'
              errorMessage={errors.location?.address?.state}
            />
          </div>
          <div className={styles.inputsContainer}>
            <TextInput
              label='Pincode'
              placeHolder='Add Pincode'
              defaultValue={project.location?.address?.pinCode}
              register={register}
              setValue={setValue}
              unregister={unregister}
              name='location.address.pinCode'
              errorMessage={errors.location?.address?.pinCode}
            />
          </div>
        </div>
        <div className={styles.formDividerLineContainer}></div>

        <MultiTextInput
          label='Eoi Type'
          placeHolder='Add EOI Type'
          onAdd={handleAddEoiType}
          onRemove={handleRemoveEoiType}
          displayInputs={updatedEoiType}
        />

        <MultiKeyValueInput
          keyLabel='Unit Tag Mapping'
          keyPlaceholder='Unit Tag Mapping'
          valueLabel='Value'
          valuePlaceholder='Add Value'
          onAdd={handleAddUnitTagType}
          onRemove={handleRemoveUnitTagType}
          displayInputs={unitTagTypeKeyValueStrings}
        />
        <div className={styles.bodyIndividualdRow}>
          <div className={styles.inputsContainer}>
            <DropDownInput
              lable='Enable EOI Booking'
              options={addLeadFlagOptions}
              setValue={setValue}
              defaultSelectedOption={getDefaultEoiBookingFlag()}
              name='eoiEnabledTemp'
            />
          </div>
          <div className={styles.inputsContainer}>
            <DropDownInput
              lable='Third Party Booking'
              options={addLeadFlagOptions}
              setValue={setValue}
              defaultSelectedOption={getDefaultThirdPartyBookingFlag()}
              name='booking.thirdPartyIntegrationEnabled'
            />
          </div>
          <div className={styles.inputsContainer}>
            <TextInput
              placeHolder='Project Type'
              label='Project Type'
              defaultValue={project.type}
              register={register}
              setValue={setValue}
              unregister={unregister}
              name='type'
              errorMessage={errors.type}
            />
          </div>
        </div>

        <div className={styles.formDividerLineContainer}></div>

        <NearByAreasInput
          onAddArea={handleAddArea}
          nearByAreaKeyValueStrings={nearByAreaKeyValueStrings}
          onRemoveArea={handleRemoveArea}
        />

        <div className={styles.formDividerLineContainer}></div>
        <CollateralsInput
          projectId={project.id}
          toogleFileUploadStatus={toogleFileUploadStatus}
          onAddUpdateCollateral={handleAddUpdateCollateral}
          collateralTitles={collateralTitles}
          onRemoveCollateral={handleRemoveCollateral}
          updatedCollaterals={updatedCollaterals}
        />
        <div className={styles.formDividerLineContainer}></div>

        <div className={styles.inputsContainer}>
          <TextInput
            label='Razorpay key Id'
            placeHolder='Add Key Id'
            defaultValue={project.razorpay?.keyId}
            register={register}
            setValue={setValue}
            unregister={unregister}
            name='razorpay.keyId'
            errorMessage={errors.razorpay?.keyId}
          />
        </div>

        <div className={styles.inputsContainer}>
          <TextInput
            label='Razorpay key Secret'
            placeHolder='Add Key Secret'
            defaultValue={project.razorpay?.keySecret}
            register={register}
            setValue={setValue}
            unregister={unregister}
            name='razorpay.keySecret'
            errorMessage={errors.razorpay?.keySecret}
          />
        </div>

        <div className={styles.bodyIndividualdRow}>
          <FileUploadNew
            placeholder='Upload Logo'
            label='Razorpay Gateway logo'
            defaultValue={project.razorpay?.logoUrl}
            s3DirPath={`projects/${project.id}/razorpay/logoUrl`}
            onChangeFileUploadStatus={toogleFileUploadStatus}
            register={register}
            unregister={unregister}
            name='razorpay.logoUrl'
            setValue={setValue}
            errorMessage={errors.razorpay?.logoUrl}
            appType='RELATA'
          />
        </div>

        <div className={styles.formDividerLineContainer}></div>
      </main>
      <footer className={styles.modalFooter}>
        <div className={styles.createProjectBtnContainer}>
          <Button
            type='submit'
            propStyles={`${styles.createProjectBtn}`}
            disabled={updateDisabled}
            onClick={() => {
              handleSubmit(handleUpdateProjectDetails)();
            }}>
            Update Details
          </Button>
        </div>
      </footer>
    </>
  );
};

export default ProjectUpdateDetailsBody;
