import { useSelector } from 'react-redux';

import { getAdminUser } from 'apps/admin/slices/adminSlice';
import { useGetCpUsersByAdminUserIdQuery } from 'apps/cp/services/cpUserAPISlice';
import LeadsDashboardHeader from 'apps/admin/components/pages/ActiveLeads/LeadsHeader';
import ActiveCpListAndPendingCpApprovalBody from 'apps/admin/components/pages/ActiveCpListAndPendingCpApproval/ActiveCpListAndPendingCpApprovalBody';

const ActiveCpListAndPendingCpApproval = () => {
  const adminUser = useSelector(getAdminUser);

  const { data: cpUsers = [], isLoading } = useGetCpUsersByAdminUserIdQuery(
    adminUser?.id
  );

  const LeadsHeaderData = {
    projectName: 'Channel Partners',
    brandName: '',
    LeadsCardsData: {
      users: cpUsers.map((cpUser) => ({
        imgUrl: 'https://picsum.photos/70',
        name: cpUser.user.name,
        role: 'CP',
        id: cpUser.id,
      })) as any,
      showNumberOfTiles: 3,
    },
    summaries: [],
  };
  return (
    <>
      <header>
        <LeadsDashboardHeader {...LeadsHeaderData} />
      </header>

      <main>
        <ActiveCpListAndPendingCpApprovalBody cpUsers={cpUsers} />
      </main>
    </>
  );
};

export default ActiveCpListAndPendingCpApproval;
