import { createSlice } from '@reduxjs/toolkit';

import {
  computeSelectedPreferences,
  constructFilterPreferences,
} from 'apps/booking/components/unitSelection/utils';
import {
  getUpdatedUnitList,
  updateUnitStatusByAppliedFilters,
  updateSectionBlockDropdownOptionsAvailableUnitsCnt,
  updateTowerDropdownOptionsAvailableUnitsCnt,
} from 'apps/booking/slices/unitSelectionHeader/utils';
import { NOT_FOUND } from 'constants/status';

export const UNIT_SELECTION_HEADER_NAV_STATE = {
  MASTER: 'MASTER',
  TOWER: 'TOWER',
};
export const DEFAULT_SELECTED_TOWER = 'towerName';
export const DEFAULT_SELECTED_SECTION = 'section';
export const DEFAULT_SELECTED_BLOCK = 'blockName';

export const unitSelectionHeaderNavSlice = createSlice({
  name: 'unitSelectionHeaderNav',
  initialState: {
    unitSelectionHeaderNavState: UNIT_SELECTION_HEADER_NAV_STATE.MASTER,
    unitListById: {},
    hierarchyUnitList: {},
    selectedUnitConfig: '',

    unitSelectionNavMenu: {
      towerName: {
        key: 'towerName',
        fallBackText: 'Tower',
        defaultSelectedValue: DEFAULT_SELECTED_TOWER,
        selectedValue: DEFAULT_SELECTED_TOWER,
        options: [
          { value: 'daisy', text: 'Daisy', availableUnitCount: 0 },
          { value: 'elderberry', text: 'Elderberry', availableUnitCount: 0 },
          { value: 'fuchsia', text: 'Fuschia', availableUnitCount: 0 },
          { value: 'hibisicus', text: 'Hibisicus', availableUnitCount: 0 },
          { value: 'gardenia', text: 'Gardenia', availableUnitCount: 0 },
        ],
      },
      section: {
        key: 'section',
        fallBackText: 'Floor',
        defaultSelectedValue: DEFAULT_SELECTED_SECTION,
        selectedValue: DEFAULT_SELECTED_SECTION,
        options: [
          // { value: SECTION_1_12, text: 'Floor 1-12', availableUnitCount: 0 },
          // {
          //   value: SECTION_13_14,
          //   text: 'Floor 13-14',
          //   availableUnitCount: 0,
          // },
        ],
      },
      blockName: {
        key: 'blockName',
        fallBackText: 'Block',
        defaultSelectedValue: DEFAULT_SELECTED_BLOCK,
        selectedValue: DEFAULT_SELECTED_BLOCK,
        options: [],
      },
    },
    selectedPreferences: {},
    filterPreferences: {},
  },
  reducers: {
    // Unit
    setHierarchyUnitList: (state, action) => {
      state.hierarchyUnitList = action.payload;
    },
    setUnitListById: (state, action) => {
      state.unitListById = action.payload;

      updateTowerDropdownOptionsAvailableUnitsCnt(
        state.unitSelectionNavMenu,
        state.unitListById
      );
      updateSectionBlockDropdownOptionsAvailableUnitsCnt(
        state.unitSelectionNavMenu,
        state.unitListById
      );
    },
    setSelectedUnitConfig: (state, action) => {
      state.selectedUnitConfig = action.payload;
    },

    // Unit Selection Nav Menu
    setUnitSelectionHeaderNavState: (state, action) => {
      state.unitSelectionHeaderNavState = action.payload;

      // Set default values
      if (
        state.unitSelectionHeaderNavState ===
        UNIT_SELECTION_HEADER_NAV_STATE.MASTER
      ) {
        for (const type in state.unitSelectionNavMenu) {
          state.unitSelectionNavMenu[type].selectedValue =
            state.unitSelectionNavMenu[type].defaultSelectedValue;
        }
      }
    },

    updateUnitSelectionNavMenu: (state, action) => {
      updateTowerDropdownOptionsAvailableUnitsCnt(
        state.unitSelectionNavMenu,
        state.unitListById
      );
      updateSectionBlockDropdownOptionsAvailableUnitsCnt(
        state.unitSelectionNavMenu,
        state.unitListById
      );
    },

    // - Tower
    setSelectedTower: (state, action) => {
      const selectedTower = state.unitSelectionNavMenu.towerName.options.find(
        (option) => option.value === action.payload
      );
      if (selectedTower && !selectedTower.availableUnitCount) {
        return;
      }

      state.unitSelectionHeaderNavState = UNIT_SELECTION_HEADER_NAV_STATE.TOWER;

      state.unitSelectionNavMenu.towerName.selectedValue = action.payload;

      updateSectionBlockDropdownOptionsAvailableUnitsCnt(
        state.unitSelectionNavMenu,
        state.unitListById
      );

      const selectedSectionOption =
        state.unitSelectionNavMenu.section.options.find(
          (option) => option.availableUnitCount
        );
      if (!selectedSectionOption) {
        return;
      }
      state.unitSelectionNavMenu.section.selectedValue =
        selectedSectionOption.value;

      state.filterPreferences = constructFilterPreferences(
        getUpdatedUnitList(state)
      );

      const selectedBlockOption =
        state.unitSelectionNavMenu.blockName.options.find(
          (option) => option.availableUnitCount
        );
      if (!selectedBlockOption) {
        return;
      }
      state.unitSelectionNavMenu.blockName.selectedValue =
        selectedBlockOption.value;
    },

    // - Section
    setSelectedSection: (state, action) => {
      state.unitSelectionNavMenu.section.selectedValue = action.payload;

      updateSectionBlockDropdownOptionsAvailableUnitsCnt(
        state.unitSelectionNavMenu,
        state.unitListById
      );

      state.filterPreferences = constructFilterPreferences(
        getUpdatedUnitList(state)
      );
    },

    // - Block
    setSelectedBlock: (state, action) => {
      state.unitSelectionNavMenu.blockName.selectedValue = action.payload;

      updateSectionBlockDropdownOptionsAvailableUnitsCnt(
        state.unitSelectionNavMenu,
        state.unitListById
      );

      state.filterPreferences = constructFilterPreferences(
        getUpdatedUnitList(state)
      );
    },

    // Filter
    setFilterPreferences: (state, action) => {
      state.filterPreferences = action.payload;
    },
    toggleFilterPreferenceItem: (state, action) => {
      const { key, itemIndex } = action.payload;
      state.filterPreferences[key].items[itemIndex].isSelected =
        !state.filterPreferences[key].items[itemIndex].isSelected;

      const { isSelected } = state.filterPreferences[key].items[itemIndex];

      state.selectedPreferences = computeSelectedPreferences(
        state.filterPreferences
      );
      const { selectedPreferences } = state;
      updateUnitStatusByAppliedFilters(
        selectedPreferences,
        state.filterPreferences,
        isSelected,
        key,
        state.unitListById
      );
    },
    clearFilterPreferences: (state) => {
      Object.values(state.filterPreferences).forEach((preference) =>
        Object.values(preference.items).forEach(
          (item) => (item.isSelected = false)
        )
      );

      state.selectedPreferences = computeSelectedPreferences(
        state.filterPreferences
      );
      const { selectedPreferences } = state;
      updateUnitStatusByAppliedFilters(
        selectedPreferences,
        state.filterPreferences,
        false,
        null,
        state.unitListById
      );
    },
    resetFilterPreferenceType: (state, action) => {
      const preferenceType = action.payload;
      Object.values(state.filterPreferences[preferenceType].items).forEach(
        (item) => (item.isSelected = false)
      );

      state.selectedPreferences = computeSelectedPreferences(
        state.filterPreferences
      );
      const { selectedPreferences } = state;
      updateUnitStatusByAppliedFilters(
        selectedPreferences,
        state.filterPreferences,
        false,
        preferenceType,
        state.unitListById
      );
    },
  },
});

export const {
  // Unit
  setHierarchyUnitList,
  setUnitListById,
  setSelectedUnitConfig,

  // Unit Selection Nav Menu
  setUnitSelectionHeaderNavState,
  setSelectedTower,
  setSelectedSection,
  setSelectedBlock,
  updateUnitSelectionNavMenu,

  // Filter
  setFilterPreferences,
  toggleFilterPreferenceItem,
  clearFilterPreferences,
  resetFilterPreferenceType,
} = unitSelectionHeaderNavSlice.actions;

// Unit
export const getHierarchyUnitList = (state) =>
  state.unitSelectionHeaderNav.hierarchyUnitList;
export const getUnitListById = (state) =>
  state.unitSelectionHeaderNav.unitListById;
export const getSelectedUnitConfig = (state) =>
  state.unitSelectionHeaderNav.selectedUnitConfig;

// Unit Selection Nav Menu
export const getUnitSelectionHeaderNavState = (state) =>
  state.unitSelectionHeaderNav.unitSelectionHeaderNavState;
export const getUnitSelectionNavMenu = (state) =>
  state.unitSelectionHeaderNav.unitSelectionNavMenu;

export const getTowerBtnState = (state) =>
  state.unitSelectionHeaderNav.unitSelectionNavMenu.towerName;
export const getSelectedTower = (state) => {
  return state.unitSelectionHeaderNav.unitSelectionNavMenu.towerName
    .selectedValue;
};
export const getTotalAvailableUnitCount = (state) =>
  state.unitSelectionHeaderNav.unitSelectionNavMenu.towerName.options.reduce(
    (count, option) => count + option.availableUnitCount,
    0
  );
export const getSelectedTowerAvailableUnitCount = (state) => {
  const selectedTower = getSelectedTower(state);
  const selectedOption =
    state.unitSelectionHeaderNav.unitSelectionNavMenu.towerName.options.find(
      (option) => option.value === selectedTower
    );

  return selectedOption ? selectedOption.availableUnitCount : NOT_FOUND;
};

export const getSectionBtnState = (state) =>
  state.unitSelectionHeaderNav.unitSelectionNavMenu.section;
export const getSelectedSection = (state) =>
  state.unitSelectionHeaderNav.unitSelectionNavMenu.section.selectedValue;
export const getSelectedSectionAvailableUnitCount = (state) => {
  const selectedSection = getSelectedSection(state);
  const selectedOption =
    state.unitSelectionHeaderNav.unitSelectionNavMenu.section.options.find(
      (option) => option.value === selectedSection
    );

  return selectedOption ? selectedOption.availableUnitCount : NOT_FOUND;
};

export const getBlockBtnState = (state) =>
  state.unitSelectionHeaderNav.unitSelectionNavMenu.blockName;
export const getSelectedBlock = (state) =>
  state.unitSelectionHeaderNav.unitSelectionNavMenu.blockName.selectedValue;
export const getSelectedBlockAvailableUnitCount = (state) => {
  const selectedBlock = getSelectedBlock(state);
  const selectedOption =
    state.unitSelectionHeaderNav.unitSelectionNavMenu.blockName.options.find(
      (option) => option.value === selectedBlock
    );

  return selectedOption ? selectedOption.availableUnitCount : NOT_FOUND;
};
// Filter
export const getFilterPreferences = (state) =>
  state.unitSelectionHeaderNav.filterPreferences;
export const getSelectedPreferences = (state) =>
  state.unitSelectionHeaderNav.selectedPreferences;
