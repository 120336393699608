import { Route, Switch, useRouteMatch } from 'react-router-dom';

import { UserRole } from 'constants/userRoles';
import ActiveCpListAndPendingCpApproval from 'apps/admin/components/pages/ActiveCpListAndPendingCpApproval';
import OrganizationsList from 'apps/admin/components/pages/SuperAdminDashboard/CompanyList';
import CollaboratorsList from 'apps/admin/components/pages/CollaboratorsList';
import AllFirms from 'apps/admin/components/pages/AllFirms';
import OrganisationsPartners from 'apps/admin/components/pages/OrganisationsPartners';
import UserListByRole from 'apps/admin/components/pages/UserListByRole';

const OrganisationRoutes = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}/`}>
        <OrganizationsList />
      </Route>

      <Route exact path={`${path}/partners`}>
        <ActiveCpListAndPendingCpApproval />
      </Route>

      <Route exact path={`${path}/collaborators`}>
        <CollaboratorsList />
      </Route>

      <Route exact path={`${path}/partner-firms`}>
        <AllFirms />
      </Route>

      <Route path={`${path}/admins`}>
        <UserListByRole userRole={UserRole.ADMIN} />
      </Route>

      <Route path={`${path}/executives`}>
        <UserListByRole userRole={UserRole.EXECUTIVE} />
      </Route>

      <Route path={`${path}/channel-partners`}>
        <OrganisationsPartners />
      </Route>
    </Switch>
  );
};

export default OrganisationRoutes;
