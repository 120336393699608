import { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';

import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { DropDown } from '../DropDown';
import {
  titleDropDown,
  applicantTypeDropDown,
  countryCodeDropDown,
  nationalityCodeDropDown,
  residentIndiaDropDown,
  marriageStatusDropDown,
  educationalQualificationDropDown,
} from '../../../../utils/applicantFormDropDownOptions';
import {
  SAVE_ICON,
  EIDT_ICON,
  DELETE_ICON,
  PRIMARY_APPLICANT_ICON,
  SECONDARY_APPLICANT_ICON,
} from '../../../../constants/icons';
import { userProfileDefaultImage } from 'constants/urls';
import { APPLICANT_TYPE, PERSON_TITLE } from 'apps/booking/constants';
import FileUploadNew from 'apps/booking/components/common/inputs/FileUploadNew/index';
import { getProject } from 'apps/booking/slices/projectSlice';
import { randomId } from 'utils/utils';

// Validation
const schema = yup.object().shape({
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  fatherName: yup.string().required(),
  email: yup.string().email().required(),
  mobile: yup
    .string()
    .matches(/^[0-9]*$/)
    .required(),
  dob: yup.date().required(),
  panNumber: yup.string().required().length(10),
  aadharNumber: yup.string().length(12).required(),
  nationality: yup.string().required(),
});

const KycApplicantFrom = (props) => {
  const {
    register,
    unregister,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const {
    handleDelete,
    handleMakePrimaryApplicant,
    handleSave,
    isPrimary,
    isEditFormOpenByDefault = false, // Only new applicant will have isEditFormOpenByDefault = true
    updateIsEditFormOpenStatus,
  } = props;

  const {
    id: applicantId,
    firstName, //Yes
    // mname = '',
    lastName = '', //Yes
    fatherName = '', //Yes
    mobile, //Yes
    dob, //Yes
    email, //Yes
    panNumber, //Yes
    panDocUrl,
    aadharNumber, //Yes
    aadharDocUrl,
    educationalQualification,
    profession,
    companyName,
    designation,
    marriageStatus,
    // passport_no,
    indianResident,
    title = PERSON_TITLE.MR,
    // embassy_coapplicant_id,
  } = props?.applicant;

  const [isEditFormOpen, setIsEditFormOpen] = useState(isEditFormOpenByDefault);
  const [fileUploadingStatuses, setFileUploadingStatuses] = useState({});
  const submitBtnEnabled = Object.values(fileUploadingStatuses).every(
    (status) => !status
  );

  const type = isPrimary ? APPLICANT_TYPE.PRIMARY : APPLICANT_TYPE.SECONDARY;
  const countryCode = props.applicant.countryCode || '91';
  const nationality = props.applicant.nationality || 'Indian'; //Yes

  // Watch dropdown values
  const selectedTitleValue = watch('title', title);
  const selectedTypeValue = watch('type', type);
  const selectedCountrycodeValue = watch('countryCode', countryCode);
  const selectedNationalityValue = watch('nationality', nationality);
  const selectedIsResidentOfIndexValue = watch(
    'indianResident',
    indianResident
  );
  const selectedEducationalqualificationValue = watch(
    'educationalQualification',
    educationalQualification
  );
  const selectedMarriageStatusValue = watch('marriageStatus', marriageStatus);
  // Upload file urls
  const selectedAdharDocUrlValue = watch('aadharDocUrl', aadharDocUrl);
  const selectedPanDocUrlValue = watch('panDocUrl', panDocUrl);

  const project = useSelector(getProject);

  const {
    _id: _id,
    createdAt: createdAt,
    __v: version,
    ...applicantDefaultValues
  } = props?.applicant; // destructoring in such way that defaultValue does not contain version number __v, createdAt, _id

  useEffect(() => {
    register('firstName', { value: firstName });
    register('lastName', { value: lastName });
    register('fatherName', { value: fatherName });
    register('aadharNumber', { value: aadharNumber });
    register('panNumber', { value: panNumber });
    register('dob', { value: formattedDob(dob) });
    // Register dropdown values
    register('title', { value: title });
    register('type', { value: type });
    register('countryCode', { value: countryCode });
    register('nationality', { value: nationality });
    register('indianResident', { value: indianResident });
    register('marriageStatus', { value: marriageStatus });
    register('eduactional_qualification', { value: educationalQualification });
    // Upload file urls
    register('aadharDocUrl', { value: aadharDocUrl });
    register('panDocUrl', { value: panDocUrl });
  }, [
    register,
    title,
    type,
    countryCode,
    nationality,
    indianResident,
    marriageStatus,
    educationalQualification,
    aadharDocUrl,
    panDocUrl,
    firstName,
    lastName,
    fatherName,
    aadharNumber,
    dob,
  ]);

  useEffect(() => {
    updateIsEditFormOpenStatus(applicantId, isEditFormOpen);
  }, [applicantId, isEditFormOpen, updateIsEditFormOpenStatus]);

  const handleDropDownSelect = (name, value) => {
    setValue(name, value, true);
  };

  const onSubmit = (data) => {
    setIsEditFormOpen(false);
    handleSave({ ...data, id: applicantId });
  };

  const formattedDob = (dob) => {
    let dobDate;
    if (dob) {
      dobDate = new Date(dob);
    } else {
      return;
    }
    return `${dobDate.getFullYear().toString().padStart(4, '0')}-${(
      dobDate.getMonth() + 1
    )
      .toString()
      .padStart(2, '0')}-${dobDate.getDate().toString().padStart(2, '0')}`;
  };

  return (
    <form className='kyc-applicant' onSubmit={handleSubmit(onSubmit)}>
      <header className='kyc-applicant__header'>
        <div className='kyc-applicant__header-title'>
          <div className='thumb'>
            <img className='img-fluid' src={userProfileDefaultImage} alt='' />
          </div>
          <div className='kyc-applicant-name-container'>
            {firstName && lastName && (
              <span className='kyc-applicant-name c-56 f-20 f-bold'>{`${firstName} ${lastName}`}</span>
            )}
            <span className='kyc-applicant-type f-15'>
              {type === APPLICANT_TYPE.PRIMARY
                ? 'Primary Applicant'
                : 'Co-Applicant'}
            </span>
          </div>
        </div>
        <div className='kyc-applicant__header-action-container c-56'>
          {/* Disabled due to time contraint */}
          {/* <div className="kyc-applicant-pregress-complete">
            <span>Completed</span> <span className="f-bold">10/10</span>
          </div> */}
          <div
            className='kyc-applicant-edit click-enabled'
            onClick={() => {
              if (isEditFormOpen) {
                handleSubmit(onSubmit)();
              } else {
                setIsEditFormOpen(true);
              }
            }}>
            <span className='icon-container'>
              <span className='icon material-icons'>
                {isEditFormOpen ? SAVE_ICON : EIDT_ICON}
              </span>
            </span>
            {isEditFormOpen ? 'Save Info' : 'Edit Info'}
          </div>
          <span
            className={`icon-container ${handleDelete && 'click-enabled'} ${
              !handleDelete && 'click-disabled'
            }`}>
            <span
              className='icon material-icons icon-delete'
              onClick={() => handleDelete(applicantId)}>
              {DELETE_ICON}
            </span>
          </span>
          <span
            className='icon-container click-disabled'
            onClick={() => handleMakePrimaryApplicant(applicantId)}>
            <span className='icon material-icons'>
              {type === APPLICANT_TYPE.PRIMARY
                ? PRIMARY_APPLICANT_ICON
                : SECONDARY_APPLICANT_ICON}
            </span>
          </span>
        </div>
      </header>
      <div className={`kyc-applicant__body ${!isEditFormOpen && 'hide'}`}>
        <section className='kyc-applicant-info-section'>
          <div className='kyc-applicant-info-section__title'>
            <span className='c-accent f-18'>Basic Information</span>
            <div className='kyc-applicant-info-section__title-dot-line'></div>
          </div>
          <div className='kyc-applicant-info-section__body'>
            <div className='input input--secondary'>
              <label className='input_label'>First name *</label>
              <div>
                <DropDown
                  cname={titleDropDown.cname}
                  defaultValue={selectedTitleValue}
                  options={titleDropDown.options}
                  handleDropDownSelect={(value) =>
                    handleDropDownSelect('title', value)
                  }
                />
                <input
                  type='text'
                  className={`input_field`}
                  {...register('firstName')}
                  defaultValue={firstName}
                />
              </div>
              <span className='input__error'>
                {errors.firstName && 'First name is required'}
              </span>
            </div>

            <div className='input'>
              <label className='input_label'>Last name *</label>
              <input
                type='text'
                className={`input_field`}
                {...register('lastName')}
                defaultValue={lastName}
              />
              <span className='input__error'>
                {errors.lastName && 'Last name is required'}
              </span>
            </div>
            <div className='input'>
              <label className='input_label'>Email ID *</label>
              <input
                type='text'
                className={`input_field ${isPrimary && 'click-disabled'}`}
                {...register('email')}
                defaultValue={email}
              />
              <span className='input__error'>
                {errors.email && 'Email ID required'}
              </span>
            </div>
            <div className='input input--secondary'>
              <label className='input_label'>Mobile number *</label>
              <div>
                <DropDown
                  cname={countryCodeDropDown.cname}
                  defaultValue={selectedCountrycodeValue}
                  options={countryCodeDropDown.options}
                  handleDropDownSelect={(value) =>
                    handleDropDownSelect('countryCode', value)
                  }
                />
                <input
                  type='number'
                  className={`input_field`}
                  {...register('mobile')}
                  defaultValue={mobile}
                />
              </div>
              <span className='input__error'>
                {errors.mobile && 'Please enter valid mobile number'}
              </span>
            </div>
            <div className='input'>
              <label className='input_label'>DOB *</label>
              <input
                type='date'
                className='input_field'
                {...register('dob')}
                defaultValue={formattedDob(dob)}
              />
              <span className='input__error'>
                {errors.dob && 'DOB required'}
              </span>
            </div>
            <div className='input'>
              <label className='input_label'>Applicant Type</label>
              <DropDown
                cname={`${applicantTypeDropDown.cname} click-disabled`}
                defaultValue={selectedTypeValue}
                options={applicantTypeDropDown.options}
                handleDropDownSelect={(value) =>
                  handleDropDownSelect('type', value)
                }
              />
            </div>
            <div className='input'>
              <label className='input_label'>Fathers/Spouse name *</label>
              <input
                type='text'
                className='input_field'
                {...register('fatherName')}
                defaultValue={fatherName}
              />
              <span className='input__error'>
                {errors.fatherName && 'Fathers/Spouse name required'}
              </span>
            </div>
          </div>
        </section>
        <section className='kyc-applicant-info-section'>
          <div className='kyc-applicant-info-section__title'>
            <span className='c-accent f-18'>Upload Documents</span>
            <div className='kyc-applicant-info-section__title-dot-line'></div>
          </div>
          <div className='kyc-applicant-info-section__body'>
            <div className='input input--first-name'>
              <label className='input_label'>Nationality *</label>
              <DropDown
                cname={nationalityCodeDropDown.cname}
                defaultValue={selectedNationalityValue}
                options={nationalityCodeDropDown.options}
                handleDropDownSelect={(value) =>
                  handleDropDownSelect('nationality', value)
                }
              />
              <span className='input__error'>
                {errors.nationality?.message}
              </span>
            </div>

            <div className='input'>
              <label className='input_label'>Indian Resident *</label>
              <DropDown
                cname={residentIndiaDropDown.cname}
                defaultValue={selectedIsResidentOfIndexValue}
                options={residentIndiaDropDown.options}
                handleDropDownSelect={(value) =>
                  handleDropDownSelect('indianResident', value)
                }
              />
              <span className='input__error'>
                {errors.indianResident?.message}
              </span>
            </div>
            {/* TODO remove block-hide  */}
            <div className='input block-hide'>
              {/* <label className="input_label">Citizen of</label>
              <input
                type="text"
                className="input_field"
                {...register('citizenship')}
                defaultValue={citizenship}
              /> */}
            </div>
            <div className='input'>
              <label className='input_label'>Aadhar Card Number *</label>
              <input
                type='text'
                placeholder='Enter Number here'
                className='input_field'
                {...register('aadharNumber')}
                defaultValue={aadharNumber}
                maxlength='12'
              />
              <span className='input__error'>
                {errors.aadharNumber && '12 digit Aadhar Number required'}
              </span>
            </div>
            <div className='input--upload-width-till-end'>
              <FileUploadNew
                placeholder='Upload Aadhar Card'
                label='Upload Aadhaar Card image'
                s3DirPath={`projects/${
                  project.id
                }/applicants/aadhaarCard/${randomId()}`}
                name='aadharDocUrl'
                register={register}
                unregister={unregister}
                setValue={setValue}
                errorMessage={errors.aadharDocUrl}
                defaultValue={aadharDocUrl}
                appType='BOOKING-ENGINE'
              />
            </div>
            <div className='input'>
              <label className='input_label'>Pan Card Number *</label>
              <input
                type='text'
                placeholder='Enter Number here'
                className='input_field'
                {...register('panNumber')}
                defaultValue={panNumber}
                maxlength='10'
              />

              <span className='input__error'>
                {errors.panNumber && '10 character Pan Number required'}
              </span>
            </div>
            <div className='input--upload-width-till-end'>
              <FileUploadNew
                placeholder='Upload Pan Card'
                label='Upload PAN Card image'
                s3DirPath={`projects/${
                  project.id
                }/applicants/panCard/${randomId()}`}
                name='panDocUrl'
                register={register}
                unregister={unregister}
                setValue={setValue}
                defaultValue={panDocUrl}
                errorMessage={errors.panDocUrl}
                appType='BOOKING-ENGINE'
              />
            </div>
          </div>
        </section>
        {/*disabled for axon, should be dynamically available*/}
        {false && (
          <section className='kyc-applicant-info-section'>
            <div className='kyc-applicant-info-section__title'>
              <span className='c-accent f-18'>Education & Profession</span>
              <div className='kyc-applicant-info-section__title-dot-line'></div>
            </div>
            <div className='kyc-applicant-info-section__body'>
              <div className='input input--first-name'>
                <label className='input_label'>Educational qualification</label>
                <DropDown
                  cname={educationalQualificationDropDown.cname}
                  defaultValue={selectedEducationalqualificationValue}
                  options={educationalQualificationDropDown.options}
                  handleDropDownSelect={(value) =>
                    handleDropDownSelect('educationalQualification', value)
                  }
                />
              </div>
              <div className='input'>
                <label className='input_label'>Profession</label>
                <input
                  type='text'
                  className='input_field'
                  {...register('profession')}
                  defaultValue={profession}
                  placeholder='Enter Profession'
                />
              </div>
              <div className='input'>
                <label className='input_label'>Name of the company</label>
                <input
                  type='text'
                  className='input_field'
                  {...register('companyName')}
                  defaultValue={companyName}
                  placeholder='Enter Name of the company'
                />
              </div>
              <div className='input'>
                <label className='input_label'>Designation</label>
                <input
                  type='text'
                  placeholder='Enter Designation'
                  className='input_field'
                  {...register('designation')}
                  defaultValue={designation}
                />
              </div>
              <div className='input'>
                <label className='input_label'>Marrital Status</label>
                <DropDown
                  cname={marriageStatusDropDown.cname}
                  defaultValue={selectedMarriageStatusValue}
                  options={marriageStatusDropDown.options}
                  handleDropDownSelect={(value) =>
                    handleDropDownSelect('marriageStatus', value)
                  }
                />
              </div>
            </div>
          </section>
        )}

        <footer className='kyc-applicant__footer'>
          <button
            className='btn btn--transparent'
            onClick={() => {
              reset(applicantDefaultValues);
            }}>
            Discard
          </button>
          <input
            type='submit'
            className={`btn ${submitBtnEnabled && 'btn--enabled'} ${
              !submitBtnEnabled && 'btn--disabled'
            }
            `}
          />
        </footer>
      </div>
    </form>
  );
};

export default KycApplicantFrom;
