import { createSlice } from '@reduxjs/toolkit';

export const bookingEngineRoutesSlice = createSlice({
  name: 'bookingEngineRoutesSlice',
  initialState: {
    bookingRoutesCurrentPage: 'unit-selection',
    dashboardRouteCurrentPage: 'dashboard',
  },
  reducers: {
    setBookingRoutesCurrentPage: (state, action) => {
      state.bookingRoutesCurrentPage = action.payload;
    },
    setDashboardRoutesCurrentPage: (state, action) => {
      state.dashboardRouteCurrentPage = action.payload;
    },
  },
});

export const { setBookingRoutesCurrentPage, setDashboardRoutesCurrentPage } =
  bookingEngineRoutesSlice.actions;
export const getBookingRoutesCurrentPage = (state) =>
  state.bookingEngineRoutesSlice.bookingRoutesCurrentPage;
export const getDashboardRoutesCurrentPage = (state) =>
  state.bookingEngineRoutesSlice.dashboardRouteCurrentPage;
