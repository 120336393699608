import FadeInFadeOutAnim from 'apps/booking/components/common/FadeInFadeOutAnim';
import AdminRoutes from 'apps/admin/routers/AdminRoutes';

const AdminContent = () => {
  return (
    <div className='content'>
      <div className='content-body'>
        <FadeInFadeOutAnim width='100%' height='100%'>
          <AdminRoutes />
        </FadeInFadeOutAnim>
      </div>
    </div>
  );
};

export default AdminContent;
