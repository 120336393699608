import { useState } from 'react';

import { GridReadyEvent, ICellRendererParams } from 'ag-grid-community';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';

import { IEoiDetails } from 'apps/admin/interfaces';
import TextInput from 'apps/booking/components/common/inputs/TextInput';
import { awsBaseUrl } from 'constants/urls';
import NameCard from 'apps/booking/components/common/admin/NameCard';
import { DEFAULT_NULL_VALUE } from 'apps/admin/components/common/ExpandedDetailSection';
import { dateToDaythMonthYear } from 'utils/dateFormator';
import ConfirmBookingCell, {
  IConfirmBookingCell,
} from 'apps/admin/components/pages/ProjectEois/ConfirmBookingCell';
import { EoiStatus } from 'constants/status';
import ToggleBookingCell from 'apps/admin/components/pages/ProjectEois/ToogleBookingCell';
import CancelBookingCell, {
  ICancelBookingCell,
} from 'apps/admin/components/pages/ProjectEois/CancelBookingCell';
import UserCkycDetailsCell from 'apps/admin/components/pages/AdminDashboard/AdminExecutiveList/Body/UserListGrid/UserCkycDetailsCell/';

import styles from './styles.module.css';

interface IEoiList {
  eoiList: IEoiDetails[];
  isLoading: boolean;
  tabName?: string;
}

const EoiList = (props: IEoiList) => {
  const { eoiList, isLoading, tabName } = props;

  const [gridApi, setGridApi] = useState(null) as any;

  //AG-grid Default column definition
  const defaultColDef = {
    sortable: false,
    filter: false,
    resizable: false,
    suppressMenu: true,
    editable: false,
    flex: 1,
  };

  // Ag-grid global search
  const handleGridSearch = (event: string) => {
    gridApi.setQuickFilter(event);
  };

  // This function will automatically be called when ag grid is ready
  const onGridReady = (params: GridReadyEvent) => {
    setGridApi(params.api);
  };

  const getUserAvatarUrl = (params: ICellRendererParams) => {
    return `${awsBaseUrl}/common/AZAlphabets/${params.data?.lead.name[0].toUpperCase()}${
      +params.data?.lead.mobile % 5
    }.png`;
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const getEoiStatus = (params: ICellRendererParams) => {
    if (!params.data.status) return DEFAULT_NULL_VALUE;
    if (
      params.data.status == EoiStatus.ACTIVE_BOOKING_DISABLED ||
      params.data.status == EoiStatus.ACTIVE_BOOKING_ENABLED
    )
      return EoiStatus.ACTIVE;
    return params.data.status;
  };

  return (
    <>
      <div className={styles.listWrapper}>
        <header>
          <div className={styles.searchContainer}>
            <TextInput
              type='text'
              inputHTMLElementStyles={styles.searchInput}
              placeHolder='Search Name, Mobile No. & Status'
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                handleGridSearch(event.target.value);
              }}
              startIconName='search'
              startIconVariant='SECONDARY'
            />
          </div>
        </header>

        <div
          className={`ag-theme-alpine ${styles.gridContainer}`}
          style={{ width: '100%', height: '100%' }}>
          <AgGridReact
            onGridReady={onGridReady}
            rowData={eoiList}
            defaultColDef={defaultColDef}
            domLayout={'autoHeight'}
            pagination={true}
            paginationPageSize={10}
            masterDetail={true}
            detailCellRenderer={'detailCellRenderer'}
            animateRows={true}>
            <AgGridColumn
              headerName='Names'
              field='lead.name'
              flex={2}
              cellRendererFramework={(params: ICellRendererParams) => (
                <NameCard
                  {...{
                    imgUrl: `${getUserAvatarUrl(params)}`,
                    title: params.data.lead.name,
                    subtitle: params.data.lead.mobile,
                    disabled: params.data.disabled,
                  }}
                />
              )}
            />
            <AgGridColumn flex={0.5} hide={true} field='lead.mobile' />

            {!tabName && (
              <AgGridColumn
                headerName='Status'
                field='status'
                flex={1}
                cellRendererFramework={(params: ICellRendererParams) => (
                  <p>{getEoiStatus(params)}</p>
                )}
              />
            )}
            <AgGridColumn
              headerName='Type'
              field='type'
              flex={1.5}
              cellRendererFramework={(params: ICellRendererParams) => (
                <p>{params.data.type || DEFAULT_NULL_VALUE}</p>
              )}
            />
            {(tabName === EoiStatus.ACTIVE || !tabName) && (
              <AgGridColumn
                headerName='Category'
                field='unitTag'
                flex={1.5}
                cellRendererFramework={(params: ICellRendererParams) => (
                  <p>{params.data.unitTag || DEFAULT_NULL_VALUE}</p>
                )}
              />
            )}
            {tabName === EoiStatus.BLOCKED ||
            tabName === EoiStatus.BOOKED ||
            tabName === EoiStatus.CANCELLED ? (
              <>
                <AgGridColumn
                  flex={1.2}
                  headerName='Zone'
                  field='unit.towerName'
                  cellRendererFramework={(params: ICellRendererParams) => (
                    <p>{params.data.unit?.towerName || DEFAULT_NULL_VALUE}</p>
                  )}
                />

                <AgGridColumn
                  flex={1.2}
                  headerName='Unit No.'
                  field='unit.number'
                  cellRendererFramework={(params: ICellRendererParams) => (
                    <p>{params.data.unit?.number || DEFAULT_NULL_VALUE}</p>
                  )}
                />
              </>
            ) : null}
            <AgGridColumn
              flex={1.2}
              headerName='Date'
              cellRendererFramework={(params: ICellRendererParams) => (
                <p className={styles.cpName}>
                  {params.data.createdAt
                    ? dateToDaythMonthYear(new Date(params.data.createdAt))
                    : DEFAULT_NULL_VALUE}
                </p>
              )}
            />
            <AgGridColumn
              flex={1.2}
              headerName='CM'
              field='cm'
              cellRendererFramework={(params: ICellRendererParams) => (
                <p className={styles.cpName}>
                  {params.data.executive.name || DEFAULT_NULL_VALUE}
                </p>
              )}
            />
            {tabName == EoiStatus.ACTIVE && (
              <AgGridColumn
                headerName='Enable Booking'
                flex={1.5}
                cellRendererFramework={ToggleBookingCell}
                cellRendererParams={(params: ICellRendererParams) => {
                  return {
                    rowData: params,
                  };
                }}
              />
            )}
            {tabName == EoiStatus.BLOCKED && (
              <>
                <AgGridColumn
                  flex={1.5}
                  headerName='Confirm Booking'
                  cellRendererFramework={ConfirmBookingCell}
                  cellRendererParams={(
                    params: ICellRendererParams
                  ): IConfirmBookingCell => ({
                    eoiLead: params.data as IEoiDetails,
                  })}
                />

                {/* <AgGridColumn
                  flex={1.5}
                  headerName='Cancel Booking'
                  cellRendererFramework={CancelBookingCell}
                  cellRendererParams={(
                    params: ICellRendererParams
                  ): ICancelBookingCell => ({
                    eoiLead: params.data as IEoiDetails,
                  })}
                /> */}
              </>
            )}
            {tabName === EoiStatus.BLOCKED ||
            tabName === EoiStatus.BOOKED ||
            tabName === EoiStatus.CANCELLED ? (
              <AgGridColumn
                flex={0.8}
                headerName='cKYC'
                cellRendererFramework={UserCkycDetailsCell}
                cellRendererParams={(params: ICellRendererParams) => ({
                  applicants: params.data?.unitCart?.applicants,
                })}
              />
            ) : null}
          </AgGridReact>
        </div>
      </div>
    </>
  );
};

export default EoiList;
