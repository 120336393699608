import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import useBlockedUnitExpireCountdown from '../../hooks/useBlockedUnitExpireCountdown';

import { isEmpty } from '../../../../utils/utils';

import { setUnitCart } from '../../slices/selectedUnit';

import { BLOCKED, UNIT_STATUSES } from '../../../../constants/status';
import { URLS } from '../../../../constants/urls';
import { useDeleteUnitCartMutation } from '../../../../api/unitCart';
import { getProject } from 'apps/booking/slices/projectSlice';
import { setBookingRoutesCurrentPage } from 'apps/booking/routers/BookingRoutes/slice';

const SummaryCard = (props) => {
  const { unitCart } = props;

  const project = useSelector(getProject);
  const history = useHistory();
  const dispatch = useDispatch();

  const hourMinSecCountDown = useBlockedUnitExpireCountdown(unitCart);
  const [deleteUnitCartAPI] = useDeleteUnitCartMutation();

  const isUnitCartBlocked = unitCart.unit.status === BLOCKED;
  const isUnitExpired =
    !isEmpty(hourMinSecCountDown) && hourMinSecCountDown.hours.length > 2;

  const handleContinue = () => {
    dispatch(setUnitCart(unitCart));
    dispatch(setBookingRoutesCurrentPage(URLS.COST_SHEET));
    history.push(`/booking/${project.id}/${URLS.COST_SHEET}`);
  };

  const handleUnitCartDelete = () => {
    deleteUnitCartAPI(unitCart.id);
  };

  return (
    <article className='summary-card'>
      <main className='summary-card__body'>
        <header className='summary-card__header'>
          <span className='summary-card__header-title'>
            You have{' '}
            {unitCart.unit.status == UNIT_STATUSES.AVAILABLE
              ? 'explored'
              : unitCart.unit.status.toLowerCase()}
          </span>
          {!isUnitCartBlocked && (
            <span
              className='icon material-icons click-enabled'
              onClick={() => handleUnitCartDelete()}>
              
            </span>
          )}
        </header>
        <div>
          <div className='summary-card__unit-details'>
            {`${unitCart.unit.blockName} ${unitCart.unit.number}, ${unitCart.unit.towerName}`}
          </div>
          {!isEmpty(hourMinSecCountDown) &&
            !isNaN(hourMinSecCountDown.hours) && (
              <div
                className={`summary-card__status ${
                  (hourMinSecCountDown.hours < 1 || isUnitExpired) &&
                  'warning-high'
                } ${hourMinSecCountDown.hours >= 1 && 'warning-low'}`}>
                <span className='icon material-icons'></span>
                {isUnitExpired ? (
                  <span>Blocking Expired</span>
                ) : (
                  <span>
                    {hourMinSecCountDown.hours < 24 ? (
                      <span className='f-bold'>
                        {hourMinSecCountDown.hours}hr{' '}
                        {hourMinSecCountDown.minutes}min{' '}
                        {hourMinSecCountDown.hours < 24 &&
                          hourMinSecCountDown.seconds}
                        sec
                      </span>
                    ) : (
                      <span className='f-bold'>
                        {Math.floor(hourMinSecCountDown.hours / 24)}day{' '}
                        {hourMinSecCountDown.hours % 24}hr{' '}
                        {hourMinSecCountDown.minutes}min
                      </span>
                    )}{' '}
                    left
                  </span>
                )}
              </div>
            )}
        </div>
      </main>
      <footer
        className='summary-card__footer click-enabled'
        onClick={() => {
          handleContinue();
        }}>
        <span>Continue</span>
        <span className='icon material-icons'></span>
      </footer>
    </article>
  );
};

export default SummaryCard;
