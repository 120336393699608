import { useSelector } from 'react-redux';

import { IProject } from 'apps/admin/components/pages/SuperAdminDashboard/SuperAdminProjectList';
import { ITab } from 'apps/cp/components/common/Tabs';
import DesktopTabs from 'apps/admin/components/common/DesktopTabs';
import { ICpLead, ICpUser, IProjectActiveLead } from 'apps/cp/interfaces';
import { IUser } from 'apps/admin/interfaces';
import { TObjectId } from 'interfaces';
import ActivePartnerLeads from './ActivePartnerLeads';
import RegisteredPartnerLeads from './RegisteredPartnerLeads';
import {
  useGetProjectPartnerActiveLeadsCountQuery,
  useGetProjectPartnerActiveLeadsCountForSmQuery,
  useGetProjectPartnerRegisteredLeadsCountForSmQuery,
  useGetProjectPartnerRegisteredLeadsCountQuery,
  useGetProjectPartnerBookedLeadsCountForSmQuery,
  useGetProjectPartnerBookedLeadsCountQuery,
  useGetbookedToRegisteredLeadCountForSmQuery,
  useGetbookedToRegisteredLeadCountQuery,
} from 'apps/admin/services/projectLeadsAPI';
import { getAdminUser } from 'apps/admin/slices/adminSlice';
import { UserRole } from 'constants/userRoles';
import AnalyticsWrapper, {
  ISummaryData,
} from 'apps/admin/components/common/AnalyticsWrapper';
import BookedPartnerLeads from './BookedPartnerLeads';
import RegistrationDone from './RegistrationDone';

export interface IActiveLead {
  activeLead: IProjectActiveLead;
  latestSiteVisitDate: Date;
  siteVisitCount: number;
  diffFirstSiteVisitDateAndRegistrationDateInDays: number;
  uniqueLeadSiteVisitCps: IUser[];
  firstSiteVisitLocation: string;
  qrCodeShared: boolean;
}

export interface ISiteVisit {
  status: string;
  visitDate: Date;
  cpLead: ICpLead;
  project: IProject;
  cpUser: ICpUser;
  createdAt: Date;
  id: TObjectId;
  user: IUser;
}
export interface IRegisteredLead {
  cpLead: ICpLead;
  scheduledSiteVisit: {
    status: string;
    visitDate: Date;
    cpLead: ICpLead;
    project: TObjectId;
    cpUser: TObjectId;
    createdAt: Date;
    id: TObjectId;
  };
  qrCodeShared: boolean;
}

export interface IBookedLead {
  bookedLead: IProjectActiveLead;
  latestSiteVisitDate: Date;
  diffFirstSiteVisitDateAndRegistrationDateInDays: number;
  uniqueLeadSiteVisitCps: IUser[];
}

export interface IRegistrationDone {
  registrationDone: IProjectActiveLead;
  latestSiteVisitDate: Date;
}

interface IProjectPartnerLeadsProps {
  project: IProject;
  onProjectUpdate: (updateProject: IProject) => void;
}

const ProjectPartnerLeads = (props: IProjectPartnerLeadsProps) => {
  const { project, onProjectUpdate } = props;

  const user = useSelector(getAdminUser);

  const { data: activeLeadsCount = 0, isLoading: activeCountLoaded } =
    user?.role === UserRole.EXECUTIVE || user?.role === UserRole.GRE
      ? useGetProjectPartnerActiveLeadsCountForSmQuery({
          projectId: project.id,
          executiveId: user.id,
        })
      : useGetProjectPartnerActiveLeadsCountQuery({
          projectId: project.id,
        });

  const { data: registeredLeadsCount = 0, isLoading: registeredCountLoaded } =
    user?.role === UserRole.EXECUTIVE || user?.role === UserRole.GRE
      ? useGetProjectPartnerRegisteredLeadsCountForSmQuery({
          projectId: project.id,
          executiveId: user.id,
        })
      : useGetProjectPartnerRegisteredLeadsCountQuery({
          projectId: project.id,
        });

  const { data: bookedLeadsCount = 0, isLoading: bookedCountLoaded } =
    user?.role === UserRole.EXECUTIVE || user?.role === UserRole.GRE
      ? useGetProjectPartnerBookedLeadsCountForSmQuery({
          projectId: project.id,
          executiveId: user.id,
        })
      : useGetProjectPartnerBookedLeadsCountQuery({
          projectId: project.id,
        });

  const {
    data: bookedRegisteredLeadCount = 0,
    isLoading: isBookedRegisteredCountLoaded,
  } =
    user?.role === UserRole.EXECUTIVE || user?.role === UserRole.GRE
      ? useGetbookedToRegisteredLeadCountForSmQuery({
          projectId: project.id,
          executiveId: user.id,
        })
      : useGetbookedToRegisteredLeadCountQuery({
          projectId: project.id,
        });

  const tabs: ITab[] = [
    {
      id: 'activeLeads',
      name: 'Active Leads',
      content: (
        <ActivePartnerLeads
          projectId={project.id}
          activeLeadsCount={activeLeadsCount}
          activeCountLoaded={activeCountLoaded}
        />
      ),
      disabled: false,
    },
    {
      id: 'bookedLeads',
      name: 'Booked Leads',
      content: (
        <BookedPartnerLeads
          projectId={project.id}
          bookedLeadsCount={bookedLeadsCount}
          bookedCountLoaded={bookedCountLoaded}
        />
      ),
      disabled: false,
    },
    {
      id: 'registerationDOne',
      name: 'Registeration-done',
      content: (
        <RegistrationDone
          projectId={project.id}
          isBookedRegisteredCountLoaded={isBookedRegisteredCountLoaded}
          bookedRegisteredLeadCount={bookedRegisteredLeadCount}
        />
      ),
      disabled: false,
    },
    {
      id: 'registeredLeads',
      name: 'Registered Leads',
      content: (
        <RegisteredPartnerLeads
          projectId={project.id}
          registeredLeadsCount={registeredLeadsCount}
          registeredCountLoaded={registeredCountLoaded}
        />
      ),
      disabled: false,
    },
  ];

  const leadsCountSummay: ISummaryData[] = [
    {
      details: {
        mainData: `${activeLeadsCount}`,
        changeData: '',
        changeDataClass: '',
        changeDesc: '',
        loaded: activeCountLoaded,
      },
      titleIcon: 'group',
      title: 'Active Leads',
    },
    {
      details: {
        mainData: `${bookedLeadsCount}`,
        changeData: '',
        changeDataClass: '',
        changeDesc: '',
        loaded: bookedCountLoaded,
      },
      titleIcon: 'group',
      title: 'Booked Leads',
    },
    {
      details: {
        mainData: `${bookedRegisteredLeadCount}`,
        changeData: '',
        changeDataClass: '',
        changeDesc: '',
        loaded: isBookedRegisteredCountLoaded,
      },
      titleIcon: 'group',
      title: 'Registration-done',
    },
    {
      details: {
        mainData: `${registeredLeadsCount}`,
        changeData: '',
        changeDataClass: '',
        changeDesc: '',
        loaded: registeredCountLoaded,
      },
      titleIcon: 'group',
      title: 'Registered Leads',
    },
  ];

  return (
    <>
      <AnalyticsWrapper summaryData={leadsCountSummay} />
      <DesktopTabs tabs={tabs} />
    </>
  );
};

export default ProjectPartnerLeads;
