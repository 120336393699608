import { createSlice } from '@reduxjs/toolkit';
import { authAPISlice } from '../../../api/auth';
import { adminUserAPISlice } from 'apps/admin/services/adminUserAPISlice';

const authUser = localStorage.getItem('user')
  ? JSON.parse(localStorage.getItem('user'))
  : {};

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    user: authUser,
    thirdPartyUserId: '',
    showAuthForms: false,
  },
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
      localStorage.setItem('user', JSON.stringify(state.user));

      if (state.user.id) {
        window.ga('set', 'dimension1', state.user.id);
      }
    },
    setThirdPartyUserId: (state, action) => {
      state.thirdPartyUserId = action.payload;
    },
    setShowAuthForms: (state, action) => {
      state.showAuthForms = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      authAPISlice.endpoints.register.matchFulfilled,
      (state, { payload }) => {
        state.user = payload;
        localStorage.setItem('user', JSON.stringify(state.user));
        state.showAuthForms = false;

        if (state.user.id) {
          window.ga('set', 'dimension1', state.user.id);
        }
      }
    );
    builder.addMatcher(
      authAPISlice.endpoints.updateUser.matchFulfilled,
      (state, { payload }) => {
        state.user = { ...state.user, ...payload };
        localStorage.setItem('user', JSON.stringify(state.user));
      }
    );
    builder.addMatcher(
      authAPISlice.endpoints.getUserByMobileOld.matchFulfilled,
      (state, { payload }) => {
        state.user = payload;
        localStorage.setItem('user', JSON.stringify(state.user));
        state.showAuthForms = false;

        if (state.user.id) {
          window.ga('set', 'dimension1', state.user.id);
        }
      }
    );
  },
});

export const { setUser, setThirdPartyUserId, setShowAuthForms } =
  authSlice.actions;

export const getUser = (state) => state.auth.user;
export const getThirdPartyUserId = (state) => state.auth.thirdPartyUserId;
export const getShowAuthForms = (state) => state.auth.showAuthForms;
