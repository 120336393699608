import { useEffect, useState } from 'react';
import { ISecondaryCompany } from 'apps/admin/components/pages/SuperAdminDashboard/SuperAdminProjectList';
import DropDownInput, {
  DropDownOptionType,
} from 'apps/booking/components/common/inputs/DropDownInput';
import TextInput from 'apps/booking/components/common/inputs/TextInput';
import { ACCESS } from 'constants/status';
import { ICompany } from 'apps/admin/interfaces';

import styles from './styles.module.css';

interface ISecondaryCompanyList {
  companyList: ICompany[];
  primaryCompanyId: string | undefined;
  secondaryCompanies?: ISecondaryCompany[];

  // Form
  register: Function;
  unregister: Function;
  setValue: Function;
  name: string;
  isLoading?: boolean;
}

const getDropDownOptions = (
  primaryCompanyId: string | undefined,
  companies: ICompany[],
  secondaryCompanies: ICompany[]
): DropDownOptionType[] => {
  const excludedCompanyIds = [
    ...secondaryCompanies.map((c) => c.id),
    ...(primaryCompanyId ? [primaryCompanyId] : []),
  ];

  return (
    companies
      .filter((c) => !excludedCompanyIds.includes(c.id))
      .map((c) => {
        const option: DropDownOptionType = {
          value: c.id as string,
          displayText: c.displayName,
        };
        return option;
      }) || []
  );
};

const getCompaniesFromSecondaryCompanies = (
  secondaryCompanies: ISecondaryCompany[],
  allCompanies: ICompany[]
): ICompany[] => {
  const secondaryCompanyIds = secondaryCompanies.map((c) => c.company);
  return allCompanies.filter((c) =>
    secondaryCompanyIds.includes(c.id as string)
  );
};

const SecondaryCompanyList = (props: ISecondaryCompanyList) => {
  const {
    companyList,
    primaryCompanyId,
    secondaryCompanies: _secondaryCompanies = [],

    // Form
    unregister,
    register,
    name = '',
    setValue,
    isLoading,
  } = props;

  const [secondaryCompanies, setSecondaryCompanies] = useState<ICompany[]>([]);
  const [dropDownOptions, setDropDownOptions] = useState<DropDownOptionType[]>(
    []
  );

  // useForm
  register && name && register(name);

  useEffect(() => {
    return () => {
      // Anything in here is fired on component unmount.
      unregister && unregister(name);
    };
  }, [name, setValue, unregister]);

  useEffect(() => {
    const secondaryCompaniesLocal = getCompaniesFromSecondaryCompanies(
      _secondaryCompanies,
      companyList
    );
    resetDropDownOptions(secondaryCompaniesLocal);
    setSecondaryCompanies(secondaryCompaniesLocal);
    // eslint-disable-next-line
  }, [primaryCompanyId, companyList]);

  const setSecondaryCompaniesWrapper = (selectedCompanies: ICompany[]) => {
    setSecondaryCompanies(selectedCompanies);
    setValue &&
      setValue(
        name,
        selectedCompanies.map((c) => {
          return {
            company: c.id,
            access: ACCESS.READ,
          };
        })
      );
    resetDropDownOptions(selectedCompanies);
  };

  const handleDropDownSelect = (selectedOption: DropDownOptionType[]) => {
    const company = companyList.find(
      (c: ICompany) => c.id === selectedOption[0]?.value
    );
    if (!company) {
      return;
    }
    const newSecondaryCompanies = [...secondaryCompanies, company];
    setSecondaryCompaniesWrapper(newSecondaryCompanies);
  };

  const resetDropDownOptions = (newSecondaryCompanies: ICompany[] = []) => {
    setDropDownOptions(
      getDropDownOptions(primaryCompanyId, companyList, newSecondaryCompanies)
    );
  };

  const removeSelectedCompany = (companyId: string) => {
    setSecondaryCompaniesWrapper(
      secondaryCompanies.filter((c) => c.id !== companyId)
    );
  };

  return (
    <div>
      {secondaryCompanies.map((c) => (
        <div>
          <TextInput
            readOnly={true}
            placeHolder=''
            defaultValue={c.displayName}
            endIconName='delete'
            onEndIconClick={() => removeSelectedCompany(c.id as string)}
          />
        </div>
      ))}
      <div className={styles.dropDownContainer}>
        <DropDownInput
          options={dropDownOptions}
          lable='Select Secondary Company'
          onValueSelected={handleDropDownSelect}
          multiSelect={false}
          showSelectedOptionDisplayText={false}
          isLoading={isLoading}
        />
      </div>
    </div>
  );
};

export default SecondaryCompanyList;
