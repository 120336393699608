import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import MultiTextInput from 'apps/booking/components/common/inputs/MultiTextInput';
import Button from 'apps/booking/components/common/buttons/Button';
import Icon from 'apps/booking/components/common/Icon';
import TextInput from 'apps/booking/components/common/inputs/TextInput';
import Modal from 'apps/booking/components/common/modal/Modal';
import { IProjectEventReq } from 'apps/admin/components/pages/SuperAdminDashboard/SuperAdminProjectList/modals/ProjectEventsModal';

import styles from './styles.module.css';

interface IAddProjectEventProps {
  onModalClose: () => void;
  onAddEvent: (data: IProjectEventReq) => void;
}

const AddProjectEvent = (props: IAddProjectEventProps) => {
  const { onModalClose, onAddEvent } = props;

  const [descList, setDescList] = useState<string[]>([]);

  const schema = yup.object().shape({
    title: yup.string().required(),
    venue: yup.string().required(),
    startDate: yup.string().required(),
    endDate: yup.string().required(),
  });

  const {
    register,
    unregister,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });

  const handleAddProjectEvent = (formData: IProjectEventReq) => {
    const eventData = {
      ...formData,
      descItems: descList,
    };

    onAddEvent(eventData);
  };

  const handleAddDescList = (description: string) => {
    const descriptions: string[] = [...descList];
    descriptions.push(description);
    setDescList(descriptions);
  };

  const handleRemoveDescList = (description: string) => {
    setDescList(descList.filter((descItem) => descItem === description));
  };

  return (
    <Modal
      onOutsideClick={onModalClose}
      additionalClasses={styles.modalAdditionalClasses}>
      <header className={styles.modalHeader}>
        <div className={styles.modalHeaderLeftContent}>
          <h1>Add Events</h1>
          <p>
            Fill in the following info to add a new event associated to the
            selected project.
          </p>
        </div>
        <Icon
          name='close'
          propStyles={styles.modalCloseIcon}
          onClick={onModalClose}
        />
      </header>

      <main>
        <div className={styles.bodyIndividualdRow}>
          <TextInput
            label='Title'
            placeHolder='Add title for the event'
            register={register}
            setValue={setValue}
            unregister={unregister}
            name='title'
            errorMessage={errors.title && 'Title is required'}
          />
        </div>
        <div className={styles.bodyIndividualdRow}>
          <TextInput
            label='Description'
            placeHolder='Add description for the event'
            register={register}
            setValue={setValue}
            unregister={unregister}
            name='desc'
          />
        </div>

        <MultiTextInput
          label='Description Items'
          placeHolder='Add descriptions for the event'
          displayInputs={descList}
          onAdd={handleAddDescList}
          onRemove={handleRemoveDescList}
        />

        <div className={styles.bodyIndividualdRow}>
          <TextInput
            label='Venue'
            placeHolder='Add Venue for the event'
            register={register}
            setValue={setValue}
            unregister={unregister}
            name='venue'
            errorMessage={errors.venue && 'Venue is required'}
          />
        </div>

        <div className={styles.bodyIndividualdRow}>
          <div className={styles.inputsContainer}>
            <TextInput
              label='Start Date'
              placeHolder=''
              type='datetime-local'
              register={register}
              setValue={setValue}
              unregister={unregister}
              name='startDate'
              errorMessage={errors.startDate && 'Start Date is required'}
            />
          </div>

          <div className={styles.inputsContainer}>
            <TextInput
              label='End Date'
              placeHolder=''
              type='datetime-local'
              register={register}
              setValue={setValue}
              unregister={unregister}
              name='endDate'
              errorMessage={errors.endDate && 'End Date is required'}
            />
          </div>
        </div>
        <div className={styles.formDividerLineContainer}></div>
      </main>

      {/* Modal Footer */}
      <footer className={styles.modalFooter}>
        <div className={styles.createProjectBtnContainer}>
          <Button
            type='submit'
            propStyles={`${styles.addEventBtn}`}
            onClick={() => {
              handleSubmit(handleAddProjectEvent)();
            }}>
            Add Event
          </Button>
        </div>
      </footer>
    </Modal>
  );
};

export default AddProjectEvent;
