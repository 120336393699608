import { useState } from 'react';

import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

import { useAddDemoRoomMutation } from 'apps/admin/services/projectsAPISlice';
import useToast from 'hooks/useToast';
import Modal from 'apps/booking/components/common/modal/Modal';
import TextInput from 'apps/booking/components/common/inputs/TextInput';
import Button from 'apps/booking/components/common/buttons/Button';
import Icon from 'apps/booking/components/common/Icon';

// Styles
import styles from '../VsgLinkModal/styles.module.css';
import { IProject } from '../..';

export interface IDemoRoom {
  id?: string;
  virtualRoomId: string;
  hostPassword: string;
  fallbackVirtualRoomUrl: string;
}

interface IAddDemoRoomModal {
  projectName: string;
  projectId: string;
  onModalClose: Function;
  onAddDemoRoom: (updateProject: IProject) => {};
}

// Validation
const schema = yup.object().shape({
  virtualRoomId: yup.string().required(),
  hostPassword: yup.string().required(),
  fallbackVirtualRoomUrl: yup.string(),
});

const AddDemoRoomModal = (props: IAddDemoRoomModal) => {
  const { projectName, projectId, onModalClose, onAddDemoRoom } = props;

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });

  const [verifyNowBtnDiable, setVerifyNowBtnDiable] = useState(true);
  const [demoRoomBackupUrl, setDemoRoomBackupUrl] = useState('');
  const [addToast] = useToast();

  const [addDemoRoom] = useAddDemoRoomMutation();

  const handleCreateDemoRoom = async (demoRoom: IDemoRoom) => {
    try {
      const updatedProject = await addDemoRoom({
        projectId,
        demoRoom,
      }).unwrap();

      onAddDemoRoom(updatedProject.data as IProject);
      onModalClose();

      addToast({
        type: 'SUCCESS',
        primaryMessage: 'Demo Room created successfully',
      });
    } catch (err) {
      const errMsg = (err as any).data.message;
      addToast({
        type: 'ERROR',
        primaryMessage: errMsg,
        secondaryMessage: 'Demo Room creation failed',
      });
    }
  };

  return (
    <Modal
      onOutsideClick={() => onModalClose()}
      additionalClasses={styles.modalAdditionalClasses}>
      {/* Modal Header */}
      <header className={styles.modalHeader}>
        <div className={styles.modalHeaderLeftContent}>
          <h1>Add Demo Rooms for {projectName}</h1>
          <p>
            Fill in the following info to add a new demo room associated to the
            selected project. Projects with less rooms than
            executivesWithDemoRoom will not be able to go live
          </p>
        </div>
        <Icon
          name='close'
          propStyles={styles.modalCloseIcon}
          onClick={() => onModalClose()}
        />
      </header>

      {/* Modal Body */}
      <main>
        <TextInput
          label='Room ID'
          placeHolder='Add Room ID from 3DV'
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            event.target.value !== ''
              ? setVerifyNowBtnDiable(false)
              : setVerifyNowBtnDiable(true);
          }}
          onBlur={() => {}}
          register={register}
          setValue={setValue}
          name='virtualRoomId'
          errorMessage={errors?.virtualRoomId ? 'Room ID is required' : ''}
        />
        <div className={styles.projectIdInputContainer}>
          <div className={styles.projectIdInput}>
            <TextInput
              label='Host Password'
              placeHolder='Add Host Password'
              onChange={() => {}}
              onBlur={() => {}}
              register={register}
              setValue={setValue}
              name='hostPassword'
              errorMessage={
                errors?.hostPassword ? 'Host Password is required' : ''
              }
            />
          </div>
          <div className={styles.projectIdInput}>
            <TextInput
              label='Fall Back video call URL'
              placeHolder='Add Fallback video call URL (Zoom, Google Meet, Teams, etc)'
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setDemoRoomBackupUrl(e.target.value);
              }}
              onBlur={() => {}}
              register={register}
              setValue={setValue}
              name='fallbackVirtualRoomUrl'
              errorMessage={
                demoRoomBackupUrl === ''
                  ? errors?.fallbackVirtualRoomUrl
                    ? 'Fall Back video call URL is required'
                    : ''
                  : demoRoomBackupUrl.includes('http')
                  ? ''
                  : 'URL should start with http:// or https://'
              }
            />
          </div>
        </div>
      </main>

      {/* Modal Footer */}
      <footer className={styles.modalFooter}>
        <div className={styles.projectIdInput}>
          <p className={styles.footerDisclaimer}>
            By adding executive, an autogenerated email will be sent to the
            executive with their randomly generated password and URL for the
            dashboard. Do this step the last, just before going for UAT.
          </p>
        </div>
        <div className={styles.projectIdInput}>
          <Button
            type='submit'
            onClick={() => handleSubmit(handleCreateDemoRoom)()}
            propStyles={`${styles.verifyNowBtn} ${
              verifyNowBtnDiable ? styles.disableBtn : ''
            }`}>
            Add Demo Room Now
          </Button>
        </div>
      </footer>
    </Modal>
  );
};

export default AddDemoRoomModal;
