import { ICpUser } from 'apps/cp/interfaces';
import { TObjectId } from 'interfaces';

const cpUserLocalStorageItemName = 'cpUser';

export const setCpUserLocal = (cpUser: ICpUser) => {
  localStorage.setItem(cpUserLocalStorageItemName, JSON.stringify(cpUser));
};

// Only used to set initial state of cpUserSlice
export const getCpUserLocal = (): ICpUser => {
  const cpUserStr = localStorage.getItem(cpUserLocalStorageItemName) as string;
  return JSON.parse(cpUserStr);
};

export const removeCpUserLocal = () => {
  localStorage.removeItem(cpUserLocalStorageItemName);
};

// Register cpUsers to projectEvent
const projectEventIdLocalStorageItemName = 'projectEventId';
export const setProjectEventIdLocal = (projectEventId: TObjectId) => {
  localStorage.setItem(projectEventIdLocalStorageItemName, projectEventId);
};

export const getProjectEventIdLocal = (): TObjectId => {
  const projectEventId = localStorage.getItem(
    projectEventIdLocalStorageItemName
  ) as TObjectId;
  return projectEventId;
};

export const removeProjectEventIdLocal = () => {
  localStorage.removeItem(projectEventIdLocalStorageItemName);
};

// storing executiveId and projectId for SM-CP onBoarding
const projectIdLocalStorageItemName = 'projectId';
export const setProjectIdLocal = (projectId: TObjectId) => {
  localStorage.setItem(projectIdLocalStorageItemName, projectId);
};

export const getProjectIdLocal = (): TObjectId => {
  const projectId = localStorage.getItem(
    projectIdLocalStorageItemName
  ) as TObjectId;
  return projectId;
};

export const removeProjectIdLocal = () => {
  localStorage.removeItem(projectIdLocalStorageItemName);
};

const executiveIdLocalStorageItemName = 'executiveId';
export const setExecutiveIdLocal = (executiveId: TObjectId) => {
  localStorage.setItem(executiveIdLocalStorageItemName, executiveId);
};

export const getExecutiveIdLocal = (): TObjectId => {
  const executiveId = localStorage.getItem(
    executiveIdLocalStorageItemName
  ) as TObjectId;
  return executiveId;
};

export const removeExecutiveIdLocal = () => {
  localStorage.removeItem(executiveIdLocalStorageItemName);
};
