import { IProject } from 'apps/admin/components/pages/SuperAdminDashboard/SuperAdminProjectList';
import { ITab } from 'apps/cp/components/common/Tabs';
import DesktopTabs from 'apps/admin/components/common/DesktopTabs';
import TeamMemberList from './TeamMemberList';

import styles from './styles.module.css';

interface IProjectMyTeamProps {
  project: IProject;
  onProjectUpdate: (updateProject: IProject) => void;
}

const ProjectMyTeam = (props: IProjectMyTeamProps) => {
  const { project, onProjectUpdate } = props;

  const tabs: ITab[] = [
    {
      id: 'active',
      name: 'Active',
      content: (
        <TeamMemberList project={project} onProjectUpdate={onProjectUpdate} />
      ),
      disabled: false,
    },
    // {
    //   id: 'details',
    //   name: 'Details',
    //   content: (
    //     <TeamMemberList project={project} onProjectUpdate={onProjectUpdate} />
    //   ),
    //   disabled: false,
    // },
  ];

  return <DesktopTabs tabs={tabs} />;
};

export default ProjectMyTeam;
