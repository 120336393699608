import { useState } from 'react';

import { GridReadyEvent, ICellRendererParams } from 'ag-grid-community';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import ExpandRowCell from 'apps/admin/components/pages/ActiveLeads/cell-renderers/ExpandRowCell';
import ExpandedFirmDetails from 'apps/admin/components/pages/AllFirms/ExpandedFirmDetails';
import { IPartnerFirm } from '..';
import { DEFAULT_NULL_VALUE } from 'apps/admin/components/common/ExpandedDetailSection';
import { dateToDaythMonthYear } from 'utils/dateFormator';
import TextInput from 'apps/booking/components/common/inputs/TextInput';

import styles from './styles.module.css';

interface IFirmListProps {
  partnerFirms: IPartnerFirm[];
  registered?: boolean;
}

const FirmList = (props: IFirmListProps) => {
  const { partnerFirms, registered } = props;

  const [gridApi, setGridApi] = useState(null) as any;

  // Ag-grid global search
  const handleGridSearch = (event: string) => {
    gridApi.setQuickFilter(event);
  };

  // This function will automatically be called when ag grid is ready
  const onGridReady = (params: GridReadyEvent) => {
    setGridApi(params.api);
  };

  return (
    <>
      <header>
        <div className={styles.searchContainer}>
          <TextInput
            type='text'
            inputHTMLElementStyles={styles.searchInput}
            placeHolder='Search Firm Name'
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              handleGridSearch(event.target.value);
            }}
            startIconName='search'
            startIconVariant='SECONDARY'
          />
        </div>
      </header>
      <div
        className={`ag-theme-alpine ${styles.gridContainer}`}
        style={{ width: '100%', height: '100%' }}>
        <AgGridReact
          rowData={partnerFirms}
          defaultColDef={{
            sortable: false,
            filter: false,
            resizable: false,
            suppressMenu: true,
          }}
          domLayout={'autoHeight'}
          pagination={true}
          paginationPageSize={10}
          masterDetail={true}
          animateRows={true}
          detailCellRenderer={'detailCellRenderer'}
          detailRowAutoHeight={true}
          onGridReady={onGridReady}
          frameworkComponents={{
            detailCellRenderer: ExpandedFirmDetails,
          }}>
          <AgGridColumn flex={0.5} hide={true} field='cpName' />
          <AgGridColumn
            flex={0.5}
            cellClass='ag-grid-column-custom-cell'
            cellRendererFramework={ExpandRowCell}
            cellRendererParams={(params: ICellRendererParams) => params}
          />
          <AgGridColumn
            headerName='Name'
            field='firmName'
            flex={2}
            cellRendererFramework={(params: ICellRendererParams) => (
              <p className={styles.cpName}>{params.data.firmName}</p>
            )}
          />
          <AgGridColumn
            flex={1}
            headerName='Status'
            cellRendererFramework={(params: ICellRendererParams) => (
              <p className={styles.cpName}>
                {params.data.CPFirmStatus
                  ? params.data.CPFirmStatus
                  : DEFAULT_NULL_VALUE}
              </p>
            )}
          />
          <AgGridColumn
            flex={1.2}
            headerName='Registered On'
            cellRendererFramework={(params: ICellRendererParams) => (
              <p className={styles.cpName}>
                {params.data.projectRegistrationDate
                  ? dateToDaythMonthYear(params.data.projectRegistrationDate)
                  : DEFAULT_NULL_VALUE}
              </p>
            )}
          />
          <AgGridColumn
            flex={1}
            headerName='Partners'
            cellRendererFramework={(params: ICellRendererParams) => (
              <h2 className={styles.growth}>
                {params.data.totalCPs
                  ? params.data.totalCPs
                  : DEFAULT_NULL_VALUE}
              </h2>
            )}
          />
          <AgGridColumn
            flex={1}
            headerName='Leads'
            cellRendererFramework={(params: ICellRendererParams) => (
              <h2 className={styles.growth}>
                {params.data.totalLeadsRegister
                  ? params.data.totalLeadsRegister
                  : DEFAULT_NULL_VALUE}
              </h2>
            )}
          />
          <AgGridColumn
            flex={1}
            headerName='Collaterals'
            cellRendererFramework={(params: ICellRendererParams) => (
              <h2 className={styles.growth}>
                {params.data.totalCollateralShared
                  ? params.data.totalCollateralShared
                  : DEFAULT_NULL_VALUE}
              </h2>
            )}
          />
          {registered && (
            <>
              <AgGridColumn
                flex={1}
                headerName='Contact'
                field='pocMobile'
                cellRendererFramework={(params: ICellRendererParams) => (
                  <h2 className={styles.cpName}>
                    {params.data.pocMobile
                      ? params.data.pocMobile
                      : DEFAULT_NULL_VALUE}
                  </h2>
                )}
              />
              {/* <AgGridColumn
              flex={1}
              headerName='Tagged'
              cellRendererFramework={(params: ICellRendererParams) => (
                <h2 className={styles.cpName}>{'--'}</h2>
              )}
            /> */}
            </>
          )}
          {!registered && (
            <>
              <AgGridColumn
                flex={1}
                headerName='Site Visit'
                cellRendererFramework={(params: ICellRendererParams) => (
                  <h2 className={styles.growth}>
                    {params.data.totalSiteVisit
                      ? params.data.totalSiteVisit
                      : 0}
                  </h2>
                )}
              />

              <AgGridColumn
                flex={1}
                headerName='Assigned'
                cellRendererFramework={(params: ICellRendererParams) => (
                  <h2 className={styles.growth}>
                    {params.data.totalAssignedLeads
                      ? params.data.totalAssignedLeads
                      : 0}
                  </h2>
                )}
              />

              <AgGridColumn
                flex={1}
                headerName='Losts leads'
                cellRendererFramework={(params: ICellRendererParams) => (
                  <h2 className={styles.growth}>
                    {Math.abs(
                      params.data.totalSiteVisit -
                        params.data.totalAssignedLeads
                    ) || 0}
                  </h2>
                )}
              />
            </>
          )}
          {/* <AgGridColumn
          flex={0.5}
          headerName='More'
          cellRendererFramework={MoreOptions}
          cellRendererParams={(params: ICellRendererParams): IMoreMenuCell => ({
            project: params.data,
          })}/> */}
        </AgGridReact>
      </div>
    </>
  );
};

export default FirmList;
