import { IUser } from 'apps/admin/interfaces';
import { adminAPISlice } from 'apps/admin/services/index';

export const adminUserAPISlice = adminAPISlice.injectEndpoints({
  endpoints: (build) => ({
    adminOtpSend: build.mutation({
      query: ({ email }: { email: String }) => ({
        url: 'api/otp/send',
        method: 'POST',
        body: { email },
      }),
    }),
    adminOtpVerify: build.mutation({
      query: ({ email, otp }: { email: String; otp: String }) => ({
        url: 'api/otp/verify',
        method: 'POST',
        body: { email, otp },
      }),
    }),
    offlinePaymentRequest: build.mutation({
      query: (unitCartId) => ({
        url: `api/otp/offlinePaymentRequest?unitCartId=${unitCartId}`,
        method: 'POST',
      }),
    }),
    otpv2Send: build.mutation({
      query: ({
        address,
        addressType,
        otpTemplate,
      }: {
        address: String;
        addressType: 'EMAIL' | 'MOBILE';
        otpTemplate: String;
      }) => ({
        url: 'api/otpv2/send',
        method: 'POST',
        body: { address, addressType, otpTemplate },
      }),
    }),
    otpv2Verify: build.mutation({
      query: ({ address, otp }: { address: String; otp: String }) => ({
        url: 'api/otpv2/verify',
        method: 'POST',
        body: { address, otp },
      }),
    }),

    getUserByEmail: build.mutation({
      query: ({ email }: { email: String }) => ({
        url: `api/users/${email}`,
        method: 'GET',
      }),
    }),
    getUserByMobile: build.mutation({
      query: ({ mobile }: { mobile: string }) => ({
        url: `api/users/mobile/${mobile}`,
        method: 'GET',
      }),
    }),

    addAdminUser: build.mutation({
      query: ({
        name,
        email,
        password,
        role,
        mobile,
      }: {
        name: String;
        email: String;
        password: String;
        role: String;
        mobile: string;
      }) => ({
        url: 'api/users/',
        method: 'POST',
        body: { name, email, password, role, mobile },
      }),
    }),
    updateAdminUser: build.mutation({
      query: ({
        userId,
        user: { name, email, mobile, jobTitle },
      }: {
        userId: string;
        user: {
          name: String;
          email: String;
          mobile: String;
          jobTitle?: string;
        };
      }) => ({
        url: `api/users/${userId}`,
        method: 'PUT',
        body: { name, email, mobile, jobTitle },
      }),
    }),

    resetAdminUserPasswordByEmail: build.mutation({
      query: ({ email, password }: { email: String; password: String }) => ({
        url: 'api/users/reset-password',
        method: 'POST',
        body: { email, password },
      }),
    }),
    resetAdminUserPasswordByMobile: build.mutation({
      query: ({ mobile, password }: { mobile: String; password: String }) => ({
        url: 'api/users/reset-password',
        method: 'POST',
        body: { mobile, password },
      }),
    }),
    loginAdminUserByEmail: build.mutation({
      query: ({ email, password }: { email: String; password: String }) => ({
        url: 'api/auth/email',
        method: 'POST',
        body: { email, password },
      }),
    }),
    loginAdminUserByMobile: build.mutation({
      query: ({ mobile, password }: { mobile: string; password: string }) => ({
        url: 'api/auth/mobile',
        method: 'POST',
        body: { mobile, password },
      }),
    }),

    getUsersByRole: build.query<IUser[], any>({
      query: (userRole: string) => `api/users/role/${userRole}`,
      transformResponse: (response: {
        data: IUser[];
        code: String;
        error: Object;
        message: String;
      }) => {
        return response.data;
      },
    }),
  }),

  overrideExisting: false,
});

export const {
  useOtpv2SendMutation,
  useOtpv2VerifyMutation,
  useAdminOtpSendMutation,
  useAdminOtpVerifyMutation,
  useOfflinePaymentRequestMutation,

  useGetUserByEmailMutation,
  useGetUserByMobileMutation,

  useAddAdminUserMutation,
  useUpdateAdminUserMutation,

  useResetAdminUserPasswordByEmailMutation,
  useResetAdminUserPasswordByMobileMutation,

  useLoginAdminUserByEmailMutation,
  useLoginAdminUserByMobileMutation,

  useGetUsersByRoleQuery,
} = adminUserAPISlice;
