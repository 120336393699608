import { useState } from 'react';

import { fallbackImageUrl } from '../../../../constants/urls';

const Image = (props) => {
  const {
    src,
    fallbackSrc = fallbackImageUrl,
    alt = 'Image',
    imgClass = '',
    containerClass = '',
  } = props;
  const [loading, setLoading] = useState(true);

  return (
    <div className={`${containerClass} image-container loading-spin-container`}>
      <img
        src={src}
        alt={alt}
        className={`${imgClass} image-container__image`}
        onError={(e) => {
          e.target.onerror = null;
          e.target.src = fallbackSrc;
        }}
        onLoad={() => setLoading(false)}
      />
      {loading && <span className='loading-spin'></span>}
    </div>
  );
};

export default Image;
