import { useState } from 'react';

import Button from 'apps/booking/components/common/buttons/Button';
import TextInput from 'apps/booking/components/common/inputs/TextInput';
import AddCompany from 'apps/admin/components/pages/SuperAdminDashboard/CompanyList/modals/AddCompanyModal';

import styles from './styles.module.css';

interface ICompanyListHeader {
  handleOrganizationListSearch: Function;
}

const CompanyListHeader = (props: ICompanyListHeader) => {
  const { handleOrganizationListSearch } = props;

  const [addNewOrganizationModalShown, setAddCompanyModalShow] =
    useState(false);

  return (
    <>
      {
        <AddCompany
          addNewOrganizationModalShown={addNewOrganizationModalShown}
          handleAddCompanyModalClose={() => setAddCompanyModalShow(false)}
        />
      }

      <header className={styles.organizationListHeader}>
        {/* Header Left Part */}
        <div>
          <h2 className={styles.dashboardHeading}>Companies Dashboard</h2>
          <p className={styles.dashboardDesc}>
            All Companies
            <span className={styles.boldTextForDashboardDesc}>
              Under One Dashboard
            </span>
          </p>

          {/* Header Left Part Search*/}
          <div className={styles.searchContainer}>
            <TextInput
              type='text'
              inputHTMLElementStyles={styles.searchInput}
              placeHolder='Search Company & Developer Name'
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                handleOrganizationListSearch(event.target.value);
              }}
              startIconName='search'
              startIconVariant='SECONDARY'
            />
            {/* <Icon name='search' propStyles={styles.searchIcon} /> */}
          </div>
        </div>

        {/* Header Right Part */}
        <div className={styles.dashboardButtonsContainer}>
          <Button
            onClick={() => {
              setAddCompanyModalShow(true);
            }}
            accent='primary'>
            Create New Company
          </Button>
        </div>
      </header>
    </>
  );
};

export default CompanyListHeader;
