import { useEffect, useMemo, useRef, useState } from 'react';

import { CROSS_TICK_ICON, SEARCH } from 'constants/icons';
import TextInput from 'apps/booking/components/common/inputs/TextInput';
import useOnClickOutside from 'utils/useOnClickOutside';
import Icon from 'apps/booking/components/common/Icon';

import styles from './styles.module.css';

interface ISearchDropDownInputProps {
  label?: string;
  placeholder: string;
  name: string;
  suggestions?: string[];
  unregister?: Function;
  register?: Function;
  setValue?: Function;
  errorMessage?: string;
  onClick?: (suggestion: string) => void;
  propStyles?: string;
  mulipleSelect?: boolean;
  crApp?: boolean;
  defaultValue?: string;
}

const SearchDropDownInput = (props: ISearchDropDownInputProps) => {
  const {
    label,
    placeholder,
    name,
    suggestions,
    setValue,
    register,
    unregister,
    errorMessage,
    onClick,
    propStyles,
    mulipleSelect,
    crApp = false,
    defaultValue = '',
  } = props;

  const [searchedText, setSearchedText] = useState(defaultValue);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);

  const handleCancelClick = (index: number) => {
    const newItems = [...selectedOptions];
    newItems.splice(index, 1);
    setSelectedOptions(newItems);
  };

  const ref = useRef() as React.MutableRefObject<HTMLUListElement>;

  useEffect(() => {
    if (mulipleSelect) {
      register && register(name, selectedOptions);
      return;
    }
    register && register(name, searchedText);
    return () => {
      // Anything in here is fired on component unmount.
      unregister && unregister(name);
    };
  }, [
    register,
    unregister,
    name,
    searchedText,
    selectedOptions,
    mulipleSelect,
  ]);

  useEffect(() => {
    if (mulipleSelect) {
      setValue && setValue(name, selectedOptions);
      return;
    }
    setValue && setValue(name, searchedText);
  }, [setValue, searchedText, selectedOptions, mulipleSelect]);

  const handleInputChange = (e: any) => {
    setSearchedText(e.target.value);
    setShowSuggestions(true);
  };

  const handleFocus = () => {
    setShowSuggestions(true);
  };

  const handleCloseSearch = () => {
    setSearchedText('');
  };

  const handleSuggestionClick = (suggestion: string) => {
    if (mulipleSelect) {
      const options = [...selectedOptions];
      options.unshift(suggestion);
      setSelectedOptions(options);
      setSearchedText('');
    } else {
      setSearchedText(suggestion);
    }
    setShowSuggestions(false);
    onClick && onClick(suggestion);
  };

  const filteredSuggestions = useMemo(() => {
    const options = suggestions?.filter((suggestion) =>
      selectedOptions.every((option) => !suggestion.includes(option))
    );
    return options?.filter((suggestion) =>
      suggestion.toLowerCase().includes(searchedText.toLowerCase())
    );
  }, [suggestions, searchedText, selectedOptions]);

  useOnClickOutside(ref, () => {
    return setShowSuggestions(false);
  });

  return (
    <div className={styles.searhContainer}>
      <TextInput
        label={label}
        placeHolder={placeholder}
        type='search'
        endIconName={
          suggestions?.length
            ? searchedText
              ? CROSS_TICK_ICON
              : SEARCH
            : undefined
        }
        defaultValue={searchedText}
        setValue={!mulipleSelect ? setValue : undefined}
        register={!mulipleSelect ? register : undefined}
        unregister={!mulipleSelect ? unregister : undefined}
        name={name}
        onChange={handleInputChange}
        onEndIconClick={handleCloseSearch}
        onFocus={handleFocus}
        errorMessage={errorMessage}
        maxLength={50}
        crApp={crApp}
        resetValue={mulipleSelect && !searchedText}
      />
      {showSuggestions && !!filteredSuggestions?.length && (
        <ul className={`${styles.searchOptions} ${propStyles}`} ref={ref}>
          {filteredSuggestions.map((suggestion) => (
            <li
              className={styles.options}
              onClick={() => handleSuggestionClick(suggestion)}>
              {suggestion}
            </li>
          ))}
        </ul>
      )}
      {mulipleSelect && (
        <div className={styles.optionsWrapper}>
          {selectedOptions.map((option: string, index: number) => (
            <p key={index} className={styles.optionText}>
              {option}
              <Icon
                name='close'
                propStyles={styles.closeIcon}
                onClick={() => handleCancelClick(index)}
              />
            </p>
          ))}
        </div>
      )}
    </div>
  );
};

export default SearchDropDownInput;
