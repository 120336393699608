import { useSelector } from 'react-redux';

import { ITab } from 'apps/cp/components/common/Tabs';
import { IProjectPartnerSiteVisitsProps } from 'apps/admin/components/pages/ProjectPartnerSitevisits';
import DirectSitevisits from './DirectSiteVisits';
import DesktopTabs from 'apps/admin/components/common/DesktopTabs';
import { getAdminUser } from 'apps/admin/slices/adminSlice';
import AnalyticsWrapper, {
  ISummaryData,
} from 'apps/admin/components/common/AnalyticsWrapper';
import { DropDownOptionType } from 'apps/booking/components/common/inputs/DropDownInput';
import { useGetProjectDirectSiteVisitsCountQuery } from 'apps/admin/services/projectLeadsAPI';

export const searchFieldOptions: DropDownOptionType[] = [
  {
    value: 'leadName',
    displayText: 'Lead Name',
  },
  { value: 'phoneNumber', displayText: 'Phone Number' },
];

export const pageCount = 10;

export enum DIRECT_SITEVISIT_STATUS {
  ALL_DIRECT_SITE_VISITS = 'ALL_DIRECT_SITE_VISITS',
}

const ProjectDirectSiteVisits = (props: IProjectPartnerSiteVisitsProps) => {
  const { project } = props;

  const user = useSelector(getAdminUser);
  const { data: allSiteVisitsCount = 0, isLoading: allSiteVisitLoaded } =
    useGetProjectDirectSiteVisitsCountQuery({
      projectId: project.id,
    });

  const tabs: ITab[] = [
    {
      id: 'allDirectSiteVisits',
      name: 'All Direct Site-visits',
      content: (
        <DirectSitevisits
          projectId={project.id}
          siteVisitType={DIRECT_SITEVISIT_STATUS.ALL_DIRECT_SITE_VISITS}
          siteVisitCount={allSiteVisitsCount}
          isCountLoaded={allSiteVisitLoaded}
        />
      ),
      disabled: false,
    },
  ];

  const siteVisitsCountSummay: ISummaryData[] = [
    {
      details: {
        mainData: `${allSiteVisitsCount}`,
        changeData: '',
        changeDataClass: '',
        changeDesc: '',
        loaded: allSiteVisitLoaded,
      },
      titleIcon: 'group',
      title: 'All',
    },
  ];
  return (
    <>
      <AnalyticsWrapper summaryData={siteVisitsCountSummay} />
      <DesktopTabs tabs={tabs} />
    </>
  );
};

export default ProjectDirectSiteVisits;
