import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';

import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import TextInput from 'apps/booking/components/common/inputs/TextInput/index';
import Button from 'apps/booking/components/common/buttons/Button';
import { DetailsFormState } from 'apps/admin/components/pages/forms/SiteVisit/DetailsForm/index';
import { ISiteVisitDetails } from 'apps/admin/components/pages/forms/SiteVisit/index';
import {
  useOtpv2SendMutation,
  useOtpv2VerifyMutation,
} from 'apps/admin/services/adminUserAPISlice';
import useToast from 'hooks/useToast';
import { OTP_TEMPLATE_DEFAULT } from 'constants/smsEmailTemplates';
import {
  getCustomerDetails,
  setCustomerDetails,
} from 'apps/admin/slices/adminSlice';
import { ICustomerDetails } from 'apps/admin/interfaces';
import {
  useGetUserByMobileMutation,
  useAddAdminUserMutation,
  useUpdateAdminUserMutation,
} from 'apps/admin/services/adminUserAPISlice';
import { NOT_FOUND, SUCCESS } from 'constants/status';
import { UserRole } from 'constants/userRoles';

import styles from './styles.module.css';

//Local Interfaces
interface ICustomerDetailsProps {
  onGoToSelectProjectPage: Function;
  siteVisitDetails: ISiteVisitDetails;
  setSiteVisitDetails: Function; //TODO: remove function
  setDetailsFormState: Function;
}

// Validation
const schema = yup.object().shape({
  customerName: yup.string().required(),
  customerEmail: yup.string().email().required(),
  location: yup.string().required(),
});

const schemaMobile = yup.object().shape({
  customerMobile: yup
    .string()
    .matches(/^[0-9]*$/)
    .length(10)
    .required(),
});

const TIMER_LENGTH = 300;

const CustomerDetails = (props: ICustomerDetailsProps) => {
  const {
    setDetailsFormState,
    onGoToSelectProjectPage,
    siteVisitDetails,
    setSiteVisitDetails,
  } = props;

  const [otpState, setOtpState] = useState(false);
  const [customerMobile, setCustomerMobile] = useState('');
  const [otp, setOtp] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [countdown, setCountdown] = useState(TIMER_LENGTH);
  const [resendActive, setResendActive] = useState(false);

  const dispatch = useDispatch();
  const globalCustomerDetails = useSelector(getCustomerDetails);
  const [getUserByMobileAPI] = useGetUserByMobileMutation();
  const [addAdminUserAPI] = useAddAdminUserMutation();
  const [updateAdminUser] = useUpdateAdminUserMutation();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });

  const {
    register: registerMobile,
    handleSubmit: handleSubmitMobile,
    setValue: setValueMobile,
    formState: { errors: errorsMobile },
  } = useForm({
    resolver: yupResolver(schemaMobile),
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });

  const [otpv2SendAPI] = useOtpv2SendMutation();
  const [otpv2VerifyAPI] = useOtpv2VerifyMutation();
  const [addToast] = useToast();

  const buttonDisabled = useMemo(() => {
    if (otpState && otp.length !== 6) {
      return true;
    }
    return false;
  }, [otp, otpState]);

  const minutes = Math.floor(countdown / 60);
  const seconds = countdown % 60;

  // maintaining timer for resend OTP
  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (otpState) {
      timer = setTimeout(() => {
        if (countdown > 0) {
          setCountdown(countdown - 1);
        }
      }, 1000);
      if (!seconds && !minutes) {
        setResendActive(true);
      }
    }
    return () => clearTimeout(timer);
  }, [otpState, countdown, seconds]);

  useEffect(() => {
    setValue('customerName', globalCustomerDetails?.customerName);
    setValue('customerEmail', globalCustomerDetails?.customerEmail);
  }, [globalCustomerDetails]);

  useEffect(() => {
    if (globalCustomerDetails?.customerMobile?.length) {
      setCustomerMobile(globalCustomerDetails?.customerMobile);
    }
  }, []);

  const handleOtpSend = async (address: string) => {
    try {
      setIsLoading(true);

      await otpv2SendAPI({
        address,
        addressType: 'MOBILE',
        otpTemplate: OTP_TEMPLATE_DEFAULT,
      }).unwrap();

      addToast({
        type: 'SUCCESS',
        primaryMessage: `OTP Sent to ${address}`,
        timeout: 1000,
      });
      setOtpState(true);
      setCustomerMobile(address);
    } catch (err) {
      const errMsg = (err as any).data.message;
      addToast({
        type: 'ERROR',
        primaryMessage: errMsg,
        timeout: 1000,
      });
    } finally {
      setIsLoading(false);
    }
  };
  const handleOtpVerify = async () => {
    try {
      setIsLoading(true);
      let isValidCustomer = true;

      await otpv2VerifyAPI({
        address: customerMobile,
        otp,
      }).unwrap();

      await getUserByMobileAPI({
        mobile: customerMobile,
      })
        .unwrap()
        .then((response) => {
          if (response.code === SUCCESS) {
            if (response.data.role !== UserRole.LEAD) {
              addToast({
                type: 'ERROR',
                primaryMessage: `User already exists as ${response.data.role}`,
                timeout: 1000,
              });
              throw new Error();
            }
            dispatch(
              setCustomerDetails({
                customerId: response.data.id,
                customerName: response.data.name,
                customerEmail: response.data.email,
              })
            );
          }
        })
        .catch((error) => {
          if (error?.data?.code !== NOT_FOUND) {
            isValidCustomer = false;
          }
        });

      setOtpState(false);
      if (!isValidCustomer) {
        setValueMobile('customerMobile', '');
        return;
      }
      addToast({
        type: 'SUCCESS',
        primaryMessage: 'OTP verified successfully',
        timeout: 1000,
      });
      dispatch(setCustomerDetails({ customerMobile: customerMobile }));
    } catch (err) {
      const errMsg = (err as any).data.message;
      addToast({
        type: 'ERROR',
        primaryMessage: errMsg,
        timeout: 1000,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const customerDetailsNextBtnClickHandler = async (
    customerDetails: ICustomerDetails
  ) => {
    if (
      !customerDetails.customerName ||
      !customerDetails.customerEmail ||
      !customerDetails.location
    )
      return;

    if (!globalCustomerDetails?.customerId?.length) {
      await addAdminUserAPI({
        name: customerDetails.customerName,
        email: customerDetails.customerEmail,
        mobile: customerMobile,
        role: 'LEAD',
        password: '1234566',
      })
        .unwrap()
        .then((response) => {
          if (response.code === SUCCESS) {
            addToast({
              type: 'SUCCESS',
              primaryMessage: 'New customer created!',
              timeout: 1000,
            });
            customerDetails.customerMobile = customerMobile;
            dispatch(
              setCustomerDetails({
                customerId: response.data.id,
                customerName: response.data.name,
                customerEmail: response.data.email,
              })
            );
            setSiteVisitDetails((prevState: ISiteVisitDetails) => {
              return { ...prevState, ...customerDetails };
            });
            setDetailsFormState(DetailsFormState.SOURCESELECTION);
          }
        })
        .catch((error) => {
          addToast({
            type: 'ERROR',
            primaryMessage: error.data.message,
            timeout: 1000,
          });
        });
      return;
    }

    await updateAdminUser({
      userId: globalCustomerDetails?.customerId,
      user: {
        name: customerDetails.customerName,
        email: customerDetails.customerEmail,
        mobile: customerMobile,
      },
    })
      .unwrap()
      .then((response) => {
        if (response.code === SUCCESS) {
          addToast({
            type: 'SUCCESS',
            primaryMessage: 'Customer information successfully updated!',
            timeout: 1000,
          });
          customerDetails.customerMobile = customerMobile;
          dispatch(
            setCustomerDetails({
              customerId: response.data.id,
              customerName: response.data.name,
              customerEmail: response.data.email,
            })
          );
          setSiteVisitDetails((prevState: ISiteVisitDetails) => {
            return { ...prevState, ...customerDetails };
          });
          setDetailsFormState(DetailsFormState.SOURCESELECTION);
        }
      })
      .catch((error) => {
        addToast({
          type: 'ERROR',
          primaryMessage: error?.data?.message,
          timeout: 1000,
        });
      });
  };

  const customerMobileNextBtnClickHandler = async (
    customerDetails: ICustomerDetails
  ) => {
    if (!otpState) {
      if (customerDetails.customerMobile) {
        await handleOtpSend(customerDetails.customerMobile);
      }
    } else {
      handleOtpVerify();
    }
  };

  const customerDetailsBackBtnClickHandler = () => {
    dispatch(
      setCustomerDetails({
        customerId: '',
        customerName: '',
        customerEmail: '',
        customerMobile: '',
        location: '',
      })
    );
    setSiteVisitDetails({
      customerName: '',
      customerEmail: '',
      customerMobile: '',
      location: '',
    });
    setOtpState(false);
    setCustomerMobile('');
  };

  const customerMobileBackBtnClickHandler = () => {
    onGoToSelectProjectPage();
    setOtpState(false);
  };

  const handleOtpChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOtp(e.target.value);
  };

  const handleResendOtp = () => {
    if (resendActive) {
      handleOtpSend(customerMobile);
      setCountdown(TIMER_LENGTH);
      setResendActive(false);
    }
    return;
  };

  return (
    <>
      {globalCustomerDetails?.customerMobile?.length ? (
        <form
          onSubmit={handleSubmit(customerDetailsNextBtnClickHandler)}
          className={styles.customerFormWrapper}>
          <main>
            <div className={styles.formWrapper}>
              {globalCustomerDetails?.customerMobile?.length && (
                <>
                  <div className={styles.textField}>
                    <TextInput
                      label='Customer Name *'
                      placeHolder='Add Customer Name'
                      register={register}
                      setValue={setValue}
                      name='customerName'
                      defaultValue={siteVisitDetails.customerName}
                      errorMessage={
                        errors.customerName ? 'Customer name is required' : ''
                      }
                      labelStyles={styles.labelText}
                      readOnly={otpState}
                    />
                  </div>
                  <div className={styles.textField}>
                    <TextInput
                      label='Customer Email Id *'
                      placeHolder='Add Email Id'
                      register={register}
                      setValue={setValue}
                      name='customerEmail'
                      defaultValue={siteVisitDetails.customerEmail}
                      errorMessage={
                        errors.customerEmail && 'Email Id is required'
                      }
                      readOnly={otpState}
                      labelStyles={styles.labelText}
                    />
                  </div>
                  <div className={styles.textField}>
                    <TextInput
                      label='Customer Location *'
                      placeHolder='Add Location'
                      register={register}
                      setValue={setValue}
                      name='location'
                      defaultValue={siteVisitDetails.location}
                      readOnly={otpState}
                      errorMessage={
                        errors.location ? 'Location is required' : ''
                      }
                      labelStyles={styles.labelText}
                    />
                  </div>
                </>
              )}
            </div>
          </main>

          <footer className={styles.footer}>
            <Button
              propStyles={styles.backButton}
              onClick={customerDetailsBackBtnClickHandler}>
              Back
            </Button>
            <Button
              propStyles={styles.sendOtpButton}
              accent='primary'
              type='submit'
              disabled={buttonDisabled}
              loading={isLoading}>
              Next
            </Button>
          </footer>
        </form>
      ) : (
        <form
          onSubmit={handleSubmitMobile(customerMobileNextBtnClickHandler)}
          className={styles.customerFormWrapper}>
          <main>
            <div className={styles.formWrapper}>
              <div className={styles.textField}>
                <TextInput
                  label='Phone Number *'
                  placeHolder='Add Phone Number'
                  defaultValue={siteVisitDetails.customerMobile}
                  startIconText='+91'
                  type='number'
                  name='customerMobile'
                  errorMessage={
                    errorsMobile.customerMobile && 'Phone number is required'
                  }
                  maxLength={10}
                  register={registerMobile}
                  setValue={setValueMobile}
                  readOnly={otpState}
                  labelStyles={styles.labelText}
                />
              </div>
              {otpState && (
                <div className={styles.textField}>
                  <p className={styles.otpMessage}>
                    An 6 digit OTP has been sent to&nbsp;
                    <span
                      className={
                        styles.boldcustomerMobile
                      }>{`+91  ${customerMobile}`}</span>
                  </p>
                  <TextInput
                    label='OTP'
                    placeHolder={'Enter OTP'}
                    onBottomMessageClick={handleResendOtp}
                    onChange={handleOtpChange}
                    maxLength={6}
                    autoFocus={true}
                    labelStyles={styles.labelText}
                  />
                  <span className={styles.bottomText} onClick={handleResendOtp}>
                    {!resendActive &&
                      `OTP valid till ${minutes}:${
                        seconds < 10 ? '0' : ''
                      }${seconds} |`}
                    <span
                      className={styles.resendOtp}
                      data-active={resendActive}>
                      &nbsp;Resend OTP
                    </span>
                  </span>
                </div>
              )}
            </div>
          </main>

          <footer className={styles.footer}>
            <Button
              propStyles={styles.backButton}
              onClick={customerMobileBackBtnClickHandler}>
              Back
            </Button>
            <Button
              propStyles={styles.sendOtpButton}
              accent='primary'
              type='submit'
              disabled={buttonDisabled}
              loading={isLoading}>
              Next
            </Button>
          </footer>
        </form>
      )}
    </>
  );
};

export default CustomerDetails;
