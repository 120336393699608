//React imports
import { useRef } from 'react';

//Component Imports
import Modal from 'apps/booking/components/common/modal/Modal';
import Button from 'apps/booking/components/common/buttons/Button';
import Icon from 'apps/booking/components/common/Icon';

//Constants Imports

//CSS Import
import styles from './styles.module.css';

interface IScheduledDemoModal {
  onClose: Function;
  showModal: Boolean;
  onRescheduleBtnClick: Function;
}

const ScheduledDemoModal = (props: IScheduledDemoModal) => {
  const { onClose, showModal, onRescheduleBtnClick } = props;
  const demoMessageRef = useRef<HTMLInputElement>(null);

  const scheduledDemoDetails = [
    { title: 'Meeting ID', desc: 'Meeting ID', value: '23GHX432' },
    { title: 'Date for Demo', desc: 'Upcoming Today', value: "30th Jan '22" },
    { title: 'Time of Demo', desc: 'Upcoming in 5 min', value: '4:30pm IST' },
    {
      title: 'Approx. Duration',
      desc: 'Demo ends by 5:00pm',
      value: '30 min',
    },
  ];

  const handelCopyToClipboardButtonClick = () => {
    navigator.clipboard.writeText('demo.relata.io/123cyt32');
  };

  return (
    <div className={styles.modalContainer}>
      <Modal
        show={showModal}
        onOutsideClick={() => onClose()}
        additionalClasses={`${styles.modalAdditionalClasses}`}>
        {/*Modal Header*/}
        <header className={styles.modalHeader}>
          <div className={styles.modalHeadingContainer}>
            <h2 className={styles.modalHeading}>Your Upcoming Demo</h2>
            <Icon
              name='close'
              propStyles={styles.crossIcon}
              onClick={() => onClose()}
            />
          </div>
          <p className={styles.headerDes}>
            Choose the discount you want to apply for this query. You will see
            the final cost reflected on the Summary being showed taking into
            account the.
          </p>
        </header>
        {/*Modal Body*/}
        <div className={styles.bodyContainer}>
          <div className={styles.bodyLeftContainer}>
            <main className={styles.modalMainContainer}>
              {scheduledDemoDetails.map((individualDetail) => (
                <div
                  className={styles.individualDetailsContainer}
                  key={individualDetail.title}>
                  <div>
                    <p className={styles.detailsTitle}>
                      {individualDetail.title}
                    </p>
                    <p className={styles.detailsDesc}>
                      {individualDetail.desc}
                    </p>
                  </div>
                  <h2 className={styles.detailsValue}>
                    {individualDetail.value}
                  </h2>
                </div>
              ))}
            </main>
            <div className={styles.passwordContainer}>
              <div>
                <p className={styles.detailsTitle}>Host Password</p>
                <p className={styles.detailsDesc}>Needed for VSG Guided Demo</p>
              </div>
              <h2 className={styles.detailsValue}>SH56URD</h2>
            </div>

            {/*Modal Footer*/}
            <footer className={styles.modalFooter}>
              <Button propStyles={styles.modalGenericButton}>
                Join VSG for Guided Demo
              </Button>
              <Button
                propStyles={`${styles.modalGenericButton} ${styles.buttonRed}`}>
                Connect with Fallback
              </Button>
              <Button
                propStyles={`${styles.modalGenericButton} ${styles.buttonTransparent}`}>
                Join VSG for Guided Demo
              </Button>
            </footer>
          </div>
          <div className={styles.bodyRightContainer}>
            <div className={styles.demoMessageContainer}>
              <p ref={demoMessageRef} className={styles.demoMessage}>
                Join a quick demo session with me where I can take you through
                the beautiful offerings of Hiranandani Marina Future City.
                <br />
                <br />
                Connect with me on this URL on
                <span className={styles.boldDemoMessage}>
                  {' '}
                  19th Jan at 4:30pm{' '}
                </span>
                to meet your dream home.
                <span className={styles.boldDemoMessage}>
                  {' '}
                  demo.relata.io/123cyt32
                </span>{' '}
                <br />
                <br />
                Google Meet Link: zoom.relata.io/123cyt32
              </p>
              <div
                className={styles.copyToClipboardBtn}
                onClick={handelCopyToClipboardButtonClick}>
                <Icon
                  name='content_copy'
                  propStyles={styles.copyIcon}
                  onClick={() => onClose()}
                />
                <p>Copy to clipboard</p>
              </div>
              <div className={styles.rescheduleDemoBtnContainer}>
                <Button
                  propStyles={styles.rescheduleDemoBtn}
                  onClick={() => {
                    onRescheduleBtnClick();
                  }}>
                  Reschedule Demo
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ScheduledDemoModal;
