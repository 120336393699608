import { useState } from 'react';

import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import useToast from 'hooks/useToast';
import {
  useAddCompanyMutation,
  useGetCompaniesQuery,
} from 'apps/admin//services/companyAPISlice';
import { useAddProjectMutation } from 'apps/admin/services/projectsAPISlice';
import Modal from 'apps/booking/components/common/modal/Modal';
import TextInput from 'apps/booking/components/common/inputs/TextInput';
import DropDownInput, {
  DropDownOptionType,
} from 'apps/booking/components/common/inputs/DropDownInput';
import Button from 'apps/booking/components/common/buttons/Button';
import Icon from 'apps/booking/components/common/Icon';
import SecondaryCompanyList from 'apps/admin/components/pages/SuperAdminDashboard/SuperAdminProjectList/modals/AddNewProjectModal/SecondaryCompanyList';
import { ICompany } from 'apps/admin/interfaces';
import { IProject } from 'apps/admin/components/pages/SuperAdminDashboard/SuperAdminProjectList';

import styles from './styles.module.css';

interface IAddNewProjectModal {
  handleModalClose: Function;
  showProjectAddedModal: Function;
}

const defaultDeveloperDetails = {
  name: '',
  displayName: '',
  rera: '',
  websiteUrl: '',
  hqAddress: '',
} as ICompany;

// Validation
const schema = yup.object().shape({
  name: yup.string().required(),
  rera: yup.string().required(),
  city: yup.string().required(),
  reraUrl: yup.string().required(),
  pocEmail: yup.string().email().required(),
  smCpOnboardingDate: yup.string().required(),
  pocMobile: yup
    .string()
    .matches(/^[0-9]*$/)
    .length(10)
    .required(),
  company: yup.object({
    name: yup.string().required(),
    displayName: yup.string().required(),
    gstNumber: yup.string().required(),
    websiteUrl: yup.string().required(),
    hqAddress: yup.string().required(),
    rera: yup.string().required(),
  }),
});

const AddNewProjectModal = (props: IAddNewProjectModal) => {
  const { handleModalClose, showProjectAddedModal } = props;

  const {
    register,
    unregister,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });

  const [isCreatingNewDeveloper, setIsCreatingNewDeveloper] = useState(false);
  const [developerDetails, setDeveloperDetails] = useState(
    defaultDeveloperDetails
  );
  const [addToast] = useToast();

  const [addProject] = useAddProjectMutation();
  const [addCompanyAPI] = useAddCompanyMutation();

  const { data: companies } = useGetCompaniesQuery({});
  const developersDropdownOptions: DropDownOptionType[] =
    companies?.map((company) => {
      return {
        value: company.id as string,
        displayText: company.displayName as string,
      };
    }) || [];

  const setDeveloperDetailsWrapper = (details: ICompany) => {
    setDeveloperDetails(details);
    setValue('company.id', details.id);
    setValue('company.name', details.name);
    setValue('company.displayName', details.displayName);
    setValue('company.gstNumber', details.gstNumber);
    setValue('company.websiteUrl', details.websiteUrl);
    setValue('company.hqAddress', details.hqAddress);
    setValue('company.rera', details.rera);
  };

  const handleNewDveloper = () => {
    setDeveloperDetailsWrapper({
      id: '',
      name: '',
      displayName: '',
      gstNumber: '',
      websiteUrl: '',
      hqAddress: '',
      pocEmail: '',
      pocMobile: '',
      users: [],
      rera: '',
    });
    setIsCreatingNewDeveloper(!isCreatingNewDeveloper);
  };

  const handelDeveloperDropdownSelection = (option: DropDownOptionType[]) => {
    // TODO: Dropdown should return single option
    const company: ICompany = companies?.find(
      (company) => company.id === option[0]?.value
    ) as ICompany;

    if (!company) {
      return;
    }

    setDeveloperDetailsWrapper({
      id: company.id,
      name: company.name,
      displayName: company.displayName,
      gstNumber: company.gstNumber,
      websiteUrl: company.websiteUrl,
      hqAddress: company.hqAddress,
      pocEmail: company.pocEmail,
      pocMobile: company.pocMobile,
      users: company.users,
      rera: company.rera,
    });
  };

  const bindDeveloperDetails = (key: string) => {
    return (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      setValue(`company.${key}`, value);
      setDeveloperDetails((prev) => {
        return { ...prev, [key]: value };
      });
    };
  };

  const handleCreateProject = async (project: IProject) => {
    let companyId = project.company.id;
    if (isCreatingNewDeveloper) {
      try {
        delete project.company.id;
        const company = await addCompanyAPI(project.company).unwrap();
        companyId = company.data.id;
      } catch (err) {
        const errMsg = (err as any).data.message;
        addToast({
          type: 'ERROR',
          primaryMessage: errMsg,
          secondaryMessage: 'Project creation failed',
          timeout: 1000,
        });
        return;
      }
    }

    try {
      const updatedProject = JSON.parse(JSON.stringify(project));
      updatedProject['companyId'] = companyId;
      delete updatedProject.company;
      // One year expiry
      updatedProject.expiryDate = new Date(
        new Date().setFullYear(new Date().getFullYear() + 1)
      );

      const projectAddedResponse = await addProject(updatedProject).unwrap();

      showProjectAddedModal({
        projectName: project.name,
        CompanyName: project.company.name,
        projectId: projectAddedResponse.data.id,
        projectCity: project.city,
      });

      handleModalClose();
    } catch (err) {
      const errMsg = (err as any).data.message;
      addToast({
        type: 'ERROR',
        primaryMessage: errMsg,
        secondaryMessage: '',
        timeout: 1000,
      });
    } finally {
    }
  };

  return (
    <div>
      <Modal
        onOutsideClick={() => handleModalClose()}
        additionalClasses={styles.modalAdditionalClasses}>
        {/* Modal Header */}
        <header className={styles.modalHeader}>
          <div className={styles.modalHeaderLeftContent}>
            <h1>Create a New Project!</h1>
            <p>
              Fill in the following info to create a new project associated to
              the account of the selected developer. You will receive Project ID
              that you will need at different steps.
            </p>
          </div>
          <Icon
            name='close'
            propStyles={styles.modalCloseIcon}
            onClick={() => handleModalClose()}
          />
        </header>
        {/* Modal Body */}
        <main>
          <div className={styles.bodyIndividualdRow}>
            <div className={styles.inputsContainer}>
              <TextInput
                label='Project Name'
                placeHolder='Add Name of the Project'
                register={register}
                setValue={setValue}
                name='name'
                errorMessage={errors.name ? 'Project Name is required' : ''}
              />
            </div>
            <div className={styles.inputsContainer}>
              <TextInput
                label='Project RERA'
                placeHolder='Add Regd RERA number'
                register={register}
                setValue={setValue}
                name='rera'
                errorMessage={errors.rera ? 'Project RERA is required' : ''}
              />
            </div>
          </div>
          <div className={styles.bodyIndividualdRow}>
            <div className={styles.inputsContainer}>
              <TextInput
                label='City of the Project'
                placeHolder='Add Name of the City'
                register={register}
                setValue={setValue}
                name='city'
                errorMessage={
                  errors.city ? 'City of the Project is required' : ''
                }
              />
            </div>
            <div className={styles.inputsContainer}>
              <TextInput
                label='Project RERA URL'
                placeHolder='Add applicable RERA site'
                register={register}
                setValue={setValue}
                name='reraUrl'
                errorMessage={
                  errors.reraUrl ? 'Project RERA URL is required' : ''
                }
              />
            </div>
          </div>
          <div className={styles.bodyIndividualdRow}>
            <div className={styles.inputsContainer}>
              <TextInput
                label='On-boarding Date'
                placeHolder=''
                type='date'
                register={register}
                setValue={setValue}
                unregister={unregister}
                name='smCpOnboardingDate'
                errorMessage={
                  errors.smCpOnboardingDate && 'On-boarding Date is required'
                }
              />
            </div>
          </div>
          <div className={styles.bodyIndividualdRow}>
            <div
              className={`${styles.inputsContainer} ${
                isCreatingNewDeveloper
                  ? styles.developerInputContainer
                  : styles.developerDropDownContainer
              }`}>
              {isCreatingNewDeveloper ? (
                // TODO: displayName should be unique for all compnies
                <div className={styles.addDeveloperTextInputContainer}>
                  <TextInput
                    label='Company Display Name'
                    placeHolder='Add Company Display Name'
                    onChange={bindDeveloperDetails('displayName')}
                    defaultValue={developerDetails.displayName}
                    errorMessage={
                      errors.company?.displayName
                        ? 'Company Display Name is required'
                        : ''
                    }
                  />
                </div>
              ) : (
                <DropDownInput
                  options={developersDropdownOptions}
                  lable='Select Company'
                  onValueSelected={handelDeveloperDropdownSelection}
                  register={register}
                  setValue={setValue}
                  name='company.id'
                />
              )}
              <div
                role='button'
                onClick={handleNewDveloper}
                className={styles.addDeveloperButton}>
                <Icon
                  name='close'
                  propStyles={`${styles.addNewDeveloperIcon} ${
                    isCreatingNewDeveloper ? '' : styles.rotateAddDeveloperIcon
                  } click-enabled`}
                />
              </div>
            </div>
            <div className={styles.inputsContainer}>
              <TextInput
                label='Rera Number'
                placeHolder='Add Rera Number'
                onChange={bindDeveloperDetails('rera')}
                disabled={!isCreatingNewDeveloper}
                defaultValue={developerDetails.rera}
                errorMessage={
                  errors.company?.rera ? 'Company Rera is required' : ''
                }
              />
            </div>
          </div>
          <div className={styles.bodyIndividualdRow}>
            <div className={styles.inputsContainer}>
              <TextInput
                label='Company Name'
                placeHolder='Add Company Name'
                onChange={bindDeveloperDetails('name')}
                disabled={!isCreatingNewDeveloper}
                defaultValue={developerDetails.name}
                errorMessage={
                  errors.company?.name ? 'Company Name is required' : ''
                }
              />
            </div>
            <div className={styles.inputsContainer}>
              <TextInput
                label='GST Number'
                placeHolder='Add GST Number'
                onChange={bindDeveloperDetails('gstNumber')}
                disabled={!isCreatingNewDeveloper}
                defaultValue={developerDetails.gstNumber}
                errorMessage={
                  errors.company?.gstNumber ? 'Company GST is required' : ''
                }
              />
            </div>
          </div>
          <div className={styles.bodyIndividualdRow}>
            <div className={styles.inputsContainer}>
              <TextInput
                label='Company Website URL'
                placeHolder='Add Company Website URL'
                onChange={bindDeveloperDetails('websiteUrl')}
                disabled={!isCreatingNewDeveloper}
                defaultValue={developerDetails.websiteUrl}
                errorMessage={
                  errors.company?.websiteUrl
                    ? 'Valid company websiteUrl URL is required'
                    : ''
                }
              />
            </div>
            <div className={styles.inputsContainer}>
              <TextInput
                label='Registered HQ Address'
                placeHolder='Registered HQ Address'
                onChange={bindDeveloperDetails('hqAddress')}
                disabled={!isCreatingNewDeveloper}
                defaultValue={developerDetails.hqAddress}
                errorMessage={
                  errors.company?.hqAddress
                    ? 'Registered HQ Address is required'
                    : ''
                }
              />
            </div>
          </div>
          <div className={styles.formDividerLineContainer}></div>
          <SecondaryCompanyList
            companyList={companies || []}
            primaryCompanyId={developerDetails.id}
            register={register}
            unregister={unregister}
            setValue={setValue}
            name='secondaryCompanies'
          />
          <div className={styles.formDividerLineContainer}></div>
          <div className={styles.bodyIndividualdRow}>
            <div
              className={`${styles.inputsContainer} ${styles.contactDetailsInputContainer}`}>
              <TextInput
                label='Point of Contact Email'
                placeHolder='POC Email ID'
                register={register}
                setValue={setValue}
                name='pocEmail'
                errorMessage={
                  errors.pocEmail ? 'Please enter valid POC email' : ''
                }
                bottomMessage='Taken from last project of the developer'
              />
            </div>
            <div
              className={`${styles.inputsContainer} ${styles.contactDetailsInputContainer}`}>
              <TextInput
                label='Point of Contact Mobile'
                placeHolder='POC Mobile Number'
                register={register}
                setValue={setValue}
                name='pocMobile'
                errorMessage={
                  errors?.pocMobile ? 'POC Mobile Number is required' : ''
                }
                bottomMessage='Taken from last project of the developer'
              />
            </div>
          </div>
        </main>
        {/* Modal Footer */}
        <footer className={styles.footer}>
          <p className={styles.footerDisclaimer}>
            By clicking Apply Discount you are making updates to the cost sheet
            that the user can view. Confirm discount on the Summary on the right
            before clicking on Apply Discount
          </p>
          <div className={styles.createProjectBtnContainer}>
            <Button
              type='submit'
              propStyles={`${styles.createProjectBtn}`}
              onClick={() => handleSubmit(handleCreateProject)()}>
              Create Project
            </Button>
          </div>
        </footer>
      </Modal>
    </div>
  );
};

export default AddNewProjectModal;
