// Components
import Modal from 'apps/booking/components/common/modal/Modal';
import Icon from 'apps/booking/components/common/Icon/index';
import Button from 'apps/booking/components/common/buttons/Button';
import { ICompany } from 'apps/admin/interfaces';

// Styles
import styles from './styles.module.css';

// Local Interfaces
interface IOrganizationAddedSuccessfullyModal {
  company: ICompany;
  onModalClose: Function;
}

const AddedCompanySuccessfulModal = (
  props: IOrganizationAddedSuccessfullyModal
) => {
  const { company, onModalClose } = props;

  const leftColumn = [
    {
      label: 'Company Display Name',
      value: company.displayName,
    },
    {
      label: 'GST Number',
      value: company.gstNumber,
    },
    {
      label: 'Registered HQ Address',
      value: company.hqAddress,
    },
    {
      label: 'Point of Contact Mobile',
      value: company.pocMobile,
    },
  ];

  const rightColumn = [
    {
      label: 'Company Name',
      value: company.name,
    },
    {
      label: 'Company Website URL',
      value: company.websiteUrl,
    },
    {
      label: 'Point of Contact Email',
      value: company.pocEmail,
    },
  ];

  return (
    <Modal
      additionalClasses={styles.organizationAddedSuccessfullyModal}
      onOutsideClick={onModalClose}>
      <header className={styles.modalHeader}>
        <Icon name='task_alt' propStyles={styles.taskCompleteIcon} />
        <div>
          <div className={styles.headingAndCloseIconContainer}>
            <h2 className={styles.modalHeading}>Company Added Successfully</h2>
            <Icon
              name='close'
              propStyles={styles.closeIcon}
              onClick={() => onModalClose()}
            />
          </div>
          <p className={styles.modalDesc}>
            Here you will find the all information about company. Now you can
            edit and add company details at any time.
          </p>
        </div>
      </header>

      <main className={styles.organizationDetailContainer}>
        <div className={styles.organizationDetailsIndividualColumns}>
          {leftColumn.map((individualDetail) => (
            <div className={styles.individualDetailContainer}>
              <h2 className={styles.individualDetailValue}>
                {individualDetail.value}
              </h2>
              <p className={styles.individualDetailLabel}>
                {individualDetail.label}
              </p>
            </div>
          ))}
        </div>
        <div className={styles.organizationDetailsIndividualColumns}>
          {rightColumn.map((individualDetail) => (
            <div className={styles.individualDetailContainer}>
              <h2 className={styles.individualDetailValue}>
                {individualDetail.value}
              </h2>
              <p className={styles.individualDetailLabel}>
                {individualDetail.label}
              </p>
            </div>
          ))}
        </div>
      </main>

      <footer className={styles.footer}>
        <div className={styles.doneBtnContainer}>
          <Button accent='primary' onClick={() => onModalClose()}>
            Done
          </Button>
        </div>
      </footer>
    </Modal>
  );
};

export default AddedCompanySuccessfulModal;
