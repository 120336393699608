import React, { useEffect, useState } from 'react';

import styles from './styles.module.css';

export interface ITab {
  id: string;
  name: string;
  content: React.ReactNode | string;
  disabled: false;
  countNumber?: number;
}

export interface ITabsProps {
  selectedTab?: ITab;
  tabs: ITab[];
  propStyles?: string;
}

const Tabs = (props: ITabsProps) => {
  const { selectedTab: _selectedTab, tabs, propStyles } = props;

  const [selectedTab, setSelectedTab] = useState<ITab>();

  useEffect(() => {
    setSelectedTab(_selectedTab || tabs[0]);
  }, [tabs]);

  return (
    <>
      <div className={`${styles.tabs} ${propStyles}`}>
        {tabs.map((tab) => (
          <p
            data-tab-active={tab.id === selectedTab?.id}
            className={`${styles.tabItem} ${propStyles}`}
            onClick={() => setSelectedTab(tab)}>
            {tab.countNumber && (
              <span className={styles.countText}>{tab.countNumber}</span>
            )}
            &nbsp;{tab.name}
          </p>
        ))}
      </div>
      <div className={styles.contentWrapper}>{selectedTab?.content}</div>
    </>
  );
};

export default Tabs;
