import React, { useState } from 'react';

import DropDownInput, {
  DropDownOptionType,
} from 'apps/booking/components/common/inputs/DropDownInput';
import Button from 'apps/booking/components/common/buttons/Button';
import { DetailsFormState } from 'apps/admin/components/pages/forms/SiteVisit/DetailsForm/index';
import {
  ISiteVisitDetails,
  SiteVisitState,
} from 'apps/admin/components/pages/forms/SiteVisit/index';

import styles from './styles.module.css';
import { useAddSiteVisitMutation } from 'apps/admin/services/siteVisitAPI';
import { addToast } from 'slices/toasts';

interface ISourceSlectionProps {
  setDetailsFormState: (state: DetailsFormState) => void;
  setSiteVisitState: (state: SiteVisitState) => void;
  siteVisitDetails: ISiteVisitDetails;
  setSiteVisitDetails: (details: ISiteVisitDetails) => void;
}

const SourceSlection = (props: ISourceSlectionProps) => {
  const {
    setDetailsFormState,
    setSiteVisitState,
    siteVisitDetails,
    setSiteVisitDetails,
  } = props;
  const [detailsSubmitted, setDetailsSubmitted] = useState(false);
  const [addDirectSiteVisitAPI] = useAddSiteVisitMutation();
  const [isSkipButtonDisabled, setIsSkipButtonDisabled] =
    useState<boolean>(true);

  const options: DropDownOptionType[] = [
    {
      value: 'Channel Partner',
      displayText: 'Channel Partner',
    },
    {
      value: 'Direct',
      displayText: 'Direct',
    },
  ];

  const handleBack = () => {
    setDetailsFormState(DetailsFormState.CUSTOMER);
  };

  const handleDropDownSelection = (selectedOption: DropDownOptionType[]) => {
    selectedOption[0].value === 'Direct'
      ? setIsSkipButtonDisabled(false)
      : setIsSkipButtonDisabled(true);
  };

  const handleShowQrCode = () => {
    setSiteVisitState(SiteVisitState.QRCODE_SCANNER);
  };

  const handleSubmit = async () => {
    try {
      setDetailsSubmitted(true);

      await addDirectSiteVisitAPI(siteVisitDetails).unwrap();
      addToast({
        type: 'SUCCESS',
        primaryMessage: 'Direct Site Visit details added',
        secondaryMessage: '',
        timeout: 1000,
      });

      setSiteVisitState(SiteVisitState.SUMMERY);
    } catch (err) {
      const errMsg = (err as any).data.message;
      addToast({
        type: 'ERROR',
        primaryMessage: errMsg,
        timeout: 1000,
      });
    } finally {
      setDetailsSubmitted(false);
    }
  };

  return (
    <div className={styles.selctionWrapper}>
      <main>
        <DropDownInput
          options={options}
          lable='Source'
          defaultSelectedOption={options[0]}
          disabled={false}
          onValueSelected={handleDropDownSelection}
        />
        <p className={styles.qrDesc}>
          Scan the QR code to get the information of lead
        </p>
        <Button
          accent='primary'
          onClick={handleShowQrCode}
          propStyles={styles.scanQr}
          disabled={!isSkipButtonDisabled}>
          Scan QR Code
        </Button>
      </main>

      <footer className={styles.footer}>
        <Button propStyles={styles.backButton} onClick={handleBack}>
          Back
        </Button>
        <Button
          propStyles={styles.sendOtpButton}
          accent='primary'
          loading={detailsSubmitted}
          disabled={isSkipButtonDisabled}
          onClick={handleSubmit}>
          Skip
        </Button>
      </footer>
    </div>
  );
};

export default SourceSlection;
