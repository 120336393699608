import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import Modal from 'apps/booking/components/common/modal/Modal';
import Icon from 'apps/booking/components/common/Icon/index';
import TextInput from 'apps/booking/components/common/inputs/TextInput/index';
import Button from 'apps/booking/components/common/buttons/Button';
import useToast from 'hooks/useToast';
import { UserRole } from 'constants/userRoles';
import { useAddCompanyUserMutation } from 'apps/admin/services/companyAPISlice';
import { capitalizeFirstLetter, randomId } from 'utils/utils';

import styles from './styles.module.css';

interface ICreateNewExecutiveModal {
  role: UserRole;
  onModalClose: Function;
  companyId: string;
}

// Validation
export const userSchema = yup.object().shape({
  email: yup.string().email().required(),
  name: yup.string().required(),
  mobile: yup
    .string()
    .matches(/^[0-9]*$/)
    .length(10)
    .required(),
});

const AddCompanyUserModal = (props: ICreateNewExecutiveModal) => {
  const { role, onModalClose, companyId } = props;

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(userSchema),
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });

  const [addToast] = useToast();
  const [addCompanyUserAPI] = useAddCompanyUserMutation();

  const userRoleDisplayText: { [key: string]: string } = {
    [UserRole.ADMIN]: 'Admin',
    [UserRole.EXECUTIVE]: 'Executive',
  };

  const handleCreateExecutiveFormSubmit = async (executive: {
    email: string;
    name: string;
    mobile: string;
    jobTitle: string;
  }) => {
    try {
      await addCompanyUserAPI({
        user: {
          ...executive,
          role,
          password: randomId(),
        },
        companyId,
      }).unwrap();

      addToast({
        type: 'SUCCESS',
        primaryMessage: `Added new ${capitalizeFirstLetter(
          role.toLowerCase()
        )}`,
      });

      onModalClose();
    } catch (err) {
      const errMsg = (err as any).data.message;
      addToast({
        type: 'ERROR',
        primaryMessage: errMsg,
        secondaryMessage: 'Failed to add new Exec',
      });
    }
  };

  return (
    // Styles here are being used from CreateNewAdminModal's CSS file as there is same styling for two modals and to follow DRY principle
    <Modal
      onOutsideClick={onModalClose}
      additionalClasses={styles.createNewAdminModal}>
      <header className={styles.modalHeader}>
        <div>
          <h2 className={styles.modalHeaderHeading}>
            Add New {userRoleDisplayText[role]}
          </h2>
          <p className={styles.modalHeaderDes}>
            Fill in the following info to add a new {userRoleDisplayText[role]}.
            Admin have access to add new executive.
          </p>
        </div>
        <Icon
          name='close'
          propStyles={styles.closeIcon}
          onClick={() => {
            onModalClose();
          }}
        />
      </header>
      <form onSubmit={handleSubmit(handleCreateExecutiveFormSubmit)}>
        <main>
          <TextInput
            label={`${userRoleDisplayText[role]} Company EmailID`}
            placeHolder={`Add Email of the ${userRoleDisplayText[role]}`}
            register={register}
            setValue={setValue}
            name='email'
            errorMessage={errors.email && 'Email is required'}
          />
          <div className={styles.twoTextInputsRowContainer}>
            <div className={styles.addNameInput}>
              <TextInput
                label='Full Name'
                placeHolder='Add Name'
                register={register}
                setValue={setValue}
                name='name'
                errorMessage={errors.name && 'Name is required'}
              />
            </div>
            <div className={styles.addNameInput}>
              <TextInput
                label='Mobile Number'
                placeHolder='Add Mobile'
                register={register}
                setValue={setValue}
                name='mobile'
                maxLength={10}
                errorMessage={errors.mobile && 'Mobile number is required'}
              />
            </div>
          </div>
        </main>

        <footer className={styles.modalFooter}>
          <p className={styles.footerDisclaimer}>
            By adding {capitalizeFirstLetter(role.toLowerCase())}, an
            autogenerated email will be sent to the{' '}
            {capitalizeFirstLetter(role.toLowerCase())} with their randomly
            generated password and URL for the dashboard. Do this step the last,
            just before going for UAT.
          </p>
          <Button
            propStyles={styles.footerAddAdminButton}
            accent='primary'
            type='submit'>
            Add {userRoleDisplayText[role]}
          </Button>
        </footer>
      </form>
    </Modal>
  );
};

export default AddCompanyUserModal;
