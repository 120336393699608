export const NOT_FOUND = 'NOT_FOUND';
export const FORBIDDEN = 'FORBIDDEN';
export const ERROR = 'ERROR';
export const SUCCESS = 'SUCCESS';
export const UNDEFINED = 'NA';

export const CURRENT_STEP_STATE = {
  ACTIVE: 'ACTIVE',
  ACTIVE_BOOKING_ENABLED: 'ACTIVE_BOOKING_ENABLED',
  ACTIVE_BOOKING_DISABLED: 'ACTIVE_BOOKING_DISABLED',
  COMPLETED: 'COMPLETED',
  INACTIVE: 'INACTIVE',
};

export const BLOCK_UNIT_SUCCESS = 'BLOCK_UNIT_SUCCESS';
export const BLOCK_UNIT_FAIL = 'BLOCK_UNIT_FAIL';

export const BOOK_UNIT_SUCCESS = 'BOOK_UNIT_SUCCESS';
export const BOOK_UNIT_FAIL = 'BOOK_UNIT_FAIL';

export const BOOKED = 'BOOKED';
export const BLOCKED = 'BLOCKED';

export enum ProjectStatus {
  ONGOING = 'ONGOING',
  HOLD = 'HOLD',
  ONBOARDING = 'ONBOARDING',
  RENEWAL = 'RENEWAL',
  EXPIRED = 'EXPIRED',
}

export enum UNIT_STATUSES {
  AVAILABLE = 'AVAILABLE',
  UNAVAILABLE = 'UNAVAILABLE',
  BOOKED = 'BOOKED',
  RESERVED = 'RESERVED',
  BLOCKED = 'BLOCKED',
  PURCHASED = 'PURCHASED',
  POSSESSION = 'POSSESSION',
  OUT_OF_PREFERENCE = 'OUT_OF_PREFERENCE',
  TAG_OUT_OF_PREFERENCE = 'TAG_OUT_OF_PREFERENCE',
}

type UnitStatusesWithIndex = {
  [key in UNIT_STATUSES]: number;
};

export const UNIT_STATUSES_INDEX: UnitStatusesWithIndex = {
  [UNIT_STATUSES.UNAVAILABLE]: 0,
  [UNIT_STATUSES.AVAILABLE]: 1,
  [UNIT_STATUSES.RESERVED]: 2,
  [UNIT_STATUSES.BLOCKED]: 3,
  [UNIT_STATUSES.BOOKED]: 4,
  [UNIT_STATUSES.PURCHASED]: 5,
  [UNIT_STATUSES.POSSESSION]: 6,
  [UNIT_STATUSES.OUT_OF_PREFERENCE]: -1,
  [UNIT_STATUSES.TAG_OUT_OF_PREFERENCE]: -1,
};
export enum ACCESS {
  READ = 'READ',
  WRITE = 'WRITE',
}

export enum LEAD_PROJECT_STATUS {
  ASSIGN = 'ASSIGN',
  UNASSIGN = 'UNASSIGN',
  SCHEDULED_SITE_VISIT = 'SCHEDULED_SITE_VISIT',
  SHORTLISTED = 'SHORTLISTED',
  BOOKED = 'BOOKED',
}

export enum LEAD_STATUS {
  INTEREST = 'INTEREST',
  ASSIGN = 'ASSIGN',
  UNASSIGN = 'UNASSIGN',
  DECLINE = 'DECLINE',
  SITE_VISIT = 'SITE_VISIT',
  JUST_ADDED = 'JUST_ADDED',
  SHORTLISTING = 'SHORTLISTING',
  SCHEDULED_SITE_VISIT = 'SCHEDULED_SITE_VISIT',
  BOOKED = 'BOOKED',
}

export enum EoiStatus {
  ACTIVE = 'ACTIVE', // ACTIVE_BOOKING_DISABLED and ACTIVE_BOOKING_ENABLED refers to ACTIVE
  ACTIVE_BOOKING_DISABLED = 'ACTIVE_BOOKING_DISABLED',
  ACTIVE_BOOKING_ENABLED = 'ACTIVE_BOOKING_ENABLED',
  CANCELLED = 'CANCELLED',
  EXPIRED = 'EXPIRED',
  INTERESTED = 'INTERESTED',
  BOOKED = 'BOOKED',
  BLOCKED = 'BLOCKED',
}

export enum EoiBookingStatus {
  ACTIVE_BOOKING_DISABLED = 'ACTIVE_BOOKING_DISABLED',
  ACTIVE_BOOKING_ENABLED = 'ACTIVE_BOOKING_ENABLED',
}

export enum UnitAvailableStatus {
  AVAILABLE = 'Available',
  UNAVAILABLE = 'Unavailable',
}
