import { useState } from 'react';

import { ICellRendererParams } from 'ag-grid-community';

import { IProject } from 'apps/admin/components/pages/SuperAdminDashboard/SuperAdminProjectList';
import Icon from 'apps/booking/components/common/Icon';
import { IDemoRoom } from 'apps/admin/components/pages/SuperAdminDashboard/SuperAdminProjectList/modals/AddDemoRoomModal';
import UpdateDemoRoomModal from 'apps/admin/components/pages/SuperAdminDashboard/SuperAdminProjectList/modals/UpdateDemoRoomModal';

import styles from '../styles.module.css';

interface IUpdateDemoRoomCell {
  demoRoom: IDemoRoom;
  projectId: string;
  onDemoRoomUpdate: (updateProject: IProject) => {};
  rowNode: ICellRendererParams['node'];
}

const UpdateDemoRoomCell = (props: IUpdateDemoRoomCell) => {
  const { demoRoom, projectId, onDemoRoomUpdate, rowNode } = props;

  const [updateModalShown, setUpdateModalShown] = useState(false);

  const handleDemoRoomUpdate = (updatedProject: IProject) => {
    const updatedDemoRoom = updatedProject.demoRooms.find(
      (d: IDemoRoom) => d.id === demoRoom.id
    );

    onDemoRoomUpdate(updatedProject);
    rowNode.setData(updatedDemoRoom);
  };

  return (
    <>
      {updateModalShown && (
        <UpdateDemoRoomModal
          roomDetails={demoRoom}
          projectId={projectId}
          onModalClose={() => setUpdateModalShown(false)}
          onUpdateDemoRoom={handleDemoRoomUpdate}
        />
      )}
      <Icon
        name='edit'
        propStyles={styles.actionIcon}
        onClick={() => setUpdateModalShown(true)}
      />
    </>
  );
};

export default UpdateDemoRoomCell;
