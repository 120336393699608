import { useSelector } from 'react-redux';

import { getFilterPreferences } from 'apps/booking/slices/unitSelectionHeader/unitSelectionHeaderNav';
import { PRICE_UNIT } from 'apps/booking/constants';

const FilterPreferencesSummery = (props) => {
  const { toggleFilterPreferenceContainer } = props;

  const preferences = useSelector(getFilterPreferences);
  const isPreferencesApplicable = Object.values(preferences).some(
    (preference) => preference.items.some((item) => item.isSelected)
  );

  const preferencesList = Object.values(preferences)
    .map((preference) =>
      preference.items
        .filter((item) => item.isSelected)
        .map((item) => {
          switch (item.type) {
            case 'PRICE':
              return `₹${item.from < 1 ? item.from * 100 : item.from} ${
                item.from < 1 ? PRICE_UNIT.LAKH : PRICE_UNIT.CRORE
              } to ₹${item.to < 1 ? item.to * 100 : item.to} ${
                item.to < 1 ? PRICE_UNIT.LAKH : PRICE_UNIT.CRORE
              }`;
            case 'AREA':
              return `${item.from} to ${item.to} Sqmt`;
            default:
              return item.name;
          }
        })
    )
    .flat(2);

  const preferencesListLen = preferencesList.length;
  const MAX_PREFERENCE_SHOWN = 5;
  return (
    <>
      {isPreferencesApplicable && (
        <div className='filter-preferences-summery'>
          <span className='filter-preferences-summery__label'>
            You selected {preferencesListLen} Preferences :
          </span>
          {preferencesList.slice(0, MAX_PREFERENCE_SHOWN).map((item) => (
            <span
              className='filter-preferences-summery__item'
              onClick={() => toggleFilterPreferenceContainer()}>
              {item}
            </span>
          ))}
          {preferencesListLen > MAX_PREFERENCE_SHOWN && (
            <span className='filter-preferences-summery__more'>
              +{preferencesListLen - MAX_PREFERENCE_SHOWN} more
            </span>
          )}
        </div>
      )}
    </>
  );
};

export default FilterPreferencesSummery;
