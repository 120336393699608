import { ordinalSuffixOf } from './utils';

/*

DaythMonthYear - 11th Oct 2022

*/
export const dateToDaythMonthYear = (date: Date) => {
  return `${ordinalSuffixOf(new Date(date).getDate())} ${new Date(
    date
  ).toLocaleDateString('en', { month: 'short' })} ${new Date(
    date
  ).toLocaleDateString('en', { year: 'numeric' })}`;
};

/*

TimeDayDateMonth - 16:40 Tue, 18 Oct

*/
export const dateToTimeDayDateMonth = (date: Date) => {
  const formattedDate = `${date.toLocaleDateString('en', {
    weekday: 'short',
  })}, ${date.getDate()} ${date.toLocaleDateString('en', { month: 'short' })}`;

  const time = date.toLocaleTimeString('en', {
    hour: 'numeric',
    hour12: false,
    minute: 'numeric',
  });

  return `${time} ${formattedDate}`;
};

export const dateToHourMinute = (date: Date) => {
  const updatedDate = new Date(date);
  let hours = updatedDate.getUTCHours();
  let minutes: any = updatedDate.getUTCMinutes();
  const ampm = hours >= 12 ? 'pm' : 'am';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? '0' + minutes : minutes;
  const strTime = hours + ':' + minutes + ' ' + ampm;
  return strTime;
};

// convert to date defaukt input value
export const defaultDateFormat = (dateStr: Date) => {
  const date = new Date(dateStr);
  const formattedDate = date.toISOString().slice(0, 10);
  return formattedDate;
};

// check for Current day return Today
export const dateIsToday = (visitDate: Date) => {
  const visitDateObj = new Date(visitDate);
  const currentDate = new Date();
  const isToday = visitDateObj.toDateString() === currentDate.toDateString();
  return isToday ? 'Today ' : '';
};

// check weather day is weekday or weekEnd (Sunday or Saturday)
export const dateIsWeekend = (date: Date) => {
  const dayOfWeek = new Date(date).getDay();
  return dayOfWeek === 0 || dayOfWeek === 6;
};

// dd-mm-yyyy
export const getDateFormatInddmmyy = (dateToChnage: Date) => {
  const date = new Date(dateToChnage);
  const day = date.getUTCDate().toString().padStart(2, '0');
  const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
  const year = date.getUTCFullYear().toString();

  const formattedDate = `${day}-${month}-${year}`;

  return formattedDate;
};

// yyyy-mm-dd
export const getDateFormatInyyyymmdd = (dateToChnage: Date) => {
  const date = new Date(dateToChnage);
  const day = date.getUTCDate().toString().padStart(2, '0');
  const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
  const year = date.getUTCFullYear().toString();

  const formattedDate = `${year}-${month}-${day}`;

  return formattedDate;
};

// Convert visitDate string to Date object in dayMonthYearDay
export const dayMonthYearDay = (visitDate: Date) => {
  const date = new Date(visitDate);
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  const dayNames = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ];
  const day = date.getDate();
  const month = date.getMonth();
  const year = date.getFullYear();
  const dayOfWeek = date.getDay();
  const formattedDate = `${day} ${monthNames[month]} ${year} ${dayNames[dayOfWeek]}`;
  return formattedDate;
};
