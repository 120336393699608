import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { GridReadyEvent, ICellRendererParams } from 'ag-grid-community';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';

import ExpandRowCell from 'apps/admin/components/pages/ActiveLeads/cell-renderers/ExpandRowCell';
import ExpandedDetailSection, {
  DEFAULT_NULL_VALUE,
} from 'apps/admin/components/common/ExpandedDetailSection';
import TextInput from 'apps/booking/components/common/inputs/TextInput';
import { IPartner } from 'apps/admin/components/pages/ProjectPartners';
import { dateToDaythMonthYear } from 'utils/dateFormator';
import { awsBaseUrl } from 'constants/urls';
import NameCard from 'apps/booking/components/common/admin/NameCard';
import DropDownInput, {
  DropDownOptionType,
} from 'apps/booking/components/common/inputs/DropDownInput';
import Button from 'apps/booking/components/common/buttons/Button';
import { UserRole } from 'constants/userRoles';
import { TObjectId } from 'interfaces';
import { getAdminUser } from 'apps/admin/slices/adminSlice';
import Pagination from 'apps/admin/components/common/Pagination';
import {
  useGetActiveProjectPartnersMutation,
  useGetRegisteredProjectPartnersMutation,
  useGetUnassignedProjectPartnersMutation,
} from 'apps/admin/services/projectPartnersAPISlice';
import SpinLoader from 'apps/booking/components/common/SpinLoader';
import AddingSmCell from 'apps/admin/components/pages/ProjectPartners/Partners/AddingSmCell';

import styles from './styles.module.css';

interface IPartnersProps {
  partnerType: 'active' | 'register' | 'unassigned';
  partnersCount: number;
  projectId: TObjectId;
}

const Partners = (props: IPartnersProps) => {
  const { partnerType, partnersCount, projectId } = props;

  const [gridApi, setGridApi] = useState(null) as any;
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [partnersLoaded, setPartnersLoaded] = useState(false);
  const [partners, setPartners] = useState<IPartner[]>([]);
  const [searchDetails, setSearchDetails] = useState({
    searchName: '',
    searchField: '',
    startDate: '',
    endDate: '',
  });

  const { register, unregister, setValue, handleSubmit } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });

  const user = useSelector(getAdminUser);
  const [getActiveProjectPartners] = useGetActiveProjectPartnersMutation();
  const [getUnassignedProjectPartners] =
    useGetUnassignedProjectPartnersMutation();
  const [getRegisteredProjectPartners] =
    useGetRegisteredProjectPartnersMutation();

  //AG-grid Default column definition
  const defaultColDef = {
    sortable: false,
    filter: false,
    resizable: false,
    suppressMenu: true,
    editable: false,
    flex: 1,
  };

  const pageCount = 10;
  const totalPages = Math.ceil(partnersCount / pageCount);

  const searchFieldOptions: DropDownOptionType[] = [
    {
      value: 'partnerName',
      displayText: 'Partner Name',
    },
    { value: 'phoneNumber', displayText: 'Phone Number' },
    {
      value: 'cpFirmName',
      displayText: 'Firm Name',
    },
    { value: 'smName', displayText: 'SM Name' },
  ];

  useEffect(() => {
    getPartners(1);
  }, [projectId, partnerType]);

  const getDetails = (partner: IPartner) => {
    return [
      {
        label: 'Phone Number',
        value: partner.cpMobile,
      },
      {
        label: 'Email',
        value: partner.cpEmail,
      },
      {
        label: 'Registered On',
        value: dateToDaythMonthYear(partner.projectRegistration),
      },
      {
        label: 'Firm URL',
        value: partner.cpFirmURL,
      },
      {
        label: 'Firm GST',
        value: partner.cpFirmGST,
      },
      {
        label: 'Firm PAN',
        value: partner.cpFirmPAN,
      },
      {
        label: 'QRs Shared',
        value: partner.shareQRCodeCount,
      },
      {
        label: 'Lost Leads',
        value: Math.abs(partner.siteVisitsDone - partner.assignedLeads) || 0,
      },
    ];
  };

  // Ag-grid global search
  const handleGridSearch = (event: string) => {
    gridApi.setQuickFilter(event);
  };

  // This function will automatically be called when ag grid is ready
  const onGridReady = (params: GridReadyEvent) => {
    setGridApi(params.api);
  };

  const getUserAvatarUrl = (params: ICellRendererParams) => {
    return `${awsBaseUrl}/common/AZAlphabets/${params.data?.cpName[0].toUpperCase()}${
      +params.data?.cpMobile % 5
    }.png`;
  };

  const getPartners = async (
    currentPageNumber: number,
    searchedData?: {
      searchName: string;
      searchField: string;
      startDate: string;
      endDate: string;
    }
  ) => {
    try {
      setPartnersLoaded(true);
      const payload = {
        projectId,
        pageNumber: currentPageNumber ? currentPageNumber : 1,
        pageCount,
        executiveId:
          user?.role === UserRole.EXECUTIVE || user?.role === UserRole.GRE
            ? user.id
            : '',
        ...searchedData,
      };
      let response;

      if (partnerType == 'active') {
        response = await getActiveProjectPartners(payload).unwrap();
      } else if (partnerType == 'unassigned') {
        response = await getUnassignedProjectPartners(payload).unwrap();
      } else {
        response = await getRegisteredProjectPartners(payload).unwrap();
      }

      setPartners(response.data);
    } catch (err) {
      console.log(err);
    } finally {
      setPartnersLoaded(false);
    }
  };

  const handleSearchPartners = (searchedData: {
    searchName: string;
    searchField: string;
    startDate: string;
    endDate: string;
  }) => {
    setSearchDetails(searchedData);
    getPartners(1, searchedData);
    setCurrentPageNumber(1);
  };

  const handleNextPage = () => {
    getPartners(currentPageNumber + 1, searchDetails);
    setCurrentPageNumber(currentPageNumber + 1);
  };

  const handleOnPageChange = () => {
    getPartners(currentPageNumber - 1, searchDetails);
    setCurrentPageNumber(currentPageNumber - 1);
  };

  const handleNavigationToFirstPage = () => {
    if (currentPageNumber !== 1) {
      getPartners(1, searchDetails);
      setCurrentPageNumber(1);
    }
  };

  const handleNavigationToLastPage = () => {
    if (currentPageNumber !== totalPages) {
      getPartners(totalPages, searchDetails);
      setCurrentPageNumber(totalPages);
    }
  };

  return (
    <>
      <header>
        <form
          className={styles.searchContainer}
          onSubmit={handleSubmit(handleSearchPartners)}>
          <DropDownInput
            options={searchFieldOptions}
            defaultSelectedOption={searchFieldOptions[0]}
            lable='Select search type '
            register={register}
            setValue={setValue}
            unregister={unregister}
            name='searchField'
            propStyles={styles.inputBox}
          />
          <TextInput
            inputHTMLElementStyles={styles.searchInput}
            placeHolder='Search'
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              handleGridSearch(event.target.value);
            }}
            startIconName='search'
            startIconVariant='SECONDARY'
            label='Search Lead, Partner and SM name'
            propStyles={styles.inputBox}
            name='searchName'
            register={register}
            setValue={setValue}
            unregister={unregister}
          />
          <TextInput
            type='date'
            placeHolder='Select start date'
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              handleGridSearch(event.target.value);
            }}
            register={register}
            setValue={setValue}
            unregister={unregister}
            label='From'
            name='startDate'
            propStyles={styles.inputBox}
          />
          <TextInput
            type='date'
            placeHolder='Select end date'
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              handleGridSearch(event.target.value);
            }}
            register={register}
            setValue={setValue}
            unregister={unregister}
            label='To'
            name='endDate'
            propStyles={styles.inputBox}
          />
          <Button accent='primary' propStyles={styles.searchBtn} type='submit'>
            Search
          </Button>
        </form>
      </header>

      {partnersLoaded ? (
        <SpinLoader />
      ) : (
        <div
          className={`ag-theme-alpine ${styles.gridContainer}`}
          style={{ width: '100%', height: '100%' }}>
          <AgGridReact
            rowData={partners}
            defaultColDef={defaultColDef}
            domLayout={'autoHeight'}
            pagination={false}
            paginationPageSize={pageCount}
            masterDetail={true}
            animateRows={true}
            detailCellRenderer={'detailCellRenderer'}
            detailRowAutoHeight={true}
            onGridReady={onGridReady}
            frameworkComponents={{
              detailCellRenderer: ExpandedDetailSection,
            }}
            detailCellRendererParams={(params: ICellRendererParams) => ({
              details: getDetails(params.data),
            })}>
            <AgGridColumn
              flex={0.8}
              cellClass='ag-grid-column-custom-cell'
              cellRendererFramework={ExpandRowCell}
              cellRendererParams={(params: ICellRendererParams) => params}
            />
            <AgGridColumn
              headerName='CP Name'
              field='cpName'
              flex={3}
              cellRendererFramework={(params: ICellRendererParams) => (
                <NameCard
                  {...{
                    imgUrl: `${getUserAvatarUrl(params)}`,
                    title: params.data.cpName,
                    subtitle: params.data.cpMobile,
                    disabled: params.data.disabled,
                  }}
                />
              )}
            />
            <AgGridColumn
              flex={2}
              headerName='Firm'
              cellClass='ag-cell-no-text-overflow ag-cell-wrap-text ag-cell-value'
              cellRendererFramework={(params: ICellRendererParams) => (
                <p className={styles.firmName}>{params.data.cpFirmName}</p>
              )}
            />
            <AgGridColumn
              flex={2}
              headerName='SM Name'
              field='smName'
              cellRendererFramework={(params: ICellRendererParams) => (
                <div>
                  {params.data.smName ? (
                    <p className={styles.cpName}>{params.data.smName}</p>
                  ) : (
                    <div>
                      {params.data.cpFirmName ? (
                        <AddingSmCell
                          projectId={projectId}
                          cpFirmId={params.data.cpFirmId}
                          cpUserId={params.data.cpUserId}
                        />
                      ) : (
                        DEFAULT_NULL_VALUE
                      )}
                    </div>
                  )}
                </div>
              )}
            />
            <AgGridColumn
              flex={1.5}
              headerName='Registered On'
              headerTooltip='Registered On'
              cellRendererFramework={(params: ICellRendererParams) => (
                <p className={styles.cpName}>
                  {params.data.projectRegistration
                    ? dateToDaythMonthYear(params.data.projectRegistration)
                    : DEFAULT_NULL_VALUE}
                </p>
              )}
            />
            <AgGridColumn
              flex={1.8}
              headerName='Collaterals Shared'
              headerTooltip='Collaterals Shared'
              cellClass='relata-ag-grid-cell-justify-center'
              cellRendererFramework={(params: ICellRendererParams) => (
                <p className={styles.counts}>{params.data.collateralsShared}</p>
              )}
            />
            <AgGridColumn
              flex={2}
              headerName='SiteVisit Done'
              headerTooltip='SiteVisit Done'
              cellClass='relata-ag-grid-cell-justify-center'
              cellRendererFramework={(params: ICellRendererParams) => (
                <p className={styles.counts}>{params.data.siteVisitsDone}</p>
              )}
            />
            <AgGridColumn
              flex={1.5}
              headerName='Registered Leads'
              headerTooltip='Registered Leads'
              cellClass='relata-ag-grid-cell-justify-center'
              cellRendererFramework={(params: ICellRendererParams) => (
                <p className={styles.counts}>{params.data.leadsRegistered}</p>
              )}
            />
            <AgGridColumn
              flex={1.5}
              headerName='Assigned Leads'
              headerTooltip='Assigned Leads'
              cellClass='relata-ag-grid-cell-justify-center'
              cellRendererFramework={(params: ICellRendererParams) => (
                <p className={styles.counts}>{params.data.assignedLeads}</p>
              )}
            />
          </AgGridReact>
          <Pagination
            totalRowCount={partnersCount || 0}
            rowCount={partners.length}
            currentPage={currentPageNumber - 1}
            onPageChange={handleOnPageChange}
            onNextPage={handleNextPage}
            onNavigateTofirstPage={handleNavigationToFirstPage}
            onNavigationToLastPage={handleNavigationToLastPage}
            loaded={partnersLoaded}
          />
        </div>
      )}
    </>
  );
};

export default Partners;
