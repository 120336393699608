import { useState } from 'react';
import Loader from 'react-spinners/BarLoader';
import S3Client from '../../../../utils/S3Client';
import { UPLOAD_ICON } from '../../../../constants/icons';

const FileUpload = (props) => {
  // TODO: Optimise - Rendered mutliple times
  const {
    cname,
    lable,
    s3Url,
    userId,
    documentType,
    handleFileUpload,
    handleIsFileUploading,
    error,
  } = props;

  const [showFileUploadingAnimation, setShowFileUploadingAnimation] =
    useState(false);

  const uplaodFile = (e) => {
    if (!e.target.files[0]) {
      // No file selected
      return;
    }

    const file = e.target.files[0];
    const fileName = e.target.files[0].name;
    const fileSize = e.target.files[0].size / 1024 / 1024; // in MiB
    const fileSizeLimit = 5;
    if (fileSize > fileSizeLimit) {
      alert(`File size exceeds ${fileSizeLimit}MB`);
      e.target.value = '';
      return;
    }

    setShowFileUploadingAnimation(true);
    handleIsFileUploading(S3Client.id(userId, documentType), true);
    S3Client.uploadFile(file, fileName, userId, documentType).then((data) => {
      if (data.status === 204) {
        handleFileUpload(data.location);
      } else {
        alert('File upload error');
      }
      setShowFileUploadingAnimation(false);
      handleIsFileUploading(S3Client.id(userId, documentType), false);
    });
  };

  return (
    <div className={`input input--upload ${cname}`}>
      <label className='input_label'>{lable}</label>
      <input
        id={S3Client.id(userId, documentType)}
        type='file'
        name='aadhar-card'
        accept='jpg/jpeg/png/pdf'
        className='input_field hide'
        placeholder='Upload file'
        onChange={(e) => uplaodFile(e)}
      />
      <label
        className='filename-label'
        htmlFor={S3Client.id(userId, documentType)}>
        {showFileUploadingAnimation
          ? 'Uploading...'
          : S3Client.fileName(s3Url) || 'Select file'}
      </label>

      {showFileUploadingAnimation ? <Loader /> : ''}
      <span className='input__error'>{error}</span>
      <span
        className={`icon material-icons icon--upload ${
          S3Client.fileName(s3Url) && 'icon--upload-success'
        }`}>
        {UPLOAD_ICON}
      </span>
    </div>
  );
};

export default FileUpload;
