import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import AdminContent from 'apps/admin/components/containers/AdminContent';
import AdminHeader from 'apps/admin/components/containers/AdminHeader';
import AdminMobileView from 'apps/admin/components/containers/AdminMobileView/AdminMobileView';
import AdminSidebar from 'apps/admin/components/containers/AdminSidebar';
import ToastContainer from 'apps/booking/components/common/toast/ToastContainer';
import { getAppSliceIsFetching } from 'slices/appSlice';
import { getAdminUser, setAdminCompany } from 'apps/admin/slices/adminSlice';
import { useGetCompanyByUserQuery } from 'apps/admin/services/companyAPISlice';
import { isAdminUserLoggedIn } from 'apps/admin/components/pages/AdminLogin/utils';

const AdminLayer = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const appSliceIsFetching = useSelector(getAppSliceIsFetching);
  const adminUser = useSelector(getAdminUser);

  const { data: company } = useGetCompanyByUserQuery(adminUser?.id);

  useEffect(() => {
    if (company) {
      dispatch(setAdminCompany(company));
    }
  }, [company, dispatch]);

  if (!isAdminUserLoggedIn()) {
    history.push('/login');
    return null;
  }

  return (
    <div className='admin'>
      <div
        className={`loading-overlay ${
          appSliceIsFetching && 'loading-overlay--enabled'
        }`}>
        <span>loading...</span>
      </div>
      <div className='desktop-screen'>
        <div className='app-main'>
          {/* Header */}
          <AdminHeader />

          <div className='app-body'>
            {/* Sidebars */}
            <AdminSidebar />

            {/* Content */}
            <AdminContent />
          </div>
        </div>
      </div>
      {/*  Admin mobile view */}
      <div className='app-main-mobile'>
        <AdminMobileView />
      </div>
      <ToastContainer />
    </div>
  );
};

export default AdminLayer;
