import { useState, useRef } from 'react';
import { useHistory } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';

import useOnClickOutside from 'utils/useOnClickOutside';
import DropdownContent, {
  getDropdownPlaceholderText,
} from 'apps/booking/components/contentHeader/unitSelection/DropdownContent';
import { URLS } from 'constants/urls';
import useToast from 'hooks/useToast';

import {
  getTowerBtnState,
  getSelectedTower,
  setSelectedTower,
  getUnitSelectionHeaderNavState,
  DEFAULT_SELECTED_TOWER,
  UNIT_SELECTION_HEADER_NAV_STATE,
} from 'apps/booking/slices/unitSelectionHeader/unitSelectionHeaderNav';
import { getProject } from 'apps/booking/slices/projectSlice';
import { getLanguageText } from 'constants/multiLanguageTexts';
import { getUserLanguage } from 'apps/booking/slices/userlanguage';
import { setBookingRoutesCurrentPage } from 'apps/booking/routers/BookingRoutes/slice';

const TowerBtn = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const towerBtnState = useSelector(getTowerBtnState);
  const selectedTower = useSelector(getSelectedTower);
  const unitSelectionHeaderNavState = useSelector(
    getUnitSelectionHeaderNavState
  );
  const userLanguage = useSelector(getUserLanguage);
  const project = useSelector(getProject);

  const [show, setShow] = useState(false);

  const [addToast] = useToast();

  const toggleDropdown = (showOrHide) => {
    setShow(showOrHide);
  };

  const ref = useRef();
  useOnClickOutside(ref, () => toggleDropdown(false));

  const handleSelectedOption = (selectedTowerOptionValue) => {
    dispatch(setSelectedTower(selectedTowerOptionValue));
    dispatch(setBookingRoutesCurrentPage(URLS.UNIT_SELECTION));
    if (
      selectedTowerOptionValue !== DEFAULT_SELECTED_TOWER &&
      !isUnitsAvailableForTower(selectedTowerOptionValue)
    ) {
      addToast({
        type: 'WARN',
        primaryMessage: `${selectedTowerOptionValue[0].toUpperCase()}${selectedTowerOptionValue.slice(
          1
        )} has zero units available. Please select any other tower!`,
      });
    }
  };

  const isUnitsAvailableForTower = (towerName) => {
    const selectedOption = towerBtnState.options.find(
      (option) => option.value === towerName
    );
    return selectedOption && selectedOption.availableUnitCount;
  };

  return (
    <button
      ref={ref}
      className={`btn btn--icon-label-container dropdown ${
        unitSelectionHeaderNavState ===
          UNIT_SELECTION_HEADER_NAV_STATE.MASTER && 'btn--enabled'
      } ${selectedTower !== DEFAULT_SELECTED_TOWER && 'btn--accent'}`}
      onClick={() => toggleDropdown(!show)}>
      <span>
        {getLanguageText(
          userLanguage,
          selectedTower == 'towerName'
            ? project.booking?.title?.towerBtnTitle || 'Tower'
            : getDropdownPlaceholderText(selectedTower, towerBtnState)
        )}
      </span>
      <span className='icon material-icons'></span>
      <DropdownContent
        btnState={towerBtnState}
        show={show}
        handleSelectedOption={handleSelectedOption}
      />
    </button>
  );
};

export default TowerBtn;
