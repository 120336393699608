import { useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import useOnClickOutside from '../../../../../utils/useOnClickOutside';
import DropdownContent, { getDropdownPlaceholderText } from './DropdownContent';

import {
  getBlockBtnState,
  getSelectedTower,
  getSelectedBlock,
  setSelectedBlock,
  DEFAULT_SELECTED_TOWER,
  DEFAULT_SELECTED_BLOCK,
} from 'apps/booking/slices/unitSelectionHeader/unitSelectionHeaderNav';
import { URLS } from '../../../../../constants/urls';
import { getProject } from 'apps/booking/slices/projectSlice';

const BlockBtn = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const blockBtnState = useSelector(getBlockBtnState);
  const selectedTower = useSelector(getSelectedTower);
  const selectedBlock = useSelector(getSelectedBlock);
  const project = useSelector(getProject);

  const [show, setShow] = useState(false);

  const toggleDropdown = (showOrHide) => {
    setShow(showOrHide);
  };

  const ref = useRef();
  useOnClickOutside(ref, () => toggleDropdown(false));

  const handleSelectedOption = (selectedBlockName) => {
    dispatch(setBookingRoutesCurrentPage(URLS.UNIT_SELECTION));
    dispatch(setSelectedBlock(selectedBlockName));
  };
  return (
    <button
      ref={ref}
      className={`btn btn--icon-label-container dropdown 
        ${selectedBlock !== DEFAULT_SELECTED_BLOCK && 'btn--accent'}
        ${selectedTower === DEFAULT_SELECTED_TOWER && 'btn--disabled'}
      }`}
      onClick={() => toggleDropdown(!show)}>
      <span>
        {selectedTower === DEFAULT_SELECTED_TOWER
          ? project.booking?.title?.blockBtnTitle || 'Block'
          : getDropdownPlaceholderText(selectedBlock, blockBtnState)}
      </span>
      <span className='icon material-icons'></span>
      <DropdownContent
        btnState={blockBtnState}
        show={show}
        handleSelectedOption={handleSelectedOption}
      />
    </button>
  );
};

export default BlockBtn;
