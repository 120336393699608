import { ICompanyReq } from 'apps/admin/interfaces/index';
import { adminAPISlice } from 'apps/admin/services/index';
import { ICompany, ISuperAdminDashboardCompany } from 'apps/admin//interfaces';
import { IPartnerFirm } from '../components/pages/AllFirms';
import { TObjectId } from 'interfaces';
import { ICollaborators } from 'apps/admin/components/pages/CollaboratorsList';

export const companyAPISlice = adminAPISlice.injectEndpoints({
  endpoints: (build) => ({
    getCompanies: build.query<ICompany[], any>({
      query: () => 'api/companies/',
      transformResponse: (response: {
        data: ICompany[];
        code: String;
        error: Object;
        message: String;
      }) => {
        return response.data;
      },
      // providesTags: (result) =>
      //   // is result available?
      //   result
      //     ? // successful query
      //       [
      //         ...result.map(({ id }) => ({ type: 'Companies' as const, id })),
      //         { type: 'Companies', id: 'LIST' }
      //       ]
      //     : // an error occurred, but we still want to refetch this query when `{ type: 'Companies', id: 'LIST' }` is invalidated
      //       [{ type: 'Companies', id: 'LIST' }]
    }),

    getSuperAdminDashboardCompanies: build.query<
      ISuperAdminDashboardCompany[],
      any
    >({
      query: () => 'api/companies/superAdminDashboardCompanies',
      transformResponse: (response: {
        data: ISuperAdminDashboardCompany[];
        code: String;
        error: Object;
        message: String;
      }) => {
        return response.data;
      },
    }),

    getCompanyByUser: build.query<ICompany, any>({
      query: (userId: string) => `api/companies/users/${userId}`,
      transformResponse: (response: {
        data: ICompany;
        code: String;
        error: Object;
        message: String;
      }) => {
        return response.data;
      },
    }),

    addCompanyUser: build.mutation({
      query: ({
        user,
        companyId,
      }: {
        user: {
          name: String;
          email: String;
          password: String;
          role: String;
          mobile: string;
          jobTitle: string;
        };
        companyId: string;
      }) => ({
        url: 'api/companies/users',
        method: 'POST',
        body: { user, companyId },
      }),
    }),

    deleteUser: build.mutation({
      query: ({
        userId,
        companyId,
      }: {
        userId: string;
        companyId: string;
      }) => ({
        url: `api/companies/${companyId}/users/${userId}`,
        method: 'DELETE',
      }),
    }),

    addCompany: build.mutation({
      query: (company: ICompanyReq) => ({
        url: 'api/companies/',
        method: 'POST',
        body: company,
      }),
      // invalidatesTags: [{ type: 'Companies', id: 'LIST' }]
    }),

    updateCompany: build.mutation({
      query: ({ id: companyId, ...company }: ICompany) => ({
        url: `api/companies/${companyId}`,
        method: 'PUT',
        body: company,
      }),
      // invalidatesTags: [{ type: 'Companies', id: 'LIST' }]
    }),

    getCompanyCpFirm: build.query<ICompany[], any>({
      query: () => 'api/companies/cp-firm',
      transformResponse: (response: {
        data: ICompany[];
        code: String;
        error: Object;
        message: String;
      }) => {
        return response.data;
      },
    }),

    getCompanyDetailsByReraNumber: build.mutation({
      query: (reraNumber: string) => ({
        url: `api/companies/rera/${reraNumber}`,
        method: 'GET',
      }),
    }),

    getPartnerFirms: build.query<IPartnerFirm[], any>({
      query: ({ organisationId }: { organisationId: TObjectId }) =>
        `api/companies/cp-firm/${organisationId}`,
      transformResponse: (response: {
        data: IPartnerFirm[];
        code: String;
        error: Object;
        message: String;
      }) => {
        return response.data;
      },
    }),

    getProjectPartnerFirms: build.mutation({
      query: ({
        projectId,
        pageNumber,
        pageCount,
        searchField = '',
        searchName = '',
        startDate = '',
        endDate = '',
      }: {
        projectId: TObjectId;
        pageNumber: number;
        pageCount: number;
        searchField: string;
        searchName?: string;
        startDate?: string;
        endDate?: string;
      }) => ({
        url: `api/projects/${projectId}/cp-firm?searchField=${searchField}&searchName=${searchName}&pageCount=${pageCount}&pageNumber=${pageNumber}&startDate=${startDate}&endDate=${endDate}`,
        method: 'GET',
      }),
    }),
    getProjectPartnerFirmsCount: build.query<number, any>({
      query: ({
        projectId,
        searchField,
      }: {
        projectId: TObjectId;
        searchField: string;
      }) =>
        `api/projects/${projectId}/cp-firm/count?searchField=${searchField}`,
      transformResponse: (response: {
        data: number;
        code: String;
        error: Object;
        message: String;
      }) => {
        return response.data;
      },
    }),
    getCollaborators: build.query<ICollaborators[], any>({
      query: ({ organisationId }: { organisationId: TObjectId }) =>
        `api/companies/collaborators/${organisationId}`,
      transformResponse: (response: {
        data: ICollaborators[];
        code: String;
        error: Object;
        message: String;
      }) => {
        return response.data;
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetCompaniesQuery,
  useGetSuperAdminDashboardCompaniesQuery,
  useGetCompanyByUserQuery,
  useAddCompanyUserMutation,
  useDeleteUserMutation,
  useAddCompanyMutation,
  useUpdateCompanyMutation,

  useGetCompanyCpFirmQuery,
  useGetCompanyDetailsByReraNumberMutation,

  useGetPartnerFirmsQuery,
  useGetProjectPartnerFirmsMutation,
  useGetProjectPartnerFirmsCountQuery,

  useGetCollaboratorsQuery,
} = companyAPISlice;
