import { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Icon from 'apps/booking/components/common/Icon';
import MoreMenu from 'apps/admin/components/common/MoreMenu';
import AlphabetAvatar from 'apps/booking/components/common/AlphabetAvatar/index';
import { useGetProjectsByUserIdQuery } from 'apps/admin/services/projectsAPISlice';
import { IProject } from 'apps/admin/components/pages/SuperAdminDashboard/SuperAdminProjectList';
import { getAdminUser } from 'apps/admin/slices/adminSlice';
import { MenuItem } from 'apps/admin/components/common/MoreMenu';
import useAdminLogOut from 'apps/admin/components/pages/AdminLogin/hooks/useAdminLogOut';
import { setAppSliceIsFetching } from 'slices/appSlice';
import { NOT_FOUND } from 'constants/status';
import TextInput from 'apps/booking/components/common/inputs/TextInput';

import styles from './styles.module.css';

// Local Interfaces
interface IProjectSelection {
  executiveId?: string;
  project?: IProject; // Default selected project
  headerMoreMenuOptions?: MenuItem[];
  onProjectSelection: (project: IProject) => void;
  firstProjectRef?: React.MutableRefObject<IProject | undefined>;
  isSiteVisitForm?: boolean;
}

const ProjectSelection = (props: IProjectSelection) => {
  const {
    executiveId,
    project,
    headerMoreMenuOptions = [],
    onProjectSelection,
    firstProjectRef,
    isSiteVisitForm,
  } = props;

  const [searchValue, setSearchValue] = useState('');

  const history = useHistory();

  const adminUser = useSelector(getAdminUser);
  const dispatch = useDispatch();

  const {
    data: projectsByExecutive,
    isLoading,
    error,
  } = useGetProjectsByUserIdQuery(executiveId || adminUser?.id);

  const { removeAdminUserLocal, logginOut } = useAdminLogOut();

  useEffect(() => {
    dispatch(setAppSliceIsFetching(logginOut));
  }, [logginOut]);

  const handleRemoveUser = async () => {
    await removeAdminUserLocal();
    history.push('/');
  };

  useEffect(() => {
    if ((error as any)?.data?.code === NOT_FOUND) {
      handleRemoveUser();
    }
    return () => {};
  }, [error]);

  headerMoreMenuOptions.push({
    displayText: 'Log Out',
    icon: 'logout',
    onClick: async () => {
      await removeAdminUserLocal();
      isSiteVisitForm ? history.push('/forms/site-visit') : history.push('/');
    },
  } as MenuItem);

  const filteredProjects = useMemo(() => {
    return projectsByExecutive?.filter((project) =>
      project.name.toLowerCase().includes(searchValue.toLowerCase())
    );
  }, [searchValue, projectsByExecutive]);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };

  const handleProjectSelection = (project: IProject) => {
    onProjectSelection(project);
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (firstProjectRef) {
    firstProjectRef.current = projectsByExecutive && projectsByExecutive[0];
  }

  return (
    <div className={styles.projectSelection}>
      <header>
        <div className={styles.iconsContainer}>
          <div className={styles.apartmentIconContainer}>
            <Icon name='apartment' propStyles={styles.apartmentIcon}></Icon>
          </div>
          <div className={styles.fullscreenIconContainer}>
            <MoreMenu menuItems={headerMoreMenuOptions} />
          </div>
        </div>
        <h2 className={styles.headerTitle}>Select Project Name</h2>
        <p className={styles.headerDesc}>
          Choose the project from the list to add site visit details of site
          visit customer for that project.
        </p>
        <div className={styles.searchWrap}>
          <TextInput
            type='text'
            inputHTMLElementStyles={styles.searchInput}
            placeHolder='Search Project Name'
            onChange={handleSearch}
            startIconName='search'
            startIconVariant='SECONDARY'
            crApp
          />
        </div>
      </header>

      <main>
        {filteredProjects?.length
          ? filteredProjects.map((_project) => {
              return (
                <div
                  className={styles.projectSelectorRadioBtnContainer}
                  key={_project.id}>
                  <input
                    type='radio'
                    name='projectsList'
                    id={_project.name}
                    value={_project.name}
                    checked={project?.id === _project.id ? true : false}
                    onChange={() => handleProjectSelection(_project)}
                  />
                  <label htmlFor={_project.name}>
                    <div className={styles.labelContainer}>
                      <div>
                        <AlphabetAvatar
                          alphabet={_project.name[0]}
                          uniqueNumber={0}
                        />
                      </div>
                      <div className={styles.radioBtnTextContainer}>
                        <p className={styles.projectNameInRadioBtn}>
                          {_project.name}
                        </p>
                        <p className={styles.developerNameInRadioBtn}>
                          {_project.company.displayName}
                        </p>
                      </div>
                    </div>
                  </label>
                </div>
              );
            })
          : 'No projects available'}
      </main>
    </div>
  );
};

export default ProjectSelection;
