export const downloadImage = (url, name = 'relata-downloaded.jpg') => {
  if (!url) {
    return;
  }

  fetch(url, { method: 'GET', headers: { 'Cache-Control': 'no-cache' } })
    .then((res) => {
      return res.blob();
    })
    .then((blob) => {
      var url = window.URL.createObjectURL(blob);
      var a = document.createElement('a');
      a.href = url;
      a.download = name;
      document.body.appendChild(a);
      a.click();
      setTimeout((_) => {
        window.URL.revokeObjectURL(url);
      }, 60000);
      a.remove();
    })
    .catch((err) => {
      console.error('err: ', err);
    });
};
