import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { GridReadyEvent, ICellRendererParams } from 'ag-grid-community';
import { AgGridReact, AgGridColumn } from 'ag-grid-react';

import { DEFAULT_NULL_VALUE } from 'apps/admin/components/common/ExpandedDetailSection';
import Pagination from 'apps/admin/components/common/Pagination';
import SpinLoader from 'apps/booking/components/common/SpinLoader';
import NameCard from 'apps/booking/components/common/admin/NameCard';
import Button from 'apps/booking/components/common/buttons/Button';
import DropDownInput from 'apps/booking/components/common/inputs/DropDownInput';
import TextInput from 'apps/booking/components/common/inputs/TextInput';
import { UserRole } from 'constants/userRoles';
import { dateToDaythMonthYear } from 'utils/dateFormator';
import { searchFieldOptions, pageCount } from '../ActivePartnerLeads';
import { IBookedLead } from '..';
import { TObjectId } from 'interfaces';
import { useGetProjectPartnerBookedLeadsMutation } from 'apps/admin/services/projectLeadsAPI';
import { getAdminUser } from 'apps/admin/slices/adminSlice';
import { awsBaseUrl } from 'constants/urls';
import { getPriceInFig } from 'utils/utils';
import UnitDetailsCell from './UnitDetailsCell';
import BookingLeadsCell from '../ActivePartnerLeads/BookingLeadsCell';

import styles from './styles.module.css';

interface IBookedPartnerLeadsProps {
  projectId: TObjectId;
  bookedLeadsCount: number;
  bookedCountLoaded: boolean;
}

const BookedPartnerLeads = (props: IBookedPartnerLeadsProps) => {
  const { projectId, bookedCountLoaded, bookedLeadsCount } = props;

  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [gridApi, setGridApi] = useState(null) as any;
  const [bookedLeadsLoaded, setBookedLeadsLoaded] = useState(false);
  const [bookedPartnerLeads, setBookedPartnerLeads] = useState<IBookedLead[]>(
    []
  );
  const [searchDetails, setSearchDetails] = useState({
    searchName: '',
    searchField: '',
    startDate: '',
    endDate: '',
  });

  const { register, unregister, setValue, handleSubmit } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });

  const user = useSelector(getAdminUser);
  const [getProjectPartnerBookedLeadsAPI] =
    useGetProjectPartnerBookedLeadsMutation();

  //AG-grid Default column definition
  const defaultColDef = {
    sortable: false,
    filter: false,
    resizable: false,
    suppressMenu: true,
    editable: false,
    flex: 1,
  };
  const totalPages = Math.ceil(bookedLeadsCount / pageCount);

  useEffect(() => {
    getBookedLeads(1);
  }, [projectId]);

  const getBookedLeads = async (
    currentPageNumber: number,
    searchedData?: {
      searchName: string;
      searchField: string;
      startDate: string;
      endDate: string;
    }
  ) => {
    try {
      setBookedLeadsLoaded(true);
      const response = await getProjectPartnerBookedLeadsAPI({
        projectId,
        pageNumber: currentPageNumber ? currentPageNumber : 1,
        pageCount,
        executiveId:
          user?.role === UserRole.EXECUTIVE || user?.role === UserRole.GRE
            ? user.id
            : '',
        ...searchedData,
      }).unwrap();

      setBookedPartnerLeads(response.data);
    } catch (error) {
      console.log(error);
    } finally {
      setBookedLeadsLoaded(false);
    }
  };

  // Ag-grid global search
  const handleGridSearch = (event: string) => {
    gridApi.setQuickFilter(event);
  };

  // This function will automatically be called when ag grid is ready
  const onGridReady = (params: GridReadyEvent) => {
    setGridApi(params.api);
  };

  const getLeadImageUrl = (params: ICellRendererParams) => {
    return `${awsBaseUrl}/common/AZAlphabets/${params.data.bookedLead.lead.name[0].toUpperCase()}0.png`;
  };

  const handleNextPage = () => {
    getBookedLeads(currentPageNumber + 1, searchDetails);
    setCurrentPageNumber(currentPageNumber + 1);
  };

  const handleOnPageChange = () => {
    getBookedLeads(currentPageNumber - 1, searchDetails);
    setCurrentPageNumber(currentPageNumber - 1);
  };

  const handleNavigationToFirstPage = () => {
    if (currentPageNumber !== 1) {
      getBookedLeads(1, searchDetails);
      setCurrentPageNumber(1);
    }
  };

  const handleNavigationToLastPage = () => {
    if (currentPageNumber !== totalPages) {
      getBookedLeads(totalPages, searchDetails);
      setCurrentPageNumber(totalPages);
    }
  };

  const handleSearchBookedLeads = (searchedData: {
    searchName: string;
    searchField: string;
    startDate: string;
    endDate: string;
  }) => {
    setSearchDetails(searchedData);
    getBookedLeads(1, searchedData);
    setCurrentPageNumber(1);
  };

  return (
    <>
      <header>
        <form
          className={styles.searchContainer}
          onSubmit={handleSubmit(handleSearchBookedLeads)}>
          <DropDownInput
            options={searchFieldOptions}
            defaultSelectedOption={searchFieldOptions[0]}
            lable='Select search type '
            register={register}
            setValue={setValue}
            unregister={unregister}
            name='searchField'
            propStyles={styles.inputBox}
          />
          <TextInput
            inputHTMLElementStyles={styles.searchInput}
            placeHolder='Search'
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              handleGridSearch(event.target.value);
            }}
            register={register}
            setValue={setValue}
            unregister={unregister}
            startIconName='search'
            startIconVariant='SECONDARY'
            label='Search Lead, Partner and SM name'
            name='searchName'
            propStyles={styles.inputBox}
          />
          <TextInput
            type='date'
            placeHolder='Select start date'
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              handleGridSearch(event.target.value);
            }}
            register={register}
            setValue={setValue}
            unregister={unregister}
            label='From'
            name='startDate'
            propStyles={styles.inputBox}
          />
          <TextInput
            type='date'
            placeHolder='Select start date'
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              handleGridSearch(event.target.value);
            }}
            register={register}
            setValue={setValue}
            unregister={unregister}
            label='To'
            name='endDate'
            propStyles={styles.inputBox}
          />
          <Button accent='primary' propStyles={styles.searchBtn} type='submit'>
            Search
          </Button>
        </form>
      </header>
      {bookedLeadsLoaded ? (
        <SpinLoader />
      ) : (
        <div
          className={`ag-theme-alpine ${styles.gridContainer}`}
          style={{ width: '100%', height: '100%' }}>
          <AgGridReact
            rowData={bookedPartnerLeads}
            defaultColDef={defaultColDef}
            onGridReady={onGridReady}
            domLayout={'autoHeight'}
            pagination={false}
            paginationPageSize={pageCount}
            masterDetail={true}
            animateRows={true}>
            <AgGridColumn
              flex={0.5}
              hide={true}
              field={'bookedLead.cpLead.propertyConfigs'}
            />
            <AgGridColumn
              flex={2.5}
              headerName='Name'
              field={'bookedLead.lead.name'}
              cellRendererFramework={(params: ICellRendererParams) => (
                <NameCard
                  {...{
                    imgUrl: `${getLeadImageUrl(params)}`,
                    title: params.data.bookedLead.lead.name,
                    subtitle: params.data.bookedLead.lead.mobile,
                  }}
                />
              )}
            />
            <AgGridColumn
              flex={1.5}
              headerName='Sitevisit on'
              cellRendererFramework={(params: ICellRendererParams) => (
                <p className={styles.cpName}>
                  {params.data.latestSiteVisitDate
                    ? dateToDaythMonthYear(params.data.latestSiteVisitDate)
                    : DEFAULT_NULL_VALUE}
                </p>
              )}
            />
            <AgGridColumn
              flex={2}
              headerName='Partner'
              field={'bookedLead.assignedUser.name'}
              cellRendererFramework={(params: ICellRendererParams) => (
                <p className={styles.cpName}>
                  {params.data.bookedLead.assignedUser.name}
                </p>
              )}
            />
            <AgGridColumn
              flex={2}
              headerName='Firm'
              cellClass='ag-cell-no-text-overflow ag-cell-wrap-text ag-cell-value'
              cellRendererFramework={(params: ICellRendererParams) => (
                <p className={styles.firmName}>
                  {params.data.cpFirmDisplayName || DEFAULT_NULL_VALUE}
                </p>
              )}
            />
            <AgGridColumn
              flex={2}
              headerName='Closing Manager'
              field={'bookedLead.executive.name'}
              cellRendererFramework={(params: ICellRendererParams) => (
                <p className={styles.cpName}>
                  {params.data.bookedLead.executive.name || DEFAULT_NULL_VALUE}
                </p>
              )}
            />
            <AgGridColumn
              flex={1.5}
              headerName='Booking amount'
              cellClass='relata-ag-grid-cell-justify-center'
              cellRendererFramework={(params: ICellRendererParams) => (
                <p className={styles.cpName}>
                  {params.data.bookedLead.bookedLead.bookingAmount
                    ? `INR ${getPriceInFig(
                        params.data.bookedLead.bookedLead.bookingAmount
                      )}`
                    : DEFAULT_NULL_VALUE}
                </p>
              )}
            />
            <AgGridColumn
              flex={2}
              headerName='Booked On'
              cellRendererFramework={(params: ICellRendererParams) => (
                <p className={styles.cpName}>
                  {params.data.bookedLead.bookedLead.bookedOn
                    ? dateToDaythMonthYear(
                        params.data.bookedLead.bookedLead.bookedOn
                      )
                    : DEFAULT_NULL_VALUE}
                </p>
              )}
            />
            <AgGridColumn
              flex={2}
              headerName='Unit-details'
              cellRendererFramework={UnitDetailsCell}
              cellRendererParams={(params: ICellRendererParams) => ({
                unitNumber: params.data.bookedLead.bookedLead.unitNumber,
                unitConfiguration:
                  params.data.bookedLead.bookedLead.unitConfiguration,
              })}
            />
            {user?.role === UserRole.SUPERADMIN && (
              <AgGridColumn
                flex={1.5}
                headerName='Register'
                cellRendererFramework={BookingLeadsCell}
                cellRendererParams={(params: ICellRendererParams) => ({
                  projectId: projectId,
                  registering: true,
                  bookedLead: params.data.bookedLead.bookedLead,
                  closingManager: params.data.bookedLead.closingManager,
                  bookedLeadId: params.data.bookedLead.bookedLead._id,
                })}
              />
            )}
          </AgGridReact>
          <Pagination
            totalRowCount={bookedLeadsCount || 0}
            rowCount={bookedPartnerLeads.length}
            currentPage={currentPageNumber - 1}
            onPageChange={handleOnPageChange}
            onNextPage={handleNextPage}
            onNavigateTofirstPage={handleNavigationToFirstPage}
            onNavigationToLastPage={handleNavigationToLastPage}
            loaded={bookedCountLoaded}
          />
        </div>
      )}
    </>
  );
};

export default BookedPartnerLeads;
