import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ConfirmationModal, {
  IConfirmationModal,
} from 'apps/booking/components/common/genericModals/ConfirmationModal';
import Icon from 'apps/booking/components/common/Icon';
import { IEoiDetails } from 'apps/admin/interfaces';
import useToast from 'hooks/useToast';
import { useConfirmBookingMutation } from 'apps/admin/services/projectsAPISlice';
import { IBookingUser } from 'apps/admin/components/pages/ProjectEois/CancelBookingCell';
import { getEoiList, setEoiList } from 'apps/admin/slices/projectAdminSlice';
import { EoiStatus } from 'constants/status';

export interface IConfirmBookingCell {
  eoiLead: IEoiDetails;
}
const ConfirmBookingCell = (props: IConfirmBookingCell) => {
  const { eoiLead } = props;

  const dispatch = useDispatch();
  const eoiList = useSelector(getEoiList);

  const [confirmBooking, setConfirmBooking] = useState<IConfirmationModal>();

  const [confirmBookingApi] = useConfirmBookingMutation();
  const [addToast] = useToast();

  const handleBookUser = () => {
    setConfirmBooking({
      accent: 'DANGER',
      heading: 'Do you want to Confirm Booking',
      onModalClose: () => setConfirmBooking(undefined),
      onResponse: async (confirmationResponse: any) => {
        try {
          await confirmationResponse;
          const bookingUser: IBookingUser = {
            unitId: eoiLead.unit?.id,
            eoiId: eoiLead.id,
          };
          await confirmBookingApi(bookingUser).unwrap();
          addToast({
            type: 'SUCCESS',
            primaryMessage: 'Successfully Booked',
          });
          handleUpdateRow(eoiLead.id, EoiStatus.BOOKED);
        } catch (err) {
          const errMsg = (err as any).data.message;
          addToast({
            type: 'ERROR',
            primaryMessage: errMsg,
          });
        }
      },
    });
  };

  const handleUpdateRow = (id: string, newStatus: string) => {
    const newEoiList = eoiList.map((eoi: IEoiDetails) =>
      eoi.id === id ? { ...eoi, status: newStatus } : eoi
    );
    dispatch(setEoiList(newEoiList));
  };

  return (
    <>
      {confirmBooking && (
        <ConfirmationModal {...confirmBooking} accent='SUCCESS' />
      )}
      <Icon
        name='details'
        propStyles='actionIcon'
        onClick={() => handleBookUser()}
      />
    </>
  );
};

export default ConfirmBookingCell;
