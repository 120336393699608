import { useSelector } from 'react-redux';
import {
  getCostSheet,
  getSelectedUnit,
  getUnitCart,
} from '../slices/selectedUnit';
import {
  ordinalSuffixOf,
  toIndianRupee,
  convertMeasureUnitToDisplayText,
} from '../../../utils/utils';
import { MEASURE_UNIT } from 'apps/booking/constants/index';
import {
  COSTSHEET_ITEM_TYPE,
  COSTSHEET_SECTION,
} from 'apps/booking/constants/index';
import { getProject } from '../slices/projectSlice';

const UnitSummaryCard = () => {
  const project = useSelector(getProject);
  const unitCart = useSelector(getUnitCart);
  const selectedUnit = unitCart.unit;
  const costSheet = selectedUnit.costSheet;

  const costSheetItemByType = {};
  const costSheetBreakupItemByType = {};
  selectedUnit.costSheet.items.forEach((item) => {
    costSheetItemByType[item.type] = item;
    if (item.section === COSTSHEET_SECTION.BREAKUP) {
      costSheetBreakupItemByType[item.type] = item;
    }
  });
  return (
    <div className='unit-summery-card'>
      <div className='unit-summery-card__header'>
        <h3 className='font-accent-bold unit-summery-card__total-cost'>
          {toIndianRupee(costSheet.totalInclusiveAmount)}
        </h3>
        <span className='f-bold f-18 c-56'>All Inclusive Total</span>
        <span className='f-regular f-15'>(excluding registration charges)</span>
      </div>
      <div className='unit-summery-card__summary'>
        <h1 className='unit-summery-card__summary-heading f-18 f-bold c-56'>
          Summary
        </h1>
        <div className='unit-summery-card__summary-item'>
          <p className='c-56 f-15'>Unit Number</p>
          <p className='c-56 f-15 f-bold'>
            {`${selectedUnit?.blockName}${selectedUnit.number}`}
          </p>
        </div>
        <div className='unit-summery-card__summary-item'>
          <p className='c-56 f-15'>Plot Size</p>
          <p className='c-56 f-15 f-bold'>
            {selectedUnit.area.value}{' '}
            {convertMeasureUnitToDisplayText(selectedUnit.area.unit)}
          </p>
        </div>
        <div className='unit-summery-card__summary-item'>
          <p className='c-56 f-15'>
            {project.booking?.title?.towerBtnTitle || 'Tower'}
          </p>
          <p className='c-56 f-15 f-bold'>{`${selectedUnit.towerName}`}</p>
        </div>
        {false && (
          <div className='unit-summery-card__summary-item'>
            <p className='c-56 f-15'>Floor</p>
            <p className='c-56 f-15 f-bold'>
              {ordinalSuffixOf(selectedUnit?.floor?.number)} Floor
            </p>
          </div>
        )}
        <div className='unit-summery-card__summary-item'>
          <p className='c-56 f-15'>Category</p>
          <p className='c-56 f-15 f-bold'>{selectedUnit.type}</p>
        </div>
        {false && (
          <div className='unit-summery-card__summary-item'>
            <p className='c-56 f-15'>Carpet Area</p>
            <p className='c-56 f-15 f-bold'>{selectedUnit.area.carpet} Sq.Ft</p>
          </div>
        )}
        {false && (
          <div className='unit-summery-card__summary-item'>
            <p className='c-56 f-15'>Agreement Cost</p>
            <p className='c-56 f-15 f-bold'>
              {toIndianRupee(costSheet.agreement_value)}
            </p>
          </div>
        )}
        {false && (
          <div className='unit-summery-card__summary-item'>
            <p className='c-56 f-15'>
              Base Price Per{' '}
              {convertMeasureUnitToDisplayText(selectedUnit.area.unit)}
            </p>
            <p className='c-56 f-15 f-bold'>
              {toIndianRupee(selectedUnit.area.value)}
            </p>
          </div>
        )}
        {false && (
          <div className='unit-summery-card__summary-item'>
            <p className='c-56 f-15'>PLC</p>
            <p className='c-56 f-15 f-bold'>{toIndianRupee(costSheet.plc)}</p>
          </div>
        )}
        {Object.values(costSheetBreakupItemByType).map((element) => {
          const { label, value, type } = element;
          if (type != COSTSHEET_ITEM_TYPE.GST)
            return (
              <div className='unit-summery-card__summary-item'>
                <p className='c-56 f-15'>{label}</p>
                <p className='c-56 f-15 f-bold'>{toIndianRupee(value)}</p>
              </div>
            );
        })}
        {false && (
          <div className='unit-summery-card__summary-item'>
            <p className='c-56 f-15'>Floor Rise</p>
            <p className='c-56 f-15 f-bold'>
              {toIndianRupee(costSheet.floor_rise)}
            </p>
          </div>
        )}
        <div className='unit-summery-card__summary-item'>
          <p className='c-56 f-15'>Total Excl. GST</p>
          <p className='c-56 f-15 f-bold'>
            {toIndianRupee(
              selectedUnit.costSheet.totalInclusiveAmount -
                costSheetBreakupItemByType[COSTSHEET_ITEM_TYPE.GST].value
            )}
          </p>
        </div>
        <div className='unit-summery-card__summary-item'>
          <p className='c-56 f-15'>
            {' '}
            {costSheetBreakupItemByType[COSTSHEET_ITEM_TYPE.GST].label}
            {costSheetBreakupItemByType[COSTSHEET_ITEM_TYPE.GST].rate?.label
              ? ` @ ${
                  costSheetBreakupItemByType[COSTSHEET_ITEM_TYPE.GST].rate
                    ?.label
                }`
              : ''}
          </p>
          <p className='c-56 f-15 f-bold'>
            {toIndianRupee(
              costSheetBreakupItemByType[COSTSHEET_ITEM_TYPE.GST].value
            )}
          </p>
        </div>
        <div className='unit-summery-card__summary-item'>
          <p className='c-56 f-15'>
            All Inclusive total (excluding registration charges)
          </p>
          <p className='c-56 f-15 f-bold'>
            {toIndianRupee(costSheet.totalInclusiveAmount)}
          </p>
        </div>
      </div>
    </div>
  );
};

export default UnitSummaryCard;
