import { useState } from 'react';

import { useUpdateProjectMutation } from 'apps/admin/services/projectsAPISlice';
import useToast from 'hooks/useToast';
import ConfirmationModal, {
  IConfirmationModal,
} from 'apps/booking/components/common/genericModals/ConfirmationModal/index';
import IconWithLabel from 'apps/admin/components/common/IconWithLabel';
import { ICompany } from 'apps/admin/interfaces';
import { IProject, ISecondaryCompany } from '../..';

interface IBlockButtonCell {
  projectId: string;
  companyToRemove: ICompany;
  secondaryCompanies: ISecondaryCompany[];
  onProjectUpdate: (updateProject: IProject) => void;
}

const BlockButtonCell = (props: IBlockButtonCell) => {
  const { projectId, secondaryCompanies, companyToRemove, onProjectUpdate } =
    props;

  const [confirmBlockProject, setConfirmBlockProject] =
    useState<IConfirmationModal>();
  const [addToast] = useToast();

  const [updateProject] = useUpdateProjectMutation();

  const handleBlockProjectClick = () => {
    setConfirmBlockProject({
      accent: 'DANGER',
      heading: 'Do you want to remove this Company?',
      onModalClose: () => setConfirmBlockProject(undefined),
      onResponse: async (confirmationResponse: any) => {
        //TODO: Remove type any of confirmationResponse
        try {
          await confirmationResponse;

          const updatedSecondaryCompanies = secondaryCompanies?.filter(
            (secondaryCompany) => secondaryCompany.company !== companyToRemove
          );
          const payload = {
            secondaryCompanies: updatedSecondaryCompanies,
          };

          const updatedProject = await updateProject({
            ...payload,
            projectId,
          }).unwrap();

          onProjectUpdate(updatedProject.data);

          setConfirmBlockProject(undefined);
        } catch (err) {
          const errMsg = (err as any).data.message;
          addToast({
            type: 'ERROR',
            primaryMessage: errMsg,
          });
          return;
        }
      },
    });
  };

  return (
    <>
      {confirmBlockProject && <ConfirmationModal {...confirmBlockProject} />}
      <IconWithLabel
        iconName='block'
        label='Remove'
        onClick={() => handleBlockProjectClick()}
      />
    </>
  );
};

export default BlockButtonCell;
