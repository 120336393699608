import { useState } from 'react';

import Icon from 'apps/booking/components/common/Icon';
import Modal from 'apps/booking/components/common/modal/Modal';
import { ICollateral } from 'apps/admin/components/pages/SuperAdminDashboard/SuperAdminProjectList';
import ImageZoomViewer from 'apps/cp/components/pages/MarketingCollateralPage/MarketingCollateralCardList/PreviewModal/ImageZoomViewer';
import PdfViewer from 'apps/cp/components/pages/MarketingCollateralPage/MarketingCollateralCardList/PreviewModal/PdfViewer';
import { youtubeUrlReg } from 'apps/cp/components/pages/MarketingCollateralPage/MarketingCollateralCardList';
import CollateralView from 'apps/cp/components/pages/MarketingCollateralPage/MarketingCollateralCardList/CollateralView';

import styles from './styles.module.css';

interface IPreviewModalProps {
  onClose: () => void;
  onShareClick: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    collateral: ICollateral
  ) => void;
  previewCollateral: ICollateral;
}

export const isUrlTypeMatch = (
  url: string,
  type: 'video' | 'image' | 'pdf' | 'youtube'
): boolean => {
  const fileType = url?.split('.').pop();

  switch (type) {
    case 'video':
      return fileType === 'mp4' || fileType === 'm4v';
    case 'image':
      return fileType === 'jpg' || fileType === 'jpeg' || fileType === 'png';
    case 'pdf':
      return fileType === 'pdf';
    case 'youtube':
      return !!url.match(youtubeUrlReg);
  }
};

export const getYoutubeVideoId = (url: string) => {
  const videoId = url.match(youtubeUrlReg);
  if (videoId) {
    return videoId[1];
  }
};

const PreviewModal = (props: IPreviewModalProps) => {
  const { onClose, onShareClick, previewCollateral } = props;

  const handleShareClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    onShareClick(event, previewCollateral);
  };

  const getUrlWithoutParams = (url: string): string => {
    const urlObj = new URL(url);
    const fileUrl = urlObj.searchParams.get('fileUrl');
    if (fileUrl) {
      return fileUrl;
    }
    return url;
  };

  return (
    <Modal
      onOutsideClick={onClose}
      additionalClasses={styles.previewDetails}
      mobileModelCenter>
      <>
        <Icon onClick={onClose} name='close' propStyles={styles.closeIcon} />
        <Icon
          onClick={handleShareClick}
          name='share'
          propStyles={`${styles.shareIcon} ${
            (isUrlTypeMatch(
              getUrlWithoutParams(previewCollateral.url),
              'video'
            ) ||
              isUrlTypeMatch(
                getUrlWithoutParams(previewCollateral.url),
                'youtube'
              )) &&
            styles.video
          }  `}
        />
        <CollateralView
          collateralUrl={getUrlWithoutParams(previewCollateral.url)}
        />
      </>
    </Modal>
  );
};

export default PreviewModal;
