import { useEffect, useState } from 'react';
import {
  useGetProjectLeadUnitsByProjectIdQuery,
  useGetProjectVSGLeadsByProjectIdQuery,
} from 'apps/admin/services/projectLeadsAPI';
import LeadList from 'apps/admin/components/pages/ProjectLeads/LeadList';
import DesktopTabs from 'apps/admin/components/common/DesktopTabs';
import { ITab } from 'apps/cp/components/common/Tabs';
import VsgLeadList from 'apps/admin/components/pages/ProjectLeads/VsgLeadList';
import { IProjectUnitLead } from '../../../../cp/interfaces/index';
import { UNIT_STATUSES, UNIT_STATUSES_INDEX } from 'constants/status';

interface IProjectLeadProps {
  projectId: string;
}

const ProjectLead = (props: IProjectLeadProps) => {
  const { projectId } = props;
  const [availableLeadList, setAvailableLeadList] = useState<
    IProjectUnitLead[]
  >([]);

  const { data: vsgLeads = [], isLoading: isVsgLeadsLoading } =
    useGetProjectVSGLeadsByProjectIdQuery(projectId);

  const {
    data: leadList = [],
    isLoading: isLeadListLoading,
    refetch,
  } = useGetProjectLeadUnitsByProjectIdQuery(projectId);

  useEffect(() => {
    setAvailableLeadList(
      leadList.map((lead) => {
        const filteredUnitCarts = lead.unitCarts.filter(
          (unitCart) =>
            UNIT_STATUSES_INDEX[unitCart.unit.status] <
            UNIT_STATUSES_INDEX[UNIT_STATUSES.BLOCKED]
        );
        return { ...lead, unitCarts: filteredUnitCarts };
      })
    );
  }, [leadList]);

  const updateLeadList = () => {
    refetch();
  };

  if (isVsgLeadsLoading || isLeadListLoading) {
    return <div>Loading...</div>;
  }

  const tabs: ITab[] = [
    {
      id: 'active',
      name: 'Active',
      content: (
        <LeadList
          projectId={projectId}
          leadsList={availableLeadList}
          updateLeadList={updateLeadList}
        />
      ),
      disabled: false,
    },
    {
      id: 'inActive',
      name: 'In Active',
      content: <LeadList projectId={projectId} leadsList={[]} />,
      disabled: false,
    },
    {
      id: 'register',
      name: 'Register',
      content: <LeadList projectId={projectId} leadsList={[]} />,
      disabled: false,
    },
    {
      id: 'vsg',
      name: 'VSG',
      content: <VsgLeadList leadsList={vsgLeads} projectId={projectId} />,
      disabled: false,
    },
  ];

  return <DesktopTabs tabs={tabs} />;
};

export default ProjectLead;
