import { TObjectId } from 'interfaces/index';
import { IUser } from 'apps/admin/interfaces/index';
import { adminAPISlice } from 'apps/admin/services';

export const projectPartnerAPISlice = adminAPISlice.injectEndpoints({
  endpoints: (build) => ({
    getUsersByRole: build.query({
      query: ({ userRole }: { userRole: string }) =>
        `api/users/role/${userRole}`,
      transformResponse: (response: {
        data: IUser[];
        code: String;
        error: Object;
        message: String;
      }) => {
        return response.data;
      },
    }),
    getUsersByRoleByProjectId: build.query({
      query: ({
        userRole,
        projectId,
      }: {
        userRole: string;
        projectId: TObjectId;
      }) => `api/projects/${projectId}/users/${userRole}`,
      transformResponse: (response: {
        data: IUser[];
        code: String;
        error: Object;
        message: String;
      }) => {
        return response.data;
      },
    }),

    addBusinessHead: build.mutation({
      query: ({
        projectId,
        userId,
        role,
      }: {
        projectId: string;
        userId: string;
        role: string;
      }) => ({
        url: `api/projects/${projectId}/businessHead`,
        method: 'POST',
        body: { userId, role },
      }),
    }),
    addClusterHead: build.mutation({
      query: ({
        projectId,
        businessHead,
        role,
        clusterHead,
      }: {
        projectId: string;
        businessHead?: string;
        clusterHead: string;
        role: string;
      }) => ({
        url: `api/projects/${projectId}/clusterHead`,
        method: 'POST',
        body: { role, businessHead, clusterHead },
      }),
    }),
    addSiteHead: build.mutation({
      query: ({
        projectId,
        clusterHead,
        siteHead,
        role,
      }: {
        projectId: string;
        clusterHead?: string;
        siteHead: string;
        role: string;
      }) => ({
        url: `api/projects/${projectId}/siteHead`,
        method: 'POST',
        body: { clusterHead, siteHead, role },
      }),
    }),
    addTeamLead: build.mutation({
      query: ({
        projectId,
        role,
        teamLead,
        siteHead,
      }: {
        projectId: string;
        teamLead: string;
        role: string;
        siteHead?: string;
      }) => ({
        url: `api/projects/${projectId}/teamLead`,
        method: 'POST',
        body: { role, teamLead, siteHead },
      }),
    }),
    addGreToProject: build.mutation({
      query: ({
        projectId,
        role,
        userId,
      }: {
        projectId: TObjectId;
        userId: TObjectId;
        role: string;
      }) => ({
        url: `api/projects/${projectId}/gre`,
        method: 'POST',
        body: { role, userId },
      }),
    }),

    getSMListByUserRole: build.query({
      query: ({
        userId,
        role,
        projectId,
      }: {
        userId: string;
        role: string;
        projectId: TObjectId;
      }) => `api/projects/${projectId}/user/${userId}/role/${role}`,
      transformResponse: (response: {
        data: IUser[];
        code: String;
        error: Object;
        message: String;
      }) => {
        return response.data;
      },
    }),

    getUsersByProjectId: build.mutation({
      query: ({ projectId }: { projectId: TObjectId }) => ({
        url: `api/projects/${projectId}/users`,
        method: 'GET',
      }),
    }),

    getExecutivesByProjectId: build.mutation({
      query: ({ projectId }: { projectId: TObjectId }) => ({
        url: `api/projects/${projectId}/executives`,
        method: 'GET',
      }),
    }),

    trackUsersLogin: build.mutation({
      query: ({
        userId,
        eventName,
      }: {
        userId: TObjectId;
        eventName: string;
      }) => ({
        url: `api/analytics/logins`,
        method: 'POST',
        body: { userId, eventName },
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetUsersByRoleQuery,
  useGetUsersByRoleByProjectIdQuery,

  useAddBusinessHeadMutation,
  useAddClusterHeadMutation,
  useAddTeamLeadMutation,
  useAddSiteHeadMutation,
  useAddGreToProjectMutation,

  useGetSMListByUserRoleQuery,

  useGetUsersByProjectIdMutation,
  useGetExecutivesByProjectIdMutation,

  useTrackUsersLoginMutation,
} = projectPartnerAPISlice;
