import { useEffect } from 'react';
import { Redirect, Switch, useHistory, useRouteMatch } from 'react-router-dom';

import BookingContent from '../../containers/BookingContent';
import ConditionalRoutes from '../ConditionalRoutes';
// Content header
import UnitSelectionContentHeader from '../../components/contentHeader/unitSelection/UnitSelectionContentHeader';
import CostSheetContentHeader from '../../components/contentHeader/CostSheetContentHeader';
import PaymentPlanContentHeader from '../../components/contentHeader/PaymentPlanContentHeader';
import KycContentHeader from '../../components/contentHeader/KycContentHeader';
import PaymentContentHeader from '../../components/contentHeader/PaymentContentHeader';
import KycDetailsContentHeader from '../../components/contentHeader/KycDetailsContentHeader';
import AgreementContentHeader from '../../components/contentHeader/AgreementContentHeader';
// Content body
import UnitSelection from '../../components/unitSelection/UnitSelection';
import UnitDetails from '../../components/unitSelection/UnitDetails';
import Costsheet from '../../components/costSheet/CostSheet';
import PaymentPlan from '../../components/paymentPlan/PaymentPlan';
import Kyc from '../../components/kyc/Kyc';
import Payment from '../../components/payment/Payment';
import KycDetails from '../../components/kyc/KycDetails';
import Agreement from '../../components/agreement/Agreement';

import { URLS } from '../../../../constants/urls';
import { useSelector } from 'react-redux';
import { getBookingRoutesCurrentPage } from './slice';

const BookingRoutes = () => {
  const { path, url } = useRouteMatch();
  const history = useHistory();

  const bookingRoutesCurrentPage = useSelector(getBookingRoutesCurrentPage);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    history.push(
      `${url}/${bookingRoutesCurrentPage}?${queryParams.toString()}`
    );
  }, [bookingRoutesCurrentPage]);

  return (
    <Switch>
      {/*<Route path={URLS.UNIT_SELECTION}>
        <BookingContent
          headerComponent={UnitSelectionContentHeader}
          contentBodyComponent={UnitSelection}
        />
      </Route>*/}
      <ConditionalRoutes
        path={`${path}/${URLS.UNIT_SELECTION}`}
        component={BookingContent}
        headerComponent={UnitSelectionContentHeader}
        contentBodyComponent={UnitSelection}
        requiredAuth={false}
        requiredUnit={false}
      />

      <ConditionalRoutes
        path={`${path}/${URLS.UNIT_DETAILS}`}
        component={BookingContent}
        headerComponent={UnitSelectionContentHeader}
        contentBodyComponent={UnitDetails}
        requiredAuth={false}
        requiredUnit={false}
      />
      <ConditionalRoutes
        path={`${path}/${URLS.COST_SHEET}`}
        component={BookingContent}
        headerComponent={CostSheetContentHeader}
        contentBodyComponent={Costsheet}
        requiredAuth={true}
        requiredUnit={true}
      />
      <ConditionalRoutes
        path={`${path}/${URLS.PAYMENT_PLAN}`}
        component={BookingContent}
        headerComponent={PaymentPlanContentHeader}
        contentBodyComponent={PaymentPlan}
        requiredAuth={true}
        requiredUnit={true}
      />
      <ConditionalRoutes
        path={`${path}/${URLS.KYC}`}
        component={BookingContent}
        headerComponent={KycContentHeader}
        contentBodyComponent={Kyc}
        requiredAuth={true}
        requiredUnit={true}
      />
      <ConditionalRoutes
        path={`${path}/${URLS.PAYMENT}`}
        component={BookingContent}
        headerComponent={PaymentContentHeader}
        contentBodyComponent={Payment}
        requiredAuth={true}
        requiredUnit={true}
      />
      <ConditionalRoutes
        path={URLS.KYC_DETAILS}
        component={BookingContent}
        headerComponent={KycDetailsContentHeader}
        contentBodyComponent={KycDetails}
        requiredAuth={true}
        requiredUnit={true}
      />
      <ConditionalRoutes
        path={URLS.AGREEMENT}
        component={BookingContent}
        headerComponent={AgreementContentHeader}
        contentBodyComponent={Agreement}
        requiredAuth={true}
        requiredUnit={true}
      />

      {/* Unmatched url redirect to dashbaord */}
      <Redirect to={'error'} />
    </Switch>
  );
};

export default BookingRoutes;
