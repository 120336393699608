import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReactSVG } from 'react-svg';
import ClipLoader from 'react-spinners/ClipLoader';
import { UNIT_STATUSES, UnitAvailableStatus } from 'constants/status';
import {
  setSelectedTower,
  getUnitListById,
} from 'apps/booking/slices/unitSelectionHeader/unitSelectionHeaderNav';
import { getProject } from 'apps/booking/slices/projectSlice';
import { s3BucketName } from 'constants/urls';
import Icon from 'apps/booking/components/common/Icon';

const MasterSVG = () => {
  const dispatch = useDispatch();

  const [masterPlanUrl, setMasterPlanUrl] = useState('');
  const project = useSelector(getProject);
  const projectId = project.id;
  const unitListById = useSelector(getUnitListById);
  const [tooltipContent, setTooltipContent] = useState('');
  const [avaialbleUnit, setAvaialbleUnit] = useState('');
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
  const [zoomLevel, setZoomLevel] = useState(1);

  const maxZoomLevel = 2;
  const isZoomInDisabled = zoomLevel >= maxZoomLevel;
  const isZoomOutDisabled = zoomLevel <= 1;
  const svgStyle = {
    transform: `scale(${zoomLevel})`,
    transformOrigin: 'top left',
  };

  const handleZoomIn = () => {
    if (zoomLevel < maxZoomLevel) {
      setZoomLevel(zoomLevel + 0.5);
    }
  };

  const handleZoomOut = () => {
    if (zoomLevel > 1) {
      setZoomLevel(zoomLevel - 0.5);
    }
  };

  const handleMouseEnter = (event) => {
    const { id } = event.target;
    const towerName = getTowerNameFromSvgPathId(id);
    const unitsAvaialble = getCountAvailableUnitsForTower(towerName);
    setTooltipContent(towerName);
    setAvaialbleUnit(unitsAvaialble);
  };

  const handleMouseMove = (event) => {
    const { layerX, layerY } = event;
    const offset = 30;
    setTooltipPosition({
      x: layerX - offset,
      y: layerY + offset,
    });
  };

  const handleMouseLeave = () => {
    setTooltipContent('');
  };

  const getTowerNameFromSvgPathId = (svgPathId) => {
    const selectedTowerName = svgPathId.replace('a-', '').split('-x-')[0];
    return selectedTowerName.toLowerCase();
  };

  const handleSelectSector = (selectedTowerId) => {
    dispatch(
      setSelectedTower(getTowerNameFromSvgPathId(selectedTowerId).toLowerCase())
    );
  };

  const getCountAvailableUnitsForTower = (towerName) => {
    const availableUnits = Object.values(unitListById).filter(
      (unit) =>
        unit.status === UNIT_STATUSES.AVAILABLE &&
        unit.publishedOnPortal === true &&
        unit.towerName.toLowerCase() === towerName
    );
    return availableUnits.length;
  };

  const isUnitsAvailableForTower = (towerName) => {
    const found = Object.values(unitListById)
      .filter(
        (unit) =>
          unit.status === UNIT_STATUSES.AVAILABLE &&
          unit.publishedOnPortal === true
      )
      .some((unit) => unit.towerName.toLowerCase() == towerName);
    return found;
  };

  const applyStyleOnPathElm = (pathElms) => {
    for (let pathElm of pathElms) {
      const towerName = getTowerNameFromSvgPathId(pathElm.id);
      const isAvailableUnit = isUnitsAvailableForTower(towerName.toLowerCase());

      if (isAvailableUnit) {
        pathElm.classList.add('unit');
        pathElm.addEventListener('click', () => handleSelectSector(pathElm.id));
      }
    }
  };

  const applyMousePointerEvents = (paths, polygons) => {
    paths.forEach((path) => {
      path.addEventListener('mouseenter', handleMouseEnter);
      path.addEventListener('mousemove', handleMouseMove);
      path.addEventListener('mouseleave', handleMouseLeave);
    });

    polygons.forEach((polygon) => {
      polygon.addEventListener('mouseenter', handleMouseEnter);
      polygon.addEventListener('mousemove', handleMouseMove);
      polygon.addEventListener('mouseleave', handleMouseLeave);
    });
  };

  useEffect(() => {
    if (projectId?.length) {
      const cacheBuster = Math.random();
      setMasterPlanUrl(
        `https://${s3BucketName}.s3.ap-south-1.amazonaws.com/projects/${projectId}/floorPlans/master_plan.2d?cache=${cacheBuster}`
      );
    }
  }, [projectId]);

  return (
    <>
      <div className='controlsWrap'>
        <Icon
          onClick={handleZoomIn}
          name='zoom_in'
          propStyles='zoomIcon zoomIn'
          disabled={isZoomInDisabled}
        />
        <Icon
          onClick={handleZoomOut}
          name='zoom_out'
          propStyles='zoomIcon zoomOut'
          disabled={isZoomOutDisabled}
        />
      </div>
      <ReactSVG
        src={masterPlanUrl}
        loading={() => <ClipLoader />}
        id='svg'
        className='svg'
        style={svgStyle}
        beforeInjection={(svg) => {
          svg.removeAttribute('height');

          const paths = svg.querySelectorAll('svg path');
          const polygons = svg.querySelectorAll('svg polygon');
          applyStyleOnPathElm(paths);
          applyStyleOnPathElm(polygons);
          applyMousePointerEvents(paths, polygons);
        }}
      />
      {tooltipContent && (
        <div
          className='tooltip'
          style={{
            top: tooltipPosition.y,
            left: tooltipPosition.x,
          }}>
          <p className={`toolTip_bold ${avaialbleUnit === 0 && 'disable'}`}>
            <span className='toolTip_bold'>{tooltipContent.toUpperCase()}</span>
          </p>
          <p className='smallText_tooltip'>
            {avaialbleUnit === 0
              ? UnitAvailableStatus.UNAVAILABLE
              : avaialbleUnit === 1
              ? `1 Unit ${UnitAvailableStatus.AVAILABLE}`
              : `${avaialbleUnit} Units ${UnitAvailableStatus.AVAILABLE}`}
          </p>
        </div>
      )}
    </>
  );
};

export default MasterSVG;
