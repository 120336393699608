import Icon from 'apps/booking/components/common/Icon';
import Modal from 'apps/booking/components/common/modal/Modal';
import { dateToDaythMonthYear } from 'utils/dateFormator';
import { IProjectEvent } from 'apps/admin/components/pages/SuperAdminDashboard/SuperAdminProjectList/modals/ProjectEventsModal';

import styles from './styles.module.css';

interface IProjectEventDetailsModalProps {
  onModalClose: () => void;
  projectEvent: IProjectEvent;
}

const ProjectEventDetailsModal = (props: IProjectEventDetailsModalProps) => {
  const { onModalClose, projectEvent } = props;

  return (
    <Modal onOutsideClick={onModalClose} additionalClasses={styles.eventModal}>
      <header className={styles.modalHeader}>
        <div>
          <h2 className={styles.modalHeading}>Details of Events</h2>
          <p className={styles.modalDesc}>{projectEvent.desc}</p>
        </div>
        <Icon
          name='close'
          propStyles={styles.crossIcon}
          onClick={onModalClose}
        />
      </header>

      <main className={styles.modalBody}>
        <div className={styles.modalBodyLeftContainer}>
          <p className={styles.eventDetailsLabel}>Developer Name</p>
          <p className={styles.eventDetailsValue}>
            {projectEvent.developerName}
          </p>
          <p className={styles.eventDetailsLabel}>Event Title</p>
          <p className={styles.eventDetailsValue}>{projectEvent.title}</p>
          <p className={styles.eventDetailsLabel}>Event Venue</p>
          <p className={styles.eventDetailsValue}>{projectEvent.venue}</p>
        </div>
        <div>
          <p className={styles.eventDetailsLabel}>Start Date</p>
          <p className={styles.eventDetailsValue}>
            {dateToDaythMonthYear(projectEvent.startDate)}
          </p>
          <p className={styles.eventDetailsLabel}>End Date</p>
          <p className={styles.eventDetailsValue}>
            {dateToDaythMonthYear(projectEvent.endDate)}
          </p>
        </div>
      </main>
    </Modal>
  );
};

export default ProjectEventDetailsModal;
