// import { useHistory } from 'react-router';
// import { ERROR } from '../../../constants/status';
// import { URLS } from '../../../constants/urls';
import useBlockedUnitExpireCountdown from '../../../hooks/useBlockedUnitExpireCountdown';
// import { addToast } from '../../../slices/toasts';
import { isEmpty } from '../../../../../utils/utils';
import { useSelector } from 'react-redux';
import { getUnitCart } from 'apps/booking/slices/selectedUnit';
import { UNIT_STATUSES } from 'constants/status';

const BlockedUnitCountDownTimer = (props) => {
  // const history = useHistory();
  const hourMinSecCountDown = useBlockedUnitExpireCountdown();
  const unitCart = useSelector(getUnitCart);

  const isUnitExpired =
    !isEmpty(hourMinSecCountDown) && hourMinSecCountDown.hours.length > 2;
  // const isBlockedUnitExpired = !isEmpty(hourMinSecCountDown) && hourMinSecCountDown.seconds.length > 2;
  // if(isBlockedUnitExpired){
  //   // addToast({
  //   //   type: ERROR,
  //   //   primaryMessage: 'Blocked Unit Expired!',
  //   //   secondaryMessage: 'Unit will be available soon'
  //   // })
  //   alert("Blocked Unit Expired. Unit will be available soon.");

  //   dispatch(setBookingRoutesCurrentPage(URLS.UNIT_SELECTION));
  // }
  return (
    <>
      {!isEmpty(hourMinSecCountDown) && (
        <div>
          <div className='info-card'>
            {isUnitExpired ? (
              <>
                <header className='info-card__header'>
                  Your{' '}
                  {unitCart.unit.status === UNIT_STATUSES.BLOCKED
                    ? 'blocked'
                    : 'reserved'}{' '}
                  unit has expired
                </header>
                <div className='info-card__body'>Expired</div>
              </>
            ) : (
              <>
                <header className='info-card__header'>
                  Your {unitCart.unit.status.toLowerCase()} unit expires in
                </header>
                {/*<div className='info-card__body'>{`${hourMinSecCountDown.hours}:${hourMinSecCountDown.minutes}:${hourMinSecCountDown.seconds}`}</div>*/}
                <div className='info-card__body'>
                  {' '}
                  {hourMinSecCountDown.hours < 24 ? (
                    <span className='f-bold'>
                      {hourMinSecCountDown.hours}hr{' '}
                      {hourMinSecCountDown.minutes}min{' '}
                      {hourMinSecCountDown.hours < 24 &&
                        hourMinSecCountDown.seconds}
                      sec
                    </span>
                  ) : (
                    <span className='f-bold'>
                      {Math.floor(hourMinSecCountDown.hours / 24)}day{' '}
                      {hourMinSecCountDown.hours % 24}hr{' '}
                      {hourMinSecCountDown.minutes}min
                    </span>
                  )}{' '}
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default BlockedUnitCountDownTimer;
