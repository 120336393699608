import { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

import Icon from 'apps/booking/components/common/Icon';
import { ICpLead } from 'apps/cp/interfaces';
import { getActivityIcon } from 'apps/cp/components/pages/CrSiteVisitPage/SiteVisitTiles';
import { getNumberOfDays } from 'utils/utils';
import {
  getLeadStatus,
  getLeadSubStatus,
  getProjectCount,
  getStatusClassName,
  getleadPreferences,
} from 'apps/cp/components/pages/CrRecommendedProjectsPage/utils';
import { getDateFormatInddmmyy } from 'utils/dateFormator';
import { LEAD_STATUS } from 'constants/status';
import TextInput from 'apps/booking/components/common/inputs/TextInput';

import styles from './styles.module.css';

interface ICpLeadItemProps {
  cpLeads: ICpLead[];
  tabIndex: number;
}

export const getMobileFromCpLead = (cpLead: ICpLead) => {
  return cpLead.user?.mobile || `XXXXX X${cpLead.mobileLastFourDigits}`;
};

const CpLeadItem = (props: ICpLeadItemProps) => {
  const { cpLeads, tabIndex } = props;

  const [searchedLeadName, setSearchedLeadName] = useState('');

  const { register, unregister, setValue } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });
  const history = useHistory();

  const searchedLeads = useMemo(() => {
    return (
      cpLeads.filter((cpLead) =>
        cpLead.name.toLowerCase().includes(searchedLeadName.toLowerCase())
      ) || []
    );
  }, [searchedLeadName, cpLeads]);

  const getLeadLastActivity = (leadLastActivity: Date) => {
    const days = getNumberOfDays(leadLastActivity, 'startDate');
    if (days > 45) {
      return `Last Activity on ${getDateFormatInddmmyy(leadLastActivity)}`;
    }

    if (days <= 0) {
      return 'Last Activity is Today';
    }

    return `Last Activity ${days} ${days == 1 ? 'day' : 'days'} Ago`;
  };

  const handleSearch = (e: any) => {
    setSearchedLeadName(e.target.value.trim());
  };

  const handleCloseSearch = () => {
    setSearchedLeadName('');
  };

  return (
    <div className={styles.itemContainer}>
      <div className={styles.searchWrapper}>
        <TextInput
          type='search'
          onChange={handleSearch}
          label=''
          placeHolder='Search lead names'
          propStyles={styles.searchContainer}
          startIconName='search'
          startIconVariant='SECONDARY'
          endIconName={searchedLeadName ? 'close' : undefined}
          onEndIconClick={handleCloseSearch}
          defaultValue={searchedLeadName}
          name='projects'
          setValue={setValue}
          register={register}
          unregister={unregister}
          crApp
          searchForMobile
        />
      </div>
      {searchedLeads.map((cpLead) => (
        <div key={cpLead.id}>
          <div
            onClick={() =>
              history.push('/cp/dashboard/addLead/recommendedProjects', {
                cpLeadReq: cpLead,
                tabIndex,
              })
            }
            className={styles.leadContainer}>
            <div className={styles.leftConatainer}>
              <div className={styles.cpLead}>
                <p className={styles.leadName}>{cpLead.name}</p>
                <p className={styles.mobile}>{getMobileFromCpLead(cpLead)}</p>
              </div>
              <div className={styles.statusWrapper}>
                <p
                  className={`${styles.leadStatus} ${
                    styles[
                      getStatusClassName(
                        cpLead.status || LEAD_STATUS.JUST_ADDED
                      )
                    ]
                  }`}>
                  {getLeadStatus(cpLead.status)}
                </p>
                <p className={styles.leadSubStatus}>
                  <Icon
                    name={getActivityIcon(
                      getNumberOfDays(cpLead.lastActivityDate, 'startDate')
                    )}
                    propStyles={`${styles.substatusIcon} ${
                      styles[
                        getActivityIcon(
                          getNumberOfDays(cpLead.lastActivityDate, 'startDate')
                        )
                      ]
                    }`}
                  />
                  {parseInt(getProjectCount(cpLead.projects, cpLead.status))
                    ? getProjectCount(cpLead.projects, cpLead.status)
                    : null}
                  {getLeadSubStatus(cpLead.status || LEAD_STATUS.JUST_ADDED)}
                </p>
              </div>

              <div className={styles.addressWrap}>
                <p className={styles.adress}>
                  {getleadPreferences(cpLead?.propertyConfigs, 3)}
                  {cpLead.preferenceAreasTemp &&
                    cpLead.preferenceAreasTemp.length > 0 &&
                    ' | ' + getleadPreferences(cpLead?.preferenceAreasTemp, 2)}
                </p>
              </div>
              <div className={styles.activity}>
                {cpLead.latestActivity
                  ? cpLead.latestActivity
                  : getLeadLastActivity(cpLead.lastActivityDate)}
              </div>
            </div>
            <div className={styles.leadQRCode}>
              <Icon
                name='keyboard_arrow_right'
                propStyles={styles.rightArrow}
              />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default CpLeadItem;
