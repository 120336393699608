import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ConfirmationModal, {
  IConfirmationModal,
} from 'apps/booking/components/common/genericModals/ConfirmationModal';
import Icon from 'apps/booking/components/common/Icon';
import { IEoiDetails } from 'apps/admin/interfaces';
import useToast from 'hooks/useToast';
import { useCancelBookingMutation } from 'apps/admin/services/projectsAPISlice';
import { TObjectId } from 'interfaces';
import { getEoiList, setEoiList } from 'apps/admin/slices/projectAdminSlice';
import { EoiStatus } from 'constants/status';
import { ILeadUnitCart } from 'apps/cp/interfaces';
import { useReleaseUnitByUnitCartIdMutation } from 'apps/booking/services/unitsAPISlice';

export interface ICancelBookingCell {
  unitCart: ILeadUnitCart;
}

export interface IBookingUser {
  unitId?: TObjectId;
  eoiId?: TObjectId;
}

const CancelBookingCell = (props: ICancelBookingCell) => {
  const { unitCart } = props;

  const dispatch = useDispatch();
  const eoiList = useSelector(getEoiList);

  const [cancelBooking, setCancelBooking] = useState<IConfirmationModal>();
  const [cancelBookingApi] = useReleaseUnitByUnitCartIdMutation();
  const [addToast] = useToast();

  const cancelBookingUser = () => {
    setCancelBooking({
      accent: 'DANGER',
      heading: 'Do you want to Cancel Blocking',
      onModalClose: () => setCancelBooking(undefined),
      onResponse: async (confirmationResponse: any) => {
        try {
          await confirmationResponse;
          await cancelBookingApi(unitCart.id).unwrap();
          addToast({
            type: 'SUCCESS',
            primaryMessage: 'Successfully Cancelled',
          });
        } catch (err) {
          const errMsg = (err as any).data.message;
          addToast({
            type: 'ERROR',
            primaryMessage: errMsg,
          });
        }
      },
    });
  };

  return (
    <>
      {cancelBooking && <ConfirmationModal {...cancelBooking} />}
      <Icon
        name='highlight_off'
        propStyles='actionIcon'
        onClick={() => cancelBookingUser()}
      />
    </>
  );
};

export default CancelBookingCell;
