import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import Icon, { IconNames } from 'apps/booking/components/common/Icon';
import {
  ICollateral,
  IProject,
} from 'apps/admin/components/pages/SuperAdminDashboard/SuperAdminProjectList';
import { useCollateralViewCpAppAnalyticsMutation } from 'apps/cp/services/cpUserAPISlice';
import { useUpdateCollateralShareCountAPIMutation } from 'apps/cp/services/cpProjectAPISlice';
import { getCpUser } from 'apps/cp/slices/cpUserSlice';
import PreviewModal from 'apps/cp/components/pages/MarketingCollateralPage/MarketingCollateralCardList/PreviewModal';
import { ICpUser } from 'apps/cp/interfaces/index';
import { TEST_USERS } from 'apps/cp/constants';

import styles from './styles.module.css';

interface IMarketingCollateralCardListProps {
  project: IProject;
}

export const youtubeUrlReg =
  /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;

const MarketingCollateralCardList = (
  props: IMarketingCollateralCardListProps
) => {
  const { project } = props;

  const [previewCollateral, setPreviewCollateral] = useState<ICollateral>();

  const cpUser = useSelector(getCpUser) as ICpUser;
  const [collateralViewCpAppAnalyticsAPI] =
    useCollateralViewCpAppAnalyticsMutation();
  const [updateCollateralShareCountAPI] =
    useUpdateCollateralShareCountAPIMutation();

  const fallBackIcon = 'home_work';

  useEffect(() => {
    if (previewCollateral) {
      document.body.className = 'no-scroll';
    } else document.body.className = '';
  }, [previewCollateral]);

  const handleShareClick = async (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    collateral: ICollateral
  ) => {
    event.stopPropagation();

    const collateralText = collateral.text.replaceAll('NEWLINE', '\n');

    if (!TEST_USERS.includes(cpUser.user.mobile)) {
      try {
        await updateCollateralShareCountAPI({
          projectId: project.id,
          cpUserId: cpUser.id,
          collateralId: collateral.id,
        }).unwrap();
      } catch (error) {
        console.error('Share count analytics', error);
      }
    }

    if (navigator.share) {
      navigator
        .share({
          title: collateral.title,
          text: collateralText,
        })
        .then(() => {
          // TODO: add tost here
          console.log('Successfully shared');
        })
        .catch((error) => {
          console.error('Please try Manually', error);
        });
    }
  };

  const handleOpenModel = (collateral: ICollateral) => {
    setPreviewCollateral(collateral);
    collateralViewCpAppAnalyticsAPI({
      projectId: project.id,
      cpUserId: cpUser.id,
      collateralId: collateral.id,
    }).unwrap();
  };

  const handleModelClose = () => {
    setPreviewCollateral(undefined);
  };

  const isValidPreviewUrl = (url: string) => {
    const fileFormats = ['mp4', 'm4v', 'jpg', 'jpeg', 'png', 'pdf'];
    const fileType = url?.split('.').pop() || '';
    if (fileFormats.includes(fileType) || url.match(youtubeUrlReg)) {
      return true;
    }
    return false;
  };

  // TODO: Add constraint to key type, ideally key should be of type TCollateralType
  const collateralTypeIcons: { [key: string]: IconNames } = {
    BROCHURE: 'menu_book',
    COST_SHEET: 'description',
    PRICE_SHEET: 'description',
    OPPORTUNITY_DOCUMENT: 'menu_book',
    AV: 'movie',
    MOVIE: 'movie',
    FLOOR_PLANS: 'photo_size_select_actual',
    UNIT_PLANS: 'photo_size_select_actual',
    PHOTOS: 'photo_size_select_actual',
  };

  return (
    <div className={styles.containerWrap}>
      {previewCollateral && (
        <PreviewModal
          onShareClick={handleShareClick}
          onClose={handleModelClose}
          previewCollateral={previewCollateral as ICollateral}
        />
      )}
      {project.collaterals.map((collateral, index) => (
        <>
          <div
            key={index}
            className={styles.collateralWrapper}
            onClick={
              isValidPreviewUrl(collateral.mobileUrl || collateral.url)
                ? () => handleOpenModel(collateral)
                : undefined
            }>
            <div className={styles.iconWrapper}>
              <Icon
                name={collateralTypeIcons[collateral.type] || fallBackIcon}
                propStyles={`${styles[collateralTypeIcons[collateral.type]]} ${
                  styles.colletralIcon
                }`}
              />
              <div
                onClick={(event) => handleShareClick(event, collateral)}
                className={styles.previewWrap}>
                <Icon name='share' propStyles={styles.shareIcon} />
              </div>
            </div>
            <p className={styles.title}>{collateral.title}</p>
            <p className={styles.subTitle}>{collateral.subTitle}</p>
          </div>
        </>
      ))}
    </div>
  );
};

export default MarketingCollateralCardList;
