import { useState, useEffect, useMemo } from 'react';
import {
  NavLink,
  useHistory,
  useLocation,
  useRouteMatch,
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { getAdminUser } from 'apps/admin/slices/adminSlice';
import { IRouteUrls, URLS } from 'constants/urls';
import { UserRole } from 'constants/userRoles';
import { dateToTimeDayDateMonth } from 'utils/dateFormator';
import useAdminLogOut from 'apps/admin/components/pages/AdminLogin/hooks/useAdminLogOut';
import { setAppSliceIsFetching } from 'slices/appSlice';
import { IUser } from 'apps/admin/interfaces';

const getISTDate = () => {
  var currentTime = new Date();
  var currentOffset = currentTime.getTimezoneOffset();
  var ISTOffset = 330; // IST offset UTC +5:30
  var ISTTime = new Date(
    currentTime.getTime() + (ISTOffset + currentOffset) * 60000
  );
  return ISTTime;
};

const AdminHeader = () => {
  const [today, setDateTime] = useState(getISTDate());

  const user = useSelector(getAdminUser) as IUser;
  const { path } = useRouteMatch();
  const { pathname: currentPath } = useLocation();
  const history = useHistory();
  const { removeAdminUserLocal, logginOut } = useAdminLogOut();
  const dispatch = useDispatch();

  interface IheaderTabs {
    tabName: string;
    redirectPath: string;
  }

  const headerTabs: IheaderTabs[] = [
    {
      tabName: 'Overview',
      redirectPath: URLS[user.role as keyof IRouteUrls],
    },

    {
      tabName: 'Projects',
      redirectPath:
        user.role === UserRole.EXECUTIVE
          ? `${path}/executives/analytics`
          : `${path}/projects/analytics`,
    },
    {
      tabName: 'Organisations',
      redirectPath:
        user.role === UserRole.ADMIN
          ? `${path}/organisations/admins`
          : `${path}/organisations`,
    },
  ];

  const dateTime = dateToTimeDayDateMonth(today);

  useEffect(() => {
    dispatch(setAppSliceIsFetching(logginOut));
  }, [logginOut]);

  useEffect(() => {
    const timer = setInterval(() => {
      setDateTime(getISTDate());
    }, 60 * 1000);
    return () => {
      clearInterval(timer);
    };
  }, []);

  const handleAdminLogout = async () => {
    await removeAdminUserLocal();
    history.push('/admin');
  };

  const selectedTab = useMemo(() => {
    if (currentPath.split('/').length >= 3) {
      const tab = headerTabs.find(
        (tab) =>
          tab.tabName.toLowerCase() === currentPath.split('/')[2].toLowerCase()
      );
      if (tab) {
        return tab.tabName;
      }
    }
    if (
      user.role === UserRole.CLUSTER_HEAD ||
      user.role === UserRole.BUSINESS_HEAD ||
      user.role === UserRole.SITE_HEAD ||
      user.role === UserRole.TEAM_LEAD ||
      user.role === UserRole.EXECUTIVE
    ) {
      return headerTabs[1].tabName;
    }
    return headerTabs[0].tabName;
  }, [headerTabs, currentPath]);

  return (
    <div className='app-header'>
      <nav className='nav'>
        <div className='nav__left-container'>
          <NavLink className='nav__brand' to='/admin'>
            <div className='nav__brand__relata-logo'>relata.io</div>
          </NavLink>
          {/* // TODO: To be unabled with project name intergration 
          <div className='nav__developer-name'>Sheth Creations</div>
          */}
        </div>
        <ul className='list nav__list'>
          {headerTabs.map((tab) => (
            <NavLink
              key={tab.tabName}
              title={tab.tabName}
              to={tab.redirectPath}
              className={`${selectedTab === tab.tabName && 'nav__link'}  ${
                (tab.tabName === 'Overview' ||
                  tab.tabName === 'Organisations') &&
                (user.role === UserRole.EXECUTIVE ||
                  user.role === UserRole.BUSINESS_HEAD ||
                  user.role === UserRole.CLUSTER_HEAD ||
                  user.role === UserRole.SITE_HEAD ||
                  user.role === UserRole.TEAM_LEAD)
                  ? 'disbleTab'
                  : ''
              }
              `}>
              <li className='nav__item'>{tab.tabName}</li>
            </NavLink>
          ))}

          {/* <li className='nav__item'>
            <Icon name='settings' propStyles='settingsIcon' />
          </li> */}
          <li className='nav__item'>
            <div className='thumb' onClick={handleAdminLogout}>
              <img
                className='img-fluid'
                src='https://bootdey.com/img/Content/avatar/avatar7.png'
                alt=''
              />
            </div>
          </li>
          <li className='nav__item'>{user.name}</li>
          {/*
            <li className='nav__item'>
              <Icon name='help_outline' />
            </li>
            <li className='nav__item'>
              <Icon name='chat_bubble_outline' />
            </li>
            
            <li className='nav__item'>
              <Icon name='apps' />
            </li>
          */}
        </ul>
      </nav>
    </div>
  );
};

export default AdminHeader;
