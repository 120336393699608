import { useState } from 'react';

import { TObjectId } from 'interfaces';
import Button from 'apps/booking/components/common/buttons/Button';
import AddingSmFormModal from 'apps/admin/components/pages/ProjectPartnerLeads/modals/AddingSmFormModal';

interface IAddingSmCellProps {
  projectId: TObjectId;
  cpFirmId: TObjectId;
  cpUserId: TObjectId;
}

const AddingSmCell = (props: IAddingSmCellProps) => {
  const { projectId, cpFirmId, cpUserId } = props;

  const [showAddingSmFormModal, setShowAddingSmFormModal] = useState(false);

  const handleOpenAddingSmForm = () => {
    setShowAddingSmFormModal(true);
  };

  return (
    <>
      {showAddingSmFormModal && (
        <AddingSmFormModal
          onModalClose={() => setShowAddingSmFormModal(false)}
          projectId={projectId}
          cpFirmId={cpFirmId}
          cpUserId={cpUserId}
        />
      )}
      <Button onClick={handleOpenAddingSmForm} accent='primary'>
        {'Tag Sm'}
      </Button>
    </>
  );
};

export default AddingSmCell;
