import { Route, Switch, useRouteMatch } from 'react-router-dom';

import { UserRole } from 'constants/userRoles';
import AdminDashboard from 'apps/admin/components/pages/AdminDashboard/AdminExecutiveList';
import CollaboratorsList from 'apps/admin/components/pages/CollaboratorsList';
import AllFirms from 'apps/admin/components/pages/AllFirms';
import OrganisationsPartners from 'apps/admin/components/pages/OrganisationsPartners';

const OrganisationRoutes = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/admins`}>
        <AdminDashboard userRole={UserRole.ADMIN} />
      </Route>
      <Route exact path={`${path}/collaborators`}>
        <CollaboratorsList />
      </Route>
      <Route path={`${path}/executives`}>
        <AdminDashboard userRole={UserRole.EXECUTIVE} />
      </Route>
      <Route exact path={`${path}/partner-firms`}>
        <AllFirms />
      </Route>
      <Route path={`${path}/channel-partners`}>
        <OrganisationsPartners />
      </Route>
    </Switch>
  );
};

export default OrganisationRoutes;
