import { useDispatch, useSelector } from 'react-redux';

import { ICellRendererParams } from 'ag-grid-community';
import { useUpdateEoiStatusByEoiIdMutation } from 'apps/admin/services/projectsAPISlice';
import { EoiBookingStatus } from 'constants/status';
import useToast from 'hooks/useToast';
import { useState, useEffect } from 'react';

import styles from './styles.module.css';
import { getEoiList, setEoiList } from 'apps/admin/slices/projectAdminSlice';
import { IEoiDetails } from 'apps/admin/interfaces';

interface IToggleBookingCell {
  rowData: ICellRendererParams;
}

const ToggleBookingCell = (props: IToggleBookingCell) => {
  const { rowData } = props;

  const dispatch = useDispatch();
  const eoiList = useSelector(getEoiList);

  const [isChecked, setIsChecked] = useState(false);

  const [addToast] = useToast();

  useEffect(() => {
    if (rowData.data.status === EoiBookingStatus.ACTIVE_BOOKING_ENABLED) {
      setIsChecked(true);
    } else setIsChecked(false);
  }, [rowData.data.status]);

  const [updateBookingStatusAPI] = useUpdateEoiStatusByEoiIdMutation();

  const handleToggleButtonChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    try {
      const newCheckedValue = !isChecked;
      setIsChecked(newCheckedValue);
      const updateBookingStatus = await updateBookingStatusAPI({
        eoiId: rowData.data.id,
        bookingStatus: newCheckedValue
          ? EoiBookingStatus.ACTIVE_BOOKING_ENABLED
          : EoiBookingStatus.ACTIVE_BOOKING_DISABLED,
      }).unwrap();
      addToast({
        type: 'SUCCESS',
        primaryMessage: 'Successfully Updated',
      });
      handleUpdateRow(
        rowData.data.id,
        newCheckedValue
          ? EoiBookingStatus.ACTIVE_BOOKING_ENABLED
          : EoiBookingStatus.ACTIVE_BOOKING_DISABLED
      );
    } catch (err) {
      const errMsg = (err as any).data.message;
      addToast({
        type: 'ERROR',
        primaryMessage: errMsg,
      });
    }
  };

  const handleUpdateRow = (id: string, newStatus: string) => {
    const newEoiList = eoiList.map((eoi: IEoiDetails) =>
      eoi.id === id ? { ...eoi, status: newStatus } : eoi
    );
    dispatch(setEoiList(newEoiList));
  };

  return (
    <label className={styles.switch}>
      <input
        type='checkbox'
        // defaultChecked={rowData.data.available}
        checked={isChecked}
        onChange={handleToggleButtonChange}
      />
      <span className={`${styles.slider} ${styles.roundEdges}`}></span>
      <p>{isChecked ? 'Enabled' : 'Disabled'}</p>
    </label>
  );
};

export default ToggleBookingCell;
