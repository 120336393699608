import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useState } from 'react';

import AgreementPlank from '../agreement/AgreementPlank';
import CostSheetPlank from '../costSheet/CostSheetPlank';
import PaymentPlanPlank from '../paymentPlan/PaymentPlanPlank';
import Image from '../common/Image';

import { floorPlanFallback, URLS } from '../../../../constants/urls';
import { getUnitCart } from '../../slices/selectedUnit';
import { floorPlanFileName } from '../unitSelection/utils';
import PaymentPlanPdfDownloadButton from '../paymentPlan/PaymentPlanPdfDownloadButton';
import CostSheetPdfDownloadButton from '../costSheet/CostSheetPdfDownloadButton';
import { getUser } from '../../slices/auth';
import AgreementDownloadButton from '../agreement/AgreementDownloadButton';

const Tabs = {
  MILESTONES: 'MILESTONES',
  COSTSHEET: 'COSTSHEET',
  AGREEMENT: 'AGREEMENT',
};

export const TabContainer = (props) => {
  const { setSelectedTab, selectedTab } = props;

  return (
    <>
      <div className='booked-unit-summary-tab-container'>
        <div className='booked-unit-summary-tab-container__header'>
          <div
            className={`booked-unit-summary-tab-container__header-item ${
              selectedTab === Tabs.MILESTONES && 'active'
            }`}
            onClick={() => setSelectedTab(Tabs.MILESTONES)}>
            Milestone
          </div>
          <div
            className={`booked-unit-summary-tab-container__header-item ${
              selectedTab === Tabs.COSTSHEET && 'active'
            }`}
            onClick={() => setSelectedTab(Tabs.COSTSHEET)}>
            Cost Sheet
          </div>
          <div
            className={`booked-unit-summary-tab-container__header-item ${
              selectedTab === Tabs.AGREEMENT && 'active'
            }`}
            onClick={() => setSelectedTab(Tabs.AGREEMENT)}>
            Agreement
          </div>
        </div>

        <div className='booked-unit-summary-tab-container__body'>
          {selectedTab === Tabs.MILESTONES && <PaymentPlanPlank />}
          {selectedTab === Tabs.COSTSHEET && <CostSheetPlank />}
          {selectedTab === Tabs.AGREEMENT && (
            <AgreementPlank editAllowed={false} />
          )}
        </div>
      </div>
    </>
  );
};

const BookedUnitCartSummary = () => {
  const history = useHistory();

  const [selectedTab, setSelectedTab] = useState(Tabs.MILESTONES);

  const user = useSelector(getUser);
  const unitCart = useSelector(getUnitCart);
  const selectedUnit = unitCart.unit;
  const fileName = floorPlanFileName(selectedUnit);

  const unitDetails = [
    {
      lable: 'Unit',
      value: `${selectedUnit.towerName} ${selectedUnit.blockName}`,
    },
    {
      lable: 'Super Built-up',
      value: `${selectedUnit.area.value} {convertMeasureUnitToDisplayText(selectedUnit.area.unit)}`,
    },
    {
      lable: 'Project',
      value: 'Embassy Edge',
    },
    {
      lable: 'Unit Facing',
      value: selectedUnit.view,
    },
  ];

  return (
    <div className='booked-unit-summary'>
      <header className='booked-unit-summary__header'>
        Summary of your <span className='c-accent f-bold'>Purchased Home</span>
      </header>
      <div className='booked-unit-summary__body'>
        <div className='booked-unit-summary__details'>
          <TabContainer
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
          />
        </div>
        <div className='booked-unit-summary__layout'>
          <div className='booked-unit-summary__layout-photo'>
            <Image
              src={`https://relata-temp.s3.ap-south-1.amazonaws.com/embassy/2d/${fileName}`}
              alt='Unit 2D plan'
              fallbackSrc={floorPlanFallback}
            />
          </div>
          <div className='booked-unit-summary__layout-details'>
            {unitDetails.map((unitDetail) => (
              <div className='booked-unit-summary__layout-details-item'>
                <div className='booked-unit-summary__layout-details-item-lable'>
                  {unitDetail.lable}
                </div>
                <div className='booked-unit-summary__layout-details-item-value'>
                  {unitDetail.value}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <footer className='payment-plan__footer'>
        <button
          type='button'
          className='btn btn--transparent'
          onClick={() => {
            history.push(URLS.DASHBOARD_BASE);
          }}>
          Back to Dashboard
        </button>

        {selectedTab === Tabs.MILESTONES && (
          <PaymentPlanPdfDownloadButton unitCartId={unitCart.id} />
        )}
        {selectedTab === Tabs.COSTSHEET && (
          <CostSheetPdfDownloadButton
            userId={user.id}
            unitId={selectedUnit.id}
          />
        )}
        {selectedTab === Tabs.AGREEMENT && (
          <AgreementDownloadButton unitCartId={unitCart.id} />
        )}
      </footer>
    </div>
  );
};

export default BookedUnitCartSummary;
