import { useState } from 'react';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';

import Icon from 'apps/booking/components/common/Icon';
import Image from 'apps/booking/components/common/Image';

import styles from './styles.module.css';

interface IImageZoomViewerProps {
  previewUrl: string;
}

const ImageZoomViewer = (props: IImageZoomViewerProps) => {
  const { previewUrl } = props;

  const [zoomPercent, setZoomPercent] = useState(0);

  const handleZoomOut = (zoomOut: Function) => {
    zoomOut();
    if (zoomPercent > 0) {
      setZoomPercent(zoomPercent - 10);
    } else {
      setZoomPercent(0);
    }
  };

  const handleZoomIn = (zoomIn: Function) => {
    zoomIn();
    if (zoomPercent < 50) {
      setZoomPercent(zoomPercent + 10);
    } else {
      setZoomPercent(50);
    }
  };

  return (
    <TransformWrapper>
      {({ zoomIn, zoomOut }) => (
        <>
          <div className={styles.controlsWrap}>
            <Icon
              onClick={() => handleZoomOut(zoomOut)}
              name='remove'
              propStyles={styles.zoomIcon}
            />

            <p className={styles.zoomPercent}>{zoomPercent}%</p>

            <Icon
              onClick={() => handleZoomIn(zoomIn)}
              name='add'
              propStyles={styles.zoomIcon}
            />
          </div>
          <TransformComponent>
            <Image src={previewUrl} containerClass={styles.imageConatiner} />
          </TransformComponent>
        </>
      )}
    </TransformWrapper>
  );
};

export default ImageZoomViewer;
