//React
import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

// ThridParty
// import 'ag-grid-enterprise';
// import { AgGridColumn, AgGridReact } from 'ag-grid-react';
// import { ICellRendererParams } from 'ag-grid-community';

// Utils
import { getVRUrl } from 'apps/admin/components/pages/ExecutiveDashboard/utils';

// Components
import ScheduledDemoModal from 'apps/admin/components/pages/ExecutiveDashboard/ScheduledDemoModal';
import SendInviteModal from 'apps/admin/components/pages/ExecutiveDashboard/SendInviteModal';
import ScheduledDemoSuccessModal from 'apps/admin/components/pages/ExecutiveDashboard/ScheduledDemoSuccessModal/index';
import Button from 'apps/booking/components/common/buttons/Button';
import Modal from 'apps/booking/components/common/modal/Modal';
import Icon from 'apps/booking/components/common/Icon';
import { getAdminUser, getAdminCompany } from 'apps/admin/slices/adminSlice';
import ExecutiveQrCodeModal from './ExecutiveQrCodeModal';

// Interfaces
import { IDemoRoom } from 'apps/admin/components/pages/SuperAdminDashboard/SuperAdminProjectList/modals/AddDemoRoomModal';
import { IProject } from 'apps/admin/components/pages/SuperAdminDashboard/SuperAdminProjectList';

//Constants
import { CLUB_RHODIUM_COMPANY_ID } from 'apps/cp/constants/ids';

// Styles
import styles from './styles.module.css';

interface ExecutiveDashboardProps {
  project: IProject;
}

const ExecutiveDashboard = (props: ExecutiveDashboardProps) => {
  const { project } = props;

  const [showScheduledDemoModal, setShowScheduledDemoModal] = useState(false);
  const [showSendInviteModal, setShowSendInviteModal] = useState(false);
  const [showScheduledDemoSuccessModal, setShowScheduledDemoSuccessModal] =
    useState(false);
  const [showNoVRUrlModal, setShowNoVRUrlModal] = useState(false);
  const [showQrModal, setShowQrModal] = useState(false);

  // const defaultColDef = {
  //   sortable: false,
  //   filter: false,
  //   resizable: false,
  //   suppressMenu: false,
  //   editable: false,
  //   flex: 1
  // };

  const adminUser = useSelector(getAdminUser);
  const executiveId = adminUser?.id as string;
  const company = useSelector(getAdminCompany);

  const HandleRescheduleBtnClick = () => {
    setShowScheduledDemoModal(false);
    setShowScheduledDemoSuccessModal(false);
    setShowSendInviteModal(true);
  };

  const handleDemoSuccessfullyScheduled = () => {
    setShowScheduledDemoModal(false);
    setShowScheduledDemoSuccessModal(true);
    setShowSendInviteModal(false);
  };
  const handelScheduledDemoModalClose = () => {
    setShowScheduledDemoModal(false);
  };

  const handelSendInviteModalClose = () => {
    setShowSendInviteModal(false);
  };

  const handelScheduledDemoSuccessModalClose = () => {
    setShowScheduledDemoSuccessModal(false);
  };

  const executivesWithDemoRoom = project.executivesWithDemoRoom.find(
    (e) => e.executive.id === executiveId
  );

  const {
    virtualRoomId,
    hostPassword,
    fallbackVirtualRoomUrl: demoRoomUrl,
  } = executivesWithDemoRoom?.demoRoom as IDemoRoom;

  const handleJoinVideoCallBtnClick = () => {
    window.open(`${demoRoomUrl}`, '_blank', 'noopener noreferrer');
  };

  const handleOpenVRBtnClick = () => {
    if (!(executivesWithDemoRoom?.vrUrl || project.vrUrl)) {
      setShowNoVRUrlModal(true);
      return;
    }
    window.open(
      getVRUrl(
        executivesWithDemoRoom?.vrUrl || project.vrUrl,
        virtualRoomId,
        executiveId
      ),
      '_blank',
      'noopener noreferrer'
    );
  };

  const isClubRhodiumProject = useMemo(() => {
    return project.company.id === CLUB_RHODIUM_COMPANY_ID;
  }, [project]);

  return (
    <>
      <Modal
        show={showNoVRUrlModal}
        onOutsideClick={() => setShowNoVRUrlModal(false)}
        additionalClasses={`${styles.noVideoLinkmodalAdditionalClasses}`}>
        <header>
          <div className={styles.noVideoModalTitleContainer}>
            <h2 className={styles.noVideoModalTitle}>
              'There is no VR Link, Please contact admin'
            </h2>
            <Icon
              name='close'
              propStyles={styles.crossIcon}
              onClick={() => setShowNoVRUrlModal(false)}
            />
          </div>
        </header>
        <main className={styles.noVideoModalBody}>
          <Button
            propStyles={styles.noVideoModalOkayBtn}
            onClick={() => setShowNoVRUrlModal(false)}>
            Okay
          </Button>
        </main>
      </Modal>

      <ScheduledDemoModal
        onClose={handelScheduledDemoModalClose}
        showModal={showScheduledDemoModal}
        onRescheduleBtnClick={HandleRescheduleBtnClick}
      />
      <SendInviteModal
        executiveId={executiveId}
        vrUrl={executivesWithDemoRoom?.vrUrl || project.vrUrl}
        deomRoom={
          project.executivesWithDemoRoom.find(
            (e) => e.executive.id === executiveId
          )?.demoRoom as IDemoRoom
        }
        projectName={project.name}
        projectId={project.id}
        developerName={project.company.displayName}
        onClose={handelSendInviteModalClose}
        showModal={showSendInviteModal}
        onDemoScheduled={handleDemoSuccessfullyScheduled}
      />
      <ScheduledDemoSuccessModal
        onClose={handelScheduledDemoSuccessModalClose}
        showModal={showScheduledDemoSuccessModal}
        onRescheduleBtnClick={HandleRescheduleBtnClick}
      />
      {/* <ExecutiveQrCodeModal
        onClose={() => setShowQrModal(false)}
        showModal={showQrModal}
        executiveId={executiveId}
        projectId={project.id}
      /> */}

      {/* Header Left Side Content */}
      <header className={styles.dashboardHeader}>
        {/* Header Right Side Content */}
        <div className={styles.headerRightContent}>
          {!isClubRhodiumProject && (
            <div
              title='Click on Icon to Copy'
              className={styles.hostPasswordContainer}>
              <p className={styles.hostPassword}>
                VR Host Password :
                <span className={styles.boldText}> {hostPassword}</span>
              </p>
              <Icon
                name='content_copy'
                propStyles={styles.copyIcon}
                onClick={() =>
                  navigator.clipboard.writeText(hostPassword)
                }></Icon>
            </div>
          )}
          <div className={styles.shareDemoBtnConttainer}>
            {!isClubRhodiumProject && demoRoomUrl && (
              <Button
                onClick={handleJoinVideoCallBtnClick}
                propStyles={styles.shareDemoBtn}>
                Join Video Call
              </Button>
            )}
            {!isClubRhodiumProject && (
              <>
                <Button
                  onClick={handleOpenVRBtnClick}
                  propStyles={styles.shareDemoBtn}>
                  Open VSG
                </Button>
                <Button
                  onClick={() => setShowSendInviteModal(true)}
                  propStyles={styles.shareDemoBtn}>
                  Share Demo
                </Button>
              </>
            )}
            {/* TODO: Hide button from Executive */}
            {/* {isClubRhodiumProject &&  (
              <Button
                onClick={() => setShowQrModal(true)}
                propStyles={styles.shareCpReferralBtn}>
                Share Cp Referral
              </Button>
            )} */}
          </div>

          {/* <div className={styles.connectBtnContainer}>
            <div className={styles.upcommingMeetingMessageContainer}>
              <Icon name='schedule' propStyles={styles.clockIcon}/>
              <h3 className={styles.upcommingMeetingMessage}>
                Upcoming Demo in 5 min
              </h3>
            </div>
            <div>
              <Button
                onClick={() => setShowScheduledDemoModal(true)}
                additionalClasses={styles.connetcNowBtn}>
                Connect Now
              </Button>
            </div>
          </div>
          <Icon name='event' propStyles={styles.calenderIcon}/>  onClick={() => setShowSendInviteModal(true)} */}
        </div>
      </header>

      {/* Body Content */}
      <main className={styles.body}>
        <div className={styles.embedMessageContainer}>
          <iframe
            title='d'
            width='100%'
            height='100%'
            frameBorder='0'
            src={
              company?.id === CLUB_RHODIUM_COMPANY_ID
                ? `https://analytics.relata.io/public/dashboard/d4786ed5-5e03-4123-8f4d-de3d50129093?project_id=${project.id}&sm_id=${executiveId}#hide_parameters=project_id%2Cproject_name%2Csm_name%2Ccp_name%2Ccp_firm%2Csm_id`
                : `https://datastudio.google.com/embed/u/0/reporting/240a12af-0566-42d1-8e55-4059d2860dd0/page/6gnnC?params=%7B%22df16%22:%22include%25EE%2580%25800%25EE%2580%2580EQ%25EE%2580%2580${project.id}%22%7D`
            }
            style={{ border: 0 }}
            allowFullScreen></iframe>
        </div>
      </main>
    </>
  );
};

export default ExecutiveDashboard;
