import Icon from 'apps/booking/components/common/Icon';

import styles from './styles.module.css';

interface IPaginationProps {
  totalRowCount: number;
  rowCount: number;
  currentPage: number;
  loaded?: boolean;
  onPageChange: () => void;
  onNextPage: () => void;
  onNavigateTofirstPage: () => void;
  onNavigationToLastPage: () => void;
}

const Pagination = (props: IPaginationProps) => {
  const {
    totalRowCount,
    currentPage,
    loaded,
    onPageChange,
    onNextPage,
    onNavigateTofirstPage,
    onNavigationToLastPage,
  } = props;
  const totalPages = Math.ceil(totalRowCount / 10);

  const handlePreviousChange = (page: number) => {
    if (page >= 0 && page < totalPages) {
      onPageChange();
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages - 1) onNextPage();
  };

  return (
    <div className={styles.navigationWrapper}>
      <Icon
        propStyles={styles.navigationIcon}
        name='first_page'
        onClick={onNavigateTofirstPage}
      />
      <Icon
        propStyles={styles.navigationIcon}
        onClick={() => handlePreviousChange(currentPage - 1)}
        name='navigate_before'
      />

      <span>
        Page <strong>{currentPage + 1}</strong> of{' '}
        <strong>{loaded ? '?' : totalPages}</strong>
      </span>
      <Icon
        onClick={handleNextPage}
        name='navigate_next'
        propStyles={styles.navigationIcon}
      />
      <Icon
        propStyles={styles.navigationIcon}
        name='last_page'
        onClick={onNavigationToLastPage}
      />
    </div>
  );
};

export default Pagination;
