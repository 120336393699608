import { useState } from 'react';
import { useSelector } from 'react-redux';

import DesktopTabs from 'apps/admin/components/common/DesktopTabs';
import { ITab } from 'apps/cp/components/common/Tabs';
import CollaboratorsStatus from 'apps/admin/components/pages/CollaboratorsList/CollaboratorsStatus';
import { getAdminCompany } from 'apps/admin/slices/adminSlice';
import Button from 'apps/booking/components/common/buttons/Button';
import AddNewCollaboratorModal from 'apps/admin/components/pages/CollaboratorsList/AddNewCollaboratorModal';
import { TObjectId } from 'interfaces';
import { useGetCollaboratorsQuery } from 'apps/admin/services/companyAPISlice';

import styles from './styles.module.css';

export interface ICollaborators {
  displayName: string;
  projects?: string[];
  assignedSM: number;
  registeredName?: string;
  pocMobile?: string;
  pocEmail?: string;
  hqAddress?: string;
  gstNumber?: string;
  panNumber?: string;
  websiteUrl?: string;
  id: TObjectId;
}

const CollaboratorsList = () => {
  const company = useSelector(getAdminCompany);

  const [showAddNewCollaboratorModal, setshowAddNewCollaboratorModal] =
    useState(false);

  const { data: collaborators = [], isLoading } = useGetCollaboratorsQuery({
    organisationId: company?.id,
  });

  const handleAddNewCollaboratorModalClose = () => {
    setshowAddNewCollaboratorModal(false);
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  {
    /* 
  const collaboratorsTabs: ITab[] = [
    {
      id: 'active',
      name: 'Active',
      content: <CollaboratorsStatus adminUserList={usersList} />,
      disabled: false,
    },
    {
      id: 'inactive',
      name: 'Inactive',
      content: <CollaboratorsStatus adminUserList={usersList} />,
      disabled: false,
    },
    {
      id: 'archived',
      name: 'Archived',
      content: <CollaboratorsStatus adminUserList={usersList} />,
      // TODO: Add filter for inactive leads
      disabled: false,
    },
  ];
*/
  }
  return (
    <>
      {showAddNewCollaboratorModal && (
        <AddNewCollaboratorModal
          handleModalClose={handleAddNewCollaboratorModalClose}
        />
      )}
      <div className={styles.tabsWrapper}>
        <h2 className={styles.heading}>All Collaborators</h2>

        {/* <div className={styles.buttonContainer}>
            <Button
            accent='primary'
            onClick={() => {
              setshowAddNewCollaboratorModal(true);
            }}
            propStyles={styles.createNewCollaboratorButton}>
            Add Collaborator
          </Button>
        </div>
        <DesktopTabs tabs={collaboratorsTabs} /> */}

        <CollaboratorsStatus collaborators={collaborators} />
      </div>
    </>
  );
};

export default CollaboratorsList;
