import { useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';

import useOnClickOutside from 'utils/useOnClickOutside';
import Icon from 'apps/booking/components/common/Icon';
import { IProject } from 'apps/admin/components/pages/SuperAdminDashboard/SuperAdminProjectList';
import { IconNames } from 'apps/booking/components/common/Icon';
import ProjectEventsModal from 'apps/admin/components/pages/SuperAdminDashboard/SuperAdminProjectList/modals/ProjectEventsModal';

import genericStyles from '../../../../../../../../styles/genericStyles.module.css';
import styles from './styles.module.css';

export interface IMoreActionsMenu {
  project: IProject;
  onProjectUpdate: (updatedProject: IProject) => void;
}

const MoreActionsMenu = (props: IMoreActionsMenu) => {
  const { project, onProjectUpdate } = props;
  const { disabled } = project;

  // TODO: Type actionModal
  const [actionModal, setActionModal] = useState() as any;
  const [showMenu, setShowMenu] = useState(false) as any; //TODO: Remove any

  const menuRef = useRef<HTMLDivElement>(null);
  const history = useHistory();

  //For Closing menu when clicked outside
  useOnClickOutside(menuRef, () => {
    return setShowMenuWrapper(false);
  });

  const setShowMenuWrapper = (showMenu: Boolean) => {
    setShowMenu(showMenu);
  };

  const actionItems = [
    {
      displayText: 'View Users',
      icon: 'person',
      onClick: () => {
        history.push('/admin/projects/executives', { project });
      },
    },
    {
      displayText: 'View Rooms',
      icon: 'fiber_smart_record',
      onClick: () => {
        history.push('/admin/projects/seats', { project });
      },
    },
    {
      displayText: 'Update Project',
      icon: 'fiber_smart_record',
      onClick: () => {
        history.push('/admin/projects/project-update', { project });
      },
    },
    {
      displayText: 'View Project Events',
      icon: 'fiber_smart_record',
      actionModal: (
        <ProjectEventsModal
          project={project}
          onModalClose={() => setActionModal(false)}
        />
      ),
      onClick: () => {
        // TODO: Redirect to events page
        setActionModal(actionItems[3].actionModal);
      },
      showModal: false,
    },
  ];

  return (
    <>
      {/* Menu Options */}
      {actionModal}
      {showMenu && (
        <div
          ref={menuRef}
          className={`${genericStyles.pointer} ${styles.actionsMenuContainer}`}>
          {actionItems.map((actionItem) => (
            <div key={actionItem.displayText}>
              <div
                className={styles.individualMenuItem}
                onClick={actionItem.onClick}>
                <p className={styles.moreActionsIndividualMenuLabel}>
                  {actionItem.displayText}
                </p>
                <Icon
                  name={actionItem.icon as IconNames}
                  propStyles={styles.moreActionsIndividualMenuIcon}
                />
              </div>
            </div>
          ))}
        </div>
      )}
      {/* 3 dots menu toogler Icon in Grid */}
      <div
        className={`${genericStyles.pointer} ${
          styles.moreActionsMenuIconContainer
        } ${disabled ? styles.disabled : ''}`}>
        <Icon
          name='more_vert'
          propStyles={`${styles.moreActionsMenuIcon} ${
            disabled ? 'disabled - icon' : ''
          }`}
          onClick={() => setShowMenuWrapper(!showMenu)}
        />
      </div>
    </>
  );
};

export default MoreActionsMenu;
