import { TObjectId } from 'interfaces';
import { IProject } from 'apps/admin/components/pages/SuperAdminDashboard/SuperAdminProjectList';
import { IProjectEvent } from 'apps/cp/components/pages/CrRegisterProjectEventPage';
import { cpAPISlice } from 'apps/cp/services';
import { ICpOnBoardingReq } from 'apps/cp/components/common/RegistrationConfirmation';

export const cpProjectAPISlice = cpAPISlice.injectEndpoints({
  endpoints: (build) => ({
    getClubRhodiumProjects: build.query<IProject[], any>({
      query: (clubRhodiumCompanyId: string) =>
        `api/projects/club-rhodium/${clubRhodiumCompanyId}`,
      transformResponse: (response: {
        data: IProject[];
        code: String;
        error: Object;
        message: String;
      }) => {
        return response.data;
      },
      // providesTags: (result) =>
      //   result
      //     ? [
      //         ...result.map(({ id }) => ({ type: 'Projects' as const, id })),
      //         { type: 'Projects', id: 'LIST' },
      //       ]
      //     : [{ type: 'Projects', id: 'LIST' }],
    }),

    registerProjectEvent: build.mutation({
      query: ({
        projectEventId,
        cpUserId,
      }: {
        projectEventId: TObjectId;
        cpUserId: TObjectId;
      }) => ({
        url: `api/projects/events/register/${projectEventId}`,
        method: 'POST',
        body: { cpUserId },
      }),
    }),

    getProjectEventByProjectEventId: build.query({
      query: (projectEventId: string) =>
        `api/projects/events/${projectEventId}`,
      transformResponse: (response: {
        data: IProjectEvent;
        code: String;
        error: Object;
        message: String;
      }) => {
        return response.data;
      },
    }),

    updateCollateralShareCountAPI: build.mutation({
      query: ({
        projectId,
        cpUserId,
        collateralId,
      }: {
        projectId: TObjectId;
        cpUserId: TObjectId;
        collateralId: TObjectId;
      }) => ({
        url: `api/analytics/raCollaterals/`,
        method: 'POST',
        body: { projectId, cpUserId, collateralId },
      }),
    }),

    updateLocationShareCountAPI: build.mutation({
      query: ({
        projectId,
        cpUserId,
      }: {
        projectId: TObjectId;
        cpUserId: TObjectId;
      }) => ({
        url: `api/analytics/raCollaterals/location/`,
        method: 'POST',
        body: { projectId, cpUserId },
      }),
    }),

    registerCpOnboarding: build.mutation({
      query: (payload: ICpOnBoardingReq) => ({
        url: 'api/analytics/cponboarding',
        method: 'POST',
        body: payload,
      }),
    }),

    getProjectExecutiveIsValid: build.query({
      query: ({
        projectId,
        executiveId,
      }: {
        projectId: TObjectId;
        executiveId: TObjectId;
      }) => `api/projects/${projectId}/executive-exists/${executiveId}`,
      transformResponse: (response: {
        data: String;
        code: String;
        error: Object;
        message: String;
      }) => {
        return response.data;
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetClubRhodiumProjectsQuery,

  useRegisterProjectEventMutation,
  useGetProjectEventByProjectEventIdQuery,

  useUpdateCollateralShareCountAPIMutation,
  useUpdateLocationShareCountAPIMutation,

  useRegisterCpOnboardingMutation,

  useGetProjectExecutiveIsValidQuery,
} = cpProjectAPISlice;
