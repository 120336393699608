import { useState } from 'react';
import { ICellRendererParams } from 'ag-grid-community';
import Icon from 'apps/booking/components/common/Icon';

const ExpandRowCell = (props: ICellRendererParams) => {
  const { node } = props;

  const [active, setActive] = useState(false);

  const handleToggle = () => {
    if (node.isExpandable()) {
      node.setExpanded(!node.expanded);
      setActive(!active);
    }
  };

  return (
    <div
      className={`custom-cell-container ${active && 'expanded'}`}
      onClick={handleToggle}>
      <Icon name={active ? 'arrow_drop_up' : 'arrow_drop_down'} />
    </div>
  );
};

export default ExpandRowCell;
