import { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import useToast from 'hooks/useToast';
import Button from 'apps/booking/components/common/buttons/Button';
import TextInput from 'apps/booking/components/common/inputs/TextInput/index';
import Icon from 'apps/booking/components/common/Icon';
import { LoginFormStates } from 'apps/admin/components/pages/AdminLogin/LoginForm/index';
import {
  emailValidationSchema,
  mobileValidationSchema,
} from 'utils/yupValidationSchemas';
import {
  ALLOWED_USER_ROLES_ON_ADMIN_PORTAL,
  ALLOWED_USER_ROLES_ON_SITE_VISIT,
} from 'apps/admin/components/containers/AdminSidebar';
import {
  useLoginAdminUserByMobileMutation,
  useLoginAdminUserByEmailMutation,
} from 'apps/admin/services/adminUserAPISlice';
import { useTrackUsersLoginMutation } from 'apps/admin/services/userRolesAPISlice';
import useAdminLogOut from 'apps/admin/components/pages/AdminLogin/hooks/useAdminLogOut';
import { setAppSliceIsFetching } from 'slices/appSlice';

import styles from '../../styles.module.css';

interface ILoginState {
  setLoginFormState: Function;
  handleMobile: Function;
}

const LoginState = (props: ILoginState) => {
  const { setLoginFormState, handleMobile } = props;

  const history = useHistory();

  const [loginCred, setLoginCred] = useState({
    mobile: '',
    email: '',
    password: '',
  });
  const [errorMessage, setErrorMessage] = useState('');
  const [mobileEmailErrorMessage, setMobileEmailErrorMessage] = useState('');
  const [addressType, setAddressType] = useState('');
  const [otpVerified, setOtpVerified] = useState(false);

  const [addToast] = useToast();
  const dispatch = useDispatch();

  const [loginAdminUserByMobileAPI] = useLoginAdminUserByMobileMutation();
  const [loginAdminUserByEmailAPI] = useLoginAdminUserByEmailMutation();
  const [trackUserLoginAPI] = useTrackUsersLoginMutation();
  const { removeAdminUserLocal, logginOut } = useAdminLogOut();

  useEffect(() => {
    dispatch(setAppSliceIsFetching(logginOut));
  }, [logginOut]);

  const handelMobileInput = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value;
    let isValid = false;

    const mobileInput = value.match(/^[0-9]*$/);
    if (mobileInput) {
      setAddressType('MOBILE');
      setLoginCred((prev) => {
        return { ...prev, mobile: value };
      });
      isValid = await mobileValidationSchema.isValid({
        value: event.target.value,
      });
    } else {
      setAddressType('EMAIL');
      setLoginCred((prev) => {
        return { ...prev, email: value };
      });

      isValid = await emailValidationSchema.isValid({
        value: event.target.value,
      });
    }

    if (isValid && errorMessage) {
      // Email/Mobile is valid, hide error message
      setMobileEmailErrorMessage('');
    }
  };
  const handelMobileBlur = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    // turned this to async function as yup validation return result as a promise which we have to consume to validate email
    switch (addressType) {
      case 'EMAIL':
        const isValidEmail = await emailValidationSchema.isValid({
          value: event.target.value,
        });
        setMobileEmailErrorMessage(
          !isValidEmail ? 'Please enter a valid Email' : ''
        );
        break;

      case 'MOBILE':
        const isValidMobile = await mobileValidationSchema.isValid({
          value: event.target.value,
        });
        setMobileEmailErrorMessage(
          !isValidMobile ? 'Please enter a valid Phone Number' : ''
        );
    }
  };

  const handelPasswordInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const password = event.target.value;
    setLoginCred((prev) => {
      return { ...prev, password };
    });
  };

  const urlParams = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop: string) => searchParams.get(prop),
  }) as any;

  const handleLogin = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      let response;
      setOtpVerified(true);

      switch (addressType) {
        case 'EMAIL':
          response = await loginAdminUserByEmailAPI({
            email: loginCred.email,
            password: loginCred.password,
          }).unwrap();
          break;

        case 'MOBILE':
          response = await loginAdminUserByMobileAPI({
            mobile: loginCred.mobile,
            password: loginCred.password,
          }).unwrap();
      }
      await trackUserLoginAPI({
        userId: response.data?.id,
        eventName: 'LOGIN',
      });

      if (!ALLOWED_USER_ROLES_ON_ADMIN_PORTAL.includes(response.data?.role)) {
        addToast({
          type: 'ERROR',
          primaryMessage: `Please login with either of the '${ALLOWED_USER_ROLES_ON_ADMIN_PORTAL.join(
            ', '
          )}' user roles`,
          secondaryMessage: '',
          timeout: 3000,
        });
        await removeAdminUserLocal();
        return;
      }

      const { redirectTo } = urlParams;
      if (
        redirectTo &&
        !ALLOWED_USER_ROLES_ON_SITE_VISIT.includes(response.data?.jobTitle)
      ) {
        addToast({
          type: 'ERROR',
          primaryMessage: `Please login with either of the '${ALLOWED_USER_ROLES_ON_SITE_VISIT.join(
            ', '
          )}' user roles`,
          secondaryMessage: '',
          timeout: 3000,
        });
        await removeAdminUserLocal();
        return;
      }
      history.push(redirectTo == null ? '/admin' : redirectTo);
    } catch (err) {
      const errMsg = (err as any).data?.message;
      addToast({
        type: 'ERROR',
        primaryMessage: errMsg,
        secondaryMessage: '',
        timeout: 1000,
      });
      setErrorMessage(errMsg);
    } finally {
      setOtpVerified(false);
    }
  };

  const buttonDisabled = useMemo(() => {
    return !((loginCred.mobile || loginCred.email) && loginCred.password);
  }, [loginCred.mobile, loginCred.password]);

  return (
    <>
      <h2 className={styles.loginFormHeading}>
        Manage your Project like Never Before!
      </h2>
      {errorMessage ? (
        <div className={styles.accessRevokedMessage}>
          <Icon name='error_outline' propStyles={styles.errorIcon} />
          <div>{errorMessage}</div>
        </div>
      ) : (
        <p className={styles.loginFormDesc}>
          Welcome to the executive login page. To start accessing your leads &
          more, Login now!
        </p>
      )}
      <form onSubmit={handleLogin}>
        <TextInput
          label='Enter Email/Mobile Number'
          placeHolder='Enter Email/Mobile Number'
          errorMessage={mobileEmailErrorMessage}
          onChange={handelMobileInput}
          onBlur={handelMobileBlur}
          type='text'
          maxLength={(addressType === 'MOBILE' && 10) || undefined}
        />
        <TextInput
          label='Password'
          placeHolder='Enter your Password'
          onChange={handelPasswordInput}
          type='password'
        />
        <Button
          propStyles={styles.loginButton}
          type='submit'
          disabled={buttonDisabled || otpVerified}
          loading={otpVerified}>
          Login Now
        </Button>
        <Button
          propStyles={styles.forgotPasswordButton}
          onClick={() => {
            handleMobile(loginCred.mobile);
            setLoginFormState(LoginFormStates.SEND_OTP);
          }}>
          Forgot Password
        </Button>
      </form>
    </>
  );
};

export default LoginState;
