import { useState, useRef } from 'react';
import useOnClickOutside from '../../../utils/useOnClickOutside';

import Icon from './common/Icon';

const getOptionText = (options, value) => {
  const option = options.find((option) => option.value === value);
  return option?.selectedText || option?.text || 'Select an option';
};

export const DropDown = (props) => {
  const {
    cname,
    defaultValue = '',
    options = [],
    handleDropDownSelect = () => {},
  } = props;

  const [showDropDown, setShowDropDown] = useState(false);
  const defaultText = getOptionText(options, defaultValue);

  const ref = useRef();
  useOnClickOutside(ref, () => setShowDropDown(false));

  return (
    <div
      ref={ref}
      className={`dropdown ${cname}`}
      onClick={() => setShowDropDown(!showDropDown)}>
      <span className='dropdown__default-text'>{defaultText}</span>
      <Icon name='arrow_drop_down' />

      <ul className={`dropdown-list ${!showDropDown && 'hide'}`}>
        {options.map((option) => (
          <li
            key={`key-${option.value}-${option.text}`}
            className='dropdown-content__item'
            onClick={() => handleDropDownSelect(option.value)}>
            {option.text}
          </li>
        ))}
      </ul>
    </div>
  );
};
