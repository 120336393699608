export enum UserRole {
  SUPERADMIN = 'SUPERADMIN',
  ADMIN = 'ADMIN',
  EXECUTIVE = 'EXECUTIVE',
  CP = 'CP',
  LEAD = 'LEAD',
  TEAM_LEAD = 'TEAMLEAD',
  SITE_HEAD = 'SITEHEAD',
  CLUSTER_HEAD = 'CLUSTERHEAD',
  BUSINESS_HEAD = 'BUSINESSHEAD',
  GRE = 'GRE',
}
