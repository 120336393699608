import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import Icon from 'apps/booking/components/common/Icon';
import Modal from 'apps/booking/components/common/modal/Modal';
import TextInput from 'apps/booking/components/common/inputs/TextInput';
import Button from 'apps/booking/components/common/buttons/Button';
import { ICompany } from 'apps/admin/interfaces';
import { useUpdateCompanyMutation } from 'apps/admin/services/companyAPISlice';
import useToast from 'hooks/useToast';

import styles from './styles.module.css';

interface IUpdateCompanyModal {
  onModalClose: Function;
  company: ICompany;
}

// Validation
const schema = yup.object().shape({
  displayName: yup.string().required(),
  name: yup.string().required(),
  gstNumber: yup.string().required(),
  websiteUrl: yup.string().url('valid url error').required(),
  hqAddress: yup.string().required(),
  pocEmail: yup.string().email().required(),
  pocMobile: yup
    .string()
    .matches(/^[0-9]*$/)
    .length(10)
    .required('Mobile number is required'),
});

const UpdateCompanyModal = (props: IUpdateCompanyModal) => {
  const { onModalClose, company } = props;

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });

  const [updateCompanyAPI] = useUpdateCompanyMutation();
  const [addToast] = useToast();

  const handleUpdateOrganizationDetails = async (updatedCompany: ICompany) => {
    try {
      await updateCompanyAPI({ id: company.id, ...updatedCompany }).unwrap();

      addToast({
        type: 'SUCCESS',
        primaryMessage: 'Successfully Updated Company',
      });

      onModalClose();
    } catch (err) {
      const errMsg = (err as any).data.message;
      addToast({
        type: 'ERROR',
        primaryMessage: errMsg,
        secondaryMessage: 'Failed to Update Company',
      });
    }
  };

  return (
    <>
      <Modal
        additionalClasses={styles.addCompany}
        onOutsideClick={onModalClose}>
        <header>
          <div className={styles.headerIconAndHeadingContainer}>
            <h2 className={styles.modalHeading}>Update Company Details</h2>
            <Icon
              name='close'
              propStyles={styles.closeIcon}
              onClick={() => onModalClose()}></Icon>
          </div>
          <p className={styles.modalDesc}>
            Edit the following info to Update company Details.
          </p>
        </header>

        <form
          action='submit'
          onSubmit={handleSubmit(handleUpdateOrganizationDetails)}>
          <main>
            <TextInput
              placeHolder='Add Display Name'
              label='Company Display Name'
              autoFocus={true}
              defaultValue={company.displayName}
              register={register}
              setValue={setValue}
              name='displayName'
              errorMessage={
                errors.displayName ? 'Display name is required' : ''
              }
            />
            <div className={styles.sideBySideTextInputsContainer}>
              <div className={styles.smallTextInputContainer}>
                <TextInput
                  placeHolder='Add Company Name'
                  label='Company Name'
                  defaultValue={company.name}
                  register={register}
                  setValue={setValue}
                  name='name'
                  errorMessage={errors.name ? 'Company name is required' : ''}
                />
              </div>
              <div className={styles.smallTextInputContainer}>
                <TextInput
                  placeHolder='Add GST Number'
                  label='GST Number'
                  defaultValue={company.gstNumber}
                  register={register}
                  setValue={setValue}
                  name='gstNumber'
                  errorMessage={
                    errors.gstNumber ? 'GST number is required' : ''
                  }
                />
              </div>
            </div>
            <div className={styles.sideBySideTextInputsContainer}>
              <div className={styles.smallTextInputContainer}>
                <TextInput
                  placeHolder='Add Website URL'
                  label='Add Website URL'
                  defaultValue={company.websiteUrl}
                  register={register}
                  setValue={setValue}
                  name='websiteUrl'
                  errorMessage={
                    errors.websiteUrl ? 'Valid Website URL is required' : ''
                  }
                />
              </div>
              <div className={styles.smallTextInputContainer}>
                <TextInput
                  placeHolder='Add Office Address'
                  label='Registered HQ Address'
                  defaultValue={company.hqAddress}
                  register={register}
                  setValue={setValue}
                  name='hqAddress'
                  errorMessage={
                    errors.hqAddress ? 'Office address is required' : ''
                  }
                />
              </div>
            </div>
            <div className={styles.sideBySideTextInputsContainer}>
              <div className={styles.smallTextInputContainer}>
                <TextInput
                  placeHolder='POC Email ID'
                  label='Point of Contact Email'
                  defaultValue={company.pocEmail}
                  register={register}
                  setValue={setValue}
                  name='pocEmail'
                  errorMessage={
                    errors.pocEmail ? 'Valid Email id is required' : ''
                  }
                />
              </div>
              <div className={styles.smallTextInputContainer}>
                <TextInput
                  placeHolder='POC Mobile Number'
                  label='Point of Contact Mobile'
                  defaultValue={company.pocMobile}
                  register={register}
                  setValue={setValue}
                  name='pocMobile'
                  maxLength={10}
                  errorMessage={
                    errors.pocMobile ? 'Mobile number is required' : ''
                  }
                />
              </div>
            </div>
          </main>

          <footer className={styles.footer}>
            <div className={styles.createOrganizationBtn}>
              <Button accent='primary' type='submit'>
                Update
              </Button>
            </div>
          </footer>
        </form>
      </Modal>
    </>
  );
};

export default UpdateCompanyModal;
