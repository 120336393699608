import { useState } from 'react';

import Icon from '../../../../../../../../booking/components/common/Icon';
import { IProjectEvent } from '..';
import ProjectEventUpdateModal from '../../ProjectEventUpdateModal';

interface IProjectEventDetailsCellProps {
  projectEvent: IProjectEvent;
  onProjectEventUpdate: (updatedProject: IProjectEvent) => void;
}

const ProjectEventUpdateCell = (props: IProjectEventDetailsCellProps) => {
  const { projectEvent, onProjectEventUpdate } = props;

  const [projectEventUpdateModalShown, setProjectEventUpdateModalShown] =
    useState(false);

  return (
    <>
      {projectEventUpdateModalShown && (
        <ProjectEventUpdateModal
          onModalClose={() => setProjectEventUpdateModalShown(false)}
          projectEvent={projectEvent}
          onProjectEventUpdate={onProjectEventUpdate}
        />
      )}
      <Icon name='edit' onClick={() => setProjectEventUpdateModalShown(true)} />
    </>
  );
};

export default ProjectEventUpdateCell;
