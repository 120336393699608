import { useState } from 'react';

import {
  getYoutubeVideoId,
  isUrlTypeMatch,
} from 'apps/cp/components/pages/MarketingCollateralPage/MarketingCollateralCardList/PreviewModal';
import ImageZoomViewer from 'apps/cp/components/pages/MarketingCollateralPage/MarketingCollateralCardList/PreviewModal/ImageZoomViewer';
import PdfViewer from 'apps/cp/components/pages/MarketingCollateralPage/MarketingCollateralCardList/PreviewModal/PdfViewer';

import styles from './styles.module.css';

interface ICollateralProps {
  collateralUrl: string;
  fullWidth?: boolean;
}
const CollateralView = (props: ICollateralProps) => {
  const { collateralUrl, fullWidth } = props;
  const [iframeLoading, setIframeLoading] = useState(true);

  return (
    <div className={`${styles.contentWrap} ${fullWidth && styles.fullScreen}`}>
      {isUrlTypeMatch(collateralUrl, 'pdf') && (
        <PdfViewer previewUrl={collateralUrl} fullWidth />
      )}
      {isUrlTypeMatch(collateralUrl, 'video') && (
        <div className={styles.videoWrapper}>
          <video width='100%' controls>
            <source src={collateralUrl} type='video/mp4' />
          </video>
        </div>
      )}
      {isUrlTypeMatch(collateralUrl, 'image') && (
        <ImageZoomViewer previewUrl={collateralUrl} />
      )}
      {isUrlTypeMatch(collateralUrl, 'youtube') && (
        <>
          <iframe
            className={iframeLoading ? 'loading-spin-container' : ''}
            width='100%'
            height='211'
            src={`https://www.youtube.com/embed/${getYoutubeVideoId(
              collateralUrl
            )}`}
            frameBorder='0'
            allowFullScreen
            title='Embedded youtube'
            onLoad={() => setIframeLoading(false)}
          />
          {iframeLoading && <span className='loading-spin'></span>}
        </>
      )}
    </div>
  );
};

export default CollateralView;
