// TODO: convert to typescript
import S3 from 'react-aws-s3';

import { s3BucketName } from 'constants/urls';

window.Buffer = window.Buffer || require('buffer').Buffer;

const REGION = 'ap-south-1';
const ACCESS_KEY_ID = 'AKIARTCQAJHCLIWTRR2M';
const SECRET_ACCESS_KEY = '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';

class S3ClientNew {
  static fileName(url) {
    return url?.substring(url?.lastIndexOf('/') + 1);
  }

  static uploadFile(file, fileName, s3DirName, bucketName = s3BucketName) {
    const config = {
      bucketName,
      dirName: s3DirName,
      // TODO: Fetch secrete key from env
      region: REGION,
      accessKeyId: ACCESS_KEY_ID,
      secretAccessKey: SECRET_ACCESS_KEY,
    };
    const ReactS3Client = new S3(config);
    return ReactS3Client.uploadFile(file, fileName);
  }
}

export default S3ClientNew;
