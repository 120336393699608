import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import BookedUnitCartCard from './BookedUnitCartCard';
import SummaryCard from './SummaryCard';

import { getUser } from '../../slices/auth';
import { useLazyGetUnitcartListQuery } from '../../../../api/unitCart';
import { useSiteVisitsByUserIdOldMutation } from '../../../../api/siteVisit';

import SiteVisitItem from './SiteVisitItem';
import ScheduleSiteVisitItem from './ScheduleSiteVisitItem';
import {
  BLOCKED,
  BOOKED,
  UNIT_STATUSES,
  UNIT_STATUSES_INDEX,
} from '../../../../constants/status';
import { dashboardHeadingBannerUrl, URLS } from '../../../../constants/urls';
import { SIDEBAR_ITEM_INDEX } from '../SidebarItem';
import { getProject } from 'apps/booking/slices/projectSlice';
import { isEmpty } from 'utils/utils';
import { setBookingRoutesCurrentPage } from 'apps/booking/routers/BookingRoutes/slice';

const Dashbaord = () => {
  const [siteVisits, setSiteVisits] = useState([]);

  const user = useSelector(getUser);
  const project = useSelector(getProject);
  const dispatch = useDispatch();
  const [getUnitcartListAPI, { data: unitCarts = [] }] =
    useLazyGetUnitcartListQuery();
  const [siteVisitsByUserIdAPI] = useSiteVisitsByUserIdOldMutation(user.id);

  useEffect(() => {
    const getUnitCarts = async () => {
      getUnitcartListAPI({ userId: user.id, projectId: project.id });
    };
    if (!isEmpty(user) && !isEmpty(project)) {
      getUnitCarts();
    }
    const getSiteVisites = async () => {
      const { data: siteVisits } = await siteVisitsByUserIdAPI(user.id);
      setSiteVisits(siteVisits || []);
      // TODO: Handle error
    };
    getSiteVisites();
  }, [user, project, getUnitcartListAPI, siteVisitsByUserIdAPI]);

  useEffect(() => {
    dispatch(setBookingRoutesCurrentPage(URLS.UNIT_SELECTION));
  }, []);

  const getAboveOrEqualBlockedUnitCartListByStatus = () => {
    return useMemo(() => {
      return unitCarts
        .filter(
          (unitCart) =>
            UNIT_STATUSES_INDEX[unitCart.unit.status] >=
            UNIT_STATUSES_INDEX[UNIT_STATUSES.BLOCKED]
        )
        .slice()
        .sort(
          (firstEl, secondEl) =>
            new Date(secondEl.updatedAt) - new Date(firstEl.updatedAt)
        );
    }, [unitCarts]);
  };

  const getExploredUnitCartListByStatus = () => {
    return useMemo(() => {
      return unitCarts
        .filter(
          (unitCart) =>
            UNIT_STATUSES_INDEX[unitCart.unit.status] <=
            UNIT_STATUSES_INDEX[UNIT_STATUSES.RESERVED]
        )
        .slice()
        .sort(
          (firstEl, secondEl) =>
            new Date(secondEl.updatedAt) - new Date(firstEl.updatedAt)
        );
    }, [unitCarts]);
  };

  const aboveOrEqualBlockedUnitCarts =
    getAboveOrEqualBlockedUnitCartListByStatus();
  const exploredUnitCarts = getExploredUnitCartListByStatus();

  return (
    <div className='dashboard'>
      <header className='dashboard-header'>
        <div className='dashboard__welcome-title'>
          Welcome to your <span className='font-accent-bold'>Dashboard</span>
        </div>
        <div className='dashboard__heading'>
          <div className='dashboard__heading-label'>
            <div className='dashboard__heading-title'>
              <span className='f-bold c-56'>Be A part</span>{' '}
              <span className='f-medium'>of Bengaluru's biggest and</span>{' '}
              <span className='font-accent-bold'>best planned City</span>
            </div>
            <div className='dashboard__heading-desc'>
              360 Realtors Springs by owning India's First Alexa Enabled Homes -
              360 Realtors Edge @ 360 Realtors Springs
            </div>
          </div>
          <figure className='dashboard__heading-banner'>
            <img
              src={dashboardHeadingBannerUrl}
              alt='Dashboard heading banner'
            />
          </figure>
        </div>
      </header>

      {aboveOrEqualBlockedUnitCarts.length > 0 && (
        <section className='dashboard-section'>
          <header className='dashboard-section__heading'>
            <div className='dashboard-section__heading-title'>Your Homes</div>
            <div className='dashboard-section__heading-desc'>
              Track your blocked, booked and other status
            </div>
          </header>
          <main className='dashboard-section__body'>
            {aboveOrEqualBlockedUnitCarts.map((unitCart) => (
              <BookedUnitCartCard unitCart={unitCart} />
            ))}
          </main>
        </section>
      )}
      {/* end -- dashboard-section*/}

      {exploredUnitCarts.length > 0 && (
        <section className='dashboard-section'>
          <header className='dashboard-section__heading'>
            <div className='dashboard-section__heading-title'>Summary</div>
            <div className='dashboard-section__heading-desc'>
              Track your reserved, explored and other status
            </div>
          </header>
          <main className='dashboard-section__body'>
            {exploredUnitCarts.map((unitCart) => (
              <SummaryCard unitCart={unitCart} />
            ))}
          </main>
        </section>
      )}

      {false && (
        <section className='dashboard-section'>
          <header className='dashboard-section__heading'>
            <div className='dashboard-section__heading-title'>
              Scheduled Calls & Site Visits
            </div>
            <div className='dashboard-section__heading-desc'>
              Track your booking and other status
            </div>
          </header>
          <main className='dashboard-section__body dashboard-section__body-disable-horizontal-scroll'>
            {siteVisits
              .slice()
              .sort((firstEl, secondEl) => {
                const firstElTime = new Date(firstEl.datetime).getTime();
                const secondElTime = new Date(secondEl.datetime).getTime();
                return firstElTime < secondElTime ? -1 : 1;
              })
              .map((siteVisit) => (
                <SiteVisitItem siteVisit={siteVisit} />
              ))}
            <ScheduleSiteVisitItem />
          </main>
        </section>
      )}
    </div>
  );
};

export default Dashbaord;
