import Icon from 'apps/booking/components/common/Icon';
import Modal from 'apps/booking/components/common/modal/Modal';
import Button from 'apps/booking/components/common/buttons/Button';
import { TObjectId } from 'interfaces';
import { useGetExecutiveQrCodeQuery } from 'apps/admin/services/qrCodeAPISlice';
import SpinLoader from 'apps/booking/components/common/SpinLoader';

import styles from './styles.module.css';

export interface IQrCodeResponse {
  qrCodeUrl: string;
  referralLink: string;
  executive: string;
  project: string;
  id: string;
}
interface IExecutiveQrCodeModalProps {
  executiveId: TObjectId;
  projectId: TObjectId;
  showModal: Boolean;
  onClose: () => void;
}

const ExecutiveQrCodeModal = (props: IExecutiveQrCodeModalProps) => {
  const { executiveId, projectId, showModal, onClose } = props;

  const { data: qrCodeDetails, isLoading } = useGetExecutiveQrCodeQuery({
    executiveId,
    projectId,
  });

  const handelCopyReferralLink = () => {
    navigator.clipboard.writeText(qrCodeDetails?.referralLink || '');
  };

  const handelCopyQrCodeLink = () => {
    navigator.clipboard.writeText(qrCodeDetails?.qrCodeUrl || '');
  };

  return (
    <Modal
      show={showModal}
      onOutsideClick={onClose}
      additionalClasses={`${styles.modalAdditionalClasses}`}>
      {/*Modal Header*/}
      <header>
        <div className={styles.modalHeader}>
          <h2 className={styles.modalHeading}>QR Code</h2>
          <Icon name='close' propStyles={styles.crossIcon} onClick={onClose} />
        </div>
        <p className={styles.modalDesc}>
          You can Share/Copy QR Code link from here
        </p>
      </header>
      {/*Modal Body*/}
      <div className={styles.bodyContainer}>
        {isLoading ? (
          <SpinLoader />
        ) : (
          <>
            <p className={styles.bodyMessage}>
              <span className={styles.messageBoldText}>CP referral Link:</span>{' '}
              {qrCodeDetails?.referralLink}
            </p>
            <p className={styles.bodyMessage}>
              <span className={styles.messageBoldText}>
                Find your QR Code here:
              </span>{' '}
              {qrCodeDetails?.qrCodeUrl}
            </p>
          </>
        )}
      </div>
      <div className={styles.buttonWrapper}>
        <Button
          propStyles={styles.copyToClipboardBtn}
          onClick={handelCopyReferralLink}>
          Copy Referral Link
        </Button>
        <Button
          propStyles={styles.copyToClipboardBtn}
          onClick={handelCopyQrCodeLink}>
          Copy QR Code Link
        </Button>
      </div>
    </Modal>
  );
};

export default ExecutiveQrCodeModal;
