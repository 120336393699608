import { Viewer, Worker } from '@react-pdf-viewer/core';
import {
  RenderCurrentScaleProps,
  RenderZoomInProps,
  RenderZoomOutProps,
  zoomPlugin,
} from '@react-pdf-viewer/zoom';

import Icon from 'apps/booking/components/common/Icon';

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/zoom/lib/styles/index.css';
import styles from './styles.module.css';

interface IPdfViewerProps {
  previewUrl: string;
  fullWidth?: boolean;
}

const PdfViewer = (props: IPdfViewerProps) => {
  const { previewUrl, fullWidth } = props;

  const zoomPluginInstance = zoomPlugin();
  const { CurrentScale, ZoomIn, ZoomOut } = zoomPluginInstance;

  return (
    <div className={styles.pdfWrapper}>
      <div className={styles.viwerHeader}>
        <div className={styles.buttonWrapper}>
          <ZoomOut>
            {(props: RenderZoomOutProps) => (
              <Icon
                onClick={props.onClick}
                name='remove'
                propStyles={styles.zoomIcon}
              />
            )}
          </ZoomOut>
        </div>
        <div className={styles.zoomPercent}>
          <CurrentScale>
            {(props: RenderCurrentScaleProps) => (
              <>{`${Math.round(props.scale * 50)}%`}</>
            )}
          </CurrentScale>
        </div>
        <div className={styles.buttonWrapper}>
          <ZoomIn>
            {(props: RenderZoomInProps) => (
              <Icon
                onClick={props.onClick}
                name='add'
                propStyles={styles.zoomIcon}
              />
            )}
          </ZoomIn>
        </div>
      </div>
      <div className={`${styles.viewerWrap} ${fullWidth && styles.fullHeight}`}>
        <div className={styles.contentWrapper}>
          <Worker workerUrl='https://unpkg.com/pdfjs-dist@3.1.81/build/pdf.worker.min.js'>
            <Viewer fileUrl={previewUrl} plugins={[zoomPluginInstance]} />
          </Worker>
        </div>
      </div>
    </div>
  );
};

export default PdfViewer;
