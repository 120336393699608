import { adminAPISlice } from 'apps/admin/services/index';
import { IUnit, TObjectId } from 'interfaces';
import { ICpLead, IProjectLead, IProjectUnitLead } from 'apps/cp/interfaces';
import { IBookLeadReq } from 'apps/admin/components/pages/ProjectPartnerLeads/modals/BookingFormModal';

export const projectLeadsAPI = adminAPISlice.injectEndpoints({
  endpoints: (build) => ({
    getProjectLeadsByAdminUserId: build.query<IProjectLead[], any>({
      query: (adminUserId: TObjectId) => `api/projectLeads/${adminUserId}`,
      transformResponse: (response: {
        data: IProjectLead[];
        code: String;
        error: Object;
        message: String;
      }) => {
        return response.data;
      },
      // providesTags: (result) =>
      //   result
      //     ? [
      //         ...result.map(({ id }) => ({ type: 'Projects' as const, id })),
      //         { type: 'Projects', id: 'LIST' },
      //       ]
      //     : [{ type: 'Projects', id: 'LIST' }],
    }),

    getProjectCPLeadsByProjectId: build.query<ICpLead[], any>({
      query: (projectId: TObjectId) =>
        `api/users/cp/cp-lead/project/${projectId}`,
      transformResponse: (response: {
        data: ICpLead[];
        code: String;
        error: Object;
        message: String;
      }) => {
        return response.data;
      },
    }),

    getProjectSiteVisitLeadsByProjectId: build.query<ICpLead[], any>({
      query: (projectId: TObjectId) => `api/projectLeads/project/${projectId}`,
      transformResponse: (response: {
        data: ICpLead[];
        code: String;
        error: Object;
        message: String;
      }) => {
        return response.data;
      },
    }),

    getProjectVSGLeadsByProjectId: build.query<ICpLead[], any>({
      query: (projectId: TObjectId) => `api/projects/${projectId}/leads`,
      transformResponse: (response: {
        data: ICpLead[];
        code: String;
        error: Object;
        message: String;
      }) => {
        return response.data;
      },
    }),

    getProjectPartnerActiveLeads: build.mutation({
      query: ({
        projectId,
        pageNumber,
        pageCount,
        executiveId,
        searchField = '',
        searchName = '',
        startDate = '',
        endDate = '',
      }: {
        projectId: TObjectId;
        pageNumber: number;
        pageCount: number;
        executiveId: TObjectId;
        searchField?: string;
        searchName?: string;
        startDate?: string;
        endDate?: string;
      }) => ({
        url: `api/projects/${projectId}/active-leads?executiveId=${executiveId}&searchField=${searchField}&searchName=${searchName}&pageCount=${pageCount}&pageNumber=${pageNumber}&startDate=${startDate}&endDate=${endDate}`,
        method: 'GET',
      }),
    }),
    getProjectPartnerActiveLeadsCount: build.query<number, any>({
      query: ({ projectId }: { projectId: TObjectId }) =>
        `api/projects/${projectId}/active-leads/count`,
      transformResponse: (response: {
        data: number;
        code: String;
        error: Object;
        message: String;
      }) => {
        return response.data;
      },
    }),
    getProjectPartnerActiveLeadsCountForSm: build.query<number, any>({
      query: ({
        projectId,
        executiveId,
      }: {
        projectId: TObjectId;
        executiveId: TObjectId;
      }) => `api/projects/${projectId}/active-leads/${executiveId}/count`,
      transformResponse: (response: {
        data: number;
        code: String;
        error: Object;
        message: String;
      }) => {
        return response.data;
      },
    }),

    getProjectPartnerSiteVisitsByProjectId: build.mutation({
      query: ({
        pageCount,
        pageNumber,
        executiveId,
        searchField = '',
        searchName = '',
        projectId,
        startDate = '',
        endDate = '',
        status,
      }: {
        projectId: TObjectId;
        pageNumber: number;
        pageCount: number;
        executiveId: TObjectId;
        searchField?: string;
        searchName?: string;
        startDate?: string;
        endDate?: string;
        status: string;
      }) => ({
        url: `api/cpLeadSiteVisit/project/${projectId}/${status}?executiveId=${executiveId}&pageCount=${pageCount}&pageNumber=${pageNumber}&searchField=${searchField}&searchName=${searchName}&startDate=${startDate}&endDate=${endDate}`,
        method: 'GET',
      }),
    }),
    getProjectPartnerSiteVisitsCount: build.query<number, any>({
      query: ({
        projectId,
        status,
        executiveId,
      }: {
        projectId: TObjectId;
        status: string;
        executiveId: TObjectId;
      }) =>
        `api/cpLeadSiteVisit/project/${projectId}/${status}/count?executiveId=${executiveId}`,
      transformResponse: (response: {
        data: number;
        code: String;
        error: Object;
        message: String;
      }) => {
        return response.data;
      },
    }),

    getProjectDirectSiteVisitsByProjectId: build.mutation({
      query: ({
        pageCount,
        pageNumber,
        searchField = '',
        searchName = '',
        projectId,
        startDate = '',
        endDate = '',
      }: {
        projectId: TObjectId;
        pageNumber: number;
        pageCount: number;
        searchField?: string;
        searchName?: string;
        startDate?: string;
        endDate?: string;
      }) => ({
        url: `api/cpLeadSiteVisit/directSiteVisit/${projectId}?pageCount=${pageCount}&pageNumber=${pageNumber}&searchField=${searchField}&searchName=${searchName}&startDate=${startDate}&endDate=${endDate}`,
        method: 'GET',
      }),
    }),
    getProjectDirectSiteVisitsCount: build.query<number, any>({
      query: ({
        projectId,
      }: {
        projectId: TObjectId;
        status: string;
        executiveId: TObjectId;
      }) => `api/cpLeadSiteVisit/directSiteVisit/${projectId}/count`,
      transformResponse: (response: {
        data: number;
        code: String;
        error: Object;
        message: String;
      }) => {
        return response.data;
      },
    }),

    getProjectPartnerRegisteredLeadsCount: build.query<number, any>({
      query: ({ projectId }: { projectId: TObjectId }) =>
        `api/projects/${projectId}/register-leads/count`,
      transformResponse: (response: {
        data: number;
        code: String;
        error: Object;
        message: String;
      }) => {
        return response.data;
      },
    }),
    getProjectPartnerRegisteredLeadsCountForSm: build.query<number, any>({
      query: ({
        projectId,
        executiveId,
      }: {
        projectId: TObjectId;
        executiveId: TObjectId;
      }) => `api/projects/${projectId}/register-leads/${executiveId}/count`,
      transformResponse: (response: {
        data: number;
        code: String;
        error: Object;
        message: String;
      }) => {
        return response.data;
      },
    }),
    getProjectPartnerRegisteredLeads: build.mutation({
      query: ({
        projectId,
        executiveId,
        pageNumber,
        pageCount,
        searchField = '',
        searchName = '',
        startDate = '',
        endDate = '',
      }: {
        projectId: TObjectId;
        executiveId: TObjectId;
        pageNumber: number;
        pageCount: number;
        searchField?: string;
        searchName?: string;
        startDate?: string;
        endDate?: string;
      }) => ({
        url: `api/projects/${projectId}/register-leads?executiveId=${executiveId}&searchField=${searchField}&searchName=${searchName}&pageCount=${pageCount}&pageNumber=${pageNumber}&startDate=${startDate}&endDate=${endDate}`,
        method: 'GET',
      }),
    }),

    getProjectPartnerBookedLeads: build.mutation({
      query: ({
        projectId,
        pageNumber,
        pageCount,
        executiveId,
        searchField = '',
        searchName = '',
        startDate = '',
        endDate = '',
      }: {
        projectId: TObjectId;
        pageNumber: number;
        pageCount: number;
        executiveId: TObjectId;
        searchField?: string;
        searchName?: string;
        startDate?: string;
        endDate?: string;
      }) => ({
        url: `api/projects/${projectId}/booked-leads?executiveId=${executiveId}&searchField=${searchField}&searchName=${searchName}&pageCount=${pageCount}&pageNumber=${pageNumber}&startDate=${startDate}&endDate=${endDate}`,
        method: 'GET',
      }),
    }),
    getProjectPartnerBookedLeadsCount: build.query<number, any>({
      query: ({ projectId }: { projectId: TObjectId }) =>
        `api/projects/${projectId}/booked-leads/count`,
      transformResponse: (response: {
        data: number;
        code: String;
        error: Object;
        message: String;
      }) => {
        return response.data;
      },
    }),
    getProjectPartnerBookedLeadsCountForSm: build.query<number, any>({
      query: ({
        projectId,
        executiveId,
      }: {
        projectId: TObjectId;
        executiveId: TObjectId;
      }) => `api/projects/${projectId}/booked-leads/${executiveId}/count`,
      transformResponse: (response: {
        data: number;
        code: String;
        error: Object;
        message: String;
      }) => {
        return response.data;
      },
    }),

    getProjectInventoryByProjectId: build.query<IUnit[], any>({
      query: (projectId: TObjectId) => `api/units/get-all/project/${projectId}`,
      transformResponse: (response: {
        data: IUnit[];
        code: String;
        error: Object;
        message: String;
      }) => {
        return response.data;
      },
    }),

    updateActivetoBookedLeads: build.mutation({
      query: ({
        requestPayload,
        projectId,
      }: {
        requestPayload: IBookLeadReq;
        projectId: TObjectId;
      }) => ({
        url: `api/projects/${projectId}/book-lead`,
        method: 'POST',
        body: requestPayload,
      }),
    }),

    updateSmtoUnAssignedPartner: build.mutation({
      query: ({ projectId, smId, cpFirmId, cpUserId }) => ({
        url: `api/projects/${projectId}/sm/${smId}/cpFirm/${cpFirmId}/tagging?cpUserId=${cpUserId}`,
        method: 'POST',
      }),
    }),

    getProjectLeadUnitsByProjectId: build.query<IProjectUnitLead[], any>({
      query: (projectId: TObjectId) =>
        `api/units/unitCart/project/${projectId}`,
      transformResponse: (response: {
        data: any[];
        code: String;
        error: Object;
        message: String;
      }) => {
        return response.data;
      },
    }),

    updateBookedToRegistrationDone: build.mutation({
      query: ({
        requestPayload,
        projectId,
      }: {
        requestPayload: IBookLeadReq;
        projectId: TObjectId;
      }) => ({
        url: `api/projects/${projectId}/registration-done`,
        method: 'POST',
        body: requestPayload,
      }),
    }),

    getBookedToRegisteredLeads: build.mutation({
      query: ({
        projectId,
        pageNumber,
        pageCount,
        executiveId,
        searchField = '',
        searchName = '',
        startDate = '',
        endDate = '',
      }: {
        projectId: TObjectId;
        pageNumber: number;
        pageCount: number;
        executiveId: TObjectId;
        searchField?: string;
        searchName?: string;
        startDate?: string;
        endDate?: string;
      }) => ({
        url: `api/projects/${projectId}/registrations-done?executiveId=${executiveId}&searchField=${searchField}&searchName=${searchName}&pageCount=${pageCount}&pageNumber=${pageNumber}&startDate=${startDate}&endDate=${endDate}`,
        method: 'GET',
      }),
    }),
    getbookedToRegisteredLeadCount: build.query<number, any>({
      query: ({ projectId }: { projectId: TObjectId }) =>
        `api/projects/${projectId}/registrations-done/count`,
      transformResponse: (response: {
        data: number;
        code: String;
        error: Object;
        message: String;
      }) => {
        return response.data;
      },
    }),
    getbookedToRegisteredLeadCountForSm: build.query<number, any>({
      query: ({
        projectId,
        executiveId,
      }: {
        projectId: TObjectId;
        executiveId: TObjectId;
      }) => `api/projects/${projectId}/registrations-done/${executiveId}/count`,
      transformResponse: (response: {
        data: number;
        code: String;
        error: Object;
        message: String;
      }) => {
        return response.data;
      },
    }),
  }),

  overrideExisting: false,
});

export const {
  useGetProjectLeadsByAdminUserIdQuery,
  useGetProjectCPLeadsByProjectIdQuery,
  useGetProjectSiteVisitLeadsByProjectIdQuery,
  useGetProjectVSGLeadsByProjectIdQuery,

  useGetProjectPartnerActiveLeadsCountQuery,
  useGetProjectPartnerActiveLeadsCountForSmQuery,
  useGetProjectPartnerActiveLeadsMutation,

  useGetProjectPartnerSiteVisitsByProjectIdMutation,
  useGetProjectPartnerSiteVisitsCountQuery,

  useGetProjectDirectSiteVisitsByProjectIdMutation,
  useGetProjectDirectSiteVisitsCountQuery,

  useGetProjectPartnerRegisteredLeadsCountQuery,
  useGetProjectPartnerRegisteredLeadsCountForSmQuery,
  useGetProjectPartnerRegisteredLeadsMutation,

  useGetProjectPartnerBookedLeadsMutation,
  useGetProjectPartnerBookedLeadsCountQuery,
  useGetProjectPartnerBookedLeadsCountForSmQuery,

  useGetProjectInventoryByProjectIdQuery,

  useUpdateActivetoBookedLeadsMutation,
  useUpdateSmtoUnAssignedPartnerMutation,
  useGetProjectLeadUnitsByProjectIdQuery,

  useUpdateBookedToRegistrationDoneMutation,

  useGetBookedToRegisteredLeadsMutation,
  useGetbookedToRegisteredLeadCountQuery,
  useGetbookedToRegisteredLeadCountForSmQuery,
} = projectLeadsAPI;
