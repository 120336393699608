import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';

import {
  ICollateral,
  TCollateralType,
} from 'apps/admin/components/pages/SuperAdminDashboard/SuperAdminProjectList';
import DropDownInput from 'apps/booking/components/common/inputs/DropDownInput';
import FileUploadNew from 'apps/booking/components/common/inputs/FileUploadNew';
import TextInput from 'apps/booking/components/common/inputs/TextInput';
import DisplayInputs from 'apps/booking/components/common/inputs/DisplayInputs';
import Button from 'apps/booking/components/common/buttons/Button';
import { CLUB_RHODIUM_BASE_URL } from 'constants/urls';

import genericStyles from '../../../../../../../../../../styles/genericStyles.module.css';
import styles from './styles.module.css';

interface ICollateralsInputProps {
  projectId: string;
  updatedCollaterals: ICollateral[];
  collateralTitles: string[];
  toogleFileUploadStatus: (fileId: string, isUploadInProgress: boolean) => void;
  onAddUpdateCollateral: (data: ICollateral, index: number) => void;
  onRemoveCollateral: (collateralTitle: string) => void;
}

const CollateralsInput = (props: ICollateralsInputProps) => {
  const {
    projectId,
    updatedCollaterals,
    collateralTitles,
    toogleFileUploadStatus,
    onAddUpdateCollateral,
    onRemoveCollateral,
  } = props;

  const [title, setTitle] = useState('');
  const [docUrlToCopy, setDocUrlToCopy] = useState('');
  const [editCollateralIndex, setEditCollateralIndex] = useState(-1);

  const { handleSubmit, setValue, register, unregister } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });

  const options = [
    { value: 'BROCHURE', displayText: 'Brochure' },
    { value: 'COST_SHEET', displayText: 'Cost Sheet' },
    { value: 'PRICE_SHEET', displayText: 'Price Sheet' },
    { value: 'OPPORTUNITY_DOCUMENT', displayText: 'Opportunity Document' },
    { value: 'AV', displayText: 'AV' },
    { value: 'MOVIE', displayText: 'Movie' },
    { value: 'FLOOR_PLANS', displayText: 'Floor Plans' },
    { value: 'UNIT_PLANS', displayText: 'Unit Plans' },
    { value: 'PHOTOS', displayText: 'Photos' },
  ];

  const editModeOn = useMemo(() => {
    return editCollateralIndex != -1;
  }, [editCollateralIndex]);

  const getCollateralTypeIndex = (collateralType: TCollateralType) => {
    return options.find((option) => option.value == collateralType);
  };

  const baseUrl = window.location.origin;

  useEffect(() => {
    if (editModeOn) {
      return setTitle(collateralTitles[editCollateralIndex]);
    }
    setTitle('');
  }, [editCollateralIndex, updatedCollaterals]);

  const handleAdd = (data: ICollateral) => {
    onAddUpdateCollateral(data, editCollateralIndex);
    setEditCollateralIndex(-1);
    setTitle('');
  };

  const handleTitleInputOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(e.target.value);
  };

  const handleFileUpload = (url: string) => {
    setDocUrlToCopy(url);
  };

  const handleCopyUrl = () => {
    const pathname = new URL(docUrlToCopy).pathname;
    navigator.clipboard.writeText(
      `${baseUrl}/viewer?fileUrl=https://d2tnc1k8s7ro6o.cloudfront.net${pathname}`
    );
  };

  const s3CollDir = useMemo(() => {
    if (editModeOn) {
      return `${projectId}${editCollateralIndex}`;
    }
    return `${projectId}${updatedCollaterals.length}`;
  }, [editModeOn, projectId, updatedCollaterals, editCollateralIndex]);

  return (
    <>
      <div className={styles.bodyIndividualdRow}>
        <div className={styles.inputsContainer}>
          <DropDownInput
            lable='Collateral Type'
            options={options}
            defaultSelectedOption={
              editModeOn
                ? getCollateralTypeIndex(
                    updatedCollaterals[editCollateralIndex].type
                  )
                : options[0]
            }
            name='type'
            showSelectedOptionDisplayText
            setValue={setValue}
          />
        </div>

        <div className={styles.inputsContainer}>
          <TextInput
            label='Collateral Title'
            placeHolder='add Title'
            name='title'
            setValue={setValue}
            register={register}
            unregister={unregister}
            onChange={handleTitleInputOnChange}
            defaultValue={
              editModeOn ? updatedCollaterals[editCollateralIndex].title : ''
            }
          />
        </div>
      </div>

      <div className={styles.bodyIndividualdRow}>
        <div className={styles.inputsContainer}>
          <TextInput
            label='Collateral Subtitle'
            placeHolder='add Subtitle'
            name='subTitle'
            setValue={setValue}
            register={register}
            unregister={unregister}
            defaultValue={
              editModeOn ? updatedCollaterals[editCollateralIndex].subTitle : ''
            }
          />
        </div>

        <div className={styles.inputsContainer}>
          <FileUploadNew
            label='Collateral Thumbnail'
            placeholder='Upload Image'
            s3DirPath={`projects/${projectId}/collaterals/${s3CollDir}/thumbnail`}
            onChangeFileUploadStatus={toogleFileUploadStatus}
            appType={'RELATA'}
            name='thumbnailUrl'
            setValue={setValue}
          />
        </div>
      </div>
      <div className={styles.bodyIndividualdRow}>
        <TextInput
          label='Collateral Text'
          placeHolder='add Text'
          name='text'
          setValue={setValue}
          register={register}
          unregister={unregister}
          defaultValue={
            editModeOn ? updatedCollaterals[editCollateralIndex].text : ''
          }
        />
        <span className={styles.previewWrap}>
          <TextInput
            label='Preview Url'
            placeHolder='Add Preview Url'
            name='mobileUrl'
            setValue={setValue}
            register={register}
            unregister={unregister}
            defaultValue={
              editModeOn
                ? updatedCollaterals[editCollateralIndex].mobileUrl
                : ''
            }
          />
        </span>
      </div>
      <div className={styles.bodyIndividualdRow}>
        <div className={styles.textContainer}>
          <TextInput
            label='Collateral Url'
            placeHolder='Add Url'
            name='url'
            setValue={setValue}
            register={register}
            unregister={unregister}
            defaultValue={
              editModeOn ? updatedCollaterals[editCollateralIndex].url : ''
            }
          />
        </div>
        <div className={styles.fileUploadContainer}>
          <FileUploadNew
            label='File Upload'
            placeholder='Upload Files'
            s3DirPath={`projects/${projectId}/collaterals/${s3CollDir}`}
            appType={'RELATA'}
            onFileUpload={handleFileUpload}
          />

          <span
            className={`${genericStyles.pointer} ${genericStyles.onTextHoverAnimation}`}>
            <Button
              accent='ghost'
              onClick={handleCopyUrl}
              disabled={!docUrlToCopy}
              propStyles={styles.copyUrl}>
              Copy Url
            </Button>
          </span>
          <Button
            onClick={handleSubmit(handleAdd)}
            disabled={!title}
            propStyles={styles.addBtn}>
            Add
          </Button>
        </div>
      </div>
      <div className={styles.bodyIndividualdRow}>
        <DisplayInputs
          displayInputs={collateralTitles}
          onRemove={onRemoveCollateral}
          setIndex={setEditCollateralIndex}
        />
      </div>
    </>
  );
};

export default CollateralsInput;
