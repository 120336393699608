import { useState } from 'react';

import { getAdminUserLocal } from 'apps/admin/components/pages/AdminLogin/utils';
import { useTrackUsersLoginMutation } from 'apps/admin/services/userRolesAPISlice';
import { adminUserLocalStorageItemName } from '../utils';

const useAdminLogOut = () => {
  const [trackUserLoginAPI] = useTrackUsersLoginMutation();
  const [logginOut, setLogginOut] = useState(false);

  const removeAdminUserLocal = async () => {
    try {
      setLogginOut(true);
      await trackUserLoginAPI({
        userId: getAdminUserLocal().id,
        eventName: 'LOGOUT',
      }).unwrap();
    } catch (err) {
    } finally {
      setLogginOut(false);
      localStorage.removeItem(adminUserLocalStorageItemName);
    }
  };

  return {
    logginOut,
    removeAdminUserLocal,
  };
};

export default useAdminLogOut;
