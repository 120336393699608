import { useState } from 'react';

import 'ag-grid-enterprise';

import { ICellRendererParams, GridReadyEvent } from 'ag-grid-community';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import DateCell from 'apps/admin/components/common/dates/DateCell';
import { ICpUser } from 'apps/cp/interfaces';
import NameCard from 'apps/booking/components/common/admin/NameCard';
import { awsBaseUrl } from 'constants/urls';
import ExpandRowCell from 'apps/admin/components/pages/ActiveLeads/cell-renderers/ExpandRowCell';
import ExpandedCpDetailsSection from 'apps/admin/components/pages/ActiveCpListAndPendingCpApproval/ActiveCpListAndPendingCpApprovalBody/ExpandedCpDetailsSection';
import RemoveCpButtonCell from 'apps/admin/components/pages/ActiveCpListAndPendingCpApproval/ActiveCpListAndPendingCpApprovalBody/ActiveCpListGrid/RemoveCpButtonCell';
import UpdateCpUserCell, {
  IUpdateCpUserCellProps,
} from 'apps/admin/components/pages/ActiveCpListAndPendingCpApproval/ActiveCpListAndPendingCpApprovalBody/ActiveCpListGrid/UpdateCpUserCell';

import styles from './styles.module.css';

interface IActiveCpListGrid {
  cpUsers: ICpUser[];
  searchTerm: string;
}

const ActiveCpListGrid = (props: IActiveCpListGrid) => {
  const { cpUsers, searchTerm } = props;

  const [gridApi, setGridApi] = useState(null) as any; // We used type "any" at all other places previously as there is no type specified in the documentation

  const onGridReady = (params: GridReadyEvent) => {
    setGridApi(params.api);
  };

  const handleGridSearch = (searchTerm: string) => {
    gridApi?.setQuickFilter(searchTerm);
  };

  handleGridSearch(searchTerm);

  const getCpImageUrl = (params: ICellRendererParams) => {
    return `${awsBaseUrl}/common/AZAlphabets/${params.data.user.name[0].toUpperCase()}${
      +params.data.user.mobile % 5
    }.png`;
  };

  return (
    <div
      className={`ag-theme-alpine ${styles.gridContainer}`}
      style={{ width: '100%', height: '100%' }}>
      <AgGridReact
        rowData={cpUsers}
        defaultColDef={{
          sortable: false,
          filter: false,
          resizable: false,
          suppressMenu: true,
        }}
        domLayout={'autoHeight'}
        pagination={true}
        paginationPageSize={10}
        masterDetail={true}
        animateRows={true}
        detailCellRenderer={'detailCellRenderer'}
        detailRowAutoHeight={true}
        frameworkComponents={{
          detailCellRenderer: ExpandedCpDetailsSection,
        }}
        onGridReady={onGridReady}>
        <AgGridColumn flex={0.8} hide={true} field='cpName'></AgGridColumn>
        <AgGridColumn
          flex={0.8}
          cellClass='ag-grid-column-custom-cell'
          cellRendererFramework={ExpandRowCell}
          cellRendererParams={(params: ICellRendererParams) =>
            params
          }></AgGridColumn>
        <AgGridColumn
          flex={2.5}
          headerName='Name'
          field='user.name'
          cellRendererFramework={(params: ICellRendererParams) => (
            <div className={styles.projectsColumnDataContainer}>
              <NameCard
                {...{
                  imgUrl: `${getCpImageUrl(params)}`,
                  title: params.data.user.name,
                  subtitle: params.data.user.email,
                }}
              />
            </div>
          )}></AgGridColumn>
        <AgGridColumn
          flex={2}
          headerName='Contact Number'
          cellRendererFramework={(params: ICellRendererParams) => (
            <p className={styles.cpName}>{params.data.user.mobile}</p>
          )}></AgGridColumn>
        <AgGridColumn
          flex={2}
          headerName='Project Name'
          cellRendererFramework={(params: ICellRendererParams) => (
            <p className={styles.cpName}>{params.data.projectName}</p>
          )}></AgGridColumn>
        <AgGridColumn
          flex={2}
          headerName='Company Name'
          cellRendererFramework={(params: ICellRendererParams) => (
            <p className={styles.cpName}>
              {params.data.companyTemp?.name || '-'}
            </p>
          )}></AgGridColumn>
        <AgGridColumn
          flex={4}
          headerName='Registration Date'
          cellRendererFramework={DateCell}
          cellRendererParams={(params: ICellRendererParams) => {
            return {
              date: params.data.createdAt,
            };
          }}></AgGridColumn>
        <AgGridColumn
          flex={1}
          headerName='Edit'
          cellRendererFramework={UpdateCpUserCell}
          cellRendererParams={(
            params: ICellRendererParams
          ): IUpdateCpUserCellProps => ({
            cpUser: params.data as ICpUser,
            rowNode: params.node,
            disabled: false,
          })}
        />
      </AgGridReact>
    </div>
  );
};

export default ActiveCpListGrid;
