import { useState } from 'react';
import { useForm } from 'react-hook-form';

import Button from 'apps/booking/components/common/buttons/Button';
import TextInput from 'apps/booking/components/common/inputs/TextInput';
import useToast from 'hooks/useToast';
import { useUpdateProjectDetailsMutation } from 'apps/admin/services/projectsAPISlice';
import { IProject } from 'apps/admin/components/pages/SuperAdminDashboard/SuperAdminProjectList';

import styles from './styles.module.css';

interface IProjectUpdatePasswordProps {
  project: IProject;
}

const ProjectUpdatePassword = (props: IProjectUpdatePasswordProps) => {
  const { project } = props;

  const [updateDisabled, setUpdateDisabled] = useState(false);

  const {
    register,
    unregister,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });

  const [addToast] = useToast();
  const [updateProjectDetails] = useUpdateProjectDetailsMutation();

  const isValidPassword = (password: string) => {
    const regex = /^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z\d]{6,}$/;
    return regex.test(password);
  };

  const handleUpdateProjectDetails = async (vsgPasswordData: {
    password: string;
    reenteredpassword: string;
  }) => {
    if (vsgPasswordData.password !== vsgPasswordData.reenteredpassword) {
      addToast({
        type: 'ERROR',
        primaryMessage: 'Passwords do not match',
        secondaryMessage: 'Password update failed!',
      });
      return;
    }
    if (!isValidPassword(vsgPasswordData.password)) {
      addToast({
        type: 'ERROR',
        primaryMessage:
          'Minimum password size is 6, and must contain number and charachter!',
        secondaryMessage: 'Password update failed!',
      });
      return;
    }
    const vsgDetailsUpdatedData = {
      vsg: {
        password: vsgPasswordData.password,
      },
    };
    try {
      await updateProjectDetails({
        ...vsgDetailsUpdatedData,
        projectId: project.id,
      }).unwrap();
      addToast({
        type: 'SUCCESS',
        primaryMessage: 'Password successfully Updated',
      });
    } catch (err) {
      const errMsg = (err as any).data.message;
      addToast({
        type: 'ERROR',
        primaryMessage: errMsg,
        secondaryMessage: 'Password update failed',
      });
    }
  };

  return (
    <>
      <header className={styles.modalHeader}>
        <div className={styles.modalHeaderLeftContent}>
          <h1>Update VSG Password!</h1>
          <p>Fill the following information to update VSG Password</p>
        </div>
      </header>
      <main>
        <div className={styles.bodyIndividualdRow}>
          <div className={styles.inputsContainer}>
            <TextInput
              label='Enter New Password'
              placeHolder='New Password'
              defaultValue={''}
              register={register}
              setValue={setValue}
              unregister={unregister}
              name='password'
              errorMessage={errors.vsg?.password}
              type='password'
            />
          </div>
          <div className={styles.inputsContainer}>
            <TextInput
              label='Re-Enter New Password'
              placeHolder='Re-Enter New Password'
              defaultValue={''}
              register={register}
              setValue={setValue}
              unregister={unregister}
              name='reenteredpassword'
              errorMessage={errors.vsg?.password}
              type='password'
            />
          </div>
        </div>
        <div className={styles.formDividerLineContainer}></div>
      </main>
      <footer className={styles.modalFooter}>
        <div className={styles.createProjectBtnContainer}>
          <Button
            type='submit'
            propStyles={`${styles.createProjectBtn}`}
            disabled={updateDisabled}
            onClick={() => {
              handleSubmit(handleUpdateProjectDetails)();
            }}>
            Update Details
          </Button>
        </div>
      </footer>
    </>
  );
};

export default ProjectUpdatePassword;
