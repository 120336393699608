import { ICellRendererParams } from 'ag-grid-community';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';

import { ILeadUnitCart } from 'apps/cp/interfaces';
import { DEFAULT_NULL_VALUE } from 'apps/admin/components/common/ExpandedDetailSection';
import { dateToDaythMonthYear } from 'utils/dateFormator';
import UserCkycDetailsCell from 'apps/admin/components/pages/AdminDashboard/AdminExecutiveList/Body/UserListGrid/UserCkycDetailsCell';
import { getProgressByValue } from 'apps/booking/components/SidebarItem';
import UserPaymentCell from 'apps/admin/components/pages/AdminDashboard/AdminExecutiveList/Body/UserListGrid/UserPaymentCell';

import styles from './styles.module.css';

interface IExpandedUnitDetailsSection {
  unitCarts: ILeadUnitCart[];
  updateLeadList?: Function;
}

const ExpandedUnitDetailsSection = (props: IExpandedUnitDetailsSection) => {
  const { unitCarts, updateLeadList } = props;

  return (
    <>
      <div
        className={`ag-theme-alpine ${styles.gridContainer}`}
        style={{ width: '100%', height: '100%' }}>
        <AgGridReact
          rowData={unitCarts}
          defaultColDef={{
            sortable: false,
            filter: false,
            resizable: false,
            suppressMenu: true,
          }}
          domLayout={'autoHeight'}
          masterDetail={true}
          animateRows={true}
          detailCellRenderer={'detailCellRenderer'}
          detailRowAutoHeight={true}>
          <AgGridColumn
            flex={1.5}
            headerName='Unit details'
            cellRendererFramework={(params: ICellRendererParams) => (
              <p
                className={
                  styles.cpName
                }>{`${params.data.unit?.towerName}, ${params.data.unit?.number}`}</p>
            )}
          />
          <AgGridColumn
            flex={1.2}
            headerName='Unit Config'
            cellRendererFramework={(params: ICellRendererParams) => (
              <p className={styles.cpName}>{params.data.unit?.type}</p>
            )}
          />
          <AgGridColumn
            flex={1.5}
            headerName='Status of Query'
            cellRendererFramework={(params: ICellRendererParams) => (
              <p className={styles.cpName}>
                {getProgressByValue(params.data.progress) || DEFAULT_NULL_VALUE}
              </p>
            )}
          />
          <AgGridColumn
            flex={2}
            headerName='Last Updated on'
            cellRendererFramework={(params: ICellRendererParams) => (
              <p className={styles.cpName}>
                {params.data.updatedAt
                  ? dateToDaythMonthYear(params.data.updatedAt)
                  : DEFAULT_NULL_VALUE}
              </p>
            )}
          />
          <AgGridColumn
            flex={2}
            headerName='Actions'
            cellRendererFramework={UserPaymentCell}
            cellRendererParams={(params: ICellRendererParams) => ({
              unitCart: params.data,
              updateList: updateLeadList,
            })}
          />
          <AgGridColumn
            flex={0.8}
            headerName='cKYC'
            cellRendererFramework={UserCkycDetailsCell}
            cellRendererParams={(params: ICellRendererParams) => ({
              applicants: params.data?.applicants,
            })}
          />
        </AgGridReact>
      </div>
    </>
  );
};

export default ExpandedUnitDetailsSection;
