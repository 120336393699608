import { useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import MasterBtn from './MasterBtn';
import TowerBtn from './TowerBtn';
import SectionBtn from './SectionBtn';
import FilterBtn from './FilterBtn';
import BlockBtn from './BlockBtn';
import FilterPreferences from './FilterPreferences';
import FilterPreferencesSummery from './FilterPreferencesSummery';
import ContentHeaderTitle from './ContentHeaderTitle';

import { NOT_FOUND } from '../../../../../constants/status';

import {
  updateUnitSelectionNavMenu,
  clearFilterPreferences,
  getSelectedTowerAvailableUnitCount,
  getSelectedSectionAvailableUnitCount,
  getSelectedBlockAvailableUnitCount,
  getUnitListById,
} from 'apps/booking/slices/unitSelectionHeader/unitSelectionHeaderNav';
import { getLanguageText } from 'constants/multiLanguageTexts';
import { getUserLanguage } from 'apps/booking/slices/userlanguage';

const UnitSelectionContentHeader = () => {
  const dispatch = useDispatch();

  const unitListById = useSelector(getUnitListById);
  const userLanguage = useSelector(getUserLanguage);

  const selectedTowerAvaialableUnitCount = useSelector(
    getSelectedTowerAvailableUnitCount
  );
  const selectedSectionAvaialableUnitCount = useSelector(
    getSelectedSectionAvailableUnitCount
  );
  const selectedBlockAvaialableUnitCount = useSelector(
    getSelectedBlockAvailableUnitCount
  );
  const [showFilterPreference, setFilterPreference] = useState(false);
  const unitSelectionContentHeaderRef = useRef();
  const setFilterPreferenceWrap = (show) => {
    if (!show) {
      dispatch(updateUnitSelectionNavMenu());
    }
    setFilterPreference(show);
  };
  const handleClearFilter = () => {
    dispatch(clearFilterPreferences());
    dispatch(updateUnitSelectionNavMenu());
  };

  const isBlockApplicable = () => {
    return !Object.values(unitListById).every(
      (unit) => unit.blockName === 'NA' || !unit.blockName
    );
  };

  const isSectionApplicable = () => {
    return !Object.values(unitListById).every(
      (unit) => unit.towerName == unit.applicableFloorplan
    );
  };

  return (
    <div ref={unitSelectionContentHeaderRef}>
      <header className='content-header'>
        <ContentHeaderTitle showFilterPreference={showFilterPreference} />
        <div
          className='content-header__nav'
          onClick={() =>
            showFilterPreference && setFilterPreferenceWrap(false)
          }>
          <nav className='nav'>
            <ul className='list nav__list'>
              <li className='nav__item'>
                <MasterBtn />
              </li>
              <li className='nav__item'>
                <span className='icon material-icons'></span>
              </li>
              <li className='nav__item'>
                <TowerBtn />
                {selectedTowerAvaialableUnitCount !== NOT_FOUND && (
                  <small className='nav__item-desc'>
                    <span className='f-bold'>
                      {selectedTowerAvaialableUnitCount === 0
                        ? getLanguageText(userLanguage, 'no')
                        : selectedTowerAvaialableUnitCount}
                    </span>{' '}
                    {getLanguageText(userLanguage, 'units')}{' '}
                    {getLanguageText(userLanguage, 'available')}
                  </small>
                )}
              </li>
              <li className='nav__item'>
                <span className='icon material-icons'></span>
              </li>
              {isSectionApplicable() && (
                <li className='nav__item'>
                  <SectionBtn />
                  {selectedTowerAvaialableUnitCount !== NOT_FOUND &&
                    selectedSectionAvaialableUnitCount !== NOT_FOUND && (
                      <small className='nav__item-desc'>
                        <span className='f-bold'>
                          {selectedSectionAvaialableUnitCount === 0
                            ? getLanguageText(userLanguage, 'no')
                            : selectedSectionAvaialableUnitCount}
                        </span>{' '}
                        {getLanguageText(userLanguage, 'units')}{' '}
                        {getLanguageText(userLanguage, 'available')}
                      </small>
                    )}
                </li>
              )}
              {isBlockApplicable() && (
                <li className='nav__item'>
                  <BlockBtn />
                  {selectedBlockAvaialableUnitCount !== NOT_FOUND && (
                    <small className='nav__item-desc'>
                      <span className='f-bold'>
                        {selectedBlockAvaialableUnitCount === 0
                          ? getLanguageText(userLanguage, 'no')
                          : selectedBlockAvaialableUnitCount}
                      </span>{' '}
                      {getLanguageText(userLanguage, 'units')}{' '}
                      {getLanguageText(userLanguage, 'available')}
                    </small>
                  )}
                </li>
              )}
              {/*TODO: disabling temporarily
              <li className='nav__item nav__vbar'></li>
              <li className='nav__item '>
                <FilterBtn
                  showFilterPreference={showFilterPreference}
                  toggleFilterPreferenceContainer={(val) =>
                    setFilterPreferenceWrap(val)
                  }
                  handleClearFilter={handleClearFilter}
                  unitSelectionContentHeaderRef={unitSelectionContentHeaderRef}
                />
              </li>
              */}
            </ul>
          </nav>
        </div>
        {!showFilterPreference && (
          <FilterPreferencesSummery
            toggleFilterPreferenceContainer={() =>
              setFilterPreferenceWrap(!showFilterPreference)
            }
          />
        )}
      </header>

      {showFilterPreference && <FilterPreferences />}
    </div>
  );
};

export default UnitSelectionContentHeader;
