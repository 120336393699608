import { createSlice } from '@reduxjs/toolkit';

export const projectSlice = createSlice({
  name: 'projectSlice',
  initialState: {
    project: {},
    userEOIs: [],
  },
  reducers: {
    setProject: (state, action) => {
      state.project = action.payload;
    },
    setUserEOIs: (state, action) => {
      state.userEOIs = action.payload;
    },
  },
});

export const { setProject, setUserEOIs } = projectSlice.actions;
export const getProject = (state) => state.projectSlice.project;
export const getUserEOIs = (state) => state.projectSlice.userEOIs;
