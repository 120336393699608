import { useState, useEffect } from 'react';

import { downloadImage } from 'utils/downloadImage';
import Image from 'apps/booking/components/common/Image';
import { floorPlanFallback, s3BucketName } from 'constants/urls';
import { PROJECTS_WITH_COMMON_UNIT_PLANS } from 'constants/projectCommons';

const UnitFloorPlanPhoto = (props) => {
  const { configUnitDetails } = props;

  const [show2DPlan, setShow2DPlan] = useState(true);

  const getplanPhotoUrls = (dimension) => {
    let s3Url = `https://${s3BucketName}.s3.ap-south-1.amazonaws.com/projects/${configUnitDetails.project.id}/floorPlans/`;
    if (
      PROJECTS_WITH_COMMON_UNIT_PLANS.includes(configUnitDetails.project.id)
    ) {
      s3Url += `common-floor/common-unit/${dimension}/common-floor.common-unit.${dimension}`;
      return s3Url;
    }
    s3Url +=
      `${configUnitDetails.applicableFloorplan.toLowerCase()}/` +
      `${configUnitDetails.number.slice(-2)}/` +
      `${dimension}/` +
      `${configUnitDetails.applicableFloorplan.toLowerCase()}.` +
      `${configUnitDetails.number.slice(-2)}.` +
      `${dimension}`;
    return s3Url;
  };

  const planPhotoUrls = {
    '2D': getplanPhotoUrls('2d'),
    '3D': getplanPhotoUrls('3d'),
  };

  const [is2DImagePresent, setIs2DImagePresent] = useState(false);
  const [is3DImagePresent, setIs3DImagePresent] = useState(false);

  const handleCheckUrl = async (dimension) => {
    try {
      const response = await fetch(planPhotoUrls[dimension], {
        method: 'HEAD',
      });
      if (response.ok) {
        if (dimension == '2D') {
          setIs2DImagePresent(true);
        }
        if (dimension == '3D') {
          setIs3DImagePresent(true);
        }
      } else {
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    handleCheckUrl('2D');
    handleCheckUrl('3D');
  }, []);

  return (
    <div className='unit-details-2d-3d-container'>
      <Image
        src={show2DPlan ? planPhotoUrls['2D'] : planPhotoUrls['3D']}
        alt='Unit 2D plan'
        fallbackSrc={floorPlanFallback}
      />
      <div className='unit-details-2d-3d-container__action-btns'>
        {(is2DImagePresent || is3DImagePresent) && (
          <span
            className='icon-container click-enabled'
            onClick={() =>
              downloadImage(
                show2DPlan ? planPhotoUrls['2D'] : planPhotoUrls['3D'],
                show2DPlan ? 'unit-2D-plan.jpg' : 'unit-3D-plan.jpg'
              )
            }>
            <span className='unit-details-2d-3d-container__action-btn icon material-icons'>
              
            </span>
          </span>
        )}
        {is2DImagePresent && (
          <span
            className={`icon-container ${!show2DPlan && 'click-enabled'} ${
              show2DPlan && 'click-disabled'
            }`}
            onClick={() => setShow2DPlan(true)}>
            <span className='unit-details-2d-3d-container__action-btn'>2D</span>
          </span>
        )}
        {is3DImagePresent && (
          <span
            className={`icon-container ${show2DPlan && 'click-enabled'} ${
              !show2DPlan && 'click-disabled'
            }`}
            onClick={() => setShow2DPlan(false)}>
            <span className='unit-details-2d-3d-container__action-btn'>3D</span>
          </span>
        )}
      </div>
    </div>
  );
};

export default UnitFloorPlanPhoto;
