import { adminAPISlice } from 'apps/admin/services/index';
import { TObjectId } from 'interfaces';
import { IApplicant } from '../interfaces';

export const applicantsAPISlice = adminAPISlice.injectEndpoints({
  endpoints: (build) => ({
    getApplicantsByUnitAndLeadId: build.query<IApplicant[], any>({
      query: (params: { unit: TObjectId; lead: TObjectId }) =>
        `api/users/applicant/unit/${params.unit}/lead/${params.lead}`,
      transformResponse: (response: {
        data: IApplicant[];
        code: String;
        error: Object;
        message: String;
      }) => {
        return response.data;
      },
    }),
    addOrUpdateApplicant: build.mutation({
      query: ({ applicant }: { applicant: IApplicant }) => ({
        url: `api/users/applicant/addOrUpdate`,
        method: 'POST',
        body: {
          ...applicant,
        },
      }),
    }),
    deleteApplicant: build.mutation({
      query: (applicantId: TObjectId) => ({
        url: `api/users/applicant/${applicantId}`,
        method: 'DELETE',
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetApplicantsByUnitAndLeadIdQuery,
  useAddOrUpdateApplicantMutation,
  useDeleteApplicantMutation,
} = applicantsAPISlice;
