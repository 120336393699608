import { useState } from 'react';

import Icon from 'apps/booking/components/common/Icon';
import BookedUnitDetailsModal from 'apps/admin/components/pages/ProjectPartnerLeads/modals/BookedUnitDetailsModal';

interface IUnitDetailsCellProps {
  unitNumber: string;
  unitConfiguration: string;
}
const UnitDetailsCell = (props: IUnitDetailsCellProps) => {
  const { unitNumber, unitConfiguration } = props;

  const [unitDetailsModalShown, setUnitModalShown] = useState(false);

  return (
    <>
      {unitDetailsModalShown && (
        <BookedUnitDetailsModal
          onModalClose={() => setUnitModalShown(false)}
          unitConfiguration={unitConfiguration}
          unitNumber={unitNumber}
        />
      )}
      <Icon
        name='details'
        propStyles='actionIcon'
        onClick={() => setUnitModalShown(true)}
      />
    </>
  );
};

export default UnitDetailsCell;
