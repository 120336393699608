import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import Icon from 'apps/booking/components/common/Icon';
import Modal from 'apps/booking/components/common/modal/Modal';
import HeadTitle from 'apps/booking/components/common/TitleHeading';
import Button from 'apps/booking/components/common/buttons/Button';
import TextInput from 'apps/booking/components/common/inputs/TextInput';
import { ICpLead, ICpUser } from 'apps/cp/interfaces';
import { useAddSiteVisitByCpUserMutation } from 'apps/cp/services/cpSiteVisitAPISlice';
import { ICpSiteVisit } from 'apps/cp/interfaces';
import { getCpUser, setCpUser } from 'apps/cp/slices/cpUserSlice';
import useToast from 'hooks/useToast';
import { TObjectId } from 'interfaces';
import { SCHEDULED_SITE_VISIT_STATUS } from 'apps/cp/components/pages/CrSiteVisitPage';
import { setAppSliceIsFetching } from 'slices/appSlice';
import { useGetCpUserByMobileMutation } from 'apps/cp/services/cpUserAPISlice';
import { getMobileFromCpLead } from 'apps/cp/components/pages/CrLeadListPage/CpLeadItem';
import { isInValidDate } from 'utils/utils';

import styles from './styles.module.css';

interface ISheduleSiteVisitModelProps {
  projectId: TObjectId;
  projectName: string;
  cpLead: ICpLead;
  onSetCpLeadRes?: (cpLeadRes: ICpLead) => void;
  onClosePopup: () => void;
}

const schema = yup.object().shape({
  visitDate: yup.string().required(),
});

const SheduleSiteVisitModel = (props: ISheduleSiteVisitModelProps) => {
  const { cpLead, projectName, projectId, onClosePopup, onSetCpLeadRes } =
    props;

  const {
    register,
    unregister,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });

  const [addSiteVisitByCpUserAPI] = useAddSiteVisitByCpUserMutation();
  const [getCpUserByMobileAPI] = useGetCpUserByMobileMutation();
  const cpUser = useSelector(getCpUser);
  const [addToast] = useToast();
  const dispatch = useDispatch();

  const handleUpdateSiteVisit = async (date: { visitDate: Date }) => {
    if (isInValidDate(date.visitDate)) {
      addToast({
        type: 'ERROR',
        primaryMessage: 'Please select a today or later date',
      });
      return;
    }

    const payload: ICpSiteVisit = {
      visitDate: date.visitDate,
      cpLead: cpLead.id,
      project: projectId,
      cpUser: cpUser?.id as TObjectId,
      status: SCHEDULED_SITE_VISIT_STATUS.SCHEDULED_SITE_VISIT,
    };
    try {
      dispatch(setAppSliceIsFetching(true));

      const response = await addSiteVisitByCpUserAPI({ ...payload }).unwrap();

      if (response.data) {
        const cpUserRes = await getCpUserByMobileAPI({
          address: cpUser?.user.mobile as string,
          addressType: 'MOBILE',
        }).unwrap();

        const updatedCpUser: ICpUser = cpUserRes.data;

        dispatch(setCpUser(updatedCpUser));
        const cpLeadRes = updatedCpUser.cpLeads.find(
          (lead) => lead.id == cpLead.id
        );
        onSetCpLeadRes && onSetCpLeadRes(cpLeadRes as ICpLead);
      }

      addToast({
        type: 'SUCCESS',
        primaryMessage: 'Site-visit is successfully scheduled',
      });

      onClosePopup();
    } catch (err) {
      const errMsg = (err as any).data?.message;
      addToast({
        type: 'ERROR',
        primaryMessage: errMsg,
      });
    } finally {
      dispatch(setAppSliceIsFetching(false));
    }
  };

  return (
    <Modal onOutsideClick={onClosePopup}>
      <div className={styles.contentWrap}>
        <div className={styles.headWrapper}>
          <HeadTitle>
            <>
              {cpLead.name} -<br /> {getMobileFromCpLead(cpLead)}
            </>
          </HeadTitle>
          <Icon
            onClick={onClosePopup}
            name='close'
            propStyles={styles.closeIcon}
          />
        </div>
        <p className={styles.subHeading}>{projectName} | C45 Developer</p>
        <div className={styles.dateContainer}>
          <p className={styles.siteVisitLabel}>Schedule New Sitevisit</p>
          <TextInput
            label='New Sitevisit Date *'
            placeHolder='Add New Date for Site Visit'
            type='date'
            register={register}
            setValue={setValue}
            unregister={unregister}
            labelStyles={styles.labelText}
            name='visitDate'
            errorMessage={errors.visitDate && 'Site-visit date is required'}
          />
        </div>
        <Button
          type='button'
          accent='primary'
          onClick={handleSubmit(handleUpdateSiteVisit)}
          propStyles={styles.btnWrap}
          crApp>
          UPDATE
        </Button>
      </div>
    </Modal>
  );
};

export default SheduleSiteVisitModel;
