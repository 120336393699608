import { createSlice } from '@reduxjs/toolkit';

export const userLanguageSlice = createSlice({
  name: 'userLanguageSlice',
  initialState: {
    language: 'en',
  },
  reducers: {
    setUserLanguage: (state, action) => {
      state.language = action.payload;
    },
  },
});

export const { setUserLanguage } = userLanguageSlice.actions;

export const getUserLanguage = (state: {
  userLanguageSlice: { language: string };
}) => state.userLanguageSlice.language;
