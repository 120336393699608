import { createRef, useEffect, useRef } from 'react';

import { motion, AnimatePresence } from 'framer-motion';
import Icon, { IconNames } from '../../Icon';

import styles from './styles.module.css';

interface ITextInput {
  label?: string;
  placeHolder: string;
  errorMessage?: string;
  defaultValue?: string;
  resetValue?: boolean;
  showSuccess?: boolean;
  crApp?: boolean;
  onChange?: Function;
  onBlur?: Function;
  onFocus?: Function;
  type?:
    | 'text'
    | 'number'
    | 'email'
    | 'tel'
    | 'date'
    | 'datetime-local'
    | 'password'
    | 'search'
    | 'range';
  disabled?: boolean;
  readOnly?: boolean;
  maxLength?: number;
  // TODO: Add proper types to literals related to useFormic
  unregister?: Function;
  register?: Function;
  name?: string;
  validate?: Function;
  setValue?: Function;
  bottomMessage?: string;
  startIconVariant?: 'PRIMARY' | 'SECONDARY';
  startIconName?: IconNames;
  startIconText?: string;
  endIconName?: IconNames;
  onEndIconClick?: Function;
  onBottomMessageClick?: Function;
  autoFocus?: boolean;
  inputHTMLElementStyles?: string;
  rangeMin?: number;
  rangeMax?: number;
  rangeMinValue?: number;
  rangeMaxValue?: number;
  minValRef?: React.RefObject<HTMLInputElement>;
  maxValRef?: React.RefObject<HTMLInputElement>;
  onMinValChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onMaxValChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  propStyles?: string;
  bottomMessageStyles?: string;
  endIconStyles?: string;
  labelStyles?: string;
  searchForMobile?: boolean;
}

const TextInput = (props: ITextInput) => {
  const {
    label,
    placeHolder,
    errorMessage = '',
    defaultValue = '',
    resetValue = false,
    showSuccess = false,
    onChange,
    crApp = false,
    onBlur,
    type = 'text',
    disabled = false,
    readOnly = false,
    maxLength,
    unregister,
    register = () => {
      return {
        name,
        ref: createRef(),
      };
    },
    name = '',
    validate,
    setValue,
    bottomMessage = '',
    startIconVariant = 'PRIMARY',
    startIconName = '',
    endIconName = '',
    startIconText = '',
    onEndIconClick,
    onBottomMessageClick,
    autoFocus = false,
    inputHTMLElementStyles,
    rangeMin = 0,
    rangeMax = 100,
    rangeMaxValue,
    rangeMinValue = 0,
    minValRef,
    maxValRef,
    onMinValChange,
    onMaxValChange,
    propStyles,
    bottomMessageStyles,
    onFocus,
    endIconStyles,
    labelStyles,
    searchForMobile,
  } = props;

  // TODO: Implement generic form registration
  const {
    onChange: onChangeForm,
    onBlur: onBlurForm,
    name: nameForm = name,
    ref: refForm = createRef(),
  } = register(name, {
    [validate ? 'validate' : '']: validate,
  });

  useEffect(() => {
    setValue && setValue(name, defaultValue);
  }, [defaultValue, name, setValue]);

  useEffect(() => {
    return () => {
      // Anything in here is fired on component unmount.
      unregister && unregister(name);
    };
  }, [unregister, name]);

  useEffect(() => {
    if (resetValue) {
      refForm.current.value = '';
      setValue &&
        setValue(name, refForm.current.value, { shouldValidate: true });
    }
  }, [resetValue]);

  const handleInputOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange && onChange(event);
    onChangeForm && onChangeForm(event);

    if (maxLength) {
      if (event.target.value.length > maxLength) {
        event.target.value = event.target.value.slice(0, maxLength);
        setValue &&
          setValue(name, event.target.value, { shouldValidate: true });
      }
    }
  };

  const handleInputOnBlur = (event: React.ChangeEvent<HTMLInputElement>) => {
    onBlur && onBlur(event);
    onBlurForm && onBlurForm(event);
  };

  const handleInputOnFocus = (event: React.ChangeEvent<HTMLInputElement>) => {
    onFocus && onFocus(event);
  };

  return (
    <div
      className={`${styles.inputContainer} ${
        crApp && styles.crApp
      } ${propStyles}`}>
      {label && (
        <label
          htmlFor={label}
          className={`${styles.inputLable} ${labelStyles}`}>
          {label}
        </label>
      )}
      <div
        className={`${styles.inputBox} ${
          crApp && (disabled || readOnly) && styles.cpDisabled
        } ${(disabled || readOnly) && styles.disabledBg} ${
          searchForMobile && styles.smallInputBox
        }`}
        data-type={type}>
        <div className={styles.inputBoxLeftContent}>
          {startIconName && (
            <div
              className={`${
                styles[
                  `${startIconVariant.toLocaleLowerCase()}StartIconContainer`
                ]
              } ${styles.startIconContainer}`}>
              <span
                className={`icon material-icons  ${
                  styles[`${startIconVariant.toLocaleLowerCase()}StartIcon`]
                }`}>
                {startIconName}
              </span>
            </div>
          )}
          {startIconText && (
            <span
              className={`${styles.startText}  ${
                (disabled || readOnly) && styles.disabledBg
              }`}>
              {startIconText}
            </span>
          )}

          {type == 'range' ? (
            <>
              <input
                type={type}
                min={rangeMin}
                max={rangeMax}
                value={rangeMinValue}
                ref={minValRef}
                onChange={onMinValChange}
                className={`${styles.thumb} ${
                  rangeMinValue > rangeMax - 100
                    ? `${styles.thumbIndex5}`
                    : `${styles.thumbIndex3}`
                }`}
              />
              <input
                type={type}
                min={rangeMin}
                max={rangeMax}
                value={rangeMaxValue}
                ref={maxValRef}
                onChange={onMaxValChange}
                className={`${styles.thumb} ${styles.thumbMax}`}
              />
            </>
          ) : (
            <input
              id={label}
              defaultValue={defaultValue}
              name={nameForm}
              ref={refForm}
              className={`${styles.inputField} ${inputHTMLElementStyles}`}
              type={type}
              placeholder={placeHolder}
              onChange={handleInputOnChange}
              onBlur={handleInputOnBlur}
              onFocus={handleInputOnFocus}
              disabled={disabled}
              readOnly={readOnly}
              autoFocus={autoFocus}
              maxLength={maxLength}
            />
          )}

          {endIconName && (
            <Icon
              name={endIconName}
              propStyles={`${styles.endIcon} ${endIconStyles}`}
              onClick={() => {
                onEndIconClick && onEndIconClick();
              }}
            />
          )}
        </div>
        <AnimatePresence>
          {errorMessage && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{
                default: { duration: 0.4 },
              }}>
              <Icon
                name={showSuccess ? 'check' : 'error_outline'}
                propStyles={`disabled ${styles.errorIcon} ${
                  showSuccess ? styles.successIcon : ''
                }`}
              />
            </motion.div>
          )}
        </AnimatePresence>
      </div>
      {bottomMessage !== '' && (
        <p
          className={`${styles.bottomMessage} ${bottomMessageStyles} `}
          onClick={() => {
            onBottomMessageClick && onBottomMessageClick();
          }}>
          {bottomMessage}
        </p>
      )}
      <AnimatePresence>
        {errorMessage && (
          <motion.p
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className={`${styles.inputFieldError}`}>
            {errorMessage}
          </motion.p>
        )}
      </AnimatePresence>
    </div>
  );
};

export default TextInput;
