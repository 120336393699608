import { useState } from 'react';

import Button from 'apps/booking/components/common/buttons/Button';
import TextInput from 'apps/booking/components/common/inputs/TextInput';
import { UserRole } from 'constants/userRoles';
import AddCompanyUserModal from 'apps/admin/components/pages/AdminDashboard/AdminExecutiveList/modals/AddCompanyUserModal';

import styles from './styles.module.css';

interface IAdminDashboardHeader {
  onGridSearch: Function;
  showHeaderButtons?: boolean;
  companyId: string;
}

const AdminDashboardHeader = (props: IAdminDashboardHeader) => {
  const { onGridSearch, showHeaderButtons = true, companyId } = props;

  const [selectedUserRole, setSelectedUserRole] = useState<UserRole | null>();

  const handleAdminDashboardGridsSearch = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    onGridSearch(event.target.value);
  };

  const handleModalClose = () => {
    setSelectedUserRole(null);
  };

  return (
    <header>
      {selectedUserRole && (
        <AddCompanyUserModal
          role={selectedUserRole}
          onModalClose={handleModalClose}
          companyId={companyId}
        />
      )}

      {/* Admin dashboard header left part */}
      <div className={styles.adminDashboardHeader}>
        <div className={styles.headerLeftContainer}>
          <h2 className={styles.headerHeading}>Admin Dashboard</h2>
          <p className={styles.headerDes}>
            All Projects {` `}
            <span className={styles.boldHeaderDes}>Under One Dashboard</span>
          </p>
        </div>
        {/* Admin dashboard header right part */}
        {showHeaderButtons && (
          <div className={styles.dashboardHeaderButtonsContainer}>
            <Button
              onClick={() => {
                setSelectedUserRole(UserRole.ADMIN);
              }}
              propStyles={styles.createAdminButton}
              accent='primary'>
              Create New Admin
            </Button>
            <Button
              onClick={() => {
                setSelectedUserRole(UserRole.EXECUTIVE);
              }}
              propStyles={styles.createAdminButton}
              accent='primary'>
              Create New Executive
            </Button>
          </div>
        )}
      </div>
    </header>
  );
};

export default AdminDashboardHeader;
