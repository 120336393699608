import { useMemo, useState } from 'react';

import useToast from 'hooks/useToast';
import TextInput from 'apps/booking/components/common/inputs/TextInput';
import Button from 'apps/booking/components/common/buttons/Button';
import { useResetAdminUserPasswordByMobileMutation } from 'apps/admin/services/adminUserAPISlice';
import { LoginFormStates } from 'apps/admin/components/pages/AdminLogin/LoginForm';

import styles from '../../styles.module.css';

interface IPasswordResetState {
  setLoginFormState: Function;
  mobile: String;
}

const PasswordResetState = (props: IPasswordResetState) => {
  const { setLoginFormState, mobile } = props;

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const [addToast] = useToast();

  const [resetAdminUserPasswordByMobileAPI] =
    useResetAdminUserPasswordByMobileMutation();

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const password = event.target.value;
    setPassword(password);
  };

  const handleConfirmPasswordChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const password = event.target.value;
    setConfirmPassword(password);
  };

  const handleResetPassword = async () => {
    if (password !== confirmPassword) {
      addToast({
        type: 'ERROR',
        primaryMessage: "Passwords don't match",
        secondaryMessage: '',
        timeout: 1000,
      });
      return;
    }
    try {
      await resetAdminUserPasswordByMobileAPI({ mobile, password }).unwrap();

      addToast({
        type: 'SUCCESS',
        primaryMessage: 'Password reset successfully',
        secondaryMessage: '',
        timeout: 1000,
      });

      setLoginFormState(LoginFormStates.PASSWORD_RESET_SUCCESS);
    } catch (err) {
      const errMsg = (err as any).data.message;
      addToast({
        type: 'ERROR',
        primaryMessage: errMsg,
        secondaryMessage: '',
        timeout: 1000,
      });
    }
  };

  const buttonDisabled = useMemo(() => {
    return !password;
  }, [password]);

  return (
    <>
      <h2 className={styles.loginFormHeading}>
        Let us know what is your New Password
      </h2>
      <p className={styles.loginFormDesc}>
        Enter the new password you want to set for your account. These both have
        to match!
      </p>
      <TextInput
        label='Enter New Password'
        placeHolder='Password'
        onChange={handlePasswordChange}
        type='password'
      />
      <TextInput
        label='Re-enter New Password'
        placeHolder='Confirm password'
        onChange={handleConfirmPasswordChange}
        type='password'
      />
      <Button
        propStyles={styles.loginButton}
        onClick={handleResetPassword}
        disabled={buttonDisabled}>
        Reset Password
      </Button>
      <Button
        propStyles={styles.forgotPasswordButton}
        onClick={() => setLoginFormState(LoginFormStates.LOGIN)}>
        Back To Login
      </Button>
    </>
  );
};

export default PasswordResetState;
