import { APPLICANT_TYPE, PERSON_TITLE } from 'apps/booking/constants';

interface IStateOption {
  value: string;
  displayText: string;
}

interface IDropdownOption {
  value: string | boolean;
  text: string;
  selectedText?: string;
}

interface IDropdownProps {
  cname: string;
  defaultValue: string;
  options: IDropdownOption[];
}

export const titleDropDown: IDropdownProps = {
  cname: 'dropdown--secondary',
  defaultValue: PERSON_TITLE.MR,
  options: [
    { value: PERSON_TITLE.MR, text: PERSON_TITLE.MR },
    { value: PERSON_TITLE.MS, text: PERSON_TITLE.MS },
    { value: PERSON_TITLE.MRS, text: PERSON_TITLE.MRS },
  ],
};

export const applicantTypeDropDown: IDropdownProps = {
  cname: '',
  defaultValue: APPLICANT_TYPE.SECONDARY,
  options: [
    { value: APPLICANT_TYPE.PRIMARY, text: 'Primary Applicant' },
    { value: APPLICANT_TYPE.SECONDARY, text: 'Co-Applicant' },
  ],
};

export const countryCodeDropDown: IDropdownProps = {
  cname: 'dropdown--secondary',
  defaultValue: '91',
  options: [
    { value: '213', text: 'Algeria (+213)', selectedText: '+213' },
    { value: '376', text: 'Andorra (+376)', selectedText: '+376' },
    { value: '244', text: 'Angola (+244)', selectedText: '+244' },
    { value: '1264', text: 'Anguilla (+1264)', selectedText: '+1264' },
    {
      value: '1268',
      text: 'Antigua &amp; Barbuda (+1268)',
      selectedText: '+1268',
    },
    { value: '54', text: 'Argentina (+54)', selectedText: '+54' },
    { value: '374', text: 'Armenia (+374)', selectedText: '+374' },
    { value: '297', text: 'Aruba (+297)', selectedText: '+297' },
    { value: '61', text: 'Australia (+61)', selectedText: '+61' },
    { value: '43', text: 'Austria (+43)', selectedText: '+43' },
    { value: '994', text: 'Azerbaijan (+994)', selectedText: '+994' },
    { value: '1242', text: 'Bahamas (+1242)', selectedText: '+1242' },
    { value: '973', text: 'Bahrain (+973)', selectedText: '+973' },
    { value: '880', text: 'Bangladesh (+880)', selectedText: '+880' },
    { value: '1246', text: 'Barbados (+1246)', selectedText: '+1246' },
    { value: '375', text: 'Belarus (+375)', selectedText: '+375' },
    { value: '32', text: 'Belgium (+32)', selectedText: '+32' },
    { value: '501', text: 'Belize (+501)', selectedText: '+501' },
    { value: '229', text: 'Benin (+229)', selectedText: '+229' },
    { value: '1441', text: 'Bermuda (+1441)', selectedText: '+1441' },
    { value: '975', text: 'Bhutan (+975)', selectedText: '+975' },
    { value: '591', text: 'Bolivia (+591)', selectedText: '+591' },
    { value: '387', text: 'Bosnia Herzegovina (+387)', selectedText: '+387' },
    { value: '267', text: 'Botswana (+267)', selectedText: '+267' },
    { value: '55', text: 'Brazil (+55)', selectedText: '+55' },
    { value: '673', text: 'Brunei (+673)', selectedText: '+673' },
    { value: '359', text: 'Bulgaria (+359)', selectedText: '+359' },
    { value: '226', text: 'Burkina Faso (+226)', selectedText: '+226' },
    { value: '257', text: 'Burundi (+257)', selectedText: '+257' },
    { value: '855', text: 'Cambodia (+855)', selectedText: '+855' },
    { value: '237', text: 'Cameroon (+237)', selectedText: '+237' },
    { value: '1', text: 'Canada (+1)', selectedText: '+1' },
    { value: '238', text: 'Cape Verde Islands (+238)', selectedText: '+238' },
    { value: '1345', text: 'Cayman Islands (+1345)', selectedText: '+1345' },
    {
      value: '236',
      text: 'Central African Republic (+236)',
      selectedText: '+236',
    },
    { value: '56', text: 'Chile (+56)', selectedText: '+56' },
    { value: '86', text: 'China (+86)', selectedText: '+86' },
    { value: '57', text: 'Colombia (+57)', selectedText: '+57' },
    { value: '269', text: 'Comoros (+269)', selectedText: '+269' },
    { value: '242', text: 'Congo (+242)', selectedText: '+242' },
    { value: '682', text: 'Cook Islands (+682)', selectedText: '+682' },
    { value: '506', text: 'Costa Rica (+506)', selectedText: '+506' },
    { value: '385', text: 'Croatia (+385)', selectedText: '+385' },
    { value: '53', text: 'Cuba (+53)', selectedText: '+53' },
    { value: '90392', text: 'Cyprus North (+90392)', selectedText: '+90392' },
    { value: '357', text: 'Cyprus South (+357)', selectedText: '+357' },
    { value: '42', text: 'Czech Republic (+42)', selectedText: '+42' },
    { value: '45', text: 'Denmark (+45)', selectedText: '+45' },
    { value: '253', text: 'Djibouti (+253)', selectedText: '+253' },
    { value: '1809', text: 'Dominica (+1809)', selectedText: '+1809' },
    {
      value: '1809',
      text: 'Dominican Republic (+1809)',
      selectedText: '+1809',
    },
    { value: '593', text: 'Ecuador (+593)', selectedText: '+593' },
    { value: '20', text: 'Egypt (+20)', selectedText: '+20' },
    { value: '503', text: 'El Salvador (+503)', selectedText: '+503' },
    { value: '240', text: 'Equatorial Guinea (+240)', selectedText: '+240' },
    { value: '291', text: 'Eritrea (+291)', selectedText: '+291' },
    { value: '372', text: 'Estonia (+372)', selectedText: '+372' },
    { value: '251', text: 'Ethiopia (+251)', selectedText: '+251' },
    { value: '500', text: 'Falkland Islands (+500)', selectedText: '+500' },
    { value: '298', text: 'Faroe Islands (+298)', selectedText: '+298' },
    { value: '679', text: 'Fiji (+679)', selectedText: '+679' },
    { value: '358', text: 'Finland (+358)', selectedText: '+358' },
    { value: '33', text: 'France (+33)', selectedText: '+33' },
    { value: '594', text: 'French Guiana (+594)', selectedText: '+594' },
    { value: '689', text: 'French Polynesia (+689)', selectedText: '+689' },
    { value: '241', text: 'Gabon (+241)', selectedText: '+241' },
    { value: '220', text: 'Gambia (+220)', selectedText: '+220' },
    { value: '7880', text: 'Georgia (+7880)', selectedText: '+7880' },
    { value: '49', text: 'Germany (+49)', selectedText: '+49' },
    { value: '233', text: 'Ghana (+233)', selectedText: '+233' },
    { value: '350', text: 'Gibraltar (+350)', selectedText: '+350' },
    { value: '30', text: 'Greece (+30)', selectedText: '+30' },
    { value: '299', text: 'Greenland (+299)', selectedText: '+299' },
    { value: '1473', text: 'Grenada (+1473)', selectedText: '+1473' },
    { value: '590', text: 'Guadeloupe (+590)', selectedText: '+590' },
    { value: '671', text: 'Guam (+671)', selectedText: '+671' },
    { value: '502', text: 'Guatemala (+502)', selectedText: '+502' },
    { value: '224', text: 'Guinea (+224)', selectedText: '+224' },
    { value: '245', text: 'Guinea - Bissau (+245)', selectedText: '+245' },
    { value: '592', text: 'Guyana (+592)', selectedText: '+592' },
    { value: '509', text: 'Haiti (+509)', selectedText: '+509' },
    { value: '504', text: 'Honduras (+504)', selectedText: '+504' },
    { value: '852', text: 'Hong Kong (+852)', selectedText: '+852' },
    { value: '36', text: 'Hungary (+36)', selectedText: '+36' },
    { value: '354', text: 'Iceland (+354)', selectedText: '+354' },
    { value: '91', text: 'India (+91)', selectedText: '+91' },
    { value: '62', text: 'Indonesia (+62)', selectedText: '+62' },
    { value: '98', text: 'Iran (+98)', selectedText: '+98' },
    { value: '964', text: 'Iraq (+964)', selectedText: '+964' },
    { value: '353', text: 'Ireland (+353)', selectedText: '+353' },
    { value: '972', text: 'Israel (+972)', selectedText: '+972' },
    { value: '39', text: 'Italy (+39)', selectedText: '+39' },
    { value: '1876', text: 'Jamaica (+1876)', selectedText: '+1876' },
    { value: '81', text: 'Japan (+81)', selectedText: '+81' },
    { value: '962', text: 'Jordan (+962)', selectedText: '+962' },
    { value: '7', text: 'Kazakhstan (+7)', selectedText: '+7' },
    { value: '254', text: 'Kenya (+254)', selectedText: '+254' },
    { value: '686', text: 'Kiribati (+686)', selectedText: '+686' },
    { value: '850', text: 'Korea North (+850)', selectedText: '+850' },
    { value: '82', text: 'Korea South (+82)', selectedText: '+82' },
    { value: '965', text: 'Kuwait (+965)', selectedText: '+965' },
    { value: '996', text: 'Kyrgyzstan (+996)', selectedText: '+996' },
    { value: '856', text: 'Laos (+856)', selectedText: '+856' },
    { value: '371', text: 'Latvia (+371)', selectedText: '+371' },
    { value: '961', text: 'Lebanon (+961)', selectedText: '+961' },
    { value: '266', text: 'Lesotho (+266)', selectedText: '+266' },
    { value: '231', text: 'Liberia (+231)', selectedText: '+231' },
    { value: '218', text: 'Libya (+218)', selectedText: '+218' },
    { value: '417', text: 'Liechtenstein (+417)', selectedText: '+417' },
    { value: '370', text: 'Lithuania (+370)', selectedText: '+370' },
    { value: '352', text: 'Luxembourg (+352)', selectedText: '+352' },
    { value: '853', text: 'Macao (+853)', selectedText: '+853' },
    { value: '389', text: 'Macedonia (+389)', selectedText: '+389' },
    { value: '261', text: 'Madagascar (+261)', selectedText: '+261' },
    { value: '265', text: 'Malawi (+265)', selectedText: '+265' },
    { value: '60', text: 'Malaysia (+60)', selectedText: '+60' },
    { value: '960', text: 'Maldives (+960)', selectedText: '+960' },
    { value: '223', text: 'Mali (+223)', selectedText: '+223' },
    { value: '356', text: 'Malta (+356)', selectedText: '+356' },
    { value: '692', text: 'Marshall Islands (+692)', selectedText: '+692' },
    { value: '596', text: 'Martinique (+596)', selectedText: '+596' },
    { value: '222', text: 'Mauritania (+222)', selectedText: '+222' },
    { value: '269', text: 'Mayotte (+269)', selectedText: '+269' },
    { value: '52', text: 'Mexico (+52)', selectedText: '+52' },
    { value: '691', text: 'Micronesia (+691)', selectedText: '+691' },
    { value: '373', text: 'Moldova (+373)', selectedText: '+373' },
    { value: '377', text: 'Monaco (+377)', selectedText: '+377' },
    { value: '976', text: 'Mongolia (+976)', selectedText: '+976' },
    { value: '1664', text: 'Montserrat (+1664)', selectedText: '+1664' },
    { value: '212', text: 'Morocco (+212)', selectedText: '+212' },
    { value: '258', text: 'Mozambique (+258)', selectedText: '+258' },
    { value: '95', text: 'Myanmar (+95)', selectedText: '+95' },
    { value: '264', text: 'Namibia (+264)', selectedText: '+264' },
    { value: '674', text: 'Nauru (+674)', selectedText: '+674' },
    { value: '977', text: 'Nepal (+977)', selectedText: '+977' },
    { value: '31', text: 'Netherlands (+31)', selectedText: '+31' },
    { value: '687', text: 'New Caledonia (+687)', selectedText: '+687' },
    { value: '64', text: 'New Zealand (+64)', selectedText: '+64' },
    { value: '505', text: 'Nicaragua (+505)', selectedText: '+505' },
    { value: '227', text: 'Niger (+227)', selectedText: '+227' },
    { value: '234', text: 'Nigeria (+234)', selectedText: '+234' },
    { value: '683', text: 'Niue (+683)', selectedText: '+683' },
    { value: '672', text: 'Norfolk Islands (+672)', selectedText: '+672' },
    { value: '670', text: 'Northern Marianas (+670)', selectedText: '+670' },
    { value: '47', text: 'Norway (+47)', selectedText: '+47' },
    { value: '968', text: 'Oman (+968)', selectedText: '+968' },
    { value: '680', text: 'Palau (+680)', selectedText: '+680' },
    { value: '507', text: 'Panama (+507)', selectedText: '+507' },
    { value: '675', text: 'Papua New Guinea (+675)', selectedText: '+675' },
    { value: '595', text: 'Paraguay (+595)', selectedText: '+595' },
    { value: '51', text: 'Peru (+51)', selectedText: '+51' },
    { value: '63', text: 'Philippines (+63)', selectedText: '+63' },
    { value: '48', text: 'Poland (+48)', selectedText: '+48' },
    { value: '351', text: 'Portugal (+351)', selectedText: '+351' },
    { value: '1787', text: 'Puerto Rico (+1787)', selectedText: '+1787' },
    { value: '974', text: 'Qatar (+974)', selectedText: '+974' },
    { value: '262', text: 'Reunion (+262)', selectedText: '+262' },
    { value: '40', text: 'Romania (+40)', selectedText: '+40' },
    { value: '7', text: 'Russia (+7)', selectedText: '+7' },
    { value: '250', text: 'Rwanda (+250)', selectedText: '+250' },
    { value: '378', text: 'San Marino (+378)', selectedText: '+378' },
    {
      value: '239',
      text: 'Sao Tome &amp; Principe (+239)',
      selectedText: '+239',
    },
    { value: '966', text: 'Saudi Arabia (+966)', selectedText: '+966' },
    { value: '221', text: 'Senegal (+221)', selectedText: '+221' },
    { value: '381', text: 'Serbia (+381)', selectedText: '+381' },
    { value: '248', text: 'Seychelles (+248)', selectedText: '+248' },
    { value: '232', text: 'Sierra Leone (+232)', selectedText: '+232' },
    { value: '65', text: 'Singapore (+65)', selectedText: '+65' },
    { value: '421', text: 'Slovak Republic (+421)', selectedText: '+421' },
    { value: '386', text: 'Slovenia (+386)', selectedText: '+386' },
    { value: '677', text: 'Solomon Islands (+677)', selectedText: '+677' },
    { value: '252', text: 'Somalia (+252)', selectedText: '+252' },
    { value: '27', text: 'South Africa (+27)', selectedText: '+27' },
    { value: '34', text: 'Spain (+34)', selectedText: '+34' },
    { value: '94', text: 'Sri Lanka (+94)', selectedText: '+94' },
    { value: '290', text: 'St. Helena (+290)', selectedText: '+290' },
    { value: '1869', text: 'St. Kitts (+1869)', selectedText: '+1869' },
    { value: '1758', text: 'St. Lucia (+1758)', selectedText: '+1758' },
    { value: '249', text: 'Sudan (+249)', selectedText: '+249' },
    { value: '597', text: 'Suriname (+597)', selectedText: '+597' },
    { value: '268', text: 'Swaziland (+268)', selectedText: '+268' },
    { value: '46', text: 'Sweden (+46)', selectedText: '+46' },
    { value: '41', text: 'Switzerland (+41)', selectedText: '+41' },
    { value: '963', text: 'Syria (+963)', selectedText: '+963' },
    { value: '886', text: 'Taiwan (+886)', selectedText: '+886' },
    { value: '7', text: 'Tajikstan (+7)', selectedText: '+7' },
    { value: '66', text: 'Thailand (+66)', selectedText: '+66' },
    { value: '228', text: 'Togo (+228)', selectedText: '+228' },
    { value: '676', text: 'Tonga (+676)', selectedText: '+676' },
    {
      value: '1868',
      text: 'Trinidad &amp; Tobago (+1868)',
      selectedText: '+1868',
    },
    { value: '216', text: 'Tunisia (+216)', selectedText: '+216' },
    { value: '90', text: 'Turkey (+90)', selectedText: '+90' },
    { value: '7', text: 'Turkmenistan (+7)', selectedText: '+7' },
    { value: '993', text: 'Turkmenistan (+993)', selectedText: '+993' },
    {
      value: '1649',
      text: 'Turks &amp; Caicos Islands (+1649)',
      selectedText: '+1649',
    },
    { value: '688', text: 'Tuvalu (+688)', selectedText: '+688' },
    { value: '256', text: 'Uganda (+256)', selectedText: '+256' },
    { value: '44', text: 'UK (+44)', selectedText: '+44' },
    { value: '380', text: 'Ukraine (+380)', selectedText: '+380' },
    {
      value: '971',
      text: 'United Arab Emirates (+971)',
      selectedText: '+971',
    },
    { value: '598', text: 'Uruguay (+598)', selectedText: '+598' },
    { value: '1', text: 'USA (+1)', selectedText: '+1' },
    { value: '7', text: 'Uzbekistan (+7)', selectedText: '+7' },
    { value: '678', text: 'Vanuatu (+678)', selectedText: '+678' },
    { value: '379', text: 'Vatican City (+379)', selectedText: '+379' },
    { value: '58', text: 'Venezuela (+58)', selectedText: '+58' },
    { value: '84', text: 'Vietnam (+84)', selectedText: '+84' },
    {
      value: '84',
      text: 'Virgin Islands - British (+1284)',
      selectedText: '+84',
    },
    { value: '84', text: 'Virgin Islands - US (+1340)', selectedText: '+84' },
    { value: '681', text: 'Wallis &amp; Futuna (+681)', selectedText: '+681' },
    { value: '969', text: 'Yemen (North)(+969)', selectedText: '+969' },
    { value: '967', text: 'Yemen (South)(+967)', selectedText: '+967' },
    { value: '260', text: 'Zambia (+260)', selectedText: '+260' },
    { value: '263', text: 'Zimbabwe (+263)', selectedText: '+263' },
  ],
};

export const nationalityCodeDropDown: IDropdownProps = {
  cname: '',
  defaultValue: 'Indian',
  options: [
    { value: 'Indian', text: 'Indian' },
    { value: 'OCI', text: 'OCI' },
    { value: 'PIO', text: 'PIO' },
  ],
};

// Show only 3 countries
export const countryDropDown: IDropdownProps = {
  cname: '',
  defaultValue: 'India',
  options: [
    { value: 'India', text: 'India' },
    { value: 'OCI', text: 'OCI' },
    { value: 'PIO', text: 'PIO' },
  ],
};

export const residentIndiaDropDown: IDropdownProps = {
  cname: '',
  defaultValue: '',
  options: [
    { value: true, text: 'Yes' },
    { value: false, text: 'No' },
  ],
};

export const marriageStatusDropDown: IDropdownProps = {
  cname: '',
  defaultValue: '',
  options: [
    { value: 'married', text: 'Married' },
    { value: 'single', text: 'Single' },
    { value: 'other', text: 'Other' },
  ],
};

export const educationalQualificationDropDown: IDropdownProps = {
  cname: '',
  defaultValue: '',
  options: [
    { value: 'Graduate', text: 'Graduate' },
    { value: 'Post Graduate', text: 'Post Graduate' },
    { value: 'Diploma', text: 'Diploma' },
    { value: 'PhD', text: 'PhD' },
    { value: 'Other', text: 'Other' },
  ],
};

export const cityDropDown: IDropdownProps = {
  cname: '',
  defaultValue: '',
  options: [
    { value: 'Agartala', text: 'Agartala' },
    { value: 'Agra', text: 'Agra' },
    { value: 'Ahmedabad', text: 'Ahmedabad' },
    { value: 'Aizwal', text: 'Aizwal' },
    { value: 'Ajmer', text: 'Ajmer' },
    { value: 'Allahabad', text: 'Allahabad' },
    { value: 'Alleppey', text: 'Alleppey' },
    { value: 'Alibaug', text: 'Alibaug' },
    { value: 'Almora', text: 'Almora' },
    { value: 'Alsisar', text: 'Alsisar' },
    { value: 'Alwar', text: 'Alwar' },
    { value: 'Ambala', text: 'Ambala' },
    { value: 'Amla', text: 'Amla' },
    { value: 'Amritsar', text: 'Amritsar' },
    { value: 'Anand', text: 'Anand' },
    { value: 'Ankleshwar', text: 'Ankleshwar' },
    { value: 'Ashtamudi', text: 'Ashtamudi' },
    { value: 'Auli', text: 'Auli' },
    { value: 'Aurangabad', text: 'Aurangabad' },
    { value: 'Baddi', text: 'Baddi' },
    { value: 'Badrinath', text: 'Badrinath' },
    { value: 'Balasinor', text: 'Balasinor' },
    { value: 'Balrampur', text: 'Balrampur' },
    { value: 'Bambora', text: 'Bambora' },
    { value: 'Bandhavgarh', text: 'Bandhavgarh' },
    { value: 'Bandipur', text: 'Bandipur' },
    { value: 'Bangalore', text: 'Bangalore' },
    { value: 'Barbil', text: 'Barbil' },
    { value: 'Bareilly', text: 'Bareilly' },
    { value: 'Behror', text: 'Behror' },
    { value: 'Belgaum', text: 'Belgaum' },
    { value: 'Berhampur', text: 'Berhampur' },
    { value: 'Betalghat', text: 'Betalghat' },
    { value: 'Bharatpur', text: 'Bharatpur' },
    { value: 'Bhandardara', text: 'Bhandardara' },
    { value: 'Bharuch', text: 'Bharuch' },
    { value: 'Bhavangadh', text: 'Bhavangadh' },
    { value: 'Bhavnagar', text: 'Bhavnagar' },
    { value: 'Bhilai', text: 'Bhilai' },
    { value: 'Bhimtal', text: 'Bhimtal' },
    { value: 'Bhopal', text: 'Bhopal' },
    { value: 'Bhubaneshwar', text: 'Bhubaneshwar' },
    { value: 'Bhuj', text: 'Bhuj' },
    { value: 'Bikaner', text: 'Bikaner' },
    { value: 'Binsar', text: 'Binsar' },
    { value: 'Bodhgaya', text: 'Bodhgaya' },
    { value: 'Bundi', text: 'Bundi' },
    { value: 'Calicut', text: 'Calicut' },
    { value: 'Canannore', text: 'Canannore' },
    { value: 'Chail', text: 'Chail' },
    { value: 'Chamba', text: 'Chamba' },
    { value: 'Chandigarh', text: 'Chandigarh' },
    { value: 'Chennai', text: 'Chennai' },
    { value: 'Chikmagalur', text: 'Chikmagalur' },
    { value: 'Chiplun', text: 'Chiplun' },
    { value: 'Chitrakoot', text: 'Chitrakoot' },
    { value: 'Chittorgarh', text: 'Chittorgarh' },
    { value: 'Coimbatore', text: 'Coimbatore' },
    { value: 'Coonoor', text: 'Coonoor' },
    { value: 'Coorg', text: 'Coorg' },
    { value: 'Corbett National Park', text: 'Corbett National Park' },
    { value: 'Cuttack', text: 'Cuttack' },
    { value: 'Dabhosa', text: 'Dabhosa' },
    { value: 'Dalhousie', text: 'Dalhousie' },
    { value: 'Daman', text: 'Daman' },
    { value: 'Dandeli', text: 'Dandeli' },
    { value: 'Dapoli', text: 'Dapoli' },
    { value: 'Darjeeling', text: 'Darjeeling' },
    { value: 'Dausa', text: 'Dausa' },
    { value: 'Dehradun', text: 'Dehradun' },
    { value: 'Dharamshala', text: 'Dharamshala' },
    { value: 'Dibrugarh', text: 'Dibrugarh' },
    { value: 'Digha', text: 'Digha' },
    { value: 'Diu', text: 'Diu' },
    { value: 'Dive Agar', text: 'Dive Agar' },
    { value: 'Dooars', text: 'Dooars' },
    { value: 'Durgapur', text: 'Durgapur' },
    { value: 'Durshet', text: 'Durshet' },
    { value: 'Dwarka', text: 'Dwarka' },
    { value: 'Faridabad', text: 'Faridabad' },
    { value: 'Firozabad', text: 'Firozabad' },
    { value: 'Gangotri', text: 'Gangotri' },
    { value: 'Gangtok', text: 'Gangtok' },
    { value: 'Ganapatipule', text: 'Ganapatipule' },
    { value: 'Gandhidham', text: 'Gandhidham' },
    { value: 'Gandhinagar', text: 'Gandhinagar' },
    { value: 'Garhmukteshwar', text: 'Garhmukteshwar' },
    { value: 'Garhwal', text: 'Garhwal' },
    { value: 'Gaya', text: 'Gaya' },
    { value: 'Ghaziabad', text: 'Ghaziabad' },
    { value: 'Goa', text: 'Goa' },
    { value: 'Gokharna', text: 'Gokharna' },
    { value: 'Gondal', text: 'Gondal' },
    { value: 'Gorakhpur', text: 'Gorakhpur' },
    { value: 'Greater Noida', text: 'Greater Noida' },
    { value: 'Gulmarg', text: 'Gulmarg' },
    { value: 'Gurgaon', text: 'Gurgaon' },
    { value: 'Guruvayoor', text: 'Guruvayoor' },
    { value: 'Guwahati', text: 'Guwahati' },
    { value: 'Gwalior', text: 'Gwalior' },
    { value: 'Halebid', text: 'Halebid' },
    { value: 'Hampi', text: 'Hampi' },
    { value: 'Hansi', text: 'Hansi' },
    { value: 'Haridwar', text: 'Haridwar' },
    { value: 'Hassan', text: 'Hassan' },
    { value: 'Hospet', text: 'Hospet' },
    { value: 'Hosur', text: 'Hosur' },
    { value: 'Hubli', text: 'Hubli' },
    { value: 'Hyderabad', text: 'Hyderabad' },
    { value: 'Idukki', text: 'Idukki' },
    { value: 'Igatpuri', text: 'Igatpuri' },
    { value: 'Imphal', text: 'Imphal' },
    { value: 'Indore', text: 'Indore' },
    { value: 'Jabalpur', text: 'Jabalpur' },
    { value: 'Jaipur', text: 'Jaipur' },
    { value: 'Jaisalmer', text: 'Jaisalmer' },
    { value: 'Jalandhar', text: 'Jalandhar' },
    { value: 'Jalgaon', text: 'Jalgaon' },
    { value: 'Jambugodha', text: 'Jambugodha' },
    { value: 'Jammu', text: 'Jammu' },
    { value: 'Jamnagar', text: 'Jamnagar' },
    { value: 'Jamshedpur', text: 'Jamshedpur' },
    { value: 'Jawhar', text: 'Jawhar' },
    { value: 'Jhansi', text: 'Jhansi' },
    { value: 'Jodhpur', text: 'Jodhpur' },
    { value: 'Jojawar', text: 'Jojawar' },
    { value: 'Jorhat', text: 'Jorhat' },
    { value: 'Junagadh', text: 'Junagadh' },
    { value: 'Kabini', text: 'Kabini' },
    { value: 'Kalimpong', text: 'Kalimpong' },
    { value: 'Kanatal', text: 'Kanatal' },
    { value: 'Kanchipuram', text: 'Kanchipuram' },
    { value: 'Kanha', text: 'Kanha' },
    { value: 'Kanpur', text: 'Kanpur' },
    { value: 'Kanyakumari', text: 'Kanyakumari' },
    { value: 'Kargil', text: 'Kargil' },
    { value: 'Karjat', text: 'Karjat' },
    { value: 'Karnal', text: 'Karnal' },
    { value: 'Karur', text: 'Karur' },
    { value: 'Karwar', text: 'Karwar' },
    { value: 'Kasargod', text: 'Kasargod' },
    { value: 'Kasauli', text: 'Kasauli' },
    { value: 'Kashipur', text: 'Kashipur' },
    { value: 'Kashid', text: 'Kashid' },
    { value: 'Katra', text: 'Katra' },
    { value: 'Kausani', text: 'Kausani' },
    { value: 'Kaza', text: 'Kaza' },
    { value: 'Kaziranga', text: 'Kaziranga' },
    { value: 'Kedarnath', text: 'Kedarnath' },
    { value: 'Khajjiar', text: 'Khajjiar' },
    { value: 'Khajuraho', text: 'Khajuraho' },
    { value: 'Khandala', text: 'Khandala' },
    { value: 'Khimsar', text: 'Khimsar' },
    { value: 'Kochin', text: 'Kochin' },
    { value: 'Kodaikanal', text: 'Kodaikanal' },
    { value: 'Kolhapur', text: 'Kolhapur' },
    { value: 'Kolkata', text: 'Kolkata' },
    { value: 'Kollam', text: 'Kollam' },
    { value: 'Kota', text: 'Kota' },
    { value: 'Kotagiri', text: 'Kotagiri' },
    { value: 'Kottayam', text: 'Kottayam' },
    { value: 'Kovalam', text: 'Kovalam' },
    { value: 'Kufri', text: 'Kufri' },
    { value: 'Kumbalgarh', text: 'Kumbalgarh' },
    { value: 'Kullu', text: 'Kullu' },
    { value: 'Kumarakom', text: 'Kumarakom' },
    { value: 'Kumbakonam', text: 'Kumbakonam' },
    { value: 'Kumily', text: 'Kumily' },
    { value: 'Kurseong', text: 'Kurseong' },
    { value: 'Kushinagar', text: 'Kushinagar' },
    { value: 'Lachung', text: 'Lachung' },
    { value: 'Leh', text: 'Leh' },
    { value: 'Lakshadweep', text: 'Lakshadweep' },
    { value: 'Lonavala', text: 'Lonavala' },
    { value: 'Lothal', text: 'Lothal' },
    { value: 'Lucknow', text: 'Lucknow' },
    { value: 'Ludhiana', text: 'Ludhiana' },
    { value: 'Lumbini', text: 'Lumbini' },
    { value: 'Madurai', text: 'Madurai' },
    { value: 'Mahabaleshwar', text: 'Mahabaleshwar' },
    { value: 'Mahabalipuram', text: 'Mahabalipuram' },
    { value: 'Malappuram', text: 'Malappuram' },
    { value: 'Malpe', text: 'Malpe' },
    { value: 'Malshej Ghat', text: 'Malshej Ghat' },
    { value: 'Malvan', text: 'Malvan' },
    { value: 'Manali', text: 'Manali' },
    { value: 'Mandavi', text: 'Mandavi' },
    { value: 'Mandawa', text: 'Mandawa' },
    { value: 'Manesar', text: 'Manesar' },
    { value: 'Mararri', text: 'Mararri' },
    { value: 'Mandormoni', text: 'Mandormoni' },
    { value: 'Mangalore', text: 'Mangalore' },
    { value: 'Manmad', text: 'Manmad' },
    { value: 'Marchula', text: 'Marchula' },
    { value: 'Matheran', text: 'Matheran' },
    { value: 'Mathura', text: 'Mathura' },
    { value: 'Mcleodganj', text: 'Mcleodganj' },
    { value: 'Mohali', text: 'Mohali' },
    { value: 'Mount Abu', text: 'Mount Abu' },
    { value: 'Moradabad', text: 'Moradabad' },
    { value: 'Morbi', text: 'Morbi' },
    { value: 'Mukteshwar', text: 'Mukteshwar' },
    { value: 'Mumbai', text: 'Mumbai' },
    { value: 'Mundra', text: 'Mundra' },
    { value: 'Munnar', text: 'Munnar' },
    { value: 'Murud Janjira', text: 'Murud Janjira' },
    { value: 'Mussoorie', text: 'Mussoorie' },
    { value: 'Mysore', text: 'Mysore' },
    { value: 'Nadukani', text: 'Nadukani' },
    { value: 'Nagapattinam', text: 'Nagapattinam' },
    { value: 'Nagarhole', text: 'Nagarhole' },
    { value: 'Nagaur Fort', text: 'Nagaur Fort' },
    { value: 'Nagothane', text: 'Nagothane' },
    { value: 'Nagpur', text: 'Nagpur' },
    { value: 'Nahan', text: 'Nahan' },
    { value: 'Nainital', text: 'Nainital' },
    { value: 'Naldhera', text: 'Naldhera' },
    { value: 'Nanded', text: 'Nanded' },
    { value: 'Napne', text: 'Napne' },
    { value: 'Nasik', text: 'Nasik' },
    { value: 'Navi Mumbai', text: 'Navi Mumbai' },
    { value: 'Neral', text: 'Neral' },
    { value: 'New Delhi', text: 'New Delhi' },
    { value: 'Nilgiri', text: 'Nilgiri' },
    { value: 'Noida', text: 'Noida' },
    { value: 'Ooty', text: 'Ooty' },
    { value: 'Orchha', text: 'Orchha' },
    { value: 'Osian', text: 'Osian' },
    { value: 'Pachmarhi', text: 'Pachmarhi' },
    { value: 'Palampur', text: 'Palampur' },
    { value: 'Palanpur', text: 'Palanpur' },
    { value: 'Pali', text: 'Pali' },
    { value: 'Pahalgam', text: 'Pahalgam' },
    { value: 'Palitana', text: 'Palitana' },
    { value: 'Pallakad', text: 'Pallakad' },
    { value: 'Panchgani', text: 'Panchgani' },
    { value: 'Panchkula', text: 'Panchkula' },
    { value: 'Panna', text: 'Panna' },
    { value: 'Panhala', text: 'Panhala' },
    { value: 'Panvel', text: 'Panvel' },
    { value: 'Pantnagar', text: 'Pantnagar' },
    { value: 'Parwanoo', text: 'Parwanoo' },
    { value: 'Patiala', text: 'Patiala' },
    { value: 'Pathankot', text: 'Pathankot' },
    { value: 'Patna', text: 'Patna' },
    { value: 'Patnitop', text: 'Patnitop' },
    { value: 'Pelling', text: 'Pelling' },
    { value: 'Pench', text: 'Pench' },
    { value: 'Phagwara', text: 'Phagwara' },
    { value: 'Phalodi', text: 'Phalodi' },
    { value: 'Pinjore', text: 'Pinjore' },
    { value: 'Pondicherry', text: 'Pondicherry' },
    { value: 'Poovar', text: 'Poovar' },
    { value: 'Porbandar', text: 'Porbandar' },
    { value: 'Port Blair', text: 'Port Blair' },
    { value: 'Poshina', text: 'Poshina' },
    { value: 'Pragpur', text: 'Pragpur' },
    { value: 'Pune', text: 'Pune' },
    { value: 'Puri', text: 'Puri' },
    { value: 'Puskhar', text: 'Puskhar' },
    { value: 'Puttaparthi', text: 'Puttaparthi' },
    { value: 'Rai Bareilly', text: 'Rai Bareilly' },
    { value: 'Raichak', text: 'Raichak' },
    { value: 'Raipur', text: 'Raipur' },
    { value: 'Rajasthan', text: 'Rajasthan' },
    { value: 'Rajgir', text: 'Rajgir' },
    { value: 'Rajkot', text: 'Rajkot' },
    { value: 'Rajpipla', text: 'Rajpipla' },
    { value: 'Rajsamand', text: 'Rajsamand' },
    { value: 'Rajahmundry', text: 'Rajahmundry' },
    { value: 'Rameshwaram', text: 'Rameshwaram' },
    { value: 'Ram Nagar', text: 'Ram Nagar' },
    { value: 'Ramgarh', text: 'Ramgarh' },
    { value: 'Ranakpur', text: 'Ranakpur' },
    { value: 'Ranchi', text: 'Ranchi' },
    { value: 'Ranikhet', text: 'Ranikhet' },
    { value: 'Ranny', text: 'Ranny' },
    { value: 'Ranthambore', text: 'Ranthambore' },
    { value: 'Ratnagiri', text: 'Ratnagiri' },
    { value: 'Ravangla', text: 'Ravangla' },
    { value: 'Rishikesh', text: 'Rishikesh' },
    { value: 'Rishyap', text: 'Rishyap' },
    { value: 'Rohetgarh', text: 'Rohetgarh' },
    { value: 'Rourkela', text: 'Rourkela' },
    { value: 'Sajan', text: 'Sajan' },
    { value: 'Salem', text: 'Salem' },
    { value: 'Saputara', text: 'Saputara' },
    { value: 'Sasan Gir', text: 'Sasan Gir' },
    { value: 'Sattal', text: 'Sattal' },
    { value: 'Sawai Madhopur', text: 'Sawai Madhopur' },
    { value: 'Sawantwadi', text: 'Sawantwadi' },
    { value: 'Secunderabad', text: 'Secunderabad' },
    { value: 'Shillong', text: 'Shillong' },
    { value: 'Shimla', text: 'Shimla' },
    { value: 'Shimlipal', text: 'Shimlipal' },
    { value: 'Shirdi', text: 'Shirdi' },
    { value: 'Sharavanbelgola', text: 'Sharavanbelgola' },
    { value: 'Shivanasamudra', text: 'Shivanasamudra' },
    { value: 'Siana', text: 'Siana' },
    { value: 'Siliguri', text: 'Siliguri' },
    { value: 'Silvassa', text: 'Silvassa' },
    { value: 'Sivaganga District', text: 'Sivaganga District' },
    { value: 'Solan', text: 'Solan' },
    { value: 'Sonauli', text: 'Sonauli' },
    { value: 'Srinagar', text: 'Srinagar' },
    { value: 'Sunderban', text: 'Sunderban' },
    { value: 'Surat', text: 'Surat' },
    { value: 'Tanjore', text: 'Tanjore' },
    { value: 'Tapola', text: 'Tapola' },
    { value: 'Tarapith', text: 'Tarapith' },
    { value: 'Thane', text: 'Thane' },
    { value: 'Thekkady', text: 'Thekkady' },
    { value: 'Thirvannamalai', text: 'Thirvannamalai' },
    { value: 'Thiruvananthapuram', text: 'Thiruvananthapuram' },
    { value: 'Tiruchirapalli', text: 'Tiruchirapalli' },
    { value: 'Tirupur', text: 'Tirupur' },
    { value: 'Tirupati', text: 'Tirupati' },
    { value: 'Thrissur', text: 'Thrissur' },
    { value: 'Udaipur', text: 'Udaipur' },
    { value: 'Udhampur', text: 'Udhampur' },
    { value: 'Udupi', text: 'Udupi' },
    { value: 'Ujjain', text: 'Ujjain' },
    { value: 'Uttarkashi', text: 'Uttarkashi' },
    { value: 'Vadodara', text: 'Vadodara' },
    { value: 'Vagamon', text: 'Vagamon' },
    { value: 'Varkala', text: 'Varkala' },
    { value: 'Vapi', text: 'Vapi' },
    { value: 'Varanasi', text: 'Varanasi' },
    { value: 'Velankanni', text: 'Velankanni' },
    { value: 'Vellore', text: 'Vellore' },
    { value: 'Veraval', text: 'Veraval' },
    { value: 'Vijayawada', text: 'Vijayawada' },
    { value: 'Vikramgadh', text: 'Vikramgadh' },
    { value: 'Vishakapatnam', text: 'Vishakapatnam' },
    { value: 'Wayanad', text: 'Wayanad' },
    { value: 'Wankaner', text: 'Wankaner' },
    { value: 'Yamunotri', text: 'Yamunotri' },
    { value: 'Yercaud', text: 'Yercaud' },
    { value: 'Yuksom', text: 'Yuksom' },
  ],
};

export const stateDropDown: IStateOption[] = [
  {
    value: 'Andhra Pradesh',
    displayText: 'Andhra Pradesh',
  },
  {
    value: 'Arunachal Pradesh',
    displayText: 'Arunachal Pradesh',
  },
  {
    value: 'Assam',
    displayText: 'Assam',
  },
  {
    value: 'Bihar',
    displayText: 'Bihar',
  },
  {
    value: 'Chhattisgarh',
    displayText: 'Chhattisgarh',
  },
  {
    value: 'Goa',
    displayText: 'Goa',
  },
  {
    value: 'Gujarat',
    displayText: 'Gujarat',
  },
  {
    value: 'Haryana',
    displayText: 'Haryana',
  },
  {
    value: 'Himachal Pradesh',
    displayText: 'Himachal Pradesh',
  },
  {
    value: 'Jharkhand',
    displayText: 'Jharkhand',
  },
  {
    value: 'Karnataka',
    displayText: 'Karnataka',
  },
  {
    value: 'Kerala',
    displayText: 'Kerala',
  },
  {
    value: 'Madhya Pradesh',
    displayText: 'Madhya Pradesh',
  },
  {
    value: 'Maharashtra',
    displayText: 'Maharashtra',
  },
  {
    value: 'Manipur',
    displayText: 'Manipur',
  },
  {
    value: 'Meghalaya',
    displayText: 'Meghalaya',
  },
  {
    value: 'Mizoram',
    displayText: 'Mizoram',
  },
  {
    value: 'Nagaland',
    displayText: 'Nagaland',
  },
  {
    value: 'Odisha',
    displayText: 'Odisha',
  },
  {
    value: 'Punjab',
    displayText: 'Punjab',
  },
  {
    value: 'Rajasthan',
    displayText: 'Rajasthan',
  },
  {
    value: 'Sikkim',
    displayText: 'Sikkim',
  },
  {
    value: 'Tamil Nadu',
    displayText: 'Tamil Nadu',
  },
  {
    value: 'Telangana',
    displayText: 'Telangana',
  },
  {
    value: 'Tripura',
    displayText: 'Tripura',
  },
  {
    value: 'Uttarakhand',
    displayText: 'Uttarakhand',
  },
  {
    value: 'Uttar Pradesh',
    displayText: 'Uttar Pradesh',
  },
  {
    value: 'West Bengal',
    displayText: 'West Bengal',
  },
];

// TODO: State dropDown options
