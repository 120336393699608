import ReactDOM from 'react-dom';

const Modal = (props) => {
  const {
    show = true,
    children,
    onOutsideClick,
    additionalClasses = '',
    mobileModelCenter = false,
  } = props;

  const handleBackdropClick = () => {
    onOutsideClick();
  };

  return (
    <>
      {ReactDOM.createPortal(
        <>
          {show && (
            <div
              className={`modal ${show && 'modal--show'} ${
                mobileModelCenter && 'modal--center'
              }`}>
              <div className={`modal-content ${additionalClasses}`}>
                {children}
              </div>
              <div
                className='modal-backdrop'
                onClick={handleBackdropClick}></div>
            </div>
          )}
        </>,
        document.getElementById('modal-root')
      )}
    </>
  );
};

export default Modal;
