import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';

import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import { GridReadyEvent, ICellRendererParams } from 'ag-grid-community';

import TextInput from 'apps/booking/components/common/inputs/TextInput';
import NameCard from 'apps/booking/components/common/admin/NameCard';
import { awsBaseUrl } from 'constants/urls';
import ExpandRowCell from 'apps/admin/components/pages/ActiveLeads/cell-renderers/ExpandRowCell';
import ExpandedDetailSection, {
  DEFAULT_NULL_VALUE,
} from 'apps/admin/components/common/ExpandedDetailSection';
import { IRegisteredLead } from 'apps/admin/components/pages/ProjectPartnerLeads';
import { dateToDaythMonthYear } from 'utils/dateFormator';
import { TObjectId } from 'interfaces';
import { getAdminUser } from 'apps/admin/slices/adminSlice';
import Pagination from 'apps/admin/components/common/Pagination';
import { useGetProjectPartnerRegisteredLeadsMutation } from 'apps/admin/services/projectLeadsAPI';
import DropDownInput from 'apps/booking/components/common/inputs/DropDownInput';
import { UserRole } from 'constants/userRoles';
import SpinLoader from 'apps/booking/components/common/SpinLoader';
import Button from 'apps/booking/components/common/buttons/Button';
import {
  pageCount,
  searchFieldOptions,
} from 'apps/admin/components/pages/ProjectPartnerLeads/ActivePartnerLeads';
import { getSiteVisitStatus } from 'apps/admin/components/pages/ProjectPartnerSitevisits';

import styles from './styles.module.css';

interface IPartnerLeadsProps {
  projectId: TObjectId;
  registeredLeadsCount: number;
  registeredCountLoaded: boolean;
}

const RegisteredPartnerLeads = (props: IPartnerLeadsProps) => {
  const { projectId, registeredLeadsCount, registeredCountLoaded } = props;

  const [registeredPartnerLeads, setregisteredPArtnerLeads] = useState<
    IRegisteredLead[]
  >([]);
  const [gridApi, setGridApi] = useState(null) as any;
  const [loaded, setLoaded] = useState(false);
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [searchDetails, setSearchDetails] = useState({
    searchName: '',
    searchField: '',
    startDate: '',
    endDate: '',
  });

  const totalPages = Math.ceil(registeredLeadsCount / pageCount);

  //AG-grid Default column definition
  const defaultColDef = {
    sortable: false,
    filter: false,
    resizable: false,
    suppressMenu: true,
    editable: false,
    flex: 1,
  };

  const { register, unregister, setValue, handleSubmit } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });

  const user = useSelector(getAdminUser);
  const [getProjectPartnerRegisteredLeadsAPI] =
    useGetProjectPartnerRegisteredLeadsMutation();

  useEffect(() => {
    getRegisteredLeads(1);
  }, [projectId]);

  const getRegisteredLeads = async (
    currentPage: number,
    searchedData?: {
      searchName: string;
      searchField: string;
      startDate: string;
      endDate: string;
    }
  ) => {
    try {
      setLoaded(true);
      const payload = {
        projectId,
        pageCount,
        pageNumber: currentPage ? currentPage : 1,
        executiveId:
          user?.role === UserRole.EXECUTIVE || user?.role === UserRole.GRE
            ? (user?.id as TObjectId)
            : '',
        ...searchedData,
      };
      let response;
      response = await getProjectPartnerRegisteredLeadsAPI({
        ...payload,
      }).unwrap();
      setregisteredPArtnerLeads(response.data);
    } catch (err) {
      console.log(err);
    } finally {
      setLoaded(false);
    }
  };

  // Ag-grid global search
  const handleGridSearch = (event: string) => {
    gridApi.setQuickFilter(event);
  };

  // This function will automatically be called when ag grid is ready
  const onGridReady = (params: GridReadyEvent) => {
    setGridApi(params.api);
  };

  const getPhoneNumber = (phoneNumber: string) => {
    if (phoneNumber && phoneNumber.length < 5) {
      return `XXXXXX${phoneNumber}`;
    }
    return phoneNumber;
  };

  const getLeadImageUrl = (params: ICellRendererParams) => {
    return `${awsBaseUrl}/common/AZAlphabets/${params.data.cpLead.name[0].toUpperCase()}0.png`;
  };

  const getDetails = (partnerLeads: IRegisteredLead) => {
    return [
      {
        label: 'Unit Preference',
        value: partnerLeads.cpLead.propertyConfigs.join(', '),
      },
      // {
      //   label: 'Shceduling Attempts',
      //   value: partnerLeads.siteVisitChanged,
      // },
      {
        label: 'Registered on',
        value: dateToDaythMonthYear(partnerLeads.cpLead.registrationDate),
      },
    ];
  };

  const handleNextPage = () => {
    getRegisteredLeads(currentPageNumber + 1, searchDetails);
    setCurrentPageNumber(currentPageNumber + 1);
  };

  const handlePreviousChange = () => {
    getRegisteredLeads(currentPageNumber - 1, searchDetails);
    setCurrentPageNumber(currentPageNumber - 1);
  };

  const handleNavigationToFirstPage = () => {
    if (currentPageNumber !== 1) {
      getRegisteredLeads(1, searchDetails);
      setCurrentPageNumber(1);
    }
  };

  const handleNavigationToLastPage = () => {
    if (currentPageNumber !== totalPages) {
      getRegisteredLeads(totalPages, searchDetails);
      setCurrentPageNumber(totalPages);
    }
  };

  const handleSearchRegisterLeads = (searchedData: {
    searchName: string;
    searchField: string;
    startDate: string;
    endDate: string;
  }) => {
    setSearchDetails(searchedData);
    getRegisteredLeads(1, searchedData);
    setCurrentPageNumber(1);
  };

  return (
    <>
      <header>
        <form
          className={styles.searchContainer}
          onSubmit={handleSubmit(handleSearchRegisterLeads)}>
          <DropDownInput
            options={searchFieldOptions}
            defaultSelectedOption={searchFieldOptions[0]}
            lable='Select search type '
            register={register}
            setValue={setValue}
            unregister={unregister}
            name='searchField'
            propStyles={styles.inputBox}
          />
          <TextInput
            inputHTMLElementStyles={styles.searchInput}
            placeHolder='Search'
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              handleGridSearch(event.target.value);
            }}
            register={register}
            setValue={setValue}
            unregister={unregister}
            startIconName='search'
            startIconVariant='SECONDARY'
            label='Search Lead, Partner and SM name'
            name='searchName'
            propStyles={styles.inputBox}
          />
          <TextInput
            type='date'
            placeHolder='Select start date'
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              handleGridSearch(event.target.value);
            }}
            register={register}
            setValue={setValue}
            unregister={unregister}
            label='From'
            name='startDate'
            propStyles={styles.inputBox}
          />
          <TextInput
            type='date'
            placeHolder='Select end date'
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              handleGridSearch(event.target.value);
            }}
            register={register}
            setValue={setValue}
            unregister={unregister}
            label='To'
            name='endDate'
            propStyles={styles.inputBox}
          />
          <Button accent='primary' propStyles={styles.searchBtn} type='submit'>
            Search
          </Button>
        </form>
      </header>

      {loaded ? (
        <SpinLoader />
      ) : (
        <div
          className={`ag-theme-alpine ${styles.gridContainer}`}
          style={{ width: '100%', height: '100%' }}>
          <AgGridReact
            rowData={registeredPartnerLeads}
            defaultColDef={defaultColDef}
            onGridReady={onGridReady}
            domLayout={'autoHeight'}
            paginationPageSize={pageCount}
            masterDetail={true}
            animateRows={true}
            detailCellRenderer={'detailCellRenderer'}
            detailRowAutoHeight={true}
            frameworkComponents={{
              detailCellRenderer: ExpandedDetailSection,
            }}
            detailCellRendererParams={(params: ICellRendererParams) => ({
              details: getDetails(params.data),
            })}>
            <AgGridColumn flex={0.8} hide={true} field='cpName' />
            <AgGridColumn
              flex={0.5}
              hide={true}
              field={'cpLead.propertyConfigs'}
            />
            <AgGridColumn
              flex={0.8}
              cellClass='ag-grid-column-custom-cell'
              cellRendererFramework={ExpandRowCell}
              cellRendererParams={(params: ICellRendererParams) => params}
            />
            <AgGridColumn
              flex={2.5}
              headerName='Name'
              field={'cpLead.name'}
              cellRendererFramework={(params: ICellRendererParams) => (
                <NameCard
                  {...{
                    imgUrl: `${getLeadImageUrl(params)}`,
                    title: params.data.cpLead.name,
                    subtitle: getPhoneNumber(
                      params.data.cpLead.mobileLastFourDigits
                    ),
                  }}
                />
              )}
            />
            <AgGridColumn
              flex={2}
              headerName='Partner'
              field={'params.data.cpLead.user.name'}
              cellRendererFramework={(params: ICellRendererParams) => (
                <p className={styles.cpName}>
                  {params.data.cpLead.user.name || DEFAULT_NULL_VALUE}
                </p>
              )}
            />
            <AgGridColumn
              flex={2}
              headerName='Firm'
              cellClass='ag-cell-no-text-overflow ag-cell-wrap-text ag-cell-value'
              cellRendererFramework={(params: ICellRendererParams) => {
                const displayName =
                  params.data &&
                  params.data.cpLead &&
                  params.data.cpLead.company[0]
                    ? params.data.cpLead.company[0].displayName
                    : DEFAULT_NULL_VALUE;

                return <p className={styles.firmName}>{displayName}</p>;
              }}
            />
            <AgGridColumn
              flex={2}
              headerName='SM'
              field={'smUser.name'}
              cellRendererFramework={(params: ICellRendererParams) => (
                <p className={styles.cpName}>
                  {params.data.smUser?.name || DEFAULT_NULL_VALUE}
                </p>
              )}
            />
            <AgGridColumn
              flex={2}
              headerName='Status'
              cellRendererFramework={(params: ICellRendererParams) => (
                <p className={styles.cpName}>
                  {params.data.scheduledSiteVisit
                    ? getSiteVisitStatus(params.data.scheduledSiteVisit.status)
                    : DEFAULT_NULL_VALUE}
                </p>
              )}
            />
            <AgGridColumn
              flex={2}
              headerName='Sitevisit on'
              cellRendererFramework={(params: ICellRendererParams) => (
                <p className={styles.cpName}>
                  {params.data.scheduledSiteVisit
                    ? dateToDaythMonthYear(
                        params.data.scheduledSiteVisit.visitDate
                      )
                    : DEFAULT_NULL_VALUE}
                </p>
              )}
            />
            <AgGridColumn
              flex={2}
              headerName='QR shared'
              cellClass='relata-ag-grid-cell-justify-center'
              cellRendererFramework={(params: ICellRendererParams) => (
                <p className={styles.cpName}>{params.data.qrCodeShared}</p>
              )}
            />
          </AgGridReact>
          <Pagination
            totalRowCount={registeredLeadsCount || 0}
            rowCount={registeredPartnerLeads.length}
            currentPage={currentPageNumber - 1}
            onPageChange={handlePreviousChange}
            onNextPage={handleNextPage}
            onNavigateTofirstPage={handleNavigationToFirstPage}
            onNavigationToLastPage={handleNavigationToLastPage}
            loaded={registeredCountLoaded}
          />
        </div>
      )}
    </>
  );
};

export default RegisteredPartnerLeads;
