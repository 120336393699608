import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { GridReadyEvent, ICellRendererParams } from 'ag-grid-community';
import { AgGridReact, AgGridColumn } from 'ag-grid-react';
import LiveSinceDateCell from 'apps/admin/components/common/dates/LiveSinceDateCell';
import { IProject } from 'apps/admin/components/pages/SuperAdminDashboard/SuperAdminProjectList';
import { IUser } from 'apps/admin/interfaces';
import NameCard from 'apps/booking/components/common/admin/NameCard';
import TextInput from 'apps/booking/components/common/inputs/TextInput';
import { UserRole } from 'constants/userRoles';
import RemoveButtonCell from 'apps/admin/components/pages/SuperAdminDashboard/SuperAdminProjectList/cell-renderers/BlockButtonCell';
import ExpandedLeadDetails from 'apps/admin/components/pages/ProjectExecutives/ProjectCollaboratorList/ExpandedLeadDetails';
import ExpandRowCell from 'apps/admin/components/pages/ActiveLeads/cell-renderers/ExpandRowCell';
import { getAdminUser } from 'apps/admin/slices/adminSlice';

import styles from './styles.module.css';

interface IProjectCollaboratorListProps {
  project: IProject;
  onProjectUpdate: (updateProject: IProject) => void;
}

const ProjectCollaboratorList = (props: IProjectCollaboratorListProps) => {
  const { project, onProjectUpdate } = props;

  const [gridApi, setGridApi] = useState(null) as any;

  const user = useSelector(getAdminUser);

  //AG-grid Default column definition
  const defaultColDef = {
    sortable: false,
    filter: false,
    resizable: false,
    suppressMenu: true,
  };

  //Ag-grid global search
  const handleGridSearch = (event: string) => {
    gridApi.setQuickFilter(event);
  };

  const secondaryCompanies = useMemo(() => {
    return project.secondaryCompanies.map(
      (secondaryCompany) => secondaryCompany.company
    );
  }, [project]);

  //This function will automatically be called when ag grid is ready
  const onGridReady = (params: GridReadyEvent) => {
    setGridApi(params.api);
  };

  return (
    <div>
      <header>
        <div className={styles.searchContainer}>
          <TextInput
            type='text'
            inputHTMLElementStyles={styles.searchInput}
            placeHolder='Search Company name'
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              handleGridSearch(event.target.value);
            }}
            startIconName='search'
            startIconVariant='SECONDARY'
          />
          {/* <Icon name='search' propStyles={styles.searchIcon} /> */}
        </div>
      </header>

      <main>
        <div
          className='ag-theme-alpine'
          style={{ height: '100%', width: '100%' }}>
          <AgGridReact
            rowData={secondaryCompanies}
            onGridReady={onGridReady}
            defaultColDef={defaultColDef}
            domLayout={'autoHeight'}
            pagination={true}
            paginationPageSize={10}
            masterDetail={true}
            animateRows={true}
            detailCellRenderer={'detailCellRenderer'}
            detailRowAutoHeight={true}
            frameworkComponents={{
              detailCellRenderer: ExpandedLeadDetails,
            }}>
            <AgGridColumn flex={0.8} hide={true} field='cpName'></AgGridColumn>
            <AgGridColumn
              flex={0.8}
              cellClass='ag-grid-column-custom-cell'
              cellRendererFramework={ExpandRowCell}
              cellRendererParams={(params: ICellRendererParams) =>
                params
              }></AgGridColumn>
            <AgGridColumn
              hide={true}
              headerName=''
              field='name'
              cellRendererFramework={(params: ICellRendererParams) => (
                <p>{params.value}</p>
              )}></AgGridColumn>
            <AgGridColumn
              flex={3}
              headerName='Company names'
              field='name'
              cellRendererFramework={(params: ICellRendererParams) => {
                return (
                  <div className={styles.organizationColumnCell}>
                    <NameCard
                      {...{
                        title: params.data.name,
                        subtitle: params.data.rera,
                        disabled: params.data.disabled,
                      }}
                    />
                  </div>
                );
              }}></AgGridColumn>

            {/* <AgGridColumn
              flex={2}
              headerName='Status'
              field='status'
              cellRendererFramework={(params: ICellRendererParams) => {
                return (
                  <div>
                    <p className={styles.displayName}>{params.data.status}</p>
                  </div>
                );
              }}></AgGridColumn> */}
            <AgGridColumn
              flex={2}
              headerName='Live Since'
              field='createdAt'
              cellRendererFramework={LiveSinceDateCell}
              cellRendererParams={(params: ICellRendererParams) => {
                return {
                  liveSinceDate: params.data.createdAt,
                  disabled: params.data.disabled,
                };
              }}></AgGridColumn>

            <AgGridColumn
              flex={2}
              headerName='Executives'
              cellRendererFramework={(params: ICellRendererParams) => {
                const users = params.data.users as IUser[];
                const executives = users?.filter(
                  (user) => user.role === UserRole.EXECUTIVE
                );
                return (
                  <p className={`${styles.displayName} ${styles.noOfProjects}`}>
                    {executives?.length}
                  </p>
                );
              }}></AgGridColumn>
            {user?.role === UserRole.SUPERADMIN && (
              <AgGridColumn
                flex={1}
                headerName='Remove'
                headerClass='relata-ag-grid-header-small-font'
                cellRendererFramework={RemoveButtonCell}
                cellRendererParams={(params: ICellRendererParams) => {
                  return {
                    projectId: project.id,
                    companyToRemove: params.data,
                    secondaryCompanies: project.secondaryCompanies,
                    onProjectUpdate: onProjectUpdate,
                  };
                }}
              />
            )}
          </AgGridReact>
        </div>
      </main>
    </div>
  );
};

export default ProjectCollaboratorList;
