import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';

import Icon from './common/Icon';

import { URLS } from '../../../constants/urls';

const CURRENT_STEP_STATE = {
  ACTIVE: 'ACTIVE',
  COMPLETED: 'COMPLETED',
  INACTIVE: 'INACTIVE',
};

export const SIDEBAR_ITEM_INDEX = {
  [URLS.UNIT_SELECTION]: 0,
  [URLS.UNIT_DETAILS]: 1,
  [URLS.COST_SHEET]: 2,
  [URLS.PAYMENT_PLAN]: 3,
  [URLS.KYC]: 4,
  [URLS.PAYMENT]: 5,
  [URLS.KYC_DETAILS]: 6,
  [URLS.AGREEMENT]: 7,
};

export const getProgressByValue = (value) => {
  return Object.keys(SIDEBAR_ITEM_INDEX).find(
    (progress) => SIDEBAR_ITEM_INDEX[progress] === value
  );
};

const SidebarItem = (props) => {
  const {
    redirectPath = '/',
    labelText,
    desc,
    icon,
    additionClasses = '',
  } = props;
  const { pathname } = useLocation();
  const currentPath = pathname.split('/').pop();

  let currentStepState = CURRENT_STEP_STATE.INACTIVE;
  if (
    redirectPath === currentPath ||
    (redirectPath === URLS.UNIT_DETAILS && currentPath === URLS.UNIT_SELECTION)
  ) {
    currentStepState = CURRENT_STEP_STATE.ACTIVE;
  } else if (
    SIDEBAR_ITEM_INDEX[redirectPath] < SIDEBAR_ITEM_INDEX[currentPath]
  ) {
    currentStepState = CURRENT_STEP_STATE.COMPLETED;
  }

  return (
    <>
      <NavLink
        to={redirectPath}
        className={`sidebar-item 
            ${
              currentStepState === CURRENT_STEP_STATE.ACTIVE &&
              'sidebar-item--active'
            }
            ${
              currentStepState === CURRENT_STEP_STATE.COMPLETED &&
              'sidebar-item--completed'
            } ${additionClasses}
        `}>
        <span className='sidebar-label-container'>
          <span className='sidebar-label-container__label'>{labelText}</span>
          <span className='sidebar-label-container__desc'>{desc}</span>
        </span>
        <span className='icon-container icon-container--with-background'>
          <Icon name={icon} />
        </span>
        <span className='sidebar-status-dot-container'>
          <span className='sidebar-status-dot-wrapper'>
            <span className='sidebar-status-dot'></span>
          </span>
        </span>
      </NavLink>
    </>
  );
};

export default SidebarItem;
