import { useState } from 'react';

import PdfDownloadButton from '../common/PdfDownloadButton';

import { usePaymentPlanPdfMutation } from '../../../../api/units1';
import { downloadImage } from '../../../../utils/downloadImage';

const PaymentPlanPdfDownloadButton = (props) => {
  const { unitCartId } = props;
  const [isDownloadInProgress, setIsDownloadInProgress] = useState(false);

  const [paymentPlanPdfAPI] = usePaymentPlanPdfMutation();

  const onDownloadBtnClick = async () => {
    setIsDownloadInProgress(true);
    const { data: { url } = {} } = await paymentPlanPdfAPI(unitCartId);
    downloadImage(url, 'payment-plan.pdf');
    setIsDownloadInProgress(false);
  };
  return (
    <PdfDownloadButton
      onDownloadBtnClick={onDownloadBtnClick}
      isLoading={isDownloadInProgress}
    />
  );
};

export default PaymentPlanPdfDownloadButton;
