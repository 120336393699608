import { useState } from 'react';

import { GridReadyEvent, ICellRendererParams } from 'ag-grid-community';
import { AgGridReact, AgGridColumn } from 'ag-grid-react';

import ExpandedDetailSection, {
  DEFAULT_NULL_VALUE,
} from 'apps/admin/components/common/ExpandedDetailSection';
import TextInput from 'apps/booking/components/common/inputs/TextInput';
import ExpandRowCell from 'apps/admin/components/pages/ActiveLeads/cell-renderers/ExpandRowCell';
import NameCard from 'apps/booking/components/common/admin/NameCard';
import { IPartner } from 'apps/admin/components/pages/ProjectPartners';
import { dateToDaythMonthYear } from 'utils/dateFormator';
import ProjectListCell from 'apps/admin/components/pages/CollaboratorsList/CollaboratorsStatus/ProjectListCell';

import styles from './styles.module.css';

interface IPartnersProps {
  channelPartners: IPartner[];
}

const Partners = (props: IPartnersProps) => {
  const { channelPartners } = props;

  const [gridApi, setGridApi] = useState(null) as any;

  //AG-grid Default column definition
  const defaultColDef = {
    sortable: false,
    filter: false,
    resizable: false,
    suppressMenu: true,
    editable: false,
    flex: 1,
  };

  const getDetails = (channelPartner: IPartner) => {
    return [
      {
        label: 'Phone Number',
        value: channelPartner.cpMobile,
      },
      {
        label: 'Email',
        value: channelPartner.cpEmail,
      },
      {
        label: 'Projects',
        value: channelPartner.projects?.length,
      },
      {
        label: 'SM',
        value: channelPartner.smWithProject?.length,
      },
      {
        label: 'Firm Name',
        value: channelPartner.cpFirmName,
      },
      {
        label: 'Firm URL',
        value: channelPartner.cpFirmURL,
      },
      {
        label: 'Firm GST Number',
        value: channelPartner.cpFirmGST,
      },
      {
        label: 'Firm PAN Number',
        value: channelPartner.cpFirmPAN,
      },
      // {
      //   label: 'RERA Details',
      //   value: channelPartner.reraDetails,
      // },
    ];
  };

  // Ag-grid global search
  const handleGridSearch = (event: string) => {
    gridApi.setQuickFilter(event);
  };

  // This function will automatically be called when ag grid is ready
  const onGridReady = (params: GridReadyEvent) => {
    setGridApi(params.api);
  };

  const getProjectList = (
    projects?: {
      project: string[];
      registerLeads: number;
      taggedLeads: number;
    }[]
  ) => {
    return projects?.map((project) => project.project) || [];
  };

  return (
    <>
      <header>
        <div className={styles.searchContainer}>
          <TextInput
            type='text'
            inputHTMLElementStyles={styles.searchInput}
            placeHolder='Search Name & Email'
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              handleGridSearch(event.target.value);
            }}
            startIconName='search'
            startIconVariant='SECONDARY'
          />
        </div>
      </header>

      <div
        className={`ag-theme-alpine ${styles.gridContainer}`}
        style={{ width: '100%', height: '100%' }}>
        <AgGridReact
          rowData={channelPartners}
          defaultColDef={defaultColDef}
          domLayout={'autoHeight'}
          pagination={true}
          paginationPageSize={10}
          masterDetail={true}
          animateRows={true}
          detailCellRenderer={'detailCellRenderer'}
          detailRowAutoHeight={true}
          onGridReady={onGridReady}
          frameworkComponents={{
            detailCellRenderer: ExpandedDetailSection,
          }}
          detailCellRendererParams={(params: ICellRendererParams) => ({
            details: getDetails(params.data),
          })}>
          <AgGridColumn
            flex={0.8}
            cellClass='ag-grid-column-custom-cell'
            cellRendererFramework={ExpandRowCell}
            cellRendererParams={(params: ICellRendererParams) => params}
          />
          <AgGridColumn
            flex={2.5}
            headerName='Name'
            field='cpName'
            cellRendererFramework={(params: ICellRendererParams) => (
              <div className={styles.projectsColumnDataContainer}>
                <NameCard
                  {...{
                    title: params.data.cpName,
                    subtitle: params.data?.cpEmail,
                  }}
                />
              </div>
            )}
          />
          <AgGridColumn
            flex={1}
            headerName='email'
            field='cpEmail'
            hide={true}
            cellRendererFramework={(params: ICellRendererParams) => (
              <p className={styles.cpName}>{params.data.cpEmail}</p>
            )}
          />
          <AgGridColumn
            flex={2}
            headerName='Working Since'
            cellRendererFramework={(params: ICellRendererParams) => (
              <p className={styles.cpName}>
                {params.data.projectRegistration
                  ? dateToDaythMonthYear(params.data.projectRegistration)
                  : DEFAULT_NULL_VALUE}
              </p>
            )}
          />
          <AgGridColumn
            flex={2}
            headerName='Projects'
            cellClass='relata-ag-grid-cell-justify-center'
            cellRendererFramework={ProjectListCell}
            cellRendererParams={(params: ICellRendererParams) => ({
              projectList: getProjectList(params.data.projects),
            })}
          />
          <AgGridColumn
            flex={2}
            headerName='Collaterals Shared'
            cellClass='relata-ag-grid-cell-justify-center'
            cellRendererFramework={(params: ICellRendererParams) => (
              <p className={styles.counts}>{params.data.collateralsShared}</p>
            )}
          />
          <AgGridColumn
            flex={2}
            headerName='Site Visits'
            cellClass='relata-ag-grid-cell-justify-center'
            cellRendererFramework={(params: ICellRendererParams) => (
              <p className={styles.counts}>{params.data.siteVisitsDone}</p>
            )}
          />
          <AgGridColumn
            flex={2}
            headerName='Registered Leads'
            cellClass='relata-ag-grid-cell-justify-center'
            cellRendererFramework={(params: ICellRendererParams) => (
              <p className={styles.counts}>{params.data.leadsRegistered}</p>
            )}
          />
          <AgGridColumn
            flex={2}
            headerName='Assigned Leads'
            cellClass='relata-ag-grid-cell-justify-center'
            cellRendererFramework={(params: ICellRendererParams) => (
              <p className={styles.counts}>{params.data.assignedLeads}</p>
            )}
          />
          <AgGridColumn
            flex={2}
            headerName='Lost Leads'
            cellClass='relata-ag-grid-cell-justify-center'
            cellRendererFramework={(params: ICellRendererParams) => (
              <p className={styles.counts}>
                {Math.abs(
                  params.data.siteVisitsDone - params.data.assignedLeads
                ) || 0}
              </p>
            )}
          />
        </AgGridReact>
      </div>
    </>
  );
};

export default Partners;
