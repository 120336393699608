import { useState } from 'react';

import { IProject } from 'apps/admin/components/pages/SuperAdminDashboard/SuperAdminProjectList';
import ConfirmationModal, {
  IConfirmationModal,
} from 'apps/booking/components/common/genericModals/ConfirmationModal';
import Icon from 'apps/booking/components/common/Icon';
import useToast from 'hooks/useToast';
import { useDeleteDemoRoomMutation } from 'apps/admin/services/projectsAPISlice';

import styles from '../styles.module.css';

interface IRemoveDemoRoomCell {
  demoRoomId: string;
  projectId: string;
  onDemoRoomDelete: (updateProject: IProject) => {};
  disabled: boolean;
}

const RemoveDemoRoomCell = (props: IRemoveDemoRoomCell) => {
  const { projectId, demoRoomId, onDemoRoomDelete, disabled } = props;

  const [confirmBlockProject, setConfirmBlockProject] =
    useState<IConfirmationModal>();

  const [deleteDemoRoom] = useDeleteDemoRoomMutation();
  const [addToast] = useToast();

  const handleDeleteDemoRoom = () => {
    setConfirmBlockProject({
      accent: 'DANGER',
      heading: 'Do you want to DELETE the demo room?',
      onModalClose: () => setConfirmBlockProject(undefined),
      onResponse: async (confirmationResponse: any) => {
        try {
          await confirmationResponse;

          const updatedProject = await deleteDemoRoom({
            projectId,
            demoRoomId,
          }).unwrap();

          onDemoRoomDelete(updatedProject.data);

          addToast({
            type: 'SUCCESS',
            primaryMessage: 'Demo Room deleted Successfully',
          });
        } catch (err) {
          const errMsg = (err as any).data.message;
          addToast({
            type: 'ERROR',
            primaryMessage: errMsg,
            secondaryMessage: 'Demo Room delete failed',
          });
        }
      },
    });
  };

  return (
    <>
      {confirmBlockProject && <ConfirmationModal {...confirmBlockProject} />}
      <Icon
        name='highlight_off'
        propStyles={`${styles.actionIcon} ${
          disabled && 'click-disabled--icon'
        } `}
        onClick={() => handleDeleteDemoRoom()}
      />
    </>
  );
};

export default RemoveDemoRoomCell;
