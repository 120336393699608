import { useSelector, useDispatch } from 'react-redux';
import { useState } from 'react';

import LoginWithOtp from './LoginWithOtp';
import RegisterForm from './RegisterForm';
import { isEmpty } from '../../../../../utils/utils';

import {
  getUser,
  setUser,
  getShowAuthForms,
  setShowAuthForms,
} from '../../../slices/auth';
import { setUnitCart } from '../../../slices/selectedUnit';
import { getProject } from 'apps/booking/slices/projectSlice';

const Auth = () => {
  const dispatch = useDispatch();

  const showAuthForm = useSelector(getShowAuthForms);
  const [showRegisterFrom, setShowRegisterFrom] = useState(false);
  const user = useSelector(getUser);
  const project = useSelector(getProject);
  const isLoggedIn = !isEmpty(user);

  const handleToggleLoginLogout = () => {
    if (isLoggedIn) {
      // Logout
      // TODO: Handle logout properly
      localStorage.removeItem('user');
      dispatch(setUser({}));
      // TODO: Not good practice - reset unitCart on user update or unit update
      dispatch(setUnitCart({}));
      if (!project?.booking?.thirdPartyIntegrationEnabled) {
        dispatch(setShowAuthForms(true));
      }
    } else {
      // Login
      dispatch(setShowAuthForms(true));
    }
  };
  return (
    <>
      <button
        className='btn btn--accent bold--Text'
        onClick={() => handleToggleLoginLogout()}>
        <span>{isLoggedIn ? 'Logout' : 'Login'}</span>
      </button>
      {showAuthForm && (
        <div className={`modal ${showAuthForm && 'modal--show'}`}>
          <div className='modal-content'>
            <div className='login'>
              <div className='login-desc'>
                <span className='login-desc__title'>
                  REGISTER/LOGIN TO TRACK YOUR PROGRESS
                </span>
                <span className='login-desc__desc'>
                  {project?.name} , India's First Alexa Enabled Homes
                </span>
              </div>
              {!showRegisterFrom && <LoginWithOtp />}
              {showRegisterFrom && (
                <RegisterForm
                  showAuthForm={showAuthForm}
                  setShowAuthForms={setShowAuthForms}
                  setShowRegisterFrom={setShowRegisterFrom}
                  mobile={mobile}
                />
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Auth;
