import FadeInFadeOutAnim from '../../components/common/FadeInFadeOutAnim';

const DashboardContent = (props) => {
  const {
    headerComponent: HeaderComponent,
    contentBodyComponent: ContentBodyComponent,
  } = props;

  return (
    <div className='content content--dashbaord'>
      {HeaderComponent && <HeaderComponent />}
      <div className='content-body content-body--dashbaord'>
        <FadeInFadeOutAnim width='100%' height='100%'>
          {ContentBodyComponent && <ContentBodyComponent />}
        </FadeInFadeOutAnim>
      </div>
    </div>
  );
};

export default DashboardContent;
