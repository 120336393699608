import { useState } from 'react';

import Icon from 'apps/booking/components/common/Icon';
import PartnerLeadDetailsModal from 'apps/admin/components/pages/ProjectPartnerLeads/modals/PartnerLeadDetailsModal';

interface IPartnerLeadDetailsCellProps {
  moreDetails: {
    label: string;
    value: string;
  }[];
  modalHeading: string;
}

const PartnerLeadDetailsCell = (props: IPartnerLeadDetailsCellProps) => {
  const { moreDetails, modalHeading } = props;

  const [leadDetailsModalShown, setLeadModalShown] = useState(false);

  return (
    <>
      {leadDetailsModalShown && (
        <PartnerLeadDetailsModal
          onModalClose={() => setLeadModalShown(false)}
          moreDetails={moreDetails}
          modalHeading={modalHeading}
        />
      )}
      <Icon
        name='details'
        propStyles='actionIcon'
        onClick={() => setLeadModalShown(true)}
      />
    </>
  );
};

export default PartnerLeadDetailsCell;
