import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import UnitSummaryCard from '../UnitSummaryCard';
import BlockUnitButton from '../common/BlockUnitButton';
import PaymentPlanPlank from './PaymentPlanPlank';
import PaymentPlanPdfDownloadButton from './PaymentPlanPdfDownloadButton';

import { useUpdateUnitCartMutation } from '../../../../api/unitCart';
import {
  getUnitCart,
  getIsFetchingUnitCart,
  setUnitCart,
  getSelectedScheme,
  getSelectedEOI,
} from '../../slices/selectedUnit';
import {
  getAppSliceIsFetching,
  setAppSliceIsFetching,
} from '../../../../slices/appSlice';
import { setBookingRoutesCurrentPage } from 'apps/booking/routers/BookingRoutes/slice';

import { isEmpty } from '../../../../utils/utils';

import { SIDEBAR_ITEM_INDEX } from '../SidebarItem';
import { URLS } from '../../../../constants/urls';
import { BOOKED } from '../../../../constants/status';

const PaymentPlan = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const unitCart = useSelector(getUnitCart);
  const isFetchingUnitCart = useSelector(getIsFetchingUnitCart);
  const appSliceIsFetching = useSelector(getAppSliceIsFetching);
  const selectedScheme = useSelector(getSelectedScheme);
  const eoi = useSelector(getSelectedEOI);

  const [updateUnitCartAPI] = useUpdateUnitCartMutation();

  const updateSelectedPaymentPlanOnServer = async () => {
    const unitCartBody = {
      id: unitCart.id,
      selectedSchemeId: selectedScheme._id,
      progress:
        unitCart.progress < SIDEBAR_ITEM_INDEX[URLS.PAYMENT_PLAN]
          ? SIDEBAR_ITEM_INDEX[URLS.PAYMENT_PLAN]
          : unitCart.progress,
      unit: unitCart.unit.id,
      user: unitCart.user.id,
      eoi: eoi.id,
    };
    const { data: unitCartdata, error: unitCartError } =
      await updateUnitCartAPI({ ...unitCartBody }).unwrap();

    if (!isEmpty(unitCartError)) {
      console.error('unitCartError', unitCartError);
      alert('unitCartError', unitCartError);
      return false;
    }

    if (!isEmpty(unitCartdata)) {
      dispatch(setUnitCart(unitCartdata));
    }
    return true;
  };

  const handleProceed = async () => {
    if (unitCart.unit.status !== BOOKED) {
      // PaymentPlan unchangable once unit is booked.
      dispatch(setAppSliceIsFetching(true));
      const handleUnitCartStatus = await updateSelectedPaymentPlanOnServer();
      dispatch(setAppSliceIsFetching(false));

      if (!handleUnitCartStatus) {
        return false;
      }
    }
    dispatch(setBookingRoutesCurrentPage(URLS.KYC));
  };

  if (isFetchingUnitCart) {
    return <div>Loading...</div>;
  }

  return (
    <div className='payment-plan'>
      <div className='payment-plan__body'>
        <div
          comment='ref={scroller}'
          className='payment-plan-milstone-table-container'>
          <PaymentPlanPlank />
          {/* <div
            ref={seeMoreOverlayBottomElm}
            className="payment-plan-milstone-table__seemore-overlay-bottom"> */}
          {/* TODO: Auto detect and show See more when content is overflowing*/}
          {/* See more */}
          {/* </div> */}
        </div>
        <UnitSummaryCard />
      </div>
      <footer className='payment-plan__footer'>
        <button
          type='button'
          className='btn btn--transparent'
          onClick={() => {
            dispatch(setBookingRoutesCurrentPage(URLS.COST_SHEET));
          }}>
          Back to Cost Sheet
        </button>
        <div className='payment-plan__footer-action-btns'>
          {/*<BlockUnitButton />*/}
          <button
            type='button'
            className={`btn btn--accent payment-plan__footer-proceed-btn ${
              appSliceIsFetching && 'loading-spin-container'
            }`}
            onClick={handleProceed}>
            <span>Proceed</span>
            <span className='ic2-fa-spin-blue'></span>
          </button>
          {/*<PaymentPlanPdfDownloadButton unitCartId={unitCart.id} />*/}
        </div>
      </footer>
    </div>
  );
};

export default PaymentPlan;
