import { useSelector } from 'react-redux';
import {
  getSelectedEOI,
  getSelectedScheme,
  getUnitCart,
} from '../../../slices/selectedUnit';
import { isEmpty, toIndianRupee } from '../../../../../utils/utils';
import { MILESTONE_KEY, EOI_PAYMENT } from 'apps/booking/constants';
import { ONE_LAKH } from 'constants/index';
import { useLazyGetNextPayableAmountForUnitCartQuery } from 'apps/booking/services/paymentsAPISlice';
import { useEffect } from 'react';

const BookingAmountCard = (props) => {
  const unitCart = useSelector(getUnitCart);
  const [getNextPayableAmountForUnitCartAPI, { data: payable = {} }] =
    useLazyGetNextPayableAmountForUnitCartQuery(unitCart.id);
  // const selectedScheme = useSelector(getSelectedScheme);
  // const selectedEOI = useSelector(getSelectedEOI);
  // const first_milestone_payable =
  //   selectedScheme.milestones[1][MILESTONE_KEY.NET_TAXABLE_AMOUNT];
  // const blocking_amount = first_milestone_payable - (selectedEOI.amount | 0);

  // if (!selectedScheme) {
  //   return null;
  // }

  // const firstMilestone =
  //   selectedScheme.milestones.slice().sort((firstEl, secondEl) => {
  //     return parseInt(firstEl.displayorder) > parseInt(secondEl.displayorder)
  //       ? 1
  //       : -1;
  //   })[0] || 0;

  // # 10% of total cost of first milestone

  useEffect(() => {
    if (isEmpty(payable)) {
      getNextPayableAmountForUnitCartAPI(unitCart.id);
    }
  }, [unitCart]);

  if (isEmpty(payable) || payable.amount == 0) {
    return null;
  }

  return (
    <div>
      <div className='info-card'>
        <header className='info-card__header'>
          {unitCart.unit.status === 'BOOKED'
            ? 'Milestone Amount'
            : unitCart.unit.status === 'BLOCKED'
            ? 'Booking Amount'
            : 'Blocking Amount'}
        </header>
        <div className='info-card__body bold'>
          {toIndianRupee(payable.amount)}
        </div>
      </div>
    </div>
  );
};

export default BookingAmountCard;
