export interface IRouteUrls {
  // Booking engine
  BOOKING_BASE: string;
  UNIT_SELECTION: string;
  UNIT_DETAILS: string;
  COST_SHEET: string;
  PAYMENT_PLAN: string;
  KYC: string;
  PAYMENT: string;
  KYC_DETAILS: string;
  AGREEMENT: string;

  // Dashboard
  DASHBOARD_BASE: string;

  // Project analytics page
  PROJECT_ANALYTICS: string;
  ANALYTICS_DASHBOARD: string;

  // Site Visit form
  SITE_VISIT_FORM: string;

  // User role based dashboards
  SUPERADMIN: string;
  ADMIN: string;
  EXECUTIVE: string;
  BUSINESSHEAD: string;
  CLUSTERHEAD: string;
  TEAMLEAD: string;
  SITEHEAD: string;
  GRE: string;
}

export const URLS: IRouteUrls = {
  // Booking engine
  BOOKING_BASE: '/booking/:projectId',
  UNIT_SELECTION: 'unit-selection',
  UNIT_DETAILS: 'unit-details',
  COST_SHEET: 'cost-sheet',
  PAYMENT_PLAN: 'payment-plan',
  KYC: 'kyc',
  PAYMENT: 'payment',
  KYC_DETAILS: '/booking/kyc-details',
  AGREEMENT: '/booking/agreement',

  // Dashboard
  DASHBOARD_BASE: '/dashboard/:projectId',

  // Project analytics page
  PROJECT_ANALYTICS: '/admin/projects/analytics',
  ANALYTICS_DASHBOARD: '/admin/projects/analytics',

  // Site Visit form
  SITE_VISIT_FORM: '/forms/site-visit',

  // User role based dashboards
  SUPERADMIN: '/admin/overview',
  ADMIN: '/admin/overview',
  EXECUTIVE: '/admin/projects/analytics',
  BUSINESSHEAD: '/admin/projects/analytics',
  CLUSTERHEAD: '/admin/projects/analytics',
  TEAMLEAD: '/admin/projects/analytics',
  SITEHEAD: '/admin/projects/analytics',
  GRE: 'admin/projects/analytics',
};

interface IAppEnvAndBaseUrls {
  local: string;
  development: string;
  staging: string;
  production: string;
}

const serverByAppEnv: IAppEnvAndBaseUrls = {
  local: 'http://127.0.0.1:3000/',
  // local: 'https://api.relata.cognilements.com',
  // local: 'https://api.dev.app.relata.io',
  development: 'https://api.dev.app.relata.io',
  staging: 'https://api.staging.app.relata.io',
  production: 'https://api.app.relata.io',
};

const bucketNameByAppEnv: IAppEnvAndBaseUrls = {
  local: 'relata',
  development: 'relata',
  staging: 'relata-staging',
  production: 'relata',
};

const clubRhodiumBaseUrls: IAppEnvAndBaseUrls = {
  local: 'http://127.0.0.1:3000',
  development: 'https://dev.c45.app.relata.io',
  staging: 'https://dev.c45.app.relata.io',
  production: 'https://c45.app.relata.io',
};

const awsBaseUrlByAppEnv: IAppEnvAndBaseUrls = {
  local: 'https://relata.s3.ap-south-1.amazonaws.com',
  development: 'https://relata.s3.ap-south-1.amazonaws.com',
  staging: 'https://relata-staging.s3.ap-south-1.amazonaws.com',
  production: 'https://relata.s3.ap-south-1.amazonaws.com',
};

const appEnv = process.env.REACT_APP_ENV as keyof IAppEnvAndBaseUrls;

if (!Object.keys(serverByAppEnv).includes(appEnv)) {
  console.error('REACT_APP_ENV not declared');
}

// export const baseUrl = 'https://api.relata.cognilements.com/';

export const awsBaseUrl =
  awsBaseUrlByAppEnv[appEnv] || awsBaseUrlByAppEnv['local'];

export const s3BucketName =
  bucketNameByAppEnv[appEnv] || bucketNameByAppEnv['local'];

export const baseUrl = serverByAppEnv[appEnv] || serverByAppEnv['local'];

export const CLUB_RHODIUM_BASE_URL =
  clubRhodiumBaseUrls[appEnv] || clubRhodiumBaseUrls['local'];

export const userProfileDefaultImage =
  'https://relata.s3.ap-south-1.amazonaws.com/cpApp/public/user1.jpg';

export const fallbackImageUrl = 'https://picsum.photos/seed/picsum/500/250';

export const floorPlanFallback =
  'https://relata-temp.s3.ap-south-1.amazonaws.com/embassy/assets/floor-plan-fallback.jpg';

// Embassy
export const refreshTokenEmbassyUrl =
  'https://test.salesforce.com/services/oauth2/token?grant_type=refresh_token&refresh_token=5Aep8613wlbvVohodL0zmG9OdXn2bFeyRYSi8e7Mg1p2N05YyPkaAR5YdUNaJKMbYY5IxvhU_WpgZ..LWY_XY5B&client_id=3MVG9Gdzj3taRxuNZGYlwtt.EvruuoQJVX4ykpt3kSqTFCGrWP_gEWQThbjvEbIxMOsayLSlmGaPPafa1LX75&client_secret=31ABF63DAEB0E10194153E314F0CFD7EE089FEBD53D59938A6A7DA6116CC8676';

export const insertLeadEmbassyUrl =
  'https://embassygroup--uatrelease.my.salesforce.com//services/apexrest/embassyOne/LeadFlows';

export const getCostDetailsEmbassyUrl =
  'https://embassygroup--uatrelease.my.salesforce.com/services/apexrest/embassyOne/CBU/';

export const blockUnitEmbassyUrl =
  'https://embassygroup--uatrelease.my.salesforce.com/services/apexrest/EmbassyOne/leadProcesses';

// User account
export const updateUserEmbassyUrl =
  'https://embassygroup--uatrelease.my.salesforce.com/services/apexrest/embassyOne/PAP/';

export const profileFallback =
  'https://relata-temp.s3.ap-south-1.amazonaws.com/embassy/assets/kyc-profile-fallback.png';

export const dashboardHeadingBannerUrl =
  'https://relata-temp.s3.ap-south-1.amazonaws.com/embassy/assets/login-background.jpg';

export const edgeLogo =
  'https://relata-temp.s3.ap-south-1.amazonaws.com/embassy/assets/embassy-primary-logo.svg';
export const embassyLogo =
  'https://relata-temp.s3.ap-south-1.amazonaws.com/embassy/assets/embassy.png';

// Helios
export const masterPlanUrl =
  'https://relata.s3.ap-south-1.amazonaws.com/projects/6372189286ee8d5f70359961/floorPlans/kharagar-busstand-x-layout.svg';

export const BELOW_5Url =
  'https://relata.s3.ap-south-1.amazonaws.com/projects/6372189286ee8d5f70359961/floorPlans/kharagar-busstand-x-para-1.svg';
export const MULTIPLE_3Url =
  'https://relata.s3.ap-south-1.amazonaws.com/projects/6372189286ee8d5f70359961/floorPlans/kharagar-busstand-x-refuge.svg';
export const NORMALUrl =
  'https://relata.s3.ap-south-1.amazonaws.com/projects/6372189286ee8d5f70359961/floorPlans/kharagar-busstand-x-typical.svg';
export const FORTH_PLANUrl =
  'https://relata.s3.ap-south-1.amazonaws.com/projects/6372189286ee8d5f70359961/floorPlans/kharagar-busstand-x-para-2.svg';
export const UNIT_2DUrl =
  'https://relata.s3.ap-south-1.amazonaws.com/projects/6372189286ee8d5f70359961/floorPlans/common-floorPlan/common-unitPlan/2D/common-unit.jpg';
export const HELIOS_ORIGIN = '*';
