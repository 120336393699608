import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';

import SiteVisit from 'apps/admin/components/pages/forms/SiteVisit';
import { isAdminUserLoggedIn } from 'apps/admin/components/pages/AdminLogin/utils';

const FormRoutes = () => {
  const { path } = useRouteMatch();
  const history = useHistory();

  if (!isAdminUserLoggedIn()) {
    if (!window.location.href.includes('/login?redirectTo=')) {
      history.push(
        `/login?redirectTo=${`${window.location.pathname}${window.location.search}`}`
      );
    }
  }

  return (
    <Switch>
      {isAdminUserLoggedIn() && (
        <Route path={`${path}/site-visit`} component={SiteVisit} />
      )}
    </Switch>
  );
};

export default FormRoutes;
