// Styles
import styles from './styles.module.css';

export type IconNames =
  | 'check'
  | 'close'
  | 'delete'
  | 'save'
  | 'edit'
  | 'file_download'
  | 'upload_file'
  | 'star'
  | 'star_outline'
  | 'block'
  | 'add_link'
  | 'person_add'
  | 'event'
  | 'content_copy'
  | 'search'
  | 'more_vert'
  | 'error_outline'
  | 'rotate_right'
  | 'person_outline'
  | 'highlight_off'
  | 'fiber_smart_record'
  | 'bookmark_added'
  | 'person'
  | 'info'
  | 'layers'
  | 'remove_circle'
  | 'place'
  | 'local_post_office'
  | 'local_phone'
  | 'call'
  | 'task_alt'
  | 'schedule'
  | 'arrow_upward'
  | 'arrow_downward'
  | 'gite'
  | 'arrow_drop_down'
  | 'arrow_drop_up'
  | 'help_outline'
  | 'chat_bubble_outline'
  | 'settings'
  | 'apps'
  | 'whatshot'
  | 'thermostat'
  | 'ac_unit'
  | 'filter_alt'
  | 'people'
  | 'workspaces'
  | 'equalizer'
  | 'group'
  | 'computer'
  | 'stream'
  | 'currency_rupee'
  | 'apartment'
  | 'fullscreen'
  | 'logout'
  | 'pin_drop'
  | 'fullscreen_exit'
  | 'equalizer'
  | 'visibility'
  | 'home_work'
  | 'domain'
  | 'view_list'
  | 'atr'
  | 'device_hub'
  | 'mail'
  | 'laptop_mac'
  | 'paid'
  | 'local_fire_department'
  | 'details'
  | 'laptop'
  | 'favorite_border'
  | 'favorite'
  | 'share'
  | 'photo_size_select_actual'
  | 'view_in_ar'
  | 'description'
  | 'menu_book'
  | 'ac_unit'
  | 'access_time'
  | 'dashboard'
  | 'explore'
  | 'add_box'
  | 'calendar_month'
  | 'new_releases'
  | 'notifications'
  | 'event_note'
  | 'tune'
  | 'arrow_back'
  | 'keyboard_arrow_right'
  | 'gps_fixed'
  | 'add_a_photo'
  | 'navigate_before'
  | 'navigate_next'
  | 'task_alt'
  | 'lock'
  | 'movie'
  | 'logout'
  | 'speed'
  | 'arrow_back_ios_new'
  | 'arrow_forward_ios'
  | 'bookmarks_outlined'
  | 'person_add_alt_outlined'
  | 'preview'
  | 'create'
  | 'add'
  | 'remove'
  | 'home'
  | 'receipt_long_outlined'
  | 'supervised_user_circle_outlined'
  | 'contact_emergency_outlined'
  | 'assignment_ind_outlined'
  | 'account_circle_outlined'
  | 'query_stats_outlined'
  | 'widgets_outlined'
  | 'settings_outlined'
  | 'people_outlined'
  | 'visibility_off'
  | 'visibility'
  | 'warning'
  | 'check_circle_outline_outlined'
  | 'launch'
  | 'event_note'
  | 'check_circle'
  | 'watch_later'
  | 'done'
  | 'replay'
  | 'check_circle_outline'
  | 'inventory'
  | 'navigate_next'
  | 'navigate_before'
  | 'last_page'
  | 'first_page'
  | 'zoom_in'
  | 'zoom_out';

export interface IICon {
  name: IconNames;
  propStyles?: string;
  disabled?: boolean;
  onClick?: React.MouseEventHandler<HTMLSpanElement>;
}

const Icon = (props: IICon) => {
  const { name, propStyles, disabled, onClick } = props;

  return (
    <span
      className={`icon material-icons ${
        onClick ? 'click-enabled' : ''
      } ${propStyles} ${disabled ? styles.disabled : ''}`}
      onClick={onClick}>
      {name}
    </span>
  );
};

export default Icon;
