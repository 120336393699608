import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import FileUploadNew from 'apps/booking/components/common/inputs/FileUploadNew';
import {
  IButtonTitles,
  IProject,
} from 'apps/admin/components/pages/SuperAdminDashboard/SuperAdminProjectList';
import { useUpdateProjectDetailsMutation } from 'apps/admin/services/projectsAPISlice';
import useToast from 'hooks/useToast';
import TextInput from 'apps/booking/components/common/inputs/TextInput';
import Button from 'apps/booking/components/common/buttons/Button';

import styles from './styles.module.css';

interface IProjectUpdateInventoryProps {
  project: IProject;
  onProjectInventoryUpdate: (updatedProjectInventory: IProject) => void;
}

const ProjectUpdateInventory = (props: IProjectUpdateInventoryProps) => {
  const { project } = props;

  const [updateDisabled, setUpdateDisabled] = useState(false);
  const [fileUploadingStatus, setFileUploadingStatus] = useState<{
    [fileId: string]: boolean;
  }>({});

  const {
    register,
    unregister,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });

  const [addToast] = useToast();
  const [updateProjectDetails] = useUpdateProjectDetailsMutation();

  const handleToogleFileUploadStatus = (
    fileId: string,
    isUploadInProgress: boolean
  ) => {
    setFileUploadingStatus((prevState) => {
      return {
        ...prevState,
        [fileId]: isUploadInProgress,
      };
    });
  };

  useEffect(() => {
    const isUploading = Object.values(fileUploadingStatus).some(
      (status) => status
    );
    setUpdateDisabled(isUploading);
  }, [fileUploadingStatus]);

  const handleUpdateTitles = async (buttonTitles: IButtonTitles) => {
    const buttonTitlesUpdatedData = {
      booking: {
        title: { ...buttonTitles },
      },
    };
    try {
      await updateProjectDetails({
        ...buttonTitlesUpdatedData,
        projectId: project.id,
      }).unwrap();
      addToast({
        type: 'SUCCESS',
        primaryMessage: 'Title successfully Updated',
      });
    } catch (err) {
      const errMsg = (err as any).data.message;
      addToast({
        type: 'ERROR',
        primaryMessage: errMsg,
        secondaryMessage: 'Failed to Update Titles',
      });
    }
  };

  return (
    <>
      <header className={styles.modalHeader}>
        <div className={styles.modalHeaderLeftContent}>
          <h1>Update Project Inventory!</h1>
          <p>Complete the below steps to update Project Inventory</p>
        </div>
      </header>
      <main>
        <div className={styles.bodyIndividualdRow}>
          <FileUploadNew
            placeholder='Upload Inventory'
            label='Inventory'
            s3DirPath={project.id + '/inventory'}
            onChangeFileUploadStatus={handleToogleFileUploadStatus}
            name='inventoryUrl'
            appType='RELATA'
            projectId={project.id}
          />
        </div>
        <div className={styles.bodyIndividualdRow}>
          <FileUploadNew
            placeholder='Upload Inventory SVGs'
            label='Project Inventory SVGs'
            s3DirPath={project.id}
            onChangeFileUploadStatus={handleToogleFileUploadStatus}
            appType='RELATA'
            showDisplayInputs
            projectId={project.id}
            folder={true}
          />
        </div>
        <div className={styles.formDividerLineContainer}></div>

        <div className={styles.bodyIndividualdRow}>
          <div className={styles.inputsContainer}>
            <TextInput
              placeHolder='Add Title'
              label='Master Button'
              defaultValue={
                project.booking?.title?.masterBtnTitle || 'MasterPlan'
              }
              register={register}
              setValue={setValue}
              unregister={unregister}
              name='masterBtnTitle'
            />
          </div>
          <div className={styles.inputsContainer}>
            <TextInput
              placeHolder='Add Title'
              label='Tower Button'
              defaultValue={project.booking?.title?.towerBtnTitle || 'Tower'}
              register={register}
              setValue={setValue}
              unregister={unregister}
              name='towerBtnTitle'
            />
          </div>
          <div className={styles.inputsContainer}>
            <TextInput
              placeHolder=' Add Title'
              label='Section Button'
              defaultValue={
                project.booking?.title?.sectionBtnTitle || 'Section'
              }
              register={register}
              setValue={setValue}
              unregister={unregister}
              name='sectionBtnTitle'
            />
          </div>
          <div className={styles.inputsContainer}>
            <TextInput
              placeHolder='Add Title'
              label='Block Button'
              defaultValue={project.booking?.title?.blockBtnTitle || 'Block'}
              register={register}
              setValue={setValue}
              unregister={unregister}
              name='blockBtnTitle'
            />
          </div>
        </div>

        <div className={styles.updateTitleBtn}>
          <Button
            type='submit'
            propStyles={`${styles.createProjectBtn}`}
            disabled={updateDisabled}
            onClick={() => {
              handleSubmit(handleUpdateTitles)();
            }}>
            Update Titles
          </Button>
        </div>

        <div className={styles.formDividerLineContainer}></div>
      </main>
    </>
  );
};

export default ProjectUpdateInventory;
