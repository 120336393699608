import { TObjectId } from 'interfaces';
import { adminAPISlice } from 'apps/admin/services';

export const eoiAPISlice = adminAPISlice.injectEndpoints({
  endpoints: (build) => ({
    getEOIForUser: build.mutation({
      query: ({ userId }: { userId?: TObjectId }) => ({
        url: `api/projects/eoi/user/${userId}`,
      }),
    }),
  }),
  overrideExisting: false,
});

export const { useGetEOIForUserMutation } = eoiAPISlice;
