import { useState } from 'react';

import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import Modal from 'apps/booking/components/common/modal/Modal';
import Icon from 'apps/booking/components/common/Icon';
import TextInput from 'apps/booking/components/common/inputs/TextInput/index';
import Button from 'apps/booking/components/common/buttons/Button';
import AddedCompanySuccessfulModal from 'apps/admin/components/pages/SuperAdminDashboard/CompanyList/modals/AddCompanyModal/AddedCompanySuccessfulModal';
import { useAddCompanyMutation } from 'apps/admin/services/companyAPISlice';
import { ICompany } from 'apps/admin/interfaces';
import useToast from 'hooks/useToast';

import styles from './styles.module.css';

interface IAddCompany {
  addNewOrganizationModalShown: boolean;
  handleAddCompanyModalClose: Function;
}

export interface IOrganizationSuccessfullyAddedModalDetails {
  newCompanyDetails: ICompany;
  successfullyModalShown: boolean;
}

const schema = yup.object().shape({
  displayName: yup.string().required(),
  name: yup.string().required(),
  gstNumber: yup.string().required(),
  websiteUrl: yup.string().url('valid url error').required(),
  hqAddress: yup.string().required(),
  rera: yup.string().required(),
  pocEmail: yup.string().email().required(),
  pocMobile: yup
    .string()
    .matches(/^[0-9]*$/)
    .length(10)
    .required('Mobile number is required'),
});

const AddCompanyModal = (props: IAddCompany) => {
  const { handleAddCompanyModalClose, addNewOrganizationModalShown } = props;

  const [successfulModalShown, setSuccessfulModalShown] = useState(false);
  const [addedCompany, setAddedCompany] = useState<ICompany>();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });

  const [addCompanyAPI] = useAddCompanyMutation();
  const [addToast] = useToast();

  const handleAddCompany = async (formData: ICompany) => {
    //TODO: Backend API will be called here
    try {
      const { data: company } = await addCompanyAPI(formData).unwrap();
      setAddedCompany(company);

      handleAddCompanyModalClose();

      setSuccessfulModalShown(true);
    } catch (err) {
      const errMsg = (err as any).data.message;
      addToast({
        type: 'ERROR',
        primaryMessage: errMsg,
        secondaryMessage: 'Failed to add company',
      });
    }
  };

  return (
    <>
      {successfulModalShown && addedCompany && (
        <AddedCompanySuccessfulModal
          company={addedCompany}
          onModalClose={() => setSuccessfulModalShown(false)}
        />
      )}

      <Modal
        show={addNewOrganizationModalShown}
        additionalClasses={styles.addCompany}
        onOutsideClick={handleAddCompanyModalClose}>
        <header>
          <div className={styles.headerIconAndHeadingContainer}>
            <h2 className={styles.modalHeading}>Create a New Company!</h2>
            <Icon
              name='close'
              propStyles={styles.closeIcon}
              onClick={() => handleAddCompanyModalClose()}></Icon>
          </div>
          <p className={styles.modalDesc}>
            Fill in the following info to create a new company. Under this
            company you can create projects.
          </p>
        </header>

        <form action='submit' onSubmit={handleSubmit(handleAddCompany)}>
          <main>
            <TextInput
              label='Company Display Name'
              placeHolder='Add Company Display Name'
              autoFocus={true}
              register={register}
              setValue={setValue}
              name='displayName'
              errorMessage={
                errors.displayName ? 'Display name is required' : ''
              }
            />
            <div className={styles.sideBySideTextInputsContainer}>
              <div className={styles.smallTextInputContainer}>
                <TextInput
                  label='Company Name'
                  placeHolder='Add Company Name'
                  register={register}
                  setValue={setValue}
                  name='name'
                  errorMessage={errors.name ? 'Developer name is required' : ''}
                />
              </div>
              <div className={styles.smallTextInputContainer}>
                <TextInput
                  label='GST Number'
                  placeHolder='Add GST Number'
                  register={register}
                  setValue={setValue}
                  name='gstNumber'
                  errorMessage={
                    errors.gstNumber ? 'GST number is required' : ''
                  }
                />
              </div>
            </div>
            <div className={styles.sideBySideTextInputsContainer}>
              <div className={styles.smallTextInputContainer}>
                <TextInput
                  label='Company Website URL'
                  placeHolder='Add Company Website URL'
                  register={register}
                  setValue={setValue}
                  name='websiteUrl'
                  errorMessage={
                    errors.websiteUrl
                      ? 'Valid company websiteUrl URL is required'
                      : ''
                  }
                />
              </div>
              <div className={styles.smallTextInputContainer}>
                <TextInput
                  label='Registered HQ Address'
                  placeHolder='Registered HQ Address'
                  register={register}
                  setValue={setValue}
                  name='hqAddress'
                  errorMessage={
                    errors.hqAddress ? 'Registered HQ Address is required' : ''
                  }
                />
              </div>
            </div>
            <div className={styles.sideBySideTextInputsContainer}>
              <div className={styles.smallTextInputContainer}>
                <TextInput
                  label='RERA Number'
                  placeHolder='Add RERA Number'
                  register={register}
                  setValue={setValue}
                  name='rera'
                  errorMessage={errors.rera ? 'Rera number is required' : ''}
                />
              </div>
            </div>
            <div className={styles.sideBySideTextInputsContainer}>
              <div className={styles.smallTextInputContainer}>
                <TextInput
                  label='Point of Contact Email'
                  placeHolder='POC Email ID'
                  register={register}
                  setValue={setValue}
                  name='pocEmail'
                  errorMessage={
                    errors.pocEmail ? 'Valid Email id is required' : ''
                  }
                />
              </div>
              <div className={styles.smallTextInputContainer}>
                <TextInput
                  label='Point of Contact Mobile'
                  placeHolder='POC Mobile Number'
                  register={register}
                  setValue={setValue}
                  name='pocMobile'
                  maxLength={10}
                  errorMessage={
                    errors.pocMobile ? 'Mobile number is required' : ''
                  }
                />
              </div>
            </div>
            <div className={styles.sideBySideTextInputsContainer}>
              <div className={styles.smallTextInputContainer}>
                <TextInput
                  label='RERA Number'
                  placeHolder='Add RERA Number'
                  register={register}
                  setValue={setValue}
                  name='reraNumber'
                  errorMessage={errors.rera ? 'Rera number is required' : ''}
                />
              </div>
            </div>
          </main>

          <footer className={styles.footer}>
            <div className={styles.createOrganizationBtn}>
              <Button accent='primary' type='submit'>
                Create Company
              </Button>
            </div>
          </footer>
        </form>
      </Modal>
    </>
  );
};

export default AddCompanyModal;
