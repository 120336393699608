import { getPriceInFig } from 'utils/utils';
import { useMemo, useState } from 'react';

import {
  ICollateral,
  IProject,
  TDistanceUnit,
} from 'apps/admin/components/pages/SuperAdminDashboard/SuperAdminProjectList';

const useProjectDetails = (props: { project: IProject }) => {
  const { project } = props;

  const [updatedPosters, setUpdatedPosters] = useState(project.posters);
  const [updatedGallery, setUpdatedGallery] = useState(project.gallery);
  const [updatedAmenities, setUpdatedAmenities] = useState(project.amenities);
  const [updatedConfigs, setUpdatedConfigs] = useState(project.configs);
  const [updatedUnitTagType, setUpdatedUnitTagType] = useState(
    project.unitTagMapping
  );
  const [updatedEoiType, setUpdatedEoiType] = useState(project.eoi.types);
  const [updatedPriceRanges, setUpdatedPriceRanges] = useState(
    project.priceRanges
  );
  const [updatedFeatures, setUpdatedFeatures] = useState(project.features);
  const [updatedNearByAreas, setUpdatedNearByArea] = useState(
    project.nearByAreas
  );
  const [updatedCollaterals, setUpdatedCollaterals] = useState(
    project.collaterals
  );

  const posterImageUrls = useMemo(() => {
    return Object.values(updatedPosters).map((image) => image.imageUrl);
  }, [updatedPosters]);

  const galleryImageUrls = useMemo(() => {
    return Object.values(updatedGallery).map((image) => image.imageUrl);
  }, [updatedGallery]);

  const featureKeyValueStrings = useMemo(() => {
    return Object.values(updatedFeatures).map(
      (feature) => `${feature.name}:${feature.value}`
    );
  }, [updatedFeatures]);

  const priceRangeKeyValueStrings = useMemo(() => {
    return Object.values(updatedPriceRanges).map(
      (range) => `${getPriceInFig(range.min)} : ${getPriceInFig(range.max)}`
    );
  }, [updatedPriceRanges]);

  const nearByAreaKeyValueStrings = useMemo(() => {
    return Object.values(updatedNearByAreas).map(
      (area) => `${area.name}:${area.distance.value}`
    );
  }, [updatedNearByAreas]);

  const unitTagTypeKeyValueStrings = useMemo(() => {
    return Object.values(updatedUnitTagType).map(
      (unitTagType) => `${unitTagType.name}:${unitTagType.value}`
    );
  }, [updatedUnitTagType]);

  const collateralTitles = useMemo(() => {
    return Object.values(updatedCollaterals).map(
      (collateral) => collateral.title
    );
  }, [updatedCollaterals]);

  const handleAddPosterImage = (imageUrls: string[]) => {
    const posters = [...updatedPosters];
    for (let i = 0; i < imageUrls.length; i++) {
      const data = {
        title: 'poster1',
        imageUrl: imageUrls[i],
      };
      posters.push(data);
    }
    setUpdatedPosters(posters);
  };

  const handleRemovePosterImage = (imageUrl: string) => {
    const removedImage = updatedPosters.filter(
      (image) => image.imageUrl !== imageUrl
    );
    setUpdatedPosters(removedImage);
  };

  const handleAddGalleryImage = (imageUrls: string[]) => {
    const gallery = [...updatedGallery];
    for (let i = 0; i < imageUrls.length; i++) {
      const data = {
        title: 'gallery1',
        imageUrl: imageUrls[i],
      };
      gallery.push(data);
    }
    setUpdatedGallery(gallery);
  };

  const handleRemoveGalleryImage = (imageUrl: string) => {
    const removedImage = updatedGallery.filter(
      (image) => image.imageUrl !== imageUrl
    );
    setUpdatedGallery(removedImage);
  };

  const handleAddAmenity = (amenitiesLabel: string) => {
    const addedAmenities = [...updatedAmenities];
    addedAmenities.push(amenitiesLabel);
    setUpdatedAmenities(addedAmenities);
  };

  const handleRemoveAmenity = (amenitiesLabel: string) => {
    const filteredAmenities = updatedAmenities.filter(
      (amenity) => amenity !== amenitiesLabel
    );

    setUpdatedAmenities(filteredAmenities);
  };

  const handleAddFeature = (feature: { name: string; value: string }) => {
    const addedFeatures = [...updatedFeatures];

    addedFeatures.push(feature);
    setUpdatedFeatures(addedFeatures);
  };

  const handleRemoveFeature = (featureName: string) => {
    const arr = updatedFeatures.filter(
      (feature) => feature.name !== featureName
    );
    setUpdatedFeatures(arr);
  };

  const handleAddUnitTagType = (unitTagType: {
    name: string;
    value: string;
  }) => {
    const addedUnitTagType = [...updatedUnitTagType];

    addedUnitTagType.push(unitTagType);
    setUpdatedUnitTagType(addedUnitTagType);
  };

  const handleRemoveUnitTagType = (unitTagName: string) => {
    const unitTagType = updatedUnitTagType.filter(
      (tagType) => tagType.name !== unitTagName
    );
    setUpdatedUnitTagType(unitTagType);
  };

  const handleAddArea = (nearByArea: {
    name: string;
    distance: { value: string; unit: TDistanceUnit };
  }) => {
    const areas = [...updatedNearByAreas];
    areas.push(nearByArea);
    setUpdatedNearByArea(areas);
  };

  const handleRemoveArea = (areaName: string) => {
    const arr = updatedNearByAreas.filter((area) => area.name !== areaName);
    setUpdatedNearByArea(arr);
  };

  const handleAddUpdateCollateral = (
    collateral: ICollateral,
    editCollateralIndex: number
  ) => {
    const addedCollaterals = [...updatedCollaterals];

    if (editCollateralIndex != -1) {
      addedCollaterals[editCollateralIndex] = collateral;
    } else {
      addedCollaterals.push(collateral);
    }
    setUpdatedCollaterals(addedCollaterals);
  };

  const handleRemoveCollateral = (title: string) => {
    const arr = updatedCollaterals.filter(
      (collateral) => collateral.title !== title
    );
    setUpdatedCollaterals(arr);
  };

  const handleAddConfig = (configLabel: string) => {
    const addedConfigs = [...updatedConfigs];
    addedConfigs.push(configLabel);
    setUpdatedConfigs(addedConfigs);
  };

  const handleAddEoiType = (eoiLabel: string) => {
    const addedEoiType = [...updatedEoiType];
    addedEoiType.push(eoiLabel);
    setUpdatedEoiType(addedEoiType);
  };

  const handleRemoveEoiType = (eoiLabel: string) => {
    const filteredEoiType = updatedEoiType.filter((eoi) => eoi !== eoiLabel);

    setUpdatedEoiType(filteredEoiType);
  };

  const handleRemoveConfig = (configLabel: string) => {
    const filteredConfigs = updatedConfigs.filter(
      (config) => config !== configLabel
    );

    setUpdatedConfigs(filteredConfigs);
  };

  const handleAddPriceRange = (priceRange: { name: string; value: string }) => {
    const addedPriceRanges = [...updatedPriceRanges];

    addedPriceRanges.push({
      min: +priceRange.name,
      max: +priceRange.value,
    });
    setUpdatedPriceRanges(
      addedPriceRanges.sort((r1, r2) => (r1.max > r2.min ? 1 : -1))
    );
  };

  const handleRemovePriceRange = (minPrice: string) => {
    const ranges = updatedPriceRanges.filter(
      (range) => range.min !== +minPrice
    );
    setUpdatedPriceRanges(ranges);
  };

  return {
    posterImageUrls,
    galleryImageUrls,
    featureKeyValueStrings,
    unitTagTypeKeyValueStrings,
    priceRangeKeyValueStrings,
    nearByAreaKeyValueStrings,
    collateralTitles,
    handleAddGalleryImage,
    handleRemoveGalleryImage,
    handleAddAmenity,
    handleRemoveAmenity,
    handleAddFeature,
    handleRemoveUnitTagType,
    handleAddUnitTagType,
    handleAddEoiType,
    handleRemoveEoiType,
    handleRemoveFeature,
    handleAddPosterImage,
    handleRemovePosterImage,
    handleAddArea,
    handleRemoveArea,
    handleAddUpdateCollateral,
    handleRemoveCollateral,
    handleAddConfig,
    handleRemoveConfig,
    handleAddPriceRange,
    handleRemovePriceRange,
    updatedAmenities,
    updatedFeatures,
    updatedGallery,
    updatedNearByAreas,
    updatedPosters,
    updatedUnitTagType,
    updatedEoiType,
    updatedCollaterals,
    updatedConfigs,
    updatedPriceRanges,
  };
};

export default useProjectDetails;
