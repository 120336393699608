import { useCallback, useState } from 'react';

import { useForm } from 'react-hook-form';

import Button from '../../buttons/Button';
import DisplayInputs from '../DisplayInputs';
import TextInput from '../TextInput';

import styles from './styles.module.css';

interface IMultiTextInputProps {
  label: string;
  type?: string;
  placeHolder: string;
  displayInputs: string[];
  onAdd?: (label: string) => void;
  onRemove?: (label: string) => void;
}

const MultiTextInput = (props: IMultiTextInputProps) => {
  const { label, placeHolder, displayInputs, onAdd, onRemove } = props;
  const [inputValue, setInputValue] = useState('');

  const { setValue } = useForm({ mode: 'onBlur', reValidateMode: 'onChange' });

  const handleAdd = useCallback(() => {
    setInputValue('');
    onAdd && onAdd(inputValue);
  }, [inputValue, onAdd]);

  const handleInputOnChange = (e: any) => {
    const inputValue = e.target.value;
    setInputValue(inputValue);
  };

  return (
    <>
      <div className={styles.inputContainer}>
        <TextInput
          label={label}
          placeHolder={placeHolder}
          resetValue={!inputValue}
          setValue={setValue}
          onChange={handleInputOnChange}
        />
        <Button
          propStyles={styles.addBtn}
          disabled={!inputValue}
          onClick={handleAdd}>
          Add
        </Button>
      </div>

      <div className={styles.bodyIndividualdRow}>
        <DisplayInputs displayInputs={displayInputs} onRemove={onRemove} />
      </div>
    </>
  );
};

export default MultiTextInput;
