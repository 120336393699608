import { useEffect, useState } from 'react';

import { ICellRendererParams } from 'ag-grid-community';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';

import Icon from 'apps/booking/components/common/Icon';
import Modal from 'apps/booking/components/common/modal/Modal';
import Tooltip from 'apps/booking/components/common/Tooltip';
import useToast from 'hooks/useToast';
import {
  useAddProjectEventMutation,
  useGetProjectEventsByProjectIdQuery,
} from 'apps/admin/services/projectsAPISlice';
import { CLUB_RHODIUM_BASE_URL } from 'constants/urls';
import Button from 'apps/booking/components/common/buttons/Button';
import AddProjectEvent from 'apps/admin/components/pages/SuperAdminDashboard/SuperAdminProjectList/modals/AddProjectEventModal';
import { IProject } from 'apps/admin/components/pages/SuperAdminDashboard/SuperAdminProjectList';
import ProjectEventDetailsCell from 'apps/admin/components/pages/SuperAdminDashboard/SuperAdminProjectList/modals/ProjectEventsModal/ProjectEventDetailsCell';
import ProjectEventUpdateCell from './ProjectEventUpdateCell';

import genericStyles from '../../../../../../../../styles/genericStyles.module.css';
import styles from './styles.module.css';

interface IProjectEventsModalProps {
  project: IProject;
  onModalClose: () => void;
}

export interface IProjectEvent {
  id: string;
  developerName: string;
  title: string;
  desc: string;
  descItems?: string[];
  venue: string;
  startDate: Date;
  endDate: Date;
  project: {
    name: string;
  };
}

export interface IProjectEventReq {
  projectId: string;
  developerName: string;
  title: string;
  desc: string;
  descItems?: string[];
  venue: string;
  startDate: Date;
  endDate: Date;
}

const ProjectEventsModal = (props: IProjectEventsModalProps) => {
  const { project, onModalClose } = props;

  const [showAddEventModal, setShowAddEventModal] = useState(false);
  const [updatedProjectEvents, setUpdatedProjectEvents] =
    useState<IProjectEvent[]>();

  const { data: projectEvents = [], isLoading } =
    useGetProjectEventsByProjectIdQuery(project.id);

  const [addProjectEventAPI] = useAddProjectEventMutation();
  const [addToast] = useToast();

  const defaultColDef = {
    sortable: false,
    filter: false,
    resizable: false,
    suppressMenu: true,
    editable: false,
    flex: 1,
  };

  useEffect(() => {
    return setUpdatedProjectEvents(projectEvents);
  }, [projectEvents]);

  const handleAddProjectEvent = async (data: IProjectEventReq) => {
    const payload = {
      ...data,
      projectId: project.id,
      developerName: project.company?.displayName,
    };

    try {
      const response = await addProjectEventAPI(payload).unwrap();

      const events = [...(updatedProjectEvents || [])];
      events.push(response.data as IProjectEvent);
      setUpdatedProjectEvents(events);

      addToast({
        type: 'SUCCESS',
        primaryMessage: 'Event is successfully added',
      });
      setShowAddEventModal(false);
    } catch (err) {
      const errMsg = (err as any).data.message;
      addToast({
        type: 'ERROR',
        primaryMessage: errMsg,
        secondaryMessage: 'Event creation failed',
      });
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      {showAddEventModal && (
        <AddProjectEvent
          onModalClose={() => {
            setShowAddEventModal(false);
          }}
          onAddEvent={handleAddProjectEvent}
        />
      )}

      <Modal
        onOutsideClick={onModalClose}
        additionalClasses={styles.modalAdditionalClasses}>
        <header className={styles.modalHeader}>
          <div>
            <h2 className={styles.modalHeading}>
              {project.name} List of Events
            </h2>
          </div>
          <Icon
            name='close'
            propStyles={styles.crossIcon}
            onClick={onModalClose}
          />
        </header>

        <main
          className={`ag-theme-alpine ${styles.gridContainer}`}
          style={{ height: '100%', width: '100%' }}>
          <AgGridReact
            rowHeight={60}
            rowData={updatedProjectEvents}
            defaultColDef={defaultColDef}
            domLayout={'autoHeight'}
            pagination={true}
            paginationPageSize={10}>
            <AgGridColumn
              flex={4}
              headerName='Title'
              field='title'
              cellClass='relata-ag-grid-cell-wrap'
              cellRendererFramework={(params: ICellRendererParams) => (
                <p className={`relata-ag-grid-wrappable-text`}>
                  {params.data.title}
                </p>
              )}></AgGridColumn>

            <AgGridColumn
              flex={4}
              headerName='Venue'
              field='venue'
              cellClass='relata-ag-grid-cell-wrap'
              cellRendererFramework={(params: ICellRendererParams) => (
                <p className={`relata-ag-grid-wrappable-text`}>
                  {params.data.venue}
                </p>
              )}></AgGridColumn>

            <AgGridColumn
              flex={2}
              headerName='URL'
              field='url'
              cellClass='relata-ag-grid-cell-wrap'
              cellRendererFramework={(params: ICellRendererParams) => (
                <Tooltip title='Click here to copy url'>
                  <p
                    onClick={() => {
                      navigator.clipboard.writeText(
                        `${CLUB_RHODIUM_BASE_URL}/cp/kyc?projectEventId=${params.data.id}`
                      );
                    }}
                    className={`${genericStyles.pointer} relata-ag-grid-wrappable-text ${genericStyles.onTextHoverAnimation}`}>
                    Copy URL
                  </p>
                </Tooltip>
              )}></AgGridColumn>

            <AgGridColumn
              flex={2}
              headerName='Update Event'
              headerClass='relata-ag-grid-header-small-font relata-ag-grid-header-justify-center'
              cellClass='relata-ag-grid-cell-justify-center'
              cellRendererFramework={ProjectEventUpdateCell}
              cellRendererParams={(params: ICellRendererParams) => ({
                projectEvent: params.data as IProjectEvent,
                onProjectEventUpdate: (updatedProjectEvent: IProjectEvent) =>
                  params.node.setData(updatedProjectEvent),
              })}
            />

            <AgGridColumn
              flex={2}
              headerName='Details'
              headerClass='relata-ag-grid-header-small-font relata-ag-grid-header-justify-center'
              cellClass='relata-ag-grid-cell-justify-center'
              cellRendererFramework={ProjectEventDetailsCell}
              cellRendererParams={(params: ICellRendererParams) => ({
                projectEvent: params.data as IProjectEvent,
              })}></AgGridColumn>
          </AgGridReact>
        </main>

        {/* Modal Footer */}
        <footer className={styles.footerContainer}>
          <div className={styles.footerLeftContainer}>
            <Button
              onClick={() => {
                setShowAddEventModal(true);
              }}
              propStyles={styles.addEventButton}>
              Add Event
            </Button>
          </div>
          <div className={styles.eventsCount}>
            {updatedProjectEvents?.length} Events
          </div>
        </footer>
      </Modal>
    </>
  );
};

export default ProjectEventsModal;
