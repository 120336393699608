import { NavLink, useLocation } from 'react-router-dom';

import Icon, { IconNames } from 'apps/booking/components/common/Icon';

export interface IAdminSidebarItem {
  redirectPath: String;
  labelText: string;
  desc?: String;
  icon: IconNames;
  additionClasses?: String;
  hideSideNavBar?: boolean;
  onClick?: () => void;
}

type TTabName = 'Overview' | 'Projects' | 'Organisations';

export interface IAdminSidebarItemMain {
  menuName: string;
  tabName: TTabName;
  submenu: IAdminSidebarItem[];
}

const AdminSidebarItem = (props: IAdminSidebarItem) => {
  const {
    redirectPath = '/',
    icon,
    additionClasses = '',
    labelText,
    hideSideNavBar = true,
    onClick,
  } = props;

  const { pathname: currentPath } = useLocation();
  const isActiveItem = currentPath === redirectPath;

  return (
    <NavLink
      title={labelText}
      to={redirectPath as string}
      className={`admin-sidebar-item ${additionClasses} ${
        isActiveItem && 'admin-sidebar-item--active'
      } ${!hideSideNavBar && 'sidebar--inaActive'} ${
        redirectPath == '/' && 'disbaled'
      }`}
      onClick={onClick}>
      <Icon name={icon} propStyles='sidebar-icon' />

      <span
        className={`admin-sidebar-item__label ${
          !hideSideNavBar ? 'hidden' : ''
        }`}>
        {labelText}
      </span>
    </NavLink>
  );
};

export default AdminSidebarItem;
