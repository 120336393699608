import { useSelector } from 'react-redux';
import { useState } from 'react';

import { GridReadyEvent, ICellRendererParams } from 'ag-grid-community';
import { AgGridReact, AgGridColumn } from 'ag-grid-react';
import DateCell from 'apps/admin/components/common/dates/DateCell';
import { IProject } from 'apps/admin/components/pages/SuperAdminDashboard/SuperAdminProjectList';
import { IUser } from 'apps/admin/interfaces';
import NameCard from 'apps/booking/components/common/admin/NameCard';
import TextInput from 'apps/booking/components/common/inputs/TextInput';
import { awsBaseUrl } from 'constants/urls';
import Tooltip from 'apps/booking/components/common/Tooltip';
import { getAdminUser } from 'apps/admin/slices/adminSlice';
import UserDetailsCell from 'apps/admin/components/pages/AdminDashboard/AdminExecutiveList/Body/UserListGrid/UserDetailsCell';
import { useGetSMListByUserRoleQuery } from 'apps/admin/services/userRolesAPISlice';
import { TObjectId } from 'interfaces';

import styles from './styles.module.css';
import genericStyles from '../../../../../../styles/genericStyles.module.css';

interface ITeamMemberListProps {
  project: IProject;
  onProjectUpdate: (updateProject: IProject) => void;
}
const TeamMemberList = (props: ITeamMemberListProps) => {
  const { project, onProjectUpdate } = props;

  const defaultColDef = {
    sortable: false,
    filter: false,
    resizable: false,
    suppressMenu: true,
  };

  const [gridApi, setGridApi] = useState(null) as any;

  const user = useSelector(getAdminUser);

  const { data: teamMemberList, isLoading } = useGetSMListByUserRoleQuery({
    role: user?.role as string,
    projectId: project.id,
    userId: user?.id as TObjectId,
  });

  const onGridReady = (params: GridReadyEvent) => {
    setGridApi(params.api);
  };

  //Ag-grid global search
  const handleGridSearch = (searchString: string) => {
    gridApi?.setQuickFilter(searchString);
  };

  const getUserAvatarUrl = (params: ICellRendererParams) => {
    return `${awsBaseUrl}/common/AZAlphabets/${params.data.name[0].toUpperCase()}${
      +params.data.mobile % 5
    }.png`;
  };

  return (
    <div>
      {/* {showAddProjectTeamMemberModal && (
        <AddProjectTeamMemberModal
          project={project}
          onModalClose={() => {
            setShowAddProjectTeamMemberModal(false);
          }}
          onAddTeamMember={handleAddDeleteTeamMember}
        />
      )} */}

      <div>
        <header className={styles.modalHeader}>
          <div>
            <h2 className={styles.modalHeading}>List of Team Members</h2>
            <p className={styles.modalDesc}>
              Following executives get access to all project of {project.name}
              developer. You can edit details or remove users from here.
            </p>
          </div>
          {/* <Button
            onClick={() => {
              setShowAddProjectTeamMemberModal(true);
            }}
            propStyles={styles.addExecutiveButton}>
            Add Team Member
          </Button> */}
        </header>
        <div className={styles.searchContainer}>
          <TextInput
            type='text'
            inputHTMLElementStyles={styles.searchInput}
            placeHolder='Search Name'
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              handleGridSearch(event.target.value);
            }}
            startIconName='search'
            startIconVariant='SECONDARY'
          />
        </div>
        <main>
          <div
            className='ag-theme-alpine'
            style={{ height: '100%', width: '100%' }}>
            <AgGridReact
              onGridReady={onGridReady}
              rowData={teamMemberList}
              defaultColDef={defaultColDef}
              domLayout={'autoHeight'}
              pagination={true}
              paginationPageSize={10}
              masterDetail={true}
              detailCellRenderer={'detailCellRenderer'}
              animateRows={true}>
              <AgGridColumn
                headerName='Names'
                field='name'
                flex={3}
                cellRendererFramework={(params: ICellRendererParams) => (
                  <NameCard
                    {...{
                      imgUrl: `${getUserAvatarUrl(params)}`,
                      title: params.data.name,
                      subtitle: params.data.email,
                      disabled: params.data.disabled,
                    }}
                  />
                )}
              />
              <AgGridColumn
                flex={1.5}
                headerName='Mobile'
                field='mobile'
                cellClass='relata-ag-grid-cell-wrap'
                cellRendererFramework={(params: ICellRendererParams) => (
                  <Tooltip title='Click to Copy'>
                    <p
                      onClick={() => {
                        navigator.clipboard.writeText(`${params.data.mobile}`);
                      }}
                      className={`${genericStyles.pointer} relata-ag-grid-wrappable-text ${genericStyles.onTextHoverAnimation}`}>
                      {params.data.mobile}
                    </p>
                  </Tooltip>
                )}
              />
              <AgGridColumn
                flex={1}
                headerName='Registered On'
                cellRendererFramework={DateCell}
                cellRendererParams={(params: ICellRendererParams) => {
                  return {
                    date: params.data.createdAt,
                  };
                }}
              />
              <AgGridColumn
                flex={2}
                headerName='Role'
                cellRendererFramework={(params: ICellRendererParams) => (
                  <p className={styles.cpName}>{params.data.role}</p>
                )}></AgGridColumn>

              {/* <AgGridColumn
                flex={0.5}
                headerName='Edit'
                cellRendererFramework={UpdateUserCell}
                cellRendererParams={(
                  params: ICellRendererParams
                ): IUpdateUserCell => ({
                  user: params.data,
                  rowNode: params.node,
                  onUserUpdate: handleExecutiveUpdate,
                  disabled: !(
                    params.data.company === adminCompany?.id ||
                    loggedInUser?.role === UserRole.SUPERADMIN
                  ),
                })}
              /> */}

              {/* <AgGridColumn
                flex={0.8}
                headerName='Remove'
                // headerClass='relata-ag-grid-header-small-font relata-ag-grid-header-justify-center'
                field=''
                // cellClass='relata-ag-grid-cell-justify-center'
                cellRendererFramework={RemoveProjectExecutiveCell}
                cellRendererParams={(
                  params: ICellRendererParams
                ): IRemoveProjectExecutiveCell => ({
                  executiveWithDemoRoomId: project.executivesWithDemoRoom.find(
                    (e) => e.executive.id === params.data.id
                  )?.id as string,
                  projectId: project.id,
                  onExecutiveDelete: handleAddDeleteTeamMember,
                  disabled: !(
                    params.data.company === adminCompany?.id ||
                    loggedInUser?.role === UserRole.SUPERADMIN
                  ),
                })}
              /> */}

              <AgGridColumn
                flex={0.8}
                headerName='Details'
                cellRendererFramework={UserDetailsCell}
                cellRendererParams={(params: ICellRendererParams) => ({
                  user: params.data as IUser,
                })}
              />
            </AgGridReact>
          </div>
        </main>
      </div>
    </div>
  );
};

export default TeamMemberList;
