import { useState } from 'react';

import LoginState from 'apps/admin/components/pages/AdminLogin/LoginForm/loginform-state-handlers/LoginState';
import SendOtpState from 'apps/admin/components/pages/AdminLogin/LoginForm/loginform-state-handlers/SendOtpState';
import PasswordResetState from 'apps/admin/components/pages/AdminLogin/LoginForm/loginform-state-handlers/ResetPassword';
import PasswordChangeSuccessState from 'apps/admin/components/pages/AdminLogin/LoginForm/loginform-state-handlers/PasswordChangeSuccess';

import styles from './styles.module.css';

export enum LoginFormStates {
  LOGIN,
  SEND_OTP,
  RESET_PASSWORD,
  PASSWORD_RESET_SUCCESS,
}

const LoginForm = () => {
  const [loginFormState, setLoginFormState] = useState(LoginFormStates.LOGIN);
  const [mobile, setMobile] = useState('');

  return (
    <div className={styles.adminLoginRightContainer}>
      {LoginFormStates.LOGIN === loginFormState && (
        <LoginState
          handleMobile={(mobile: string) => setMobile(mobile)}
          setLoginFormState={(value: React.SetStateAction<LoginFormStates>) => {
            setLoginFormState(value);
          }}></LoginState>
      )}
      {LoginFormStates.SEND_OTP === loginFormState && (
        <SendOtpState
          mobile={mobile}
          handleMobile={(mobile: string) => setMobile(mobile)}
          setLoginFormState={(value: React.SetStateAction<LoginFormStates>) => {
            setLoginFormState(value);
          }}
          addressType={'MOBILE'}></SendOtpState>
      )}

      {LoginFormStates.RESET_PASSWORD === loginFormState && (
        <PasswordResetState
          mobile={mobile}
          setLoginFormState={(value: React.SetStateAction<LoginFormStates>) => {
            setLoginFormState(value);
          }}></PasswordResetState>
      )}
      {LoginFormStates.PASSWORD_RESET_SUCCESS === loginFormState && (
        <PasswordChangeSuccessState
          setLoginFormState={(value: React.SetStateAction<LoginFormStates>) => {
            setLoginFormState(value);
          }}></PasswordChangeSuccessState>
      )}
      <p className={styles.loginDisclaimer}>
        By clicking continue, you are agreeing to our privacy policy & terms of
        use. For more information{' '}
        <span className={styles.termsAndConditionsLink}>
          Terms & Conditions
        </span>{' '}
        .
      </p>
    </div>
  );
};

export default LoginForm;
