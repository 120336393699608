import { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Route, Switch, useParams } from 'react-router-dom';

import LandscapeFlashScreen from 'apps/booking/components/LandscapeFlashScreen';
import BookingRoutes from 'apps/booking/routers/BookingRoutes';
import DashboardRoutes from 'apps/booking/routers/DashboardRoutes';
import Header from 'apps/booking/containers/header/Header';
import { getAppSliceIsFetching, setAppSliceIsFetching } from 'slices/appSlice';
import {
  getUnitListById,
  setFilterPreferences,
  setHierarchyUnitList,
  setUnitListById,
} from 'apps/booking/slices/unitSelectionHeader/unitSelectionHeaderNav';
import {
  getUser,
  setUser,
  setThirdPartyUserId,
} from 'apps/booking/slices/auth';
import { getUserEOIs, setProject, setUserEOIs } from '../slices/projectSlice';
import {
  useLazyGetUnitList1Query,
  useLazyGetUnitListByProjectIdQuery,
} from 'api/units1';
import useToast from 'hooks/useToast';
import { isEmpty } from 'utils/utils';
import { constructFilterPreferences } from 'apps/booking/components/unitSelection/utils';
import { HELIOS_ORIGIN, URLS } from 'constants/urls';
import { CURRENT_STEP_STATE, UNIT_STATUSES } from 'constants/status';
import { setUserLanguage } from 'apps/booking/slices/userlanguage';
import DashbaordSidebar from '../containers/dashboard/DashboardSidebar';
import BookingSidebar from '../containers/BookingSidebar';
import { useGetProjectsByProjectIdQuery } from 'apps/admin/services/projectsAPISlice';
import { useGetEOIForUserMutation } from 'apps/admin/services/eoiAPISlice';
import { setShowAuthForms } from 'apps/booking/slices/auth';
import { PROJECT_TYPE } from 'constants/index';

export const SECTION_1_12 = 'section-1-12';
export const SECTION_13_14 = 'section-13-14';

const ContentLayer = () => {
  const { projectId } = useParams();

  const dispatch = useDispatch();
  const [unitList, setUnitList] = useState([]);
  const appSliceIsFetching = useSelector(getAppSliceIsFetching);
  const unitListById = useSelector(getUnitListById);
  const user = useSelector(getUser);
  const userEOIs = useSelector(getUserEOIs);

  const [addToast] = useToast();

  const [getUnitListAPI, unitListByThirdPartyProjectId] =
    useLazyGetUnitList1Query();
  const [getUnitListByProjectId, unitListByProjectId] =
    useLazyGetUnitListByProjectIdQuery();
  const [getEOIForUserAPI] = useGetEOIForUserMutation();

  const { data: projectData } = useGetProjectsByProjectIdQuery(projectId);

  const urlParams = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });
  let {
    pid: thirdPartyProjectId,
    uid: thirdPartyUserId,
    t: allowedUnitTag,
    lang: userLanguage,
  } = urlParams;

  const staticIdTagMapping = {
    k641: 'GP',
    kd66: 'PH',
    ka77: 'SC',
    k507: 'ST',
    kf67: 'NT',
    kdb7: 'DT',
    k508: 'RM',
    k98b: 'SG',
    ka71: 'JRM',
    k50d: 'CE',
    kf6c: 'PAP',
    k50f: 'EX',
    k9e6: 'MH',
  };

  const isQueryParamValid = useMemo(
    () =>
      !(!allowedUnitTag || !thirdPartyUserId) ||
      !projectData?.booking?.thirdPartyIntegrationEnabled,
    [projectData]
  );

  const hasCommonTag = (array1, array2) => {
    return array1?.some((item) => array2?.includes(item));
  };

  useEffect(() => {
    if (isEmpty(projectData)) {
      return;
    }
    if (isEmpty(unitListById)) {
      if (projectData.booking.thirdPartyIntegrationEnabled) {
        dispatch(setAppSliceIsFetching(true));
        getUnitListAPI({ thirdPartyProjectId });
      } else {
        getUnitListByProjectId({ projectId });
      }
    }
  }, [
    dispatch,
    getUnitListAPI,
    getUnitListByProjectId,
    unitListById,
    projectData,
  ]);

  useEffect(() => {
    if (!isQueryParamValid || isEmpty(projectData)) {
      return;
    }
    if (projectData.booking.thirdPartyIntegrationEnabled) {
      allowedUnitTag = [staticIdTagMapping[allowedUnitTag]];
      dispatch(setUser(thirdPartyUserId));
      dispatch(setThirdPartyUserId(thirdPartyUserId));
    } else if (projectData.eoi.enabled) {
      // if (projectData?.tags) allowedUnitTag = projectData?.tags;TODO: get this from database
      allowedUnitTag = [];
      for (let i = 0; i < userEOIs.length; i++) {
        if (!allowedUnitTag.includes(userEOIs[i].unitTag)) {
          allowedUnitTag.push(userEOIs[i].unitTag);
        }
      }
    } else {
      allowedUnitTag = ['ALL'];
    }
    if (unitList.length > 0 && allowedUnitTag.length) {
      const hierarchyUnitList = {};
      const _unitListById = {};
      const unitListAfterTagFilter = unitList.map((unit) => {
        if (
          unit.status == UNIT_STATUSES.AVAILABLE &&
          !hasCommonTag(allowedUnitTag, unit.tags) &&
          !allowedUnitTag.includes('ALL')
        ) {
          return { ...unit, status: UNIT_STATUSES.TAG_OUT_OF_PREFERENCE };
        }
        return unit;
      });
      for (let i = 0; i < unitListAfterTagFilter.length; i++) {
        const unit = unitListAfterTagFilter[i];
        const towerName = unit.towerName.toLowerCase();
        const section = unit.applicableFloorplan.toLowerCase();
        if (!towerName || !section) {
          continue;
        }
        let config = unit.number.slice(-2);
        if (unit.project.type == PROJECT_TYPE.PLOTS)
          config = unit.number.toLowerCase();

        if (!hierarchyUnitList[towerName]) {
          hierarchyUnitList[towerName] = {};
        }
        if (!hierarchyUnitList[towerName][section]) {
          hierarchyUnitList[towerName][section] = {};
        }
        if (!hierarchyUnitList[towerName][section][config]) {
          hierarchyUnitList[towerName][section][config] = [];
        }
        hierarchyUnitList[towerName][section][config].push(unit.id);
        _unitListById[unit.id] = unit;
      }

      dispatch(setHierarchyUnitList(hierarchyUnitList));
      dispatch(setUnitListById(_unitListById));
      dispatch(setThirdPartyUserId(thirdPartyUserId));
      // Stop loading
      dispatch(setAppSliceIsFetching(false));
    }
  }, [dispatch, unitList, userEOIs, projectData]);

  useEffect(() => {
    const filterPreferences = constructFilterPreferences(
      Object.values(unitListById)
    );
    dispatch(setFilterPreferences(filterPreferences));
  }, [unitListById]);

  useEffect(async () => {
    if (projectData?.id && projectData?.eoi.enabled && user?.id) {
      dispatch(setAppSliceIsFetching(true));

      const { data: eoiData } = await getEOIForUserAPI({
        userId: user.id,
      });

      const activeEOIs = eoiData.data.filter(
        (eoi) =>
          projectData.id == eoi.project &&
          eoi.status == CURRENT_STEP_STATE.ACTIVE_BOOKING_ENABLED
      );

      if (!activeEOIs.length) {
        addToast({
          type: 'ERROR',
          primaryMessage: 'No active eoi found',
          timeout: 1000,
        });
        // TODO: Logout
      } else {
        dispatch(setUserEOIs(activeEOIs));
      }

      dispatch(setAppSliceIsFetching(false));
    }
  }, [user?.id, projectData?.id]);

  useEffect(() => {
    dispatch(setUserLanguage(userLanguage));
  }, [userLanguage]);

  useEffect(() => {
    if (!isEmpty(projectData)) {
      if (!projectData.booking.thirdPartyIntegrationEnabled && isEmpty(user)) {
        dispatch(setShowAuthForms(true));
      }
      dispatch(setProject(projectData));
    }
  }, [projectData]);

  useEffect(() => {
    if (!isQueryParamValid) {
      addToast({
        type: 'ERROR',
        primaryMessage: 'Project details & User details are wrong',
        secondaryMessage: 'Please select correct project',
        timeout: 1000 * 60 * 60,
      });
      if (projectData.booking.thirdPartyIntegrationEnabled) {
        window.parent.postMessage(
          {
            code: 'INVALID_QUERY_PARAMS',
            message: 'Please select the correct project ID',
            data: {},
          },
          HELIOS_ORIGIN // TODO: Make it generic
        );
      }
    }
  }, []);

  useEffect(() => {
    if (!isEmpty(unitListByThirdPartyProjectId?.data)) {
      setUnitList(unitListByThirdPartyProjectId?.data);
    }
    if (!isEmpty(unitListByProjectId?.data)) {
      setUnitList(unitListByProjectId?.data);
    }
  }, [unitListByThirdPartyProjectId, unitListByProjectId]);

  return (
    <>
      <div
        className={`loading-overlay ${
          appSliceIsFetching && 'loading-overlay--enabled'
        }`}>
        <span>loading...</span>
      </div>
      <LandscapeFlashScreen />
      <div className={`app-main ${appSliceIsFetching && 'app-main--loading'}`}>
        {/* Header */}
        <Header />

        <div className='app-body'>
          {/* Sidebars */}
          <Switch>
            <Route
              path={URLS.DASHBOARD_BASE}
              component={DashbaordSidebar}></Route>
            <Route path={URLS.BOOKING_BASE} component={BookingSidebar}></Route>
          </Switch>
          {/* Content */}
          <Switch>
            <Route
              path={URLS.DASHBOARD_BASE}
              component={DashboardRoutes}></Route>
            <Route path={URLS.BOOKING_BASE} component={BookingRoutes}></Route>
          </Switch>
        </div>
      </div>
    </>
  );
};

export default ContentLayer;
