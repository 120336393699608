import { useEffect, useMemo, useState } from 'react';

import { Html5Qrcode } from 'html5-qrcode';
import {
  Html5QrcodeError,
  Html5QrcodeResult,
  Html5QrcodeScanType,
} from 'html5-qrcode/esm/core';

import { TObjectId } from 'interfaces';
import Button from 'apps/booking/components/common/buttons/Button';
import Icon from 'apps/booking/components/common/Icon';
import { DetailsFormState } from '../DetailsForm';
import {
  ISiteVisitDetails,
  SiteVisitState,
} from 'apps/admin/components/pages/forms/SiteVisit';
import { ICpLead } from 'apps/cp/interfaces';
import SpinLoader from 'apps/booking/components/common/SpinLoader';
import { IProject } from 'apps/admin/components/pages/SuperAdminDashboard/SuperAdminProjectList';
import { dateToDaythMonthYear } from 'utils/dateFormator';
import useToast from 'hooks/useToast';

import styles from './styles.module.css';

interface IQrCodeScannerProps {
  // onScannerSkip: () => void;
  cpLead?: ICpLead;
  loading: boolean;
  selectedProject?: IProject;
  contendingCpLeadIds: string[];
  siteVisitDetails: ISiteVisitDetails;
  setContendCpLeadIds: (ids: string[]) => void;
  onQrCodeCpLeadIdDetect: (cpLeadId: TObjectId) => void;
  setDetailsFormState: (state: DetailsFormState) => void;
  setSiteVisitState: (state: SiteVisitState) => void;
  setSiteVisitDetails: Function;
}

const scanningRules = [
  'Take QR code from channel partner of particular lead.',
  'Place the QR code inside the square area.',
  'Scanning will start automatically.',
];

const PROJECT_ERROR = 'Project name is not matching';
const LEAD_NUMBER_NOT_MATCHING_ERROR =
  "Lead has done site-visit for some project earlier and scanned mobile number is not matching with the customer's mobile number";
const SAME_CP_ERROR = '   Customer Scanned the QR code of same Channel Partner';

const QrCodeScanner = (props: IQrCodeScannerProps) => {
  const {
    cpLead,
    loading,
    selectedProject,
    contendingCpLeadIds,
    siteVisitDetails,
    setContendCpLeadIds,
    setDetailsFormState,
    setSiteVisitState,
    onQrCodeCpLeadIdDetect,
    setSiteVisitDetails,
  } = props;

  const [showCpDetails, setShowCpDetails] = useState(false);
  const [qrCodeDetails, setQrCodeDetails] = useState<{
    cli: TObjectId;
    pi: TObjectId;
  }>({ cli: '', pi: '' });
  const [sameCpScanned, setSameCpScanned] = useState(false);
  const [cpLeadIdMap, setCpLeadIdMap] = useState<Map<string, string>>(
    new Map()
  );

  const [addToast] = useToast();

  const cpLeadProject = useMemo(() => {
    return cpLead?.projects.find((project) => project.id === qrCodeDetails.pi);
  }, [cpLead, qrCodeDetails]);

  const cpLeadIds = useMemo(() => {
    const ids = [...contendingCpLeadIds];
    if (qrCodeDetails.cli && !ids.includes(qrCodeDetails.cli)) {
      ids.push(qrCodeDetails.cli);
    }
    return ids;
  }, [contendingCpLeadIds, qrCodeDetails]);

  const showErrorMessg = useMemo(() => {
    if (qrCodeDetails.pi !== selectedProject?.id) {
      return PROJECT_ERROR;
    }

    if (
      cpLead?.user &&
      cpLead?.user.mobile != siteVisitDetails.customerMobile
    ) {
      return LEAD_NUMBER_NOT_MATCHING_ERROR;
    }
    if (sameCpScanned) {
      return SAME_CP_ERROR;
    }
    return '';
  }, [qrCodeDetails, selectedProject, cpLead, siteVisitDetails, sameCpScanned]);

  useEffect(() => {
    if (cpLead) {
      if (
        cpLeadIdMap.has(cpLead.cpUser.id) &&
        !contendingCpLeadIds.includes(
          cpLeadIdMap.get(cpLead.cpUser.id) as string
        )
      ) {
        cpLeadIdMap.delete(cpLead.cpUser.id);
        return;
      }
      if (
        cpLeadIdMap.has(cpLead.cpUser.id) &&
        cpLead.id !== cpLeadIdMap.get(cpLead.cpUser.id)
      ) {
        setSameCpScanned(true);
        return;
      }
      const map = cpLeadIdMap.set(cpLead.cpUser.id, cpLead.id);
      setCpLeadIdMap(map);
      setSameCpScanned(false);
    }
  }, [cpLead, contendingCpLeadIds]);

  useEffect(() => {
    if (cpLead && qrCodeDetails.pi) {
      setShowCpDetails(true);
    }
  }, [cpLead, qrCodeDetails.pi]);

  useEffect(() => {
    const html5QrCode = new Html5Qrcode('reader');
    const qrCodeSuccessCallback = (
      decodedText: string,
      result: Html5QrcodeResult
    ) => {
      let scannedCodeDetails: any = {};
      try {
        scannedCodeDetails = JSON.parse(decodedText);
        if (!scannedCodeDetails || !scannedCodeDetails.cli) {
          // Old QR code
          const url = new URL(decodedText);
          const searchParams = url.searchParams;

          scannedCodeDetails.cli = searchParams.get('li');
          scannedCodeDetails.pi = searchParams.get('pi');
        }
      } catch (err) {
        // Old QR code
        const url = new URL(decodedText);
        const searchParams = url.searchParams;

        scannedCodeDetails.cli = searchParams.get('li');
        scannedCodeDetails.pi = searchParams.get('pi');
      }

      setQrCodeDetails(scannedCodeDetails);
      const cpLeadId = scannedCodeDetails.cli;

      if (cpLeadId) {
        onQrCodeCpLeadIdDetect(cpLeadId);
      }
    };

    const qrCodeErrorCallback = (
      errorMessage: string,
      error: Html5QrcodeError
    ) => {
      // alert(errorMessage);
      // alert(error);
    };

    const config = {
      fps: 10,
      qrbox: { width: 250, height: 250 },
      supportedScanTypes: [
        Html5QrcodeScanType.SCAN_TYPE_CAMERA,
        Html5QrcodeScanType.SCAN_TYPE_FILE,
      ],
    };
    // Select back camera or fail with `OverconstrainedError`.
    html5QrCode.start(
      { facingMode: { exact: 'environment' } },
      config,
      qrCodeSuccessCallback,
      qrCodeErrorCallback
    );

    // return () => {
    //   html5QrCode
    //     .stop()
    //     .then((ignore) => {
    //       // QR Code scanning is stopped.
    //     })
    //     .catch((err) => {
    //       // Stop failed, handle it.
    //     });
    // };
  }, []);

  const handleUploadQrCodeImage = (e: any) => {
    const html5QrCode = new Html5Qrcode('reader');

    if (e.target.files.length == 0) {
      // No file selected, ignore
      return;
    }

    // Use the first item in the list
    const imageFile = e.target.files[0];
    html5QrCode
      .scanFile(imageFile)
      .then((qrCodeMessage) => {
        const scannedCodeDetails = JSON.parse(qrCodeMessage);

        setQrCodeDetails(scannedCodeDetails);
        const cpLeadId = scannedCodeDetails.cli;

        if (cpLeadId) {
          onQrCodeCpLeadIdDetect(cpLeadId);
        } else {
          addToast({
            primaryMessage: 'QR code is invalid',
            type: 'ERROR',
          });
        }
      })
      .catch((err) => {
        console.log(`Error scanning file. Reason: ${err}`);
        addToast({
          primaryMessage: 'QR code is invalid',
          type: 'ERROR',
        });
      });
  };

  const handleBack = () => {
    setSiteVisitState(SiteVisitState.DETAILS_FORM);
    setDetailsFormState(DetailsFormState.SOURCESELECTION);
    setShowCpDetails(false);
    setQrCodeDetails({
      cli: '',
      pi: '',
    });
    setContendCpLeadIds([]);
    setCpLeadIdMap(new Map());
  };

  const handleAccept = () => {
    setSiteVisitDetails((prevState: ISiteVisitDetails) => {
      return { ...prevState, projectId: qrCodeDetails.pi };
    });
    setQrCodeDetails({
      cli: '',
      pi: '',
    });
    setContendCpLeadIds(cpLeadIds);
    setSiteVisitState(SiteVisitState.DETAILS_FORM);
    setDetailsFormState(DetailsFormState.CPINFO);
  };

  const handleRetry = () => {
    setShowCpDetails(false);
    setQrCodeDetails({
      cli: '',
      pi: '',
    });
    setSameCpScanned(false);
  };

  return (
    <div className={styles.container}>
      <div id='reader' className={styles.qrCodeContainer}></div>

      {!cpLead && loading && (
        <div className={styles.infoContainer}>
          <SpinLoader />
        </div>
      )}
      {!showCpDetails && (
        <div className={styles.infoContainer}>
          <span className={styles.infoHeading}>How to scan QR Code</span>
          <div className={`${styles.info} ${styles.disabled}`}>
            <p className={styles.smallDesc}>
              Channel partner QR code scan successfully. Below are the details
              of Channel Partner.
            </p>
            {scanningRules.map((rules, index: number) => (
              <div className={styles.listWrap} key={index}>
                <span className={styles.horizontalLine} />
                <p className={styles.smallText}>{rules}</p>
              </div>
            ))}
          </div>
          <div className={styles.buttonWrap}>
            <Button propStyles={styles.backButton} onClick={handleBack}>
              Back
            </Button>
            <div className={styles.uploadFileWrapper}>
              <Button propStyles={styles.uploadBtn} accent='primary'>
                Upload from Gallery
              </Button>
              <input
                type='file'
                accept='image/*'
                className={styles.uploadFile}
                onChange={handleUploadQrCodeImage}
              />
            </div>
          </div>
        </div>
      )}
      {showCpDetails && (
        <div className={styles.infoContainer}>
          <span className={styles.infoHeading}>Data Scanned Successfully</span>
          <div className={styles.info}>
            <p className={styles.smallDesc}>
              Channel partner QR code scan successfully. Below are the details
              of Channel Partner.
            </p>
            <div className={styles.dataCard}>
              <p className={styles.cardHeading}>Channel Partner Information</p>
              <div className={styles.dataType}>
                <p className={styles.dataLabel}>Name of Channel Partner</p>
                <p className={styles.dataText}>{cpLead?.cpUser.user.name}</p>
              </div>
              <div className={styles.dataType}>
                <p className={styles.dataLabel}>Date of Registration</p>
                <p className={styles.dataText}>
                  {cpLeadProject?.activities?.length &&
                    dateToDaythMonthYear(
                      cpLeadProject.activities[0].activityDate
                    )}
                </p>
              </div>
              <div className={styles.dataType}>
                <p className={styles.dataLabel}>Project Name</p>
                <p className={styles.dataText}>{cpLeadProject?.name}</p>
              </div>
            </div>
            {showErrorMessg && (
              <div className={styles.errorDisplay}>
                <Icon name='error_outline' propStyles={styles.errorIcon} />
                <p className={styles.errorText}>{showErrorMessg}</p>
              </div>
            )}

            {!showErrorMessg &&
              cpLead &&
              !siteVisitDetails.customerMobile.includes(
                cpLead.mobileLastFourDigits
              ) && (
                <div className={styles.warningDisplay}>
                  <Icon name='error_outline' propStyles={styles.warningIcon} />
                  <p className={styles.errorText}>
                    Last 4 digit mobile no. is not matching
                  </p>
                </div>
              )}
          </div>
          <div className={styles.buttonWrap}>
            <Button propStyles={styles.backButton} onClick={handleBack}>
              Back
            </Button>
            <Button
              accent='primary'
              propStyles={styles.bottomButton}
              onClick={showErrorMessg ? handleRetry : handleAccept}>
              {showErrorMessg ? 'Retry' : 'Accept'}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default QrCodeScanner;
