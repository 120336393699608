import { IMinMaxRange } from '../apps/admin/components/pages/SuperAdminDashboard/SuperAdminProjectList';

export const DEFAULT_PROPERTY_CONFIGS = [
  '1 BHK',
  '2 BHK',
  '2 BHK Duplex',
  '3 BHK',
  '3 BHK Duplex',
  '4 BHK +',
];

export const ADDLEAD_PROPERTY_CONFIGS = [
  { displayText: '1', value: '1 BHK' },
  { displayText: '2', value: '2 BHK' },
  { displayText: '3', value: '3 BHK' },
  { displayText: '4', value: '4 BHK' },
  { displayText: '5+', value: '5+ BHK' },
  { displayText: 'Duplex', value: 'Duplex' },
  { displayText: 'Bunglow', value: 'Bunglow' },
  { displayText: 'Others', value: 'Others' },
];

export const ONE_CRORE = 10000000;
export const ONE_LAKH = 100000;

export const DEFAULT_PROPERTY_PRICE_RANGE: IMinMaxRange[] = [
  {
    min: 1000000,
    max: 5000000,
  },
  {
    min: 5000001,
    max: 10000000,
  },
  {
    min: 10000001,
    max: 50000000,
  },
  {
    min: 50000001,
    max: 100000000,
  },
];

export const DEFAULT_CITIES = [
  'Mumbai',
  'Pune',
  'Delhi',
  'Chennai',
  'Banglore',
  'Hyderabad',
  'Guwahati',
  'Jodhpur',
];
export const DEFAULT_RESIDENTIAL_CONFIGS = ['Residential', 'Commercial'];

export const locationMumbai = [
  'Airoli',
  'Ambernath',
  'Ambivli',
  'Andheri',
  'Apta',
  'Asangaon',
  'Atgaon',
  'Badlapur',
  'Bamandongri',
  'Bandra',
  'Bhandup',
  'Bhayandar',
  'Bhivpuri Road',
  'Bhiwandi Road',
  'Boisar',
  'Borivali',
  'Byculla',
  'CBD Belapur',
  'Charni Road',
  'Chembur',
  'Chikhloli',
  'Chinchpokli',
  'Chunabhatti',
  'Churchgate',
  'Cotton Green',
  'Currey Road',
  'Dadar',
  'Dahanu Road',
  'Dahisar',
  'Dativali',
  'Dighe',
  'Diva',
  'Dockyard Road',
  'Dolavli',
  'Dombivali',
  'Dronagiri',
  'Gavhan',
  'Ghansoli',
  'Ghatkopar',
  'Goregaon',
  'Govandi',
  'Grant Road',
  'Guru Tegh Bahadur Nagar',
  'Hamrapur',
  'Jite',
  'Jogeshwari',
  'Juchandra',
  'Juinagar',
  'Kalamboli',
  'Kalwa',
  'Kalyan',
  'Kaman Road',
  'Kandivali',
  'Kanjurmarg',
  'Karjat',
  'Kasara',
  'Kasu',
  'Kelavli',
  'Kelve Road',
  'Khadavli',
  'Khandeshwar',
  'Khar',
  'Kharbao',
  'Khardi',
  'Kharghar',
  'Kharkopar',
  'Khopoli',
  "King's Circle",
  'Kopar',
  'Kopar Khairane',
  'Kurla',
  'Lower Parel',
  'Lowjee',
  'Mahalaxmi',
  'Mahim',
  'Malad',
  'Mankhurd',
  'Mansarovar',
  'Marine Lines',
  'Masjid',
  'Matunga',
  'Mira Road',
  'Mulund',
  'Mumbra',
  'Nagothane',
  'Nahur',
  'Naigaon',
  'Nallasopara',
  'Navade Road',
  'Neral',
  'Nerul',
  'Nhava Sheva',
  'Nidi',
  'Nilaje',
  'Palasdari',
  'Palghar',
  'Panvel',
  'Parel',
  'Pen',
  'Prabhadevi',
  'Rabale',
  'Ram Mandir',
  'Ranjanpada',
  'Rasayani',
  'Reay Road',
  'Roha',
  'Sagar Sangam',
  'Sandhurst Road',
  'Sanpada',
  'Santacruz',
  'Saphale',
  'Seawoods',
  'Sewri',
  'Shahad',
  'Shelu',
  'Sion',
  'Somtane',
  'Taloja',
  'Targhar',
  'Thakurli',
  'Thane',
  'Thansit',
  'Tilak Nagar',
  'Titwala',
  'Turbhe',
  'Ulhasnagar',
  'Umbermali',
  'Umroli',
  'Uran City',
  'Vaitarna',
  'Vangani',
  'Vangaon',
  'Vasai',
  'Vasai Road',
  'Vashi',
  'Vasind',
  'Vidyavihar',
  'Vikhroli',
  'Vile Parle',
  'Virar',
  'Vithalwadi',
  'Wadala',
];

export const locationPune = [
  'Ambegaon Budruk',
  'Aundh',
  'Baner',
  'Bavdhan',
  'Balewadi',
  'Shivajinagar',
  'Bibvewadi',
  'Bhugaon',
  'Bhukum',
  'Dhankawadi',
  'Dhanori',
  'Dhayari',
  'Erandwane',
  'Fursungi',
  'Ghorpadi',
  'Hadapsar',
  'Hingne Khurd',
  'Karve Nagar',
  'Kalas',
  'Katraj',
  'Khadki',
  'Kharadi',
  'Kondhwa',
  'Koregaon Park',
  'Kothrud',
  'Lohagaon',
  'Manjri',
  'Markal',
  'Mohammed Wadi',
  'Mundhwa',
  'Nanded',
  'Parvati (Parvati Hill)',
  'Panmala',
  'Pashan',
  'Pirangut',
  'Shivane',
  'Sus',
  'Undri',
  'Vishrantwadi',
  'Vitthalwadi',
  'Vadgaon Khurd',
  'Vadgaon Budruk',
  'Vadgaon Sheri',
  'Wagholi',
  'Wanwadi',
  'Warje',
  'Yerwada',
  'Akurdi',
  'Bhosari',
  'Chakan',
  'Charholi Budruk',
  'Chikhli',
  'Chimbali',
  'Chinchwad',
  'Dapodi',
  'Dehu Road',
  'Dighi',
  'Dudulgaon',
  'Hinjawadi',
  'Kalewadi',
  'Kasarwadi',
  'Maan',
  'Moshi',
  'Phugewadi',
  'Pimple Gurav',
  'Pimple Nilakh',
  'Pimple Saudagar',
  'Pimpri',
  'Ravet',
  'Rahatani',
  'Sangvi',
  'Talawade',
  'Tathawade',
  'Thergaon',
  'Wakad',
  'Ambi',
  'Parandwadi',
  'Somatne',
  'Talegaon',
  'Urse',
  'Vadgaon Maval',
  'Varale',
  'Kasba Peth',
  'Guruwar Peth',
  'Somwar Peth',
  'Mangalwar Peth',
  'Shukrawar Peth',
  'Raviwar Peth',
  'Shaniwar Peth',
  'Bhavani Peth',
  'Ghorpade Peth',
  'Budhwar Peth',
  'Ganesh Peth',
  'Sadashiv Peth',
  'Narayan Peth',
  'Rasta Peth',
  'Nana Peth',
  'Mahatma Phule Peth',
  'Navi Peth',
];

export const PROJECT_TYPE = {
  PLOTS: 'PLOTS',
  TOWERS: 'TOWERS',
};
