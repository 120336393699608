import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import HeadTitle from 'apps/booking/components/common/TitleHeading';
import Tabs, { ITab } from 'apps/cp/components/common/Tabs';
import SiteVisitTiles from './SiteVisitTiles';
import { getCpUser } from 'apps/cp/slices/cpUserSlice';
import { TObjectId } from 'interfaces';
import { ISiteVistlead } from 'apps/cp/interfaces';
import { useGetSheduledSiteVisitByCpUserMutation } from 'apps/cp/services/cpSiteVisitAPISlice';

import styles from './styles.module.css';

export const SCHEDULED_SITE_VISIT_STATUS = {
  SCHEDULED_SITE_VISIT: 'SCHEDULED_SITE_VISIT',
  SITE_VISIT_DONE: 'SITE_VISIT_DONE',
  MISSED: 'MISSED',
  CANCEL: 'CANCEL',
  CONFIRM: 'CONFIRM',
  RESCHEDULED_SITE_VISIT: 'RESCHEDULED_SITE_VISIT',
};

const CrSiteVisitPage = () => {
  const [cpUserSiteVisitDetails, setCpUserSiteVisitDetails] = useState<
    ISiteVistlead[]
  >([]);
  const [showLoader, setShowLoader] = useState(false);

  const cpUser = useSelector(getCpUser);

  const [getSheduledSiteVisitByCpUserAPI] =
    useGetSheduledSiteVisitByCpUserMutation();

  const handleSiteVisitCall = async () => {
    try {
      setShowLoader(true);
      const response = await getSheduledSiteVisitByCpUserAPI(
        cpUser?.id as TObjectId
      ).unwrap();
      setCpUserSiteVisitDetails(response.data);
    } catch (err) {
    } finally {
      setShowLoader(false);
    }
  };

  useEffect(() => {
    handleSiteVisitCall();
  }, [cpUser?.cpLeads]);

  const sheduledSiteVisit = useMemo(() => {
    return cpUserSiteVisitDetails.filter(
      (userDetails) =>
        userDetails.status ===
          SCHEDULED_SITE_VISIT_STATUS.SCHEDULED_SITE_VISIT ||
        userDetails.status ===
          SCHEDULED_SITE_VISIT_STATUS.RESCHEDULED_SITE_VISIT ||
        userDetails.status === SCHEDULED_SITE_VISIT_STATUS.CONFIRM
    );
  }, [cpUserSiteVisitDetails]);

  const missedSitevist = useMemo(() => {
    return cpUserSiteVisitDetails.filter(
      (userDetails) =>
        userDetails.status === SCHEDULED_SITE_VISIT_STATUS.MISSED ||
        userDetails.status === SCHEDULED_SITE_VISIT_STATUS.CANCEL
    );
  }, [cpUserSiteVisitDetails]);

  const completedSiteVisit = useMemo(() => {
    return cpUserSiteVisitDetails.filter(
      (userDetails) =>
        userDetails.status === SCHEDULED_SITE_VISIT_STATUS.SITE_VISIT_DONE
    );
  }, [cpUserSiteVisitDetails]);

  const tabs: ITab[] = [
    {
      id: 'scheduled',
      name: 'Scheduled',
      content: (
        <>
          <SiteVisitTiles
            siteVisitleads={sheduledSiteVisit}
            showLoader={showLoader}
            showSearch
          />
        </>
      ),
      disabled: false,
    },
    {
      id: 'completed',
      name: 'Completed',
      content: (
        <>
          <SiteVisitTiles
            siteVisitleads={completedSiteVisit}
            showLoader={showLoader}
            showSearch
          />
        </>
      ),
      disabled: false,
    },
    {
      id: 'missed',
      name: 'Missed',
      content: (
        <>
          <SiteVisitTiles
            siteVisitleads={missedSitevist}
            showLoader={showLoader}
            showSearch
          />
        </>
      ),
      disabled: false,
    },
  ];

  return (
    <div className={styles.listContainer}>
      <div className={styles.headerWrap}>
        <HeadTitle>Site Visits</HeadTitle>
      </div>
      <div className={styles.leadItemWrap}>
        <Tabs tabs={tabs} propStyles={styles.tabs} selectedTab={tabs[0]} />
      </div>
    </div>
  );
};

export default CrSiteVisitPage;
