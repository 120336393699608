import { useState } from 'react';

import Icon from 'apps/booking/components/common/Icon';
import { IUser } from 'apps/admin/interfaces';
import UserDetailsModal from 'apps/admin/components/pages/AdminDashboard/AdminExecutiveList/modals/UserDetailsModal';

interface IUserDetailsCell {
  user: IUser;
}

const UserDetailsCell = (props: IUserDetailsCell) => {
  const { user } = props;

  const [executiveDetailsModalShown, setExecutiveDetailsModalShown] =
    useState(false);

  return (
    <>
      {executiveDetailsModalShown && (
        <UserDetailsModal
          onModalClose={() => setExecutiveDetailsModalShown(false)}
          user={user}
        />
      )}
      <Icon
        name='details'
        propStyles='actionIcon'
        onClick={() => setExecutiveDetailsModalShown(true)}
      />
    </>
  );
};

export default UserDetailsCell;
