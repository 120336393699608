import Icon from '../../Icon';
import S3ClientNew from '../../../../../../utils/S3ClientNew';

import styles from './styles.module.css';

interface IDisplayInputsProps {
  displayInputs?: string[];
  type?: 'image' | 'keyValue';
  setIndex?: (index: number) => void;
  onRemove?: (label: string) => void;
}

const DisplayInputs = (props: IDisplayInputsProps) => {
  const { displayInputs, type, onRemove, setIndex } = props;

  const handleClickOnLabelText = (index: number) => {
    setIndex && setIndex(index);
  };

  return (
    <div className={styles.labelContainer}>
      {displayInputs?.map((label, index: number) => (
        <div className={styles.labelWrap}>
          <span
            className={styles.label}
            onClick={() => handleClickOnLabelText(index)}>
            {type === 'image' ? S3ClientNew.fileName(label) : label}
          </span>
          <Icon
            propStyles={styles.filterClose}
            onClick={() =>
              onRemove &&
              onRemove(type === 'keyValue' ? label.split(':')[0] : label)
            }
            name='close'
          />
        </div>
      ))}
    </div>
  );
};

export default DisplayInputs;
