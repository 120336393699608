import { useEffect, useState } from 'react';

import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import Button from 'apps/booking/components/common/buttons/Button';
import Icon from 'apps/booking/components/common/Icon';
import TextInput from 'apps/booking/components/common/inputs/TextInput';
import Modal from 'apps/booking/components/common/modal/Modal';
import FileUploadNew from 'apps/booking/components/common/inputs/FileUploadNew';
import useToast from 'hooks/useToast';
import { useUpdateAdminUserMutation } from 'apps/admin/services/adminUserAPISlice';
import { ICpUser } from 'apps/cp/interfaces';
import { useUpdateCpUserMutation } from 'apps/cp/services/cpUserAPISlice';

import styles from './styles.module.css';

interface IUpdateCpUserModalProps {
  cpUser: ICpUser;
  onModalClose: () => void;
  onCpUserUpdate: (updateCpUser: ICpUser) => void;
}

const userSchema = yup.object().shape({
  user: yup.object({
    email: yup.string().email().required(),
    name: yup.string().required(),
    mobile: yup
      .string()
      .matches(/^[0-9]*$/)
      .length(10)
      .required(),
  }),

  companyTemp: yup.object({
    name: yup.string().required(),
  }),

  panNumber: yup.string().required(),
  gstNumber: yup.string().required(),
  rera: yup.object({
    number: yup.string().required(),
  }),
});

const UpdateCpUserModal = (props: IUpdateCpUserModalProps) => {
  const { cpUser, onCpUserUpdate, onModalClose } = props;

  const {
    register,
    unregister,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(userSchema),
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });

  const [updateDisabled, setUpdateDisabled] = useState(false);
  const [fileUploadingStatus, setFileUploadingStatus] = useState<{
    [fileId: string]: boolean;
  }>({});

  const [addToast] = useToast();
  const [updateCpUserAPI] = useUpdateCpUserMutation();
  const [updateUserAPI] = useUpdateAdminUserMutation();

  // TODO: remove any
  const handleUpdateCpUser = async (cpUserData: any) => {
    try {
      await updateUserAPI({
        userId: cpUser.user.id as string,
        user: cpUserData.user,
      }).unwrap();

      /*----- we are deleting user object from cpUser as we are making diff api call to update user details which is nested under cpUser------*/
      delete cpUserData.user;

      const response = await updateCpUserAPI({
        cpUserId: cpUser.id,
        ...cpUserData,
      }).unwrap();

      const updatedCpUser: ICpUser = response.data;

      onCpUserUpdate(updatedCpUser);
      addToast({
        type: 'SUCCESS',
        primaryMessage: 'Successfully Updated CpUser Details',
      });
      onModalClose();
    } catch (err) {
      const errMsg = (err as any).data.message;
      addToast({
        type: 'ERROR',
        primaryMessage: errMsg,
      });
    }
  };

  const toogleFileUploadStatus = (
    fileId: string,
    isUploadInProgress: boolean
  ) => {
    setFileUploadingStatus((prevState) => {
      return {
        ...prevState,
        [fileId]: isUploadInProgress,
      };
    });
  };

  useEffect(() => {
    const isUploading = Object.values(fileUploadingStatus).some(
      (status) => status
    );
    setUpdateDisabled(isUploading);
  }, [fileUploadingStatus]);

  return (
    <div>
      <Modal
        onOutsideClick={() => onModalClose()}
        additionalClasses={styles.modalAdditionalClasses}>
        <header className={styles.modalHeader}>
          <div className={styles.modalHeaderLeftContent}>
            <h1>Update Channel Partner!</h1>
            <p>
              Fill the following information to update Channel Partner details
            </p>
          </div>
          <Icon
            name='close'
            propStyles={styles.modalCloseIcon}
            onClick={() => onModalClose()}
          />
        </header>

        <main>
          <div className={styles.bodyIndividualdRow}>
            <div className={styles.inputsContainer}>
              <TextInput
                defaultValue={cpUser.user.name}
                label='User Name'
                placeHolder='Name of the user'
                register={register}
                setValue={setValue}
                unregister={unregister}
                name='user.name'
                errorMessage={errors.user?.name && 'User name is required'}
              />
            </div>
            <div className={styles.inputsContainer}>
              <TextInput
                label='User Email'
                defaultValue={cpUser.user.email}
                placeHolder='Email ID'
                register={register}
                setValue={setValue}
                unregister={unregister}
                name='user.email'
                errorMessage={errors.user?.email && 'Please enter valid email'}
              />
            </div>
          </div>

          <div className={styles.bodyIndividualdRow}>
            <div className={styles.inputsContainer}>
              <TextInput
                label='Mobile'
                defaultValue={cpUser.user.mobile}
                placeHolder='Mobile Number'
                register={register}
                setValue={setValue}
                unregister={unregister}
                name='user.mobile'
                maxLength={10}
                errorMessage={
                  errors?.user?.mobile && 'Mobile Number is required'
                }
              />
            </div>
          </div>

          <div className={styles.bodyIndividualdRow}>
            <div className={styles.inputsContainer}>
              <TextInput
                label='PAN number'
                defaultValue={cpUser.panNumber}
                placeHolder='PAN number'
                register={register}
                setValue={setValue}
                unregister={unregister}
                name='panNumber'
                errorMessage={errors.panNumber && 'PAN number is required'}
              />
            </div>
            <div className={styles.inputsContainer}>
              <FileUploadNew
                placeholder='Upload Pan Card'
                label='Upload PAN card image'
                defaultValue={cpUser.panUrl}
                s3DirPath={`cpApp/cpUser/${cpUser.id}/documents/pan`}
                onChangeFileUploadStatus={toogleFileUploadStatus}
                register={register}
                unregister={unregister}
                name='panUrl'
                setValue={setValue}
                errorMessage={errors.panUrl}
                appType='RELATA'
              />
            </div>
          </div>
          <div className={styles.bodyIndividualdRow}>
            <div className={styles.inputsContainer}>
              <TextInput
                label='GST number'
                defaultValue={cpUser.gstNumber}
                placeHolder='GST number'
                register={register}
                setValue={setValue}
                unregister={unregister}
                name='gstNumber'
                errorMessage={errors.gstNumber && 'GST number is required'}
              />
            </div>
            <div className={styles.inputsContainer}>
              <FileUploadNew
                placeholder='Upload Gst Card'
                label='Upload GST card image'
                defaultValue={cpUser.gstUrl}
                s3DirPath={`cpApp/cpUser/${cpUser.id}/documents/gst`}
                onChangeFileUploadStatus={toogleFileUploadStatus}
                register={register}
                unregister={unregister}
                name='gstUrl'
                setValue={setValue}
                errorMessage={errors.gstUrl}
                appType='RELATA'
              />
            </div>
          </div>
          <div className={styles.bodyIndividualdRow}>
            <div className={styles.inputsContainer}>
              <TextInput
                label='RERA Number'
                defaultValue={cpUser.rera.number}
                placeHolder='RERA Number'
                register={register}
                setValue={setValue}
                unregister={unregister}
                name='rera.number'
                errorMessage={errors?.rera?.number && 'RERA Number is required'}
              />
            </div>
          </div>
          <div className={styles.bodyIndividualdRow}>
            <div className={styles.inputsContainer}>
              <TextInput
                label='Company name'
                defaultValue={cpUser.companyTemp?.name}
                placeHolder='Company name'
                register={register}
                setValue={setValue}
                unregister={unregister}
                name='companyTemp.name'
                errorMessage={
                  errors?.companyTemp?.name && 'Company name is required'
                }
              />
            </div>
            <div className={styles.inputsContainer}>
              <TextInput
                label='Company Url'
                defaultValue={cpUser.companyTemp?.websiteUrl}
                placeHolder='Company name'
                register={register}
                setValue={setValue}
                unregister={unregister}
                name='companyTemp.websiteUrl'
                errorMessage={errors?.companyTemp?.websiteUrl}
              />
            </div>
          </div>
          <div className={styles.bodyIndividualdRow}>
            <div className={styles.inputsContainer}>
              <TextInput
                label='Company contact number'
                defaultValue={cpUser.companyTemp?.pocMobile}
                placeHolder='Company contact number'
                register={register}
                setValue={setValue}
                unregister={unregister}
                name='companyTemp.pocMobile'
                errorMessage={errors?.companyTemp?.pocMobile}
              />
            </div>
          </div>
          <div className={styles.formDividerLineContainer}></div>
        </main>

        {/* Modal Footer */}
        <footer className={styles.modalFooter}>
          <div className={styles.createProjectBtnContainer}>
            <Button
              type='submit'
              propStyles={`${styles.createProjectBtn}`}
              disabled={updateDisabled}
              onClick={() => handleSubmit(handleUpdateCpUser)()}>
              Update Details
            </Button>
          </div>
        </footer>
      </Modal>
    </div>
  );
};

export default UpdateCpUserModal;
