import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import Button from '../../../../../../../booking/components/common/buttons/Button';
import Icon from '../../../../../../../booking/components/common/Icon';
import TextInput from '../../../../../../../booking/components/common/inputs/TextInput';
import Modal from '../../../../../../../booking/components/common/modal/Modal';
import { IProjectEvent, IProjectEventReq } from '../ProjectEventsModal';
import MultiTextInput from '../../../../../../../booking/components/common/inputs/MultiTextInput';
import { useUpdateProjectEventMutation } from '../../../../../../services/projectsAPISlice';
import useToast from '../../../../../../../../hooks/useToast';

import styles from './styles.module.css';

interface IProjectEventUpdateModal {
  projectEvent: IProjectEvent;
  onModalClose: () => void;
  onProjectEventUpdate: (updatedProject: IProjectEvent) => void;
}

const schema = yup.object().shape({
  title: yup.string().required(),
  venue: yup.string().required(),
  startDate: yup.string().required(),
  endDate: yup.string().required(),
});

const ProjectEventUpdateModal = (props: IProjectEventUpdateModal) => {
  const { projectEvent, onModalClose, onProjectEventUpdate } = props;

  const [descList, setDesList] = useState(projectEvent.descItems || []);

  const [addToast] = useToast();
  const [updateProjectEventAPI] = useUpdateProjectEventMutation();

  const {
    register,
    unregister,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });

  const handleUpdateProjectEvent = async (formData: IProjectEventReq) => {
    const projectEventData = {
      ...formData,
      descItems: descList,
    };

    try {
      const updatedProjectEvent = await updateProjectEventAPI({
        ...projectEventData,
        projectEventId: projectEvent.id,
      }).unwrap();

      onProjectEventUpdate(updatedProjectEvent.data);

      addToast({
        type: 'SUCCESS',
        primaryMessage: 'Project event is successfully Updated',
      });

      onModalClose();
    } catch (err) {
      const errMsg = (err as any).data.message;
      addToast({
        type: 'ERROR',
        primaryMessage: errMsg,
        secondaryMessage: 'Project event creation failed',
      });
    }
  };

  const handleAddDescList = (description: string) => {
    const descriptions: string[] = [...descList];
    descriptions.push(description);
    setDesList(descriptions);
  };

  const handleRemoveDescList = (description: string) => {
    setDesList(descList.filter((descItem) => descItem === description));
  };

  return (
    <Modal
      onOutsideClick={onModalClose}
      additionalClasses={styles.modalAdditionalClasses}>
      <header className={styles.modalHeader}>
        <div className={styles.modalHeaderLeftContent}>
          <h1>Update Events</h1>
          <p>
            Fill in the following info to update the event associated to the
            selected project.
          </p>
        </div>
        <Icon
          name='close'
          propStyles={styles.modalCloseIcon}
          onClick={onModalClose}
        />
      </header>

      <main>
        <div className={styles.bodyIndividualdRow}>
          <TextInput
            label='Title'
            placeHolder='Update title for the event'
            register={register}
            setValue={setValue}
            unregister={unregister}
            name='title'
            defaultValue={projectEvent.title}
            errorMessage={errors.title && 'Title is required'}
          />
        </div>
        <div className={styles.bodyIndividualdRow}>
          <TextInput
            label='Description'
            placeHolder='Update description for the event'
            register={register}
            setValue={setValue}
            unregister={unregister}
            name='desc'
            defaultValue={projectEvent.desc}
          />
        </div>

        <MultiTextInput
          label='Description Items'
          placeHolder='Update descriptions for the event'
          displayInputs={descList}
          onAdd={handleAddDescList}
          onRemove={handleRemoveDescList}
        />

        <div className={styles.bodyIndividualdRow}>
          <TextInput
            label='Venue'
            placeHolder='Update Venue for the event'
            register={register}
            setValue={setValue}
            unregister={unregister}
            name='venue'
            defaultValue={projectEvent.venue}
            errorMessage={errors.venue && 'Venue is required'}
          />
        </div>

        <div className={styles.bodyIndividualdRow}>
          <div className={styles.inputsContainer}>
            <TextInput
              label='Start Date'
              placeHolder=''
              type='datetime-local'
              register={register}
              setValue={setValue}
              unregister={unregister}
              name='startDate'
              // defaultValue={projectEvent.startDate}
              errorMessage={errors.startDate && 'Start Date is required'}
            />
          </div>

          <div className={styles.inputsContainer}>
            <TextInput
              label='End Date'
              placeHolder=''
              type='datetime-local'
              register={register}
              setValue={setValue}
              unregister={unregister}
              name='endDate'
              // defaultValue={projectEvent.endDate.toLocaleDateString()}
              errorMessage={errors.endDate && 'End Date is required'}
            />
          </div>
        </div>
        <div className={styles.formDividerLineContainer}></div>
      </main>

      {/* Modal Footer */}
      <footer className={styles.modalFooter}>
        <div className={styles.createProjectBtnContainer}>
          <Button
            type='submit'
            propStyles={`${styles.addEventBtn}`}
            onClick={() => {
              handleSubmit(handleUpdateProjectEvent)();
            }}>
            Update Event
          </Button>
        </div>
      </footer>
    </Modal>
  );
};

export default ProjectEventUpdateModal;
