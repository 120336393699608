import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import useToast from 'hooks/useToast';
import { TObjectId } from 'interfaces';
import Modal from 'apps/booking/components/common/modal/Modal';
import Icon from 'apps/booking/components/common/Icon';
import Button from 'apps/booking/components/common/buttons/Button';
import SearchDropDownInput from 'apps/booking/components/common/inputs/SearchDropDownInput';
import SpinLoader from 'apps/booking/components/common/SpinLoader';
import { useUpdateSmtoUnAssignedPartnerMutation } from 'apps/admin/services/projectLeadsAPI';
import { IUser } from 'apps/admin/interfaces';
import { useGetExecutivesByProjectIdMutation } from 'apps/admin/services/userRolesAPISlice';

import styles from './styles.module.css';

interface IAddingSmFormModalProps {
  onModalClose: () => void;
  projectId: TObjectId;
  cpFirmId?: TObjectId;
  cpUserId?: TObjectId;
}

const addingSmSchema = yup.object().shape({
  smName: yup.string().required(),
});

const AddingSmFormModal = (props: IAddingSmFormModalProps) => {
  const { projectId, cpFirmId, cpUserId, onModalClose } = props;

  const [addedSm, setIsAddedSm] = useState(true);
  const [executives, setExecutives] = useState<IUser[]>([]);
  const [isExecutivesLoding, setIsExecutiveLoading] = useState(false);

  const [addToast] = useToast();

  const [updateSmtoUnAssignedPartnerAPI] =
    useUpdateSmtoUnAssignedPartnerMutation();
  const [getExecutivesByProjectIdAPI] = useGetExecutivesByProjectIdMutation();

  const getExecutiveId = (executiveName: string) => {
    const executive = executives.find(
      (executive) => executive.name === executiveName
    );
    return executive?.id;
  };

  const {
    register,
    unregister,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(addingSmSchema),
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });

  const executiveNames = useMemo(() => {
    return executives.map((executive) => executive.name);
  }, [executives]);

  const buttonLabel = 'Tag SM';

  useEffect(() => {
    getEexecutiveList();
  }, [projectId]);

  const getEexecutiveList = async () => {
    try {
      setIsExecutiveLoading(true);
      const response = await getExecutivesByProjectIdAPI({
        projectId,
      }).unwrap();
      setExecutives(response.data);
    } catch (err) {
    } finally {
      setIsExecutiveLoading(false);
    }
  };

  const handleSmAdding = async (formData: { smName: string }) => {
    try {
      setIsAddedSm(true);
      const smId = getExecutiveId(formData.smName) as TObjectId;
      await updateSmtoUnAssignedPartnerAPI({
        projectId,
        smId,
        cpFirmId,
        cpUserId,
      }).unwrap();

      onModalClose();
      addToast({
        type: 'SUCCESS',
        primaryMessage: 'UnAssigned Partner is successfully Tagged',
      });
    } catch (err) {
      const errMsg = (err as any).data.message;
      addToast({
        type: 'ERROR',
        primaryMessage: errMsg,
      });
    } finally {
      setIsAddedSm(false);
    }
  };

  if (isExecutivesLoding) {
    <SpinLoader />;
  }

  const upDateAdd = async (params: String) => {
    cpUserId ? setIsAddedSm(false) : setIsAddedSm(true);
  };
  return (
    <Modal
      onOutsideClick={onModalClose}
      additionalClasses={styles.createNewAdminModal}>
      <header className={styles.modalHeader}>
        <div>
          <h2 className={styles.modalHeaderHeading}>Add SM</h2>
          <p className={styles.modalHeaderDes}>
            Adding SM for UnAssigned Partners with Firms and SM Is Not Tagged
          </p>
        </div>
        <Icon
          name='close'
          propStyles={styles.closeIcon}
          onClick={onModalClose}
        />
      </header>

      <form onSubmit={handleSubmit(handleSmAdding)}>
        <main>
          <div className={styles.twoTextInputsRowContainer}>
            <div className={styles.addNameInput}>
              <SearchDropDownInput
                label='SM name *'
                placeholder='select sm name'
                setValue={setValue}
                register={register}
                name='smName'
                suggestions={executiveNames}
                unregister={unregister}
                errorMessage={errors.smName && 'SM name is required'}
                propStyles={styles.searchOptions}
                onClick={upDateAdd}
              />
            </div>
          </div>
        </main>

        <footer className={styles.modalFooter}>
          <Button
            propStyles={styles.footerAddAdminButton}
            accent='primary'
            type='submit'
            disabled={addedSm}>
            {buttonLabel}
          </Button>
        </footer>
      </form>
    </Modal>
  );
};

export default AddingSmFormModal;
