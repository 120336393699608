import { useState } from 'react';

import useToast from 'hooks/useToast';
import TextInput from 'apps/booking/components/common/inputs/TextInput';
import Button from 'apps/booking/components/common/buttons/Button';
import { mobileValidationSchema } from 'utils/yupValidationSchemas';
import { LoginFormStates } from 'apps/admin/components/pages/AdminLogin/LoginForm';
import { OTP_TEMPLATE_RESET_PASSWORD } from 'constants/smsEmailTemplates';
import {
  useOtpv2SendMutation,
  useOtpv2VerifyMutation,
} from 'apps/admin/services/adminUserAPISlice';

import styles from '../../styles.module.css';

interface ISendOtpState {
  setLoginFormState: Function;
  handleMobile: Function;
  mobile: string;
  addressType: 'EMAIL' | 'MOBILE';
}

const SendOtpState = (props: ISendOtpState) => {
  const { setLoginFormState, handleMobile, mobile, addressType } = props;

  const [mobileErrorMessage, setMobileErrorMessage] = useState('');
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [otp, setOtp] = useState('');
  const [buttonDisabled, setButtonDisabled] = useState(true);

  const [addToast] = useToast();

  const [otpv2SendAPI] = useOtpv2SendMutation();
  const [otpv2VerifyAPI] = useOtpv2VerifyMutation();

  const handelMobileInputChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const mobile = event.target.value.slice(0, 10);
    if (mobile.length === 10) {
      handleMobile(mobile);
      // On mobile change, set is otp sent to false as we need to send opt for changed mobile number
      setIsOtpSent(false);

      const isValid = await mobileValidationSchema.isValid({
        value: mobile,
      });
      if (isValid) {
        // Mobile is valid, hide error message
        setButtonDisabled(false);
        setMobileErrorMessage('');
        return;
      }
    }
    setButtonDisabled(true);
  };
  const handelMobileBlur = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const mobile = event.target.value;
    const isValid = await mobileValidationSchema.isValid({ value: mobile });
    setMobileErrorMessage(!isValid ? 'Please enter a valid Phone Number' : '');
  };

  const handleOtpInputChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const otp = event.target.value;
    setOtp(otp);
    if (otp.length === 6) {
      setButtonDisabled(false);
      return;
    }
    setButtonDisabled(true);
  };

  const handleOtpSend = async () => {
    try {
      await otpv2SendAPI({
        address: mobile,
        addressType,
        otpTemplate: OTP_TEMPLATE_RESET_PASSWORD,
      }).unwrap();
      setIsOtpSent(true);
      setButtonDisabled(true);

      addToast({
        type: 'SUCCESS',
        primaryMessage: 'Sent the OTP on your mobile number',
        secondaryMessage: '',
        timeout: 1000,
      });
    } catch (err) {
      const errMsg = (err as any).data.message;
      addToast({
        type: 'ERROR',
        primaryMessage: errMsg,
        secondaryMessage: '',
        timeout: 1000,
      });
    }
  };
  const handleOtpVerify = async () => {
    try {
      await otpv2VerifyAPI({ address: mobile, otp }).unwrap();
      addToast({
        type: 'SUCCESS',
        primaryMessage: 'OTP verified successfully',
        secondaryMessage: '',
        timeout: 1000,
      });
      setLoginFormState(LoginFormStates.RESET_PASSWORD);
    } catch (err) {
      const errMsg = (err as any).data.message;
      addToast({
        type: 'ERROR',
        primaryMessage: errMsg,
        secondaryMessage: '',
        timeout: 1000,
      });
    }
  };

  return (
    <>
      <h2 className={styles.loginFormHeading}>
        Verify your identity with OTP to proceed
      </h2>
      <p className={styles.loginFormDesc}>
        To reset your password, enter your Phone Number to verify you.
      </p>
      <TextInput
        label='Phone Number'
        placeHolder='Enter Phone Number to verify'
        errorMessage={mobileErrorMessage}
        onChange={handelMobileInputChange}
        onBlur={handelMobileBlur}
        type='number'
        maxLength={10}
      />
      <TextInput
        label='Enter 6-digit OTP'
        placeHolder='otp'
        onChange={handleOtpInputChange}
        type='password'
        disabled={!isOtpSent}
        maxLength={6}
      />
      <Button
        propStyles={styles.loginButton}
        onClick={isOtpSent ? handleOtpVerify : handleOtpSend}
        disabled={buttonDisabled}>
        {isOtpSent ? 'Verify OTP' : 'Send OTP Now'}
      </Button>
      <Button
        propStyles={styles.forgotPasswordButton}
        onClick={() => setLoginFormState(LoginFormStates.LOGIN)}>
        Back To Login
      </Button>
    </>
  );
};

export default SendOtpState;
