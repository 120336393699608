import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import Modal from 'apps/booking/components/common/modal/Modal';
import Icon, { IconNames } from 'apps/booking/components/common/Icon';
import HeadTitle from 'apps/booking/components/common/TitleHeading';
import Button from 'apps/booking/components/common/buttons/Button';
import TextInput from 'apps/booking/components/common/inputs/TextInput';
import { ICpLead, ICpSiteVisit, ICpUser } from 'apps/cp/interfaces';
import useToast from 'hooks/useToast';
import { useUpdateSheduledSiteVisitByCpUserMutation } from 'apps/cp/services/cpSiteVisitAPISlice';
import { SCHEDULED_SITE_VISIT_STATUS } from 'apps/cp/components/pages/CrSiteVisitPage';
import { setAppSliceIsFetching } from 'slices/appSlice';
import { getCpUser, setCpUser } from 'apps/cp/slices/cpUserSlice';
import { useGetCpUserByMobileMutation } from 'apps/cp/services/cpUserAPISlice';
import { getMobileFromCpLead } from 'apps/cp/components/pages/CrLeadListPage/CpLeadItem';
import { isInValidDate } from 'utils/utils';

import styles from './styles.module.css';

interface ISiteVistConfirmationModelProps {
  cpLead: ICpLead;
  show: boolean;
  projectName: string;
  siteVisitData: ICpSiteVisit;
  onSetCpLeadRes?: (cpLead: ICpLead) => void;
  onClosePopup: () => void;
}

const schema = yup.object().shape({
  siteVisitDate: yup.string().required(),
});

const SiteVistConfirmationModel = (props: ISiteVistConfirmationModelProps) => {
  const {
    show,
    cpLead,
    siteVisitData,
    projectName,
    onClosePopup,
    onSetCpLeadRes,
  } = props;

  const [resheduleVisit, setResheduleVisit] = useState(false);
  const [confirmSiteVisit, setConfirmSiteVisit] = useState(false);
  const [cancelSiteVisit, setCancelSiteVisit] = useState(false);

  const dispatch = useDispatch();

  const {
    register,
    unregister,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });

  const [addToast] = useToast();
  const [updateSheduledSiteVisitByCpUserAPI] =
    useUpdateSheduledSiteVisitByCpUserMutation();
  const [getCpUserByMobileAPI] = useGetCpUserByMobileMutation();
  const cpUser = useSelector(getCpUser);

  useEffect(() => {
    if (show) {
      document.body.className = 'no-scroll';
    } else document.body.className = '';
  }, [show]);

  const handleClosePopup = () => {
    onClosePopup();
    setConfirmSiteVisit(false);
    setResheduleVisit(false);
    setCancelSiteVisit(false);
  };

  const handleSiteVisitAction = async (
    updatedVisitDate: Date,
    status: string
  ) => {
    const { project, cpUser: cpUserId, cpLead: cpLeadId, id } = siteVisitData;
    const payload: ICpSiteVisit = {
      visitDate: updatedVisitDate,
      project,
      cpUser: cpUserId,
      cpLead: cpLeadId,
      status,
    };

    try {
      dispatch(setAppSliceIsFetching(true));

      const response = await updateSheduledSiteVisitByCpUserAPI({
        sheduleVisitId: id,
        ...payload,
      }).unwrap();

      if (response.data) {
        const cpUserRes = await getCpUserByMobileAPI({
          address: cpUser?.user.mobile as string,
          addressType: 'MOBILE',
        }).unwrap();

        const updatedCpUser: ICpUser = cpUserRes.data;

        dispatch(setCpUser(updatedCpUser));
        const cpLeadRes = updatedCpUser.cpLeads.find(
          (lead) => lead.id == cpLead.id
        );
        onSetCpLeadRes && onSetCpLeadRes(cpLeadRes as ICpLead);
      }

      handleClosePopup();
    } catch (err) {
      const errMsg = (err as any).data?.message;
      addToast({
        type: 'ERROR',
        primaryMessage: errMsg,
      });
    } finally {
      dispatch(setAppSliceIsFetching(false));
    }
  };

  const handleConfirmedSiteVisit = async () => {
    await handleSiteVisitAction(
      siteVisitData.visitDate,
      SCHEDULED_SITE_VISIT_STATUS.CONFIRM
    );
  };

  const handleConfirmSiteVisitClick = () => {
    setConfirmSiteVisit(true);
  };

  const handleRescheduleSiteVisitClick = () => {
    setResheduleVisit(true);
  };

  const handleCancelSiteVisitClick = () => {
    setCancelSiteVisit(true);
  };

  const handleBackTositeVisit = () => {
    setResheduleVisit(false);
  };

  const handleResheduleVisit = () => {
    setCancelSiteVisit(false);
    handleRescheduleSiteVisitClick();
  };

  const handleCancelSiteVisit = async () => {
    await handleSiteVisitAction(
      siteVisitData.visitDate,
      SCHEDULED_SITE_VISIT_STATUS.CANCEL
    );
  };

  const handleRescheduleSiteVisit = async (date: { siteVisitDate: Date }) => {
    if (isInValidDate(date.siteVisitDate)) {
      addToast({
        type: 'ERROR',
        primaryMessage: 'Please select a today or later date',
      });
      return;
    }
    await handleSiteVisitAction(
      date.siteVisitDate,
      SCHEDULED_SITE_VISIT_STATUS.RESCHEDULED_SITE_VISIT
    );
  };

  const buttonVarients = [
    {
      buttonText: 'Confirm Sitevisit',
      icon: 'done',
      class: styles['green'],
      onClick: handleConfirmSiteVisitClick,
    },
    {
      buttonText: 'Re-Schedule Sitevisit',
      icon: 'replay',
      class: styles['grey'],
      onClick: handleRescheduleSiteVisitClick,
    },
    {
      buttonText: 'Cancel Sitevisit',
      icon: 'close',
      class: styles['orange'],
      onClick: handleCancelSiteVisitClick,
    },
  ];

  return (
    <Modal show={show} onOutsideClick={onClosePopup}>
      <div className={styles.container}>
        <div className={styles.headWrapper}>
          <HeadTitle>
            <>
              {cpLead.name} -<br /> {getMobileFromCpLead(cpLead)}
            </>
          </HeadTitle>
          <Icon
            onClick={handleClosePopup}
            name='close'
            propStyles={styles.closeIcon}
          />
        </div>
        <p className={styles.subHeading}>{projectName} | C45</p>
        {!(resheduleVisit || confirmSiteVisit || cancelSiteVisit) && (
          <div className={styles.contentWrap}>
            <p className={styles.contentHeading}>Update on Sitevisit </p>
            <p className={styles.detailText}>
              The following Firm details were found
            </p>
            {buttonVarients.map((buttonVarient) => (
              <div
                key={buttonVarient.buttonText}
                className={styles.buttonWrap}
                onClick={buttonVarient.onClick}>
                <Icon
                  name={buttonVarient.icon as IconNames}
                  propStyles={`${styles.buttonIcon} ${buttonVarient.class}`}
                />
                <p className={styles.buttonText}>{buttonVarient.buttonText}</p>
              </div>
            ))}
          </div>
        )}
        {confirmSiteVisit && (
          <div className={styles.visitConfirm}>
            <Icon
              name='task_alt'
              propStyles={`${styles.iconSelect} ${styles.green}`}
            />
            <p className={styles.subTitle}>Sitevisit Confirmed</p>
            <p className={styles.confirmText}>
              {cpLead.name}'s Site Visit for <strong>{projectName} </strong>has
              been confirmed, we will notify your SM shortly.
            </p>
            <Button
              type='button'
              accent='primary'
              crApp
              onClick={handleConfirmedSiteVisit}
              propStyles={styles.btnWrap}>
              Done
            </Button>
          </div>
        )}
        {resheduleVisit && (
          <div className={styles.reshedule}>
            <div className={styles.buttonWrap} onClick={handleBackTositeVisit}>
              <Icon
                name='replay'
                propStyles={`${styles.buttonIcon} ${styles.repeat}`}
              />
              <p className={styles.buttonText}>Re-Schedule Sitevisit</p>
            </div>
            <div className={styles.dateContainer}>
              <TextInput
                label='New Sitevisit Date *'
                placeHolder='Add New Date for Site Visit'
                type='date'
                register={register}
                setValue={setValue}
                unregister={unregister}
                labelStyles={styles.labelText}
                name='siteVisitDate'
                endIconName='event_note'
                errorMessage={
                  errors.siteVisitDate && 'Site-visit date is required'
                }
              />
            </div>
            <Button
              type='button'
              accent='primary'
              onClick={handleSubmit(handleRescheduleSiteVisit)}
              propStyles={styles.btnWrap}
              crApp>
              UPDATE
            </Button>
          </div>
        )}

        {cancelSiteVisit && (
          <div className={styles.cancelWrap}>
            <Icon
              name='highlight_off'
              propStyles={`${styles.iconSelect} ${styles.red}`}
            />
            <p className={styles.subTitle}>Do You Want to Cancel?</p>
            <p className={styles.confirmText}>
              You are cancelling {cpLead.name}'s Site Visit for
              <strong> {projectName} </strong>
              you can reschedule it to a later date, so that we can remind you
              for a follow-up
            </p>
            <p className={styles.resheduleAgain} onClick={handleResheduleVisit}>
              TRY RESCHEDULING
            </p>
            <Button
              type='button'
              accent='primary'
              crApp
              onClick={handleCancelSiteVisit}
              propStyles={styles.btnWrap}>
              Cancel
            </Button>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default SiteVistConfirmationModel;
