import { useState } from 'react';

import validateVSGUrl from 'apps/admin/components/pages/SuperAdminDashboard/SuperAdminProjectList/utils';
import { useUpdateProjectMutation } from 'apps/admin/services/projectsAPISlice';
import useToast from 'hooks/useToast';
import Modal from 'apps/booking/components/common/modal/Modal';
import TextInput from 'apps/booking/components/common/inputs/TextInput';
import Button from 'apps/booking/components/common/buttons/Button';
import Icon from 'apps/booking/components/common/Icon';

import styles from './styles.module.css';

interface IVsgLinkModal {
  onModalClose: Function;
  projectId: string;
  projectName: string;
  vrUrl: string;
  onRowUpdate: Function;
}

const VsgLinkModal = (props: IVsgLinkModal) => {
  const { projectId, projectName, onModalClose, vrUrl, onRowUpdate } = props;

  const [vsgUrl, setVsgUrl] = useState('');
  const [isValidVsgUrl, setIsValidVsgUrl] = useState(false);
  const [showVsgUrlInputError, setShowVsgUrlInputError] = useState(false);

  const [addToast] = useToast();

  const [updateProject] = useUpdateProjectMutation();

  const handleUpdateVsgUrl = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const vsgUrl = event.target.value;
    const isValidUrl = await validateVSGUrl(projectId, vsgUrl);
    setIsValidVsgUrl(isValidUrl);
    setVsgUrl(vsgUrl);

    if (showVsgUrlInputError) setShowVsgUrlInputError(false);
  };

  const handleUpdateProjectVsgUrl = async () => {
    if (!isValidVsgUrl) {
      setShowVsgUrlInputError(true);
      return;
    }
    try {
      const updatedProject = await updateProject({
        projectId,
        vrUrl: vsgUrl,
      }).unwrap();

      onRowUpdate(updatedProject.data);

      addToast({
        type: 'SUCCESS',
        primaryMessage: 'VSG Link added successfully',
      });

      onModalClose();
    } catch (err) {
      const errMsg = (err as any).data.message;

      addToast({
        type: 'ERROR',
        primaryMessage: errMsg,
        secondaryMessage: 'Project creation failed',
      });
      return;
    }
  };

  return (
    <Modal
      onOutsideClick={() => onModalClose(false)}
      additionalClasses={styles.modalAdditionalClasses}>
      {/* Modal Header */}
      <header className={styles.modalHeader}>
        <div className={styles.modalHeaderLeftContent}>
          <h1>Add VSG Link for {projectName}</h1>
          <p>
            Fill in the following info to create a new project associated to the
            account of the selected developer. You will receive Project ID that
            you will need at different steps.
          </p>
        </div>
        <Icon
          name='close'
          propStyles={styles.modalCloseIcon}
          onClick={() => onModalClose(false)}
        />
      </header>

      {/* Modal Body */}
      <main>
        <TextInput
          errorMessage={showVsgUrlInputError ? 'Enter a valid VSG Link' : ''}
          label='Add VSG link to project here'
          placeHolder='Paste the URL for the VSG here'
          onChange={handleUpdateVsgUrl}
          defaultValue={vrUrl || ''}
        />
        {/* <div className={styles.projectIdInputContainer}>
          <div className={styles.projectIdInput}>
            <TextInput
              label="Project ID for Runwal My City"
              placeHolder="12ADC3423"
              onChange={() => {}}
              onBlur={() => {}}
              disabled={true}
            />
          </div>
          <div className={styles.projectIdInput}>
            <TextInput
              label="AProject ID on Link"
              placeHolder=""
              onChange={() => {}}
              onBlur={() => {}}
              disabled={true}
            />
          </div>
        </div> */}
      </main>

      {/* Modal Footer */}
      <footer className={styles.modalFooter}>
        <div className={styles.projectIdInput}>
          <p className={styles.footerDisclaimer}>
            By clicking Apply Discount you are making updates to the cost sheet
            that the user can view. Confirm discount on the Summary on the right
            before clicking on Apply Discount
          </p>
        </div>
        <div className={styles.projectIdInput}>
          <Button
            onClick={handleUpdateProjectVsgUrl}
            propStyles={`${styles.verifyNowBtn}`}>
            Verify Now
          </Button>
        </div>
      </footer>
    </Modal>
  );
};

export default VsgLinkModal;
