import Icon from 'apps/booking/components/common/Icon';

import styles from './styles.module.css';

interface IApplicantDetails {
  label: string;
  value: string;
  docUrl?: string;
}

const ApplicantDetails = (props: IApplicantDetails) => {
  const { label, value, docUrl } = props;
  return (
    <div className={styles.detailWrap}>
      <p className={styles.executiveDetailsLabel}>{label}</p>
      <p className={styles.executiveDetailsValue}>
        {value}
        {docUrl && (
          <a
            className='font-accent-bold'
            href={docUrl}
            target='_blank'
            rel='noopener noreferrer'>
            <Icon name='launch' propStyles={styles.launchIcon} />
          </a>
        )}
      </p>
    </div>
  );
};

export default ApplicantDetails;
