import Icon from 'apps/booking/components/common/Icon';
import SpinLoader from 'apps/booking/components/common/SpinLoader';
import { Summary } from './Interface';

const SummaryCompoent = (props: Summary) => {
  const {
    titleIcon,
    title,
    mainData,
    changeData,
    changeDataClass,
    changeDesc,
    loaded,
  } = props;
  return (
    <div className='summary-component'>
      <div className='summary-component__header'>
        <Icon name={titleIcon} />
        <span>{title}</span>
      </div>
      {loaded ? (
        <div className='summary-component__body'>
          <SpinLoader />
        </div>
      ) : (
        <>
          <div className='summary-component__body'>{mainData}</div>
          <div className='summary-component__footer'>
            <span className={changeDataClass}>{changeData}</span>
            <span> {changeDesc}</span>
          </div>
        </>
      )}
    </div>
  );
};

export default SummaryCompoent;
