import { useState, useEffect } from 'react';

import CollateralView from 'apps/cp/components/pages/MarketingCollateralPage/MarketingCollateralCardList/CollateralView';
import { useCollateralBitlyAnalyticsMutation } from 'apps/cp/services/cpUserAPISlice';
import { TObjectId } from 'interfaces';

import styles from './styles.module.css';

const FileViewer = () => {
  const [ipAddress, setIpAddress] = useState('');
  const [projectId, setProjectId] = useState('');
  const [collateralSharedURL, setCollateralSharedURL] = useState('');
  const [collateralBitlyAnalyticsAPI] = useCollateralBitlyAnalyticsMutation();

  const urlParams = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop: string) => searchParams.get(prop),
  }) as any;
  const { fileUrl } = urlParams;

  const handleCollateral = async (
    projectId: TObjectId,
    collateralSharedLink: TObjectId,
    deviceIP: TObjectId
  ) => {
    try {
      await collateralBitlyAnalyticsAPI({
        projectId: projectId,
        collateralSharedLink: collateralSharedURL,
        deviceInfo: deviceIP,
      });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    const dnsLookup = new XMLHttpRequest();
    dnsLookup.open('GET', 'https://api.ipify.org/?format=json');
    dnsLookup.onload = function () {
      const jsonObject = JSON.parse(dnsLookup.responseText);
      const ipAddress = jsonObject.ip;
      setIpAddress(ipAddress);
      const parts = fileUrl.split('/');

      setProjectId(parts[4]);
      const fileURLIndex =
        window.location.search.indexOf('fileUrl=') + 'fileUrl='.length;
      setCollateralSharedURL(window.location.search.substring(fileURLIndex));
    };
    dnsLookup.send();
    if (projectId && collateralSharedURL && ipAddress) {
      handleCollateral(projectId, collateralSharedURL, ipAddress);
    }
  }, [projectId, collateralSharedURL, ipAddress]);

  return (
    <div className={styles.viewerWrap}>
      <CollateralView collateralUrl={fileUrl} fullWidth />
    </div>
  );
};

export default FileViewer;
