import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { CLUB_RHODIUM_COMPANY_ID } from 'apps/cp/constants/ids';
import { useGetClubRhodiumProjectsQuery } from 'apps/cp/services/cpProjectAPISlice';
import Icon from 'apps/booking/components/common/Icon';
import DropDownInput, {
  DropDownOptionType,
} from 'apps/booking/components/common/inputs/DropDownInput';
import Modal from 'apps/booking/components/common/modal/Modal';
import Button from 'apps/booking/components/common/buttons/Button';

import styles from './styles.module.css';

interface IAddNewCollaboratorModelProps {
  handleModalClose: Function;
}

const schema = yup.object().shape({
  name: yup.string().required(),
});

const AddNewCollaboratorModal = (props: IAddNewCollaboratorModelProps) => {
  const { handleModalClose } = props;

  const { register, setValue } = useForm({
    resolver: yupResolver(schema),
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });

  //   TODO: add  Api data required
  const { data: projects = [] } = useGetClubRhodiumProjectsQuery(
    CLUB_RHODIUM_COMPANY_ID
  );
  const projectsDropdownOptions: DropDownOptionType[] =
    projects?.map((project) => {
      return {
        value: project.id as string,
        displayText: project.name as string,
      };
    }) || [];

  return (
    <div>
      <Modal
        onOutsideClick={() => handleModalClose()}
        additionalClasses={styles.modalAdditionalClasses}>
        <header className={styles.modalHeader}>
          <div className={styles.modalHeaderLeftContent}>
            <h1>Create a New Collaborator!</h1>
            <p>
              Fill in the following info to create a new project associated to
              the account of the selected developer. You will receive Project ID
              that you will need at different steps.
            </p>
          </div>
          <Icon
            name='close'
            propStyles={styles.modalCloseIcon}
            onClick={() => handleModalClose()}
          />
        </header>
        <main>
          <div className={styles.bodyIndividualdRow}>
            <div className={styles.inputsContainer}>
              <DropDownInput
                options={projectsDropdownOptions}
                lable='Select Project'
                onValueSelected={() => {}}
                register={register}
                setValue={setValue}
                name='project.id'
              />
            </div>
            <div className={styles.buttonWrapper}>
              <Button
                onClick={() => handleModalClose()}
                accent={'primary'}
                propStyles={styles.createNewCollaboratorButton}>
                New Collaborator
              </Button>
            </div>
          </div>
        </main>
      </Modal>
    </div>
  );
};

export default AddNewCollaboratorModal;
