import { IProject } from 'apps/admin/components/pages/SuperAdminDashboard/SuperAdminProjectList';
import { CLUB_RHODIUM_COMPANY_ID } from 'apps/cp/constants/ids';

import styles from './styles.module.css';

export interface IAdminDashboardAnalyticsProjectList {
  projectName: string;
  developerName: string;
  projectId: string;
}

interface IAdminDashboardAnalytics {
  project: IProject;
}

const AdminDashboardAnalytics = (props: IAdminDashboardAnalytics) => {
  const { project } = props;

  return (
    <div className={styles.analyticsContainer}>
      <iframe
        title='d'
        width='100%'
        height='100%'
        frameBorder='0'
        src={
          project.company.id === CLUB_RHODIUM_COMPANY_ID
            ? `https://analytics.relata.io/public/dashboard/d4786ed5-5e03-4123-8f4d-de3d50129093?project_id=${project.id}#hide_parameters=project_id%2Cproject_name%2Csm_name%2Ccp_name%2Ccp_firm%2Csm_id`
            : `https://datastudio.google.com/embed/u/0/reporting/240a12af-0566-42d1-8e55-4059d2860dd0/page/6gnnC?params=%7B%22df16%22:%22include%25EE%2580%25800%25EE%2580%2580EQ%25EE%2580%2580${project.id}%22%7D`
        }
        allowFullScreen></iframe>
    </div>
  );
};

export default AdminDashboardAnalytics;
