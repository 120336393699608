import { useState } from 'react';
import {
  CORRECT_TICK_ICON,
  CROSS_TICK_ICON,
} from '../../../../constants/icons';
import { profileFallback } from '../../../../constants/urls';
import { randomId } from '../../../../utils/utils';

export const AddKycApplicant = (props) => {
  const { handleCreate } = props;

  const [name, setName] = useState('');
  const [showAddApplicantPlaceholder, setShowAddApplicantPlaceholder] =
    useState(true);
  const setShowAddApplicantPlaceholderWrap = (val) => {
    // Clear name on add applicant placeholder hidden
    setName('');
    setShowAddApplicantPlaceholder(val);
  };

  return (
    <div className='add-kyc-applicant'>
      {showAddApplicantPlaceholder && (
        <div className='add-kyc-applicant__body'>
          <div className='kyc-applicant__header-title'>
            <div className='thumb'>
              <img className='img-fluid' src={profileFallback} alt='' />
            </div>
            <div className='kyc-applicant-name-container'>
              <span className='kyc-applicant-name c-56 f-20 f-bold'>
                <input
                  type='text'
                  className='kyc-add-name'
                  id='kyc-add-name'
                  placeholder='Add Applicant Name'
                  onChange={(event) => setName(event.target.value)}
                />
              </span>
              <span className='kyc-applicant-type f-15'>Co-Applicant</span>
            </div>
          </div>
          <div>
            <span
              type='submit'
              className={`icon-container icon-container--with-background icon-correct ${
                name && 'click-enabled'
              }`}
              onClick={() => {
                handleCreate({
                  firstName: name,
                  isEditFormOpenByDefault: true,
                });
                setShowAddApplicantPlaceholderWrap(false);
              }}>
              <span className='icon material-icons '>{CORRECT_TICK_ICON}</span>
            </span>
            <span
              className='icon-container icon-container--with-background icon-cross click-enabled'
              onClick={() => setShowAddApplicantPlaceholderWrap(false)}>
              <span className='icon material-icons '>{CROSS_TICK_ICON}</span>
            </span>
          </div>
        </div>
      )}
      <div
        className='add-kyc-applicant__btn'
        onClick={() =>
          !showAddApplicantPlaceholder &&
          setShowAddApplicantPlaceholderWrap(true)
        }>
        <button
          type='button'
          className={`btn btn--outline ${
            showAddApplicantPlaceholder && 'btn--outline-disabled'
          }`}
          onClick={() =>
            !showAddApplicantPlaceholder &&
            setShowAddApplicantPlaceholderWrap(true)
          }>
          <span>Add Co-Applicant</span>
        </button>
      </div>
    </div>
  );
};
