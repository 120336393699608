import ProjectUpdateBody from 'apps/admin/components/pages/ProjectUpdate/ProjectUpdateBody';
import ProjectUpdateDetailsBody from 'apps/admin/components/pages/ProjectUpdate/ProjectUpdateDetailsBody/index';
import ProjectUpdateInventory from 'apps/admin/components/pages/ProjectUpdate/ProjectUpdateInventory';
import ProjectUpdateVSGPassword from 'apps/admin/components/pages/ProjectUpdate/ProjectUpdateVSG';
import { IProject } from 'apps/admin/components/pages/SuperAdminDashboard/SuperAdminProjectList';
import DesktopTabs from '../../common/DesktopTabs';
import { ITab } from 'apps/cp/components/common/Tabs';

const projectUpdateTabsEnum = {
  UPDATE_PROJECT: { value: 'UPDATE_PROJECT', displayText: 'Update Project' },
  UPDATE_PROJECT_DETAILS: {
    value: 'UPDATE_PROJECT_DETAILS',
    displayText: 'Update Project Details',
  },
  UPDATE_PROJECT_INVENTORY: {
    value: 'BOOKING_ENGINE',
    displayText: 'Booking Engine',
  },
  UPDATE_PROJECT_VSG: {
    value: 'UPDATE_VSG',
    displayText: 'Update VSG',
  },
};

interface IProjectUpdateProps {
  project: IProject;
  onProjectUpdate: React.Dispatch<React.SetStateAction<IProject>>;
}

const ProjectUpdate = (props: IProjectUpdateProps) => {
  const { project, onProjectUpdate } = props;

  const tabs: ITab[] = [
    {
      id: projectUpdateTabsEnum.UPDATE_PROJECT.value,
      name: projectUpdateTabsEnum.UPDATE_PROJECT.displayText,
      content: (
        <ProjectUpdateBody
          project={project}
          onProjectUpdate={onProjectUpdate}
        />
      ),
      disabled: false,
    },
    {
      id: projectUpdateTabsEnum.UPDATE_PROJECT_DETAILS.value,
      name: projectUpdateTabsEnum.UPDATE_PROJECT_DETAILS.displayText,
      content: (
        <ProjectUpdateDetailsBody
          project={project}
          onProjectDetailsUpdate={onProjectUpdate}
        />
      ),
      disabled: false,
    },
    {
      id: projectUpdateTabsEnum.UPDATE_PROJECT_INVENTORY.value,
      name: projectUpdateTabsEnum.UPDATE_PROJECT_INVENTORY.displayText,
      content: (
        <ProjectUpdateInventory
          project={project}
          onProjectInventoryUpdate={onProjectUpdate}
        />
      ),
      disabled: false,
    },
    {
      id: projectUpdateTabsEnum.UPDATE_PROJECT_VSG.value,
      name: projectUpdateTabsEnum.UPDATE_PROJECT_VSG.displayText,
      content: <ProjectUpdateVSGPassword project={project} />,
      disabled: false,
    },
  ];

  return (
    <body>
      <DesktopTabs tabs={tabs} />
    </body>
  );
};

export default ProjectUpdate;
