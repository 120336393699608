import { useSelector } from 'react-redux';

import { ITab } from 'apps/cp/components/common/Tabs';
import DesktopTabs from 'apps/admin/components/common/DesktopTabs';
import { IProject } from 'apps/admin/components/pages/SuperAdminDashboard/SuperAdminProjectList';
import Partners from './Partners';
import { getAdminUser } from 'apps/admin/slices/adminSlice';
import { UserRole } from 'constants/userRoles';
import AnalyticsWrapper, {
  ISummaryData,
} from 'apps/admin/components/common/AnalyticsWrapper';
import {
  useGetActiveProjectPartnersCountQuery,
  useGetRegisteredProjectPartnersCountQuery,
  useGetUnassignedProjectPartnersCountQuery,
} from 'apps/admin/services/projectPartnersAPISlice';

export interface IPartner {
  projectRegistration: Date;
  leadsRegistered: number;
  collateralsShared: number;
  siteVisitsDone: number;
  assignedLeads: number;
  totalWorkingProjects?: number;
  projects?: {
    project: string[];
    registerLeads: number;
    taggedLeads: number;
  }[];
  smWithProject?: {
    sm?: string;
    project?: IProject;
  }[];
  lostLeads: number;
  cpName?: string;
  cpEmail?: string;
  cpMobile?: string;
  cpFirmName?: string;
  cpFirmPAN?: string;
  cpFirmGST?: string;
  cpFirmURL?: string;
  cpUserId: string;
  smName?: string;
  recentSiteVisitDate?: Date;
  shareQRCodeCount: number;
}

interface IProjectPartnersProps {
  project: IProject;
  onProjectUpdate: (updateProject: IProject) => void;
}

const ProjectPartners = (props: IProjectPartnersProps) => {
  const { project, onProjectUpdate } = props;

  const user = useSelector(getAdminUser);

  const { data: activeCount = 0, isLoading: activeCountLoaded } =
    useGetActiveProjectPartnersCountQuery({
      projectId: project.id,
      executiveId:
        user?.role === UserRole.EXECUTIVE || user?.role === UserRole.GRE
          ? user.id
          : '',
    });
  const { data: unassignedCount = 0, isLoading: unassignedCountLoaded } =
    useGetUnassignedProjectPartnersCountQuery({
      projectId: project.id,
      executiveId:
        user?.role === UserRole.EXECUTIVE || user?.role === UserRole.GRE
          ? user.id
          : '',
    });
  const { data: registeredCount = 0, isLoading: registeredCountLoaded } =
    useGetRegisteredProjectPartnersCountQuery({
      projectId: project.id,
      executiveId:
        user?.role === UserRole.EXECUTIVE || user?.role === UserRole.GRE
          ? user.id
          : '',
    });

  const tabs: ITab[] = [
    {
      id: 'registeredPartners',
      name: 'Registered Partners',
      content: (
        <Partners
          partnersCount={+registeredCount}
          projectId={project.id}
          partnerType='register'
        />
      ),
      disabled: false,
    },
    {
      id: 'unassignedPartners',
      name: 'Unassigned Partners',
      content: (
        <Partners
          partnersCount={+unassignedCount}
          projectId={project.id}
          partnerType='unassigned'
        />
      ),
      disabled: false,
    },
    {
      id: 'activePartners',
      name: 'Active Partners',
      content: (
        <Partners
          partnersCount={+activeCount}
          projectId={project.id}
          partnerType='active'
        />
      ),
      disabled: false,
    },
  ];

  const projectPartnerCountSummary: ISummaryData[] = [
    {
      details: {
        mainData: `${registeredCount}`,
        changeData: '',
        changeDataClass: '',
        changeDesc: '',
        loaded: registeredCountLoaded,
      },
      titleIcon: 'group',
      title: 'Registered Partners',
    },
    {
      details: {
        mainData: `${unassignedCount}`,
        changeData: '',
        changeDataClass: '',
        changeDesc: '',
        loaded: unassignedCountLoaded,
      },
      titleIcon: 'group',
      title: 'Unassigned Partners',
    },
    {
      details: {
        mainData: `${activeCount}`,
        changeData: '',
        changeDataClass: '',
        changeDesc: '',
        loaded: activeCountLoaded,
      },
      titleIcon: 'group',
      title: 'Active Partners',
    },
  ];

  return (
    <>
      <AnalyticsWrapper summaryData={projectPartnerCountSummary} />
      <DesktopTabs tabs={tabs} />
    </>
  );
};

export default ProjectPartners;
