import { ICellRendererParams } from 'ag-grid-community';
import { ICollaborators } from '../..';

import styles from './styles.module.css';

const ExpandedDetails = (props: ICellRendererParams) => {
  const collaboratorsData: ICollaborators = props?.data; //TODO: remove any after api imtegration
  const collaboratorsItems = !collaboratorsData
    ? []
    : [
        {
          label: 'Registered Name',
          value: collaboratorsData.registeredName,
        },
        {
          label: 'POC Number',
          value: collaboratorsData.pocMobile,
        },
        {
          label: 'POC Email',
          value: collaboratorsData.pocEmail,
        },
        // {
        //   label: 'Firm Formation',
        //   value: '--',
        // },
        {
          label: 'Registered on',
          value: '--',
        },
        {
          label: 'Firm GST',
          value: collaboratorsData.gstNumber,
        },
        {
          label: 'Firm PAN',
          value: collaboratorsData.panNumber,
        },
        // {
        //   label: 'List of Rera',
        //   value: '--',
        // },
        {
          label: 'Firm URL',
          value: collaboratorsData.websiteUrl,
        },
        {
          label: 'Projects Involved',
          value: collaboratorsData.projects?.length || 0,
        },
      ];
  return (
    <div className={styles.allDetailsContainer}>
      {collaboratorsItems.map((leadDetailItem) => (
        <div
          key={leadDetailItem.label}
          className={styles.individualDetailContainer}>
          <div className={styles.detailLabel}>{leadDetailItem.label}</div>
          <p className={styles.detailValue}>
            {leadDetailItem.value ? leadDetailItem.value : '---'}
          </p>
        </div>
      ))}
    </div>
  );
};

export default ExpandedDetails;
