import { useSelector } from 'react-redux';
import BlockedUnitCountDownTimer from '../common/cards/BlockedUnitCountDownTimer';
import BookingAmountCard from '../common/cards/BookingAmountCard';
import { getProject } from 'apps/booking/slices/projectSlice';

const KycContentHeader = () => {
  const project = useSelector(getProject);

  return (
    <header className='content-header'>
      <div className='content-header__title-plate'>
        <h1 className='content-header__title'>
          Add Your <span className='font-accent-bold'>Personal Details</span> &
          Other Applicants
        </h1>
        <p className='content-header__description'>
          Select amongst the payment schemes available to take complete benefit
          of your {project.name} dream home.
        </p>
      </div>

      <div className='content-header__card-container'>
        <BlockedUnitCountDownTimer />
        <BookingAmountCard />
      </div>
    </header>
  );
};

export default KycContentHeader;
