import { createSlice } from '@reduxjs/toolkit';
import { unitCartAPISlice } from '../../../api/unitCart';

export const seletedUnitSlice = createSlice({
  name: 'selectedUnit',
  initialState: {
    unit: {},
    unitCart: {},
    isFetchingUnitCart: false,
    selectedScheme: {},
    selectedEOI: {},
  },
  reducers: {
    setSelectedUnit: (state, action) => {
      // Reset unitCart on user update or unit update
      const isUnitChanged = state.unit.id !== action.payload.id;
      if (isUnitChanged) {
        state.unitCart = {};
      }
      state.unit = action.payload;
    },
    setUnitCart: (state, action) => {
      state.unitCart = action.payload;
    },
    setSelectedSchemeId: (state, action) => {
      state.unitCart['selectedSchemeId'] = action.payload;
    },
    setSelectedScheme: (state, action) => {
      state.selectedScheme = action.payload;
    },
    setSelectedEOI: (state, action) => {
      state.selectedEOI = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Update unitCart in store
    builder.addMatcher(
      unitCartAPISlice.endpoints.getUnitCart.matchFulfilled,
      (state, { payload }) => {
        state.unitCart = payload || {};
      }
    );

    // handle isFetchingUnitCart
    builder.addMatcher(
      unitCartAPISlice.endpoints.getUnitCart.matchFulfilled,
      (state, { payload }) => {
        state.isFetchingUnitCart = false;
      }
    );
    builder.addMatcher(
      unitCartAPISlice.endpoints.getUnitCart.matchRejected,
      (state, { payload }) => {
        state.isFetchingUnitCart = false;
      }
    );
    builder.addMatcher(
      unitCartAPISlice.endpoints.getUnitCart.matchPending,
      (state, { payload }) => {
        state.isFetchingUnitCart = true;
      }
    );
  },
});

export const {
  setSelectedUnit,
  setUnitCart,
  setSelectedSchemeId,
  setSelectedScheme,
  setSelectedEOI,
} = seletedUnitSlice.actions;

export const getSelectedUnit = (state) => state.selectedUnit.unit;
export const getEmbassyUnitId = (state) =>
  state.selectedUnit.unit.embassy_unit_id;

export const getUnitCart = (state) => state.selectedUnit.unitCart;
export const getIsFetchingUnitCart = (state) =>
  state.selectedUnit.isFetchingUnitCart;
export const getSelectedSchemeId = (state) => {
  const costSheet = getCostSheet(state);
  const unitCart = getUnitCart(state);

  const selectedSchemeSfdcId =
    unitCart?.scheme_sfdc_id || costSheet?.scheme_details[0].scheme_sfdc_id;
  return selectedSchemeSfdcId;
};
export const getSelectedScheme = (state) => state.selectedUnit.selectedScheme;
export const getSelectedEOI = (state) => state.selectedUnit.selectedEOI;
export const getCostSheet = (state) => {
  const selectedUnit = state.selectedUnit.unit;
  const unitCart = state.selectedUnit.unitCart;
  const costSheet = unitCart?.cost_sheet || selectedUnit?.cost_sheet;
  return costSheet;
};
