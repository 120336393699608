import { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import KycApplicantFrom from './KycApplicantFrom';
import { AddKycApplicant } from './AddKycApplicant';
import { getUnitCart, setUnitCart } from '../../slices/selectedUnit';
import { useUpdateUnitCartMutation } from '../../../../api/unitCart';
import { isEmpty } from '../../../../utils/utils';
import { APPLICANT_TYPE } from 'apps/booking/constants';
import useToast from 'hooks/useToast';
import { ERROR } from 'constants/status';
import {
  useAddOrUpdateApplicantMutation,
  useDeleteApplicantMutation,
} from 'apps/booking/services/applicantsAPISlice';

const CoApplicants = (props) => {
  const { updateIsEditFormOpenStatus } = props;

  const dispatch = useDispatch();

  const [newApplicant, setNewApplicant] = useState();

  const unitCart = useSelector(getUnitCart);
  const user = unitCart.user;
  const selectedUnit = unitCart.unit;

  const applicantsGlobal = unitCart.applicants || [];

  const [updateUnitCartAPI] = useUpdateUnitCartMutation();
  const [addOrUpdateApplicantMutation] = useAddOrUpdateApplicantMutation();
  const [deleteApplicantMutation] = useDeleteApplicantMutation();

  const [addToast] = useToast();

  const coApplicants = useMemo(() => {
    return applicantsGlobal?.filter(
      (applicant) => applicant.type == APPLICANT_TYPE.SECONDARY
    );
  }, [applicantsGlobal]);

  const updateApplicantOnServer = async (applicants) => {
    const unitCartBody = {
      id: unitCart.id,
      applicants,
      unit: unitCart.unit.id,
      user: unitCart.user.id,
    };
    const { data: unitCartdata, error: unitCartError } =
      await updateUnitCartAPI({ ...unitCartBody }).unwrap();

    if (!isEmpty(unitCartError)) {
      console.error('unitCartError', unitCartError);
      return false;
    }

    if (!isEmpty(unitCartdata)) {
      dispatch(setUnitCart(unitCartdata));
      // TODO: Update unit status or fetch the latest unit details
    }
  };

  const handleDelete = async (index = -1, applicantId) => {
    try {
      if (applicantId) {
        await deleteApplicantMutation(applicantId).unwrap();
        let updatedApplicants = applicantsGlobal.filter(
          (applicant) => applicant.id != applicantId
        );
        await updateApplicantOnServer(updatedApplicants);
      } else {
        setNewApplicant(null);
      }
      updateIsEditFormOpenStatus(applicantId, false);
    } catch (error) {
      const errMsg = error.data?.message;
      addToast({
        type: ERROR,
        primaryMessage: errMsg,
        secondaryMessage: 'Applicant delete failed!',
        timeout: 1000,
      });
    }
  };

  const handleCreate = (applicant) => {
    setNewApplicant(applicant);
  };

  const handleSave = async (applicant, index) => {
    try {
      const { data: newCoApplicant } = await addOrUpdateApplicantMutation({
        applicant: {
          ...applicant,
          id: applicant?.id,
          unit: selectedUnit.id,
          lead: user.id,
        },
      }).unwrap();
      let updatedApplicants = [];
      if (applicant.id) {
        updatedApplicants = applicantsGlobal.map((applicant) =>
          applicant.id == newCoApplicant.id ? newCoApplicant : applicant
        );
      } else {
        updatedApplicants = [...applicantsGlobal, newCoApplicant];
      }
      await updateApplicantOnServer(updatedApplicants);
    } catch (error) {
      const errMsg = error.data?.message;
      addToast({
        type: ERROR,
        primaryMessage: errMsg,
        secondaryMessage: 'Applicant add failed!',
        timeout: 1000,
      });
    }
    setNewApplicant(null);
  };

  return (
    <>
      {coApplicants?.map((applicant, index) => (
        <KycApplicantFrom
          key={applicant.id}
          applicant={applicant}
          handleDelete={() => handleDelete(index, applicant.id)}
          handleSave={(modifiedApplicant) =>
            handleSave(modifiedApplicant, index)
          }
          isEditFormOpenByDefault={false}
          updateIsEditFormOpenStatus={updateIsEditFormOpenStatus}
        />
      ))}
      {newApplicant && (
        <KycApplicantFrom
          key={newApplicant.id}
          applicant={newApplicant}
          handleDelete={() => handleDelete(-1, newApplicant.id)}
          handleSave={(modifiedApplicant) => handleSave(modifiedApplicant, -1)}
          isEditFormOpenByDefault={true}
          updateIsEditFormOpenStatus={updateIsEditFormOpenStatus}
        />
      )}
      <AddKycApplicant handleCreate={handleCreate} />
    </>
  );
};

export default CoApplicants;
