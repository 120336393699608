import { useState } from 'react';

import Icon from 'apps/booking/components/common/Icon';
import { IProjectEvent } from 'apps/admin/components/pages/SuperAdminDashboard/SuperAdminProjectList/modals/ProjectEventsModal';
import ProjectEventDetailsModal from 'apps/admin/components/pages/SuperAdminDashboard/SuperAdminProjectList/modals/ProjectEventDetailsModal';

interface IProjectEventDetailsCellProps {
  projectEvent: IProjectEvent;
}

const ProjectEventDetailsCell = (props: IProjectEventDetailsCellProps) => {
  const { projectEvent } = props;

  const [eventDetailsModalShown, setEventDetailsModalShown] = useState(false);

  return (
    <>
      {eventDetailsModalShown && (
        <ProjectEventDetailsModal
          onModalClose={() => setEventDetailsModalShown(false)}
          projectEvent={projectEvent}
        />
      )}
      <Icon name='details' onClick={() => setEventDetailsModalShown(true)} />
    </>
  );
};

export default ProjectEventDetailsCell;
