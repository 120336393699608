import { adminAPISlice } from 'apps/admin/services/index';

export const unitsAPISlice = adminAPISlice.injectEndpoints({
  endpoints: (build) => ({
    eoiBlockUnitById: build.mutation({
      query: ({ unitId, userId }: { unitId: String; userId: String }) => ({
        url: `api/units/eoi/block`,
        method: 'POST',
        body: {
          unitId,
          userId,
        },
      }),
    }),
    reserveUnitByUnitCartId: build.mutation({
      query: (unitCartId) => ({
        url: `api/units/reserve`,
        method: 'POST',
        body: {
          unitCartId,
        },
      }),
    }),
    blockUnitByUnitCartId: build.mutation({
      query: (unitCartId) => ({
        url: `api/units/block`,
        method: 'POST',
        body: {
          unitCartId,
        },
      }),
    }),
    bookUnitByUnitCartId: build.mutation({
      query: (unitCartId) => ({
        url: `api/units/book`,
        method: 'POST',
        body: {
          unitCartId,
        },
      }),
    }),
    releaseUnitByUnitCartId: build.mutation({
      query: (unitCartId) => ({
        url: `api/units/release`,
        method: 'POST',
        body: {
          unitCartId,
        },
      }),
    }),
  }),

  overrideExisting: false,
});

export const {
  useEoiBlockUnitByIdMutation,
  useReserveUnitByUnitCartIdMutation,
  useBlockUnitByUnitCartIdMutation,
  useBookUnitByUnitCartIdMutation,
  useReleaseUnitByUnitCartIdMutation,
} = unitsAPISlice;
