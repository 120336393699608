import { useState } from 'react';
import Modal from 'apps/booking/components/common/modal/Modal';
import Button from 'apps/booking/components/common/buttons/Button';

import styles from './styles.module.css';

interface IUpdateSVGProps {
  onModalClose: Function;
  fileName: string;
  svgPathIds: string[];
}

const UpdateSVGModal = (props: IUpdateSVGProps) => {
  const { onModalClose, fileName, svgPathIds } = props;

  const [newValues, setNewValues] = useState([...svgPathIds]);

  const handleInputChange = (index: number, newValue: string) => {
    const updatedValues = [...newValues];
    updatedValues[index] = newValue;
    setNewValues(updatedValues);
  };

  return (
    <Modal additionalClasses={styles.executiveDetailsModal}>
      <header className={styles.modalHeader}>
        <div>
          <h2 className={styles.modalHeading}>SVG File: {fileName}</h2>
        </div>
      </header>
      <div className={styles.tableWrapper}>
        <table className={styles.updateTable}>
          <thead>
            <tr>
              <th className={styles.updateTh}>Current Path Id</th>
              <th className={styles.updateTh}>New Path Id</th>
            </tr>
          </thead>
          <tbody>
            {svgPathIds.map((pathId, index) => (
              <tr key={index}>
                <td className={styles.updateTd}>{pathId}</td>
                <td className={styles.updateTd}>
                  <div className={styles.updateSVGInput}>
                    <input
                      type='text'
                      value={newValues[index]}
                      onChange={(e) => handleInputChange(index, e.target.value)}
                    />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className={styles.buttonContainer}>
        <Button
          onClick={() => onModalClose(newValues)}
          propStyles={styles.addEoiButton}>
          Save
        </Button>
      </div>
    </Modal>
  );
};

export default UpdateSVGModal;
