import Modal from 'apps/booking/components/common/modal/Modal';
import Icon from 'apps/booking/components/common/Icon/index';

import styles from './styles.module.css';

interface IProjectListModal {
  onModalClose: (state: boolean) => void;
  projectList: string[];
}

const ProjectListModal = (props: IProjectListModal) => {
  const { onModalClose, projectList } = props;

  return (
    <Modal
      onOutsideClick={() => {
        onModalClose(false);
      }}
      additionalClasses={styles.executiveDetailsModal}>
      <header className={styles.modalHeader}>
        <div>
          <h2 className={styles.modalHeading}>List of Projects</h2>
        </div>
        <Icon
          name='close'
          onClick={() => {
            onModalClose(false);
          }}></Icon>
      </header>

      <main className={styles.modalBody}>
        <div>
          <p className={styles.executiveDetailsLabel}>
            Name of Assigned projects
          </p>
          <div className={styles.projectDetailsContainer}>
            {projectList?.map((project) => {
              return (
                <>
                  <p className={styles.assignedProjectName}>{project}</p>
                </>
              );
            })}
            {!projectList.length && 'No projects assigned'}
          </div>
        </div>
      </main>
    </Modal>
  );
};

export default ProjectListModal;
