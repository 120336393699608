import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import useToast from '../../../../hooks/useToast';

import UnitSummaryCard from '../UnitSummaryCard';
import AgreementPlank from './AgreementPlank';
import AgreementDownloadButton from './AgreementDownloadButton';

import { setAppSliceIsFetching } from '../../../../slices/appSlice';
import { getUnitCart, setUnitCart } from '../../slices/selectedUnit';

import { useUpdateUnitCartMutation } from '../../../../api/unitCart';

import { isEmpty } from '../../../../utils/utils';

import { URLS } from '../../../../constants/urls';
import { SIDEBAR_ITEM_INDEX } from '../SidebarItem';

const Agreement = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [agree, setAgree] = useState(false);
  const unitCart = useSelector(getUnitCart);
  const [addToast] = useToast();

  const [updateUnitCartAPI] = useUpdateUnitCartMutation();

  const toggleAgree = (agree) => {
    setAgree(agree);
  };

  const updateUnitCartProgressOnServer = async () => {
    const unitCartBody = {
      id: unitCart.id,
      progress:
        unitCart.progress < SIDEBAR_ITEM_INDEX[URLS.AGREEMENT]
          ? SIDEBAR_ITEM_INDEX[URLS.AGREEMENT]
          : unitCart.progress,
    };
    const { data: unitCartdata, error: unitCartError } =
      await updateUnitCartAPI({ ...unitCartBody });

    if (unitCartError) {
      console.error('unitCartError', unitCartError);
      alert('unitCartError', unitCartError);
      return false;
    }

    if (!isEmpty(unitCartdata)) {
      dispatch(setUnitCart(unitCartdata));
    }
    return true;
  };

  const handleProceed = async () => {
    dispatch(setAppSliceIsFetching(true));
    const handleUnitCartStatus = await updateUnitCartProgressOnServer();
    dispatch(setAppSliceIsFetching(false));

    if (handleUnitCartStatus) {
      addToast({
        type: 'SUCCESS',
        primaryMessage: 'Congratulations!',
        secondaryMessage: `You have successfully booked  ${unitCart.unit.towerName}, 
          ${unitCart.unit.blockName}${unitCart.unit.number} in Embassy`,
      });
      history.push(URLS.DASHBOARD_BASE);
    }
  };

  return (
    <div className='content-with-unit-summary-card agreement'>
      <div className='content-with-unit-summary-card__body'>
        <div className='content-with-unit-summary-card__content agreement__content'>
          <AgreementPlank editAllowed={true} />
        </div>
        <UnitSummaryCard />
      </div>
      <footer className='payment-plan__footer'>
        <button
          type='button'
          className='btn btn--transparent'
          onClick={() => {
            history.push(URLS.KYC_DETAILS);
          }}>
          Back to Details
        </button>
        <div className='payment-plan__footer-action-btns'>
          <span className='kyc-agree-checkbox'>
            <label className='checkbox'>
              <input type='checkbox' onClick={() => toggleAgree(!agree)} />
              <span>
                <p className='link-terms-conditions'>
                  I Agree to the{' '}
                  <a
                    className='font-accent-bold'
                    href='https://relata-temp.s3.ap-south-1.amazonaws.com/embassy/assets/terms_and_conditions.pdf'
                    target='_blank'
                    rel='noreferrer'>
                    Terms and Conditions
                  </a>
                </p>
              </span>
            </label>
          </span>

          <button
            type='button'
            className={`btn kyc-save-and-pay ${agree && 'btn--accent'}  ${
              !agree && 'click-disabled'
            }`}
            onClick={() => handleProceed()}>
            Confirm & Proceed
          </button>
          <AgreementDownloadButton unitCartId={unitCart.id} />
        </div>
      </footer>
    </div>
  );
};

export default Agreement;
