import { useEffect, useState } from 'react';

import Modal from 'apps/booking/components/common/modal/Modal';
import DropDownInput, {
  DropDownOptionType,
} from 'apps/booking/components/common/inputs/DropDownInput';
import Button from 'apps/booking/components/common/buttons/Button';
import Icon from 'apps/booking/components/common/Icon';
import TextInput from 'apps/booking/components/common/inputs/TextInput';
import { ILeadUnitCart } from 'apps/cp/interfaces';
import { toIndianRupee } from 'utils/utils';
import useToast from 'hooks/useToast';
import {
  useSuccessfulPaymentOrderMutation,
  useCreatePaymentOrderMutation,
} from 'apps/booking/services/paymentsAPISlice';
import { IPaymentOrder } from 'apps/booking/interfaces';
import {
  useBlockUnitByUnitCartIdMutation,
  useBookUnitByUnitCartIdMutation,
  useReserveUnitByUnitCartIdMutation,
} from 'apps/booking/services/unitsAPISlice';
import {
  ERROR,
  SUCCESS,
  UNIT_STATUSES,
  UNIT_STATUSES_INDEX,
} from 'constants/status';
import { dateToDaythMonthYear } from 'utils/dateFormator';

import styles from './styles.module.css';
import genericStyles from '../../../../../styles/genericStyles.module.css';

enum paymentModalStates {
  NEW,
  EDIT,
  UPDATE,
}

const paymentModeOptions: DropDownOptionType[] = [
  { value: 'CASH_CHALLAN', displayText: 'Cash Challan' },
  { value: 'CHEQUE', displayText: 'Cheque' },
  { value: 'NETBANKING', displayText: 'Netbanking' },
];

interface PaymentModalType {
  closePaymentModal: Function;
  unitCart: ILeadUnitCart;
  updateList?: Function;
}

const PaymentModal = (props: PaymentModalType) => {
  const { unitCart, closePaymentModal, updateList } = props;
  const [show, setShow] = useState(false);
  const [transactionId, setTransactionId] = useState('');
  const [paymentModalState, setPaymentModalState] = useState(
    transactionId ? paymentModalStates.EDIT : paymentModalStates.NEW
  );
  const [paymentOrder, setPaymentOrder] = useState<IPaymentOrder>();
  const [amount, setAmount] = useState(0);
  const [addToast] = useToast();
  const [paymentMode, setPaymentMode] = useState(
    paymentModeOptions[0].value as string
  );

  const [createPaymentOrderAPI] = useCreatePaymentOrderMutation();
  const [successfulPaymentOrderAPI] = useSuccessfulPaymentOrderMutation();
  const [reserveUnitByUnitCartIdAPI] = useReserveUnitByUnitCartIdMutation();
  const [blockUnitByUnitCartIdAPI] = useBlockUnitByUnitCartIdMutation();
  const [bookUnitByUnitCartIdAPI] = useBookUnitByUnitCartIdMutation();

  useEffect(() => {
    const fetchPaymentOrder = async () => {
      try {
        const { data: order } = await createPaymentOrderAPI({
          unitCartId: unitCart.id,
        }).unwrap();
        setPaymentOrder(order);
        // if (paymentOrder.amount) {
        setShow(true);
        // }
      } catch (error) {
        closePaymentModal();
        console.error(error);
      }
    };

    fetchPaymentOrder();
  }, []);

  useEffect(() => {
    if (paymentOrder && paymentOrder.amount) {
      setAmount(paymentOrder.amount);
    }
  }, [paymentOrder]);

  const handleTransactionId = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newTransactionId = e.target.value;
    setTransactionId(newTransactionId);
  };
  const handleAmount = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newAmount = e.target.value;
    setAmount(Number(newAmount));
  };
  const handleDropDownSelection = (value: DropDownOptionType[]) => {
    setPaymentMode(value[0]?.value as string);
  };

  const handleOutclick = () => {};

  const updateTransactionDetails = async () => {
    // Called on new create or update existing transaction details

    // updateTransactionDetailsOnServer(paymentMode, transacionId)
    if (paymentOrder?.id && paymentMode) {
      try {
        //reserve unit: if not already by user
        if (
          UNIT_STATUSES_INDEX[unitCart.unit.status] <
          UNIT_STATUSES_INDEX[UNIT_STATUSES.BLOCKED]
        ) {
          await reserveUnitByUnitCartIdAPI(unitCart.id).unwrap();
          await blockUnitByUnitCartIdAPI(unitCart.id).unwrap();
        } else if (
          UNIT_STATUSES_INDEX[unitCart.unit.status] <=
          UNIT_STATUSES_INDEX[UNIT_STATUSES.BLOCKED]
        ) {
          await bookUnitByUnitCartIdAPI(unitCart.id).unwrap();
        }
        await successfulPaymentOrderAPI({
          paymentOrderId: paymentOrder.id,
          transactionId: transactionId,
          unitCartId: unitCart.id,
          mode: paymentMode,
          amountPaid: amount,
        });
        addToast({
          type: SUCCESS,
          primaryMessage: 'Payment added!',
          timeout: 1000,
        });
        updateList && updateList();
      } catch (error) {
        addToast({
          type: ERROR,
          primaryMessage: 'Payment add failed!',
          timeout: 1000,
        });
      }
    }

    // Always close the modal
    setShow(false);
    closePaymentModal();
  };
  const handleEditTransactionDetails = () => {
    setPaymentModalState(paymentModalStates.UPDATE);
  };
  const handleRemoveTransactionDetails = () => {
    // Always close the modal
    setShow(false);

    // deletTransactionDetailsOnServer(paymentMode, transacionId)
  };

  return (
    <Modal
      show={show}
      onOutsideClick={handleOutclick}
      additionalClasses={`${styles.paymentModalContainer}`}>
      <div className={styles.paymentModal}>
        <header>
          <div className={styles.paymentModalHeader}>
            <div className={styles.headerTitleContainer}>
              <div className={styles.headerTitle}>Add Payment</div>
            </div>
            <Icon
              name='close'
              onClick={() => {
                setShow(false);
                closePaymentModal();
              }}
            />
          </div>
          <div className={styles.headerDesc}>
            Choose Payment Method & add Transaction ID, Cheque Number, Challan
            Number or others
          </div>
        </header>
        <main className={styles.bodyContainer}>
          <span className={styles.dashedLine}></span>
          <div className={styles.detailsContainer}>
            <div className={styles.details}>
              <p className={styles.detailsTitle}>Unit Details</p>
              <div>
                {UNIT_STATUSES_INDEX[UNIT_STATUSES.BLOCKED] >
                UNIT_STATUSES_INDEX[unitCart.unit.status]
                  ? 'Blocking'
                  : 'Booking'}{' '}
                Amount
                <p className={styles.detailsDesc}>
                  Net Payment Due for{' '}
                  {UNIT_STATUSES_INDEX[UNIT_STATUSES.BLOCKED] >
                  UNIT_STATUSES_INDEX[unitCart.unit.status]
                    ? 'Blocking'
                    : 'Booking'}{' '}
                </p>
              </div>
            </div>
            <div className={styles.details}>
              <p className={`${styles.detailsTitle} ${styles.unitName}`}>
                {unitCart.unit.towerName}, {unitCart.unit.number}
              </p>
              {/* <h2 className={styles.price}>
                {toIndianRupee(paymentOrder?.amount)}
              </h2> */}
              <TextInput
                onChange={handleAmount}
                defaultValue={amount.toString()}
                type='number'
                placeHolder={
                  paymentOrder?.amount ? paymentOrder?.amount.toString() : ''
                }
                startIconName='currency_rupee'
                inputHTMLElementStyles={styles.paymentDetailsInput}
                disabled={paymentModalState === paymentModalStates.EDIT}
              />
            </div>
          </div>
          <div className={styles.dropDownContainer}>
            <DropDownInput
              options={paymentModeOptions}
              defaultSelectedOption={paymentModeOptions[0]}
              lable='Payment Mode'
              onValueSelected={handleDropDownSelection}
              disabled={paymentModalState === paymentModalStates.EDIT}
            />
          </div>
          <TextInput
            label='Transaction ID, Cheque No, Challan No.'
            onChange={handleTransactionId}
            defaultValue={transactionId}
            type='text'
            placeHolder='AD-X-1382-1389-XX'
            inputHTMLElementStyles={styles.paymentDetailsInput}
            disabled={paymentModalState === paymentModalStates.EDIT}
          />
          <br />
          {(unitCart.paymentOrders || unitCart.eoi) && (
            <div className={styles.detailsDesc}>
              Previous payments
              <table>
                <thead>
                  <tr>
                    <th>TransactionId</th>
                    <th>Amount</th>
                    <th>Date</th>
                  </tr>
                </thead>
                <tbody>
                  {unitCart.eoi && (
                    <tr>
                      <td>EOI</td>
                      <td>{toIndianRupee(unitCart.eoi.amount)}</td>
                      <td>{dateToDaythMonthYear(unitCart.eoi.createdAt)}</td>
                    </tr>
                  )}
                  {unitCart.paymentOrders.map((order, index) => {
                    if (order.amount === 0) {
                      return null; // Exclude rows with amount = 0
                    }
                    return (
                      <tr key={index}>
                        <td>{order.transactionId}</td>
                        <td>{toIndianRupee(order.amount)}</td>
                        <td>{dateToDaythMonthYear(order.createdAt)}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          )}
        </main>
        <footer>
          <p className={styles.disclaimer}>
            By clicking <span className={styles.boldTxt}>Mark Payment</span> you
            are marking the Payment for the selected unit as done to the user
            can. This will mark unit as{' '}
            {UNIT_STATUSES_INDEX[unitCart.unit.status] <
            UNIT_STATUSES_INDEX[UNIT_STATUSES.BLOCKED]
              ? 'Blocked'
              : 'Booked'}
            {/* After this user will be able to proceed to Signing Draft Agreement
            etc. */}
          </p>
          <Button
            onClick={
              paymentModalState === paymentModalStates.EDIT
                ? handleEditTransactionDetails
                : updateTransactionDetails
            }
            propStyles={styles.markPaymentButton}
            disabled={false}>
            {paymentModalState === paymentModalStates.NEW
              ? `${
                  UNIT_STATUSES_INDEX[unitCart.unit.status] <
                  UNIT_STATUSES_INDEX[UNIT_STATUSES.BLOCKED]
                    ? 'Block'
                    : 'Book'
                } & Mark Payment`
              : paymentModalState === paymentModalStates.EDIT
              ? 'Edit the Payment'
              : 'Update the Payment'}
          </Button>
          {paymentModalState !== paymentModalStates.NEW && (
            <Button
              onClick={handleRemoveTransactionDetails}
              propStyles={`${styles.markPaymentButton} ${styles.removePaymentButton}`}>
              Remove the Payment
            </Button>
          )}
          {/*<div className={`${genericStyles.pointer} ${styles.ghostButton} `}>
            <p>Download Cost Sheet</p>
          </div>*/}
        </footer>
      </div>
    </Modal>
  );
};

export default PaymentModal;
