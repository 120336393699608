import { useState } from 'react';

import PdfDownloadButton from 'apps/booking/components/common/PdfDownloadButton';
import { useAgreementPdfMutation } from 'api/units1';
import { downloadImage } from 'utils/downloadImage';

interface IAgreementDownloadButtonProps {
  unitCartId: string;
}

const AgreementDownloadButton = (props: IAgreementDownloadButtonProps) => {
  const { unitCartId } = props;
  const [isDownloadInProgress, setIsDownloadInProgress] = useState(false);
  const [agreementPdfAPI] = useAgreementPdfMutation();

  const onDownloadBtnClick = async () => {
    setIsDownloadInProgress(true);
    const response = await agreementPdfAPI(unitCartId);
    'data' in response
      ? downloadImage(
          response.data.url as keyof IAgreementDownloadButtonProps,
          'agreement.pdf'
        )
      : console.error(
          'Error occurred while downloading agreement:',
          response.error
        );
    setIsDownloadInProgress(false);
  };

  return (
    <PdfDownloadButton
      onDownloadBtnClick={onDownloadBtnClick}
      isLoading={isDownloadInProgress}
    />
  );
};

export default AgreementDownloadButton;
