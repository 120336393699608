import { useState } from 'react';

import * as yup from 'yup';

import { GridReadyEvent, ICellRendererParams } from 'ag-grid-community';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';

import NameCard from 'apps/booking/components/common/admin/NameCard';
import { ICpLead } from 'apps/cp/interfaces';
import { DEFAULT_NULL_VALUE } from 'apps/admin/components/common/ExpandedDetailSection';
import { dateToDaythMonthYear } from 'utils/dateFormator';
import TextInput from 'apps/booking/components/common/inputs/TextInput';
import LeadAnalyticsCell from 'apps/admin/components/pages/ProjectLeads/VsgLeadList/LeadAnalyticsCell';

import styles from './styles.module.css';

interface ILeadListProps {
  leadsList: ICpLead[];
  projectId: string;
}

const schema = yup.object().shape({
  name: yup.string().required(),
});

const VsgLeadList = (props: ILeadListProps) => {
  const { leadsList, projectId } = props;

  const [gridApi, setGridApi] = useState(null) as any;
  // We used type "any" at all other places previously as there is no type specified in the documentation

  const onGridReady = (params: GridReadyEvent) => {
    setGridApi(params.api);
  };

  //Ag-grid global search
  const handleGridSearch = (searchString: string) => {
    gridApi?.setQuickFilter(searchString);
  };

  // TODO: TO update the api functionality
  const handelLeadStatusUpdate = (updatedLeadStatus: ICpLead) => {};

  // const getDetails = (unitDetails:any) => {
  //   return [
  //     {
  //       label: 'Unit details',
  //       value: 'xx',
  //     },
  //     {
  //       label: 'Unit Config',
  //       value: 'xx',
  //     },
  //     {
  //       label: 'Status of Query',
  //       value: 'xx',
  //     },
  //     {
  //       label: 'Last Updated on',
  //       value: 'xx',
  //     },
  //     {
  //       label: 'Firm Name',
  //       value: 'xx',
  //     },
  //     {
  //       label: 'Firm URL',
  //       value: channelPartner.cpFirmURL,
  //     },
  //     {
  //       label: 'Firm GST Number',
  //       value: channelPartner.cpFirmGST,
  //     }

  return (
    <>
      <header>
        <div className={styles.searchContainer}>
          <TextInput
            type='text'
            inputHTMLElementStyles={styles.searchInput}
            placeHolder='Search Name'
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              handleGridSearch(event.target.value);
            }}
            startIconName='search'
            startIconVariant='SECONDARY'
          />
        </div>
      </header>
      <div
        className={`ag-theme-alpine ${styles.gridContainer}`}
        style={{ width: '100%', height: '100%' }}>
        <AgGridReact
          rowData={leadsList}
          defaultColDef={{
            sortable: false,
            filter: false,
            resizable: false,
            suppressMenu: true,
          }}
          domLayout={'autoHeight'}
          pagination={true}
          paginationPageSize={10}
          masterDetail={true}
          animateRows={true}
          detailCellRenderer={'detailCellRenderer'}
          detailRowAutoHeight={true}
          onGridReady={onGridReady}>
          <AgGridColumn flex={0.8} hide={true} field='cpName' />
          <AgGridColumn
            flex={3}
            headerName='Name'
            field='user.name'
            cellRendererFramework={(params: ICellRendererParams) => (
              <div className={styles.projectsColumnDataContainer}>
                {params.data.name ? (
                  <NameCard
                    {...{
                      title: params.data.name,
                      subtitle: `Mob: ${params.data.mobile}`,
                    }}
                  />
                ) : (
                  DEFAULT_NULL_VALUE
                )}
              </div>
            )}
          />
          <AgGridColumn
            flex={2}
            headerName='Registration Date'
            cellRendererFramework={(params: ICellRendererParams) => (
              <p className={styles.cpName}>
                {params.data.createdAt
                  ? dateToDaythMonthYear(params.data.createdAt)
                  : DEFAULT_NULL_VALUE}
              </p>
            )}
          />
          <AgGridColumn
            flex={2}
            headerName='Source'
            cellRendererFramework={(params: ICellRendererParams) => (
              <p className={styles.cpName}>
                {/* {params.data.source || DEFAULT_NULL_VALUE} */}
                Direct
              </p>
            )}
          />
          {/* TODO: to integrated after the API is crated */}
          {/* <AgGridColumn
            flex={1.5}
            headerName='Collaterals'
            cellRendererFramework={(params: ICellRendererParams) => (
              <h2 className={styles.growth}>
                {params.data.projects?.length || 0}
              </h2>
            )}
          />
          <AgGridColumn
            flex={1}
            headerName='Site Visit'
            cellRendererFramework={(params: ICellRendererParams) => (
              <h2 className={styles.growth}>
                {params.data.projects?.length || 0}
              </h2>
            )}
          />
          <AgGridColumn
            flex={1.5}
            headerName='Status'
            field='Status'
            cellRendererFramework={LeadStatusDropDownCell}
            cellRendererParams={(params: ICellRendererParams) => {
              return {
                leadsList: params.data,
                onStatusUpdate: (updatedLeadStatus: ICpLead) =>
                  handelLeadStatusUpdate(updatedLeadStatus),
              };
            }}></AgGridColumn> */}
          <AgGridColumn
            flex={2}
            headerName='Analytics'
            headerClass='relata-ag-grid-header-small-font'
            cellRendererFramework={LeadAnalyticsCell}
            cellRendererParams={(params: ICellRendererParams) => {
              return {
                disabled: params.data.disabled,
                lead: params.data,
                projectId: projectId,
              };
            }}
          />
          {/* <AgGridColumn
            flex={0.8}
            headerName='Block'
            headerClass='relata-ag-grid-header-small-font'
            cellRendererFramework={InactiveLeadStatusCell}
            cellRendererParams={(params: ICellRendererParams) => {
              return {
                leadId: params.data.id,
                disabled: params.data.disabled,
                onRowUpdate: (updatedLead: ICpLead) =>
                  params.node.setData(updatedLead),
              };
            }}></AgGridColumn> */}
        </AgGridReact>
      </div>
    </>
  );
};

export default VsgLeadList;
