import React from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import Image from '../common/Image';
import CostSheetPlank from './CostSheetPlank';
import CostSheetPdfDownloadButton from './CostSheetPdfDownloadButton';

import { usePostUnitCartMutation } from '../../../../api/unitCart';
import {
  getSelectedUnit,
  setUnitCart,
  getUnitCart,
  getSelectedEOI,
} from '../../slices/selectedUnit';
import {
  getAppSliceIsFetching,
  setAppSliceIsFetching,
} from '../../../../slices/appSlice';
import { getUser, setShowAuthForms } from '../../slices/auth';
import { setBookingRoutesCurrentPage } from 'apps/booking/routers/BookingRoutes/slice';

import { isEmpty } from '../../../../utils/utils';
import { floorPlanFileName } from '../unitSelection/utils';

import { SIDEBAR_ITEM_INDEX } from '../SidebarItem';
import { URLS, floorPlanFallback } from '../../../../constants/urls';

const Costsheet = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const appSliceIsFetching = useSelector(getAppSliceIsFetching);
  const user = useSelector(getUser);
  const unitCart = useSelector(getUnitCart);
  let selectedUnit = useSelector(getSelectedUnit);
  const eoi = useSelector(getSelectedEOI);
  const unitCartUnit = unitCart.unit;
  if (!isEmpty(unitCartUnit)) {
    selectedUnit = unitCartUnit;
  }

  const [postUnitCartAPI] = usePostUnitCartMutation();

  const fileName = floorPlanFileName(selectedUnit);

  const handleUnitCart = async () => {
    const unitCartExists = unitCart.id;
    if (!unitCartExists) {
      /*
        Create unitCart if it doesn't exists
        It is used to track explored units.
      */
      const unitCartBody = {
        ...unitCart,
        user: user.id,
        unit: selectedUnit.id,
        progress: SIDEBAR_ITEM_INDEX[URLS.COST_SHEET],
        eoi: eoi.id,
      };
      const { data: unitCartdata, error: unitCartError } =
        await postUnitCartAPI({ ...unitCartBody }).unwrap();

      if (!isEmpty(unitCartError)) {
        console.error('unitCartError', unitCartError);
        alert('unitCartError', unitCartError);
        return false;
      }

      if (!isEmpty(unitCartdata)) {
        dispatch(setUnitCart(unitCartdata));
      }
    }
    return true;
  };

  const handleCostSheetProceed = async () => {
    if (!isEmpty(user)) {
      dispatch(setAppSliceIsFetching(true));
      const handleUnitCartStatus = await handleUnitCart();
      dispatch(setAppSliceIsFetching(false));

      if (!handleUnitCartStatus) {
        return false;
      }

      dispatch(setBookingRoutesCurrentPage(URLS.PAYMENT_PLAN));
    } else {
      dispatch(setShowAuthForms(true));
    }
  };

  return (
    <div className='costsheet'>
      <div className='costsheet-details'>
        <CostSheetPlank />
        <footer className='costsheet-details__footer'>
          <button
            className='btn btn--transparent'
            onClick={() => {
              dispatch(setBookingRoutesCurrentPage(URLS.UNIT_DETAILS));
            }}>
            Back to Unit Selection
          </button>
          <button
            className={`btn btn--enabled ${
              appSliceIsFetching && 'loading-spin-container'
            }`}
            onClick={handleCostSheetProceed}>
            <span>Proceed</span>
            <span className='ic2-fa-spin-blue'></span>
          </button>
          <CostSheetPdfDownloadButton
            userId={user.id}
            unitId={selectedUnit.id}
          />
        </footer>
      </div>

      <div className='costsheet-image-container'>
        <Image
          src={`https://relata-temp.s3.ap-south-1.amazonaws.com/embassy/2d/${fileName}`}
          alt='Unit 2D plan'
          fallbackSrc={floorPlanFallback}
        />
      </div>
    </div>
  );
};

export default Costsheet;
