import { useSelector } from 'react-redux';
import { getProject } from 'apps/booking/slices/projectSlice';
import { useState, useEffect } from 'react';

import SiteVisit from '../../components/header/siteVisit/SiteVisit';
import Auth from '../../components/header/auth/Auth';
import DashboardNavItem from './DashboardNavItem';
import { NavLink } from 'react-router-dom';
import { dateToTimeDayDateMonth } from '../../../../utils/dateFormator';

const getISTDate = () => {
  var currentTime = new Date();
  var currentOffset = currentTime.getTimezoneOffset();
  var ISTOffset = 330; // IST offset UTC +5:30
  var ISTTime = new Date(
    currentTime.getTime() + (ISTOffset + currentOffset) * 60000
  );
  return ISTTime;
};

const Header = () => {
  const project = useSelector(getProject);
  const [today, setDateTime] = useState(getISTDate());
  useEffect(() => {
    const timer = setInterval(() => {
      setDateTime(getISTDate());
    }, 60 * 1000);
    return () => {
      clearInterval(timer);
    };
  }, []);

  const dateTime = dateToTimeDayDateMonth(today);

  return (
    <>
      <div className='app-header'>
        <nav className='nav'>
          <NavLink className='nav__brand' to='/'>
            {/* <img
              src='https://relata-temp.s3.ap-south-1.amazonaws.com/embassy/assets/embassy-primary-logo.svg'
              alt='Website Logo'
            /> */}
            relata.io
          </NavLink>
          <ul className='list nav__list'>
            <DashboardNavItem />
            {/*TODO: disabling temporarily*/}
            {false && (
              <li className='nav__item nav__item--disabled'>
                <a
                  href='https://d1b2b4oevn2eyz.cloudfront.net/embassy/index.html'
                  target='_blank'
                  rel='noreferrer'>
                  3D Tour
                </a>
              </li>
            )}
            {false && (
              <li className='nav__item nav__item--disabled'>
                <SiteVisit />
              </li>
            )}
            {false && (
              <li className='nav__item'>
                {/* dynamic brochure on project basis*/}
                <a
                  href={`https://relata.s3.ap-south-1.amazonaws.com/projects/${project.id}/brochure`}
                  target='_blank'
                  rel='noreferrer'>
                  Brochure
                </a>
              </li>
            )}
            <li className='nav__item nav__vbar'></li>
            <li className='nav__item'>{dateTime}</li>
            <li className='nav__item'>
              <Auth />
            </li>
            {/* <li className='nav__item'>
              <img
                className='nav__brand'
                src='https://relata-temp.s3.ap-south-1.amazonaws.com/embassy/assets/embassy.png'
                alt='Website Logo'
              />
            </li> */}
          </ul>
        </nav>
      </div>
    </>
  );
};

export default Header;
