import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { baseUrl } from 'constants/urls';
import { getAuthTokenLocal } from 'utils/localStorage';

export const cpAPISlice = createApi({
  reducerPath: 'cpAPISlice',
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders(headers) {
      headers.set('x-auth-token', getAuthTokenLocal());
      return headers;
    },
  }),
  // TODO - Make best use of this
  // tagTypes: ['Projects'], //['Companies','DemoRooms'],
  endpoints: () => ({}),
});
