import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';

import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import { GridReadyEvent, ICellRendererParams } from 'ag-grid-community';

import TextInput from 'apps/booking/components/common/inputs/TextInput';
import NameCard from 'apps/booking/components/common/admin/NameCard';
import { awsBaseUrl } from 'constants/urls';
import ExpandRowCell from 'apps/admin/components/pages/ActiveLeads/cell-renderers/ExpandRowCell';
import ExpandedDetailSection, {
  DEFAULT_NULL_VALUE,
} from 'apps/admin/components/common/ExpandedDetailSection';
import { IActiveLead } from 'apps/admin/components/pages/ProjectPartnerLeads';
import { dateToDaythMonthYear } from 'utils/dateFormator';
import { useGetProjectPartnerActiveLeadsMutation } from 'apps/admin/services/projectLeadsAPI';
import { getAdminUser } from 'apps/admin/slices/adminSlice';
import { TObjectId } from 'interfaces';
import { UserRole } from 'constants/userRoles';
import Pagination from 'apps/admin/components/common/Pagination';
import DropDownInput, {
  DropDownOptionType,
} from 'apps/booking/components/common/inputs/DropDownInput';
import SpinLoader from 'apps/booking/components/common/SpinLoader';
import Button from 'apps/booking/components/common/buttons/Button';
import BookingLeadsCell from './BookingLeadsCell';
import ActiveLeadDetailsCell from '../PartnerLeadDetailsCell';

import styles from './styles.module.css';

interface IActivePartnerLeadsProps {
  projectId: TObjectId;
  activeLeadsCount: number;
  activeCountLoaded: boolean;
}

export const searchFieldOptions: DropDownOptionType[] = [
  {
    value: 'cpLeadName',
    displayText: 'Lead Name',
  },
  {
    value: 'partnerName',
    displayText: 'Partner Name',
  },
  { value: 'phoneNumber', displayText: 'Phone Number' },
  { value: 'unit-config', displayText: 'Unit Config' },
  { value: 'smName', displayText: 'SM Name' },
  { value: 'greName', displayText: 'GRE name' },
];

export const pageCount = 10;

const ActivePartnerLeads = (props: IActivePartnerLeadsProps) => {
  const { projectId, activeLeadsCount, activeCountLoaded } = props;

  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [gridApi, setGridApi] = useState(null) as any;
  const [activeLeadsLoaded, setActiveLeadsLoaded] = useState(false);
  const [activePartnerLeads, setActivePartnerLeads] = useState<IActiveLead[]>(
    []
  );
  const [searchDetails, setSearchDetails] = useState({
    searchName: '',
    searchField: '',
    startDate: '',
    endDate: '',
  });

  const { register, unregister, setValue, handleSubmit } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });

  const user = useSelector(getAdminUser);
  const [getProjectPartnerActiveLeadsAPI] =
    useGetProjectPartnerActiveLeadsMutation();

  //AG-grid Default column definition
  const defaultColDef = {
    sortable: false,
    filter: false,
    resizable: false,
    suppressMenu: true,
    editable: false,
    flex: 1,
  };
  const totalPages = Math.ceil(activeLeadsCount / pageCount);

  useEffect(() => {
    getActiveLeads(1);
  }, [projectId]);

  const getDetails = (partnerLeads: IActiveLead) => {
    return [
      {
        label: 'Unit Preference',
        value: partnerLeads.activeLead.cpLead.propertyConfigs.join(', '),
      },
      {
        label: 'Registered on',
        value: dateToDaythMonthYear(
          partnerLeads.activeLead.cpLead.registrationDate
        ),
      },
      {
        label: 'Days between Registration & First site visit',
        value: Math.abs(
          partnerLeads.diffFirstSiteVisitDateAndRegistrationDateInDays
        ).toString(),
      },
      {
        label: 'Partners Engaged',
        value: partnerLeads.uniqueLeadSiteVisitCps
          .map((cp) => cp.name)
          .join(', '),
      },
    ];
  };

  const getMoreDetails = (params: ICellRendererParams) => {
    return [
      {
        label: 'GRE',
        value: params.data.activeLead.executive.name || DEFAULT_NULL_VALUE,
      },

      {
        label: 'QR Shared',
        value: params.data.qrCodeShared,
      },
    ];
  };

  const getActiveLeads = async (
    currentPageNumber: number,
    searchedData?: {
      searchName: string;
      searchField: string;
      startDate: string;
      endDate: string;
    }
  ) => {
    try {
      setActiveLeadsLoaded(true);
      const response = await getProjectPartnerActiveLeadsAPI({
        projectId,
        pageNumber: currentPageNumber ? currentPageNumber : 1,
        pageCount,
        executiveId:
          user?.role === UserRole.EXECUTIVE || user?.role === UserRole.GRE
            ? user.id
            : '',
        ...searchedData,
      }).unwrap();

      setActivePartnerLeads(response.data);
    } catch (error) {
      console.log(error);
    } finally {
      setActiveLeadsLoaded(false);
    }
  };

  // Ag-grid global search
  const handleGridSearch = (event: string) => {
    gridApi.setQuickFilter(event);
  };

  // This function will automatically be called when ag grid is ready
  const onGridReady = (params: GridReadyEvent) => {
    setGridApi(params.api);
  };

  const getLeadImageUrl = (params: ICellRendererParams) => {
    return `${awsBaseUrl}/common/AZAlphabets/${params.data.activeLead.lead.name[0].toUpperCase()}0.png`;
  };

  const handleNextPage = () => {
    getActiveLeads(currentPageNumber + 1, searchDetails);
    setCurrentPageNumber(currentPageNumber + 1);
  };

  const handleOnPageChange = () => {
    getActiveLeads(currentPageNumber - 1, searchDetails);
    setCurrentPageNumber(currentPageNumber - 1);
  };

  const handleNavigationToFirstPage = () => {
    if (currentPageNumber !== 1) {
      getActiveLeads(1, searchDetails);
      setCurrentPageNumber(1);
    }
  };

  const handleNavigationToLastPage = () => {
    if (currentPageNumber !== totalPages) {
      getActiveLeads(totalPages, searchDetails);
      setCurrentPageNumber(totalPages);
    }
  };

  const handleSearchActiveLeads = (searchedData: {
    searchName: string;
    searchField: string;
    startDate: string;
    endDate: string;
  }) => {
    setSearchDetails(searchedData);
    getActiveLeads(1, searchedData);
    setCurrentPageNumber(1);
  };

  return (
    <>
      <header>
        <form
          className={styles.searchContainer}
          onSubmit={handleSubmit(handleSearchActiveLeads)}>
          <DropDownInput
            options={searchFieldOptions}
            defaultSelectedOption={searchFieldOptions[0]}
            lable='Select search type '
            register={register}
            setValue={setValue}
            unregister={unregister}
            name='searchField'
            propStyles={styles.inputBox}
          />
          <TextInput
            inputHTMLElementStyles={styles.searchInput}
            placeHolder='Search'
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              handleGridSearch(event.target.value);
            }}
            register={register}
            setValue={setValue}
            unregister={unregister}
            startIconName='search'
            startIconVariant='SECONDARY'
            label='Search Lead, Partner and SM name'
            name='searchName'
            propStyles={styles.inputBox}
          />
          <TextInput
            type='date'
            placeHolder='Select start date'
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              handleGridSearch(event.target.value);
            }}
            register={register}
            setValue={setValue}
            unregister={unregister}
            label='From'
            name='startDate'
            propStyles={styles.inputBox}
          />
          <TextInput
            type='date'
            placeHolder='Select start date'
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              handleGridSearch(event.target.value);
            }}
            register={register}
            setValue={setValue}
            unregister={unregister}
            label='To'
            name='endDate'
            propStyles={styles.inputBox}
          />
          <Button accent='primary' propStyles={styles.searchBtn} type='submit'>
            Search
          </Button>
        </form>
      </header>
      {activeLeadsLoaded ? (
        <SpinLoader />
      ) : (
        <div
          className={`ag-theme-alpine ${styles.gridContainer}`}
          style={{ width: '100%', height: '100%' }}>
          <AgGridReact
            rowData={activePartnerLeads}
            defaultColDef={defaultColDef}
            onGridReady={onGridReady}
            domLayout={'autoHeight'}
            pagination={false}
            paginationPageSize={pageCount}
            masterDetail={true}
            animateRows={true}
            detailCellRenderer={'detailCellRenderer'}
            detailRowAutoHeight={true}
            frameworkComponents={{
              detailCellRenderer: ExpandedDetailSection,
            }}
            detailCellRendererParams={(params: ICellRendererParams) => ({
              details: getDetails(params.data),
            })}>
            <AgGridColumn
              flex={0.8}
              cellClass='ag-grid-column-custom-cell'
              cellRendererFramework={ExpandRowCell}
              cellRendererParams={(params: ICellRendererParams) => params}
            />
            <AgGridColumn
              flex={2.5}
              headerName='Name'
              field={'activeLead.lead.name'}
              cellRendererFramework={(params: ICellRendererParams) => (
                <NameCard
                  {...{
                    imgUrl: `${getLeadImageUrl(params)}`,
                    title: params.data.activeLead.lead.name,
                    subtitle: params.data.activeLead.lead.mobile,
                  }}
                />
              )}
            />
            <AgGridColumn
              flex={1.5}
              headerName='Sitevisit on'
              cellRendererFramework={(params: ICellRendererParams) => (
                <p className={styles.cpName}>
                  {params.data.latestSiteVisitDate
                    ? dateToDaythMonthYear(params.data.latestSiteVisitDate)
                    : DEFAULT_NULL_VALUE}
                </p>
              )}
            />
            <AgGridColumn
              flex={2}
              headerName='Partner'
              field={'activeLead.assignedUser.name'}
              cellRendererFramework={(params: ICellRendererParams) => (
                <p className={styles.cpName}>
                  {params.data.activeLead.assignedUser.name}
                </p>
              )}
            />
            <AgGridColumn
              flex={2}
              headerName='Firm'
              field={'cpFirmDisplayName'}
              cellClass='ag-cell-no-text-overflow ag-cell-wrap-text ag-cell-value'
              cellRendererFramework={(params: ICellRendererParams) => (
                <p className={styles.firmName}>
                  {params.data.cpFirmDisplayName || DEFAULT_NULL_VALUE}
                </p>
              )}
            />
            <AgGridColumn
              flex={2}
              headerName='SM'
              field={'smUser.name'}
              cellClass='ag-cell-no-text-overflow'
              cellRendererFramework={(params: ICellRendererParams) => (
                <p className={styles.cpName}>
                  {params.data.smUser?.name || DEFAULT_NULL_VALUE}
                </p>
              )}
            />
            <AgGridColumn
              flex={2}
              headerName='Sitevisits'
              cellRendererFramework={(params: ICellRendererParams) => (
                <p className={styles.counts}>
                  {params.data.siteVisitCount
                    ? params.data.siteVisitCount
                    : DEFAULT_NULL_VALUE}
                </p>
              )}
            />
            <AgGridColumn
              flex={1.5}
              headerName='Location'
              cellRendererFramework={(params: ICellRendererParams) => (
                <p className={styles.cpName}>
                  {params.data.firstSiteVisitLocation
                    ? params.data.firstSiteVisitLocation
                    : DEFAULT_NULL_VALUE}
                </p>
              )}
            />
            {user?.role === UserRole.SUPERADMIN && (
              <AgGridColumn
                flex={1.5}
                headerName='Booking'
                cellRendererFramework={BookingLeadsCell}
                cellRendererParams={(params: ICellRendererParams) => ({
                  projectId: projectId,
                  activeLeadId: params.data.activeLead.id,
                  bookedLead: params.data.activeLead.bookedLead,
                })}
              />
            )}
            <AgGridColumn
              flex={1.5}
              headerName='Details'
              cellRendererFramework={ActiveLeadDetailsCell}
              cellRendererParams={(params: ICellRendererParams) => ({
                moreDetails: getMoreDetails(params),
                modalHeading: 'Active Lead Details',
              })}
            />
          </AgGridReact>
          <Pagination
            totalRowCount={activeLeadsCount || 0}
            rowCount={activePartnerLeads.length}
            currentPage={currentPageNumber - 1}
            onPageChange={handleOnPageChange}
            onNextPage={handleNextPage}
            onNavigateTofirstPage={handleNavigationToFirstPage}
            onNavigationToLastPage={handleNavigationToLastPage}
            loaded={activeCountLoaded}
          />
        </div>
      )}
    </>
  );
};

export default ActivePartnerLeads;
