import Modal from 'apps/booking/components/common/modal/Modal';
import Icon from 'apps/booking/components/common/Icon/index';
import { IApplicant } from 'apps/booking/interfaces';
import ApplicantDetails from 'apps/admin/components/pages/AdminDashboard/AdminExecutiveList/modals/UserCkycModal/applicantDetails';

import styles from './styles.module.css';

interface IUserCkycModal {
  onModalClose: Function;
  applicants: IApplicant[];
}

const UserCkycModal = (props: IUserCkycModal) => {
  const { onModalClose, applicants } = props;

  return (
    <Modal
      onOutsideClick={() => {
        onModalClose(false);
      }}
      additionalClasses={styles.executiveDetailsModal}>
      <header className={styles.modalHeader}>
        <div>
          <h2 className={styles.modalHeading}>Details of Applicants</h2>
        </div>
        <Icon
          name='close'
          onClick={() => {
            onModalClose(false);
          }}></Icon>
      </header>
      <main className={styles.modalBox}>
        <div className={styles.mainCard}>
          {applicants?.map((applicant: IApplicant) => (
            <>
              <div className={styles.applicantWrapper} key={applicant.id}>
                <ApplicantDetails
                  label='Name'
                  value={`${applicant.firstName} ${applicant.lastName}`}
                />
                <ApplicantDetails label='Email' value={applicant.email} />
                <ApplicantDetails label='Phone' value={applicant.mobile} />
                <ApplicantDetails
                  label='Applicant Type'
                  value={applicant.type}
                />
                <ApplicantDetails
                  label='Aadhar'
                  value={applicant.aadharNumber}
                  docUrl={applicant.aadharDocUrl}
                />
                <ApplicantDetails
                  label='Pan Number'
                  value={applicant.panNumber}
                  docUrl={applicant.panDocUrl}
                />
              </div>
              <hr className={styles.horizontalLine} />
            </>
          ))}
        </div>
        {!applicants.length && (
          <p className={styles.emptyContainer}>No details Available</p>
        )}
      </main>
    </Modal>
  );
};

export default UserCkycModal;
