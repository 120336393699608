import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { GridReadyEvent, ICellRendererParams } from 'ag-grid-community';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';

import ExpandedFirmList from 'apps/admin/components/pages/ProjectFirmPartners/ExpandedFirmList';
import ExpandRowCell from 'apps/admin/components/pages/ActiveLeads/cell-renderers/ExpandRowCell';
import { dateToDaythMonthYear } from 'utils/dateFormator';
import { IPartnerFirm } from 'apps/admin/components/pages/AllFirms';
import TextInput from 'apps/booking/components/common/inputs/TextInput';
import { TObjectId } from 'interfaces';
import {
  useGetProjectPartnerFirmsCountQuery,
  useGetProjectPartnerFirmsMutation,
} from 'apps/admin/services/companyAPISlice';
import Button from 'apps/booking/components/common/buttons/Button';
import Pagination from 'apps/admin/components/common/Pagination';
import SpinLoader from 'apps/booking/components/common/SpinLoader';
import { DEFAULT_NULL_VALUE } from 'apps/admin/components/common/ExpandedDetailSection';

import styles from './styles.module.css';

interface ILeadListProps {
  projectId: TObjectId;
  firmCount: number;
  countLoaded: boolean;
}

export const pageCount = 10;

const LeadList = (props: ILeadListProps) => {
  const { projectId, firmCount, countLoaded } = props;

  const [firmList, setFirmList] = useState<IPartnerFirm[]>([]);
  const [firmLoaded, setFirmLoaded] = useState(false);
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [gridApi, setGridApi] = useState(null) as any;
  const [searchDetails, setSearchDetails] = useState({
    searchName: '',
    startDate: '',
    endDate: '',
  });

  const { register, unregister, setValue, handleSubmit } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });

  const [getProjectPartnerFirms] = useGetProjectPartnerFirmsMutation();

  const totalPages = Math.ceil(firmCount / pageCount);

  useEffect(() => {
    getFirmList(1);
  }, [projectId]);

  // Ag-grid global search
  const handleGridSearch = (event: string) => {
    gridApi.setQuickFilter(event);
  };

  // This function will automatically be called when ag grid is ready
  const onGridReady = (params: GridReadyEvent) => {
    setGridApi(params.api);
  };

  const getFirmList = async (
    currentPageNumber: number,
    searchDate?: {
      searchName: string;
      startDate: string;
      endDate: string;
    }
  ) => {
    try {
      setFirmLoaded(true);
      const payload = {
        projectId,
        searchField: '',
        pageNumber: currentPageNumber ? currentPageNumber : 1,
        pageCount,
        ...searchDate,
      };
      const response = await getProjectPartnerFirms(payload).unwrap();
      setFirmList(response.data);
    } catch (err) {
    } finally {
      setFirmLoaded(false);
    }
  };

  const handleSearchActiveFirms = (searchData: {
    searchName: string;
    startDate: string;
    endDate: string;
  }) => {
    setSearchDetails(searchData);
    getFirmList(1, searchData);
    setCurrentPageNumber(1);
  };

  const handleNextPage = () => {
    getFirmList(currentPageNumber + 1, searchDetails);
    setCurrentPageNumber(currentPageNumber + 1);
  };

  const handleOnPageChange = () => {
    getFirmList(currentPageNumber - 1, searchDetails);
    setCurrentPageNumber(currentPageNumber - 1);
  };

  const handleNavigationToFirstPage = () => {
    if (currentPageNumber !== 1) {
      getFirmList(1, searchDetails);
      setCurrentPageNumber(1);
    }
  };

  const handleNavigationToLastPage = () => {
    if (currentPageNumber !== totalPages) {
      getFirmList(totalPages, searchDetails);
      setCurrentPageNumber(totalPages);
    }
  };

  return (
    <>
      <header>
        <form
          className={styles.searchContainer}
          onSubmit={handleSubmit(handleSearchActiveFirms)}>
          <TextInput
            inputHTMLElementStyles={styles.searchInput}
            label='Search'
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              handleGridSearch(event.target.value);
            }}
            register={register}
            setValue={setValue}
            unregister={unregister}
            startIconName='search'
            startIconVariant='SECONDARY'
            placeHolder='Search Lead, Partner and SM name'
            name='searchName'
            propStyles={styles.inputBox}
          />
          <TextInput
            type='date'
            placeHolder='Select start date'
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              handleGridSearch(event.target.value);
            }}
            register={register}
            setValue={setValue}
            unregister={unregister}
            label='From'
            name='startDate'
            propStyles={styles.dateInputBox}
          />
          <TextInput
            type='date'
            placeHolder='Select start date'
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              handleGridSearch(event.target.value);
            }}
            register={register}
            setValue={setValue}
            unregister={unregister}
            label='To'
            name='endDate'
            propStyles={styles.dateInputBox}
          />
          <Button accent='primary' propStyles={styles.searchBtn} type='submit'>
            Search
          </Button>
        </form>
      </header>
      {firmLoaded ? (
        <SpinLoader />
      ) : (
        <div
          className={`ag-theme-alpine ${styles.gridContainer}`}
          style={{ width: '100%', height: '100%' }}>
          <AgGridReact
            rowData={firmList}
            defaultColDef={{
              sortable: false,
              filter: false,
              resizable: false,
              suppressMenu: true,
            }}
            domLayout={'autoHeight'}
            pagination={false}
            paginationPageSize={pageCount}
            masterDetail={true}
            animateRows={true}
            detailCellRenderer={'detailCellRenderer'}
            detailRowAutoHeight={true}
            onGridReady={onGridReady}
            frameworkComponents={{
              detailCellRenderer: ExpandedFirmList,
            }}>
            <AgGridColumn flex={0.8} hide={true} field='cpName' />
            <AgGridColumn
              flex={0.8}
              cellClass='ag-grid-column-custom-cell'
              cellRendererFramework={ExpandRowCell}
              cellRendererParams={(params: ICellRendererParams) =>
                params
              }></AgGridColumn>
            <AgGridColumn
              headerName='Name'
              field='firmName'
              flex={1.5}
              cellRendererFramework={(params: ICellRendererParams) => (
                <p className={styles.cpName}>{params.data.firmName}</p>
              )}></AgGridColumn>

            <AgGridColumn
              flex={1.2}
              headerName='Registered On'
              cellRendererFramework={(params: ICellRendererParams) => (
                <p className={styles.cpName}>
                  {params.data.projectRegistrationDate
                    ? dateToDaythMonthYear(params.data.projectRegistrationDate)
                    : DEFAULT_NULL_VALUE}
                </p>
              )}
            />
            <AgGridColumn
              flex={1}
              headerName='Partners'
              cellRendererFramework={(params: ICellRendererParams) => (
                <h2 className={styles.counts}>{params.data.totalCPRegister}</h2>
              )}
            />
            <AgGridColumn
              flex={1}
              headerName='Leads'
              cellRendererFramework={(params: ICellRendererParams) => (
                <h2 className={styles.counts}>
                  {params.data.totalLeadsRegister}
                </h2>
              )}
            />
            <AgGridColumn
              flex={1}
              headerName='Collaterals'
              cellRendererFramework={(params: ICellRendererParams) => (
                <h2 className={styles.counts}>
                  {params.data.totalCollateralShared}
                </h2>
              )}
            />
            <AgGridColumn
              flex={1}
              headerName='Site Visit'
              cellRendererFramework={(params: ICellRendererParams) => (
                <h2 className={styles.counts}>{params.data.totalSiteVisit}</h2>
              )}
            />
            <AgGridColumn
              flex={1}
              headerName='Assigned'
              cellRendererFramework={(params: ICellRendererParams) => (
                <h2 className={styles.counts}>
                  {params.data.totalAssignedLeads}
                </h2>
              )}
            />

            <AgGridColumn
              flex={1}
              headerName='Losts leads'
              cellRendererFramework={(params: ICellRendererParams) => (
                <h2 className={styles.counts}>
                  {Math.abs(
                    params.data.totalSiteVisit - params.data.totalAssignedLeads
                  )}
                </h2>
              )}
            />
            {/* TODO: TO be implimented when required */}
            {/* <AgGridColumn
          flex={0.5}
          headerName='More'
          cellRendererFramework={MoreMenuItems}
          cellRendererParams={(params: ICellRendererParams): IMoreMenuCell => ({
            project: params.data,
          })}></AgGridColumn> */}
          </AgGridReact>
          <Pagination
            totalRowCount={firmCount || 0}
            rowCount={firmList.length}
            currentPage={currentPageNumber - 1}
            onPageChange={handleOnPageChange}
            onNextPage={handleNextPage}
            onNavigateTofirstPage={handleNavigationToFirstPage}
            onNavigationToLastPage={handleNavigationToLastPage}
            loaded={countLoaded}
          />
        </div>
      )}
    </>
  );
};

export default LeadList;
