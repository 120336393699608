import { useState } from 'react';

import { GridReadyEvent, ICellRendererParams } from 'ag-grid-community';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';

import NameCard from 'apps/booking/components/common/admin/NameCard';
import ExpandRowCell from 'apps/admin/components/pages/ActiveLeads/cell-renderers/ExpandRowCell';
import ExpandedDetails from 'apps/admin/components/pages/CollaboratorsList/CollaboratorsStatus/ExpandedDetails';
import TextInput from 'apps/booking/components/common/inputs/TextInput';
import { ICollaborators } from '..';
import ProjectListCell from 'apps/admin/components/pages/CollaboratorsList/CollaboratorsStatus/ProjectListCell';

import styles from './styles.module.css';

interface ICollaboratorsStatusProps {
  collaborators: ICollaborators[];
}
//AG-grid Default column definition
const defaultColDef = {
  sortable: false,
  filter: false,
  resizable: false,
  suppressMenu: true,
  editable: false,
  flex: 1,
};

const CollaboratorsStatus = (props: ICollaboratorsStatusProps) => {
  const { collaborators } = props;

  const [gridApi, setGridApi] = useState(null) as any;

  const handleGridSearch = (event: string) => {
    gridApi.setQuickFilter(event);
  };

  // This function will automatically be called when ag grid is ready
  const onGridReady = (params: GridReadyEvent) => {
    setGridApi(params.api);
  };

  return (
    <>
      <header>
        <div className={styles.searchContainer}>
          <TextInput
            type='text'
            inputHTMLElementStyles={styles.searchInput}
            placeHolder='Search'
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              handleGridSearch(event.target.value);
            }}
            startIconName='search'
            startIconVariant='SECONDARY'
          />
        </div>
      </header>

      <div
        className={`ag-theme-alpine ${styles.gridContainer}`}
        style={{ width: '100%', height: '100%' }}>
        <AgGridReact
          rowData={collaborators}
          defaultColDef={defaultColDef}
          domLayout={'autoHeight'}
          pagination={true}
          paginationPageSize={10}
          masterDetail={true}
          onGridReady={onGridReady}
          animateRows={true}
          detailCellRenderer={'detailCellRenderer'}
          detailRowAutoHeight={true}
          frameworkComponents={{
            detailCellRenderer: ExpandedDetails,
          }}>
          <AgGridColumn flex={0.8} hide={true} field='cpName' />
          <AgGridColumn
            flex={0.8}
            cellClass='ag-grid-column-custom-cell'
            cellRendererFramework={ExpandRowCell}
            cellRendererParams={(params: ICellRendererParams) => params}
          />
          <AgGridColumn
            flex={3}
            headerName='Name'
            field='displayName'
            cellRendererFramework={(params: ICellRendererParams) => (
              <div className={styles.projectsColumnDataContainer}>
                <NameCard
                  {...{
                    title: params.data?.displayName,
                    subtitle: params.data?.pocMobile,
                  }}
                />
              </div>
            )}
          />
          {/* <AgGridColumn
            flex={2.5}
            headerName='Registered On'
            cellRendererFramework={(params: ICellRendererParams) => (
              <p className={styles.cpName}>{'Reg Date'}</p>
            )}
          /> */}
          <AgGridColumn
            flex={3}
            headerName='Projects'
            cellRendererFramework={ProjectListCell}
            cellRendererParams={(params: ICellRendererParams) => ({
              projectList: params.data.projects,
            })}
          />
          <AgGridColumn
            flex={3}
            headerName='Executives'
            cellRendererFramework={(params: ICellRendererParams) => (
              <p className={styles.grow}>{params.data.assignedSM}</p>
            )}
          />
          {/* <AgGridColumn
          flex={1}
          headerName='Block'
          headerClass='relata-ag-grid-header-small-font'
          cellRendererFramework={InactiveLeadStatusCell}
          cellRendererParams={(params: ICellRendererParams) => {
            // TODO: API integration
            return {
              projectId: params.data.id,
              disabled: params.data.disabled,
              onRowUpdate: (updatedProject: IProject) =>
                params.node.setData(updatedProject),
            };
          }}></AgGridColumn> */}
          {/* <AgGridColumn
            flex={1}
            headerName='Block'
            headerClass='relata-ag-grid-header-small-font'
            cellRendererFramework={RemoveCollaborator}
            cellRendererParams={(params: ICellRendererParams) => {
              
              return {
                projectId: params.data.id,
                disabled: params.data.disabled,
                onRowUpdate: (updatedProject: IProject) =>
                  params.node.setData(updatedProject),
              };
            }}></AgGridColumn> */}
        </AgGridReact>
      </div>
    </>
  );
};

export default CollaboratorsStatus;
