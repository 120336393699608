import { createSlice } from '@reduxjs/toolkit';

export const projectAdminSlice = createSlice({
  name: 'projectAdminSlice',
  initialState: {
    unitList: [],
    eoiList: [],
  },
  reducers: {
    setUnitList: (state, action) => {
      state.unitList = action.payload;
    },
    setEoiList: (state, action) => {
      state.eoiList = action.payload;
    },
  },
});

export const { setUnitList, setEoiList } = projectAdminSlice.actions;
export const getUnitList = (state) => state.projectAdminSlice.unitList;
export const getEoiList = (state) => state.projectAdminSlice.eoiList;
