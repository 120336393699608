import { IQrCodeResponse } from 'apps/admin/components/pages/ExecutiveDashboard/ExecutiveQrCodeModal';
import { TObjectId } from 'interfaces';
import { adminAPISlice } from 'apps/admin/services';

export const qrCodeAPISlice = adminAPISlice.injectEndpoints({
  endpoints: (build) => ({
    getExecutiveQrCode: build.query({
      query: ({
        executiveId,
        projectId,
      }: {
        executiveId: TObjectId;
        projectId: TObjectId;
      }) => `api/qrCode/smProject/${executiveId}/${projectId}`,
      transformResponse: (response: {
        data: IQrCodeResponse;
        code: String;
        error: Object;
        message: String;
      }) => {
        return response.data;
      },
    }),
  }),
  overrideExisting: false,
});

export const { useGetExecutiveQrCodeQuery } = qrCodeAPISlice;
