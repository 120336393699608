import AdminSidebarItem, {
  IAdminSidebarItemMain,
} from 'apps/admin/components/containers/AdminSidebar/AdminSidebarItem';

interface INavSideBarItemsProps {
  sidebarSteps: IAdminSidebarItemMain[];
  hideSideNavBar: boolean;
}
const NavSideBarItems = (props: INavSideBarItemsProps) => {
  const { sidebarSteps, hideSideNavBar } = props;

  return (
    <div className='sidebar-items-wrap'>
      {sidebarSteps.length &&
        sidebarSteps.map((menuItem) => (
          <div key={menuItem.menuName} className='categoryWrap'>
            <div className='headerWrapper'>
              <p className={`categoryheading ${!hideSideNavBar && 'hide'}`}>
                {menuItem.menuName}
              </p>

              <span className={`divider ${!hideSideNavBar && 'show'} `} />
            </div>
            {menuItem.submenu.map((item) => (
              <AdminSidebarItem
                key={item.labelText as string}
                redirectPath={item.redirectPath}
                labelText={item.labelText}
                desc={item.desc}
                icon={item.icon}
                additionClasses={item.additionClasses}
                hideSideNavBar={hideSideNavBar}
              />
            ))}
          </div>
        ))}
    </div>
  );
};

export default NavSideBarItems;
