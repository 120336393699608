import useToast from 'hooks/useToast';

import { useUpdateProjectMutation } from 'apps/admin/services/projectsAPISlice';
import DropDownInput, {
  DropDownOptionType,
} from 'apps/booking/components/common/inputs/DropDownInput';
import { ERROR } from 'constants/status';
import { IProject } from 'apps/admin/components/pages/SuperAdminDashboard/SuperAdminProjectList';

import styles from '../../styles.module.css';

const optionsByStatus: any = {
  ONBOARDING: [
    { value: 'ONBOARDING', displayText: 'ONBOARDING' },
    { value: 'LIVE', displayText: 'LIVE' }, // TODO: OpenTransactionModal
    { value: 'HOLD', displayText: 'HOLD' },
    { value: 'EARLY_RENEWAL', displayText: 'EARLY RENEWAL' },
  ],
  LIVE: [
    { value: 'LIVE', displayText: 'LIVE' },
    { value: 'MAINTENANCE', displayText: 'MAINTENANCE' },
  ],
  MAINTENANCE: [
    { value: 'MAINTENANCE', displayText: 'MAINTENANCE' },
    { value: 'LIVE', displayText: 'LIVE' },
  ],
  RENEWAL: [
    { value: 'RENEW', displayText: 'RENEW' },
    { value: 'LIVE', displayText: 'LIVE' }, // TODO: OpenTransactionModal
  ],
  EXPIRED: [
    { value: 'EXPIRED', displayText: 'EXPIRED' },
    { value: 'LIVE', displayText: 'LIVE' }, // TODO: OpenTransactionModal
  ],
  HOLD: [
    { value: 'HOLD', displayText: 'HOLD' },
    { value: 'ONBOARDING', displayText: 'ONBOARDING' },
  ],
};

interface IProjectStatusDropDownCell {
  onStatusUpdate: Function;
  projectData: IProject;
}

const ProjectStatusDropDownCell = (props: IProjectStatusDropDownCell) => {
  const { onStatusUpdate, projectData } = props;

  const [addToast] = useToast();

  const [updateProject] = useUpdateProjectMutation();

  const defaultSelectedOption = {
    value: projectData.status,
    displayText: projectData.status,
  };

  const handleStatusDropdownChange = async (
    selectedOption: DropDownOptionType[]
  ) => {
    onStatusUpdate({
      selectedOption: selectedOption,
      projectId: projectData.id,
      projectName: projectData.name,
      developerName: projectData.company.displayName,
    });

    // To handle project Renewal modal
    if (
      selectedOption[0]?.value === 'EARLY_RENEWAL' ||
      selectedOption[0]?.value === 'RENEW'
    ) {
      return;
    }

    try {
      await updateProject({
        status: selectedOption[0]?.value,
        projectId: projectData.id,
      }).unwrap();
    } catch (err) {
      const errMsg = (err as any).data.message;
      addToast({
        type: ERROR,
        primaryMessage: errMsg,
        secondaryMessage: 'Update status failed',
        timeout: 1000,
      });
      return;
    }
  };

  return (
    <div className={styles.statusDropdownContainer}>
      <DropDownInput
        disabled={projectData.disabled}
        defaultSelectedOption={defaultSelectedOption}
        lable=''
        options={optionsByStatus[projectData.status]}
        onValueSelected={handleStatusDropdownChange}
      />
    </div>
  );
};

export default ProjectStatusDropDownCell;
