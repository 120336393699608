import { useSelector } from 'react-redux';

import Icon from 'apps/booking/components/common/Icon/index';
import MoreMenu from 'apps/admin/components/common/MoreMenu';
import CustomerDetails from 'apps/admin/components/pages/forms/SiteVisit/DetailsForm/CustomerDetails';
import { MenuItem } from 'apps/admin/components/common/MoreMenu';
import {
  IQrScanDetails,
  ISiteVisitDetails,
} from 'apps/admin/components/pages/forms/SiteVisit';
import { SiteVisitState } from 'apps/admin/components/pages/forms/SiteVisit';
import { IProject } from 'apps/admin/components/pages/SuperAdminDashboard/SuperAdminProjectList';
import SourceSlection from './SourceSlection';
import AssingedCpInfo, { ICpPreview } from './AssingedCpInfo';
import { TObjectId } from 'interfaces';
import { getCustomerDetails } from 'apps/admin/slices/adminSlice';

import styles from './styles.module.css';

export enum DetailsFormState {
  CUSTOMER,
  SOURCESELECTION,
  QRCODE,
  CPINFO,
}

interface IDetailsForm {
  qrScanDetails: IQrScanDetails;
  setSiteVisitState: (state: SiteVisitState) => void;
  siteVisitDetails: ISiteVisitDetails;
  setSiteVisitDetails: (details: ISiteVisitDetails) => void;
  headerMoreMenuOptions: MenuItem[];
  detailsFormState: DetailsFormState;
  setDetailsFormState: (state: DetailsFormState) => void;
  onProjectSelection: (project?: IProject) => void;
  contendingCpLeadIds: string[];
  assignedCpPreview?: ICpPreview;
  setAssignedCpPreview: (cp?: ICpPreview) => void;
  setContendCpLeadIds: (leadIds: TObjectId[]) => void;
}

const DetailsForm = (props: IDetailsForm) => {
  const {
    qrScanDetails,
    siteVisitDetails,
    headerMoreMenuOptions,
    detailsFormState,
    assignedCpPreview,
    setAssignedCpPreview,
    setSiteVisitDetails,
    setSiteVisitState,
    setDetailsFormState,
    onProjectSelection,
    contendingCpLeadIds,
    setContendCpLeadIds,
  } = props;
  const customerDetails = useSelector(getCustomerDetails);

  const handleGoToSelectProjectPage = () => {
    onProjectSelection();
    setSiteVisitState(SiteVisitState.PROJECT_SELECTION);
  };

  return (
    <div className={styles.formWrap}>
      <header>
        <div className={styles.iconsContainer}>
          <div className={styles.pinDropIconContainer}>
            <div className={styles.locationWrap}>
              <Icon name='pin_drop' propStyles={styles.pinIcon} />
            </div>
          </div>
          <MoreMenu menuItems={headerMoreMenuOptions} />
        </div>

        <h2 className={styles.headerTitle}>
          {detailsFormState === DetailsFormState.CUSTOMER
            ? 'Site Visit Form'
            : 'Add Partner'}
        </h2>
        <p className={styles.descriptiveText}>
          {customerDetails?.customerMobile?.length
            ? 'Enter customer name, mobile number and other details to keep track of customer visit.'
            : 'Enter customer phone number for OTP verification'}
        </p>
      </header>

      <main>
        {detailsFormState === DetailsFormState.CUSTOMER && (
          <CustomerDetails
            setDetailsFormState={setDetailsFormState}
            onGoToSelectProjectPage={handleGoToSelectProjectPage}
            siteVisitDetails={siteVisitDetails}
            setSiteVisitDetails={setSiteVisitDetails}
          />
        )}

        {/* <PropertyDetails
            projectPropertyConfigs={projectPropertyConfigs}
            projectPropertyPriceRanges={projectPropertyPriceRanges}
            setDetailsFormState={setDetailsFormState}
            setSiteVisitState={setSiteVisitState}
            siteVisitDetails={siteVisitDetails}
            setSiteVisitDetails={setSiteVisitDetails}
          /> */}

        {detailsFormState === DetailsFormState.SOURCESELECTION && (
          <SourceSlection
            setDetailsFormState={setDetailsFormState}
            setSiteVisitState={setSiteVisitState}
            siteVisitDetails={siteVisitDetails}
            setSiteVisitDetails={setSiteVisitDetails}
          />
        )}

        {detailsFormState === DetailsFormState.CPINFO && (
          <AssingedCpInfo
            qrScanDetails={qrScanDetails}
            siteVisitDetails={siteVisitDetails}
            setSiteVisitDetails={setSiteVisitDetails}
            setSiteVisitState={setSiteVisitState}
            contendingCpLeadIds={contendingCpLeadIds}
            assignedCpPreview={assignedCpPreview}
            setAssignedCpPreview={setAssignedCpPreview}
            setContendCpLeadIds={setContendCpLeadIds}
          />
        )}
      </main>
    </div>
  );
};

export default DetailsForm;
