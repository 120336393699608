import { useSelector } from 'react-redux';

import {
  getUnitSelectionHeaderNavState,
  getSelectedTower,
  getTotalAvailableUnitCount,
  getSelectedSection,
  UNIT_SELECTION_HEADER_NAV_STATE,
} from 'apps/booking/slices/unitSelectionHeader/unitSelectionHeaderNav';
import { getProject } from 'apps/booking/slices/projectSlice';
import { getLanguageText } from 'constants/multiLanguageTexts';
import { getUserLanguage } from 'apps/booking/slices/userlanguage';
import { PROJECT_TYPE } from 'constants/index';

const ContentHeaderTitle = (props) => {
  const { showFilterPreference } = props;
  const unitSelectionHeaderNavState = useSelector(
    getUnitSelectionHeaderNavState
  );
  const towerName = useSelector(getSelectedTower);
  const selectedSection = useSelector(getSelectedSection);
  const userlanguage = useSelector(getUserLanguage);
  const totalAvailableUnitCount = useSelector(getTotalAvailableUnitCount);
  const project = useSelector(getProject);
  const projectName = project.name;

  let firstHalfTitle = projectName;
  let secondHalfTitle = '';
  if (showFilterPreference) {
    firstHalfTitle = `${getLanguageText(userlanguage, 'Preferences')}`;
    if (userlanguage == 'en')
      secondHalfTitle = ` ${getLanguageText(
        userlanguage,
        'for'
      )} ${getLanguageText(userlanguage, projectName)}`;
    else
      secondHalfTitle = ` ${getLanguageText(
        userlanguage,
        projectName
      )} ${getLanguageText(userlanguage, 'for')}`;
  } else if (
    unitSelectionHeaderNavState === UNIT_SELECTION_HEADER_NAV_STATE.TOWER
  ) {
    firstHalfTitle = `${getLanguageText(userlanguage, selectedSection)},${
      project.type == PROJECT_TYPE.PLOTS
        ? ''
        : ` ${getLanguageText(userlanguage, towerName)}`
    }`;
    secondHalfTitle = ` ${getLanguageText(userlanguage, projectName)}`;
  }
  return (
    <>
      <div className='content-header__title-plate'>
        <h1 className='content-header__title'>
          {getLanguageText(userlanguage, 'You are viewing')}{' '}
          <span className='font-accent-bold text-capitalize'>
            {firstHalfTitle}
          </span>
          {getLanguageText(userlanguage, secondHalfTitle)}
        </h1>
        <p className='content-header__description'>
          {getLanguageText(
            userlanguage,
            totalAvailableUnitCount
              ? 'Select the unit you want to view on the floor map. Click on it to view details & availability'
              : 'There are no units available for the current selection. Please check other selection or try again later'
          )}
        </p>
      </div>
    </>
  );
};

export default ContentHeaderTitle;
