import { useState, useEffect } from 'react';

import styles from './Button.module.css';
import './animationStyles.css';

interface IButton {
  type?: 'submit' | 'reset' | 'button';
  propStyles?: {};
  children: React.ReactNode;
  onClick?: Function;
  link?: string;
  crApp?: boolean;
  //TODO: Get button accents from design team
  accent?:
    | 'primary'
    | 'success'
    | 'danger'
    | 'warn'
    | 'secondary'
    | 'ghost'
    | 'bordered';
  disabled?: boolean;
  loading?: boolean;
}

const Button = (props: IButton) => {
  const {
    propStyles,
    children,
    onClick = () => {},
    type = 'button',
    link = '',
    accent = '',
    crApp = false,
    disabled = false,
    loading = false,
  } = props;

  const [coords, setCoords] = useState({ x: -1, y: -1 });
  const [isRippling, setIsRippling] = useState(false);

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (coords.x !== -1 && coords.y !== -1) {
      setIsRippling(true);
      timeout = setTimeout(() => setIsRippling(false), 300);
    } else setIsRippling(false);
    return () => {
      timeout && clearTimeout(timeout);
    };
  }, [coords]);

  useEffect(() => {
    if (!isRippling) setCoords({ x: -1, y: -1 });
  }, [isRippling]);

  const internalClickHandler = (e: any, type: string) => {
    const rect = e.target.getBoundingClientRect();
    setCoords({ x: e.clientX - rect.left, y: e.clientY - rect.top });

    if (link) {
      window.open(`${link}`, '_blank', 'noopener noreferrer');
    }

    onClick(e);
  };

  return (
    <button
      type={type}
      className={`${styles.button}  ${styles[accent]} ${propStyles} ${
        loading && 'loading-spin-container'
      } ${crApp && styles.cpButton}`}
      disabled={disabled}
      onClick={(event) => internalClickHandler(event, type)}>
      {isRippling && (
        <span
          className='ripple'
          style={{
            left: coords.x,
            top: coords.y,
          }}
        />
      )}
      {children}
      <span className='ic2-fa-spin-blue'></span>
    </button>
  );
};
export default Button;
