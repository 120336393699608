import { ICellRendererParams } from 'ag-grid-community';
import { useUpdateProjectUnitStatusMutation } from 'apps/admin/services/projectsAPISlice';
import { UNIT_STATUSES } from 'constants/status';
import useToast from 'hooks/useToast';
import { useState, useEffect } from 'react';

import styles from './styles.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { getUnitList, setUnitList } from 'apps/admin/slices/projectAdminSlice';
import { IUnit } from 'interfaces';

interface IToggleShowHideCell {
  rowData: ICellRendererParams;
}

const ToggleShowHideCell = (props: IToggleShowHideCell) => {
  const { rowData } = props;

  const dispatch = useDispatch();
  const inventoryList = useSelector(getUnitList);
  const [isChecked, setIsChecked] = useState(false);

  const [addToast] = useToast();

  useEffect(() => {
    if (rowData.data.status === UNIT_STATUSES.AVAILABLE) {
      setIsChecked(true);
    } else setIsChecked(false);
  }, [rowData.data.status]);

  const [updateProjectUnitStatusAPI] = useUpdateProjectUnitStatusMutation();

  const handleToggleButtonChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    try {
      const newCheckedValue = !isChecked;
      setIsChecked(newCheckedValue);
      const updateBookingStatus = await updateProjectUnitStatusAPI({
        unitId: rowData.data.id,
        visibilityStatus: newCheckedValue
          ? UNIT_STATUSES.AVAILABLE
          : UNIT_STATUSES.UNAVAILABLE,
      }).unwrap();
      addToast({
        type: 'SUCCESS',
        primaryMessage: 'Successfully Updated',
      });
      handleUpdateRow(
        rowData.data.id,
        newCheckedValue ? UNIT_STATUSES.AVAILABLE : UNIT_STATUSES.UNAVAILABLE
      );
    } catch (err) {
      const errMsg = (err as any).data.message;
      addToast({
        type: 'ERROR',
        primaryMessage: errMsg,
      });
    }
  };

  const handleUpdateRow = (id: string, newStatus: string) => {
    const newInventoryList = inventoryList.map((inventory: IUnit) =>
      inventory.id === id ? { ...inventory, status: newStatus } : inventory
    );
    dispatch(setUnitList(newInventoryList));
  };
  return (
    <label className={styles.switch}>
      <input
        type='checkbox'
        checked={isChecked}
        onChange={handleToggleButtonChange}
        disabled={
          !(
            rowData.data.status === UNIT_STATUSES.AVAILABLE ||
            rowData.data.status === UNIT_STATUSES.UNAVAILABLE
          )
        }
      />
      <span className={`${styles.slider} ${styles.roundEdges}`}></span>
      <p>{isChecked ? 'Show' : 'Hide'}</p>
    </label>
  );
};

export default ToggleShowHideCell;
