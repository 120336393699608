import { lazy, Suspense, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useRouteMatch,
} from 'react-router-dom';
import { skipToken } from '@reduxjs/toolkit/dist/query';

import {
  removeExecutiveIdLocal,
  removeProjectIdLocal,
  setExecutiveIdLocal,
  setProjectIdLocal,
} from 'apps/cp/components/pages/CpLoginPage/cpLocalStorage';
import { getAppSliceIsFetching } from 'slices/appSlice';
import InvalidQRPage from 'apps/cp/components/pages/ProjectDetailPage/InvalidQRPage';
import { useGetProjectExecutiveIsValidQuery } from 'apps/cp/services/cpProjectAPISlice';
import SpinLoader from 'apps/booking/components/common/SpinLoader';

const CpKycContent = lazy(() => import('apps/cp/containers/CpKycContent'));
const CpDashboardContent = lazy(
  () => import('apps/cp/containers/CpDashboardContent')
);

const CpContentLayer = () => {
  const { path } = useRouteMatch();

  const appSliceIsFetching = useSelector(getAppSliceIsFetching);
  const history = useHistory();

  const urlParams = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop: string) => searchParams.get(prop),
  }) as any;
  const { pi: smCpRegistrationProjectId, ei: smCpRegistrationSmId } = urlParams;

  const { data: validExecutive, isLoading: isValidating } =
    useGetProjectExecutiveIsValidQuery(
      smCpRegistrationProjectId && smCpRegistrationSmId
        ? {
            projectId: smCpRegistrationProjectId,
            executiveId: smCpRegistrationSmId,
          }
        : skipToken
    );

  if (smCpRegistrationProjectId && smCpRegistrationSmId) {
    setProjectIdLocal(smCpRegistrationProjectId);
    setExecutiveIdLocal(smCpRegistrationSmId);
  }

  useEffect(() => {
    if (
      !isValidating &&
      !validExecutive &&
      smCpRegistrationProjectId &&
      smCpRegistrationSmId
    ) {
      history.replace('/cp/invalid-qr-page');
      removeProjectIdLocal();
      removeExecutiveIdLocal();
    }
  }, [
    isValidating,
    validExecutive,
    smCpRegistrationProjectId,
    smCpRegistrationSmId,
  ]);

  return (
    <>
      <div
        className={`loading-overlay ${
          appSliceIsFetching && 'loading-overlay--enabled'
        }`}>
        <span>loading...</span>
      </div>
      <div
        className={`cp-app-main ${appSliceIsFetching && 'app-main--loading'}`}>
        <Suspense
          fallback={
            <div className={`loading-overlay loading-overlay--enabled`}>
              <span>loading...</span>
            </div>
          }>
          <Switch>
            <Route path={`${path}/kyc`} component={CpKycContent}></Route>
            <Route
              path={`${path}/dashboard`}
              component={CpDashboardContent}></Route>
            <Route path={`${path}/invalid-qr-page`} component={InvalidQRPage} />

            {isValidating ? <SpinLoader /> : <Redirect to='/cp/dashboard' />}
          </Switch>
        </Suspense>
      </div>
    </>
  );
};

export default CpContentLayer;
