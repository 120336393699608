import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import BookingAmountCard from '../common/cards/BookingAmountCard';
import BlockedUnitCountDownTimer from '../common/cards/BlockedUnitCountDownTimer';

import {
  getUnitCart,
  getCostSheet,
  getIsFetchingUnitCart,
  setSelectedSchemeId,
  getSelectedUnit,
  setSelectedScheme,
  getSelectedScheme,
} from '../../slices/selectedUnit';

import { DropDown } from '../DropDown';
import { BOOKED } from '../../../../constants/status';
import { getProject } from 'apps/booking/slices/projectSlice';
import { isEmpty } from 'utils/utils';
import { PROJECT_TYPE } from 'constants/index';

const getPaymentPlanDropDown = (schemeDetails = [], id) => {
  const paymentPlanDropDown = {
    cname: 'dropdown--secondary',
    defaultValue: id ? id : schemeDetails[0]?._id,
    options: schemeDetails.map((scheme) => {
      return { value: scheme._id, text: scheme.name };
    }),
  };
  return paymentPlanDropDown;
};

const PaymentPlanContentHeader = () => {
  const dispatch = useDispatch();
  const unitCart = useSelector(getUnitCart);
  const isFetchingUnitCart = useSelector(getIsFetchingUnitCart);
  let selectedScheme = useSelector(getSelectedScheme);
  const selectedUnit = unitCart.unit;
  const project = useSelector(getProject);
  const schemes = selectedUnit.schemes;

  useEffect(() => {
    if (unitCart?.selectedSchemeId) {
      handleSchemeSelect(unitCart?.selectedSchemeId);
    }
  }, [unitCart]);

  if (isEmpty(selectedScheme)) {
    selectedScheme = unitCart.unit.schemes[0];
    dispatch(setSelectedScheme(unitCart.unit.schemes[0]));
  }

  const paymentPlanDropDown = getPaymentPlanDropDown(
    schemes,
    selectedScheme._id
  );

  const handleSchemeSelect = (schemeId) => {
    dispatch(
      setSelectedScheme(schemes.find((scheme) => scheme._id == schemeId))
    );
  };

  if (isFetchingUnitCart) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <header className='content-header content-header--payment-plan'>
        <div className='content-header__title-plate'>
          <h1 className='content-header__title'>
            <span>You have selected the</span>{' '}
            {/* <span className="font-accent-bold"> */}
            <div className='content-header__drop-down'>
              <DropDown
                cname={paymentPlanDropDown.cname}
                defaultValue={paymentPlanDropDown.defaultValue}
                options={paymentPlanDropDown.options}
                handleDropDownSelect={(value) => handleSchemeSelect(value)}
              />
            </div>
            {/* </span> */}
          </h1>
          <p className='content-header__description'>
            Select amongst the payment schemes available to take complete
            benefit of your {project.name} dream{' '}
            {project.type == PROJECT_TYPE.PLOTS ? 'plot' : 'home'}.
          </p>
        </div>

        <div className='content-header__card-container'>
          <BlockedUnitCountDownTimer />
          <BookingAmountCard />
        </div>
      </header>
    </>
  );
};

export default PaymentPlanContentHeader;
