import { Redirect, Switch, useRouteMatch } from 'react-router-dom';
import BookedUnitCartSummary from '../components/dashboard/BookedUnitCartSummary';
import Dashbaord from '../components/dashboard/Dashbaord';
import DashboardContent from '../containers/dashboard/DashboardContent';
import ConditionalRoutes from './ConditionalRoutes';

const DashboardRoutes = () => {
  let { path } = useRouteMatch();

  return (
    <Switch>
      <ConditionalRoutes
        path={`${path}/booking-summary`}
        component={DashboardContent}
        headerComponent=''
        contentBodyComponent={BookedUnitCartSummary}
        requiredAuth={true}
        requiredUnit={false}
      />
      <ConditionalRoutes
        exact
        path={`${path}/`}
        component={DashboardContent}
        headerComponent=''
        contentBodyComponent={Dashbaord}
        requiredAuth={true}
        requiredUnit={false}
      />

      {/* Unmatched url redirect to dashbaord */}
      <Redirect to={`${path}/`} />
    </Switch>
  );
};

export default DashboardRoutes;
