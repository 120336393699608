import { useSelector } from 'react-redux';

import { ITab } from 'apps/cp/components/common/Tabs';
import { IProject } from 'apps/admin/components/pages/SuperAdminDashboard/SuperAdminProjectList';
import PartnerSitevisits from './PartnerSiteVisits';
import DesktopTabs from 'apps/admin/components/common/DesktopTabs';
import { getAdminUser } from 'apps/admin/slices/adminSlice';
import { UserRole } from 'constants/userRoles';
import AnalyticsWrapper, {
  ISummaryData,
} from 'apps/admin/components/common/AnalyticsWrapper';
import { DropDownOptionType } from 'apps/booking/components/common/inputs/DropDownInput';
import { SCHEDULED_SITE_VISIT_STATUS } from 'apps/cp/components/pages/CrSiteVisitPage';
import { useGetProjectPartnerSiteVisitsCountQuery } from 'apps/admin/services/projectLeadsAPI';

export interface IProjectPartnerSiteVisitsProps {
  project: IProject;
  onProjectUpdate: (updateProject: IProject) => void;
}

export const searchFieldOptions: DropDownOptionType[] = [
  {
    value: 'cpLeadName',
    displayText: 'Lead Name',
  },
  {
    value: 'partnerName',
    displayText: 'Partner Name',
  },
  { value: 'phoneNumber', displayText: 'Phone Number' },
  { value: 'unit-config', displayText: 'Unit Config' },
  { value: 'smName', displayText: 'SM Name' },
];

export const pageCount = 10;

export const getSiteVisitStatus = (siteVisitstatus: string) => {
  let status = '';
  switch (siteVisitstatus) {
    case SCHEDULED_SITE_VISIT_STATUS.CANCEL:
      status = 'Expired';
      break;
    case SCHEDULED_SITE_VISIT_STATUS.MISSED:
      status = 'Expired';
      break;
    case SCHEDULED_SITE_VISIT_STATUS.SCHEDULED_SITE_VISIT:
      status = 'Scheduled';
      break;
    case SCHEDULED_SITE_VISIT_STATUS.RESCHEDULED_SITE_VISIT:
      status = 'Scheduled';
      break;
    case SCHEDULED_SITE_VISIT_STATUS.SITE_VISIT_DONE:
      status = 'Done';
      break;
  }
  return status;
};

export enum PARTNER_SITEVISIT_STATUS {
  SCHEDULED_SITE_VISIT = 'SCHEDULED_SITE_VISIT',
  TODAYS_SITE_VISITS = 'TODAYS_SITE_VISITS',
  UPCOMING_SITE_VISITS = 'UPCOMING_SITE_VISITS',
  COMPLETED_SITE_VISITS = 'COMPLETED_SITE_VISITS',
  MISSED_SITE_VISITS = 'MISSED_SITE_VISITS',
  ALL_SITE_VISITS = 'ALL_SITE_VISITS',
}

const ProjectPartnerSiteVisits = (props: IProjectPartnerSiteVisitsProps) => {
  const { project } = props;

  const user = useSelector(getAdminUser);
  const { data: allSiteVisitsCount = 0, isLoading: allSiteVisitLoaded } =
    useGetProjectPartnerSiteVisitsCountQuery({
      projectId: project.id,
      status: PARTNER_SITEVISIT_STATUS.ALL_SITE_VISITS,
      executiveId:
        user?.role === UserRole.EXECUTIVE || user?.role === UserRole.GRE
          ? user.id
          : '',
    });

  const {
    data: completedSiteVisitCount = 0,
    isLoading: completedSiteVisitLoaded,
  } = useGetProjectPartnerSiteVisitsCountQuery({
    projectId: project.id,
    status: PARTNER_SITEVISIT_STATUS.COMPLETED_SITE_VISITS,
    executiveId:
      user?.role === UserRole.EXECUTIVE || user?.role === UserRole.GRE
        ? user.id
        : '',
  });

  const { data: todaySiteVisitCount = 0, isLoading: todaySiteVisitLoaded } =
    useGetProjectPartnerSiteVisitsCountQuery({
      projectId: project.id,
      status: PARTNER_SITEVISIT_STATUS.TODAYS_SITE_VISITS,
      executiveId:
        user?.role === UserRole.EXECUTIVE || user?.role === UserRole.GRE
          ? user.id
          : '',
    });

  const {
    data: upcomingSiteVisitCount = 0,
    isLoading: upcomingSiteVisitLoaded,
  } = useGetProjectPartnerSiteVisitsCountQuery({
    projectId: project.id,
    status: PARTNER_SITEVISIT_STATUS.UPCOMING_SITE_VISITS,
    executiveId:
      user?.role === UserRole.EXECUTIVE || user?.role === UserRole.GRE
        ? user.id
        : '',
  });

  const { data: missedSiteVisitCount = 0, isLoading: missedSiteVisitLoaded } =
    useGetProjectPartnerSiteVisitsCountQuery({
      projectId: project.id,
      status: PARTNER_SITEVISIT_STATUS.MISSED_SITE_VISITS,
      executiveId:
        user?.role === UserRole.EXECUTIVE || user?.role === UserRole.GRE
          ? user.id
          : '',
    });

  const tabs: ITab[] = [
    {
      id: 'allSiteVisits',
      name: 'All Site-visits',
      content: (
        <PartnerSitevisits
          projectId={project.id}
          siteVisitType={PARTNER_SITEVISIT_STATUS.ALL_SITE_VISITS}
          siteVisitCount={allSiteVisitsCount}
          isCountLoaded={allSiteVisitLoaded}
        />
      ),
      disabled: false,
    },
    {
      id: 'todaySiteVisits',
      name: 'Today’s Upcoming',
      content: (
        <PartnerSitevisits
          projectId={project.id}
          siteVisitType={PARTNER_SITEVISIT_STATUS.TODAYS_SITE_VISITS}
          siteVisitCount={allSiteVisitsCount}
          isCountLoaded={allSiteVisitLoaded}
        />
      ),
      disabled: false,
    },
    {
      id: 'upcomingSiteVisits',
      name: 'Scheduled Site-visits',
      content: (
        <PartnerSitevisits
          projectId={project.id}
          siteVisitType={PARTNER_SITEVISIT_STATUS.UPCOMING_SITE_VISITS}
          siteVisitCount={upcomingSiteVisitCount}
          isCountLoaded={upcomingSiteVisitLoaded}
        />
      ),
      disabled: false,
    },
    {
      id: 'completedSiteVisits',
      name: 'Completed Site-visits',
      content: (
        <PartnerSitevisits
          projectId={project.id}
          siteVisitType={PARTNER_SITEVISIT_STATUS.COMPLETED_SITE_VISITS}
          siteVisitCount={completedSiteVisitCount}
          isCountLoaded={completedSiteVisitLoaded}
        />
      ),
      disabled: false,
    },
    {
      id: 'missedSiteVisits',
      name: 'Missed Site-visits',
      content: (
        <PartnerSitevisits
          projectId={project.id}
          siteVisitType={PARTNER_SITEVISIT_STATUS.MISSED_SITE_VISITS}
          siteVisitCount={missedSiteVisitCount}
          isCountLoaded={missedSiteVisitLoaded}
        />
      ),
      disabled: false,
    },
  ];

  const siteVisitsCountSummay: ISummaryData[] = [
    {
      details: {
        mainData: `${allSiteVisitsCount}`,
        changeData: '',
        changeDataClass: '',
        changeDesc: '',
        loaded: allSiteVisitLoaded,
      },
      titleIcon: 'group',
      title: 'All',
    },
    {
      details: {
        mainData: `${todaySiteVisitCount}`,
        changeData: '',
        changeDataClass: '',
        changeDesc: '',
        loaded: todaySiteVisitLoaded,
      },
      titleIcon: 'group',
      title: 'Today’s',
    },
    {
      details: {
        mainData: `${upcomingSiteVisitCount}`,
        changeData: '',
        changeDataClass: '',
        changeDesc: '',
        loaded: upcomingSiteVisitLoaded,
      },
      titleIcon: 'group',
      title: 'Upcoming',
    },

    {
      details: {
        mainData: `${completedSiteVisitCount}`,
        changeData: '',
        changeDataClass: '',
        changeDesc: '',
        loaded: completedSiteVisitLoaded,
      },
      titleIcon: 'group',
      title: 'Completed',
    },
    {
      details: {
        mainData: `${missedSiteVisitCount}`,
        changeData: '',
        changeDataClass: '',
        changeDesc: '',
        loaded: missedSiteVisitLoaded,
      },
      titleIcon: 'group',
      title: 'Missed',
    },
  ];
  return (
    <>
      <AnalyticsWrapper summaryData={siteVisitsCountSummay} />
      <DesktopTabs tabs={tabs} />
    </>
  );
};

export default ProjectPartnerSiteVisits;
