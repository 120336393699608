import Modal from 'apps/booking/components/common/modal/Modal';
import Icon from 'apps/booking/components/common/Icon';

import styles from './styles.module.css';

interface IBookedUnitDetailsModal {
  unitNumber: string;
  unitConfiguration: string;
  onModalClose: () => void;
}

const BookedUnitDetailsModal = (props: IBookedUnitDetailsModal) => {
  const { unitConfiguration, unitNumber, onModalClose } = props;

  const unitDetails = [
    { label: 'Unit Number', value: unitNumber },
    { label: 'Unit Configuration', value: unitConfiguration },
  ];

  return (
    <Modal
      onOutsideClick={onModalClose}
      additionalClasses={styles.unitDetailsModal}>
      <header className={styles.modalHeader}>
        <div>
          <h2 className={styles.modalHeading}>Unit-details of booked Leads</h2>
        </div>
        <Icon name='close' onClick={onModalClose} />
      </header>

      <main className={styles.modalBody}>
        <div className={styles.modalBodyLeftContainer}>
          {unitDetails.map((unit) => {
            return (
              <>
                <p className={styles.unitDetailsLabel}>{unit.label}</p>
                <p className={styles.unitDetailsValue}>{unit.value}</p>
              </>
            );
          })}
        </div>
      </main>
    </Modal>
  );
};

export default BookedUnitDetailsModal;
