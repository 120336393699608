import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { IUnit } from 'interfaces';
import { baseUrl } from '../constants/urls';

export const unitsAPISlice = createApi({
  reducerPath: 'unitsAPI',
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders(headers) {
      return headers;
    },
  }),
  endpoints: (build) => ({
    // Units
    getUnitDetails: build.query({
      query: ({ id, towerName, blockName, number }) => {
        const idQuery = id ? `id=${id}` : '';
        return `unit_details?${idQuery}&blockName=${blockName}&towerName=${towerName}&number=${number}`;
      },
      keepUnusedDataFor: 120,
    }),

    getUnitList1: build.query({
      query: ({ thirdPartyProjectId }: { thirdPartyProjectId: string }) => {
        return `api/units/get-all/${thirdPartyProjectId}`;
      },
      transformResponse: (response: {
        data: IUnit[];
        code: String;
        error: Object;
        message: String;
      }) => {
        return response.data;
      },
      keepUnusedDataFor: 120,
    }),
    getUnitListByProjectId: build.query({
      query: ({ projectId }: { projectId: string }) => {
        return `api/units/get-all/project/${projectId}`;
      },
      transformResponse: (response: {
        data: IUnit[];
        code: String;
        error: Object;
        message: String;
      }) => {
        return response.data;
      },
      keepUnusedDataFor: 120,
    }),

    blockUnit: build.mutation({
      query: (unitCartId) => {
        return {
          url: `block_unit/${unitCartId}`,
          method: 'PUT',
        };
      },
    }),

    syncInventory: build.mutation({
      query: (data) => ({
        url: 'api/units/sync-invenotry',
        method: 'POST',
        body: data,
      }),
      transformResponse: (response: {
        code: String;
        error: Object;
        message: String;
      }) => {
        return response;
      },
    }),

    // PDF
    costSheetPdf: build.mutation({
      query: ({ user_id, unit_id }) => {
        return {
          url: `costsheet_pdf?${
            user_id && `user_id=${user_id}`
          }&unit_id=${unit_id}`,
          method: 'POST',
        };
        // TODO: Cache forever
      },
    }),

    paymentPlanPdf: build.mutation({
      query: (unitCartId) => {
        return {
          url: `payment_plan_pdf?unit_cart_id=${unitCartId}`,
          method: 'POST',
        };
        // TODO: Cache forever
      },
    }),

    agreementPdf: build.mutation({
      query: (unitCartId) => {
        return {
          url: `agreement_pdf?unit_cart_id=${unitCartId}`,
          method: 'POST',
        };
        // TODO: Cache forever
      },
    }),
  }),
});

export const {
  // Unit
  useGetUnitDetailsQuery,
  useGetUnitList1Query,
  useLazyGetUnitList1Query,
  useLazyGetUnitListByProjectIdQuery,
  useBlockUnitMutation,
  useSyncInventoryMutation,

  // PDF
  useCostSheetPdfMutation,
  usePaymentPlanPdfMutation,
  useAgreementPdfMutation,
} = unitsAPISlice;
