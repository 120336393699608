import { useEffect, useState } from 'react';
import { useGetProjectLeadUnitsByProjectIdQuery } from 'apps/admin/services/projectLeadsAPI';
import DesktopTabs from 'apps/admin/components/common/DesktopTabs';
import { ITab } from 'apps/cp/components/common/Tabs';
import BookingList from 'apps/admin/components/pages/ProjectBookings/BookingList';
import { ILeadUnitCart } from 'apps/cp/interfaces';
import { UNIT_STATUSES } from 'constants/status';

interface IProjectLeadProps {
  projectId: string;
}

const ProjectBooking = (props: IProjectLeadProps) => {
  const { projectId } = props;
  const [blockedUnitcarts, setBlockedUnitcarts] = useState<ILeadUnitCart[]>([]);
  const [bookedUnitcarts, setBookedUnitcarts] = useState<ILeadUnitCart[]>([]);

  const {
    data: leadList = [],
    isLoading: isLeadListLoading,
    refetch,
  } = useGetProjectLeadUnitsByProjectIdQuery(projectId);

  useEffect(() => {
    let unitCarts: ILeadUnitCart[] = [];
    leadList.forEach((lead) => {
      unitCarts = [...unitCarts, ...lead.unitCarts];
    });
    unitCarts = unitCarts.sort((a, b) => (a.updatedAt < b.updatedAt ? 1 : -1));
    setBlockedUnitcarts(
      unitCarts.filter(
        (unitCart) => unitCart.unit.status == UNIT_STATUSES.BLOCKED
      )
    );
    setBookedUnitcarts(
      unitCarts.filter(
        (unitCart) => unitCart.unit.status == UNIT_STATUSES.BOOKED
      )
    );
  }, [leadList]);

  const updateLeadList = () => {
    refetch();
  };

  if (isLeadListLoading) {
    return <div>Loading...</div>;
  }

  const tabs: ITab[] = [
    {
      id: 'blocked',
      name: 'Blocked',
      content: (
        <BookingList
          projectId={projectId}
          unitCarts={blockedUnitcarts}
          tabName={UNIT_STATUSES.BLOCKED}
          updateLeadList={updateLeadList}
        />
      ),
      disabled: false,
    },
    {
      id: 'booking',
      name: 'Booked',
      content: (
        <BookingList
          projectId={projectId}
          unitCarts={bookedUnitcarts}
          tabName={UNIT_STATUSES.BOOKED}
        />
      ),
      disabled: false,
    },
  ];

  return <DesktopTabs tabs={tabs} />;
};

export default ProjectBooking;
