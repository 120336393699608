import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { IProject } from 'apps/admin/components/pages/SuperAdminDashboard/SuperAdminProjectList';
import Icon from 'apps/booking/components/common/Icon';
import DropDownInput, {
  DropDownOptionType,
} from 'apps/booking/components/common/inputs/DropDownInput';
import TextInput from 'apps/booking/components/common/inputs/TextInput';
import Modal from 'apps/booking/components/common/modal/Modal';
import Button from 'apps/booking/components/common/buttons/Button';
import { useAddProjectEoiMutation } from 'apps/admin/services/projectsAPISlice';
import {
  useAddAdminUserMutation,
  useGetUserByMobileMutation,
} from 'apps/admin/services/adminUserAPISlice';
import { EoiStatus } from 'constants/status';
import { IAddEoi, IEoiDetails, IUser } from 'apps/admin/interfaces';
import useToast from 'hooks/useToast';
import { useGetUsersByProjectIdMutation } from 'apps/admin/services/userRolesAPISlice';
import { UserRole } from 'constants/userRoles';
import { randomId } from 'utils/utils';

import styles from './styles.module.css';
import { getEoiList, setEoiList } from 'apps/admin/slices/projectAdminSlice';

interface IAddProjectEoiModal {
  project: IProject;
  onModalClose: Function;
}

// Validation
const mobileSchema = yup.object().shape({
  mobile: yup
    .string()
    .matches(/^[0-9]*$/)
    .length(10)
    .required(),
});

const userSchema = yup.object().shape({
  mobile: yup
    .string()
    .matches(/^[0-9]*$/)
    .length(10)
    .required(),
  email: yup.string().email().required(),
  name: yup.string().required(),
});

const eoiSchema = yup.object().shape({
  email: yup.string().email().required(),
  name: yup.string().required(),
  mobile: yup
    .string()
    .matches(/^[0-9]*$/)
    .length(10)
    .required(),
  unitTag: yup.string().required(),
  type: yup.string().required(),
  executive: yup.string().required(),
  amount: yup.number().required(),
});

enum FormState {
  MOBILE_EDIT,
  EOI_LEAD_EDIT,
  EOI_EDIT,
}

const AddProjectEoiModal = (props: IAddProjectEoiModal) => {
  const { project, onModalClose } = props;

  const [executive, setExecutive] = useState<IUser[]>([]);
  const [formState, setFormState] = useState(FormState.MOBILE_EDIT);
  const [eoiLeadDetails, setEoiLeadDetails] = useState({
    name: '',
    email: '',
    id: '',
  });

  const dispatch = useDispatch();
  const eoiList = useSelector(getEoiList);

  const [addAdminUserAPI] = useAddAdminUserMutation();
  const [addProjectEoiAPI] = useAddProjectEoiMutation();
  const [getUserByMobileAPI] = useGetUserByMobileMutation();
  const [getUsersByProjectIdAPI] = useGetUsersByProjectIdMutation();
  const [addToast] = useToast();

  const {
    register,
    unregister,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(
      formState == FormState.MOBILE_EDIT
        ? mobileSchema
        : formState == FormState.EOI_LEAD_EDIT
        ? userSchema
        : eoiSchema
    ),
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });

  useEffect(() => {
    getExecutiveList();
  }, [project]);

  const unitOptions: DropDownOptionType[] =
    project.unitTagMapping.map((unit) => {
      return {
        value: unit.value as string,
        displayText: unit.name,
      };
    }) || [];

  const eoiTypeOptions: DropDownOptionType[] =
    project.eoi.types.map((type, index) => {
      return {
        value: type,
        displayText: type,
      };
    }) || [];

  const executiveOptions: DropDownOptionType[] =
    executive.map((user: IUser) => {
      return {
        value: user.id,
        displayText: user.name,
      };
    }) || [];

  const handleGetUserDetailsAndUpdateFormState = async (data: {
    mobile: string;
  }) => {
    try {
      const response = await getUserByMobileAPI({
        mobile: data.mobile,
      }).unwrap();

      setEoiLeadDetails({
        name: response.data.name,
        email: response.data.email,
        id: response.data.id,
      });
      setFormState(FormState.EOI_EDIT);
    } catch (err) {
      // EOI Lead doesn't exists, create EOI lead entry in backend
      setFormState(FormState.EOI_LEAD_EDIT);
    }
  };

  const handleCreateEOILead = async (leadData: {
    mobile: string;
    email: string;
    name: string;
  }) => {
    try {
      const response = await addAdminUserAPI({
        name: leadData.name,
        email: leadData.email,
        mobile: leadData.mobile,
        role: UserRole.LEAD,
        password: randomId(),
      }).unwrap();

      setEoiLeadDetails({
        name: response.data.name,
        email: response.data.email,
        id: response.data.id,
      });
      setFormState(FormState.EOI_EDIT);
    } catch (err) {
      const errMsg = (err as any).data.message;
      addToast({
        type: 'ERROR',
        primaryMessage: errMsg,
        secondaryMessage: 'Failed to add new Eoi User',
        timeout: 1000,
      });
    }
  };

  const handleCreateEoi = async (eoiData: IAddEoi) => {
    try {
      const response = await addProjectEoiAPI({
        project: project.id,
        type: eoiData.type,
        unitTag: eoiData.unitTag,
        lead: eoiLeadDetails.id,
        executive: eoiData.executive,
        status: EoiStatus.ACTIVE_BOOKING_DISABLED,
        amount: eoiData.amount,
      }).unwrap();

      addToast({
        type: 'SUCCESS',
        primaryMessage: 'EOI information successfully updated!',
        timeout: 1000,
      });
      handleUpdateEoiList(response.data as IEoiDetails);
      onModalClose(false);
    } catch (err) {
      const errMsg = (err as any).data.message;
      addToast({
        type: 'ERROR',
        primaryMessage: errMsg,
        secondaryMessage: 'Failed to add new Eoi',
        timeout: 1000,
      });
    }
  };

  const handleUpdateEoiList = (eoi: IEoiDetails) => {
    let newEoiList = [...eoiList, eoi];
    newEoiList.sort(
      (leadA: IEoiDetails, leadB: IEoiDetails) =>
        +new Date(leadB.createdAt) - +new Date(leadA.createdAt)
    );
    dispatch(setEoiList(newEoiList));
  };

  const getExecutiveList = async () => {
    try {
      const response = await getUsersByProjectIdAPI({
        projectId: project.id,
      }).unwrap();
      setExecutive(response.data);
    } catch (err) {}
  };

  return (
    <Modal
      onOutsideClick={() => onModalClose(false)}
      additionalClasses={styles.modalAdditionalClasses}>
      <header className={styles.modalHeader}>
        <div className={styles.modalHeaderLeftContent}>
          <h1>Add User for {project.name}</h1>
          <p>
            Fill in the following info to add a new user associated to the
            selected project. All users of a developer have access to all
            projects of the developer by default.
          </p>
        </div>
        <Icon
          name='close'
          propStyles={styles.modalCloseIcon}
          onClick={() => onModalClose(false)}
        />
      </header>
      <main>
        <div className={styles.projectIdInputContainer}>
          <div className={styles.projectIdInput}>
            <TextInput
              label='Mobile Number'
              placeHolder='Add Mobile'
              register={register}
              setValue={setValue}
              name='mobile'
              type='number'
              unregister={unregister}
              maxLength={10}
              readOnly={formState != FormState.MOBILE_EDIT}
              validate={(value: string) => value.length > 0}
              errorMessage={errors.mobile && 'Mobile Number is required'}
            />
          </div>
        </div>
        {[FormState.EOI_LEAD_EDIT, FormState.EOI_EDIT].includes(formState) && (
          <>
            <div className={styles.projectIdInputContainer}>
              <div className={styles.projectIdInput}>
                <TextInput
                  label='Full Name'
                  placeHolder='Add Name'
                  register={register}
                  setValue={setValue}
                  defaultValue={eoiLeadDetails.name}
                  name='name'
                  readOnly={formState != FormState.EOI_LEAD_EDIT}
                  unregister={unregister}
                  errorMessage={errors.name && 'Name is required'}
                />
              </div>
              <div className={styles.projectIdInput}>
                <TextInput
                  label='Email'
                  placeHolder='Add Email of the User'
                  register={register}
                  setValue={setValue}
                  defaultValue={eoiLeadDetails.email}
                  name='email'
                  type='email'
                  readOnly={formState != FormState.EOI_LEAD_EDIT}
                  unregister={unregister}
                  errorMessage={errors.email && 'Please enter valid email'}
                />
              </div>
            </div>
          </>
        )}
        {formState == FormState.EOI_EDIT && (
          <>
            <div className={styles.projectIdInputContainer}>
              <div className={styles.projectIdInput}>
                <DropDownInput
                  options={unitOptions}
                  lable='Select Unit Category'
                  defaultSelectedOption={{
                    value: '',
                    displayText: 'Select Unit Category',
                  }}
                  register={register}
                  disabled={unitOptions.length === 0}
                  setValue={setValue}
                  unregister={unregister}
                  name='unitTag'
                  errorMessage={errors.unitTag && `Please Select Unit Category`}
                />
              </div>
              <div className={styles.projectIdInput}>
                <DropDownInput
                  options={eoiTypeOptions}
                  lable='Select Eoi Type'
                  defaultSelectedOption={{
                    value: '',
                    displayText: 'Select EOI Type',
                  }}
                  register={register}
                  setValue={setValue}
                  unregister={unregister}
                  disabled={eoiTypeOptions.length === 0}
                  name='type'
                  errorMessage={errors.type && `Please Select Eoi Type`}
                />
              </div>
            </div>
            <div className={styles.projectIdInputContainer}>
              <div className={styles.projectIdInput}>
                <DropDownInput
                  options={executiveOptions}
                  lable='Select Closing Executive'
                  register={register}
                  setValue={setValue}
                  defaultSelectedOption={{
                    value: 'CLOSING_EXECUTIVE',
                    displayText: 'Select Closing Executive',
                  }}
                  disabled={executiveOptions.length === 0}
                  unregister={unregister}
                  name='executive'
                  errorMessage={
                    errors.executive ? `Please Select Closing Executive` : ''
                  }
                />
              </div>
              <div className={styles.addNameInput}>
                <TextInput
                  label='EOI Amount *'
                  placeHolder='EOI amount'
                  register={register}
                  setValue={setValue}
                  unregister={unregister}
                  name='amount'
                  startIconText='INR'
                  errorMessage={errors.amount && 'EOI amount is required'}
                />
              </div>
            </div>
          </>
        )}
      </main>
      <footer className={styles.modalFooter}>
        <div className={styles.projectIdInput}>
          <Button
            type='submit'
            onClick={
              formState == FormState.MOBILE_EDIT
                ? handleSubmit(handleGetUserDetailsAndUpdateFormState)
                : formState == FormState.EOI_LEAD_EDIT
                ? handleSubmit(handleCreateEOILead)
                : handleSubmit(handleCreateEoi)
            }
            propStyles={`${styles.verifyNowBtn}`}>
            {formState == FormState.EOI_EDIT ? 'Add EOI' : 'Next'}
          </Button>
        </div>
      </footer>
    </Modal>
  );
};

export default AddProjectEoiModal;
