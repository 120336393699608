import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import useToast from '../../../../hooks/useToast';

import { useDispatch } from 'react-redux';

import { getUser } from '../../slices/auth';
import { useSelector } from 'react-redux';
import UnitSummaryCard from '../UnitSummaryCard';
import { getUnitCart } from '../../slices/selectedUnit';
import { URLS } from '../../../../constants/urls';
import {
  getAppSliceIsFetching,
  setAppSliceIsFetching,
} from '../../../../slices/appSlice';
import { useLazyGetUnitCartQuery } from '../../../../api/unitCart';
import {
  BLOCKED,
  ERROR,
  SUCCESS,
  UNIT_STATUSES_INDEX,
  UNIT_STATUSES,
} from 'constants/status';
import { setBookingRoutesCurrentPage } from 'apps/booking/routers/BookingRoutes/slice';
import {
  useCreatePaymentOrderMutation,
  useLazyGetNextPayableAmountForUnitCartQuery,
  useSuccessfulPaymentOrderMutation,
} from 'apps/booking/services/paymentsAPISlice';
import { getProject } from 'apps/booking/slices/projectSlice';
import {
  useBlockUnitByUnitCartIdMutation,
  useBookUnitByUnitCartIdMutation,
  useReserveUnitByUnitCartIdMutation,
} from 'apps/booking/services/unitsAPISlice';
import { PAYMENT_MODES } from 'apps/booking/constants';
import { isEmpty } from 'utils/utils';
import { ONE_LAKH } from 'constants/index';
import { useOfflinePaymentRequestMutation } from 'apps/admin/services/adminUserAPISlice';

const PaymentStatus = Object.freeze({
  Idle: 1,
  Intitiate: 2,
  Processing: 3,
  Successful: 4,
  Failed: 5,
});

const Payment = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const project = useSelector(getProject);
  const unitCart = useSelector(getUnitCart);
  const [paymentStatus, setPaymentStatus] = useState(PaymentStatus.Intitiate);
  const [payableAmount, setPayableAmount] = useState(0);
  const user = useSelector(getUser);
  const appSliceIsFetching = useSelector(getAppSliceIsFetching);
  const [addToast] = useToast();

  const [getNextPayableAmountForUnitCartAPI, { data: payable = {} }] =
    useLazyGetNextPayableAmountForUnitCartQuery(unitCart.id);
  const [successfulPaymentOrderAPI] = useSuccessfulPaymentOrderMutation();
  const [createPaymentOrderAPI] = useCreatePaymentOrderMutation();
  const [reserveUnitByUnitCartIdAPI] = useReserveUnitByUnitCartIdMutation();
  const [blockUnitByUnitCartIdAPI] = useBlockUnitByUnitCartIdMutation();
  const [bookUnitByUnitCartIdAPI] = useBookUnitByUnitCartIdMutation();
  const [getUnitCartAPI] = useLazyGetUnitCartQuery();
  const [offlinePaymentRequestAPI] = useOfflinePaymentRequestMutation();

  useEffect(() => {
    getNextPayableAmountForUnitCartAPI(unitCart.id);
  }, [unitCart]);

  useEffect(() => {
    if (!isEmpty(payable)) {
      setPayableAmount(payable.amount);
    }
  }, [payable]);

  const loadRazorpayScript = (src) => {
    const razorpayScriptId = 'razorpay-checkout';
    const doesScriptAlreadyAdded = document.querySelector(
      `#${razorpayScriptId}`
    );

    return new Promise((resolve) => {
      if (doesScriptAlreadyAdded) {
        resolve(true);
        return;
      }
      const script = document.createElement('script');
      script.id = 'razorpay-checkout';
      script.src = 'https://checkout.razorpay.com/v1/checkout.js';
      script.async = true;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };

  const payWithRazorpay = async () => {
    if (
      paymentStatus === PaymentStatus.Processing ||
      paymentStatus === PaymentStatus.Successful
    ) {
      // Already processing
      return;
    }

    if (payableAmount > 5 * ONE_LAKH) {
      addToast({
        type: 'INFO',
        primaryMessage: 'Payment is greater than 5 Lacs',
        secondaryMessage: 'There would be multiple payments',
        timeout: 5000,
      });
    }

    if (!project.razorpay.isEnabled) {
      addToast({
        type: 'WARN',
        primaryMessage: 'Online payment is unsupported',
        secondaryMessage: 'Please try offline',
      });
      dispatch(setAppSliceIsFetching(false));
      setPaymentStatus(PaymentStatus.Failed);
      return;
    }

    const res = await loadRazorpayScript();

    if (!res) {
      alert('Razorpay SDK failed to load. Are you online?');
      return;
    }

    setPaymentStatus(PaymentStatus.Processing);
    dispatch(setAppSliceIsFetching(true));

    let { data: resPaymentOrder, error: errPaymentOrder } =
      await createPaymentOrderAPI({
        unitCartId: unitCart.id,
        amount: Math.min(payableAmount, 5 * ONE_LAKH),
      });
    resPaymentOrder = resPaymentOrder.data;

    if (errPaymentOrder) {
      alert(errPaymentOrder.data.message);
      console.error(errPaymentOrder.data.message);
      dispatch(setAppSliceIsFetching(false));
      setPaymentStatus(PaymentStatus.Failed);
      dispatch(setBookingRoutesCurrentPage(URLS.UNIT_SELECTION));
      return;
    }

    if (
      UNIT_STATUSES_INDEX[unitCart.unit.status] <
      UNIT_STATUSES_INDEX[UNIT_STATUSES.BLOCKED]
    ) {
      await reserveUnitByUnitCartIdAPI(unitCart.id).unwrap();
    }

    getUnitCartAPI({
      unit_id: unitCart.unit.id,
      user_id: unitCart.user.id,
    });

    var options = {
      key: project.razorpay.keyId, // Enter the Key ID generated from the Dashboard
      amount: resPaymentOrder.razorpayOrder.amount,
      currency: 'INR',
      name: project.name,
      description: 'Payment to block unit',
      image: project.razorpay.logoUrl,
      order_id: resPaymentOrder.razorpayOrder.orderId,
      handler: async (response) => {
        // TODO: Signiture verfication

        const { data: successfulPaymentOrder } =
          await successfulPaymentOrderAPI({
            paymentOrderId: resPaymentOrder.id,
            transactionId: response.razorpay_payment_id,
            unitCartId: unitCart.id,
            mode: PAYMENT_MODES.RAZORPAY.value,
          }).unwrap();
        if (payableAmount == successfulPaymentOrder.amount) {
          if (
            UNIT_STATUSES_INDEX[unitCart.unit.status] <
            UNIT_STATUSES_INDEX[UNIT_STATUSES.BLOCKED]
          ) {
            await blockUnitByUnitCartIdAPI(unitCart.id).unwrap();
            addToast({
              type: SUCCESS,
              primaryMessage: 'Unit is blocked successfully',
              secondaryMessage:
                'Our executive will be in contact for next steps',
            });
          } else if (
            UNIT_STATUSES_INDEX[unitCart.unit.status] ==
            UNIT_STATUSES_INDEX[UNIT_STATUSES.BLOCKED]
          ) {
            await bookUnitByUnitCartIdAPI(unitCart.id).unwrap();
            addToast({
              type: SUCCESS,
              primaryMessage: 'Unit is booked successfully',
              secondaryMessage:
                'Our executive will be in contact for next steps',
            });
          }
        }
        setPaymentStatus(PaymentStatus.Intitiate);
        if (successfulPaymentOrder.amount) {
          addToast({
            type: 'SUCCESS',
            primaryMessage: 'Payment Successful!',
            secondaryMessage: 'Your payment has been recieved',
          });
        }

        // if (errSuccessfulPayment) {
        //   // Failed
        //   addToast({
        //     type: 'ERROR',
        //     primaryMessage: 'Payment Failed!',
        //     secondaryMessage: errSuccessfulPayment,
        //   });
        //   setPaymentStatus(PaymentStatus.Failed);
        // } else {

        // }

        getUnitCartAPI({
          unit_id: unitCart.unit.id,
          user_id: unitCart.user.id,
        });

        dispatch(setAppSliceIsFetching(false));
      },
      prefill: {
        name: user.name,
        email: user.email,
        contact: user.mobile,
      },
      notes: {
        address: 'Razorpay Corporate Office',
      },
      modal: {
        ondismiss: function () {
          document.querySelector('.razorpay-container').remove();
          document.querySelector('#razorpay-checkout').remove();
          setPaymentStatus(PaymentStatus.Failed);
          dispatch(setAppSliceIsFetching(false));
        },
      },
      theme: {
        color: '#906fc8',
      },
    };
    var razorPayInstance = new window.Razorpay(options);
    razorPayInstance.open();
  };

  let statusImagePath;
  switch (paymentStatus) {
    case PaymentStatus.Processing:
      statusImagePath =
        'https://relata-temp.s3.ap-south-1.amazonaws.com/embassy/assets/payment-processing.jpg';
      break;
    case PaymentStatus.Successful:
      statusImagePath =
        'https://relata-temp.s3.ap-south-1.amazonaws.com/embassy/assets/payment-success.png';
      break;
    case PaymentStatus.Failed:
      statusImagePath =
        'https://relata-temp.s3.ap-south-1.amazonaws.com/embassy/assets/payment-fail.jpg';
      break;
    default:
      statusImagePath =
        'https://relata-temp.s3.ap-south-1.amazonaws.com/embassy/assets/payment-processing.jpg';
  }

  // useEffect(() => {
  //   if (paymentStatus === PaymentStatus.Intitiate) {
  //     payWithRazorpay();
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  const handleProceed = () => {
    history.push(`/dashboard/${project.id}`);
  };

  const handleZeroPayableAmount = async () => {
    try {
      if (
        UNIT_STATUSES_INDEX[unitCart.unit.status] <
        UNIT_STATUSES_INDEX[UNIT_STATUSES.BLOCKED]
      ) {
        await reserveUnitByUnitCartIdAPI(unitCart.id).unwrap();
        addToast({
          type: SUCCESS,
          primaryMessage: 'Unit is reserved successfully',
          secondaryMessage: 'Our executive will be in contact for next steps',
        });
      } else if (
        UNIT_STATUSES_INDEX[unitCart.unit.status] ==
        UNIT_STATUSES_INDEX[UNIT_STATUSES.BLOCKED]
      ) {
        await blockUnitByUnitCartIdAPI(unitCart.id).unwrap();
        addToast({
          type: SUCCESS,
          primaryMessage: 'Unit is blocked successfully',
          secondaryMessage: 'Our executive will be in contact for next steps',
        });
      } else {
        await bookUnitByUnitCartIdAPI(unitCart.id).unwrap();
        addToast({
          type: SUCCESS,
          primaryMessage: 'Unit is booked successfully',
          secondaryMessage: 'Our executive will be in contact for next steps',
        });
      }
      getUnitCartAPI({
        unit_id: unitCart.unit.id,
        user_id: unitCart.user.id,
      });
      history.push(`/dashboard/${project.id}`);
    } catch (error) {
      addToast({
        type: ERROR,
        primaryMessage: 'Unit reservation failed',
      });
    }
  };

  const handlePayOffline = async () => {
    try {
      await offlinePaymentRequestAPI(unitCart.id).unwrap();
      addToast({
        type: SUCCESS,
        primaryMessage: 'Offline payment request sent!',
        secondaryMessage: 'Our executive will be in contact for next steps',
      });
    } catch (error) {
      addToast({
        type: ERROR,
        primaryMessage: 'Offline payment request failed!',
      });
    }
  };

  return (
    <>
      {/* <div>{statusView}</div> */}

      <div className='payment-plan'>
        <div className='payment-plan__body'>
          <div className='payment-plan-milstone-table-container'>
            {/* <div className="payment-plan-milstone-table__seemore-overlay-bottom"> */}
            <div className='payment-image-container'>
              <img
                className='payment-page-status-image'
                src={statusImagePath}
                alt='Unit 2D plan'
              />
            </div>
            {/* </div> */}
          </div>
          <UnitSummaryCard />
        </div>
        <footer className='payment-plan__footer'>
          <button
            type='button'
            className='btn btn--transparent'
            onClick={() => {
              dispatch(setBookingRoutesCurrentPage(URLS.KYC));
            }}>
            Back to KYC
          </button>
          <div className='payment-plan__footer-action-btns'>
            {payableAmount != 0 ? (
              <div>
                {!(paymentStatus === PaymentStatus.Successful) && (
                  <div>
                    <button
                      type='button'
                      className={`btn btn--outline  ${
                        appSliceIsFetching && 'loading-spin-container'
                      }`}
                      onClick={() => payWithRazorpay()}>
                      <span>
                        {paymentStatus === PaymentStatus.Processing
                          ? 'Payment Processing'
                          : paymentStatus === PaymentStatus.Intitiate
                          ? 'Pay Online'
                          : 'Retry Payment'}
                      </span>
                      <span className='ic2-fa-spin-blue'></span>
                    </button>
                    <button
                      type='button'
                      className={`btn btn--outline payment-plan__footer-proceed-btn btn--accent}`}
                      onClick={handlePayOffline}>
                      Pay Offline
                    </button>
                  </div>
                )}
                {paymentStatus === PaymentStatus.Successful && (
                  <button
                    type='button'
                    className={`btn btn--outline payment-plan__footer-proceed-btn btn--accent}`}
                    onClick={handleProceed}>
                    Proceed
                  </button>
                )}
              </div>
            ) : (
              <div>
                {' '}
                <button
                  type='button'
                  className={`btn btn--outline payment-plan__footer-proceed-btn btn--accent}`}
                  onClick={handleZeroPayableAmount}>
                  {`${
                    UNIT_STATUSES_INDEX[unitCart.unit.status] <
                    UNIT_STATUSES_INDEX[UNIT_STATUSES.BLOCKED]
                      ? 'Block'
                      : 'Book'
                  } now`}
                </button>
              </div>
            )}
          </div>
        </footer>
      </div>
    </>
  );
};

export default Payment;
