import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import useToast from 'hooks/useToast';
import { TObjectId } from 'interfaces';
import Modal from 'apps/booking/components/common/modal/Modal';
import Icon from 'apps/booking/components/common/Icon';
import Button from 'apps/booking/components/common/buttons/Button';
import TextInput from 'apps/booking/components/common/inputs/TextInput';
import SearchDropDownInput from 'apps/booking/components/common/inputs/SearchDropDownInput';
import SpinLoader from 'apps/booking/components/common/SpinLoader';
import {
  useUpdateActivetoBookedLeadsMutation,
  useUpdateBookedToRegistrationDoneMutation,
} from 'apps/admin/services/projectLeadsAPI';
import { IUser } from 'apps/admin/interfaces';
import { useGetUsersByProjectIdMutation } from 'apps/admin/services/userRolesAPISlice';
import { getDateFormatInyyyymmdd } from 'utils/dateFormator';

import styles from './styles.module.css';

export interface IBookLeadReq {
  activeLeadId?: TObjectId;
  closingManagerId: TObjectId;
  unitNumber: string;
  unitConfiguration: string;
  bookingAmount: number;
  bookedOn: Date;
  agreementValue?: number;
  bookedLeadId?: TObjectId;
}

interface IBookingFormModalProps {
  onModalClose: () => void;
  projectId: TObjectId;
  activeLeadId?: TObjectId;
  registering?: boolean;
  bookedLeadData?: IBookLeadReq;
  bookedLeadId?: TObjectId;
  onSetIsBooked: (state: boolean) => void;
  closingManager?: IUser;
}

const bookingSchema = yup.object().shape({
  smName: yup.string().required(),
  unitNumber: yup.string().required(),
  unitConfiguration: yup.string().required(),
  bookingAmount: yup.number().required(),
  bookedOn: yup.string().required(),
});

const registeringSchema = yup.object().shape({
  smName: yup.string().required(),
  unitNumber: yup.string().required(),
  unitConfiguration: yup.string().required(),
  bookingAmount: yup.number().required(),
  bookedOn: yup.string().required(),
  agreementValue: yup.number().required(),
});

const BookingFormModal = (props: IBookingFormModalProps) => {
  const {
    projectId,
    activeLeadId,
    registering,
    onModalClose,
    onSetIsBooked,
    bookedLeadData,
    bookedLeadId,
    closingManager,
  } = props;

  const [booked, setIsBooked] = useState(false);
  const [executives, setExecutives] = useState<IUser[]>([]);
  const [isExecutivesLoding, setIsExecutiveLoading] = useState(false);

  const [addToast] = useToast();

  const [updateActivetoBookedLeadsAPI] = useUpdateActivetoBookedLeadsMutation();
  const [updateBookedToRegistrationDone] =
    useUpdateBookedToRegistrationDoneMutation();
  const [getUsersByProjectIdAPI] = useGetUsersByProjectIdMutation();

  const {
    register,
    unregister,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(registering ? registeringSchema : bookingSchema),
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });

  const agreementValue = watch('agreementValue');

  const executiveNames = useMemo(() => {
    return executives.map((executive) => executive.name);
  }, [executives]);

  const buttonLabel = useMemo(() => {
    if (agreementValue?.length) {
      return 'Register';
    }
    return 'Book';
  }, [agreementValue]);

  const getExecutiveId = (executiveName: string) => {
    const executive = executives.find(
      (executive) => executive.name === executiveName
    );

    return executive?.id;
  };

  useEffect(() => {
    getEexecutiveList();
  }, [projectId]);

  const getEexecutiveList = async () => {
    try {
      setIsExecutiveLoading(true);
      const response = await getUsersByProjectIdAPI({
        projectId,
      }).unwrap();
      setExecutives(response.data);
    } catch (err) {
    } finally {
      setIsExecutiveLoading(false);
    }
  };

  const handleLeadBooking = async (formData: {
    smName: string;
    unitNumber: string;
    unitConfiguration: string;
    bookingAmount: number;
    bookedOn: Date;
  }) => {
    try {
      setIsBooked(true);
      const requestPayload: IBookLeadReq = {
        activeLeadId,
        closingManagerId: getExecutiveId(formData.smName) as TObjectId,
        unitNumber: formData.unitNumber,
        unitConfiguration: formData.unitConfiguration,
        bookingAmount: formData.bookingAmount,
        bookedOn: formData.bookedOn,
      };

      await updateActivetoBookedLeadsAPI({
        requestPayload,
        projectId,
      }).unwrap();

      onSetIsBooked(true);
      onModalClose();
      addToast({
        type: 'SUCCESS',
        primaryMessage: 'Lead is successfully booked',
      });
    } catch (err) {
      const errMsg = (err as any).data.message;
      addToast({
        type: 'ERROR',
        primaryMessage: errMsg,
      });
      onSetIsBooked(false);
    } finally {
      setIsBooked(false);
    }
  };

  const handleRegistrationOfLeads = async (formData: {
    agreementValue: number;
    unitNumber: string;
    unitConfiguration: string;
    bookingAmount: number;
    bookedOn: Date;
    smName: string;
  }) => {
    try {
      setIsBooked(true);
      const requestPayload: IBookLeadReq = {
        bookedLeadId,
        closingManagerId: getExecutiveId(formData.smName) as TObjectId,
        unitNumber: formData.unitNumber,
        unitConfiguration: formData.unitConfiguration,
        bookingAmount: formData.bookingAmount,
        bookedOn: formData.bookedOn,
        agreementValue: formData.agreementValue,
      };

      await updateBookedToRegistrationDone({
        requestPayload,
        projectId,
      }).unwrap();

      onSetIsBooked(true);
      onModalClose();
      addToast({
        type: 'SUCCESS',
        primaryMessage: 'registration is done successfully',
      });
    } catch (err) {
      const errMsg = (err as any).data.message;
      addToast({
        type: 'ERROR',
        primaryMessage: errMsg,
      });
      onSetIsBooked(false);
    } finally {
      setIsBooked(false);
    }
  };

  if (isExecutivesLoding) {
    <SpinLoader />;
  }

  return (
    <Modal
      onOutsideClick={onModalClose}
      additionalClasses={styles.createNewAdminModal}>
      <header className={styles.modalHeader}>
        <div>
          <h2 className={styles.modalHeaderHeading}>Booking Form for Leads</h2>
          <p className={styles.modalHeaderDes}>
            Edit the following info to book leads
          </p>
        </div>
        <Icon
          name='close'
          propStyles={styles.closeIcon}
          onClick={onModalClose}
        />
      </header>

      <form
        onSubmit={handleSubmit(
          registering ? handleRegistrationOfLeads : handleLeadBooking
        )}>
        <main>
          <div className={styles.twoTextInputsRowContainer}>
            <div className={styles.addNameInput}>
              <SearchDropDownInput
                label='Executive name *'
                placeholder='select executive name'
                setValue={setValue}
                register={register}
                name='smName'
                suggestions={executiveNames}
                unregister={unregister}
                errorMessage={errors.smName && 'executive name is required'}
                propStyles={styles.searchOptions}
                defaultValue={closingManager?.name}
              />
            </div>
            {registering && (
              <div className={styles.addNameInput}>
                <TextInput
                  label='Agreement Value *'
                  placeHolder='Agreement value'
                  register={register}
                  setValue={setValue}
                  unregister={unregister}
                  name='agreementValue'
                  startIconText='INR'
                  errorMessage={
                    errors.agreementValue && 'agrement value is required'
                  }
                  type='number'
                  defaultValue={bookedLeadData?.agreementValue?.toString()}
                />
              </div>
            )}
          </div>

          <div className={styles.twoTextInputsRowContainer}>
            <div className={styles.addNameInput}>
              <TextInput
                label='Unit no. *'
                placeHolder='unit no.'
                register={register}
                setValue={setValue}
                unregister={unregister}
                name='unitNumber'
                errorMessage={errors.unitNumber && 'unit no. is required'}
                defaultValue={bookedLeadData?.unitNumber}
              />
            </div>
            <div className={styles.addNameInput}>
              <TextInput
                label='Unit configuration *'
                placeHolder='unit-config'
                register={register}
                setValue={setValue}
                unregister={unregister}
                name='unitConfiguration'
                errorMessage={
                  errors.unitConfiguration && 'configuration is required'
                }
                defaultValue={bookedLeadData?.unitNumber}
              />
            </div>
          </div>
          <div className={styles.twoTextInputsRowContainer}>
            <div className={styles.addNameInput}>
              <TextInput
                label='Booking Amount *'
                placeHolder='booking amount'
                register={register}
                setValue={setValue}
                unregister={unregister}
                name='bookingAmount'
                startIconText='INR'
                errorMessage={
                  errors.bookingAmount && 'booking amount is required'
                }
                type='number'
                defaultValue={bookedLeadData?.bookingAmount.toString()}
              />
            </div>
            <div className={styles.addNameInput}>
              <TextInput
                label='Booking Date *'
                placeHolder='booking date'
                register={register}
                setValue={setValue}
                unregister={unregister}
                name='bookedOn'
                maxLength={10}
                type='date'
                errorMessage={errors.bookedOn && 'booking date is required'}
                defaultValue={getDateFormatInyyyymmdd(
                  bookedLeadData?.bookedOn as Date
                )}
              />
            </div>
          </div>
        </main>

        <footer className={styles.modalFooter}>
          <p className={styles.footerDisclaimer}>
            By adding details the leads in booked to the particular SM and with
            selected unit configuration
          </p>
          <Button
            propStyles={styles.footerAddAdminButton}
            accent='primary'
            type='submit'
            disabled={booked}>
            {buttonLabel}
          </Button>
        </footer>
      </form>
    </Modal>
  );
};

export default BookingFormModal;
