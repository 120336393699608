import { useSelector } from 'react-redux';

import BlockedUnitCountDownTimer from '../common/cards/BlockedUnitCountDownTimer';
import BookingAmountCard from '../common/cards/BookingAmountCard';

import { getUnitCart } from '../../slices/selectedUnit';
import { BOOKED, UNIT_STATUSES, UNIT_STATUSES_INDEX } from 'constants/status';

const PaymentContentHeader = () => {
  const unitCart = useSelector(getUnitCart);
  const selectedUnit = unitCart.unit;
  const isPaymentDone = unitCart.unit.status === UNIT_STATUSES.PURCHASED;

  return (
    <header className='content-header'>
      <div className='content-header__title-plate'>
        {isPaymentDone ? (
          <h1 className='content-header__title'>
            Payment is <span className='font-accent-bold'>Successful</span> and
            unit{' '}
            <span className='font-accent-bold'>
              {selectedUnit.towerName}, {selectedUnit.type}
            </span>{' '}
            has been booked
          </h1>
        ) : (
          <h1 className='content-header__title'>
            Complete the{' '}
            <span className='font-accent-bold'>Requested Payment </span> to
            continue your{' '}
            {UNIT_STATUSES_INDEX[unitCart.unit.status] <
            UNIT_STATUSES_INDEX[UNIT_STATUSES.BLOCKED]
              ? 'Blocking'
              : 'Booking'}
          </h1>
        )}
        <p className='content-header__description'>
          Accept the payment request on your bank app and pay the required
          amount to proceed.
        </p>
      </div>
      <div className='content-header__card-container'>
        <BlockedUnitCountDownTimer />
        <BookingAmountCard />
      </div>
    </header>
  );
};

export default PaymentContentHeader;
