import { useDispatch, useSelector } from 'react-redux';

import {
  setUnitSelectionHeaderNavState,
  UNIT_SELECTION_HEADER_NAV_STATE,
} from 'apps/booking/slices/unitSelectionHeader/unitSelectionHeaderNav';
import { URLS } from '../../../../../constants/urls';
import { getLanguageText } from 'constants/multiLanguageTexts';
import { getUserLanguage } from 'apps/booking/slices/userlanguage';
import { setBookingRoutesCurrentPage } from 'apps/booking/routers/BookingRoutes/slice';
import { getProject } from 'apps/booking/slices/projectSlice';

const MasterBtn = () => {
  const dispatch = useDispatch();
  const userLanguage = useSelector(getUserLanguage);
  const project = useSelector(getProject);

  return (
    <button
      className='btn btn--accent'
      onClick={() => {
        dispatch(setBookingRoutesCurrentPage(URLS.UNIT_SELECTION));
        dispatch(
          setUnitSelectionHeaderNavState(UNIT_SELECTION_HEADER_NAV_STATE.MASTER)
        );
      }}>
      <span>
        {getLanguageText(
          userLanguage,
          project.booking?.title?.masterBtnTitle || 'Master Plan'
        )}
      </span>
    </button>
  );
};

export default MasterBtn;
