import { useMemo } from 'react';

import { ICompany } from 'apps/admin/interfaces';
import UserListGrid from 'apps/admin/components/pages/AdminDashboard/AdminExecutiveList/Body/UserListGrid';
import { UserRole } from 'constants/userRoles';

import styles from './styles.module.css';

interface IAdminDashboardBody {
  gridSearchTerm: string;
  company: ICompany;
  userRole: string;
}

const AdminDashboardBody = (props: IAdminDashboardBody) => {
  const { gridSearchTerm, company, userRole } = props;

  const users = useMemo(() => {
    if (userRole === UserRole.EXECUTIVE) {
      return (
        company?.users.filter(
          (user) =>
            user.role === UserRole.EXECUTIVE ||
            user.role === UserRole.GRE ||
            user.role === UserRole.SITE_HEAD
        ) || []
      );
    }

    return company?.users.filter((user) => user.role === userRole) || [];
  }, [userRole, company]);

  const handleExecutiveListSearch = (executiveGridSearchHandler: Function) => {
    executiveGridSearchHandler(gridSearchTerm);
  };

  return (
    <body>
      <div className={styles.tabsContainer}>
        <div className={styles.tabContainerBody}>
          <UserListGrid
            onExecutiveListSearch={handleExecutiveListSearch}
            users={users}
            companyId={company.id as string}
          />
        </div>
      </div>
    </body>
  );
};

export default AdminDashboardBody;
