import { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { CROSS_TICK_ICON } from '../../../../../constants/icons';
import {
  useOtpv2SendMutation,
  useOtpv2VerifyMutation,
  useGetUserByMobileMutation,
} from 'apps/admin/services/adminUserAPISlice';
import { useGetEOIForUserMutation } from 'apps/admin/services/eoiAPISlice';
import {
  getShowAuthForms,
  setShowAuthForms,
  setUser,
} from 'apps/booking/slices/auth';
import { getProject, setUserEOIs } from 'apps/booking/slices/projectSlice';
import { OTP_TEMPLATE_DEFAULT } from 'constants/smsEmailTemplates';
import useToast from 'hooks/useToast';

import { CURRENT_STEP_STATE } from 'constants/status';

// Validation
const schema = yup.object().shape({
  mobile_no: yup
    .string()
    .matches(/^[0-9]*$/)
    .length(10)
    .required(),
});

const LoginWithOtp = (props) => {
  const [requestLoading, setRequestLoading] = useState(false);
  const [isOtpValid, setIsOtpValid] = useState(false);
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [otpError, setOtpError] = useState('');
  const [formData, setFormData] = useState({
    mobile_no: '',
  });
  const otpInputRef = useRef();
  const [otpv2SendAPI] = useOtpv2SendMutation();
  const [otpv2VerifyAPI] = useOtpv2VerifyMutation();
  const [getUserByMobileAPI] = useGetUserByMobileMutation();
  const [getEOIForUserAPI] = useGetEOIForUserMutation();
  const dispatch = useDispatch();
  const projectData = useSelector(getProject);
  const showAuthForms = useSelector(getShowAuthForms);

  const [addToast] = useToast();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isValid: isFormValid },
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
  });

  const mobile = watch('mobile_no');

  const resetOtp = () => {
    otpInputRef.current.value = '';
    setOtpError('');
    setIsOtpValid(false);
  };

  const handleSendOtp = async (data) => {
    setFormData(data);
    try {
      await otpv2SendAPI({
        address: data.mobile_no,
        addressType: 'MOBILE',
        otpTemplate: OTP_TEMPLATE_DEFAULT,
      }).unwrap();
      addToast({
        type: 'SUCCESS',
        primaryMessage: `OTP Sent to ${data.mobile_no}`,
        timeout: 1000,
      });
      setIsOtpSent(true);
    } catch (err) {
      const errMsg = err.data.message;
      addToast({
        type: 'ERROR',
        primaryMessage: errMsg,
        timeout: 1000,
      });
    }
    resetOtp();
  };

  const handleOptInputFieldChange = (value) => {
    if (value.length !== 6) {
      setIsOtpValid(false);
      setOtpError('');
      if (value.length > 6) {
        setOtpError('OPT contains only 6 digit');
      }
      return false;
    }

    setOtpError('');
    setIsOtpValid(true);
    return true;
  };

  const handleConfirmOtp = async (data) => {
    setRequestLoading(true);

    const { mobile_no } = data;
    const otp = otpInputRef.current.value;

    try {
      await otpv2VerifyAPI({ address: mobile_no, otp });
      const { data: user } = await getUserByMobileAPI({
        mobile: mobile_no,
      }).unwrap();

      if (projectData?.eoi?.enabled) {
        const { data: eoiData } = await getEOIForUserAPI({
          userId: user.id,
        });

        const activeEOIs = eoiData.data.filter(
          (eoi) =>
            projectData.id == eoi.project &&
            eoi.status == CURRENT_STEP_STATE.ACTIVE_BOOKING_ENABLED
        );
        // if (!activeEOIs.length) {
        //   addToast({
        //     type: 'ERROR',
        //     primaryMessage: 'No active eoi found',
        //     timeout: 1000,
        //   });
        //   return;
        // }
        dispatch(setUserEOIs(activeEOIs));
      }

      dispatch(setUser(user));
      dispatch(setShowAuthForms(false));
    } catch (error) {
      const errMsg = error.data?.message;
      addToast({
        type: 'ERROR',
        primaryMessage: errMsg ? errMsg : 'Internal error',
        timeout: 1000,
      });
    }
    setRequestLoading(false);
  };

  return (
    <>
      <form
        className={`login-form login-form--singin-with-otp ${
          isFormValid && 'login-form--valid'
        }`}>
        <header className='login-form__header'>
          <span className='login-from__header-title'>Hey there!</span>
          {!showAuthForms && (
            <span
              className='icon material-icons icon-cross click-enabled'
              onClick={() => dispatch(setShowAuthForms(false))}>
              {CROSS_TICK_ICON}
            </span>
          )}
        </header>
        <main className='login-form__body'>
          <div className='form-summery-desc'>
            Welcome to the user login page. To start accessing your account,
            Login now!
          </div>
          <div className='input'>
            <label className='input_label'>Phone Number</label>
            <input
              type='text'
              className='input_field'
              {...register('mobile_no')}
              defaultValue={formData.mobile_no}
            />
            <span className='input__error'>
              {errors.mobile_no &&
                mobile.length > 10 &&
                '10 digit Phone Number is required'}
            </span>
          </div>
          <div className={`input input--otp ${!isOtpSent && 'click-disabled'}`}>
            <label className='input_label'>Enter 6-digit OTP</label>
            <input
              ref={otpInputRef}
              type='number'
              className='input_field'
              min='100000'
              max='999999'
              onInput={(e) => handleOptInputFieldChange(e.target.value)}
            />
            <span
              className='input__resend-otp click-enabled'
              onClick={() => handleSubmit(handleSendOtp)()}>
              Resend OTP
            </span>
            <span className='input__error'>{otpError}</span>
          </div>
        </main>
        <footer className='login-form__footer'>
          <div className='input'>
            <button
              type='button'
              className={`input_field 
                ${(isOtpSent ? isOtpValid : isFormValid) && 'click-enabled'}
                ${!(isOtpSent ? isOtpValid : isFormValid) && 'click-disabled'}
                ${requestLoading && 'loading-spin-container'}`}
              onClick={(e) => {
                isOtpSent
                  ? handleSubmit(handleConfirmOtp)()
                  : handleSubmit(handleSendOtp)();
              }}>
              <span>{isOtpSent ? 'SUBMIT' : 'SEND OTP'}</span>
              <span className='ic2-fa-spin-blue'></span>
            </button>
          </div>
          <span className='f-15 c-56'>
            By clicking continue, you are agreeing to our privacy policy & terms
            of use. More information at &nbsp;
            <a
              className='c-accent'
              href='https://relata-temp.s3.ap-south-1.amazonaws.com/embassy/assets/terms_and_conditions.pdf'
              target='_blank'
              rel='noreferrer'>
              Terms & Conditions.
            </a>
          </span>
        </footer>
      </form>
    </>
  );
};

export default LoginWithOtp;
