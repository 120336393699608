import { useEffect, useState } from 'react';

import Icon from 'apps/booking/components/common/Icon';
import Button from 'apps/booking/components/common/buttons/Button';
import {
  ISiteVisitDetails,
  SiteVisitState,
  IQrScanDetails,
} from 'apps/admin/components/pages/forms/SiteVisit/index';
import {
  useAddSiteVisitMutation,
  useGetAssignedCpPreviewMutation,
  useQrcodeScanAnalyticsMutation,
} from 'apps/admin/services/siteVisitAPI';
import { TObjectId } from 'interfaces';
import useToast from 'hooks/useToast';
import SpinLoader from 'apps/booking/components/common/SpinLoader';
import { QRCODE_EVENTS } from 'apps/cp/constants';

import styles from './styles.module.css';

export interface IAssignedCpPreviewReq {
  projectId: TObjectId;
  contendingCpLeadIds: TObjectId[];
  leadMobile: string;
}

export interface ICpPreview {
  id: TObjectId;
  name: string;
  mobile: string;
  email: string;
  cpLeadId: TObjectId;
  sm: {
    userId?: TObjectId;
    name?: string;
    mobile?: string;
  };
}

export interface IAssignedCpPreviewRes {
  winnerCanBeModified: boolean;
  conflicted: boolean;
  winnerCp: ICpPreview;
  loserCps: ICpPreview[];
  assignmentReason: string;
}

interface IAssingedCpInfoProps {
  qrScanDetails: IQrScanDetails;
  siteVisitDetails: ISiteVisitDetails;
  setSiteVisitDetails: Function;
  contendingCpLeadIds: string[];
  setSiteVisitState: (state: SiteVisitState) => void;
  assignedCpPreview?: ICpPreview;
  setAssignedCpPreview: (cp?: ICpPreview) => void;
  setContendCpLeadIds: (leadIds: TObjectId[]) => void;
}

export const getCpNameInitials = (name: string) => {
  return `${name[0].toUpperCase()}`;
};

const CONFLICT_MESSG = 'CPs are in Conflict';

const AssingedCpInfo = (props: IAssingedCpInfoProps) => {
  const {
    qrScanDetails,
    siteVisitDetails,
    contendingCpLeadIds,
    assignedCpPreview,
    setAssignedCpPreview,
    setSiteVisitState,
    setContendCpLeadIds,
  } = props;

  const [cpPreviewing, setCpPreviewing] = useState(false);
  const [cpPreviewDetails, setCpPreviewDetails] =
    useState<IAssignedCpPreviewRes>();
  const [losingCpsPreview, setLosingCpsPreview] = useState<ICpPreview[]>();
  const [detailsSubmitted, setDetailsSubmitted] = useState(false);

  const [getAssignedCpPreviewAPI] = useGetAssignedCpPreviewMutation();
  const [addSiteVisitAPI] = useAddSiteVisitMutation();
  const [qrcodeScanAnalyticsAPI] = useQrcodeScanAnalyticsMutation();
  const [addToast] = useToast();

  useEffect(() => {
    if (contendingCpLeadIds.length) {
      handleAssignedCpPreview();
    }
  }, [contendingCpLeadIds]);

  const handleAssignedCpPreview = async () => {
    try {
      setCpPreviewing(true);
      const response = await getAssignedCpPreviewAPI({
        projectId: siteVisitDetails.projectId,
        contendingCpLeadIds: contendingCpLeadIds,
        leadMobile: siteVisitDetails.customerMobile,
      }).unwrap();

      setCpPreviewDetails(response.data);
      setAssignedCpPreview(response.data.winnerCp);
      setLosingCpsPreview(response.data.loserCps);
    } catch (err) {
      const errMsg = (err as any).data.message;
      addToast({
        type: 'ERROR',
        primaryMessage: errMsg,
        timeout: 1000,
      });
    } finally {
      setCpPreviewing(false);
    }
  };

  const handleBack = () => {
    setContendCpLeadIds([]);
    setCpPreviewDetails(undefined);
    setAssignedCpPreview(undefined);
    setLosingCpsPreview(undefined);

    setSiteVisitState(SiteVisitState.QRCODE_SCANNER);
  };

  const handleSubmit = async () => {
    try {
      setDetailsSubmitted(true);
      const finalSiteVisitDetails: ISiteVisitDetails = {
        ...siteVisitDetails,
        cpLeadIds: contendingCpLeadIds,
      };
      await addSiteVisitAPI(finalSiteVisitDetails).unwrap();
      await qrcodeScanAnalyticsAPI({
        cpUserId: qrScanDetails.cpUserId,
        cpLeadId: qrScanDetails.cpLeadId,
        projectId: finalSiteVisitDetails.projectId,
        eventName: QRCODE_EVENTS.SCANQRCODE,
      }).unwrap();
      addToast({
        type: 'SUCCESS',
        primaryMessage: 'Site visit details added',
        secondaryMessage: '',
        timeout: 1000,
      });

      setSiteVisitState(SiteVisitState.SUMMERY);
    } catch (err) {
      const errMsg = (err as any).data.message;
      addToast({
        type: 'ERROR',
        primaryMessage: errMsg,
        timeout: 1000,
      });
    } finally {
      setDetailsSubmitted(false);
    }
  };

  const handleShowQrCode = () => {
    setSiteVisitState(SiteVisitState.QRCODE_SCANNER);
  };

  const handleRemoveTentativeCp = () => {
    if (cpPreviewDetails?.winnerCanBeModified) {
      const updatedCpLeadIds = contendingCpLeadIds.filter(
        (id) => id != assignedCpPreview?.cpLeadId
      );
      setContendCpLeadIds(updatedCpLeadIds);

      setAssignedCpPreview(undefined);
    }
  };

  const handleRemoveLosingCps = (removedLosingCp: ICpPreview) => {
    const updatedCpLeadIds = contendingCpLeadIds.filter(
      (id) => id !== removedLosingCp.cpLeadId
    );
    setContendCpLeadIds(updatedCpLeadIds);

    const removedCps = losingCpsPreview?.filter(
      (cp) => cp.id !== removedLosingCp.id
    );

    setLosingCpsPreview(removedCps);
  };

  if (cpPreviewing) {
    return <SpinLoader />;
  }

  return (
    <>
      <main>
        <p className={styles.assingedCpText}>
          Channel Partner Assigned to the Lead
        </p>
        {assignedCpPreview && !cpPreviewDetails?.conflicted && (
          <div className={styles.cpInfoWrapper}>
            <div className={styles.cpInfo}>
              <div className={styles.nameIntials}>
                {getCpNameInitials(assignedCpPreview.name)}
                <span className={styles.checkIcon}>
                  <Icon name='check_circle' propStyles={styles.rightIcon} />
                </span>
              </div>
              <div className={styles.contentText}>
                <span className={styles.cpName}>{assignedCpPreview.name}</span>
                {assignedCpPreview.sm.name && (
                  <span className={styles.smName}>
                    {assignedCpPreview.sm.name}
                  </span>
                )}
              </div>
            </div>
            <Icon
              name='close'
              propStyles={styles.close}
              onClick={handleRemoveTentativeCp}
            />
          </div>
        )}
        <div className={styles.tagging}>
          <div className={styles.textWrapperMain}>
            <span className={styles.ashtrikIcon}>*</span>
            <span className={styles.textWrap}>
              {cpPreviewDetails?.conflicted
                ? CONFLICT_MESSG
                : cpPreviewDetails?.assignmentReason}
            </span>
          </div>
        </div>
        {!!losingCpsPreview?.length && (
          <>
            <p className={styles.assingedCpText}>Other Channel Partner(s)</p>
            {losingCpsPreview.map((cp) => (
              <div className={styles.cpInfoWrapper} key={cp.id}>
                <div className={styles.cpInfo}>
                  <div className={styles.nameIntialsNoBorder}>
                    {getCpNameInitials(cp.name)}
                  </div>
                  <div className={styles.contentText}>
                    <span className={styles.cpName}>{cp.name}</span>
                    {cp.sm.name && (
                      <span className={styles.smName}>{cp.sm.name}</span>
                    )}
                  </div>
                </div>
                <Icon
                  name='close'
                  propStyles={styles.close}
                  onClick={() => handleRemoveLosingCps(cp)}
                />
              </div>
            ))}
          </>
        )}
        <p className={styles.assingedCpText}>Add another Channel Partner</p>
        <Button
          onClick={handleShowQrCode}
          accent='primary'
          propStyles={styles.scanQr}>
          Scan QR Code
        </Button>
      </main>

      <footer className={styles.footer}>
        <Button propStyles={styles.backButton} onClick={handleBack}>
          Back
        </Button>
        <Button
          propStyles={styles.submitButton}
          accent='primary'
          loading={detailsSubmitted}
          onClick={handleSubmit}>
          Submit
        </Button>
      </footer>
    </>
  );
};

export default AssingedCpInfo;
