import { useMemo } from 'react';

import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import TextInput from 'apps/booking/components/common/inputs/TextInput';
import Button from 'apps/booking/components/common/buttons/Button';
import { IProject } from 'apps/admin/components/pages/SuperAdminDashboard/SuperAdminProjectList';
import SecondaryCompanyList from 'apps/admin/components/pages/SuperAdminDashboard/SuperAdminProjectList/modals/AddNewProjectModal/SecondaryCompanyList';
import useToast from 'hooks/useToast';
import { useUpdateProjectMutation } from 'apps/admin/services/projectsAPISlice';
import { useGetCompaniesQuery } from 'apps/admin/services/companyAPISlice';
import { defaultDateFormat } from 'utils/dateFormator';

import styles from './styles.module.css';

// Validation
const schema = yup.object().shape({
  name: yup.string().required(),
  rera: yup.string().required(),
  city: yup.string().required(),
  reraUrl: yup.string().required(),
  pocEmail: yup.string().email().required(),
  pocMobile: yup.string().required(),
  smCpOnboardingDate: yup.string().required(),
});

interface IProjectUpdateBodyProps {
  project: IProject;
  onProjectUpdate: (updateProject: IProject) => void;
}

const ProjectUpdateBody = (props: IProjectUpdateBodyProps) => {
  const { project, onProjectUpdate } = props;

  const {
    register,
    unregister,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });

  const [addToast] = useToast();
  const [updateProject] = useUpdateProjectMutation();

  const { data: companies, isLoading } = useGetCompaniesQuery({});

  const nonCpfirmList = useMemo(() => {
    return companies?.filter((company) => company.isCpFirm === false);
  }, [companies]);

  const handleUpdateProject = async (projectUpdatedData: IProject) => {
    try {
      const updatedProject = await updateProject({
        ...projectUpdatedData,
        projectId: project.id,
      }).unwrap();

      onProjectUpdate(updatedProject.data);

      addToast({
        type: 'SUCCESS',
        primaryMessage: 'Project successfully Updated',
      });
    } catch (err) {
      const errMsg = (err as any).data.message;

      addToast({
        type: 'ERROR',
        primaryMessage: errMsg,
        secondaryMessage: 'Project creation failed',
      });
      return;
    }
  };

  return (
    <>
      <header className={styles.modalHeader}>
        <div className={styles.modalHeaderLeftContent}>
          <h1>Update Project!</h1>
          <p>
            Fill in the following info to create a new project associated to the
            account of the selected developer. You will receive Project ID that
            you will need at different steps.
          </p>
        </div>
      </header>
      <main>
        <div className={styles.bodyIndividualdRow}>
          <div className={styles.inputsContainer}>
            <TextInput
              defaultValue={project.name}
              label='Project Name'
              placeHolder='Add Name of the Project'
              register={register}
              setValue={setValue}
              unregister={unregister}
              name='name'
              errorMessage={errors.name ? 'Project Name is required' : ''}
            />
          </div>
          <div className={styles.inputsContainer}>
            <TextInput
              label='Project RERA'
              defaultValue={project.rera}
              placeHolder='Add Regd RERA number'
              register={register}
              setValue={setValue}
              unregister={unregister}
              name='rera'
              errorMessage={errors.rera ? 'Project RERA is required' : ''}
            />
          </div>
        </div>
        <div className={styles.bodyIndividualdRow}>
          <div className={styles.inputsContainer}>
            <TextInput
              label='City of the Project'
              defaultValue={project.city}
              placeHolder='Add Name of the City'
              register={register}
              setValue={setValue}
              unregister={unregister}
              name='city'
              errorMessage={
                errors.city ? 'City of the Project is required' : ''
              }
            />
          </div>
          <div className={styles.inputsContainer}>
            <TextInput
              label='Project RERA URL'
              defaultValue={project.reraUrl}
              placeHolder='Add applicable RERA site'
              register={register}
              setValue={setValue}
              unregister={unregister}
              name='reraUrl'
              errorMessage={
                errors.reraUrl ? 'Project RERA URL is required' : ''
              }
            />
          </div>
        </div>
        <div className={styles.bodyIndividualdRow}>
          <div className={styles.inputsContainer}>
            <TextInput
              label='On-boarding Date'
              placeHolder=''
              defaultValue={
                project.smCpOnboardingDate
                  ? defaultDateFormat(project.smCpOnboardingDate)
                  : ''
              }
              type='date'
              register={register}
              setValue={setValue}
              unregister={unregister}
              name='smCpOnboardingDate'
              errorMessage={
                errors.smCpOnboardingDate && 'On-boarding is required'
              }
            />
          </div>
        </div>
        <div className={styles.formDividerLineContainer}></div>
        <SecondaryCompanyList
          companyList={nonCpfirmList || []}
          primaryCompanyId={project.company.id}
          secondaryCompanies={project.secondaryCompanies}
          register={register}
          unregister={unregister}
          setValue={setValue}
          name='secondaryCompanies'
          isLoading={isLoading}
        />
        <div className={styles.formDividerLineContainer}></div>
        <div className={styles.bodyIndividualdRow}>
          <div
            className={`${styles.inputsContainer} ${styles.contactDetailsInputContainer}`}>
            <TextInput
              label='Point of Contact Email'
              defaultValue={project.pocEmail}
              placeHolder='POC Email ID'
              register={register}
              setValue={setValue}
              unregister={unregister}
              name='pocEmail'
              errorMessage={
                errors.pocEmail ? 'Please enter valid POC email' : ''
              }
            />
            <p className={styles.inputSuggestion}>
              Taken from last project of the developer
            </p>
          </div>
          <div
            className={`${styles.inputsContainer} ${styles.contactDetailsInputContainer}`}>
            <TextInput
              label='Point of Contact Mobile'
              defaultValue={project.pocMobile}
              placeHolder='POC Mobile Number'
              register={register}
              setValue={setValue}
              unregister={unregister}
              name='pocMobile'
              errorMessage={
                errors?.pocMobile ? 'POC Mobile Number is required' : ''
              }
            />
            <p className={styles.inputSuggestion}>
              Taken from last project of the developer
            </p>
          </div>
        </div>
      </main>
      <footer className={styles.footer}>
        <p className={styles.footerDisclaimer}>
          By clicking Apply Discount you are making updates to the cost sheet
          that the user can view. Confirm discount on the Summary on the right
          before clicking on Apply Discount
        </p>
        <div className={styles.createProjectBtnContainer}>
          <Button
            type='submit'
            propStyles={`${styles.createProjectBtn}`}
            onClick={() => handleSubmit(handleUpdateProject)()}>
            Update Project
          </Button>
        </div>
      </footer>
    </>
  );
};

export default ProjectUpdateBody;
