import { useState } from 'react';
import { useForm } from 'react-hook-form';

import Button from '../../buttons/Button';
import DisplayInputs from '../DisplayInputs';
import TextInput from '../TextInput';

import styles from './styles.module.css';

interface IMultiKeyValueInputProps {
  keyLabel?: string;
  keyPlaceholder: string;
  valueLabel?: string;
  valuePlaceholder: string;
  onAdd?: Function;
  type?:
    | 'text'
    | 'number'
    | 'email'
    | 'tel'
    | 'date'
    | 'datetime-local'
    | 'password'
    | 'range';
  displayInputs: string[];
  onRemove?: (label: string) => void;
}

const MultiKeyValueInput = (props: IMultiKeyValueInputProps) => {
  const {
    keyLabel,
    keyPlaceholder,
    valueLabel,
    valuePlaceholder,
    onAdd,
    displayInputs,
    type = 'text',
    onRemove,
  } = props;

  const [keyInput, setKeyInput] = useState('');
  const [valueInput, setValueInput] = useState('');

  const { setValue, handleSubmit } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });

  const handleKeyInputChange = (e: any) => {
    setKeyInput(e.target.value);
  };

  const handleValueInputChange = (e: any) => {
    setValueInput(e.target.value);
  };

  const handleAdd = () => {
    onAdd &&
      onAdd({
        name: keyInput,
        value: valueInput,
      });
    setKeyInput('');
    setValueInput('');
  };

  return (
    <>
      <div className={styles.bodyIndividualdRow}>
        <TextInput
          label={keyLabel}
          placeHolder={keyPlaceholder}
          onChange={handleKeyInputChange}
          setValue={setValue}
          type={type}
          resetValue={!keyInput}
        />
        <div className={styles.inputBtnWrap}>
          <TextInput
            label={valueLabel}
            placeHolder={valuePlaceholder}
            onChange={handleValueInputChange}
            setValue={setValue}
            resetValue={!valueInput}
            disabled={!keyInput}
            type={type}
          />
          <Button
            onClick={handleSubmit(handleAdd)}
            disabled={!valueInput}
            propStyles={styles.addBtn}>
            add
          </Button>
        </div>
      </div>

      <div className={styles.bodyIndividualdRow}>
        <DisplayInputs
          displayInputs={displayInputs}
          onRemove={onRemove}
          type='keyValue'
        />
      </div>
    </>
  );
};

export default MultiKeyValueInput;
