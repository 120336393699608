import Icon from 'apps/booking/components/common/Icon';

import styles from './styles.module.css';

const InvalidQRPage = () => {
  return (
    <div className={styles.container}>
      <div className={styles.contentWrapper}>
        <Icon name='block' propStyles={styles.removeIcon} />
        <p className={styles.title}>Invalid QR Code</p>
        <p className={styles.message}>
          SM doesn't belong to this project <br />
          Contact your SM for a valid QR Code.
        </p>
      </div>
    </div>
  );
};

export default InvalidQRPage;
