import { dateToDaythMonthYear } from 'utils/dateFormator';
import { getLiveSinceDays, ordinalSuffixOf } from 'utils/utils';

import styles from './styles.module.css';

interface ILiveSinceDateCellProps {
  liveSinceDate: Date;
  disabled?: boolean;
}

const LiveSinceDateCell = (props: ILiveSinceDateCellProps) => {
  const { liveSinceDate, disabled = false } = props;

  return (
    <div>
      <p
        className={`${styles.liveSinceDateValue} ${
          disabled ? styles.projectDisabled : ''
        }`}>
        {dateToDaythMonthYear(liveSinceDate)}
      </p>
      <p className={styles.liveSinceDiffInMonthsValue}>
        {`${getLiveSinceDays(liveSinceDate)} days`}
      </p>
    </div>
  );
};

export default LiveSinceDateCell;
