import { useState } from 'react';

import ProjectListModal from 'apps/admin/components/pages/CollaboratorsList/CollaboratorsStatus/ProjectListCell/ProjectListModal';
import Icon from 'apps/booking/components/common/Icon';

import styles from './styles.module.css';

interface IProjectListCell {
  projectList: string[];
}

const ProjectListCell = (props: IProjectListCell) => {
  const { projectList } = props;

  const [projectDetailsModalShown, setProjectDetailsModalShown] =
    useState(false);

  return (
    <>
      {projectDetailsModalShown && !!projectList?.length && (
        <ProjectListModal
          onModalClose={() => setProjectDetailsModalShown(false)}
          projectList={projectList}
        />
      )}
      <h2
        className={styles.projectCount}
        onClick={() => setProjectDetailsModalShown(true)}>
        {projectList?.length || 0}
        <Icon name='details' />
      </h2>
    </>
  );
};

export default ProjectListCell;
