import { useState } from 'react';

import PdfDownloadButton from '../common/PdfDownloadButton';

import { useCostSheetPdfMutation } from '../../../../api/units1';
import { downloadImage } from '../../../../utils/downloadImage';

const CostSheetPdfDownloadButton = (props) => {
  const { userId, unitId } = props;
  const [isDownloadInProgress, setIsDownloadInProgress] = useState(false);

  const [costSheetPdfAPI] = useCostSheetPdfMutation();

  const onDownloadBtnClick = async () => {
    setIsDownloadInProgress(true);
    const { data: { url } = {} } = await costSheetPdfAPI({
      user_id: userId,
      unit_id: unitId,
    });
    downloadImage(url, 'costsheet.pdf');
    setIsDownloadInProgress(false);
  };
  return (
    <PdfDownloadButton
      onDownloadBtnClick={onDownloadBtnClick}
      isLoading={isDownloadInProgress}
    />
  );
};

export default CostSheetPdfDownloadButton;
