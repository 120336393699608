import { useState } from 'react';
import { Route, useLocation, useRouteMatch } from 'react-router-dom';

import ProjectNameWithProjectSelection from 'apps/admin/components/common/ProjectNameWithProjectSelection';
import { IProject } from 'apps/admin/components/pages/SuperAdminDashboard/SuperAdminProjectList';
import ProjectPartners from 'apps/admin/components/pages/ProjectPartners';
import ProjectPartnerLeads from 'apps/admin/components/pages/ProjectPartnerLeads';
import ProjectMyTeam from 'apps/admin/components/pages/ProjectMyTeam';
import AdminDashboardAnalytics from 'apps/admin/components/pages/AdminDashboard/AdminDashboardAnalytics';
import ProjectExecutives from 'apps/admin/components/pages/ProjectExecutives';
import ProjectPartnerSiteVisits from 'apps/admin/components/pages/ProjectPartnerSitevisits';

interface IProjectRoutesProps {
  project?: IProject;
}

interface ILocationState {
  project: IProject;
}

const ProjectRoutes = (props: IProjectRoutesProps) => {
  const { path } = useRouteMatch();

  const location = useLocation<ILocationState>();
  const { project: projectFromState = location?.state?.project } = props;

  const [project, setSelectedProject] = useState<IProject>(projectFromState);

  return (
    <>
      <ProjectNameWithProjectSelection
        project={project}
        onProjectSelect={setSelectedProject}
      />

      {project ? (
        <>
          <Route exact path={`${path}/analytics`}>
            <AdminDashboardAnalytics project={project} />
          </Route>
          <Route exact path={`${path}/partners`}>
            <ProjectPartners
              project={project}
              onProjectUpdate={setSelectedProject}
            />
          </Route>
          <Route exact path={`${path}/partnerLeads`}>
            <ProjectPartnerLeads
              project={project}
              onProjectUpdate={setSelectedProject}
            />
          </Route>
          <Route exact path={`${path}/executives`}>
            <ProjectExecutives
              project={project}
              onProjectUpdate={setSelectedProject}
            />
          </Route>
          <Route exact path={`${path}/my-team`}>
            <ProjectMyTeam
              project={project}
              onProjectUpdate={setSelectedProject}
            />
          </Route>
          <Route exact path={`${path}/partnerSitevisits`}>
            <ProjectPartnerSiteVisits
              project={project}
              onProjectUpdate={setSelectedProject}
            />
          </Route>
        </>
      ) : (
        'Project is not available'
      )}
    </>
  );
};

export default ProjectRoutes;
