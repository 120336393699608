import { useMemo, useState } from 'react';

import 'ag-grid-enterprise';

import Modal from 'apps/booking/components/common/modal/Modal';
import Icon from 'apps/booking/components/common/Icon/index';
import Button from 'apps/booking/components/common/buttons/Button';
import { IUser } from 'apps/admin/interfaces';
import UserListGrid from 'apps/admin/components/pages/AdminDashboard/AdminExecutiveList/Body/UserListGrid';
import { UserRole } from 'constants/userRoles';
import AddCompanyUserModal from 'apps/admin/components/pages/AdminDashboard/AdminExecutiveList/modals/AddCompanyUserModal';
import { capitalizeFirstLetter } from 'utils/utils';

import styles from './styles.module.css';

interface ICompanyUserListModal {
  users: IUser[];
  companyId: string;
  companyName: string;
  userRole: UserRole;
  onModalClose: Function;
}

const CompanyUserListModal = (props: ICompanyUserListModal) => {
  const { users, companyId, companyName, userRole, onModalClose } = props;

  const [createNewAdminModal, setCreateNewAdminModal] = useState(false);

  const usersWithNameSort = useMemo(() => {
    return users.sort((user1: IUser, user2: IUser) =>
      user1.name.toLowerCase() > user2.name.toLowerCase() ? 1 : -1
    );
  }, [users]);

  return (
    <>
      {createNewAdminModal && (
        <AddCompanyUserModal
          role={userRole}
          onModalClose={() => setCreateNewAdminModal(false)}
          companyId={companyId}
        />
      )}
      <Modal
        onOutsideClick={onModalClose}
        additionalClasses={`${styles.modalAdditionalClasses}`}>
        {/* Modal Header */}
        <header className={styles.modalHeader}>
          <div>
            <h2 className={styles.modalHeading}>
              {companyName} List of{' '}
              {capitalizeFirstLetter(userRole.toLowerCase())}
            </h2>
            <p className={styles.modalDesc}>
              Following is the list of{' '}
              {capitalizeFirstLetter(userRole.toLowerCase())}
            </p>
          </div>
          <Icon
            name='close'
            propStyles={styles.crossIcon}
            onClick={() => {
              onModalClose();
            }}
          />
        </header>
        <UserListGrid users={usersWithNameSort} companyId={companyId} />
        <footer className={styles.footerContainer}>
          <div className={styles.footerLeftContainer}>
            <Button
              onClick={() => setCreateNewAdminModal(true)}
              propStyles={styles.addAdminButton}>
              Add {capitalizeFirstLetter(userRole.toLowerCase())}
            </Button>
            <p className={styles.disclaimer}>
              Adding an admin will send them an email with their randomly
              generated password.
            </p>
          </div>
          <div className={styles.adminCount}>
            {users.length} {capitalizeFirstLetter(userRole.toLowerCase())}
          </div>
        </footer>
      </Modal>
    </>
  );
};

export default CompanyUserListModal;
