//React Imports
import { useRef } from 'react';

//Third Party Import

// Utils
import {
  getEnvQueryParam,
  getVRUrl,
} from 'apps/admin/components/pages/ExecutiveDashboard/utils';

//Component Imports
import Modal from 'apps/booking/components/common/modal/Modal';
// import DropDownInput, {
//   DropDownOptionType
// } from '../../../../../../components/common/inputs/DropDownInput/DropDownInput';
import Icon from 'apps/booking/components/common/Icon';

//Constants Imports
import Button from 'apps/booking/components/common/buttons/Button';

//Interfaces
import { IDemoRoom } from 'apps/admin/components/pages/SuperAdminDashboard/SuperAdminProjectList/modals/AddDemoRoomModal';

//CSS Import
import styles from './styles.module.css';
// import genericStyles from '../../../../../../styles/genericStyles.module.css';

interface ISendInviteModal {
  executiveId: string;
  vrUrl: string;
  deomRoom: IDemoRoom;
  projectName: string;
  projectId: string;
  developerName: string;
  onClose: Function;
  showModal: Boolean;
  onDemoScheduled: Function;
}

const SendInviteModal = (props: ISendInviteModal) => {
  const {
    onClose,
    showModal,
    onDemoScheduled,
    executiveId,
    vrUrl,
    deomRoom,
    projectName,
    projectId,
    developerName,
  } = props;

  const inviteMessageRef = useRef() as React.LegacyRef<HTMLParagraphElement>;

  // TODO: Call onDemoScheduled at appropriate place

  // const handelDemoSessionDateChange = (
  //   event: React.ChangeEvent<HTMLInputElement>
  // ) => {
  //   //Changed Value should be will be stored in database
  //   console.log(event.target.value);
  // };

  // const handleDropDownSelection = (value: DropDownOptionType[]) => {};

  // const handelCopyToClipboardButtonClick = () => {
  //   navigator.clipboard.writeText('demo.relata.io/123cyt32');
  // };
  // const durationOptions: DropDownOptionType[] = [
  //   { value: '10', displayText: '10' },
  //   { value: '20', displayText: '20' },
  //   { value: '30', displayText: '30' },
  //   { value: '45', displayText: '45' }
  // ];

  return (
    <Modal
      show={showModal}
      onOutsideClick={() => onClose()}
      additionalClasses={`${styles.modalAdditionalClasses}`}>
      {/* Modal Header */}
      <header>
        <div className={styles.modalTitleContainer}>
          <h2 className={styles.modalTitle}>Schedule Demo</h2>
          <Icon
            name='close'
            propStyles={styles.crossIcon}
            onClick={() => onClose()}
          />
        </div>
        <p className={styles.modalDesc}>
          Choose Payment Method & add Transaction ID, Cheque Number, Challan
          Number or others
        </p>
      </header>

      {/* Modal Body */}
      <main>
        {/* <div className={styles.dateTimePickerInputsLabelsContainer}>
          <p className={styles.meetingScheduleDatePickerLabel}>Discount Mode</p>
        </div> */}
        {/* <div>
          <div className={styles.meetingScheduleDatePickerContainer}>
            <input
              type="datetime-local"
              className={`${genericStyles.pointer} ${styles.dateTimePickerInput}`}
              onChange={handelDemoSessionDateChange}
            />
          </div>
        </div> */}
        {/* <div>
          <div className={styles.durationDropdownContainer}>
            <DropDownInput
              defaultSelectedOption={{ value: '10', displayText: '10' }}
              options={durationOptions}
              lable="Expected Duration"
              onValueSelected={handleDropDownSelection}
            />
            <p
              className={`${styles.warningMessageDurationUnderDurationDropdwon} ${styles.meetingScheduleDatePickerLabel}`}>
              Schedule the demo to access the meeting invitation
            </p>
          </div>
        </div> */}
        <div className={styles.sendInviteModalMessageContainer}>
          <p className={styles.meetingScheduleDatePickerLabel}>
            Preview Invitation
          </p>
          <div className={styles.sendInviteModalMessage}>
            <p ref={inviteMessageRef}>
              Join a quick demo session with me where I can take you through the
              beautiful offerings of {developerName}'s {projectName}. <br />
              <br />
              Connect with me on this URL to meet your dream home.{' '}
              <span className={styles.messageBoldText}>
                {getVRUrl(vrUrl, deomRoom.virtualRoomId, executiveId)}{' '}
              </span>
              <br />
              <br />
              Google Meet Link:{' '}
              {deomRoom?.fallbackVirtualRoomUrl || 'Contact Admin'}
            </p>

            {/* <div
              className={styles.copyToClipboardBtn}
              onClick={handelCopyToClipboardButtonClick}>
              <Icon name='content_copy' propStyles={styles.copyIcon} onClick={() => onClose()}/>
              <p>Copy to clipboard</p>
            </div> */}
          </div>
          <p
            className={`${styles.ScheduleDemoInstructionLabel} ${styles.meetingScheduleDatePickerLabel}`}>
            Schedule the demo to access the meeting invitation
          </p>
        </div>
      </main>

      {/* Modal Footer */}
      <footer className={styles.sendInviteModalFooter}>
        {/* TODO: Add copy to clipboard functionality */}
        <Button
          propStyles={styles.copyToClipBoardButton}
          onClick={() => {
            navigator.clipboard.writeText(
              `Join a quick demo session with me where I can take you through the beautiful offerings of ${projectName}.\n\nConnect with me on this URL to meet your dream home.\n\n${`${vrUrl}?vri=${deomRoom?.virtualRoomId}&ei=${executiveId}${getEnvQueryParam()}`}\n\nGoogle Meet Link:  ${
                deomRoom?.fallbackVirtualRoomUrl || 'Contact Admin'
              }`
            );
          }}>
          Copy to clipboard
        </Button>
      </footer>
    </Modal>
  );
};

export default SendInviteModal;
