import { adminAPISlice } from 'apps/admin/services/index';
import { TObjectId } from 'interfaces';
import { ICpSiteVisit } from 'apps/cp/interfaces/';

export const cpSiteVisitAPISlice = adminAPISlice.injectEndpoints({
  endpoints: (build) => ({
    getSheduledSiteVisitByCpUser: build.mutation({
      query: (cpUserId: TObjectId) => ({
        url: `api/cpLeadSiteVisit/cp/${cpUserId}`,
        method: 'GET',
      }),
    }),
    addSiteVisitByCpUser: build.mutation({
      query: (cpSiteVisitDetails: ICpSiteVisit) => ({
        url: 'api/cpLeadSiteVisit',
        method: 'POST',
        body: cpSiteVisitDetails,
      }),
    }),
    updateSheduledSiteVisitByCpUser: build.mutation({
      query: ({ sheduleVisitId, ...fieldsToUpdate }) => ({
        url: `api/cpLeadSiteVisit/${sheduleVisitId}`,
        method: 'PUT',
        body: fieldsToUpdate,
      }),
    }),
  }),

  overrideExisting: false,
});

export const {
  useGetSheduledSiteVisitByCpUserMutation,
  useAddSiteVisitByCpUserMutation,
  useUpdateSheduledSiteVisitByCpUserMutation,
} = cpSiteVisitAPISlice;
