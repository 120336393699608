import { useState } from 'react';

import { ICellRendererParams } from 'ag-grid-community';

import MoreMenu from 'apps/admin/components/common/MoreMenu';
import CompanyDetailsModal from 'apps/admin/components/pages/SuperAdminDashboard/CompanyList/modals/CompanyDetailsModal';
import UpdateCompanyModal from 'apps/admin/components/pages/SuperAdminDashboard/CompanyList/modals/UpdateCompanyModal';
import { MenuItem } from 'apps/admin/components/common/MoreMenu';
import CompanyUserListModal from 'apps/admin/components/pages/SuperAdminDashboard/CompanyList/modals//CompanyUserListModal/index';
import { ICompany } from 'apps/admin/interfaces';
import { UserRole } from 'constants/userRoles';

interface ICompanyListMoreMenu {
  rowData: ICellRendererParams;
}

const CompanyListMoreMenu = (props: ICompanyListMoreMenu) => {
  const { rowData } = props;

  const company = rowData.data as ICompany;
  const [companyDetailsModalShown, setCompanyDetailsModalShown] =
    useState(false);

  const [updateCompanyModalShown, setUpdateCompanyModalShown] = useState(false);

  const [adminListModalShown, setAdminListModalShown] = useState(false);

  const [executiveListModalShown, setExecutiveListModalShown] = useState(false);

  const moreMenuOptions = [
    {
      displayText: 'View Details',
      icon: 'visibility',
      onClick: () => {
        setCompanyDetailsModalShown(true);
      },
    },
    {
      displayText: 'Edit Details',
      icon: 'edit',
      onClick: () => {
        setUpdateCompanyModalShown(true);
      },
    },
    {
      displayText: 'View Admin',
      icon: 'view_list',
      onClick: () => {
        setAdminListModalShown(true);
      },
    },
    {
      displayText: 'View Executive',
      icon: 'visibility',
      onClick: () => {
        setExecutiveListModalShown(true);
      },
    },
  ] as MenuItem[];

  return (
    <>
      {companyDetailsModalShown && (
        <CompanyDetailsModal
          company={company}
          onModalClose={() => {
            setCompanyDetailsModalShown(false);
          }}
        />
      )}

      {updateCompanyModalShown && (
        <UpdateCompanyModal
          company={company}
          onModalClose={() => setUpdateCompanyModalShown(false)}
        />
      )}

      {adminListModalShown && (
        <CompanyUserListModal
          users={company.users.filter((user) => user.role === UserRole.ADMIN)}
          companyId={company.id as string}
          companyName={company.name}
          userRole={UserRole.ADMIN}
          onModalClose={() => setAdminListModalShown(false)}
        />
      )}

      {executiveListModalShown && (
        <CompanyUserListModal
          users={company.users.filter(
            (user) => user.role === UserRole.EXECUTIVE
          )}
          companyId={company.id as string}
          companyName={company.name}
          userRole={UserRole.EXECUTIVE}
          onModalClose={() => setExecutiveListModalShown(false)}
        />
      )}

      <MoreMenu menuItems={moreMenuOptions} />
    </>
  );
};

export default CompanyListMoreMenu;
