import { createRef, useEffect, useState } from 'react';
import Loader from 'react-spinners/BarLoader';

import csvtojson from 'csvtojson';

import useToast from 'hooks/useToast';
import S3ClientNew from 'utils/S3ClientNew';
import Icon from 'apps/booking/components/common/Icon/index';
import DisplayInputs from 'apps/booking/components/common/inputs/DisplayInputs/index';
import { useSyncInventoryMutation } from 'api/units1';
import { SUCCESS } from 'constants/status';
import { IJsonUnit } from 'interfaces';
import UpdateSVGModal from 'apps/admin/components/pages/AdminDashboard/AdminExecutiveList/modals/UpdateSVGModal';

import styles from './style.module.css';

declare module 'react' {
  interface InputHTMLAttributes<T> extends HTMLAttributes<T> {
    // extends React's HTMLAttributes
    directory?: string;
    webkitdirectory?: string;
  }
}

interface IFileUploadNewProps {
  folder?: boolean;
  placeholder: string;
  label: string;
  s3DirPath: string;
  projectId?: string;
  onChangeFileUploadStatus?: (
    fileId: string,
    fileUploadStatus: boolean
  ) => void;
  onFileUpload?: (fileLocation: string) => void;
  onMultipleFileUpload?: (images: string[]) => void;
  defaultValue?: string;
  multiple?: boolean;
  errorMessage?: any;

  // useForm
  register?: Function;
  unregister?: Function;
  name?: string;
  setValue?: Function;

  appType?: 'RELATA' | 'RELATA-CP' | 'RELATA-CR' | 'BOOKING-ENGINE';
  propStyles?: any;
  showDisplayInputs?: boolean;
  displayInputs?: string[];
  onRemove?: (label: string) => void;
}

const FileUploadNew = (props: IFileUploadNewProps) => {
  const {
    folder,
    placeholder,
    label,
    s3DirPath,
    onChangeFileUploadStatus,
    onFileUpload,
    defaultValue = '',
    multiple,
    errorMessage,
    projectId,
    // useForm
    register = () => {
      return {
        name,
        ref: createRef(),
      };
    },
    unregister,
    name = '',
    setValue,

    appType,
    propStyles,
    onMultipleFileUpload,
    showDisplayInputs,
    displayInputs,
    onRemove,
  } = props;

  const [resolveUpdateSVG, setResolveUpdateSVG] = useState<
    ((file: File) => void) | null
  >(() => null);
  const [svgPathIds, setSVGPathIds] = useState<string[]>([]);
  const [updateSVGModalShown, setUpdateSVGModalShown] = useState(false);
  const [svgFile, setSvgFile] = useState<File | null>(null);
  const [showFileUploadingAnimation, setShowFileUploadingAnimation] =
    useState(false);
  const [uploadedFileName, setUploadedFileName] = useState(defaultValue);
  const [addToast] = useToast();
  const [syncInventoryAPI] = useSyncInventoryMutation();

  useEffect(() => {
    setValue && setValue(name, defaultValue);
  }, [defaultValue, name, setValue]);

  useEffect(() => {
    if (defaultValue) {
      setUploadedFileName(defaultValue);
    }
  }, [defaultValue]);

  useEffect(() => {
    register(name);

    return () => {
      unregister && unregister(name);
    };
  }, [register, unregister, name]);

  const handleFileUpload = (e: any) => {
    if (!e.target.files[0]) {
      // No file selected
      return;
    }

    const file = e.target.files[0];
    const fileName = e.target.files[0].name;
    const fileSize = e.target.files[0].size / 1024 / 1024; // in MiB
    const fileSizeLimit = 100;

    if (fileSize > fileSizeLimit) {
      addToast({
        type: 'ERROR',
        primaryMessage: `File size exceeds ${fileSizeLimit}MB`,
      });
      e.target.value = '';
      return;
    }

    setShowFileUploadingAnimation(true);
    onChangeFileUploadStatus && onChangeFileUploadStatus(s3DirPath, true);

    S3ClientNew.uploadFile(file, fileName, s3DirPath).then(
      (data: { status: number; location: string }) => {
        if (data.status === 204) {
          onFileUpload && onFileUpload(data.location);
          setValue && setValue(name, data.location);
          setUploadedFileName(fileName);
        } else {
          addToast({
            type: 'ERROR',
            primaryMessage: 'File upload error',
          });
        }

        setShowFileUploadingAnimation(false);
        onChangeFileUploadStatus && onChangeFileUploadStatus(s3DirPath, false);
      }
    );
  };

  // TODO: make a common function to handle upload files
  const handleMultipleFileUpload = async (e: any) => {
    if (!e.target.files.length) {
      // No file selected
      return;
    }

    const img: string[] = [];
    const files = e.target.files;
    for (let i = 0; i < files.length; i++) {
      const fileName = files[i].name;
      const fileSize = files[i].size / 1024 / 1024;
      const fileSizeLimit = 100;

      if (fileSize > fileSizeLimit) {
        addToast({
          type: 'ERROR',
          primaryMessage: `File size exceeds ${fileSizeLimit}MB`,
        });
        return;
      }
      setShowFileUploadingAnimation(true);

      S3ClientNew.uploadFile(files[i], fileName, s3DirPath).then(
        (data: { status: number; location: string }) => {
          if (data.status === 204) {
            img.push(data.location);
            onMultipleFileUpload && onMultipleFileUpload(img);
          } else {
            addToast({
              type: 'ERROR',
              primaryMessage: 'File upload error',
            });
          }

          setShowFileUploadingAnimation(false);
          onChangeFileUploadStatus &&
            onChangeFileUploadStatus(s3DirPath, false);
        }
      );
    }
  };

  const handleInventoryUpload = async (e: any) => {
    if (!e.target.files[0]) {
      // No file selected Or Wrong format
      return;
    }

    const file = e.target.files[0];
    const fileName = e.target.files[0].name;
    const fileSize = e.target.files[0].size / 1024 / 1024; // in MiB
    const fileSizeLimit = 100;

    if (fileSize > fileSizeLimit) {
      addToast({
        type: 'ERROR',
        primaryMessage: `File size exceeds ${fileSizeLimit}MB`,
      });
      e.target.value = '';
      return;
    }

    setShowFileUploadingAnimation(true);
    onChangeFileUploadStatus && onChangeFileUploadStatus(s3DirPath, true);

    const reader = new FileReader();
    reader.readAsText(file!);
    reader.onload = () => {
      const csv = reader.result as string;
      csvtojson()
        .fromString(csv)
        .then((json) => {
          sanitifyJsonUnit(json);
          uploadInventoryJson(json);
        });
    };
  };

  const uploadInventoryJson = async (json: Object) => {
    await syncInventoryAPI({ inventory: json })
      .unwrap()
      .then((response) => {
        if (response.code === SUCCESS) {
          console.log('Inventory synced');
          addToast({
            type: 'SUCCESS',
            primaryMessage: `Inventory synced successfully!`,
          });
        }
      })
      .catch((error) => {
        console.log('Inventory sync failure', error);
        addToast({
          type: 'ERROR',
          primaryMessage: `Inventory sync failed!`,
        });
      });
    setShowFileUploadingAnimation(false);
    onChangeFileUploadStatus && onChangeFileUploadStatus(s3DirPath, false);
  };

  const sanitifyJsonUnit = (jsonArray: IJsonUnit[]) => {
    const { projectId } = props;
    if (projectId === undefined) {
      throw new Error('Project ID is required');
    }
    if (!jsonArray) {
      return;
    }
    jsonArray.forEach((jsonObj) => {
      jsonObj['project'] = projectId;
      Object.entries(jsonObj).forEach(([key, value]) => {
        if (key == 'totalInclusiveAmount') {
          if (value !== null) {
            value = value
              .replaceAll(',', '')
              .replaceAll('"', '')
              .replaceAll(' ', '');
            jsonObj[key] = value;
          }
        }
      });
    });
  };
  const handleFolderUpload = async (e: any) => {
    if (!e.target.files[0]) {
      // No file selected
      return;
    }

    const files = e.target.files;

    for (let i = 0; i < files.length; i++) {
      try {
        let file = e.target.files[i];
        let fileName = e.target.files[i].name;
        fileName = fileName
          .replace('.svg', '')
          .replace('.jpg', '')
          .replace('.png', '')
          .replace('.jpeg', '');
        const fileSize = e.target.files[i].size / 1024 / 1024; // in MiB
        const fileSizeLimit = 100;

        if (fileSize > fileSizeLimit) {
          addToast({
            type: 'ERROR',
            primaryMessage: `File size exceeds ${fileSizeLimit}MB`,
          });
          e.target.value = '';
          throw new Error('File size exceeds ' + fileSizeLimit + 'MB');
        }

        if (file.type.toLowerCase().includes('svg')) {
          const pathIds = await extractPathIds(file);
          setSVGPathIds(pathIds);
          setSvgFile(file);
          setUpdateSVGModalShown(true);

          file = await new Promise<File>((resolve) => {
            setResolveUpdateSVG(() => resolve);
          });
        }
        let s3DirPath = getS3UrlForInventory(fileName);

        setShowFileUploadingAnimation(true);
        onChangeFileUploadStatus && onChangeFileUploadStatus(s3DirPath, true);

        S3ClientNew.uploadFile(file, fileName.toLowerCase(), s3DirPath).then(
          (data: { status: number; location: string }) => {
            if (data.status === 204) {
              onFileUpload && onFileUpload(data.location);
            } else {
              addToast({
                type: 'ERROR',
                primaryMessage: 'File upload error',
              });
            }

            setShowFileUploadingAnimation(false);
            onChangeFileUploadStatus &&
              onChangeFileUploadStatus(s3DirPath, false);
          }
        );
      } catch (error) {
        console.log('File upload failed: ', error);
      }
    }
  };

  const getS3UrlForInventory = (fileName: string) => {
    const configs = fileName.split('.');
    let s3Url = `projects/${projectId}/floorPlans`;

    if (configs.length <= 2 && configs.length > 0) {
      // file is for a towerPlan
      return s3Url;
    } else if (configs.length >= 3) {
      // file is for a unitPlan
      s3Url +=
        `/${configs[0].toLowerCase()}` +
        `/${configs[1].toLowerCase()}` +
        `/${configs[2].toLowerCase()}`;
      return s3Url;
    }

    throw new Error(
      'File format should have 1 or 3 config i.e. [floorConfig] or [floorConfig, unitConfig, dimensionConfig]'
    );
  };

  const extractPathIds = (file: File) => {
    return new Promise<string[]>((resolve) => {
      const reader = new FileReader();

      reader.onload = async (event) => {
        const svgContent = event?.target?.result as any;
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(svgContent, 'image/svg+xml');
        const paths = xmlDoc.getElementsByTagName('path');
        const pathIds: string[] = [];

        for (let i = 0; i < paths.length; i++) {
          const path = paths[i];
          const originalId = path.getAttribute('id');
          if (originalId) {
            pathIds.push(originalId);
          }
        }
        resolve(pathIds);
      };

      reader.onerror = (event) => {
        console.log(event?.target?.error);
      };

      reader.readAsText(file);
    });
  };

  const extractAndModifyPathIdsFromFile = (file: File, pathIds: string[]) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (event) => {
        const svgContent = event?.target?.result;
        const modifiedSvgContent = extractAndModifyPathIds(
          svgContent as string,
          pathIds
        );

        const modifiedFile = new File([modifiedSvgContent], file.name, {
          type: file.type,
        });

        resolve(modifiedFile);
      };

      reader.onerror = (event) => {
        reject(event?.target?.error);
      };

      reader.readAsText(file);
    });
  };

  const extractAndModifyPathIds = (svgContent: string, pathIds: string[]) => {
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(svgContent, 'image/svg+xml');
    const paths = xmlDoc.getElementsByTagName('path');
    for (let i = 0; i < paths.length; i++) {
      paths[i].setAttribute('id', pathIds[i]);
    }
    const serializer = new XMLSerializer();
    return serializer.serializeToString(xmlDoc);
  };

  return (
    <div className={styles.inputContainer} data-app={appType}>
      <label className={styles.inputLabel} data-app={appType}>
        {label}
      </label>
      <div className={styles.inputBox} data-app={appType}>
        <div className={styles.inputBoxLeftContent} data-app={appType}>
          {folder && (
            <input
              id={s3DirPath}
              type='file'
              accept='jpg/jpeg/png/pdf/csv'
              className={styles.inputField}
              placeholder={placeholder}
              onChange={handleFolderUpload}
              webkitdirectory=''
            />
          )}{' '}
          {!folder &&
            (multiple ? (
              <input
                id={s3DirPath}
                type='file'
                accept='jpg/jpeg/png/pdf'
                className={styles.inputField}
                placeholder={placeholder}
                onChange={handleMultipleFileUpload}
                multiple
              />
            ) : (
              <input
                id={s3DirPath}
                type='file'
                accept='jpg/jpeg/png/pdf/csv'
                className={styles.inputField}
                placeholder={placeholder}
                onChange={
                  label == 'Inventory'
                    ? handleInventoryUpload
                    : handleFileUpload
                }
              />
            ))}
          <label
            className={styles.fileNameLabel}
            data-app={appType}
            htmlFor={s3DirPath}>
            {!multiple && showFileUploadingAnimation
              ? 'Uploading...'
              : S3ClientNew.fileName(uploadedFileName) || placeholder}
          </label>
          {showFileUploadingAnimation && <Loader />}
          <span className={styles.inputError}>{errorMessage}</span>
          <Icon name='upload_file' propStyles={`${styles.endIcon}`} />
        </div>
      </div>
      {showDisplayInputs && (
        <DisplayInputs
          displayInputs={displayInputs}
          onRemove={onRemove}
          type='image'
        />
      )}
      {updateSVGModalShown && (
        <UpdateSVGModal
          onModalClose={async (updatedPathIds: string[]) => {
            const file = await extractAndModifyPathIdsFromFile(
              svgFile as File,
              updatedPathIds
            );
            resolveUpdateSVG && resolveUpdateSVG(file as File);
            setUpdateSVGModalShown(false);
          }}
          fileName={svgFile?.name || ''}
          svgPathIds={svgPathIds}
        />
      )}
    </div>
  );
};

export default FileUploadNew;
