import { useSelector } from 'react-redux';

import { getUserLanguage } from 'apps/booking/slices/userlanguage';
import { getLanguageText } from 'constants/multiLanguageTexts';

export const getDropdownPlaceholderText = (
  selectedOptionValue,
  dropDownBtnState
) => {
  const selectedTowerOption = dropDownBtnState.options.find(
    (option) => option.value === selectedOptionValue
  );
  return selectedTowerOption
    ? selectedTowerOption.text
    : dropDownBtnState.fallBackText;
};

const DropdownContent = (props) => {
  const { btnState, show, handleSelectedOption } = props;
  const userLanguage = useSelector(getUserLanguage);

  return (
    <>
      <div className={`dropdown-content ${show && 'dropdown-content--show'}`}>
        {btnState.options.map((option) => (
          <button
            key={option.value}
            className='dropdown-content__item'
            // 'click-disabled': option.availableUnitCount === 0
            onClick={() => handleSelectedOption(option.value)}>
            <span
              className={`${
                option.value === btnState.selectedValue && 'font-accent-bold'
              }`}>
              {getLanguageText(userLanguage, option.text)}
            </span>
            <small className='dropdown-content__item-desc'>
              <span className='f-bold'>
                {option.availableUnitCount === 0
                  ? getLanguageText(userLanguage, 'no')
                  : option.availableUnitCount}
              </span>{' '}
              <span>{getLanguageText(userLanguage, 'units')}</span>{' '}
              {getLanguageText(userLanguage, 'Available')}
            </small>
          </button>
        ))}
      </div>
    </>
  );
};

export default DropdownContent;
