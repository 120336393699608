import SummaryCompoent from 'apps/booking/components/common/admin/SummaryCompoent';
import { IconNames } from 'apps/booking/components/common/Icon';

import styles from './styles.module.css';

export interface ISummaryData {
  details: {
    mainData: string;
    changeData: string;
    changeDataClass: string;
    changeDesc: string;
    loaded: boolean;
  };
  titleIcon: IconNames;
  title: string;
}

interface IAnalyticsWrapperProps {
  summaryData: ISummaryData[];
}

const AnalyticsWrapper = (props: IAnalyticsWrapperProps) => {
  const { summaryData } = props;
  return (
    <div className={styles.metricsWrapper}>
      {summaryData.map((summary) => (
        <div className='project-overview-analytics__total-demos'>
          <SummaryCompoent
            titleIcon={summary.titleIcon}
            title={summary.title}
            {...summary.details}
          />
        </div>
      ))}
    </div>
  );
};

export default AnalyticsWrapper;
