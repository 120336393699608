import { ICellRendererParams } from 'ag-grid-community';
import { DEFAULT_NULL_VALUE } from 'apps/admin/components/common/ExpandedDetailSection';

import { IPartnerFirm } from '..';

import styles from './styles.module.css';

const ExpandedFirmDetails = (props: ICellRendererParams) => {
  const firmDetails: IPartnerFirm = props?.data;

  const firmDetailsItems = !firmDetails
    ? []
    : [
        {
          label: 'Phone Number',
          value: firmDetails.pocMobile,
        },
        {
          label: 'Email id',
          value: firmDetails.pocEmail,
        },
        // {
        //   label: 'Firm Formation',
        //   value: '--',
        // },
        {
          label: 'PAN Number',
          value: firmDetails.panNumber,
        },
        {
          label: 'GST Number',
          value: firmDetails.gstNumber,
        },
        // {
        //   label: 'List of CP firm',
        //   value: '--',
        // },
        // {
        //   label: 'List Of Projects CP firm',
        //   value: '--',
        // },
        {
          label: 'Firm Url',
          value: firmDetails.websiteUrl,
        },
        {
          label: 'Total CPs',
          value: firmDetails.totalCPs,
        },
      ];

  return (
    <div className={styles.allDetailsContainer}>
      {firmDetailsItems.map((firmDetails) => (
        <div
          key={firmDetails.label}
          className={styles.individualDetailContainer}>
          <div className={styles.detailLabel}>{firmDetails.label}</div>

          <p className={styles.detailValue}>
            {firmDetails.value ? firmDetails.value : DEFAULT_NULL_VALUE}
          </p>
        </div>
      ))}
    </div>
  );
};

export default ExpandedFirmDetails;
