// Components
import Icon from 'apps/booking/components/common/Icon/index';

// Interfaces
import { IconNames } from 'apps/booking/components/common/Icon/index';

// Styles
import styles from './styles.module.css';
import genericStyles from '../../../../../styles/genericStyles.module.css';

// Local Interfaces
interface IIconWithLabel {
  iconName: IconNames;
  label: string;
  disabled?: boolean;
  propStyles?: string;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
}

const IconWithLabel = (props: IIconWithLabel) => {
  const { iconName, label, disabled, propStyles = '', onClick } = props;
  return (
    <div
      className={`${genericStyles.pointer} ${styles.iconWithLabelContainer} ${
        disabled ? styles.disabled : ''
      }`}
      onClick={onClick}>
      <Icon
        propStyles={`${styles.icon} ${propStyles}`}
        disabled={disabled}
        name={iconName}
      />
      <p className={`${styles.label} ${propStyles}`}>{label}</p>
    </div>
  );
};

export default IconWithLabel;
