import { Suspense, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Switch, Redirect, Route, useHistory } from 'react-router-dom';

import { AnimatePresence, motion } from 'framer-motion';

import { URLS } from 'constants/urls';
import { NOT_FOUND } from 'constants/status';
import ContentLayer from 'apps/booking/layoutBluePrints/ContentLayer';
import { useLazyGetUnitCartQuery } from 'api/unitCart';
import { useGetCpUserByMobileMutation } from 'apps/cp/services/cpUserAPISlice';
import { getUser } from 'apps/booking/slices/auth';
import { getSelectedUnit } from 'apps/booking/slices/selectedUnit';
import { getCpUser, removeCpUser } from 'apps/cp/slices/cpUserSlice';
import { removeAuthTokenLocal } from 'utils/localStorage';
import { removeCpUserLocal } from 'apps/cp/components/pages/CpLoginPage/cpLocalStorage';
import FormRoutes from 'apps/admin/routers/FormRoutes';
import CommonAdminComponents from 'apps/booking/components/CommonAdminComponents';
import ToastContainer from 'apps/booking/components/common/toast/ToastContainer';
import CpContentLayer from 'apps/cp/layoutBluePrints/CpContentLayer/index';
import { getRelataAppType } from 'apps/admin/components/pages/ExecutiveDashboard/utils';
import AdminLogin from 'apps/admin/components/pages/AdminLogin';
import AdminLayer from 'apps/admin/components/layoutBluePrints/AdminLayer';
import FileViewer from 'apps/cp/components/pages/FileViewer';
import { ICpUser } from 'apps/cp/interfaces';

const Routes = () => {
  const [getUnitCartAPI] = useLazyGetUnitCartQuery();
  const [getCpUserByMobileAPI] = useGetCpUserByMobileMutation();
  const user = useSelector(getUser);
  const unit = useSelector(getSelectedUnit);
  const cpUser = useSelector(getCpUser) as ICpUser;
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    if (user?.id && unit?.id) {
      // Fetch unit cart for first time
      getUnitCartAPI({ unit_id: unit.id, user_id: user.id });
    }
  }, [user?.id, unit?.id, getUnitCartAPI]);

  const logoutUser = () => {
    removeAuthTokenLocal();
    removeCpUserLocal();
    dispatch(removeCpUser());
  };

  const handleIsValidCpUser = async () => {
    try {
      await getCpUserByMobileAPI({
        address: cpUser.user.mobile,
        addressType: 'MOBILE',
      }).unwrap();
    } catch (err) {
      const errCode = (err as any).data.code;
      if (errCode === NOT_FOUND) {
        logoutUser();
        history.push('/cp/kyc/login');
      }
    }
  };

  useEffect(() => {
    if (cpUser?.id) {
      handleIsValidCpUser();
    }
    return () => {};
  }, []);

  const SuspenseLoading = () => {
    const [show, setShow] = useState(false);
    useEffect(() => {
      let timeout = setTimeout(() => setShow(true), 300);
      return () => {
        clearTimeout(timeout);
      };
    }, []);

    return (
      <>
        <AnimatePresence>
          {show && (
            <motion.div
              key='loading'
              style={{ width: '100%', height: '100%' }}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.4 }}>
              <div
                style={{
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  alignContent: 'center',
                  justifyContent: 'center',
                }}>
                Please wait while we load the experience for you...
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </>
    );
  };

  return (
    <>
      <AnimatePresence>
        <Suspense fallback={<SuspenseLoading />}>
          <Switch>
            <Route path='/login' component={AdminLogin} />

            {getRelataAppType() != 'RELATA-SV' && (
              <Route path='/admin' component={AdminLayer} />
            )}

            <Route path='/forms' component={FormRoutes} />

            <Route
              path='/common-admin-components'
              component={CommonAdminComponents}
            />

            {/* Relata CP - Channel Partner(Broker) */}
            <Route path='/cp'>
              <CpContentLayer />
            </Route>

            {/* TODO: Add inside BookingRoutes */}
            <Route path={[URLS.DASHBOARD_BASE, URLS.BOOKING_BASE]}>
              <ContentLayer />
            </Route>

            {/*   To view the collateral share file  */}
            <Route path='/viewer' component={FileViewer} />

            {/* Unmatched url redirect to admin dashboard or login page base on if user has already logged in*/}
            {getRelataAppType() === 'RELATA-SV' ? (
              <Redirect to={URLS.SITE_VISIT_FORM} />
            ) : (
              <Redirect to={URLS.ADMIN} />
            )}
          </Switch>
        </Suspense>
      </AnimatePresence>
      <ToastContainer />
    </>
  );
};

export default Routes;
