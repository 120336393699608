import { useState, useRef } from 'react';

import useOnClickOutside from 'utils/useOnClickOutside';
import Icon from 'apps/booking/components/common/Icon/index';
import { IconNames } from 'apps/booking/components/common/Icon';

import styles from './styles.module.css';
import genericStyles from '../../../../../styles/genericStyles.module.css';

export type MenuItem = {
  displayText: string;
  icon: IconNames;
  onClick: React.MouseEventHandler<HTMLDivElement>;
};

// Local Interfaces
interface IMoreMenu {
  menuItems: MenuItem[];
  disabled?: boolean;
  menuPosition?: 'right' | 'left';
}

const MoreMenu = (props: IMoreMenu) => {
  const { menuItems, disabled = false, menuPosition = 'left' } = props;

  const [menuShown, setMenuShown] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);

  //For Closing menu when clicked outside
  useOnClickOutside(menuRef, () => {
    return setMenuShown(false);
  });

  return (
    <div className={styles.moreMenuContainer}>
      {/* Menu Options */}
      {menuShown && (
        <div
          ref={menuRef}
          className={`${styles.MenuContainer} ${styles[menuPosition]}`}>
          {menuItems.map((menuItem) => (
            <div key={menuItem.displayText}>
              <div
                className={`${genericStyles.pointer} ${styles.individualMenuItem}`}
                onClick={menuItem.onClick}>
                <p className={styles.moreIndividualMenuLabel}>
                  {menuItem.displayText}
                </p>
                <Icon
                  name={menuItem.icon as IconNames}
                  propStyles={styles.moreIndividualMenuIcon}
                />
              </div>
            </div>
          ))}
        </div>
      )}
      {/* 3 dots menu toogler Icon in Grid */}
      <div
        className={`${styles.moreMenuIconContainer} ${genericStyles.pointer} ${
          disabled ? styles.disabled : ''
        }`}>
        <Icon
          name='more_vert'
          propStyles={`${styles.moreMenuIcon}`}
          disabled={disabled}
          onClick={() => setMenuShown(!menuShown)}
        />
      </div>
    </div>
  );
};

export default MoreMenu;
