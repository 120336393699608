import { IProject } from 'apps/admin/components/pages/SuperAdminDashboard/SuperAdminProjectList';
import ActiveFirmList from 'apps/admin/components/pages/ProjectFirmPartners/ActiveFirmList';
import AllFirmList from 'apps/admin/components/pages/ProjectFirmPartners/AllFirmList';
import DesktopTabs from 'apps/admin/components/common/DesktopTabs';
import { ITab } from 'apps/cp/components/common/Tabs';
import { IPartnerFirm } from '../AllFirms';
import AnalyticsWrapper, {
  ISummaryData,
} from 'apps/admin/components/common/AnalyticsWrapper';
import { useGetProjectPartnerFirmsCountQuery } from 'apps/admin/services/companyAPISlice';

import styles from './styles.module.css';

interface IProjectFirmPartnersProps {
  project: IProject;
  onProjectUpdate: (updateProject: IProject) => void;
}

interface IFirmDetails {
  activeFirms: IPartnerFirm[];
  registerFirms: IPartnerFirm[];
  pendingFirms: IPartnerFirm[];
  allFirms: IPartnerFirm[];
  activeFirmsCount: number;
  registerFirmsCount: number;
  pendingFirmsCount: number;
  allFirmsCount: number;
}

const ProjectFirmPartners = (props: IProjectFirmPartnersProps) => {
  const { project, onProjectUpdate } = props;

  const { data: activeFirmCount = 0, isLoading: activeFirmCountLoaded } =
    useGetProjectPartnerFirmsCountQuery({
      projectId: project.id,
      searchField: '',
    });

  const { data: registerFirmCount = 0, isLoading: registerFirmCountLoaded } =
    useGetProjectPartnerFirmsCountQuery({
      projectId: project.id,
      searchField: 'register',
    });

  const { data: pendingFirmCount = 0, isLoading: pendingFirmCountLoaded } =
    useGetProjectPartnerFirmsCountQuery({
      projectId: project.id,
      searchField: 'pending',
    });

  const { data: allfirmsCount = 0, isLoading: allFirmsCountLoaded } =
    useGetProjectPartnerFirmsCountQuery({
      projectId: project.id,
      searchField: 'all',
    });

  const tabs: ITab[] = [
    // TODO: to impliment when required.
    // {
    //   id: 'newFirm',
    //   name: 'New Firms',
    //   content: <NewFirmList firmList={partnerFirms} isLoading={isLoading} />,
    //   disabled: false,
    // },
    {
      id: 'activeFirm',
      name: 'Active Firms',
      content: (
        <ActiveFirmList
          projectId={project.id}
          firmCount={activeFirmCount}
          countLoaded={activeFirmCountLoaded}
        />
      ),
      disabled: false,
    },
    {
      id: 'registerFirm',
      name: 'Registered Firms',
      content: (
        <AllFirmList
          projectId={project.id}
          partnerFirmType='register'
          firmCount={registerFirmCount}
          countLoaded={registerFirmCountLoaded}
        />
      ),
      disabled: false,
    },
    {
      id: 'pendingFirm',
      name: 'Pending Firms',
      content: (
        <AllFirmList
          projectId={project.id}
          partnerFirmType='pending'
          firmCount={pendingFirmCount}
          countLoaded={pendingFirmCountLoaded}
        />
      ),
      disabled: false,
    },
    {
      id: 'allFirm',
      name: 'All Firms',
      content: (
        <AllFirmList
          projectId={project.id}
          partnerFirmType='all'
          firmCount={allfirmsCount}
          countLoaded={allFirmsCountLoaded}
        />
      ),
      disabled: false,
    },
  ];

  const leadsCountSummay: ISummaryData[] = [
    {
      details: {
        mainData: `${activeFirmCount}`,
        changeData: '',
        changeDataClass: '',
        changeDesc: '',
        loaded: activeFirmCountLoaded,
      },
      titleIcon: 'group',
      title: 'Active Firms',
    },
    {
      details: {
        mainData: `${registerFirmCount}`,
        changeData: '',
        changeDataClass: '',
        changeDesc: '',
        loaded: registerFirmCountLoaded,
      },
      titleIcon: 'group',
      title: 'Registered Firms',
    },
    {
      details: {
        mainData: `${pendingFirmCount}`,
        changeData: '',
        changeDataClass: '',
        changeDesc: '',
        loaded: pendingFirmCountLoaded,
      },
      titleIcon: 'group',
      title: 'Pending Firms',
    },
    {
      details: {
        mainData: `${allfirmsCount}`,
        changeData: '',
        changeDataClass: '',
        changeDesc: '',
        loaded: allFirmsCountLoaded,
      },
      titleIcon: 'group',
      title: 'All Firms',
    },
  ];

  return (
    <>
      <AnalyticsWrapper summaryData={leadsCountSummay} />

      <div className={styles.tabsContainer}>
        <DesktopTabs tabs={tabs} />
      </div>
    </>
  );
};

export default ProjectFirmPartners;
