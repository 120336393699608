import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import { GridReadyEvent, ICellRendererParams } from 'ag-grid-community';

import TextInput from 'apps/booking/components/common/inputs/TextInput';
import { useGetProjectInventoryByProjectIdQuery } from 'apps/admin/services/projectLeadsAPI';
import ToggleShowHideCell from 'apps/admin/components/pages/ProjectInventory/ToggleShowHideCell';
import { getUnitList, setUnitList } from 'apps/admin/slices/projectAdminSlice';

import styles from './styles.module.css';

interface IProjectInventoryProps {
  projectId: string;
}

const ProjectInventory = (props: IProjectInventoryProps) => {
  const { projectId } = props;

  const dispatch = useDispatch();
  const inventoryList = useSelector(getUnitList);

  const { data: inventory_list = [], isLoading } =
    useGetProjectInventoryByProjectIdQuery(projectId);

  useEffect(() => {
    dispatch(setUnitList(inventory_list));
  }, [inventory_list]);

  const [gridApi, setGridApi] = useState(null) as any;

  const onGridReady = (params: GridReadyEvent) => {
    setGridApi(params.api);
  };

  const handleGridSearch = (searchString: string) => {
    gridApi?.setQuickFilter(searchString);
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className={styles.inventoryWrapper}>
      <header>
        <div>
          <h2 className={styles.modalHeading}>List of Inventory</h2>
          <p className={styles.modalDesc}>
            Following users get access to all project of
          </p>
        </div>
        <div className={styles.searchContainer}>
          <TextInput
            type='text'
            inputHTMLElementStyles={styles.searchInput}
            placeHolder='Search Unit Name, Sector & Status'
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              handleGridSearch(event.target.value);
            }}
            startIconName='search'
            startIconVariant='SECONDARY'
          />
        </div>
      </header>
      <div
        className={`ag-theme-alpine ${styles.gridContainer}`}
        style={{ width: '100%', height: '100%' }}>
        <AgGridReact
          rowData={inventoryList}
          defaultColDef={{
            sortable: false,
            filter: false,
            resizable: false,
            suppressMenu: true,
          }}
          domLayout={'autoHeight'}
          pagination={true}
          paginationPageSize={10}
          masterDetail={true}
          animateRows={true}
          detailCellRenderer={'detailCellRenderer'}
          detailRowAutoHeight={true}
          onGridReady={onGridReady}>
          <AgGridColumn
            flex={2}
            field='number'
            headerName='Unit Name'
            cellRendererFramework={(params: ICellRendererParams) => (
              <p className={styles.cpNameBold}>{params.data.number}</p>
            )}
          />
          <AgGridColumn
            flex={2}
            headerName='Sector'
            field='towerName'
            cellRendererFramework={(params: ICellRendererParams) => (
              <p className={styles.cpNameBold}>{params.data.towerName}</p>
            )}
          />
          <AgGridColumn
            flex={2}
            headerName='Super Built Up'
            field='area.value'
            cellRendererFramework={(params: ICellRendererParams) => (
              <p className={styles.cpNameBold}>{params.data.area.value}</p>
            )}
          />
          <AgGridColumn
            flex={2}
            headerName='Status'
            field='status'
            cellRendererFramework={(params: ICellRendererParams) => (
              <p className={styles.cpName}>{params.data.status}</p>
            )}
          />
          <AgGridColumn
            headerName='Show/Hide'
            flex={1.5}
            cellRendererFramework={ToggleShowHideCell}
            cellRendererParams={(params: ICellRendererParams) => {
              return {
                rowData: params,
              };
            }}></AgGridColumn>
        </AgGridReact>
      </div>
    </div>
  );
};

export default ProjectInventory;
