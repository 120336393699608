import SummaryCompoent from 'apps/booking/components/common/admin/SummaryCompoent';

import styles from './styles.module.css';

interface ISummaryData {
  mainData: string;
  changeData: string;
  changeDataClass: string;
  changeDesc: string;
}

interface IAnalyticsWrapperProps {
  activePartnersSummary: ISummaryData;
  activeLeadsSummary: ISummaryData;
  collateralSharedSummary: ISummaryData;
}

const AnalyticsWrapper = (props: IAnalyticsWrapperProps) => {
  const { activeLeadsSummary, activePartnersSummary, collateralSharedSummary } =
    props;
  return (
    <div className={styles.metricsWrapper}>
      <div className='project-overview-analytics__total-demos'>
        <SummaryCompoent
          titleIcon='group'
          title='Active Partners'
          {...activePartnersSummary}
        />
      </div>
      <div className='project-overview-analytics__total-projects'>
        <SummaryCompoent
          titleIcon='group'
          title='Active Leads'
          {...activeLeadsSummary}
        />
      </div>
      <div className='project-overview-analytics__total-projects'>
        <SummaryCompoent
          titleIcon='group'
          title='Collateral Shared'
          {...collateralSharedSummary}
        />
      </div>
    </div>
  );
};

export default AnalyticsWrapper;
