interface IClubRhodiumCompanyIds {
  [key: string]: string;
}
const clubRhodiumCompanyIds: IClubRhodiumCompanyIds = {
  local: '634662369095431000046e5a',
  development: '634662369095431000046e5a',
  staging: '6367e07a24a66b547861db6d',
  production: '6367e07a24a66b547861db6d',
};

if (!process.env.REACT_APP_ENV) {
  console.error('REACT_APP_ENV not declared');
}

const appEnv = process.env.REACT_APP_ENV || 'local';

export const CLUB_RHODIUM_COMPANY_ID = clubRhodiumCompanyIds[appEnv];
