import { useEffect, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { ReactSVG } from 'react-svg';
import ClipLoader from 'react-spinners/ClipLoader';

import {
  getSelectedSection,
  getSelectedTower,
  setSelectedUnitConfig,
  getSelectedBlock,
  DEFAULT_SELECTED_BLOCK,
  getHierarchyUnitList,
  getUnitListById,
} from 'apps/booking/slices/unitSelectionHeader/unitSelectionHeaderNav';
import { URLS, s3BucketName } from 'constants/urls';
import { isEmpty } from 'utils/utils';
import { getConfigDetailsFromPath } from 'apps/booking/components/unitSelection/utils';
import { getProject } from 'apps/booking/slices/projectSlice';
import { UNIT_STATUSES, UnitAvailableStatus } from 'constants/status';
import { setBookingRoutesCurrentPage } from 'apps/booking/routers/BookingRoutes/slice';
import Icon from 'apps/booking/components/common/Icon';

const TowerSVG = (props) => {
  const { selectedSection } = props;
  const [selectedSectionSvgUrl, setSelectedSectionSvgUrl] = useState('');
  const [tooltipContent, setTooltipContent] = useState('');
  const [zoomLevel, setZoomLevel] = useState(1);
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
  const dispatch = useDispatch();
  const history = useHistory();

  const hierarchyUnitList = useSelector(getHierarchyUnitList);
  const unitListById = useSelector(getUnitListById);
  const towerName = useSelector(getSelectedTower);
  const section = useSelector(getSelectedSection);
  const block = useSelector(getSelectedBlock);
  const project = useSelector(getProject);
  const projectId = project.id;

  const maxZoomLevel = 2;
  const isZoomInDisabled = zoomLevel >= maxZoomLevel;
  const isZoomOutDisabled = zoomLevel <= 1;
  const svgStyle = {
    transform: `scale(${zoomLevel})`,
    transformOrigin: 'top left',
  };

  const handleZoomIn = () => {
    if (zoomLevel < maxZoomLevel) {
      setZoomLevel(zoomLevel + 0.2);
    }
  };

  const handleZoomOut = () => {
    if (zoomLevel > 1) {
      setZoomLevel(zoomLevel - 0.2);
    }
  };

  function isUnitsAvaialbeForConfig(
    towerName,
    section,
    blockName,
    unitNumberDigit = '1'
  ) {
    var avilableUnitsCtn = 0;
    // TODO - invalid section should not have been set
    let configArray =
      hierarchyUnitList?.[towerName.toLowerCase()]?.[section.toLowerCase()]?.[
        unitNumberDigit
      ];

    if (!configArray) {
      return avilableUnitsCtn;
    }

    // TODO: Bad code - need to put explicitly - .toLowerCase()
    return configArray.some(
      (unitId) =>
        (unitListById[unitId].blockName.toLowerCase() === blockName ||
          !unitListById[unitId].blockName ||
          blockName === DEFAULT_SELECTED_BLOCK) &&
        unitListById[unitId].status === UNIT_STATUSES.AVAILABLE &&
        unitListById[unitId].publishedOnPortal === true
    );
  }

  const updateSvgPathBehaviourWrapper = useCallback(
    (root, towerName, section) => {
      const selectSector = (configPath) => {
        dispatch(setSelectedUnitConfig(configPath));
        dispatch(setBookingRoutesCurrentPage(URLS.UNIT_DETAILS));
      };

      const updateSvgPathBehaviour = (root = document, towerName, section) => {
        const polygons = root.querySelectorAll('svg polygon');
        const paths = root.querySelectorAll('svg path');

        const applyMousePointerEvents = (paths, polygons) => {
          paths.forEach((path) => {
            path.addEventListener('mouseenter', handleMouseEnter);
            path.addEventListener('mousemove', handleMouseMove);
            path.addEventListener('mouseleave', handleMouseLeave);
          });

          polygons.forEach((polygon) => {
            polygon.addEventListener('mouseenter', handleMouseEnter);
            polygon.addEventListener('mousemove', handleMouseMove);
            polygon.addEventListener('mouseleave', handleMouseLeave);
          });
        };

        const handleMouseEnter = (event) => {
          const { id } = event.target;
          const selectedUnitId = id.replace('a-', '').split('-x-');
          setTooltipContent(selectedUnitId[2]);
        };

        const handleMouseMove = (event) => {
          const { layerX, layerY } = event;
          const offset = 25;
          setTooltipPosition({
            x: layerX - offset,
            y: layerY + offset,
          });
        };

        const handleMouseLeave = () => {
          setTooltipContent('');
        };

        applyMousePointerEvents(paths, polygons);

        const applyStyleOnPathElm = (pathElm, towerName, section) => {
          const configPath = pathElm.id;

          const { blockName, unitNumberDigit } = getConfigDetailsFromPath(
            configPath,
            project
          );

          const isAvailableUnit = isUnitsAvaialbeForConfig(
            towerName,
            section,
            blockName,
            unitNumberDigit
          );

          if (isAvailableUnit) {
            pathElm.classList.add('unit');
            pathElm.addEventListener('click', () => selectSector(pathElm.id));
          }
        };

        for (let pathElm of polygons) {
          applyStyleOnPathElm(pathElm, towerName, section);
        }

        for (let pathElm of paths) {
          applyStyleOnPathElm(pathElm, towerName, section);
        }
      };
      updateSvgPathBehaviour(root, towerName, section);
    },
    [hierarchyUnitList, unitListById, dispatch, history]
  );

  useEffect(() => {
    updateSvgPathBehaviourWrapper(undefined, towerName, section);
  }, [updateSvgPathBehaviourWrapper, towerName, section]);

  useEffect(() => {
    if (selectedSection?.length && projectId?.length) {
      const cacheBuster = Math.random();
      setSelectedSectionSvgUrl(
        `https://${s3BucketName}.s3.ap-south-1.amazonaws.com/projects/${projectId}/floorPlans/${selectedSection.toLowerCase()}.2d?cache=${cacheBuster}`
      );
    }
  }, [selectedSection, projectId]);

  if (isEmpty(hierarchyUnitList)) {
    return null;
  }

  return (
    <>
      <div className='controlsWrap'>
        <Icon
          onClick={handleZoomIn}
          name='zoom_in'
          propStyles='zoomIcon zoomIn'
          disabled={isZoomInDisabled}
        />
        <Icon
          onClick={handleZoomOut}
          name='zoom_out'
          propStyles='zoomIcon zoomOut'
          disabled={isZoomOutDisabled}
        />
      </div>
      <ReactSVG
        src={selectedSectionSvgUrl}
        loading={() => <ClipLoader />}
        id='svg'
        className='svg'
        style={svgStyle}
        beforeInjection={(svg) => {
          svg.removeAttribute('height');
          updateSvgPathBehaviourWrapper(svg, towerName, section);
        }}
      />
      {tooltipContent && (
        <div
          className='tooltip'
          style={{
            top: tooltipPosition.y,
            left: tooltipPosition.x,
          }}>
          <p
            className={`toolTip_bold ${
              !isUnitsAvaialbeForConfig(
                towerName,
                section,
                block,
                tooltipContent
              ) && 'disable'
            }`}>
            Unit:&nbsp;
            <span className='toolTip_bold'>{tooltipContent.toUpperCase()}</span>
          </p>
          <p className='smallText_tooltip'>
            {isUnitsAvaialbeForConfig(towerName, section, block, tooltipContent)
              ? UnitAvailableStatus.AVAILABLE
              : UnitAvailableStatus.UNAVAILABLE}
          </p>
        </div>
      )}
    </>
  );
};

export default TowerSVG;
