import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import ProjectSelection from 'apps/admin/components/common/ProjectSelection';
import DetailsForm, {
  DetailsFormState,
} from 'apps/admin/components/pages/forms/SiteVisit/DetailsForm/index';
import SiteVisitSummery from 'apps/admin/components/pages/forms/SiteVisit/SiteVisitSummery';
import { MenuItem } from 'apps/admin/components/common/MoreMenu';
import {
  IMinMaxRange,
  IProject,
} from 'apps/admin/components/pages/SuperAdminDashboard/SuperAdminProjectList';
import { useLazyGetCpLeadByIdQuery } from 'apps/cp/services/cpUserAPISlice';
import {
  DEFAULT_PROPERTY_CONFIGS,
  DEFAULT_PROPERTY_PRICE_RANGE,
} from 'constants/index';
import { TObjectId } from 'interfaces';
import { useDispatch, useSelector } from 'react-redux';
import { getAdminUser } from 'apps/admin/slices/adminSlice';
import QrCodeScanner from 'apps/admin/components/pages/forms/SiteVisit//QrCodeScanner';
import useAdminLogOut from 'apps/admin/components/pages/AdminLogin/hooks/useAdminLogOut';
import { setAppSliceIsFetching } from 'slices/appSlice';
import { ICpPreview } from './DetailsForm/AssingedCpInfo';

import styles from './styles.module.css';

export enum SiteVisitState {
  PROJECT_SELECTION,
  DETAILS_FORM,
  SUMMERY,
  QRCODE_SCANNER,
}

export interface ISiteVisitDetails {
  customerName: string;
  customerEmail: string;
  customerMobile: string;
  cpLeadIds: TObjectId[];
  executiveId: TObjectId;
  projectId: TObjectId;

  location: string;

  configs: string[];
  preferenceAreasTemp: string[];
  priceRange?: IMinMaxRange;

  customerFeedback?: string;
}

export interface IQrScanDetails {
  cpLeadId: TObjectId;
  cpUserId: TObjectId;
}

export interface IFullScreenHandle {
  active: boolean;
  // Specifies if attached element is currently full screen.

  enter: () => Promise<void>;
  // Requests this element to go full screen.

  exit: () => Promise<void>;
  // Requests this element to exit full screen.

  node: React.MutableRefObject<HTMLDivElement | null>;
  // The attached DOM node
}

const SiteVisit = () => {
  const [siteVisitState, setSiteVisitState] = useState<SiteVisitState>(
    SiteVisitState.PROJECT_SELECTION
  );

  const [siteVisitDetails, setSiteVisitDetails] = useState({
    customerName: '',
    customerEmail: '',
    customerMobile: '',
    projectId: '',

    location: '',
    preferenceAreasTemp: [''],
    customerFeedback: '',
  } as ISiteVisitDetails);
  const [qrScanDetails, setQrScanDetails] = useState({
    cpLeadId: '',
    cpUserId: '',
  });

  const [selectedProject, setSelectedProject] = useState<IProject>();
  const [cpLeadId, setCpLeadId] = useState<TObjectId>();
  const [detailsFormState, setDetailsFormState] = useState(
    DetailsFormState.CUSTOMER
  );
  const [contendingCpLeadIds, setContendCpLeadIds] = useState<TObjectId[]>([]);
  const [assignedCpPreview, setAssignedCpPreview] = useState<ICpPreview>();

  const history = useHistory();
  const handleFullScreen = useFullScreenHandle();

  const adminUser = useSelector(getAdminUser);
  const dispatch = useDispatch();
  const { removeAdminUserLocal, logginOut } = useAdminLogOut();

  useEffect(() => {
    dispatch(setAppSliceIsFetching(logginOut));
  }, [logginOut]);

  const urlParams = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop: string) => searchParams.get(prop),
  }) as any;
  const { li: _cpLeadId } = urlParams;
  const [getCpLeadByIdAPI, { data: cpLead, isLoading }] =
    useLazyGetCpLeadByIdQuery();

  useEffect(() => {
    setCpLeadId(_cpLeadId);
  }, [_cpLeadId]);

  useEffect(() => {
    if (cpLeadId) {
      getCpLeadByIdAPI({ cpLeadId });
    }
  }, [cpLeadId]);

  useEffect(() => {
    cpLead &&
      setSiteVisitDetails((prev) => {
        return {
          ...prev,
          configs: cpLead.propertyConfigs,
          preferenceAreasTemp: cpLead.preferenceAreasTemp,
          executiveId: adminUser?.id as TObjectId,
        };
      });
  }, [cpLead]);

  useEffect(() => {
    cpLead &&
      setQrScanDetails((prev) => {
        return {
          ...prev,
          cpLeadId: cpLead.id,
          cpUserId: cpLead.cpUser.id,
        };
      });
  }, [cpLead]);

  useEffect(() => {
    setSiteVisitDetails((prev) => {
      return {
        ...prev,
        executiveId: adminUser?.id as TObjectId,
      };
    });
  }, [adminUser?.id]);

  const headerMoreMenuOptions = [
    {
      displayText: handleFullScreen.active ? 'Exit Fullscreen' : 'Fullscreen',
      icon: handleFullScreen.active ? 'fullscreen_exit' : 'fullscreen',
      onClick: () => {
        handleFullScreen.active
          ? handleFullScreen.exit()
          : handleFullScreen.enter();
      },
    },
    {
      displayText: 'Log Out',
      icon: 'logout',
      onClick: async () => {
        await removeAdminUserLocal();
        history.push('/forms/site-visit');
      },
    },
  ] as MenuItem[];

  const handleProjectSelection = (project?: IProject) => {
    if (project) {
      setSiteVisitDetails((prev) => {
        return {
          ...prev,
          projectId: project.id,
          customerFeedback: '',
        } as ISiteVisitDetails;
      });
      setSelectedProject(project);
      setSiteVisitState(SiteVisitState.DETAILS_FORM);
      return;
    }
    setSelectedProject(undefined);
  };

  const handleQrCodeCpLeadIdDetect = (cpLeadId: TObjectId) => {
    setCpLeadId(cpLeadId);
  };

  const projectPropertyConfigs = selectedProject?.configs.length
    ? selectedProject?.configs
    : DEFAULT_PROPERTY_CONFIGS;
  const projectPropertyPriceRanges = selectedProject?.priceRanges.length
    ? selectedProject?.priceRanges
    : DEFAULT_PROPERTY_PRICE_RANGE;
  return (
    <FullScreen
      handle={handleFullScreen}
      className={styles.fullscreenContainer}>
      {siteVisitState === SiteVisitState.QRCODE_SCANNER && (
        <QrCodeScanner
          setSiteVisitState={setSiteVisitState}
          setDetailsFormState={setDetailsFormState}
          onQrCodeCpLeadIdDetect={handleQrCodeCpLeadIdDetect}
          setSiteVisitDetails={setSiteVisitDetails}
          cpLead={cpLead}
          loading={isLoading}
          selectedProject={selectedProject}
          contendingCpLeadIds={contendingCpLeadIds}
          setContendCpLeadIds={setContendCpLeadIds}
          siteVisitDetails={siteVisitDetails}
        />
      )}
      <div className={styles.siteVisitForm}>
        {siteVisitState === SiteVisitState.PROJECT_SELECTION && (
          <ProjectSelection
            project={selectedProject}
            onProjectSelection={handleProjectSelection}
            isSiteVisitForm={true}
          />
        )}

        {siteVisitState === SiteVisitState.DETAILS_FORM && (
          <DetailsForm
            qrScanDetails={qrScanDetails}
            siteVisitDetails={siteVisitDetails}
            setSiteVisitDetails={setSiteVisitDetails}
            setSiteVisitState={setSiteVisitState}
            detailsFormState={detailsFormState}
            setDetailsFormState={setDetailsFormState}
            headerMoreMenuOptions={headerMoreMenuOptions}
            onProjectSelection={handleProjectSelection}
            contendingCpLeadIds={contendingCpLeadIds}
            assignedCpPreview={assignedCpPreview}
            setAssignedCpPreview={setAssignedCpPreview}
            setContendCpLeadIds={setContendCpLeadIds}
          />
        )}

        {siteVisitState === SiteVisitState.SUMMERY && (
          <SiteVisitSummery
            siteVisitDetails={siteVisitDetails}
            assignedCpPreview={assignedCpPreview}
            headerMoreMenuOptions={headerMoreMenuOptions}
          />
        )}
      </div>
    </FullScreen>
  );
};

export default SiteVisit;
