import { useState } from 'react';

import { GridReadyEvent, ICellRendererParams } from 'ag-grid-community';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';

import { IProject } from 'apps/admin/components/pages/SuperAdminDashboard/SuperAdminProjectList';
import AddDemoRoomModal, {
  IDemoRoom,
} from 'apps/admin/components/pages/SuperAdminDashboard/SuperAdminProjectList/modals/AddDemoRoomModal';
import UpdateDemoRoomCell from 'apps/admin/components/pages/SuperAdminDashboard/SuperAdminProjectList/modals/DemoRoomsListModal/UpdateDemoRoomCell';
import RemoveDemoRoomCell from 'apps/admin/components/pages/SuperAdminDashboard/SuperAdminProjectList/modals/DemoRoomsListModal/RemoveDemoRoomCell';
import Button from 'apps/booking/components/common/buttons/Button';
import TextInput from 'apps/booking/components/common/inputs/TextInput';

import styles from './styles.module.css';
import genericStyles from '../../../../../styles/genericStyles.module.css';

export interface IProjectDemoRoomsProps {
  projectDemoRooms: IDemoRoom[];
  onProjectUpdate: (updatedProject: IProject) => void;
  projectName: string;
  projectId: string;
}

const ProjectDemoRooms = (props: IProjectDemoRoomsProps) => {
  const { projectDemoRooms, onProjectUpdate, projectId, projectName } = props;

  const [demoRooms, setDemoRooms] = useState<IDemoRoom[]>(projectDemoRooms);
  const [showAddDemoRoomModal, setShowAddDemoRoomModal] = useState(false);
  const [gridApi, setGridApi] = useState(null) as any;

  //Ag-grid default Column definition
  const defaultColDef = {
    sortable: false,
    filter: false,
    resizable: false,
    suppressMenu: true,
    editable: false,
    flex: 1,
  };

  const handleAddDeleteDemoRooms = async (updatedProject: IProject) => {
    onProjectUpdate(updatedProject);

    setDemoRooms(updatedProject.demoRooms);
  };

  const onGridReady = (params: GridReadyEvent) => {
    setGridApi(params.api);
  };

  //Ag-grid global search
  const handleGridSearch = (searchString: string) => {
    gridApi?.setQuickFilter(searchString);
  };

  return (
    <>
      {showAddDemoRoomModal && (
        <AddDemoRoomModal
          projectName={projectName}
          projectId={projectId}
          onModalClose={() => setShowAddDemoRoomModal(false)}
          onAddDemoRoom={handleAddDeleteDemoRooms}
        />
      )}

      <div className={styles.demoWrapper}>
        {/* Modal Header */}
        <header className={styles.modalHeader}>
          <div>
            <h2 className={styles.modalHeading}>List of Demo Rooms</h2>
            <p className={styles.modalDesc}>
              Following executivesWithDemoRoom get access to all project of
              Runwal developer. You can edit details, reset password or remove
              users from here.
            </p>
          </div>
          <div className={styles.searchContainer}>
            <TextInput
              type='text'
              inputHTMLElementStyles={styles.searchInput}
              placeHolder='Search Room ID'
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                handleGridSearch(event.target.value);
              }}
              startIconName='search'
              startIconVariant='SECONDARY'
            />
          </div>
        </header>

        {/* Modal Body */}
        <main
          className={`ag-theme-alpine ${styles.gridContainer}`}
          style={{ height: '100%', width: '100%' }}>
          <AgGridReact
            rowHeight={60}
            rowData={demoRooms}
            onGridReady={onGridReady}
            defaultColDef={defaultColDef}
            domLayout={'autoHeight'}
            pagination={true}
            paginationPageSize={10}>
            <AgGridColumn
              flex={5}
              headerName='Room ID'
              field='virtualRoomId'
              cellClass='relata-ag-grid-cell-wrap'
              cellRendererFramework={(params: ICellRendererParams) => (
                <p
                  title='Click to Copy'
                  onClick={() => {
                    navigator.clipboard.writeText(`${params.value}`);
                  }}
                  className={`relata-ag-grid-wrappable-text ${genericStyles.onTextHoverAnimation}`}>
                  {params.value}
                </p>
              )}
            />
            <AgGridColumn
              flex={5}
              headerName='Host Password'
              field='hostPassword'
              cellClass='relata-ag-grid-cell-wrap'
              cellRendererFramework={(params: ICellRendererParams) => (
                <p
                  title='Click to Copy'
                  onClick={() => {
                    navigator.clipboard.writeText(`${params.value}`);
                  }}
                  className={`relata-ag-grid-wrappable-text ${genericStyles.onTextHoverAnimation}`}>
                  {params.value}
                </p>
              )}
            />
            <AgGridColumn
              flex={6}
              headerName='Backup Link'
              field='fallbackVirtualRoomUrl'
              cellClass='relata-ag-grid-cell-wrap'
              cellRendererFramework={(params: ICellRendererParams) => (
                <p
                  title='Click to Copy'
                  onClick={() => {
                    navigator.clipboard.writeText(`${params.value}`);
                  }}
                  className={`relata-ag-grid-wrappable-text ${genericStyles.onTextHoverAnimation}`}>
                  {params.value}
                </p>
              )}
            />
            <AgGridColumn
              flex={2}
              headerName='In Use'
              cellClass='relata-ag-grid-cell-wrap'
              cellRendererFramework={(params: ICellRendererParams) => (
                <p className='relata-ag-grid-wrappable-text'>
                  {params.data.isAssignedToExecutive ? 'Yes' : 'No'}
                </p>
              )}
            />
            {/*
                TODO: Add edit pen icon
                TODO: Convert cells into TextInput fields
            */}
            <AgGridColumn
              flex={2}
              headerName='Edit'
              headerClass='relata-ag-grid-header-small-font relata-ag-grid-header-justify-center'
              field='addExe'
              cellClass='relata-ag-grid-cell-justify-center'
              cellRendererFramework={UpdateDemoRoomCell}
              cellRendererParams={(params: ICellRendererParams) => ({
                demoRoom: params.data,
                projectId: projectId,
                onDemoRoomUpdate: onProjectUpdate,
                rowNode: params.node,
              })}
            />
            <AgGridColumn
              flex={2}
              headerName='Remove'
              headerClass='relata-ag-grid-header-small-font relata-ag-grid-header-justify-center'
              field='addExe'
              cellClass='relata-ag-grid-cell-justify-center'
              cellRendererFramework={RemoveDemoRoomCell}
              cellRendererParams={(params: ICellRendererParams) => ({
                projectId: projectId,
                demoRoomId: params.data.id,
                onDemoRoomDelete: handleAddDeleteDemoRooms,
                disabled: params.data.isAssignedToExecutive,
              })}
            />
          </AgGridReact>
        </main>

        {/* Modal Footer */}
        <footer className={styles.footerContainer}>
          <div className={styles.footerLeftContainer}>
            <Button
              onClick={() => {
                setShowAddDemoRoomModal(true);
              }}
              propStyles={styles.addExecutiveButton}>
              Add Demo Rooms
            </Button>
            <p className={styles.disclaimer}>
              Adding an executive will send them an email with their randomly
              generated password. This action will add the executive to all the
              Runwal's projects
            </p>
          </div>
          <div className={styles.executiveCount}>{demoRooms?.length} Rooms</div>
        </footer>
      </div>
    </>
  );
};

export default ProjectDemoRooms;
