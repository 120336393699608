import { useState } from 'react';
import { useSelector } from 'react-redux';

import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import {
  useSendOtpMutation,
  useVerifyOtpMutation,
} from '../../../../../api/auth';
import { useSiteVisitOldMutation } from '../../../../../api/siteVisit';

import { getUser } from '../../../slices/auth';

import {
  CROSS_TICK_ICON,
  EIDT_ICON,
  CORRECT_TICK_ICON,
} from '../../../../../constants/icons';

// Validation
const schema = yup.object().shape({
  fname: yup.string().required(),
  lname: yup.string().required(),
  email: yup.string().email(),
  mobile_no: yup
    .string()
    .matches(/^[0-9]*$/)
    .length(10)
    .required(),
  date: yup.string().required(),
});

const FORM_STATE = {
  DETAILS: 'DETAILS',
  SUMMERY_WITH_OTP: 'SUMMERY_WITH_OTP',
  SUMMERY: 'SUMMERY',
};
const SiteVisitForm = (props) => {
  const { setShowSiteVisitForm } = props;
  const user = useSelector(getUser);
  const [somethingWentWrong, setSomethingWentWrong] = useState('');
  const [requestLoading, setRequestLoading] = useState(false);
  const [formState, setFormState] = useState(FORM_STATE.DETAILS);
  const [otp1, setOtp] = useState('');
  const [isOtpValid, setIsOtpValid] = useState(false);
  const [otpError, setOtpError] = useState('');
  const [formData, setFormData] = useState({
    fname: user.fname || '',
    lname: user.lname || '',
    email: user.email || '',
    mobile_no: user.mobile_no || '',
    date: '',
    time: '12:00',
    project: 'Embassy Edge',
  });

  const {
    register,
    handleSubmit,
    formState: { errors, isValid: isFormValid },
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
  });
  const [sendOtpAPI] = useSendOtpMutation();
  const [verifyOtpAPI] = useVerifyOtpMutation();
  const [siteVisitAPI] = useSiteVisitOldMutation();

  // TODO: Format formData.time="14:52" to "02:52 PM" and "02:52" to "02:52 AM"

  const setFormStateWrap = (val) => {
    setRequestLoading(false);
    setOtpError('');
    setOtp('');
    setIsOtpValid(false);
    setFormState(val);
  };

  const resetOtp = () => {
    setOtpError('');
    setIsOtpValid(false);
  };

  const handleSendOtp = (data) => {
    setFormData(data);

    // TODO: Error handling
    sendOtpAPI({ mobile_no: data.mobile_no });
    resetOtp();
    setFormStateWrap(FORM_STATE.SUMMERY_WITH_OTP);
  };

  const handleOptInputFieldChange = (value) => {
    if (value.length !== 6) {
      setIsOtpValid(false);
      setOtpError('');
      if (value.length > 6) {
        setOtpError('OPT contains only 6 digit');
      }
      return false;
    }

    setOtp(value);
    setOtpError('');
    setIsOtpValid(true);
    return true;
  };

  const handleResendOtp = () => {
    sendOtpAPI({ mobile_no: formData.mobile_no });
    setOtpError('');
  };

  const handleSiteVisitRequest = async () => {
    /*
      Embasy has Email Id as primary key
    */

    const dateTime = new Date(formData.date);
    dateTime.setHours(formData.time.split(':')[0]);
    dateTime.setMinutes(formData.time.split(':')[1]);

    const { error: siteVisitError } = await siteVisitAPI({
      ...formData,
      datetime: dateTime,
    });
    if (siteVisitError) {
      // TODO: For testing purpose, Remove in productions
      setSomethingWentWrong(`Relata SiteVisit : ${siteVisitError}`);
      return false;
    }
    return true;
  };

  const handleConfirmOtp = async () => {
    const {
      data: { otpVerified },
    } = await verifyOtpAPI({
      mobile_no: formData.mobile_no,
      otp: otp1,
    });

    if (otpVerified) {
      if (await handleSiteVisitRequest()) {
        setFormStateWrap(FORM_STATE.SUMMERY);
      }
    } else {
      setOtpError('OTP is wrong');
    }
  };

  const handleConfirmOtpWrap = async () => {
    setRequestLoading(true);
    await handleConfirmOtp();
    setRequestLoading(false);
  };

  const handleContinueBooking = () => {
    setShowSiteVisitForm(false);
  };

  return (
    <>
      {formState === FORM_STATE.DETAILS && (
        <form
          className={`site-visit-form ${
            isFormValid && 'site-visit-form--valid'
          }`}
          onSubmit={handleSubmit(handleSendOtp)}>
          <header className='site-visit-form__header'>
            <span className='site-visit-from__header-title'>
              Fill your details
            </span>
            <span
              className='icon material-icons icon-cross click-enabled'
              onClick={() => setShowSiteVisitForm(false)}>
              {CROSS_TICK_ICON}
            </span>
          </header>
          <main className='site-visit-form__body'>
            <div className='input'>
              <label className='input_label'>First Name</label>
              <input
                type='text'
                className='input_field'
                {...register('fname')}
                defaultValue={formData.fname}
              />

              <span className='input__error'>
                {errors.fname && 'First Name is required'}
              </span>
            </div>
            <div className='input'>
              <label className='input_label'>Last Name</label>
              <input
                type='text'
                className='input_field'
                {...register('lname')}
                defaultValue={formData.lname}
              />
              <span className='input__error'>
                {errors.lname && 'Last Name is required'}
              </span>
            </div>
            <div className='input'>
              <label className='input_label'>Email ID</label>
              <input
                type='text'
                className='input_field'
                {...register('email')}
                defaultValue={formData.email}
              />
              <span className='input__error'>
                {errors.email && 'Email ID is required'}
              </span>
            </div>
            <div className='input'>
              <label className='input_label'>Phone Number</label>
              <input
                type='text'
                className='input_field'
                {...register('mobile_no')}
                defaultValue={formData.mobile_no}
              />
              <span className='input__error'>
                {errors.mobile_no && '10 digit Phone Number is required'}
              </span>
            </div>
            <div className='input-date'>
              <div className='input'>
                <label className='input_label'>Date</label>
                <input
                  type='date'
                  className='input_field'
                  {...register('date')}
                  defaultValue={formData.date}
                />
                <span className='input__error'>
                  {errors.date && 'Date is required'}
                </span>
              </div>
              <div className='input'>
                <label className='input_label'>Preffered Time</label>
                <input
                  type='time'
                  className='input_field'
                  {...register('time')}
                  defaultValue={formData.time}
                />
              </div>
            </div>

            <div className='input'>
              <label className='input_label'>Project</label>
              <input
                type='text'
                className='input_field click-disabled'
                {...register('project')}
                defaultValue={formData.project}
              />
            </div>
          </main>
          <footer className='site-visit-form__footer'>
            <div className='input'>
              <input
                type='submit'
                className={`input_field ${isFormValid && 'click-enabled'} ${
                  !isFormValid && 'click-disabled'
                }`}
                value='SUBMIT'
              />
            </div>
          </footer>
        </form>
      )}
      {formState === FORM_STATE.SUMMERY_WITH_OTP && (
        <div
          className={`site-visit-form site-visit-form--summery-with-otp ${
            isFormValid && 'site-visit-form--valid'
          }`}>
          <header className='site-visit-form__header'>
            <span
              className='icon material-icons icon-edit click-enabled'
              onClick={() => {
                setFormStateWrap(FORM_STATE.DETAILS);
              }}>
              {EIDT_ICON}
            </span>
            <span className='site-visit-from__header-title'>Summary</span>
            <span
              className='icon material-icons icon-cross click-enabled'
              onClick={() => setShowSiteVisitForm(false)}>
              {CROSS_TICK_ICON}
            </span>
          </header>
          <main className='site-visit-form__body'>
            <div className='form-summery-desc'>
              You can check your details & enter OTP received on your mobile to
              confirm your site visit
            </div>
            <div className='form-summery'>
              <div className='form-summery__item'>
                <span className='form-summery__item-label'>Name</span>
                <span className='form-summery__item-value'>{`${formData.fname} ${formData.lname}`}</span>
              </div>
              <div className='form-summery__item'>
                <span className='form-summery__item-label'>Email</span>
                <span className='form-summery__item-value'>
                  {formData.email}
                </span>
              </div>
              <div className='form-summery__item'>
                <span className='form-summery__item-label'>Mobile</span>
                <span className='form-summery__item-value'>
                  {formData.mobile_no}
                </span>
              </div>
              <div className='form-summery__item'>
                <span className='form-summery__item-label'>Date</span>
                <span className='form-summery__item-value'>{`${formData.time} ${formData.date}`}</span>
              </div>
              <div className='form-summery__item'>
                <span className='form-summery__item-label'>Project</span>
                <span className='form-summery__item-value'>
                  {formData.project}
                </span>
              </div>
            </div>
            <div className='input input--otp'>
              <label className='input_label'>Enter 6-digit OTP</label>
              <input
                type='number'
                className='input_field'
                min='100000'
                max='999999'
                onInput={(e) => handleOptInputFieldChange(e.target.value)}
              />

              <span
                className='input__resend-otp click-enabled'
                onClick={() => handleResendOtp()}>
                Resend OTP
              </span>
              <span className='input__error'>{otpError}</span>
            </div>
          </main>
          <footer className='site-visit-form__footer'>
            <div className='input'>
              <button
                className={`input_field 
                  ${isOtpValid && 'click-enabled'}
                  ${!isOtpValid && 'click-disabled'}
                  ${requestLoading && 'loading-spin-container'}
                `}
                onClick={() => handleConfirmOtpWrap()}>
                <span>CONFIRM</span>
                <span className='ic2-fa-spin-blue'></span>
              </button>
            </div>
            {somethingWentWrong}
          </footer>
        </div>
      )}
      {formState === FORM_STATE.SUMMERY && (
        <div className='site-visit-form site-visit-form--summery'>
          <header className='site-visit-form__header'>
            <span className='site-visit-from__header-title'>Summary</span>
            <span
              className='icon material-icons icon-cross click-enabled'
              onClick={() => setShowSiteVisitForm(false)}>
              {CROSS_TICK_ICON}
            </span>

            <span className='icon-container icon-container--correct-tick icon-container--with-background '>
              <span className='icon material-icons icon-correct-tick'>
                {CORRECT_TICK_ICON}
              </span>
            </span>
          </header>
          <main className='site-visit-form__body'>
            <div className='form-summery-desc'>
              Our executive will get in touch with you shortly. Please check
              your email for further details
            </div>
            <div className='form-summery'>
              <header className='form-summery__heading'>
                <span>Summary</span>
              </header>
              <div className='form-summery__item'>
                <span className='form-summery__item-label'>Customer</span>
                <span className='form-summery__item-value'>{`${formData.fname} ${formData.lname}`}</span>
              </div>
              <div className='form-summery__item'>
                <span className='form-summery__item-label'>Project</span>
                <span className='form-summery__item-value'>
                  {formData.project}
                </span>
              </div>

              <div className='form-summery__item'>
                <span className='form-summery__item-label'>Date</span>
                <span className='form-summery__item-value'>{`${formData.time} ${formData.date}`}</span>
              </div>
              <div className='form-summery__item'>
                <span className='form-summery__item-label'>Address</span>
                <span className='form-summery__item-value'>
                  Embassy Springs, Navarathna Agrahara, Karnataka-562157
                </span>
              </div>
            </div>
          </main>
          <footer className='site-visit-form__footer'>
            <div className='input'>
              <input
                type='submit'
                className='input_field click-enabled'
                value='CONTINUE TO BOOKING PROCESS'
                onClick={() => handleContinueBooking()}
              />
            </div>
          </footer>
        </div>
      )}
    </>
  );
};

export default SiteVisitForm;
