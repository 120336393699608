import Modal from 'apps/booking/components/common/modal/Modal';

import styles from '../styles.module.css';

interface IImagePreviewModalProps {
  imageUrl: string;
  onModalClose: () => void;
}

const ImagePreviewModal = (props: IImagePreviewModalProps) => {
  const { imageUrl, onModalClose } = props;

  return (
    <Modal
      onOutsideClick={() => onModalClose()}
      additionalClasses={styles.modalAdditionalClasses}>
      <img src={imageUrl} alt='preview' />
    </Modal>
  );
};

export default ImagePreviewModal;
