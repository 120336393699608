import { useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { getAdminUserRole } from 'apps/admin/components/pages/AdminLogin/utils';
import AdminSidebarItem, {
  IAdminSidebarItemMain,
} from 'apps/admin/components/containers/AdminSidebar/AdminSidebarItem';
import { UserRole } from 'constants/userRoles';
import NavSideBarItems from 'apps/admin/components/containers/AdminSidebar/NavSideBarItems';
import Icon, { IconNames } from 'apps/booking/components/common/Icon';
import useAdminLogOut from 'apps/admin/components/pages/AdminLogin/hooks/useAdminLogOut';
import { setAppSliceIsFetching } from 'slices/appSlice';

interface ICommonUserItem {
  redirectPath: String;
  labelText: string;
  icon: IconNames;
  additionClasses?: String;
  onClick?: () => void;
}

const superUserAdminSidebarItems: IAdminSidebarItemMain[] = [
  {
    menuName: 'Overview',
    tabName: 'Projects',
    submenu: [
      {
        redirectPath: '/admin/projects/analytics',
        labelText: 'Behaviour',
        icon: 'query_stats_outlined',
        additionClasses: 'admin-sidebar-item--enabled',
      },
      // {
      //   redirectPath: '/admin/projects/sitevisits',
      //   labelText: 'Sitevisits',
      //   icon: 'person_add_alt_outlined',
      //   additionClasses: 'admin-sidebar-item--enabled',
      // },
      {
        redirectPath: '/admin/projects/leads',
        labelText: 'Leads',
        icon: 'person_add_alt_outlined',
        additionClasses: 'admin-sidebar-item--enabled',
      },
      {
        redirectPath: '/admin/projects/bookings',
        labelText: 'Bookings',
        icon: 'person_add_alt_outlined',
        additionClasses: 'admin-sidebar-item--enabled',
      },
      {
        redirectPath: '/admin/projects/inventory',
        labelText: 'Inventory',
        icon: 'inventory',
        additionClasses: 'admin-sidebar-item--enabled',
      },
    ],
  },
  {
    menuName: 'Channels',
    tabName: 'Projects',
    submenu: [
      {
        redirectPath: '/admin/projects/partners',
        labelText: 'Partners',
        icon: 'query_stats_outlined',
        additionClasses: 'admin-sidebar-item--enabled',
      },
      {
        redirectPath: '/admin/projects/partnerLeads',
        labelText: 'Partner Leads',
        icon: 'account_circle_outlined',
        additionClasses: 'admin-sidebar-item--enabled',
      },
      {
        redirectPath: '/admin/projects/partnerFirms',
        labelText: 'Partner Firms',
        icon: 'account_circle_outlined',
        additionClasses: 'admin-sidebar-item--enabled',
      },
      {
        redirectPath: '/admin/projects/partnerSitevisits',
        labelText: 'Partner Sitevisits',
        icon: 'account_circle_outlined',
        additionClasses: 'admin-sidebar-item--enabled',
      },
      {
        redirectPath: '/admin/projects/directSitevisits',
        labelText: 'Direct Sitevisits',
        icon: 'account_circle_outlined',
        additionClasses: 'admin-sidebar-item--enabled',
      },
    ],
  },
  {
    menuName: 'Leads',
    tabName: 'Projects',
    submenu: [
      {
        redirectPath: '/admin/projects/eoi',
        labelText: 'EOIs',
        icon: 'receipt_long_outlined',
        additionClasses: 'admin-sidebar-item--enabled',
      },
    ],
  },
  {
    menuName: 'Settings',
    tabName: 'Projects',
    submenu: [
      {
        redirectPath: '/admin/projects/executives',
        labelText: 'Users',
        icon: 'account_circle_outlined',
        additionClasses: 'admin-sidebar-item--enabled',
      },
      {
        redirectPath: '/admin/projects/seats',
        labelText: 'Seats',
        icon: 'widgets_outlined',
        additionClasses: 'admin-sidebar-item--enabled',
      },
      {
        redirectPath: '/admin/projects/project-update',
        labelText: 'Preference',
        icon: 'settings_outlined',
        additionClasses: 'admin-sidebar-item--enabled',
      },
    ],
  },
  {
    menuName: 'Companies',
    tabName: 'Organisations',
    submenu: [
      {
        redirectPath: '/admin/organisations',
        labelText: 'All Companies',
        icon: 'receipt_long_outlined',
        additionClasses: 'admin-sidebar-item--enabled',
      },
      {
        redirectPath: '/admin/organisations/admins',
        labelText: 'Admins',
        icon: 'supervised_user_circle_outlined',
        additionClasses: 'admin-sidebar-item--enabled',
      },
      {
        redirectPath: '/admin/organisations/executives',
        labelText: 'Executives',
        icon: 'account_circle_outlined',
        additionClasses: 'admin-sidebar-item--enabled',
      },
      // {
      //   redirectPath: '/admin/organisations/partner-firms',
      //   labelText: 'Partner Firm',
      //   icon: 'contact_emergency_outlined',
      //   additionClasses: 'admin-sidebar-item--enabled',
      // },
      {
        redirectPath: '/admin/organisations/partners',
        labelText: 'Partners',
        icon: 'assignment_ind_outlined',
        additionClasses: 'admin-sidebar-item--enabled',
      },
    ],
  },
  {
    menuName: 'Channels',
    tabName: 'Organisations',
    submenu: [
      {
        redirectPath: '/admin/organisations/channel-partners',
        labelText: 'Partners',
        icon: 'assignment_ind_outlined',
        additionClasses: 'admin-sidebar-item--enabled',
      },
      {
        redirectPath: '/admin/organisations/partner-firms',
        labelText: 'Partner Firms',
        icon: 'contact_emergency_outlined',
        additionClasses: 'admin-sidebar-item--enabled',
      },
    ],
  },
];

const adminUserAdminSidebarItems: IAdminSidebarItemMain[] = [
  {
    menuName: 'Overview',
    tabName: 'Projects',
    submenu: [
      {
        redirectPath: '/admin/projects/analytics',
        labelText: 'Behaviour',
        icon: 'query_stats_outlined',
        additionClasses: 'admin-sidebar-item--enabled',
      },
      // {
      //   redirectPath: '/admin/projects/sitevisits',
      //   labelText: 'Sitevisits',
      //   icon: 'person_add_alt_outlined',
      //   additionClasses: 'admin-sidebar-item--enabled',
      // },

      {
        redirectPath: '/admin/projects/leads',
        labelText: 'Leads',
        icon: 'person_add_alt_outlined',
        additionClasses: 'admin-sidebar-item--enabled',
      },
      {
        redirectPath: '/admin/projects/bookings',
        labelText: 'Bookings',
        icon: 'person_add_alt_outlined',
        additionClasses: 'admin-sidebar-item--enabled',
      },
      {
        redirectPath: '/admin/projects/inventory',
        labelText: 'Inventory',
        icon: 'inventory',
        additionClasses: 'admin-sidebar-item--enabled',
      },
    ],
  },
  {
    menuName: 'Channel',
    tabName: 'Projects',
    submenu: [
      {
        redirectPath: '/admin/projects/partners',
        labelText: 'Partners',
        icon: 'query_stats_outlined',
        additionClasses: 'admin-sidebar-item--enabled',
      },
      {
        redirectPath: '/admin/projects/partnerLeads',
        labelText: 'Partner Leads',
        icon: 'account_circle_outlined',
        additionClasses: 'admin-sidebar-item--enabled',
      },
      {
        redirectPath: '/admin/projects/partnerFirms',
        labelText: 'Partner Firms',
        icon: 'account_circle_outlined',
        additionClasses: 'admin-sidebar-item--enabled',
      },
      {
        redirectPath: '/admin/projects/partnerSitevisits',
        labelText: 'Partner Sitevisits',
        icon: 'account_circle_outlined',
        additionClasses: 'admin-sidebar-item--enabled',
      },
      {
        redirectPath: '/admin/projects/directSitevisits',
        labelText: 'Direct Sitevisits',
        icon: 'account_circle_outlined',
        additionClasses: 'admin-sidebar-item--enabled',
      },
    ],
  },
  {
    menuName: 'Leads',
    tabName: 'Projects',
    submenu: [
      {
        redirectPath: '/admin/projects/eoi',
        labelText: 'EOIs',
        icon: 'receipt_long_outlined',
        additionClasses: 'admin-sidebar-item--enabled',
      },
    ],
  },
  {
    menuName: 'Settings',
    tabName: 'Projects',
    submenu: [
      {
        redirectPath: '/admin/projects/executives',
        labelText: 'Users',
        icon: 'account_circle_outlined',
        additionClasses: 'admin-sidebar-item--enabled',
      },
    ],
  },
  {
    menuName: 'Companies',
    tabName: 'Organisations',
    submenu: [
      {
        redirectPath: '/admin/organisations/admins',
        labelText: 'Admins',
        icon: 'supervised_user_circle_outlined',
        additionClasses: 'admin-sidebar-item--enabled',
      },
      {
        redirectPath: '/admin/organisations/executives',
        labelText: 'Executives',
        icon: 'account_circle_outlined',
        additionClasses: 'admin-sidebar-item--enabled',
      },
    ],
  },
  {
    menuName: 'Collaborators',
    tabName: 'Organisations',
    submenu: [
      {
        redirectPath: '/admin/organisations/collaborators',
        labelText: 'All Firms',
        icon: 'person_add_alt_outlined',
        additionClasses: 'admin-sidebar-item--enabled',
      },
    ],
  },
  {
    menuName: 'Channels',
    tabName: 'Organisations',
    submenu: [
      {
        redirectPath: '/admin/organisations/channel-partners',
        labelText: 'Partners',
        icon: 'assignment_ind_outlined',
        additionClasses: 'admin-sidebar-item--enabled',
      },
      {
        redirectPath: '/admin/organisations/partner-firms',
        labelText: 'Partner Firms',
        icon: 'contact_emergency_outlined',
        additionClasses: 'admin-sidebar-item--enabled',
      },
    ],
  },
];

const executiveUserAdminSidebarItems: IAdminSidebarItemMain[] = [
  {
    menuName: 'Overview',
    tabName: 'Projects',
    submenu: [
      {
        redirectPath: '/admin/projects/analytics',
        labelText: 'Behaviour',
        icon: 'query_stats_outlined',
        additionClasses: 'admin-sidebar-item--enabled',
      },
      // {
      //   redirectPath: '/admin/projects/sitevisits',
      //   labelText: 'Sitevisits',
      //   icon: 'person_add_alt_outlined',
      //   additionClasses: 'admin-sidebar-item--enabled',
      // },
      {
        redirectPath: '/admin/projects/leads',
        labelText: 'Leads',
        icon: 'person_add_alt_outlined',
        additionClasses: 'admin-sidebar-item--enabled',
      },
      {
        redirectPath: '/admin/projects/bookings',
        labelText: 'Bookings',
        icon: 'person_add_alt_outlined',
        additionClasses: 'admin-sidebar-item--enabled',
      },
      {
        redirectPath: '/admin/projects/inventory',
        labelText: 'Inventory',
        icon: 'inventory',
        additionClasses: 'admin-sidebar-item--enabled',
      },
    ],
  },

  // TODO: Hide user from production as training going on
  {
    menuName: 'Settings',
    tabName: 'Projects',
    submenu: [
      {
        redirectPath: '/admin/projects/executives',
        labelText: 'Users',
        icon: 'account_circle_outlined',
        additionClasses: 'admin-sidebar-item--enabled',
      },
    ],
  },
  {
    menuName: 'Channel',
    tabName: 'Projects',
    submenu: [
      {
        redirectPath: '/admin/projects/partners',
        labelText: 'Partners',
        icon: 'query_stats_outlined',
        additionClasses: 'admin-sidebar-item--enabled',
      },
      {
        redirectPath: '/admin/projects/partnerLeads',
        labelText: 'Partner Leads',
        icon: 'query_stats_outlined',
        additionClasses: 'admin-sidebar-item--enabled',
      },
      // {
      //   redirectPath: '/admin/projects/partnerFirms',
      //   labelText: 'Partner Firms',
      //   icon: 'account_circle_outlined',
      //   additionClasses: 'admin-sidebar-item--enabled',
      // },
      {
        redirectPath: '/admin/projects/partnerSitevisits',
        labelText: 'Partner Sitevisits',
        icon: 'account_circle_outlined',
        additionClasses: 'admin-sidebar-item--enabled',
      },
    ],
  },
  {
    menuName: 'Leads',
    tabName: 'Projects',
    submenu: [
      {
        redirectPath: '/admin/projects/eoi',
        labelText: 'EOIs',
        icon: 'receipt_long_outlined',
        additionClasses: 'admin-sidebar-item--enabled',
      },
    ],
  },
];

const businessHeadUserAdminSidebarItems: IAdminSidebarItemMain[] = [
  {
    menuName: 'Overview',
    tabName: 'Projects',
    submenu: [
      {
        redirectPath: '/admin/projects/analytics',
        labelText: 'Behaviour',
        icon: 'query_stats_outlined',
        additionClasses: 'admin-sidebar-item--enabled',
      },
      {
        redirectPath: '/admin/projects/my-team',
        labelText: 'My Team',
        icon: 'people_outlined',
        additionClasses: 'admin-sidebar-item--enabled',
      },
      // {
      //   redirectPath: '/admin/projects/sitevisits',
      //   labelText: 'Sitevisits',
      //   icon: 'person_add_alt_outlined',
      //   additionClasses: 'admin-sidebar-item--enabled',
      // },
      // {
      //   redirectPath: '/admin/projects/leads',
      //   labelText: 'Leads',
      //   icon: 'person_add_alt_outlined',
      //   additionClasses: 'admin-sidebar-item--enabled',
      // },
    ],
  },
  {
    menuName: 'Channel',
    tabName: 'Projects',
    submenu: [
      {
        redirectPath: '/admin/projects/partners',
        labelText: 'Partners',
        icon: 'query_stats_outlined',
        additionClasses: 'admin-sidebar-item--enabled',
      },
      {
        redirectPath: '/admin/projects/partnerLeads',
        labelText: 'Partner Leads',
        icon: 'query_stats_outlined',
        additionClasses: 'admin-sidebar-item--enabled',
      },
      // {
      //   redirectPath: '/admin/projects/partnerFirms',
      //   labelText: 'Partner Firms',
      //   icon: 'account_circle_outlined',
      //   additionClasses: 'admin-sidebar-item--enabled',
      // },
    ],
  },
];

const clusterHeadUserAdminSidebarItems: IAdminSidebarItemMain[] = [
  {
    menuName: 'Overview',
    tabName: 'Projects',
    submenu: [
      {
        redirectPath: '/admin/projects/analytics',
        labelText: 'Behaviour',
        icon: 'query_stats_outlined',
        additionClasses: 'admin-sidebar-item--enabled',
      },
      {
        redirectPath: '/admin/projects/my-team',
        labelText: 'My Team',
        icon: 'people_outlined',
        additionClasses: 'admin-sidebar-item--enabled',
      },
      // {
      //   redirectPath: '/admin/projects/sitevisits',
      //   labelText: 'Sitevisits',
      //   icon: 'person_add_alt_outlined',
      //   additionClasses: 'admin-sidebar-item--enabled',
      // },
      // {
      //   redirectPath: '/admin/projects/leads',
      //   labelText: 'Leads',
      //   icon: 'person_add_alt_outlined',
      //   additionClasses: 'admin-sidebar-item--enabled',
      // },
    ],
  },
  {
    menuName: 'Channel',
    tabName: 'Projects',
    submenu: [
      {
        redirectPath: '/admin/projects/partners',
        labelText: 'Partners',
        icon: 'query_stats_outlined',
        additionClasses: 'admin-sidebar-item--enabled',
      },
      {
        redirectPath: '/admin/projects/partnerLeads',
        labelText: 'Partner Leads',
        icon: 'query_stats_outlined',
        additionClasses: 'admin-sidebar-item--enabled',
      },
      // {
      //   redirectPath: '/admin/projects/partnerFirms',
      //   labelText: 'Partner Firms',
      //   icon: 'account_circle_outlined',
      //   additionClasses: 'admin-sidebar-item--enabled',
      // },
    ],
  },
];
const siteHeadUserAdminSidebarItems: IAdminSidebarItemMain[] = [
  {
    menuName: 'Overview',
    tabName: 'Projects',
    submenu: [
      {
        redirectPath: '/admin/projects/analytics',
        labelText: 'Behaviour',
        icon: 'query_stats_outlined',
        additionClasses: 'admin-sidebar-item--enabled',
      },
      // {
      //   redirectPath: '/admin/projects/my-team',
      //   labelText: 'My Team',
      //   icon: 'people_outlined',
      //   additionClasses: 'admin-sidebar-item--enabled',
      // },
      // {
      //   redirectPath: '/admin/projects/sitevisits',
      //   labelText: 'Sitevisits',
      //   icon: 'person_add_alt_outlined',
      //   additionClasses: 'admin-sidebar-item--enabled',
      // },
      // {
      //   redirectPath: '/admin/projects/leads',
      //   labelText: 'Leads',
      //   icon: 'person_add_alt_outlined',
      //   additionClasses: 'admin-sidebar-item--enabled',
      // },
    ],
  },
  {
    menuName: 'Channel',
    tabName: 'Projects',
    submenu: [
      {
        redirectPath: '/admin/projects/partners',
        labelText: 'Partners',
        icon: 'query_stats_outlined',
        additionClasses: 'admin-sidebar-item--enabled',
      },
      {
        redirectPath: '/admin/projects/partnerLeads',
        labelText: 'Partner Leads',
        icon: 'query_stats_outlined',
        additionClasses: 'admin-sidebar-item--enabled',
      },
      // {
      //   redirectPath: '/admin/projects/partnerFirms',
      //   labelText: 'Partner Firms',
      //   icon: 'account_circle_outlined',
      //   additionClasses: 'admin-sidebar-item--enabled',
      // },
      {
        redirectPath: '/admin/projects/partnerSitevisits',
        labelText: 'Partner Sitevisits',
        icon: 'account_circle_outlined',
        additionClasses: 'admin-sidebar-item--enabled',
      },
    ],
  },
  {
    menuName: 'Settings',
    tabName: 'Projects',
    submenu: [
      {
        redirectPath: '/admin/projects/executives',
        labelText: 'Users',
        icon: 'account_circle_outlined',
        additionClasses: 'admin-sidebar-item--enabled',
      },
    ],
  },
];
const teamLeadUserAdminSidebarItems: IAdminSidebarItemMain[] = [
  {
    menuName: 'Overview',
    tabName: 'Projects',
    submenu: [
      {
        redirectPath: '/admin/projects/analytics',
        labelText: 'Behaviour',
        icon: 'query_stats_outlined',
        additionClasses: 'admin-sidebar-item--enabled',
      },
      {
        redirectPath: '/admin/projects/my-team',
        labelText: 'My Team',
        icon: 'people_outlined',
        additionClasses: 'admin-sidebar-item--enabled',
      },
      // {
      //   redirectPath: '/admin/projects/sitevisits',
      //   labelText: 'Sitevisits',
      //   icon: 'person_add_alt_outlined',
      //   additionClasses: 'admin-sidebar-item--enabled',
      // },
      // {
      //   redirectPath: '/admin/projects/leads',
      //   labelText: 'Leads',
      //   icon: 'person_add_alt_outlined',
      //   additionClasses: 'admin-sidebar-item--enabled',
      // },
    ],
  },
  {
    menuName: 'Channel',
    tabName: 'Projects',
    submenu: [
      {
        redirectPath: '/admin/projects/partners',
        labelText: 'Partners',
        icon: 'query_stats_outlined',
        additionClasses: 'admin-sidebar-item--enabled',
      },
      {
        redirectPath: '/admin/projects/partnerLeads',
        labelText: 'Partner Leads',
        icon: 'query_stats_outlined',
        additionClasses: 'admin-sidebar-item--enabled',
      },
      // {
      //   redirectPath: '/admin/projects/partnerFirms',
      //   labelText: 'Partner Firms',
      //   icon: 'account_circle_outlined',
      //   additionClasses: 'admin-sidebar-item--enabled',
      // },
    ],
  },
  // {
  //   menuName: 'Channel',
  //   tabName: 'Projects',
  //   submenu: [
  //     {
  //       redirectPath: '/admin/projects/partners',
  //       labelText: 'Partners',
  //       icon: 'query_stats_outlined',
  //       additionClasses: 'admin-sidebar-item--enabled',
  //     },
  //     {
  //       redirectPath: '/admin/projects/partnerLeads',
  //       labelText: 'Partner Leads',
  //       icon: 'query_stats_outlined',
  //       additionClasses: 'admin-sidebar-item--enabled',
  //     },
  //     {
  //       redirectPath: '/admin/projects/partnerFirms',
  //       labelText: 'Partner Firms',
  //       icon: 'account_circle_outlined',
  //       additionClasses: 'admin-sidebar-item--enabled',
  //     },
  //   ],
  // },
];

export type TAllowedUserRolesOnAdminPortal =
  | UserRole.SUPERADMIN
  | UserRole.ADMIN
  | UserRole.BUSINESS_HEAD
  | UserRole.CLUSTER_HEAD
  | UserRole.SITE_HEAD
  | UserRole.TEAM_LEAD
  | UserRole.EXECUTIVE
  | UserRole.GRE;

export const ALLOWED_USER_ROLES_ON_ADMIN_PORTAL: TAllowedUserRolesOnAdminPortal[] =
  [
    UserRole.SUPERADMIN,
    UserRole.ADMIN,
    UserRole.EXECUTIVE,
    UserRole.CLUSTER_HEAD,
    UserRole.BUSINESS_HEAD,
    UserRole.SITE_HEAD,
    UserRole.TEAM_LEAD,
    UserRole.GRE,
  ];
export const ALLOWED_USER_ROLES_ON_SITE_VISIT: TAllowedUserRolesOnAdminPortal[] =
  [UserRole.GRE];

const sideBarMenuOptionsByUserRole: {
  [key in TAllowedUserRolesOnAdminPortal]: IAdminSidebarItemMain[];
} = {
  [UserRole.SUPERADMIN]: superUserAdminSidebarItems,
  [UserRole.ADMIN]: adminUserAdminSidebarItems,
  [UserRole.BUSINESS_HEAD]: businessHeadUserAdminSidebarItems,
  [UserRole.CLUSTER_HEAD]: clusterHeadUserAdminSidebarItems,
  [UserRole.SITE_HEAD]: siteHeadUserAdminSidebarItems,
  [UserRole.TEAM_LEAD]: teamLeadUserAdminSidebarItems,
  [UserRole.EXECUTIVE]: executiveUserAdminSidebarItems,
  [UserRole.GRE]: executiveUserAdminSidebarItems,
};

const AdminSidebar = () => {
  const [hideSideNavBar, setHideNavBar] = useState(true);

  const history = useHistory();

  const sidebarSteps: IAdminSidebarItemMain[] =
    sideBarMenuOptionsByUserRole[
      getAdminUserRole() as TAllowedUserRolesOnAdminPortal
    ] || [];

  const { pathname: currentPath } = useLocation();
  const { removeAdminUserLocal, logginOut } = useAdminLogOut();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setAppSliceIsFetching(logginOut));
  }, [logginOut]);

  const commonUserItem: ICommonUserItem[] = [
    {
      redirectPath: '',
      labelText: 'Logout',
      icon: 'logout',
      additionClasses: 'admin-sidebar-item--enabled',
      onClick: async () => {
        await removeAdminUserLocal();
        history.push('/admin');
      },
    },
  ];

  const selectedHeaderTab = useMemo(() => {
    const tab = sidebarSteps.find((subMenu) =>
      subMenu.submenu.find((menu) => menu.redirectPath === currentPath)
    );
    if (tab) {
      return tab.tabName;
    }
    return 'Overview';
  }, [sidebarSteps, currentPath]);

  const toggleNavBar = () => {
    setHideNavBar(!hideSideNavBar);
  };

  const filteredSidebarStepsByHeaderTabName = useMemo(() => {
    return (
      sidebarSteps.filter(
        (sidebarStep) => sidebarStep.tabName == selectedHeaderTab
      ) || []
    );
  }, [sidebarSteps, selectedHeaderTab]);

  if (!filteredSidebarStepsByHeaderTabName.length) {
    return null;
  }

  return (
    <div className={`admin-sidebar ${!hideSideNavBar && 'active'}`}>
      <div className='toggle-sidebar_button' onClick={toggleNavBar}>
        <Icon
          name={hideSideNavBar ? 'arrow_back_ios_new' : 'arrow_forward_ios'}
          propStyles='toggle-arrow'
        />
      </div>
      <NavSideBarItems
        sidebarSteps={filteredSidebarStepsByHeaderTabName}
        hideSideNavBar={hideSideNavBar}
      />
      <div className='sidebar-bottom'>
        <span className='dividerTop' />
        {commonUserItem.map((item) => (
          <AdminSidebarItem
            key={item.labelText as string}
            redirectPath={item.redirectPath}
            labelText={item.labelText}
            icon={item.icon}
            additionClasses={item.additionClasses}
            hideSideNavBar={hideSideNavBar}
            onClick={item.onClick}
          />
        ))}
      </div>
    </div>
  );
};

export default AdminSidebar;
