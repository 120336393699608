import { useHistory } from 'react-router-dom';

import MoreMenu, { MenuItem } from 'apps/admin/components/common/MoreMenu';
import { IProject } from 'apps/admin/components/pages/SuperAdminDashboard/SuperAdminProjectList';

export interface IMoreMenuCell {
  project: IProject;
}

const MoreMenuCell = (props: IMoreMenuCell) => {
  const { project } = props;

  const history = useHistory();

  const moreMenuOptions: MenuItem[] = [
    {
      displayText: 'View Executive',
      icon: 'person',
      onClick: () => {
        history.push('/admin/projects/executives', { project: project });
      },
    },
  ];

  return (
    <>
      <MoreMenu menuItems={moreMenuOptions} disabled={project.disabled} />
    </>
  );
};

export default MoreMenuCell;
