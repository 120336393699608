import React, { useState, useRef } from 'react';
import { useHistory } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';

import useOnClickOutside from '../../../../../utils/useOnClickOutside';
import DropdownContent, { getDropdownPlaceholderText } from './DropdownContent';
import { URLS } from '../../../../../constants/urls';

import {
  getUnitSelectionHeaderNavState,
  getSectionBtnState,
  getSelectedTower,
  getSelectedSection,
  setSelectedSection,
  DEFAULT_SELECTED_TOWER,
  UNIT_SELECTION_HEADER_NAV_STATE,
} from 'apps/booking/slices/unitSelectionHeader/unitSelectionHeaderNav';
import { getLanguageText } from 'constants/multiLanguageTexts';
import { getUserLanguage } from 'apps/booking/slices/userlanguage';
import { setBookingRoutesCurrentPage } from 'apps/booking/routers/BookingRoutes/slice';
import { getProject } from 'apps/booking/slices/projectSlice';

const SectionBtn = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const sectionBtnState = useSelector(getSectionBtnState);
  const selectedTower = useSelector(getSelectedTower);
  const selectedSection = useSelector(getSelectedSection);
  const unitSelectionHeaderNavState = useSelector(
    getUnitSelectionHeaderNavState
  );
  const userLanguage = useSelector(getUserLanguage);
  const project = useSelector(getProject);

  const [show, setShow] = useState(false);
  const [active, setActive] = useState(false);
  const toggleDropdownSection = (showOrHide) => {
    setShow(showOrHide);
  };

  if (
    unitSelectionHeaderNavState === UNIT_SELECTION_HEADER_NAV_STATE.MASTER &&
    active
  ) {
    setActive(false);
  }

  const ref = useRef();
  useOnClickOutside(ref, () => toggleDropdownSection(false));

  const handleSelectedOption = (selectedSectionName) => {
    setActive(true);
    dispatch(setBookingRoutesCurrentPage(URLS.UNIT_SELECTION));
    dispatch(setSelectedSection(selectedSectionName));
  };
  return (
    <button
      ref={ref}
      className={`btn btn--icon-label-container dropdown 
        ${selectedTower !== DEFAULT_SELECTED_TOWER && 'btn--accent'}
        ${selectedTower === DEFAULT_SELECTED_TOWER && 'btn--disabled'}
      `}
      onClick={() => toggleDropdownSection(!show)}>
      <span>
        {getLanguageText(
          userLanguage,
          selectedTower === DEFAULT_SELECTED_TOWER
            ? project.booking?.title?.sectionBtnTitle || 'Floor'
            : getDropdownPlaceholderText(selectedSection, sectionBtnState)
        )}
      </span>
      <span className='icon material-icons'></span>
      <DropdownContent
        btnState={sectionBtnState}
        show={show}
        handleSelectedOption={handleSelectedOption}
      />
    </button>
  );
};

export default SectionBtn;
