import { useEffect, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';

import 'ag-grid-enterprise';
import { GridReadyEvent, ICellRendererParams } from 'ag-grid-community';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';

import { getAdminCompany, getAdminUser } from 'apps/admin/slices/adminSlice';
import Tooltip from 'apps/booking/components/common/Tooltip';
import UpdateUserCell, {
  IUpdateUserCell,
} from 'apps/admin/components/pages/AdminDashboard/AdminExecutiveList/Body/UserListGrid/UpdateUserCell';
import { UserRole } from 'constants/userRoles';
import RemoveProjectExecutiveCell, {
  IRemoveProjectExecutiveCell,
} from 'apps/admin/components/pages/ProjectExecutives/RemoveProjectExecutiveCell';
import UserDetailsCell from 'apps/admin/components/pages/AdminDashboard/AdminExecutiveList/Body/UserListGrid/UserDetailsCell';
import { IUser } from 'apps/admin/interfaces';
import Button from 'apps/booking/components/common/buttons/Button';
import { IProject } from 'apps/admin/components/pages/SuperAdminDashboard/SuperAdminProjectList';
import AddProjectExecutiveModal from 'apps/admin/components/pages/SuperAdminDashboard/SuperAdminProjectList/modals/AddProjectExecutiveModal';
import { CLUB_RHODIUM_COMPANY_ID } from 'apps/cp/constants/ids';
import TextInput from 'apps/booking/components/common/inputs/TextInput';
import DateCell from 'apps/admin/components/common/dates/DateCell';
import QrCodeCell from 'apps/admin/components/pages/AdminDashboard/AdminExecutiveList/Body/UserListGrid/QrCodeCell';
import NameCard from 'apps/booking/components/common/admin/NameCard';
import { useGetUsersByProjectIdMutation } from 'apps/admin/services/userRolesAPISlice';
import { awsBaseUrl } from 'constants/urls';
import { DEFAULT_NULL_VALUE } from 'apps/admin/components/common/ExpandedDetailSection';

import genericStyles from '../../../../../../styles/genericStyles.module.css';
import styles from './styles.module.css';

interface IProjectExecutivesProps {
  project: IProject;
  onProjectUpdate: (updateProject: IProject) => void;
  inActiveList?: boolean;
}

const ProjectExecutiveList = (props: IProjectExecutivesProps) => {
  const { project, onProjectUpdate, inActiveList } = props;
  const [users, setUsers] = useState<IUser[]>([]);

  const adminCompany = useSelector(getAdminCompany);
  const loggedInUser = useSelector(getAdminUser);

  const defaultColDef = {
    sortable: false,
    filter: false,
    resizable: false,
    suppressMenu: true,
  };

  const [showAddProjectExecutiveModal, setShowAddProjectExecutiveModal] =
    useState(false);

  const [gridApi, setGridApi] = useState(null) as any;

  // const [executiveList, setExecutiveList] = useState<IUser[]>([]);

  const user = useSelector(getAdminUser);

  const [getUsersByProjectIdAPI] = useGetUsersByProjectIdMutation();

  const handleUpdateUsersList = async () => {
    try {
      const response = await getUsersByProjectIdAPI({
        projectId: project.id,
      }).unwrap();
      setUsers(response.data);
    } catch (err) {}
  };

  useEffect(() => {
    handleUpdateUsersList();
  }, [project]);

  // useEffect(() => {
  //   setExecutiveList(project.executivesWithDemoRoom.map((e) => e.executive));
  // }, [project]);

  const onGridReady = (params: GridReadyEvent) => {
    setGridApi(params.api);
  };

  //Ag-grid global search
  const handleGridSearch = (searchString: string) => {
    gridApi?.setQuickFilter(searchString);
  };

  const handleAddDeleteExecutive = (updatedProject: IProject) => {
    onProjectUpdate(updatedProject);
    // setExecutiveList(
    //   updatedProject.executivesWithDemoRoom.map((e) => e.executive)
    // );
    // setExecutiveList(project.executivesWithDemoRoom.map((e) => e.executive));
  };

  const getUserAvatarUrl = (params: ICellRendererParams) => {
    return `${awsBaseUrl}/common/AZAlphabets/${params.data.name[0].toUpperCase()}${
      +params.data.mobile % 5
    }.png`;
  };

  const handleExecutiveUpdate = (updateUser: IUser) => {
    // Update the updatedExecutive details
    const updatedProject = {
      ...project,
      executivesWithDemoRoom: project.executivesWithDemoRoom.map((e) => {
        if (e.executive.id === updateUser.id) {
          return { ...e, executive: updateUser };
        }
        return e;
      }),
    };
    onProjectUpdate(updatedProject);
  };

  const sortedUsers = useMemo(() => {
    let sortedExecutiveList = [...users];

    if (users) {
      return sortedExecutiveList.sort(
        (a, b) =>
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      );
    }
    return sortedExecutiveList;
  }, [users]);

  return (
    <>
      {showAddProjectExecutiveModal && (
        <AddProjectExecutiveModal
          project={project}
          onModalClose={() => {
            setShowAddProjectExecutiveModal(false);
          }}
          onAddExecutive={handleAddDeleteExecutive}
        />
      )}

      <div>
        <header className={styles.modalHeader}>
          <div>
            <h2 className={styles.modalHeading}>List of Users</h2>
            <p className={styles.modalDesc}>
              Following users get access to all project of {project.name}
              developer. You can edit details or remove users from here.
            </p>
          </div>

          {user?.role == UserRole.SUPERADMIN && (
            <Button
              onClick={() => {
                setShowAddProjectExecutiveModal(true);
              }}
              propStyles={styles.addExecutiveButton}>
              Add User
            </Button>
          )}
        </header>
        <div className={styles.searchContainer}>
          <TextInput
            type='text'
            inputHTMLElementStyles={styles.searchInput}
            placeHolder='Search Name, Email & Mobile Number'
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              handleGridSearch(event.target.value);
            }}
            startIconName='search'
            startIconVariant='SECONDARY'
          />
        </div>
        <main>
          <div
            className='ag-theme-alpine'
            style={{ height: '100%', width: '100%' }}>
            <AgGridReact
              onGridReady={onGridReady}
              rowData={sortedUsers}
              defaultColDef={defaultColDef}
              domLayout={'autoHeight'}
              pagination={true}
              paginationPageSize={10}
              masterDetail={true}
              detailCellRenderer={'detailCellRenderer'}
              animateRows={true}>
              <AgGridColumn
                headerName='Names'
                field='name'
                flex={3}
                cellRendererFramework={(params: ICellRendererParams) => (
                  <NameCard
                    {...{
                      imgUrl: `${getUserAvatarUrl(params)}`,
                      title: params.data.name,
                      subtitle: params.data.email,
                      disabled: params.data.disabled,
                    }}
                  />
                )}
              />
              <AgGridColumn
                flex={1}
                headerName='email'
                field='email'
                hide={true}
                cellRendererFramework={(params: ICellRendererParams) => (
                  <p className={styles.cpName}>{params.data.email}</p>
                )}
              />
              <AgGridColumn
                flex={1.2}
                headerName='Mobile'
                field='mobile'
                cellClass='relata-ag-grid-cell-wrap'
                cellRendererFramework={(params: ICellRendererParams) => (
                  <Tooltip title='Click to Copy'>
                    <p
                      onClick={() => {
                        navigator.clipboard.writeText(`${params.data.mobile}`);
                      }}
                      className={`${genericStyles.pointer} relata-ag-grid-wrappable-text ${genericStyles.onTextHoverAnimation}`}>
                      {params.data.mobile}
                    </p>
                  </Tooltip>
                )}
              />
              <AgGridColumn
                flex={1}
                headerName={inActiveList ? 'Un-Registered On' : 'Registered On'}
                cellRendererFramework={DateCell}
                cellRendererParams={(params: ICellRendererParams) => {
                  return {
                    date: params.data.createdAt,
                  };
                }}
              />
              <AgGridColumn
                flex={0.8}
                headerName='Activity'
                cellRendererFramework={(params: ICellRendererParams) => (
                  <p className={styles.cpName}>{params.data.activity}</p>
                )}></AgGridColumn>
              <AgGridColumn
                flex={1.5}
                headerName='JobTitle'
                cellRendererFramework={(params: ICellRendererParams) => (
                  <p className={styles.cpName}>
                    {params.data.jobTitle
                      ? params.data.jobTitle
                      : DEFAULT_NULL_VALUE}
                  </p>
                )}></AgGridColumn>
              {/* TODO: Hide roles  until cp  training
              <AgGridColumn
                flex={1.2}
                headerName='Role'
                cellRendererFramework={(params: ICellRendererParams) => (
                  <p className={styles.cpName}>{params.data.role}</p>
                )}></AgGridColumn> */}
              {user?.role === UserRole.SUPERADMIN && (
                <AgGridColumn
                  flex={0.5}
                  headerName='Edit'
                  headerClass='relata-ag-grid-header-small-font'
                  // field=''
                  // cellClass='relata-ag-grid-cell-justify-center'
                  cellRendererFramework={UpdateUserCell}
                  cellRendererParams={(
                    params: ICellRendererParams
                  ): IUpdateUserCell => ({
                    user: params.data,
                    rowNode: params.node,
                    onUserUpdate: handleExecutiveUpdate,
                    disabled: !(
                      params.data.company === adminCompany?.id ||
                      loggedInUser?.role === UserRole.SUPERADMIN
                    ),
                    userRole: params.data.role,
                  })}
                />
              )}

              {user?.role === UserRole.SUPERADMIN && (
                <AgGridColumn
                  flex={0.8}
                  headerName='Remove'
                  // headerClass='relata-ag-grid-header-small-font relata-ag-grid-header-justify-center'
                  field=''
                  // cellClass='relata-ag-grid-cell-justify-center'
                  cellRendererFramework={RemoveProjectExecutiveCell}
                  cellRendererParams={(
                    params: ICellRendererParams
                  ): IRemoveProjectExecutiveCell => ({
                    executiveWithDemoRoomId:
                      project.executivesWithDemoRoom.find(
                        (e) => e.executive?.id === params.data.id
                      )?.id as string,
                    projectId: project.id,
                    userRole: params.data.role,
                    onExecutiveDelete: handleAddDeleteExecutive,
                    disabled: !(
                      params.data.company === adminCompany?.id ||
                      loggedInUser?.role === UserRole.SUPERADMIN
                    ),
                  })}
                />
              )}
              {project.company.id == CLUB_RHODIUM_COMPANY_ID && (
                <AgGridColumn
                  flex={0.8}
                  headerName='Referrals'
                  cellRendererFramework={QrCodeCell}
                  cellRendererParams={(params: ICellRendererParams) => ({
                    executiveId: params.data.id,
                    projectId: project.id,
                    userRole: params.data.role,
                  })}
                />
              )}
              <AgGridColumn
                flex={0.8}
                headerName='Details'
                cellRendererFramework={UserDetailsCell}
                cellRendererParams={(params: ICellRendererParams) => ({
                  user: params.data as IUser,
                })}
              />
            </AgGridReact>
          </div>
        </main>
      </div>
    </>
  );
};

export default ProjectExecutiveList;
