import Button from 'apps/booking/components/common/buttons/Button';
import Icon from 'apps/booking/components/common/Icon';
import { LoginFormStates } from 'apps/admin/components/pages/AdminLogin/LoginForm';

import styles from '../../styles.module.css';

interface IPasswordChangeSuccessState {
  setLoginFormState: Function;
}

const PasswordChangeSuccessState = (props: IPasswordChangeSuccessState) => {
  const { setLoginFormState } = props;

  return (
    <>
      <h2 className={styles.loginFormHeading}>
        You Password has been changed!
      </h2>
      <p className={styles.loginFormDesc}>
        Enjoy you brand new shiny Password. You can now head back to login, till
        you forget your password the next time!
      </p>
      <div className={styles.passwordResetSuccessIconContainer}>
        <Icon name='task_alt' propStyles={styles.passwordResetSuccessIcon} />
      </div>
      <Button
        propStyles={styles.loginButton}
        onClick={() => setLoginFormState(LoginFormStates.LOGIN)}>
        Back to Login
      </Button>
      <Button
        propStyles={`${styles.forgotPasswordButton} ${styles.disabledForgotPasswordButton}`}
        onClick={() => setLoginFormState(LoginFormStates.SEND_OTP)}>
        Forgot Password
      </Button>
    </>
  );
};

export default PasswordChangeSuccessState;
