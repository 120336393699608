import { configureStore } from '@reduxjs/toolkit';

import { unitSelectionHeaderNavSlice } from 'apps/booking/slices/unitSelectionHeader/unitSelectionHeaderNav';
import { bookingEngineRoutesSlice } from 'apps/booking/routers/BookingRoutes/slice';
import { seletedUnitSlice } from './apps/booking/slices/selectedUnit';
import { authSlice } from './apps/booking/slices/auth';
import { projectSlice } from 'apps/booking/slices/projectSlice';
import { toastsSlice } from './slices/toasts';
import { appSlice } from './slices/appSlice';
import { leadsSearchSlice } from './apps/admin/slices/leadsSearchSlice';

import { unitsAPISlice } from './api/units1';
import { unitCartAPISlice } from './api/unitCart';
import { authAPISlice } from './api/auth';
import { siteVisitAPISlice } from './api/siteVisit';
import { applicantAPISlice } from './api/applicantAPISlice';
import { paymentAPISlice } from './api/paymentAPISlice';
import { paymentsAPISlice } from 'apps/booking/services/paymentsAPISlice';

// Admin
// AdminSlices
import { adminSlice } from './apps/admin/slices/adminSlice';
import { projectAdminSlice } from 'apps/admin/slices/projectAdminSlice';

// AdminAPISlices
import { adminAPISlice } from './apps/admin/services';

// CP
import { cpAppSlice } from './apps/cp/slices/cpAppSlice';
import { cpUserSlice } from './apps/cp/slices/cpUserSlice';
import { cpAPISlice } from './apps/cp/services';
import { userLanguageSlice } from 'apps/booking/slices/userlanguage';

export const store = configureStore({
  reducer: {
    [appSlice.name]: appSlice.reducer,
    [unitSelectionHeaderNavSlice.name]: unitSelectionHeaderNavSlice.reducer,
    [bookingEngineRoutesSlice.name]: bookingEngineRoutesSlice.reducer,
    [seletedUnitSlice.name]: seletedUnitSlice.reducer,
    [authSlice.name]: authSlice.reducer,
    [projectSlice.name]: projectSlice.reducer,
    [toastsSlice.name]: toastsSlice.reducer,
    [leadsSearchSlice.name]: leadsSearchSlice.reducer,

    [unitsAPISlice.reducerPath]: unitsAPISlice.reducer,
    [unitCartAPISlice.reducerPath]: unitCartAPISlice.reducer,
    [authAPISlice.reducerPath]: authAPISlice.reducer,
    [siteVisitAPISlice.reducerPath]: siteVisitAPISlice.reducer,
    [applicantAPISlice.reducerPath]: applicantAPISlice.reducer,
    [paymentAPISlice.reducerPath]: paymentAPISlice.reducer,
    [paymentsAPISlice.reducerPath]: paymentsAPISlice.reducer,

    // Admin
    // AdminSlices
    [adminSlice.name]: adminSlice.reducer,
    [projectAdminSlice.name]: projectAdminSlice.reducer,

    // AdminAPISlices
    [adminAPISlice.reducerPath]: adminAPISlice.reducer,

    // CP
    [cpAppSlice.name]: cpAppSlice.reducer,
    [cpUserSlice.name]: cpUserSlice.reducer,

    [cpAPISlice.reducerPath]: cpAPISlice.reducer,

    [userLanguageSlice.name]: userLanguageSlice.reducer,
  },
  middleware: (getDefaultMiddleware) => {
    return (
      getDefaultMiddleware()
        .concat(unitsAPISlice.middleware)
        .concat(unitCartAPISlice.middleware)
        .concat(authAPISlice.middleware)
        .concat(siteVisitAPISlice.middleware)
        .concat(applicantAPISlice.middleware)
        .concat(paymentAPISlice.middleware)
        .concat(paymentsAPISlice.middleware)

        // AdminAPISlices
        .concat(adminAPISlice.middleware)
    );
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
