import 'ag-grid-enterprise';

import { IProject } from 'apps/admin/components/pages/SuperAdminDashboard/SuperAdminProjectList';

import { ITab } from 'apps/cp/components/common/Tabs';
import DesktopTabs from '../../common/DesktopTabs';
import ProjectCollaboratorList from './ProjectCollaboratorList';
import ProjectExecutiveList from './ProjectExecutiveList';

interface IProjectExecutivesProps {
  project: IProject;
  onProjectUpdate: (updateProject: IProject) => void;
}

const ProjectExecutives = (props: IProjectExecutivesProps) => {
  const { project, onProjectUpdate } = props;

  const tabs: ITab[] = [
    {
      id: 'active',
      name: 'Active',
      content: (
        <ProjectExecutiveList
          project={project}
          onProjectUpdate={onProjectUpdate}
        />
      ),
      disabled: false,
    },
    // {
    //   id: 'inactive',
    //   name: 'Inactive',
    //   content: (
    //     <ProjectExecutiveList
    //       project={project}
    //       onProjectUpdate={onProjectUpdate}
    //       inActiveList
    //     />
    //   ),
    //   disabled: false,
    // },
    {
      id: 'collaborators',
      name: 'Collaborators',
      content: (
        <ProjectCollaboratorList
          project={project}
          onProjectUpdate={onProjectUpdate}
        />
      ),
      disabled: false,
    },
  ];

  return <DesktopTabs tabs={tabs} />;
};

export default ProjectExecutives;
