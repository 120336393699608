import { useState } from 'react';

import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { CROSS_TICK_ICON, EIDT_ICON } from '../../../../../constants/icons';

import { useRegisterMutation } from '../../../../../api/auth';

// Validation
const schema = yup.object().shape({
  fname: yup.string().required(),
  lname: yup.string().required(),
  email: yup.string().email(),
});

const RegisterForm = (props) => {
  const {
    forcefullShowAuthForm,
    setShowAuthForms,
    setShowRegisterFrom,
    mobile,
  } = props;
  const [requestLoading, setRequestLoading] = useState(false);
  const [uniqueEmailError, setUniqueEmailError] = useState(false);
  const [somethingWentWrong, setSomethingWentWrong] = useState('');

  const {
    register,
    handleSubmit,
    formState: { errors, isValid: isFormValid },
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
  });

  const [registerAPI] = useRegisterMutation();

  if (mobile.length !== 10) {
    setShowRegisterFrom(false);
    return null;
  }

  const handleEmailInputFieldChange = () => {
    setUniqueEmailError(false);
  };

  const handleRegister = async (data) => {
    const { error: userError } = await registerAPI({
      fname: data.fname,
      lname: data.lname,
      email: data.email,
      mobile_no: mobile,
    });
    if (userError) {
      const { data: { email: emailError, message } = {} } = userError;
      if (emailError || message === 'EMAIL_ALREADY_EXISTS') {
        setUniqueEmailError(true);
      } else {
        // TODO: For testing purpose, Remove in productions
        setSomethingWentWrong(`Relata USER : ${message}`);
      }
      return;
    }

    setShowAuthForms(false);
  };

  const handleRegisterWrap = async (data) => {
    setSomethingWentWrong('');
    setRequestLoading(true);
    await handleRegister(data);
    setRequestLoading(false);
  };

  return (
    <>
      <form
        className={`login-form login-form--singin-with-otp login-form--register ${
          isFormValid && 'login-form--valid'
        }
        `}
        onSubmit={handleSubmit(handleRegisterWrap)}>
        <header className='login-form__header'>
          <span
            className='icon material-icons icon-edit click-enabled'
            onClick={() => {
              setShowRegisterFrom(false);
            }}>
            {EIDT_ICON}
          </span>
          <span className='login-from__header-title'>Register</span>
          {!forcefullShowAuthForm && (
            <span
              className='icon material-icons icon-cross click-enabled'
              onClick={() => setShowAuthForms(false)}>
              {CROSS_TICK_ICON}
            </span>
          )}
        </header>
        <main className='login-form__body'>
          <div className='form-summery-desc'>
            Welcome to the user register page. To start accessing your account,
            Register now!
          </div>
          <div className='input'>
            <label className='input_label'>First Name</label>
            <input type='text' className='input_field' {...register('fname')} />

            <span className='input__error'>
              {errors.fname && 'First Name is required'}
            </span>
          </div>
          <div className='input'>
            <label className='input_label'>Last Name</label>
            <input type='text' className='input_field' {...register('lname')} />
            <span className='input__error'>
              {errors.lname && 'Last Name is required'}
            </span>
          </div>
          <div className='input'>
            <label className='input_label'>Email ID</label>
            <input
              type='text'
              className='input_field'
              {...register('email')}
              onInput={(e) => handleEmailInputFieldChange(e.target.value)}
            />
            <span className='input__error'>
              {(errors.email && 'Email ID is required') ||
                (uniqueEmailError && 'Email ID is already used')}
            </span>
          </div>
          <div className='input'>
            <label className='input_label'>Phone Number</label>
            <input
              type='text'
              className='input_field'
              defaultValue={mobile}
              disabled
            />
          </div>
        </main>
        <footer className='login-form__footer'>
          <div className='input'>
            <button
              type='submit'
              className={`input_field 
                ${isFormValid && 'click-enabled'}
                ${!isFormValid && 'click-disabled'}
                ${requestLoading && 'loading-spin-container'}`}>
              <span>REGISTER</span>
              <span className='ic2-fa-spin-blue'></span>
            </button>
          </div>
          {somethingWentWrong}
        </footer>
      </form>
    </>
  );
};

export default RegisterForm;
