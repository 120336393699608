import { useForm } from 'react-hook-form';
import { useState, useEffect, useMemo } from 'react';

import Icon, { IconNames } from 'apps/booking/components/common/Icon';
import { getNumberOfDays } from 'utils/utils';
import {
  getProjectCount,
  getLeadStatus,
} from 'apps/cp/components/pages/CrRecommendedProjectsPage/utils';
import { ICpLead, ICpSiteVisit, ISiteVistlead } from 'apps/cp/interfaces';
import SheduleSiteVisitModel from 'apps/cp/components/common/SheduleSiteVisitModel';
import { dateIsToday, dayMonthYearDay } from 'utils/dateFormator';
import { TObjectId } from 'interfaces';
import { SCHEDULED_SITE_VISIT_STATUS } from 'apps/cp/components/pages/CrSiteVisitPage/index';
import SiteVistConfirmationModel from 'apps/cp/components/common/SiteVistConfirmationModel';
import SpinLoader from 'apps/booking/components/common/SpinLoader';
import TextInput from 'apps/booking/components/common/inputs/TextInput';
import { IProject } from 'apps/admin/components/pages/SuperAdminDashboard/SuperAdminProjectList';

import styles from './styles.module.css';

export const getActivityIcon = (activityDays: number) => {
  let iconName: IconNames;
  switch (true) {
    case activityDays <= 7:
      iconName = 'whatshot';
      break;
    case activityDays <= 20:
      iconName = 'thermostat';
      break;
    case activityDays >= 21:
      iconName = 'ac_unit';
      break;
    default:
      iconName = 'whatshot';
  }

  return iconName;
};

interface ISiteVisitTilesProps {
  siteVisitleads: ISiteVistlead[];
  showSearch?: boolean;
  showLoader?: boolean;
}

const SiteVisitTiles = (props: ISiteVisitTilesProps) => {
  const { siteVisitleads, showLoader, showSearch } = props;

  const [showConfirmSheduleModal, setShowConfirmSheduleModal] = useState(false);
  const [showSiteVisitModal, setshowSiteVisitModal] = useState(false);
  const [project, setProject] = useState<IProject>();
  const [cpLead, setCpLead] = useState<ICpLead>();
  const [siteVisitData, setSiteVisitData] = useState<ICpSiteVisit>();
  const [searchedLeadName, setSearchedLeadName] = useState('');

  const { register, unregister, setValue } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });

  const searchedSiteVisits = useMemo(() => {
    return siteVisitleads.filter((siteVisit) =>
      siteVisit.cpLead.name
        .toLowerCase()
        .includes(searchedLeadName.toLowerCase())
    );
  }, [searchedLeadName, siteVisitleads]);

  useEffect(() => {
    if (showConfirmSheduleModal) {
      document.body.className = 'no-scroll';
    } else document.body.className = '';
  }, [showConfirmSheduleModal]);

  const uniqueVisitDates = [
    ...new Set(
      searchedSiteVisits?.map(
        (siteVisitDate: { visitDate: Date }) => siteVisitDate.visitDate
      )
    ),
  ].sort();

  const handleShowSheduleVisit = (visitDetails: ISiteVistlead) => {
    setProject(visitDetails?.project);
    setCpLead(visitDetails.cpLead);

    if (
      visitDetails.status === SCHEDULED_SITE_VISIT_STATUS.CANCEL ||
      visitDetails.status === SCHEDULED_SITE_VISIT_STATUS.MISSED ||
      visitDetails.status === SCHEDULED_SITE_VISIT_STATUS.SITE_VISIT_DONE
    ) {
      setshowSiteVisitModal(true);
      return;
    }
    if (
      visitDetails.status === SCHEDULED_SITE_VISIT_STATUS.CONFIRM ||
      getNumberOfDays(visitDetails.visitDate) > 0
    ) {
      return;
    }

    const payload: ICpSiteVisit = {
      id: visitDetails.id,
      cpLead: visitDetails.cpLead.id,
      project: visitDetails.project.id,
      cpUser: visitDetails.cpUser.id,
      visitDate: visitDetails.visitDate,
      status: visitDetails.status,
    };
    setSiteVisitData(payload);
    setShowConfirmSheduleModal(true);
  };

  const getSiteVisitIcon = (siteVisitStatus: string, visitDate: Date) => {
    let icon: IconNames = 'event_note';
    const days = getNumberOfDays(visitDate);

    switch (siteVisitStatus) {
      case SCHEDULED_SITE_VISIT_STATUS.CONFIRM:
        icon = 'check_circle';
        break;
      case SCHEDULED_SITE_VISIT_STATUS.RESCHEDULED_SITE_VISIT:
        icon = days <= 0 ? 'check_circle_outline_outlined' : 'access_time';
        break;
      case SCHEDULED_SITE_VISIT_STATUS.SCHEDULED_SITE_VISIT:
        icon = days <= 0 ? 'check_circle_outline_outlined' : 'access_time';
        break;
    }

    return icon;
  };

  const handleSearch = (e: any) => {
    setSearchedLeadName(e.target.value.trim().toLowerCase());
  };

  const handleCloseSearch = () => {
    setSearchedLeadName('');
  };

  if (showLoader) {
    return (
      <div className={styles.spinWrap}>
        <SpinLoader />
      </div>
    );
  }

  if (siteVisitleads.length === 0) {
    return (
      <div className={styles.emptyLeads}>No Site Vist Leads are available</div>
    );
  }

  return (
    <div className={styles.itemContainer}>
      {showSiteVisitModal && (
        <SheduleSiteVisitModel
          onClosePopup={() => setshowSiteVisitModal(false)}
          projectName={project?.name as string}
          cpLead={cpLead as ICpLead}
          projectId={project?.id as TObjectId}
        />
      )}

      {showConfirmSheduleModal && (
        <SiteVistConfirmationModel
          onClosePopup={() => setShowConfirmSheduleModal(false)}
          projectName={project?.name as string}
          cpLead={cpLead as ICpLead}
          show={showConfirmSheduleModal}
          siteVisitData={siteVisitData as ICpSiteVisit}
        />
      )}
      {showSearch && (
        <div className={styles.searchWrapper}>
          <TextInput
            type='search'
            onChange={handleSearch}
            label=''
            placeHolder='Search lead names'
            propStyles={styles.searchContainer}
            startIconName='search'
            startIconVariant='SECONDARY'
            endIconName={searchedLeadName ? 'close' : undefined}
            onEndIconClick={handleCloseSearch}
            defaultValue={searchedLeadName}
            name='projects'
            setValue={setValue}
            register={register}
            unregister={unregister}
            crApp
            searchForMobile
          />
        </div>
      )}
      {uniqueVisitDates.map((visitingDate, index) => (
        <>
          <p className={styles.visitDate} key={index}>
            <span className={styles.visitDay}>
              {dateIsToday(visitingDate as Date)}
            </span>
            {dayMonthYearDay(visitingDate as Date)}
          </p>
          {searchedSiteVisits.map((visitDetails) => {
            if (visitDetails?.visitDate === visitingDate) {
              return (
                <>
                  <div key={visitDetails.id} className={styles.container}>
                    <div className={styles.leftConatainer}>
                      <div className={styles.cpLeadInfo}>
                        <p className={styles.leadndProjectname}>
                          {visitDetails?.cpLead.name}&nbsp;-&nbsp;
                          {visitDetails?.project.name}
                        </p>
                        <p className={styles.leadStatus}>
                          <Icon
                            name={getActivityIcon(
                              getNumberOfDays(
                                visitDetails.cpLead.lastActivityDate,
                                'startDate'
                              )
                            )}
                            propStyles={`${styles.substatusIcon} ${
                              styles[
                                getActivityIcon(
                                  getNumberOfDays(
                                    visitDetails.cpLead.lastActivityDate,
                                    'startDate'
                                  )
                                )
                              ]
                            }`}
                          />
                          <span className={styles.leadStatusText}>
                            {getProjectCount(
                              visitDetails?.cpLead.projects,
                              visitDetails?.cpLead.status
                            )}
                            {getLeadStatus(visitDetails?.cpLead.status)} | XXXXX
                            X{visitDetails?.cpLead.mobileLastFourDigits}
                          </span>
                        </p>
                      </div>
                    </div>
                    <div className={styles.rightContainer}>
                      <Icon
                        name={getSiteVisitIcon(
                          visitDetails.status,
                          visitDetails.visitDate
                        )}
                        propStyles={
                          visitDetails.status ===
                          SCHEDULED_SITE_VISIT_STATUS.CONFIRM
                            ? styles.greenCircle
                            : styles.visitIcon
                        }
                        onClick={() => {
                          handleShowSheduleVisit(visitDetails);
                        }}
                      />
                    </div>
                  </div>
                </>
              );
            }
            return null;
          })}
        </>
      ))}
    </div>
  );
};

export default SiteVisitTiles;
