import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { useGetOrganisationPartnersQuery } from 'apps/admin/services/projectPartnersAPISlice';
import { ITab } from 'apps/cp/components/common/Tabs';
import DesktopTabs from 'apps/admin/components/common/DesktopTabs';
import AnalyticsWrapper from 'apps/admin/components/pages/ProjectPartners/AnalyticsWrapper';
import { getAdminCompany, getAdminUser } from 'apps/admin/slices/adminSlice';
import { UserRole } from 'constants/userRoles';
import Partners from './Partners';

const OrganisationsPartners = () => {
  const user = useSelector(getAdminUser);
  const company = useSelector(getAdminCompany);

  const { data: channelPartners = [], isLoading } =
    useGetOrganisationPartnersQuery({
      organisationId: user?.role === UserRole.ADMIN ? company?.id : '',
    });

  const activeChannelPartners = useMemo(() => {
    return channelPartners.filter((partner) => partner.assignedLeads !== 0);
  }, [channelPartners]);

  const registeredChannelPartners = useMemo(() => {
    return channelPartners.filter((partner) => partner.assignedLeads == 0);
  }, [channelPartners]);

  const tabs: ITab[] = [
    {
      id: 'activeLeads',
      name: 'Registered',
      content: <Partners channelPartners={registeredChannelPartners} />,
      disabled: false,
    },
    {
      id: 'registeredLeads',
      name: 'Active',
      content: <Partners channelPartners={activeChannelPartners} />,
      disabled: false,
    },
  ];

  const totalActiveLeads = channelPartners.reduce(
    (previousPartner, currentPartner) =>
      previousPartner + currentPartner.assignedLeads,
    0
  );

  const totalCollateralShared = channelPartners.reduce(
    (previousPartner, currentPartner) =>
      previousPartner + currentPartner.collateralsShared,
    0
  );

  const activePartnersSummary = {
    mainData: `${activeChannelPartners.length}`,
    changeData: '',
    changeDataClass: '',
    changeDesc: '',
  };
  const activeLeadsSummary = {
    mainData: `${totalActiveLeads}`,
    changeData: '',
    changeDataClass: '',
    changeDesc: '',
  };
  const collateralSharedSummary = {
    mainData: `${totalCollateralShared}`,
    changeData: '',
    changeDataClass: '',
    changeDesc: '',
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <AnalyticsWrapper
        activePartnersSummary={activePartnersSummary}
        activeLeadsSummary={activeLeadsSummary}
        collateralSharedSummary={collateralSharedSummary}
      />
      <DesktopTabs tabs={tabs} />;
    </>
  );
};

export default OrganisationsPartners;
