import { adminAPISlice } from 'apps/admin/services/index';
import { TObjectId } from 'interfaces';

export const paymentsAPISlice = adminAPISlice.injectEndpoints({
  endpoints: (build) => ({
    createPaymentOrder: build.mutation({
      query: ({ unitCartId, amount }) => ({
        url: `api/payment/order?unitCartId=${unitCartId}${
          amount ? `&amount=${amount}` : ''
        }`,
        method: 'POST',
      }),
    }),
    getNextPayableAmountForUnitCart: build.query({
      query: (unitCartId) => ({
        url: `api/payment/nextPayableAmount?unitCartId=${unitCartId}`,
        method: 'GET',
      }),
      transformResponse: (response: {
        data: Object;
        code: String;
        error: Object;
        message: String;
      }) => {
        return response.data;
      },
    }),
    successfulPaymentOrder: build.mutation({
      query: ({
        paymentOrderId,
        transactionId,
        unitCartId,
        mode,
        amountPaid,
      }: {
        paymentOrderId: string;
        transactionId: string;
        unitCartId: TObjectId;
        mode: string;
        amountPaid?: number;
      }) => ({
        url: `api/payment/successfulPayment`,
        method: 'PUT',
        body: {
          paymentOrderId,
          transactionId,
          unitCartId,
          mode,
          amountPaid,
        },
      }),
    }),
  }),

  overrideExisting: false,
});

export const {
  useCreatePaymentOrderMutation,
  useLazyGetNextPayableAmountForUnitCartQuery,
  useSuccessfulPaymentOrderMutation,
} = paymentsAPISlice;
