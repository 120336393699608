import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { ITab } from 'apps/cp/components/common/Tabs';
import DesktopTabs from 'apps/admin/components/common/DesktopTabs';
import FirmList from 'apps/admin/components/pages/AllFirms/FirmList';
import { useGetPartnerFirmsQuery } from 'apps/admin/services/companyAPISlice';
import { getAdminCompany, getAdminUser } from 'apps/admin/slices/adminSlice';
import { UserRole } from 'constants/userRoles';

import styles from './styles.module.css';

export interface IPartnerFirm {
  firmName: string;
  projectRegistrationDate?: Date;
  totalCPRegister?: number;
  totalLeadsRegister?: number;
  totalCollateralShared?: number;
  totalSiteVisit?: number;
  lostLead?: number;
  totalAssignedLeads?: number;
  pocMobile?: string;
  pocEmail?: string;
  gstNumber?: string;
  panNumber?: string;
  websiteUrl?: string;
  totalCPs?: string;
  CPFirmStatus?: string;
}

const AllFirms = () => {
  const user = useSelector(getAdminUser);
  const company = useSelector(getAdminCompany);

  const { data: partnerFirms = [], isLoading } = useGetPartnerFirmsQuery({
    organisationId: user?.role === UserRole.ADMIN ? company?.id : '',
  });

  const registeredFirms = useMemo(() => {
    return partnerFirms.filter(
      (firm) => firm.totalAssignedLeads && firm.totalAssignedLeads === 0
    );
  }, [partnerFirms]);

  const activeFirms = useMemo(() => {
    return partnerFirms.filter(
      (firm) => firm.totalAssignedLeads && firm.totalAssignedLeads > 0
    );
  }, [partnerFirms]);

  const tabs: ITab[] = [
    {
      id: 'active',
      name: 'Active',
      content: <FirmList partnerFirms={activeFirms} />,
      disabled: false,
    },
    {
      id: 'registered',
      name: 'Registered',
      content: <FirmList partnerFirms={registeredFirms} registered />,
      disabled: false,
    },
  ];

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className={styles.tabWrapper}>
      <h2 className={styles.heading}>All Firms</h2>
      <DesktopTabs tabs={tabs} />
    </div>
  );
};

export default AllFirms;
