import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import Modal from 'apps/booking/components/common/modal/Modal';
import Icon from 'apps/booking/components/common/Icon/index';
import { IUser } from 'apps/admin/interfaces';
import { useGetProjectsByUserIdQuery } from 'apps/admin/services/projectsAPISlice';
import { capitalizeFirstLetter } from 'utils/utils';
import useAdminLogOut from 'apps/admin/components/pages/AdminLogin/hooks/useAdminLogOut';
import { setAppSliceIsFetching } from 'slices/appSlice';
import { NOT_FOUND } from 'constants/status';

import styles from './styles.module.css';

interface IUserModal {
  onModalClose: Function;
  user: IUser;
}

const UserDetailsModal = (props: IUserModal) => {
  const { onModalClose, user } = props;
  const history = useHistory();

  const executiveDetails = [
    { label: 'Executive Name', value: user.name },
    { label: 'Phone Number', value: user.mobile },
    { label: 'Email Id', value: user.email },
  ];

  const {
    data: projectsByExecutive = [],
    isLoading,
    error,
  } = useGetProjectsByUserIdQuery(user.id);
  const { removeAdminUserLocal, logginOut } = useAdminLogOut();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setAppSliceIsFetching(logginOut));
  }, [logginOut]);

  const handleRemoveUser = async () => {
    await removeAdminUserLocal();
    history.push('/');
  };

  useEffect(() => {
    if ((error as any)?.data?.code === NOT_FOUND) {
      handleRemoveUser();
    }
    return () => {};
  }, [error]);

  if (isLoading) {
    return null;
  }

  return (
    <Modal
      onOutsideClick={() => {
        onModalClose(false);
      }}
      additionalClasses={styles.executiveDetailsModal}>
      <header className={styles.modalHeader}>
        <div>
          <h2 className={styles.modalHeading}>
            Details of {capitalizeFirstLetter(user.role.toLowerCase())}
          </h2>
          <p className={styles.modalDesc}>
            These are the details of the {user.role.toLowerCase()}. Admin can
            view the executive details, edit details of executive.
          </p>
        </div>
        <Icon
          name='close'
          onClick={() => {
            onModalClose(false);
          }}></Icon>
      </header>

      <main className={styles.modalBody}>
        <div className={styles.modalBodyLeftContainer}>
          {executiveDetails.map((executiveDetail) => {
            return (
              <>
                <p className={styles.executiveDetailsLabel}>
                  {executiveDetail.label}
                </p>
                <p className={styles.executiveDetailsValue}>
                  {executiveDetail.value}
                </p>
              </>
            );
          })}
        </div>
        <div>
          <p className={styles.executiveDetailsLabel}>
            Name of Assigned projects
          </p>
          <div className={styles.projectDetailsContainer}>
            {projectsByExecutive?.map((project) => {
              return (
                <>
                  <p className={styles.assignedProjectName}>{project.name}</p>
                  <p className={styles.assignedProjectDate}>
                    {/* {project.dateAssigned} */}
                  </p>
                </>
              );
            })}
            {!projectsByExecutive.length && 'No projects assigned'}
          </div>
        </div>
      </main>
    </Modal>
  );
};

export default UserDetailsModal;
