import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';

import { getAdminUserRole } from 'apps/admin/components/pages/AdminLogin/utils';
import SuperAdminDashboard from 'apps/admin/components/pages/SuperAdminDashboard/SuperAdminProjectList';
import { URLS } from 'constants/urls';
import { TAllowedUserRolesOnAdminPortal } from 'apps/admin/components/containers/AdminSidebar';
import OrganisationRoutes from 'apps/admin/routers/AdminRoutes/SuperAdminUserRoutes/OrganisationRoutes';
import ProjectRoutes from 'apps/admin/routers/AdminRoutes/SuperAdminUserRoutes/ProjectRoutes';

const SuperAdminUserRoutes = () => {
  const { path } = useRouteMatch();

  const getDefaultRedirect = () => {
    return `${URLS[getAdminUserRole() as TAllowedUserRolesOnAdminPortal]}`;
  };

  return (
    <Switch>
      <Route path={`${path}/overview`}>
        <SuperAdminDashboard />
      </Route>

      <Route path={`${path}/projects`}>
        <ProjectRoutes />
      </Route>

      <Route path={`${path}/organisations`}>
        <OrganisationRoutes />
      </Route>

      {/* 
      
      Note - Not in use at the moment. This components may be used in future.

      <Route exact path={`${path}/inventory-management`}>
        <InventoryManagement />
      </Route>

      <Route exact path={`${path}/cp-profile`}>
        <CpProfile />
      </Route>

      <Route exact path={`${path}/executive/leads`}>
        <LeadsDashboard />
      </Route> 

      */}

      <Redirect to={getDefaultRedirect()} />
    </Switch>
  );
};

export default SuperAdminUserRoutes;
