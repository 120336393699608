import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { useLazyGetUnitcartListQuery } from '../../../../api/unitCart';
import { URLS } from '../../../../constants/urls';
import { getUser } from '../../slices/auth';
import { getProject } from 'apps/booking/slices/projectSlice';
import { isEmpty } from 'utils/utils';

const DashboardNavItem = () => {
  const user = useSelector(getUser);
  const project = useSelector(getProject);
  const dashboardBaseUrl = `/dashboard/${project.id}`;
  const [getUnitcartListAPI, { data: unitCarts = [] }] =
    useLazyGetUnitcartListQuery();

  useEffect(() => {
    const getUnitCarts = async () => {
      getUnitcartListAPI({ userId: user.id, projectId: project.id });
    };
    if (!isEmpty(user) && !isEmpty(project)) {
      getUnitCarts();
    }
  }, [user, project, getUnitcartListAPI]);

  return (
    <li
      className={`nav__item 
        ${
          !(user?.id && unitCarts && unitCarts.length > 0) &&
          'nav__item--disabled'
        }`}>
      <NavLink to={dashboardBaseUrl}>Dashboard</NavLink>
    </li>
  );
};

export default DashboardNavItem;
