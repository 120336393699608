import {
  Summary,
  UsersListType,
} from 'apps/booking/components/common/admin/Interface';
import SummaryCompoent from 'apps/booking/components/common/admin/SummaryCompoent';
import UserNamesList from 'apps/booking/components/common/admin/UserNamesList';
import UserPhotosList from 'apps/booking/components/common/admin/UserPhotosList';

export interface LeadsStats {
  summaries: Summary[];
  projectName: string;
  brandName: string;
  LeadsCardsData: UsersListType;
}

const LeadsDashboardHeader = (props: LeadsStats) => {
  const { summaries, projectName, brandName, LeadsCardsData } = props;
  return (
    <>
      <div className='leads-dashboard-header'>
        <div className='names-container'>
          <div className='project-name'>{projectName}</div>
          <div className='brand-name'>{brandName}</div>
          <div className='leads-list'>
            <UserPhotosList {...LeadsCardsData} />
            <div className='leads-names-container'>
              <div className='lead-title'>Lead </div>
              <div className='leads-data'>
                <UserNamesList {...LeadsCardsData} />
              </div>
            </div>
          </div>
        </div>
        <div className='data-container'>
          {summaries.map((summery) => (
            <SummaryCompoent {...summery} />
          ))}
        </div>
      </div>
    </>
  );
};
export default LeadsDashboardHeader;
