// Components
import TextInput from 'apps/booking/components/common/inputs/TextInput';

// Styles
import styles from './styles.module.css';

// Local Interfaces
interface IAdminDashboardHeader {
  onGridSearch: Function;
}

const Header = (props: IAdminDashboardHeader) => {
  const { onGridSearch } = props;

  const handleAdminDashboardGridsSearch = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    onGridSearch(event.target.value);
  };

  return (
    <>
      {/* Admin dashboard header left part */}
      <div className={styles.adminDashboardHeader}>
        <div className={styles.headerLeftContainer}>
          <h2 className={styles.headerHeading}>Projects Dashboard</h2>
          <p className={styles.headerDes}>
            All Projects {` `}
            <span className={styles.boldHeaderDes}>Under One Dashboard</span>
          </p>
          <div className={styles.searchBoxContainer}>
            <TextInput
              label=''
              placeHolder='Search Name'
              startIconName='search'
              startIconVariant='SECONDARY'
              onChange={handleAdminDashboardGridsSearch}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
