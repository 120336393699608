import { MEASURE_UNIT } from 'apps/booking/constants';
import { getDateFormatInyyyymmdd } from './dateFormator';
import { ONE_CRORE, ONE_LAKH } from 'constants/index';

export const isEmpty = (obj: any) =>
  !obj || (obj && Object.keys(obj).length === 0 && obj.constructor === Object);

export const toIndianRupee = (number = 0) => {
  const roundOffNumber = Math.round(number);
  return parseFloat(roundOffNumber.toString()).toLocaleString('en-IN', {
    maximumFractionDigits: 0,
    style: 'currency',
    currency: 'INR',
  });
};

export const convertMeasureUnitToDisplayText = (unit: string) => {
  switch (unit) {
    case MEASURE_UNIT.SQFT:
      return 'Sq.Ft.';
    case MEASURE_UNIT.SQMT:
      return 'Sq.Mt.';
    default:
      return '';
  }
};

export const ordinalSuffixOf = (number: number) => {
  var j = number % 10,
    k = number % 100;
  if (j === 1 && k !== 11) {
    return number + 'st';
  }
  if (j === 2 && k !== 12) {
    return number + 'nd';
  }
  if (j === 3 && k !== 13) {
    return number + 'rd';
  }
  return number + 'th';
};

export const rankToWordMapping = [
  'Zeroth',
  'First',
  'Second',
  'Third',
  'Fourth',
  'Fifth',
  'Sixth',
  'Seventh',
  'Eighth',
  'Ninth',
  'Tenth',
  'Eleventh',
  'Twelfth',
  'Thirteenth',
  'Fourteenth',
  'Fifteenth',
  'Sixteenth',
  'Seventeenth',
  'Eighteenth',
  'Nineteenth',
];

export const randomId = () =>
  Math.random()
    .toString(36)
    .replace(/[^a-z]+/g, '')
    .substr(0, 5);

export const toTwoDigits = (seconds: number) => {
  return seconds < 10 ? '0' + seconds : seconds;
};

//get no. of days from startDate
export const getLiveSinceDays = (startDate: Date) => {
  const date1: any = new Date(startDate);
  const date2: any = new Date();

  return Math.ceil((date2 - date1) / (1000 * 60 * 60 * 24));
};

//get no. of days on the basis of yyyy-mm-dd
export const getNumberOfDays = (targetDate: Date, startDate?: string) => {
  const date1 = new Date(getDateFormatInyyyymmdd(targetDate));
  const date2 = new Date();
  const presentDate = new Date(getDateFormatInyyyymmdd(date2));

  if (startDate) {
    return (presentDate.getTime() - date1.getTime()) / (1000 * 60 * 60 * 24);
  }

  return (date1.getTime() - date2.getTime()) / (1000 * 60 * 60 * 24);
};

// program to convert first letter of a string to uppercase
export const capitalizeFirstLetter = (str: string): string => {
  // converting first letter to uppercase
  const capitalized = str.charAt(0).toUpperCase() + str.slice(1);

  return capitalized;
};

export const getPriceInFig = (priceValue: number) => {
  if (priceValue >= ONE_CRORE) {
    return `${(priceValue / ONE_CRORE).toFixed(2)} Cr`;
  } else if (priceValue >= ONE_LAKH) {
    return `${(priceValue / ONE_LAKH).toFixed(2)} L`;
  }

  return priceValue;
};

export const isInValidDate = (date: Date) => {
  const today = new Date();
  const currentYear = today.getFullYear();
  const currentMonth = today.getMonth();
  const currentDay = today.getDate();

  const targetDate = new Date(date);
  const targetYear = targetDate.getFullYear();
  const targetMonth = targetDate.getMonth();
  const targetDay = targetDate.getDate();

  if (targetYear < currentYear) {
    return true;
  }

  if (targetMonth < currentMonth) {
    return true;
  }

  if (targetDay < currentDay) {
    return true;
  }

  return false;
};
