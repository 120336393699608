import { useState } from 'react';

import Icon from 'apps/booking/components/common/Icon';
import Button from 'apps/booking/components/common/buttons/Button';
import PaymentModal from 'apps/admin/components/common/PaymentModal';
import { ILeadUnitCart } from 'apps/cp/interfaces';

import styles from './styles.module.css';

interface IUserPaymentCell {
  unitCart: ILeadUnitCart;
  updateList?: Function;
}

const UserPaymentCell = (props: IUserPaymentCell) => {
  const { unitCart, updateList } = props;

  const [paymentModalShown, setPaymentModalShown] = useState(false);

  return (
    <>
      {paymentModalShown && (
        <PaymentModal
          unitCart={unitCart}
          closePaymentModal={() => setPaymentModalShown(false)}
          updateList={updateList}
        />
      )}

      <Button
        accent='bordered'
        onClick={() => setPaymentModalShown(true)}
        type='submit'
        propStyles={styles.paymentBtn}>
        +&nbsp;&nbsp;Payments
      </Button>
    </>
  );
};

export default UserPaymentCell;
