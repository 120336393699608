import { useState } from 'react';

import 'ag-grid-enterprise';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import { GridReadyEvent, ICellRendererParams } from 'ag-grid-community';

import CompanyListHeader from './CompanyListHeader';
import NameCard from 'apps/booking/components/common/admin/NameCard';
import RegistrationRenderer, {
  DUMMY_GROWTH,
} from '../SuperAdminProjectList/cell-renderers/RegistrationRendererCell/index';
import { useGetSuperAdminDashboardCompaniesQuery } from 'apps/admin/services/companyAPISlice';
import LiveSinceDateCell from 'apps/admin/components/common/dates/LiveSinceDateCell';
import { IProject } from 'apps/admin/components/pages/SuperAdminDashboard/SuperAdminProjectList';
import { UserRole } from 'constants/userRoles';
import { awsBaseUrl } from 'constants/urls';
import CompanyListMoreMenu from './modals/CompanyListMoreMenu';
import { IUser } from 'apps/admin/interfaces';

import styles from './styles.module.css';

const OrganizationsList = () => {
  const [gridApi, setGridApi] = useState(null) as any;
  // We used type "any" at all other places previously as there is no type specified in the documentation

  const { data: companies } = useGetSuperAdminDashboardCompaniesQuery({});

  //AG-grid Default column definition
  const defaultColDef = {
    sortable: false,
    filter: false,
    resizable: false,
    suppressMenu: true,
    editable: false,
    flex: 1,
    wrapText: true,
  };

  //Ag-grid global search
  const handleGridSearch = (event: string) => {
    gridApi.setQuickFilter(event);
  };

  //This function will automatically be called when ag grid is ready
  const onGridReady = (params: GridReadyEvent) => {
    setGridApi(params.api);
  };

  const getOrganizationImageUrl = (params: ICellRendererParams) => {
    return `${awsBaseUrl}/common/AZAlphabets/${params.data.name[0].toUpperCase()}${
      +params.data.mobile % 5
    }.png`;
  };

  if (!companies) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <header>
        <CompanyListHeader handleOrganizationListSearch={handleGridSearch} />
      </header>

      <main>
        <div
          className='ag-theme-alpine'
          style={{ height: '100%', width: '100%' }}>
          <AgGridReact
            rowData={companies}
            onGridReady={onGridReady}
            defaultColDef={defaultColDef}
            domLayout={'autoHeight'}
            pagination={true}
            paginationPageSize={10}
            headerHeight={60}>
            <AgGridColumn
              hide={true}
              headerName=''
              field='name'
              cellRendererFramework={(params: ICellRendererParams) => (
                <p>{params.value}</p>
              )}></AgGridColumn>
            <AgGridColumn
              flex={3}
              field='companyName'
              headerName='Company names'
              cellRendererFramework={(params: ICellRendererParams) => {
                return (
                  <div className={styles.organizationColumnCell}>
                    <NameCard
                      {...{
                        imgUrl: `${getOrganizationImageUrl(params)}`,
                        title: params.data.name,
                        subtitle: params.data.rera,
                        disabled: params.data.disabled,
                      }}
                    />
                  </div>
                );
              }}></AgGridColumn>
            <AgGridColumn
              flex={2}
              headerName='Developer Name'
              field='displayName'
              cellClass='ag-cell-no-text-overflow'
              cellRendererFramework={(params: ICellRendererParams) => {
                return (
                  <div>
                    <p className={styles.displayName}>
                      {params.data.displayName}
                    </p>
                    <p className={styles.cityName}>{params.data.hqAddress}</p>
                  </div>
                );
              }}></AgGridColumn>
            <AgGridColumn
              flex={2}
              headerName='Live Since'
              field='createdAt'
              cellRendererFramework={LiveSinceDateCell}
              cellRendererParams={(params: ICellRendererParams) => {
                return {
                  liveSinceDate: params.data.createdAt,
                  disabled: params.data.disabled,
                };
              }}></AgGridColumn>
            <AgGridColumn
              flex={1.5}
              headerName='Registrations'
              cellClass='relata-ag-grid-cell-justify-center'
              cellRendererFramework={RegistrationRenderer}
              cellRendererParams={(params: ICellRendererParams) => {
                const projects = params.data.projects as IProject[];
                const leadCount = projects.reduce(
                  (count, project) => count + project.leads.length,
                  0
                );
                return {
                  disabled: params.data.disabled,
                  value: leadCount,
                  growth: DUMMY_GROWTH, //TODO
                };
              }}></AgGridColumn>
            <AgGridColumn
              flex={1}
              headerName='Projects'
              cellClass='relata-ag-grid-cell-justify-center'
              cellRendererFramework={(params: ICellRendererParams) => {
                return (
                  <p className={`${styles.displayName} ${styles.noOfProjects}`}>
                    {params.data.projects.length}
                  </p>
                );
              }}></AgGridColumn>
            <AgGridColumn
              flex={1}
              headerName='Executives'
              cellClass='relata-ag-grid-cell-justify-center'
              cellRendererFramework={(params: ICellRendererParams) => {
                const users = params.data.users as IUser[];
                const executives = users.filter(
                  (user) => user.role === UserRole.EXECUTIVE
                );
                return (
                  <p className={`${styles.displayName} ${styles.noOfProjects}`}>
                    {executives.length}
                  </p>
                );
              }}></AgGridColumn>
            <AgGridColumn
              flex={1}
              // More Actions
              headerName=''
              cellRendererFramework={CompanyListMoreMenu}
              cellRendererParams={(params: ICellRendererParams) => {
                return {
                  rowData: params,
                };
              }}></AgGridColumn>
          </AgGridReact>
        </div>
      </main>
    </div>
  );
};

export default OrganizationsList;
