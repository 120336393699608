import { dateToDaythMonthYear } from 'utils/dateFormator';

import styles from './styles.module.css';

interface IDateCell {
  date: Date;
  disabled?: boolean;
}

const DateCell = (props: IDateCell) => {
  const { date, disabled = false } = props;

  return (
    <div>
      <p
        className={`${styles.liveSinceDateValue} ${
          disabled ? styles.projectDisabled : ''
        }`}>
        {dateToDaythMonthYear(date)}
      </p>
    </div>
  );
};

export default DateCell;
