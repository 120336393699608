import Modal from 'apps/booking/components/common/modal/Modal';
import Icon from 'apps/booking/components/common/Icon';

import styles from './styles.module.css';

interface IPartnerLeadDetailsModalProps {
  moreDetails: {
    label: string;
    value: string;
  }[];
  modalHeading: string;
  onModalClose: () => void;
}

const PartnerLeadDetailsModal = (props: IPartnerLeadDetailsModalProps) => {
  const { moreDetails, modalHeading, onModalClose } = props;

  return (
    <Modal
      onOutsideClick={onModalClose}
      additionalClasses={styles.unitDetailsModal}>
      <header className={styles.modalHeader}>
        <div>
          <h2 className={styles.modalHeading}>{modalHeading}</h2>
        </div>
        <Icon name='close' onClick={onModalClose} />
      </header>

      <main className={styles.modalBody}>
        <div className={styles.modalBodyLeftContainer}>
          {moreDetails.map((detail) => {
            return (
              <>
                <p className={styles.unitDetailsLabel}>{detail.label}</p>
                <p className={styles.unitDetailsValue}>{detail.value}</p>
              </>
            );
          })}
        </div>
      </main>
    </Modal>
  );
};

export default PartnerLeadDetailsModal;
