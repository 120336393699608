import { useSelector } from 'react-redux';

import BlockedUnitCountDownTimer from '../common/cards/BlockedUnitCountDownTimer';
import BookingAmountCard from '../common/cards/BookingAmountCard';

import { getSelectedUnit, getUnitCart } from '../../slices/selectedUnit';
import { isEmpty } from '../../../../utils/utils';

const CostSheetContentHeader = () => {
  const unitCart = useSelector(getUnitCart);
  let selectedUnit = useSelector(getSelectedUnit);
  if (!isEmpty(unitCart)) {
    selectedUnit = unitCart.unit;
  }

  return (
    <header className='content-header'>
      <div className='content-header__title-plate'>
        <h1 className='content-header__title'>
          You are viewing the{' '}
          <span className='font-accent-bold'>
            Cost Sheet for Unit {selectedUnit.number}, {selectedUnit.type}
          </span>
        </h1>
        <p className='content-header__description'>Cost Break-up</p>
      </div>

      <div className='content-header__card-container'>
        <BlockedUnitCountDownTimer />
        <BookingAmountCard />
      </div>
    </header>
  );
};

export default CostSheetContentHeader;
