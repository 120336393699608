import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MasterSVG from './MasterSVG';
import TowerSVG from './TowerSVG';
import {
  getSelectedTower,
  getSelectedSection,
  getSelectedBlock,
  getUnitSelectionHeaderNavState,
} from 'apps/booking/slices/unitSelectionHeader/unitSelectionHeaderNav';
import { setSelectedUnit, setUnitCart } from '../../slices/selectedUnit';
import { getLanguageText } from 'constants/multiLanguageTexts';
import { getUserLanguage } from 'apps/booking/slices/userlanguage';

const UnitSelection = () => {
  const dispatch = useDispatch();
  const navState = useSelector(getUnitSelectionHeaderNavState);
  const selectedSection = useSelector(getSelectedSection);
  const userLanguage = useSelector(getUserLanguage);

  useEffect(() => {
    dispatch(setSelectedUnit({}));
    dispatch(setUnitCart({}));
  }, [dispatch]);

  return (
    <div className='unit-selection'>
      {navState === 'MASTER' ? (
        <MasterSVG name='MASTER' />
      ) : (
        <TowerSVG selectedSection={selectedSection} />
      )}
      <div className='svg-path-color-labels'>
        <span className='svg-path-color-labels__item'>
          <div className='svg-path-color-labels__item-color-indicator svg-path-color-labels__item-color-indicator--available'></div>
          <span className='svg-path-color-labels__item-label'>
            {getLanguageText(userLanguage, 'available')}
          </span>
        </span>
        <span className='svg-path-color-labels__item'>
          <div className='svg-path-color-labels__item-color-indicator svg-path-color-labels__item-color-indicator--filtered'></div>
          <span className='svg-path-color-labels__item-label'>
            {getLanguageText(userLanguage, 'filtered')}
          </span>
        </span>
      </div>
    </div>
  );
};

export default UnitSelection;
