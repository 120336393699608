export enum PRICE_UNIT {
  LAKH = 'Lakh',
  CRORE = 'Cr',
}

export enum MEASURE_UNIT {
  SQMT = 'SQMT',
  SQFT = 'SQFT',
  METER = 'METER',
}

export enum COSTSHEET_SECTION {
  SUMMARY = 'SUMMARY',
  BREAKUP = 'BREAKUP',
  ADDITIONAL = 'ADDITIONAL',
}

export enum COSTSHEET_ITEM_TYPE {
  AGREEMENT_COST = 'AGREEMENT_COST',
  PLC = 'PLC',
  EDC = 'EDC',
  CLUB_CHARGE = 'CLUB_CHARGE',
  IFMS = 'IFMS',
  GST = 'GST',
}

export enum MILESTONE_KEY {
  NAME = 'name',
  PERCENTAGE = 'percentage',
  PROPERTY_VALUE = 'property_value',
  GST = 'gst',
  NET_TAXABLE_AMOUNT = 'net_taxable_amount',
}

//TODO: handle this at admin-portal + backend
export const EOI_PAYMENT = {
  PLATINUM: {
    ROOTS: 300000,
    WAVE: 500000,
    AIR: 1000000,
    SKY: 2000000,
  },
  SILVER: {
    ROOTS: 50000,
    WAVE: 50000,
    AIR: 50000,
    SKY: 50000,
  },
};

export enum APPLICANT_TYPE {
  PRIMARY = 'Primary',
  SECONDARY = 'Secondary',
}

export enum MARITAL_STATUS {
  SINGLE = 'Single',
  MARRIED = 'Married',
  DIVORCED = 'Divorced',
  WIDOWED = 'Widowed',
}

export enum PERSON_TITLE {
  MR = 'Mr.',
  MRS = 'Mrs.',
  MS = 'Ms.',
}

export const PAYMENT_MODES = {
  CASH_CHALLAN: {
    value: 'CASH_CHALLAN',
    displayText: 'Cash challan',
  },
  CHEQUE: { value: 'CHEQUE', displayText: 'Cheque' },
  NETBANKING: { value: 'NETBANKING', displayText: 'Netbanking' },
  RAZORPAY: { value: 'RAZORPAY', displayText: 'Razorpay' },
};
