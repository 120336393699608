import { useState } from 'react';

import { GridReadyEvent, ICellRendererParams } from 'ag-grid-community';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';

import NameCard from 'apps/booking/components/common/admin/NameCard';
import { ICpLead, IProjectUnitLead } from 'apps/cp/interfaces';
import { DEFAULT_NULL_VALUE } from 'apps/admin/components/common/ExpandedDetailSection';
import { dateToDaythMonthYear } from 'utils/dateFormator';
import TextInput from 'apps/booking/components/common/inputs/TextInput';
import DateCell from 'apps/admin/components/common/dates/DateCell';
import { awsBaseUrl } from 'constants/urls';
import ExpandRowCell from 'apps/admin/components/pages/ActiveLeads/cell-renderers/ExpandRowCell';
import ExpandedUnitDetailsSection from 'apps/admin/components/pages/ProjectLeads/LeadList/ExpandedUnitDetailsSection';
import { getProgressByValue } from 'apps/booking/components/SidebarItem';

import styles from './styles.module.css';

interface ILeadListProps {
  leadsList: IProjectUnitLead[];
  projectId: string;
  updateLeadList?: Function;
}

const LeadList = (props: ILeadListProps) => {
  const { leadsList, projectId, updateLeadList } = props;

  const [gridApi, setGridApi] = useState(null) as any;

  const onGridReady = (params: GridReadyEvent) => {
    setGridApi(params.api);
  };

  const handleGridSearch = (searchString: string) => {
    gridApi?.setQuickFilter(searchString);
  };

  // TODO: TO update the api functionality
  const handelLeadStatusUpdate = (updatedLeadStatus: ICpLead) => {};

  const getUserAvatarUrl = (params: ICellRendererParams) => {
    return `${awsBaseUrl}/common/AZAlphabets/${params.data?.user.name[0].toUpperCase()}${
      +params.data?.user.mobile % 5
    }.png`;
  };

  return (
    <>
      <header>
        <div className={styles.searchContainer}>
          <TextInput
            type='text'
            inputHTMLElementStyles={styles.searchInput}
            placeHolder='Search Name'
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              handleGridSearch(event.target.value);
            }}
            startIconName='search'
            startIconVariant='SECONDARY'
          />
        </div>
      </header>
      <div
        className={`ag-theme-alpine ${styles.gridContainer}`}
        style={{ width: '100%', height: '100%' }}>
        <AgGridReact
          rowData={leadsList}
          defaultColDef={{
            sortable: false,
            filter: false,
            resizable: false,
            suppressMenu: true,
          }}
          domLayout={'autoHeight'}
          pagination={true}
          paginationPageSize={10}
          masterDetail={true}
          animateRows={true}
          detailCellRenderer={'detailCellRenderer'}
          detailRowAutoHeight={true}
          onGridReady={onGridReady}
          frameworkComponents={{
            detailCellRenderer: (params: ICellRendererParams) => (
              <ExpandedUnitDetailsSection
                unitCarts={params.data.unitCarts}
                updateLeadList={updateLeadList}
              />
            ),
          }}>
          <AgGridColumn
            flex={0.8}
            cellClass='ag-grid-column-custom-cell'
            cellRendererFramework={ExpandRowCell}
            cellRendererParams={(params: ICellRendererParams) => params}
          />
          <AgGridColumn flex={0.5} hide={true} field='user.mobile' />
          <AgGridColumn
            flex={3}
            headerName='Name'
            field='user.name'
            cellRendererFramework={(params: ICellRendererParams) => (
              <div className={styles.projectsColumnDataContainer}>
                {params.data.user.name ? (
                  <NameCard
                    {...{
                      imgUrl: `${getUserAvatarUrl(params)}`,
                      title: params.data.user.name,
                      subtitle: `Mob: ${params.data.user.mobile}`,
                    }}
                  />
                ) : (
                  DEFAULT_NULL_VALUE
                )}
              </div>
            )}
          />
          <AgGridColumn
            flex={1}
            headerName='Enquiries'
            cellRendererFramework={(params: ICellRendererParams) => (
              <p className={styles.cpName}>
                {params.data?.unitCarts?.length || DEFAULT_NULL_VALUE}
              </p>
            )}
          />
          <AgGridColumn
            flex={2}
            headerName='Last Activity on'
            cellRendererFramework={(params: ICellRendererParams) => (
              <p className={styles.cpName}>
                {params.data
                  ? dateToDaythMonthYear(params.data.lastActivityOn)
                  : DEFAULT_NULL_VALUE}
              </p>
            )}
          />
          <AgGridColumn
            flex={2}
            headerName='Registered On'
            field='createdAt'
            cellRendererFramework={DateCell}
            cellRendererParams={(params: ICellRendererParams) => ({
              date: params.data.user.createdAt,
            })}
          />

          <AgGridColumn
            headerName='Status'
            field='status'
            flex={1}
            cellRendererFramework={(params: ICellRendererParams) => (
              <p className={styles.cpName}>
                {getProgressByValue(params.data.progress) || DEFAULT_NULL_VALUE}
              </p>
            )}
          />
          <AgGridColumn
            headerName='Last Action'
            field='recentActivity'
            flex={1.8}
            cellRendererFramework={(params: ICellRendererParams) => (
              <p className={styles.cpName}>
                {getProgressByValue(params.data.recentActivity) ||
                  DEFAULT_NULL_VALUE}
              </p>
            )}
          />
        </AgGridReact>
      </div>
    </>
  );
};

export default LeadList;
