import { useEffect, useState } from 'react';

import 'ag-grid-enterprise';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import { GridReadyEvent, ICellRendererParams } from 'ag-grid-community';

import NameCard from 'apps/booking/components/common/admin/NameCard';
import { awsBaseUrl } from 'constants/urls';
import { IUser } from 'apps/admin/interfaces';
import Tooltip from 'apps/booking/components/common/Tooltip';
import UpdateUserCell, {
  IUpdateUserCell,
} from 'apps/admin/components/pages/AdminDashboard/AdminExecutiveList/Body/UserListGrid/UpdateUserCell';
import UserDetailsCell from 'apps/admin/components/pages/AdminDashboard/AdminExecutiveList/Body/UserListGrid/UserDetailsCell';
import RemoveCompanyUserCell, {
  IRemoveCompanyUserCell,
} from 'apps/admin/components/pages/AdminDashboard/AdminExecutiveList/Body/UserListGrid/RemoveCompanyUserCell';
import { dateToDaythMonthYear } from 'utils/dateFormator';
import TextInput from 'apps/booking/components/common/inputs/TextInput';

import styles from './styles.module.css';
import genericStyles from '../../../../../../../../styles/genericStyles.module.css';

interface IUserListGrid {
  onExecutiveListSearch?: Function;
  users: IUser[];
  companyId: string;
}

//AG-grid Default column definition
const defaultColDef = {
  sortable: false,
  filter: false,
  resizable: false,
  suppressMenu: true,
  editable: false,
  flex: 1,
};

const UserListGrid = (props: IUserListGrid) => {
  const { onExecutiveListSearch, users: _users = [], companyId } = props;

  const [users, setUsers] = useState<IUser[]>([]);

  const [gridApi, setGridApi] = useState(null) as any;

  useEffect(() => {
    setUsers(_users);
  }, [_users]);

  //This function will automatically be called when ag grid is ready
  const onGridReady = (params: GridReadyEvent) => {
    setGridApi(params.api);
  };

  //Ag-grid global search
  const handleGridSearch = (searchString: string) => {
    gridApi?.setQuickFilter(searchString);
  };

  onExecutiveListSearch && onExecutiveListSearch(handleGridSearch);

  const getExecutiveAvatarUrl = (params: ICellRendererParams) => {
    return `${awsBaseUrl}/common/AZAlphabets/${params.data.name[0].toUpperCase()}${
      +params.data.mobile % 5
    }.png`;
  };

  const handleUserDelete = (userId: string) => {
    setUsers(users.filter((u) => u.id !== userId));
  };

  return (
    <>
      <div className={styles.searchBoxContainer}>
        <TextInput
          label=''
          placeHolder='Search Name'
          startIconName='search'
          startIconVariant='SECONDARY'
          onChange={(event: { target: { value: string } }) =>
            handleGridSearch(event?.target.value)
          }
        />
      </div>
      <div
        className={`ag-theme-alpine ${styles.gridContainer}`}
        style={{ height: '100%', width: '100%' }}>
        <AgGridReact
          rowData={users}
          defaultColDef={defaultColDef}
          onGridReady={onGridReady}
          domLayout={'autoHeight'}
          pagination={true}
          paginationPageSize={10}
          headerHeight={60}>
          <AgGridColumn
            hide={true}
            headerName=''
            field='name'
            cellRendererFramework={(params: ICellRendererParams) => (
              <p>{params.value}</p>
            )}
          />
          <AgGridColumn
            headerName='Names'
            field='name'
            flex={3}
            cellRendererFramework={(params: ICellRendererParams) => (
              <NameCard
                {...{
                  imgUrl: `${getExecutiveAvatarUrl(params)}`,
                  title: params.data.name,
                  subtitle: params.data.email,
                  disabled: params.data.disabled,
                }}
              />
            )}
          />
          <AgGridColumn
            headerName='Mobile'
            field='mobile'
            flex={1.8}
            cellRendererFramework={(params: ICellRendererParams) => (
              <p>{params.data.mobile}</p>
            )}
          />

          <AgGridColumn
            headerName='Registered On'
            field='mobile'
            flex={2}
            cellRendererFramework={(params: ICellRendererParams) => (
              <p>{dateToDaythMonthYear(params.data.createdAt)}</p>
            )}
          />

          <AgGridColumn
            flex={1.5}
            headerName='Edit'
            cellRendererFramework={UpdateUserCell}
            cellRendererParams={(
              params: ICellRendererParams
            ): IUpdateUserCell => ({
              user: params.data as IUser,
              rowNode: params.node,
              disabled: false,
              userRole: params.data.role,
            })}
          />
          <AgGridColumn
            flex={1.5}
            headerName='Remove'
            cellRendererFramework={RemoveCompanyUserCell}
            cellRendererParams={(
              params: ICellRendererParams
            ): IRemoveCompanyUserCell => ({
              user: params.data as IUser,
              companyId: companyId,
              onUserDelete: handleUserDelete,
            })}
          />
          <AgGridColumn
            flex={1.5}
            headerName='Details'
            cellRendererFramework={UserDetailsCell}
            cellRendererParams={(params: ICellRendererParams) => ({
              user: params.data as IUser,
            })}
          />
        </AgGridReact>
      </div>
    </>
  );
};

export default UserListGrid;
