import { useState } from 'react';

import { ICellRendererParams } from 'ag-grid-community';

import Icon from 'apps/booking/components/common/Icon';
import { IUser } from 'apps/admin/interfaces';
import UpdateUserModal from 'apps/admin/components/pages/AdminDashboard/AdminExecutiveList/modals/UpdateUserModal';
import { UserRole } from 'constants/userRoles';

export interface IUpdateUserCell {
  user: IUser;
  rowNode: ICellRendererParams['node'];
  onUserUpdate?: (updatedUser: IUser) => void;
  disabled: boolean;
  userRole: string;
}

const UpdateUserCell = (props: IUpdateUserCell) => {
  const { user, rowNode, onUserUpdate, disabled, userRole } = props;
  const [updateUserModalShown, setUpdateUserModalShown] = useState(false);

  const handleUserUpdate = (updatedUser: IUser) => {
    rowNode.setData(updatedUser);
    onUserUpdate && onUserUpdate(updatedUser);
  };

  if (userRole !== UserRole.EXECUTIVE) {
    return null;
  }

  return (
    <>
      {updateUserModalShown && (
        <UpdateUserModal
          onModalClose={() => setUpdateUserModalShown(false)}
          user={user}
          onUserUpdate={handleUserUpdate}
        />
      )}
      <Icon
        name='person_outline'
        propStyles={`actionIcon ${disabled && 'click-disabled--icon'} `}
        onClick={() => setUpdateUserModalShown(true)}
      />
    </>
  );
};

export default UpdateUserCell;
