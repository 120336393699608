import { useState } from 'react';
import { useSelector } from 'react-redux';

import { getAdminCompany } from 'apps/admin/slices/adminSlice';
import Body from 'apps/admin/components/pages/AdminDashboard/AdminExecutiveList/Body';
import Header from 'apps/admin/components/pages/AdminDashboard/AdminExecutiveList/Header';

interface IAdminDashboardProps {
  userRole: string;
}

const AdminDashboard = (props: IAdminDashboardProps) => {
  const { userRole } = props;

  const [gridSearchTerm, setGridSearchTerm] = useState('');

  const company = useSelector(getAdminCompany);

  const handleGridSearch = (searchTerm: string) => {
    setGridSearchTerm(searchTerm);
  };

  // TODO: Make Loading generic
  if (!company) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Header
        onGridSearch={handleGridSearch}
        companyId={company.id as string}
      />
      <Body
        gridSearchTerm={gridSearchTerm}
        company={company}
        userRole={userRole}
      />
    </>
  );
};

export default AdminDashboard;
