import Button from 'apps/booking/components/common/buttons/Button';
import Icon from 'apps/booking/components/common/Icon/index';
import { ISiteVisitDetails } from 'apps/admin/components/pages/forms/SiteVisit';
import { ICpPreview, getCpNameInitials } from '../DetailsForm/AssingedCpInfo';
import MoreMenu, { MenuItem } from 'apps/admin/components/common/MoreMenu';

import styles from './styles.module.css';

interface ISiteVisitSummery {
  siteVisitDetails: ISiteVisitDetails;
  assignedCpPreview?: ICpPreview;
  headerMoreMenuOptions: MenuItem[];
}

const SiteVisitSummery = (props: ISiteVisitSummery) => {
  const { siteVisitDetails, assignedCpPreview, headerMoreMenuOptions } = props;

  const handleRedirectSiteVisit = () => {
    window.location.reload();
  };

  return (
    <div>
      <header className={styles.header}>
        <div className={styles.menuWrapper}>
          <Icon name='task_alt' propStyles={styles.confirmationIcon} />
          <MoreMenu menuItems={headerMoreMenuOptions} />
        </div>
        <h2 className={styles.headerTitle}>Data Added Successfully</h2>
        <p className={styles.headerDesc}>
          Customer personal siteVisitDetails added successfully. Now you can
          keep track of customer site visits
        </p>
      </header>

      <main className={styles.main}>
        <p className={styles.mainHeading}>Personal Information</p>
        <div className={styles.personalInfoSection}>
          <div className={styles.detailsRow}>
            <div className={styles.individualDetailsSet}>
              <p className={styles.detailsHeading}>Customer Name</p>
              <p className={styles.detailsValue}>
                {siteVisitDetails.customerName}
              </p>
            </div>
            <div className={styles.individualDetailsSet}>
              <p className={styles.detailsHeading}>Email Id</p>
              <p className={styles.detailsValue}>
                {siteVisitDetails.customerEmail}
              </p>
            </div>
          </div>
          <div className={styles.detailsRow}>
            <div className={styles.individualDetailsSet}>
              <p className={styles.detailsHeading}>Phone Number</p>
              <p className={styles.detailsValue}>
                +91 {siteVisitDetails.customerMobile}
              </p>
            </div>
            <div className={styles.individualDetailsSet}>
              <p className={styles.detailsHeading}>Location</p>
              <p className={styles.detailsValue}>{siteVisitDetails.location}</p>
            </div>
          </div>

          <div className={styles.divider}></div>
        </div>
        {assignedCpPreview && (
          <>
            <p className={styles.cpHeading}>Assigned Channel Partner</p>

            <div className={styles.cpInfoWrapper}>
              <div className={styles.cpInfo}>
                <div className={styles.nameIntials}>
                  {getCpNameInitials(assignedCpPreview.name)}
                  <span className={styles.checkIcon}>
                    <Icon name='check_circle' propStyles={styles.rightIcon} />
                  </span>
                </div>
                <div className={styles.contentText}>
                  <span className={styles.cpName}>
                    {assignedCpPreview.name}
                  </span>
                  <span className={styles.smName}>
                    {assignedCpPreview.sm.name}
                  </span>
                </div>
              </div>
            </div>
          </>
        )}

        {/* <p className={styles.mainHeading}>Requirement</p> */}
        {/* <div>
          <div className={styles.detailsRow}>
            <div className={styles.individualDetailsSet}>
              <p className={styles.detailsHeading}>Date Of Visit</p>
              <p className={styles.detailsValue}>
                {`${String(
                  new Date(siteVisitDetails.visitDate).getDate()
                ).padStart(2, '0')}/${String(
                  +new Date(siteVisitDetails.visitDate).getMonth() + 1
                ).padStart(2, '0')}/${new Date(
                  siteVisitDetails.visitDate
                ).getFullYear()}`}
              </p>
            </div>
            <div className={styles.individualDetailsSet}>
              <p className={styles.detailsHeading}>Configuration</p>
              <p className={styles.detailsValue}>
                {siteVisitDetails.configs.join(' ')}
              </p>
            </div>
          </div>
          <div className={styles.detailsRow}>
            <div className={styles.individualDetailsSet}>
              <p className={styles.detailsHeading}>Location preference</p>
              <p className={styles.detailsValue}>
                {siteVisitDetails.preferenceAreasTemp}
              </p>
            </div>
            <div className={styles.individualDetailsSet}>
              <p className={styles.detailsHeading}>Budget</p>
              <p className={styles.detailsValue}>
                {`${siteVisitDetails.priceRange?.min} - ${siteVisitDetails.priceRange?.max}`}
              </p>
            </div>
          </div>
        </div> */}
        {/* <p className={styles.detailsHeading}>Customer Feedback</p>
        <p className={styles.detailsValue}>
          {siteVisitDetails.customerFeedback || 'NA'}
        </p> */}
      </main>

      <footer className={styles.footer}>
        {/* 
        // TODO: Edit functionality is broken
        <Button
          propStyles={styles.footerButton}
          onClick={() => setSiteVisitState(SiteVisitState.DETAILS_FORM)}>
          Edit
        </Button> */}
        <Button
          propStyles={styles.footerButton}
          onClick={() => handleRedirectSiteVisit()}
          accent='primary'>
          Done
        </Button>
      </footer>
    </div>
  );
};

export default SiteVisitSummery;
