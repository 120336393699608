import { useState } from 'react';

import 'ag-grid-enterprise';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import { GridReadyEvent, ICellRendererParams } from 'ag-grid-community';

import { useGetUsersByRoleQuery } from 'apps/admin/services/adminUserAPISlice';
import NameCard from 'apps/booking/components/common/admin/NameCard';
import Tooltip from 'apps/booking/components/common/Tooltip';
import { awsBaseUrl } from 'constants/urls';
import { dateToDaythMonthYear } from 'utils/dateFormator';
import TextInput from 'apps/booking/components/common/inputs/TextInput';

import genericStyles from '../../../../../styles/genericStyles.module.css';
import styles from './styles.module.css';

interface IUserListByRoleProps {
  userRole: string;
}

const UserListByRole = (props: IUserListByRoleProps) => {
  const { userRole } = props;

  const { data: userListByrole = [], isLoading } =
    useGetUsersByRoleQuery(userRole);

  const [gridApi, setGridApi] = useState(null) as any;

  const defaultColDef = {
    sortable: false,
    filter: false,
    resizable: false,
    suppressMenu: true,
    editable: false,
    flex: 1,
  };

  // Ag-grid global search
  const handleGridSearch = (event: string) => {
    gridApi.setQuickFilter(event);
  };

  const onGridReady = (params: GridReadyEvent) => {
    setGridApi(params.api);
  };

  const getUserAvatarUrl = (params: ICellRendererParams) => {
    return `${awsBaseUrl}/common/AZAlphabets/${params.data.name[0].toUpperCase()}${
      +params.data.mobile % 5
    }.png`;
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <header className={styles.organizationListHeader}>
        {/* Header Left Part */}
        <div className={styles.headerContainer}>
          <h2 className={styles.dashboardHeading}>
            All {userRole.toLowerCase()} users
          </h2>
          <p className={styles.dashboardDesc}>
            {userRole.toLowerCase()} users
            <span className={styles.boldTextForDashboardDesc}>
              Under One Dashboard
            </span>
          </p>
          <div className={styles.searchContainer}>
            <TextInput
              type='text'
              inputHTMLElementStyles={styles.searchInput}
              placeHolder='Search Name, Email & Mobile Number'
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                handleGridSearch(event.target.value);
              }}
              startIconName='search'
              startIconVariant='SECONDARY'
            />
          </div>
        </div>
      </header>
      <div
        className={`ag-theme-alpine ${styles.gridContainer}`}
        style={{ height: '100%', width: '100%' }}>
        <AgGridReact
          rowData={userListByrole}
          defaultColDef={defaultColDef}
          onGridReady={onGridReady}
          domLayout={'autoHeight'}
          pagination={true}
          paginationPageSize={10}
          headerHeight={60}>
          <AgGridColumn
            hide={true}
            headerName=''
            field='name'
            cellRendererFramework={(params: ICellRendererParams) => (
              <p>{params.value}</p>
            )}
          />
          <AgGridColumn
            headerName='Names'
            field='name'
            flex={4}
            cellRendererFramework={(params: ICellRendererParams) => (
              <NameCard
                {...{
                  imgUrl: `${getUserAvatarUrl(params)}`,
                  title: params.data.name,
                  subtitle: params.data.email,
                  disabled: params.data.disabled,
                }}
              />
            )}
          />
          <AgGridColumn
            headerName='Mobile'
            field='mobile'
            flex={4}
            cellRendererFramework={(params: ICellRendererParams) => (
              <p>{params.data.mobile}</p>
            )}
          />

          <AgGridColumn
            flex={4}
            headerName='Email'
            field='email'
            cellClass='relata-ag-grid-cell-wrap'
            cellRendererFramework={(params: ICellRendererParams) => (
              <Tooltip title='Click to Copy'>
                <p
                  onClick={() => {
                    navigator.clipboard.writeText(`${params.data.email}`);
                  }}
                  className={`${genericStyles.pointer} relata-ag-grid-wrappable-text ${styles.adminDetailsTextHoverAction}`}>
                  {params.data.email}
                </p>
              </Tooltip>
            )}
          />
          <AgGridColumn
            headerName='Registered On'
            field='createdAt'
            flex={2}
            cellRendererFramework={(params: ICellRendererParams) => (
              <p>{dateToDaythMonthYear(params.data.createdAt)}</p>
            )}
          />
        </AgGridReact>
      </div>
    </>
  );
};

export default UserListByRole;
