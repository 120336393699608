import { useDispatch, useSelector } from 'react-redux';
import {
  getCostSheet,
  getIsFetchingUnitCart,
  getSelectedEOI,
  getSelectedScheme,
  getSelectedUnit,
  getUnitCart,
  setSelectedScheme,
} from '../../slices/selectedUnit';
import { isEmpty, toIndianRupee } from 'utils/utils';
import { EOI_PAYMENT, MILESTONE_KEY } from 'apps/booking/constants';

const PaymentPlanPlank = () => {
  // const costSheet = useSelector(getCostSheet);
  const unitCart = useSelector(getUnitCart);
  const isFetchingUnitCart = useSelector(getIsFetchingUnitCart);
  const dispatch = useDispatch();
  const selectedUnit = unitCart.unit;

  let selectedScheme = useSelector(getSelectedScheme);
  let schemeId = unitCart?.selectedSchemeId;
  if (schemeId && isEmpty(selectedScheme)) {
    selectedScheme = selectedUnit.schemes.find(
      (scheme) => scheme._id == schemeId
    );
  }

  if (isEmpty(selectedScheme)) {
    selectedScheme = unitCart.unit.schemes[0];
    dispatch(setSelectedScheme(unitCart.unit.schemes[0]));
  }
  const milestone_titles = selectedScheme.milestones[0];
  const milestones = selectedScheme.milestones.slice(1);

  const completed_milestone = {
    name: 'Total',
    percentage: 0,
    property_value: 0,
    gst: 0,
    net_taxable_amount: 0,
  };

  milestones.forEach((milestone) => {
    Object.entries(milestone).forEach(([key, value]) => {
      if (key == MILESTONE_KEY.NAME) {
        return;
      }
      completed_milestone[key] += Number(value.replace('%', ''));
    });
  });

  // const scroller = useRef();
  // const seeMoreOverlayBottomElm = useRef();
  // const tableHeader = useRef();
  // useEffect(() => {
  //   const scrollHandler = (event) => {
  //     var element = event.target;
  //     if (
  //       element.scrollHeight - Math.ceil(element.scrollTop) ===
  //       element.clientHeight
  //     ) {
  //       seeMoreOverlayBottomElm.current.classList.add(
  //         'payment-plan-milstone-table__seemore-overlay--hide'
  //       );
  //     } else {
  //       seeMoreOverlayBottomElm.current.classList.remove(
  //         'payment-plan-milstone-table__seemore-overlay--hide'
  //       );
  //     }

  //     if (element.scrollTop === 0) {
  //       tableHeader.current.classList.add(
  //         'payment-plan-milstone-table__seemore-head-overlay--hide'
  //       );
  //     } else {
  //       tableHeader.current.classList.remove(
  //         'payment-plan-milstone-table__seemore-head-overlay--hide'
  //       );
  //     }
  //   };
  //   const scrollerElm = scroller?.current;
  //   if (scrollerElm) {
  //     scrollerElm.addEventListener('scroll', scrollHandler);
  //     return () => {
  //       scrollerElm.removeEventListener('scroll', scrollHandler);
  //     };
  //   }
  // }, [scroller]);

  if (isFetchingUnitCart) {
    return <div>Loading...</div>;
  }

  return (
    <table className='payment-plan-milstone-table'>
      <thead
        comment='ref={tableHeader}'
        className='payment-plan-milstone-table__seemore-head payment-plan-milstone-table__seemore-head-overlay--hide'>
        <tr>
          {Object.entries(milestone_titles).map(
            ([key, value]) =>
              Object.values(MILESTONE_KEY).includes(key) && (
                <th key={key}>{value}</th>
              )
          )}
          {/*{selectedEOI && (
            <tr>
              <th>EOI Paid</th>
              <th>Total Payable Amt.</th>
            </tr>
          )}*/}
        </tr>
      </thead>
      <tbody>
        {milestones.map((milestone, index) => (
          <tr>
            {Object.entries(milestone).map(([key, value]) => {
              if (!Object.values(MILESTONE_KEY).includes(key)) {
                return null;
              }

              if (key === MILESTONE_KEY.NAME) {
                return (
                  <td
                    className='payment-plan-milstone-table__title-cell'
                    key={key}>
                    {value}
                  </td>
                );
              } else {
                return (
                  <td key={key}>
                    {key === MILESTONE_KEY.PERCENTAGE
                      ? value
                      : toIndianRupee(Number(value))}
                  </td>
                );
              }
            })}
            {/*{selectedEOI &&
              Object.entries(milestone).map(([key, value]) => {
                if (key !== MILESTONE_KEY.NET_TAXABLE_AMOUNT) {
                  return null;
                } else {
                  return (
                    <tr>
                      <td>
                        {toIndianRupee((selectedEOI.amount | 0) * !index)}
                      </td>
                      <td>
                        {toIndianRupee(
                          Number(value) - (selectedEOI.amount | 0) * !index
                        )}
                      </td>
                    </tr>
                  );
                }
              })}*/}
            {/*<td className='payment-plan-milstone-table__title-cell'>
              {milestone.name}
            </td>
            <td className='payment-plan-milstone-table__percent-cell'>{`${parseFloat(
              milestone.percentage
            ).toString()} %`}</td>
            <td>{toIndianRupee(milestone.property_value)}</td>
            <td>{toIndianRupee(milestone.one_third_value)}</td>
            <td>{toIndianRupee(milestone.net_taxable_amount)}</td>
            <td>
              {toIndianRupee(
                parseFloat(milestone.sgst) + parseFloat(milestone.cgst)
              )}
            </td>
            <td className='f-bold'>{toIndianRupee(milestone.total_cost)}</td>*/}
          </tr>
        ))}
        {
          <tr>
            {Object.entries(completed_milestone).map(([key, value]) => {
              if (!Object.values(MILESTONE_KEY).includes(key)) {
                return null;
              }

              if (key === MILESTONE_KEY.NAME) {
                return (
                  <td
                    className='payment-plan-milstone-table__title-cell bold'
                    key={key}>
                    {value}
                  </td>
                );
              } else {
                return (
                  <td key={key} className='bold'>
                    {key === MILESTONE_KEY.PERCENTAGE
                      ? `${value}%`
                      : toIndianRupee(Number(value))}
                  </td>
                );
              }
            })}
            {/*{selectedEOI &&
              Object.entries(completed_milestone).map(([key, value]) => {
                if (key !== MILESTONE_KEY.NET_TAXABLE_AMOUNT) {
                  return null;
                } else {
                  return (
                    <tr>
                      <td className='bold'>
                        {toIndianRupee(selectedEOI.amount | 0)}
                      </td>
                      <td className='bold'>
                        {toIndianRupee(
                          Number(value) - (selectedEOI.amount | 0)
                        )}
                      </td>
                    </tr>
                  );
                }
              })}*/}
            {/*<td className='payment-plan-milstone-table__title-cell'>
            {milestone.name}
          </td>
          <td className='payment-plan-milstone-table__percent-cell'>{`${parseFloat(
            milestone.percentage
          ).toString()} %`}</td>
          <td>{toIndianRupee(milestone.property_value)}</td>
          <td>{toIndianRupee(milestone.one_third_value)}</td>
          <td>{toIndianRupee(milestone.net_taxable_amount)}</td>
          <td>
            {toIndianRupee(
              parseFloat(milestone.sgst) + parseFloat(milestone.cgst)
            )}
          </td>
          <td className='f-bold'>{toIndianRupee(milestone.total_cost)}</td>*/}
          </tr>
        }
        {false && (
          <tr>
            <td className='payment-plan-milstone-table__title-cell'>
              ADDITIONALS & DEPOSITS
            </td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td className='f-bold'>
              {toIndianRupee(
                parseFloat(costSheet.total_additional_amount) +
                  parseFloat(costSheet.tax_amount_on_additional)
              )}
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

export default PaymentPlanPlank;
