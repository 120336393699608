import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';

import { getAdminUserRole } from 'apps/admin/components/pages/AdminLogin/utils';
import { URLS } from 'constants/urls';
import { TAllowedUserRolesOnAdminPortal } from 'apps/admin/components/containers/AdminSidebar';
import ProjectRoutes from './ProjectRoutes';

const ExecutiveHeadUserRoutes = () => {
  const { path } = useRouteMatch();

  const getDefaultRedirect = () => {
    return `${URLS[getAdminUserRole() as TAllowedUserRolesOnAdminPortal]}`;
  };

  return (
    <Switch>
      <Route path={`${path}/projects`}>
        <ProjectRoutes />
      </Route>

      <Redirect to={getDefaultRedirect()} />
    </Switch>
  );
};

export default ExecutiveHeadUserRoutes;
