// import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import {
  getCostSheet,
  getSelectedUnit,
  getUnitCart,
} from '../../slices/selectedUnit';
import {
  isEmpty,
  ordinalSuffixOf,
  toIndianRupee,
  convertMeasureUnitToDisplayText,
} from '../../../../utils/utils';
import { getProject } from 'apps/booking/slices/projectSlice';
import {
  COSTSHEET_SECTION,
  COSTSHEET_ITEM_TYPE,
  MEASURE_UNIT,
} from 'apps/booking/constants';
import { PROJECT_TYPE } from 'constants/index';

const CostSheetPlank = () => {
  const unitCart = useSelector(getUnitCart);
  const costSheet = useSelector(getCostSheet);
  let selectedUnit = useSelector(getSelectedUnit);
  const project = useSelector(getProject);
  const unitCartUnit = unitCart.unit;
  if (!isEmpty(unitCartUnit)) {
    selectedUnit = unitCartUnit;
  }

  const costSheetItemByType = {};
  const costSheetSummaryItemByType = {};
  const costSheetBreakupItemByType = {};
  const costSheetAdditionalItemByType = {};

  selectedUnit.costSheet.items.forEach((item) => {
    costSheetItemByType[item.type] = item;
    if (item.section === COSTSHEET_SECTION.SUMMARY) {
      costSheetSummaryItemByType[item.type] = item;
    } else if (item.section === COSTSHEET_SECTION.BREAKUP) {
      costSheetBreakupItemByType[item.type] = item;
    } else if (item.section === COSTSHEET_SECTION.ADDITIONAL) {
      costSheetAdditionalItemByType[item.type] = item;
    }
  });

  // Comment temporary
  // const scroller = useRef();
  // const seeMoreOverlayBottomElm = useRef();
  // const seeMoreOverlayTopElm = useRef();
  // useEffect(() => {
  //   const scrollHandler = (event) => {
  //     var element = event.target;
  //     if (
  //       element.scrollHeight - Math.ceil(element.scrollTop) ===
  //       element.clientHeight
  //     ) {
  //       seeMoreOverlayBottomElm.current.classList.add(
  //         'scroll-more-overlay--hide'
  //       );
  //     } else {
  //       seeMoreOverlayBottomElm.current.classList.remove(
  //         'scroll-more-overlay--hide'
  //       );
  //     }

  //     if (element.scrollTop === 0) {
  //       seeMoreOverlayTopElm.current.classList.add('scroll-more-overlay--hide');
  //     } else {
  //       seeMoreOverlayTopElm.current.classList.remove(
  //         'scroll-more-overlay--hide'
  //       );
  //     }
  //   };
  //   const scrollerElm = scroller?.current;
  //   if (scrollerElm) {
  //     scrollerElm.addEventListener('scroll', scrollHandler);
  //     return () => {
  //       scrollerElm.removeEventListener('scroll', scrollHandler);
  //     };
  //   }
  // }, [scroller]);

  return (
    <>
      <div className='costsheet-plank'>
        <header className='costsheet-details__header'>
          <div className='costsheet-details__header-labels f-18'>
            <div className='costsheet-details__header-labels-item'>
              Unit No.{' '}
              <span className='f-bold'>
                {`${selectedUnit.blockName}${selectedUnit.number}`}
              </span>
            </div>
            <div className='costsheet-details__header-labels-item'>
              Project <span className='f-bold'>{project.name}</span>
            </div>
            {/* {<div className="costsheet-details__header-labels-item">
              Date <span className="f-bold">Wed, 22nd Apr 21</span>
            </div>} */}
          </div>
          <div className='costsheet-details__header-summery'>
            <span className='font-accent-bold costsheet-details__total-cost'>
              {toIndianRupee(selectedUnit.costSheet.totalInclusiveAmount)}
            </span>
            {costSheetItemByType[COSTSHEET_ITEM_TYPE.GST]?.rate?.label && (
              <span className='f-bold f-15 c-56'>
                Inclusive of GST{' '}
                {costSheetItemByType[COSTSHEET_ITEM_TYPE.GST].rate.label}
              </span>
            )}
            <span className='f-regular f-15'>
              (excluding registration charges)
            </span>
          </div>
          {/* <div
            ref={seeMoreOverlayTopElm}
            className="scroll-more-overlay scroll-more-overlay--top scroll-more-overlay--hide"></div> */}
        </header>

        <div comment='ref={scroller}' className='costsheet-details__body'>
          <ul className='costsheet-details__body-highlights'>
            <li>
              <h3 className='costsheet__statistics-value costsheet__statistics-value--primary costsheet__statistics-value--small'>
                {selectedUnit.area.value}{' '}
                {convertMeasureUnitToDisplayText(selectedUnit.area.unit)}
              </h3>
              <p className='costsheet__statistics-description costsheet__statistics-description--large f-bold'>
                {project.type == PROJECT_TYPE.PLOTS
                  ? 'Plot Size'
                  : 'Super Built up Area'}
              </p>
              <p className='costsheet__statistics-description costsheet__statistics-description--tagline costsheet__statistics-description--small'>
                {selectedUnit.number} {selectedUnit?.blockName},{' '}
                {selectedUnit?.type}
              </p>
            </li>
            <li>
              <h3 className='costsheet__statistics-value costsheet__statistics-value--primary costsheet__statistics-value--small'>
                {toIndianRupee(
                  costSheetItemByType[COSTSHEET_ITEM_TYPE.AGREEMENT_COST].rate
                    .value
                )}
              </h3>
              <p className='costsheet__statistics-description costsheet__statistics-description--large f-bold'>
                Base Selling Price
              </p>
              <p className='costsheet__statistics-description costsheet__statistics-description--tagline costsheet__statistics-description--small'>
                {
                  costSheetItemByType[COSTSHEET_ITEM_TYPE.AGREEMENT_COST].rate
                    .label
                }
              </p>
            </li>
            {costSheet?.floor_rise && (
              <li>
                <h3 className='costsheet__statistics-value costsheet__statistics-value--primary costsheet__statistics-value--small'>
                  {toIndianRupee(costSheet.floor_rise)}
                </h3>
                <p className='costsheet__statistics-description costsheet__statistics-description--large f-bold'>
                  Floor Rise
                </p>
                <p className='costsheet__statistics-description costsheet__statistics-description--tagline costsheet__statistics-description--small'>
                  As per {ordinalSuffixOf(selectedUnit?.floor?.number)} Floor
                  Selection
                </p>
              </li>
            )}
            {false && (
              <li>
                <h3 className='costsheet__statistics-value costsheet__statistics-value--primary costsheet__statistics-value--small'>
                  {toIndianRupee(
                    costSheetItemByType[COSTSHEET_ITEM_TYPE.PLC].value
                  )}
                </h3>
                <p className='costsheet__statistics-description costsheet__statistics-description--large f-bold'>
                  {costSheetItemByType[COSTSHEET_ITEM_TYPE.PLC].label}
                </p>
                <p className='costsheet__statistics-description costsheet__statistics-description--tagline costsheet__statistics-description--small'>
                  Net Cost
                </p>
              </li>
            )}
            <li>
              <h3 className='costsheet__statistics-value costsheet__statistics-value--primary costsheet__statistics-value--small'>
                {toIndianRupee(
                  costSheetItemByType[COSTSHEET_ITEM_TYPE.AGREEMENT_COST].value
                )}
              </h3>
              <p className='costsheet__statistics-description costsheet__statistics-description--large f-bold'>
                {`${
                  costSheetItemByType[COSTSHEET_ITEM_TYPE.AGREEMENT_COST].label
                } excl. GST`}
              </p>
              <p className='costsheet__statistics-description costsheet__statistics-description--tagline costsheet__statistics-description--small'>
                Base cost of Unit
              </p>
            </li>
            {costSheet?.gst_value && (
              <li>
                <h3 className='costsheet__statistics-value costsheet__statistics-value--primary costsheet__statistics-value--small'>
                  {toIndianRupee(costSheet.gst_value)}
                </h3>
                <p className='costsheet__statistics-description costsheet__statistics-description--large f-bold'>
                  GST
                </p>
                <p className='costsheet__statistics-description costsheet__statistics-description--tagline costsheet__statistics-description--small'>
                  on 2/3rd of total value
                </p>
              </li>
            )}
          </ul>
          <div className='costsheet-details__body-section-container'>
            <section className='costsheet-plant__body-section'>
              <h3 className='costsheet__details-heading'>Summary</h3>
              <p className='costsheet__details-description'>
                Cost details of a unit
              </p>
              <ul>
                <li className='costsheet__detail-item'>
                  <p className='costsheet__detail-item-value'>Unit Number</p>
                  <p className='costsheet__detail-item-value costsheet__detail-item-value--right f-bold'>
                    {`${selectedUnit?.blockName}${selectedUnit.number}`}
                  </p>
                </li>
                <li className='costsheet__detail-item'>
                  <p className='costsheet__detail-item-value'>
                    {project.type == PROJECT_TYPE.PLOTS
                      ? 'Plot Size'
                      : 'Super Built up Area'}
                  </p>
                  <p className='costsheet__detail-item-value costsheet__detail-item-value--right f-bold'>
                    {selectedUnit.area.value}{' '}
                    {convertMeasureUnitToDisplayText(selectedUnit.area.unit)}
                  </p>
                </li>
                <li className='costsheet__detail-item'>
                  <p className='costsheet__detail-item-value'>
                    {project.type == PROJECT_TYPE.PLOTS
                      ? 'Zone'
                      : 'Tower and Block'}
                  </p>
                  <p className='costsheet__detail-item-value costsheet__detail-item-value--right f-bold'>
                    {`${selectedUnit.towerName} ${selectedUnit?.blockName}`}
                  </p>
                </li>
                {selectedUnit?.floor?.number && (
                  <li className='costsheet__detail-item'>
                    <p className='costsheet__detail-item-value'>Floor</p>
                    <p className='costsheet__detail-item-value costsheet__detail-item-value--right f-bold'>
                      {ordinalSuffixOf(selectedUnit?.floor?.number)} Floor
                    </p>
                  </li>
                )}
                <li className='costsheet__detail-item'>
                  <p className='costsheet__detail-item-value'>Category</p>
                  <p className='costsheet__detail-item-value costsheet__detail-item-value--right f-bold'>
                    {selectedUnit.type}
                  </p>
                </li>
                {selectedUnit.area?.carpet && (
                  <li className='costsheet__detail-item'>
                    <p className='costsheet__detail-item-value'>Carpet Area</p>
                    <p className='costsheet__detail-item-value costsheet__detail-item-value--right f-bold'>
                      {selectedUnit.area.carpet} Sq.Ft
                    </p>
                  </li>
                )}
                <li className='costsheet__detail-item'>
                  <p className='costsheet__detail-item-value'>{`${
                    costSheetItemByType[COSTSHEET_ITEM_TYPE.AGREEMENT_COST]
                      .label
                  } excl. GST`}</p>
                  <p className='costsheet__detail-item-value costsheet__detail-item-value--right f-bold'>
                    {toIndianRupee(
                      costSheetItemByType[COSTSHEET_ITEM_TYPE.AGREEMENT_COST]
                        .value
                    )}
                  </p>
                </li>
                {Object.values(costSheetSummaryItemByType).map((element) => {
                  const { label, value } = element;
                  return (
                    <li className='costsheet__detail-item' key={element.id}>
                      <p className='costsheet__detail-item-value'>{label}</p>
                      <p className='costsheet__detail-item-value costsheet__detail-item-value--right f-bold'>
                        {toIndianRupee(value)}
                      </p>
                    </li>
                  );
                })}
              </ul>
            </section>

            <section className='costsheet-plant__body-section'>
              <h3 className='costsheet__details-heading'>Cost Break-Up</h3>
              <p className='costsheet__details-description'>
                Price components of Unit
              </p>
              <ul>
                {costSheet?.floor_rise && (
                  <li className='costsheet__detail-item'>
                    <p className='costsheet__detail-item-value'>Floor Rise</p>
                    <p className='costsheet__detail-item-value costsheet__detail-item-value--right f-bold'>
                      {toIndianRupee(costSheet.floor_rise)}
                    </p>
                  </li>
                )}
                {costSheet?.property_value && (
                  <li className='costsheet__detail-item'>
                    <p className='costsheet__detail-item-value'>
                      Total Value Of Property
                    </p>
                    <p className='costsheet__detail-item-value costsheet__detail-item-value--right f-bold'>
                      {toIndianRupee(costSheet.property_value)}
                    </p>
                  </li>
                )}
                {costSheet?.gst_value && (
                  <li className='costsheet__detail-item'>
                    <p className='costsheet__detail-item-value'>
                      GST (7.5%) on 2/3rd of total value
                    </p>
                    <p className='costsheet__detail-item-value costsheet__detail-item-value--right f-bold'>
                      {toIndianRupee(costSheet.gst_value)}
                    </p>
                  </li>
                )}
                {costSheet?.agreement_value && (
                  <li className='costsheet__detail-item'>
                    <p className='costsheet__detail-item-value'>
                      Cost Of The Apartment (Agreement Value)
                    </p>
                    <p className='costsheet__detail-item-value costsheet__detail-item-value--right f-bold'>
                      {toIndianRupee(costSheet.agreement_value)}
                    </p>
                  </li>
                )}
                {Object.values(costSheetBreakupItemByType).map((element) => {
                  const { label, value, rate, type, breakup } = element;
                  if (type != COSTSHEET_ITEM_TYPE.GST)
                    return (
                      <>
                        <li className='costsheet__detail-item'>
                          <p className='costsheet__detail-item-value'>
                            {label}
                            {rate?.label ? ` @ ${rate?.label}` : ''}
                          </p>
                          <p className='costsheet__detail-item-value costsheet__detail-item-value--right f-bold'>
                            {toIndianRupee(value)}
                          </p>
                        </li>
                        {breakup &&
                          breakup.map((item, index) => {
                            if (item.rate !== 0) {
                              return (
                                <li className='costsheet__detail-item'>
                                  <p
                                    className='costsheet__details-description'
                                    key={index}>
                                    {item.label}
                                  </p>
                                </li>
                              );
                            }
                            return null; // Don't render when item.value is zero
                          })}
                      </>
                    );
                })}
                <li className='costsheet__detail-item'>
                  <p className='costsheet__detail-item-value'>Total</p>
                  <p className='costsheet__detail-item-value costsheet__detail-item-value--right f-bold'>
                    {toIndianRupee(
                      selectedUnit.costSheet.totalInclusiveAmount -
                        costSheetBreakupItemByType[COSTSHEET_ITEM_TYPE.GST]
                          .value
                    )}
                  </p>
                </li>
                <li className='costsheet__detail-item'>
                  <p className='costsheet__detail-item-value'>
                    {costSheetBreakupItemByType[COSTSHEET_ITEM_TYPE.GST].label}
                    {costSheetBreakupItemByType[COSTSHEET_ITEM_TYPE.GST].rate
                      ?.label
                      ? ` @ ${
                          costSheetBreakupItemByType[COSTSHEET_ITEM_TYPE.GST]
                            .rate?.label
                        }`
                      : ''}
                  </p>
                  <p className='costsheet__detail-item-value costsheet__detail-item-value--right f-bold'>
                    {toIndianRupee(
                      costSheetBreakupItemByType[COSTSHEET_ITEM_TYPE.GST].value
                    )}
                  </p>
                </li>
                <li className='costsheet__detail-item'>
                  <p className='costsheet__detail-item-value f-bold'>
                    Total Amount Incl 18% GST
                  </p>
                  <p className='costsheet__detail-item-value costsheet__detail-item-value--right f-bold'>
                    {toIndianRupee(selectedUnit.costSheet.totalInclusiveAmount)}
                  </p>
                </li>
              </ul>
            </section>

            {costSheetAdditionalItemByType.size && (
              <section className='costsheet-plant__body-section'>
                <h3 className='costsheet__details-heading'>
                  Additionals & Deposits
                </h3>
                <p className='costsheet__details-description'>
                  Select from one of the available units to proceed
                </p>
                <ul>
                  {Object.values(costSheetAdditionalItemByType).map(
                    (element) => {
                      const { label, value } = element;
                      return (
                        <li className='costsheet__detail-item' key={element.id}>
                          <p className='costsheet__detail-item-value'>
                            {label}
                          </p>
                          <p className='costsheet__detail-item-value costsheet__detail-item-value--right f-bold'>
                            {toIndianRupee(value)}
                          </p>
                        </li>
                      );
                    }
                  )}
                  {costSheet?.additional_details &&
                    costSheet.additional_details.map((additional_cost) => (
                      <li className='costsheet__detail-item'>
                        <p className='costsheet__detail-item-value'>
                          {additional_cost.name}
                        </p>
                        <p className='costsheet__detail-item-value costsheet__detail-item-value--right f-bold'>
                          {toIndianRupee(additional_cost.total_cost)}
                        </p>
                      </li>
                    ))}

                  <li className='costsheet__detail-item'>
                    <p className='costsheet__detail-item-value'>Total</p>
                    <p className='costsheet__detail-item-value costsheet__detail-item-value--right f-bold'>
                      {toIndianRupee(
                        Object.values(costSheetAdditionalItemByType).reduce(
                          (accumulator, element) => {
                            return accumulator + element.value;
                          },
                          0
                        )
                      )}
                    </p>
                  </li>
                </ul>
              </section>
            )}
          </div>
        </div>
        {/* <div ref={seeMoreOverlayBottomElm} className="scroll-more-overlay f-12">
          See more
        </div> */}
      </div>
    </>
  );
};

export default CostSheetPlank;
