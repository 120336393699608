import { useState } from 'react';

import ConfirmationModal, {
  IConfirmationModal,
} from 'apps/booking/components/common/genericModals/ConfirmationModal';
import Icon from 'apps/booking/components/common/Icon';
import useToast from 'hooks/useToast';
import { IProject } from 'apps/admin/components/pages/SuperAdminDashboard/SuperAdminProjectList';
import { useDeleteProjectExecutiveMutation } from 'apps/admin/services/projectsAPISlice';
import { UserRole } from 'constants/userRoles';

import styles from './styles.module.css';

export interface IRemoveProjectExecutiveCell {
  executiveWithDemoRoomId: string;
  projectId: string;
  onExecutiveDelete: (updateProject: IProject) => void;
  disabled: boolean;
  userRole: string;
}

const RemoveProjectExecutiveCell = (props: IRemoveProjectExecutiveCell) => {
  const {
    projectId,
    executiveWithDemoRoomId,
    onExecutiveDelete,
    disabled,
    userRole,
  } = props;

  const [confirmBlockProject, setConfirmBlockProject] =
    useState<IConfirmationModal>();

  const [deleteProjectExecutive] = useDeleteProjectExecutiveMutation();
  const [addToast] = useToast();

  const handleDelete = () => {
    setConfirmBlockProject({
      accent: 'DANGER',
      heading: 'Do you want to DELETE the executive from the project?',
      onModalClose: () => setConfirmBlockProject(undefined),
      onResponse: async (confirmationResponse: any) => {
        try {
          await confirmationResponse;

          const updatedProject = await deleteProjectExecutive({
            projectId: projectId,
            executiveWithDemoRoomId,
          }).unwrap();

          onExecutiveDelete(updatedProject.data);

          addToast({
            type: 'SUCCESS',
            primaryMessage: 'Removed executive from project',
            secondaryMessage: '',
          });
        } catch (err) {
          const errMsg = (err as any).data.message;
          addToast({
            type: 'ERROR',
            primaryMessage: errMsg,
            secondaryMessage: 'Failed to remove executive from project',
          });
        }
      },
    });
  };

  if (userRole !== UserRole.EXECUTIVE) {
    return null;
  }

  return (
    <>
      {confirmBlockProject && <ConfirmationModal {...confirmBlockProject} />}
      <Icon
        name='highlight_off'
        propStyles={`actionIcon ${disabled && 'click-disabled--icon'} `}
        onClick={() => handleDelete()}
      />
    </>
  );
};

export default RemoveProjectExecutiveCell;
