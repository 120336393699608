import { createSlice } from '@reduxjs/toolkit';
import { IToast } from '../apps/booking/components/common/toast/Toast';
import { RootState } from '../store';

import { randomId } from '../utils/utils';

// Define a type for the slice state
interface IState {
  toasts: { [randomId: string]: IToast };
}

// Define the initial state using that type
const initialState: IState = { toasts: {} };

export const toastsSlice = createSlice({
  name: 'toastsSlice',
  initialState,
  reducers: {
    addToast: (state, action) => {
      state.toasts[randomId()] = action.payload;
    },

    deleteToast: (state, action) => {
      delete state.toasts[action.payload];
    },
  },
});

export const { addToast, deleteToast } = toastsSlice.actions;

export const getToasts = (state: RootState) => state.toastsSlice.toasts;
