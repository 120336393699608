import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { UserRole } from 'constants/userRoles';
import { getAdminUser } from 'apps/admin/slices/adminSlice';
import { IProject } from 'apps/admin/components/pages/SuperAdminDashboard/SuperAdminProjectList';
import Button from 'apps/booking/components/common/buttons/Button';
import DesktopTabs from 'apps/admin/components/common/DesktopTabs';
import { ITab } from 'apps/cp/components/common/Tabs';
import EoiList from 'apps/admin/components/pages/ProjectEois/EoiList';
import AddProjectEoiModal from 'apps/admin/components/pages/SuperAdminDashboard/SuperAdminProjectList/modals/AddProjectEoiModal';
import { useGetProjectEoiLeadByProjectIdQuery } from 'apps/admin/services/projectsAPISlice';
import { EoiStatus } from 'constants/status';
import { IEoiDetails } from 'apps/admin/interfaces';
import { getEoiList, setEoiList } from 'apps/admin/slices/projectAdminSlice';
import { IUnit } from 'interfaces';
import { isEmpty } from 'utils/utils';

import styles from './styles.module.css';

interface IProjectEois {
  project: IProject;
  onProjectUpdate: (updateProject: IProject) => void;
}

const ProjectEois = (props: IProjectEois) => {
  const { project, onProjectUpdate } = props;

  const dispatch = useDispatch();

  const user = useSelector(getAdminUser);
  const eoiListGlobal = useSelector(getEoiList);

  const [showAddProjectEoiModal, setShowAddProjectEoiModal] = useState(false);

  const { data: eoiLeads = [], isLoading } =
    useGetProjectEoiLeadByProjectIdQuery(project.id);

  useEffect(() => {
    let orderedByCreatedAtEOIs = [...eoiLeads];
    orderedByCreatedAtEOIs.sort(
      (leadA: IEoiDetails, leadB: IEoiDetails) =>
        +new Date(leadB.createdAt) - +new Date(leadA.createdAt)
    );
    dispatch(setEoiList(orderedByCreatedAtEOIs));
  }, [eoiLeads]);

  const executivelist = useMemo(() => {
    return eoiListGlobal.filter(
      (eoiData: IEoiDetails) => eoiData.executive.id === user?.id
    );
  }, [eoiListGlobal]);

  const eoiList = useMemo(() => {
    return user?.role === UserRole.EXECUTIVE ? executivelist : eoiListGlobal;
  }, [eoiListGlobal]);

  const getEoiListByStatus = (statuses: EoiStatus[]) => {
    return useMemo(
      () =>
        eoiList.filter(
          (eoiLead: { status: { toUpperCase: () => EoiStatus } }) =>
            statuses.includes(eoiLead.status.toUpperCase() as EoiStatus)
        ),
      [eoiList]
    );
  };

  const getEoiListByStatusAndUnit = (statuses: EoiStatus[]) => {
    return useMemo(() => {
      return eoiList.filter(
        (eoiLead: { status: { toUpperCase: () => EoiStatus }; unit: IUnit }) =>
          statuses.includes(eoiLead.status.toUpperCase() as EoiStatus) &&
          !isEmpty(eoiLead.unit)
      );
    }, [eoiList]);
  };

  const activeEoiList = getEoiListByStatus([
    EoiStatus.ACTIVE_BOOKING_DISABLED,
    EoiStatus.ACTIVE_BOOKING_ENABLED,
  ]);
  const bookedEoiList = getEoiListByStatusAndUnit([EoiStatus.BOOKED]);
  const expiredEoiList = getEoiListByStatus([EoiStatus.EXPIRED]);
  const cancelledEoiList = getEoiListByStatusAndUnit([EoiStatus.CANCELLED]);
  const blockedEoiList = getEoiListByStatusAndUnit([EoiStatus.BLOCKED]);
  const interestedEoiList = getEoiListByStatus([EoiStatus.INTERESTED]);
  const disabledEoiList = getEoiListByStatus([
    EoiStatus.ACTIVE_BOOKING_DISABLED,
  ]);
  const enabledEoiList = getEoiListByStatus([EoiStatus.ACTIVE_BOOKING_ENABLED]);

  const tabs: ITab[] = [
    {
      id: 'allEoi',
      name: 'All',
      content: <EoiList eoiList={eoiList} isLoading={false} />,
      disabled: false,
    },
    {
      id: 'activeEoi',
      name: 'Active',
      content: (
        <EoiList
          eoiList={activeEoiList}
          isLoading={false}
          tabName={EoiStatus.ACTIVE}
        />
      ),
      disabled: false,
    },
    {
      id: 'disabledEoi',
      name: 'Disabled',
      content: (
        <EoiList
          eoiList={disabledEoiList}
          isLoading={false}
          tabName={EoiStatus.ACTIVE}
        />
      ),
      disabled: false,
    },
    {
      id: 'enabledEoi',
      name: 'Enabled',
      content: (
        <EoiList
          eoiList={enabledEoiList}
          isLoading={false}
          tabName={EoiStatus.ACTIVE}
        />
      ),
      disabled: false,
    },
    // {
    //   id: 'expiredEoi',
    //   name: 'Expired',
    //   content: (
    //     <EoiList
    //       eoiList={expiredEoiList}
    //       isLoading={false}
    //       tabName={EoiStatus.EXPIRED}
    //     />
    //   ),
    //   disabled: false,
    // },
    // {
    //   id: 'cancelledEoi',
    //   name: 'Cancelled',
    //   content: (
    //     <EoiList
    //       eoiList={cancelledEoiList}
    //       isLoading={false}
    //       tabName={EoiStatus.CANCELLED}
    //     />
    //   ),
    //   disabled: false,
    // },
    // {
    //   id: 'interestedEoi',
    //   name: 'Interested',
    //   content: (
    //     <EoiList
    //       eoiList={interestedEoiList}
    //       isLoading={false}
    //       tabName={EoiStatus.INTERESTED}
    //     />
    //   ),
    //   disabled: false,
    // },
    // {
    //   id: 'bookedEoi',
    //   name: 'Booked',
    //   content: (
    //     <EoiList
    //       eoiList={bookedEoiList}
    //       isLoading={false}
    //       tabName={EoiStatus.BOOKED}
    //     />
    //   ),
    //   disabled: false,
    // },
    // {
    //   id: 'blockedEoi',
    //   name: 'Blocked',
    //   content: (
    //     <EoiList
    //       eoiList={blockedEoiList}
    //       isLoading={false}
    //       tabName={EoiStatus.BLOCKED}
    //     />
    //   ),
    //   disabled: false,
    // },
  ];

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      {showAddProjectEoiModal && (
        <AddProjectEoiModal
          project={project}
          onModalClose={() => {
            setShowAddProjectEoiModal(false);
          }}
        />
      )}
      <div className={styles.eoiContainer}>
        <header className={styles.modalHeader}>
          <div>
            <h2 className={styles.modalHeading}>List of EOIs</h2>
            <p className={styles.modalDesc}>
              Following users get access to all project of {project.name}.
            </p>
          </div>

          <Button
            onClick={() => {
              setShowAddProjectEoiModal(true);
            }}
            propStyles={styles.addEoiButton}>
            Add EOI
          </Button>
        </header>

        <DesktopTabs tabs={tabs} />
      </div>
    </>
  );
};
export default ProjectEois;
