import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { URLS } from '../../../../constants/urls';
import { SIDEBAR_ITEM_INDEX } from '../SidebarItem';

import UnitSummaryCard from '../UnitSummaryCard';

import { getUser } from '../../slices/auth';
// import { useUpdateUserMutation } from '../../api/auth';
import KycDetailsFrom from './KycDetailsFrom';
import { useState } from 'react';
import {
  getAppSliceIsFetching,
  setAppSliceIsFetching,
} from '../../../../slices/appSlice';
import { getUnitCart, setUnitCart } from '../../slices/selectedUnit';
import { useUpdateUnitCartMutation } from '../../../../api/unitCart';
import { isEmpty } from '../../../../utils/utils';

const KycDetails = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [proceedKycDetails, setProceedKycDetails] = useState(false);
  const [proceedBntEnabled, setProceedBntEnabled] = useState(true);
  const user = useSelector(getUser);
  const appSliceIsFetching = useSelector(getAppSliceIsFetching);
  const unitCart = useSelector(getUnitCart);
  const [updateUnitCartAPI] = useUpdateUnitCartMutation();
  // const [updateUserAPI] = useUpdateUserMutation();

  const handleSave = async (data) => {
    // TODO

    // const { error: updateUserError } = await updateUserAPI({
    //   id: user.id,
    //   ...data,
    // });

    // if (updateUserError) {
    //   const { data: { message } = {} } = updateUserError;
    //   // alert(message);
    //   console.error('updateUserAPI', message);
    //   return false;
    // }
    return true;
  };

  const updateUnitCartProgressOnServer = async () => {
    const unitCartBody = {
      id: unitCart.id,
      progress:
        unitCart.progress < SIDEBAR_ITEM_INDEX[URLS.KYC_DETAILS]
          ? SIDEBAR_ITEM_INDEX[URLS.KYC_DETAILS]
          : unitCart.progress,
    };
    const { data: unitCartdata, error: unitCartError } =
      await updateUnitCartAPI({ ...unitCartBody });

    if (unitCartError) {
      console.error('unitCartError', unitCartError);
      // TODO: Monkey patching - Find out reason for error
      // alert('unitCartError', unitCartError);
      return false;
    }

    if (!isEmpty(unitCartdata)) {
      dispatch(setUnitCart(unitCartdata));
    }
    return true;
  };

  const handleSaveWrap = async (data) => {
    dispatch(setAppSliceIsFetching(true));
    const saveAPIStatus = await handleSave(data);
    const handleUnitCartStatus = await updateUnitCartProgressOnServer();
    dispatch(setAppSliceIsFetching(false));

    if (saveAPIStatus && handleUnitCartStatus) {
      history.push(URLS.AGREEMENT);
    }
  };

  const handleProceed = () => {
    setProceedKycDetails(true);
  };
  return (
    <div className='kyc'>
      <div className='kyc__body'>
        <div className='kyc-applicant-list'>
          <KycDetailsFrom
            key={user.id}
            applicant={user}
            handleSave={(data) => handleSaveWrap(data)}
            proceedKycDetails={proceedKycDetails}
            setProceedKycDetails={setProceedKycDetails}
            setProceedBntEnabled={setProceedBntEnabled}
          />
        </div>
        <UnitSummaryCard />
      </div>
      <footer className='payment-plan__footer'>
        <button
          type='button'
          className='btn btn--transparent'
          onClick={() => {
            history.push(URLS.KYC);
          }}>
          Back to KYC
        </button>
        <div className='payment-plan__footer-action-btns'>
          <button
            type='button'
            className={`btn kyc-save-and-pay
              ${appSliceIsFetching && 'loading-spin-container'}
              ${proceedBntEnabled && 'btn--accent'}
              ${!proceedBntEnabled && 'click-disabled'}`}
            onClick={() => handleProceed()}>
            <span>Proceed</span>
            <span className='ic2-fa-spin-blue'></span>
          </button>
        </div>
      </footer>
    </div>
  );
};

export default KycDetails;
