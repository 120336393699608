import { useEffect, useState } from 'react';

import { ITab, ITabsProps } from 'apps/cp/components/common/Tabs';

import styles from './styles.module.css';

const DesktopTabs = (props: ITabsProps) => {
  const { selectedTab: _selectedTab, tabs } = props;

  const [selectedTab, setSelectedTab] = useState<ITab>();

  useEffect(() => {
    setSelectedTab(_selectedTab || tabs[0]);
  }, [tabs]);

  return (
    <body>
      <div className={styles.tabsContainer}>
        <div className={styles.tabContainerHeader}>
          {tabs.map((tab) => (
            <div
              key={tab.id}
              className={`${styles.tabItem} ${
                tab.disabled
                  ? styles.disabledTab
                  : selectedTab?.id === tab.id
                  ? styles.activeTab
                  : ''
              }`}
              onClick={() => setSelectedTab(tab)}>
              {tab.name}
            </div>
          ))}
        </div>
        <main>{selectedTab?.content}</main>
      </div>
    </body>
  );
};

export default DesktopTabs;
