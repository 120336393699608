import { useState } from 'react';

import Modal from 'apps/booking/components/common/modal/Modal';
import { IUser } from 'apps/admin/interfaces';
import IconWithLabel from 'apps/admin/components/common/IconWithLabel';
import Icon from 'apps/booking/components/common/Icon';

import styles from './styles.module.css';

interface ILeadAnalyticsCell {
  lead: IUser;
  projectId: string;
}

const LeadAnalyticsCell = (props: ILeadAnalyticsCell) => {
  const { lead, projectId } = props;

  const [showAnalyticsModal, setShowAnalyticsModal] = useState(false);

  return (
    <>
      <Modal
        show={showAnalyticsModal}
        onOutsideClick={() => setShowAnalyticsModal(false)}
        additionalClasses={styles.modalClass}>
        <div className={styles.leadWrapper}>
          <header className={styles.modalHead}>
            <div className={styles.leftContainer}>
              <div className={styles.nameWrapper}>
                <h2 className={styles.leadName}>{lead.name}</h2>
                {/* <div className={styles.statusIcon}>
                  <Icon name='whatshot' propStyles={styles.hotIcon} />
                  <p className={styles.statusText}>HOT</p>
                </div> */}
              </div>
              <p className={styles.subText}>Lead Analytics Below</p>
            </div>
            <div className={styles.rightContainer}>
              <Icon
                name='close'
                propStyles={styles.crossIcon}
                onClick={() => setShowAnalyticsModal(false)}
              />
            </div>
          </header>
          <div className={styles.iframeWrapper}>
            <iframe
              title='d'
              className={styles.analyticsFrame}
              width='100%'
              height='90%'
              frameBorder='0'
              src={`https://lookerstudio.google.com/embed/reporting/c14dea2a-d830-4d36-921b-158260c9d9c6/page/cEw1C?params=%EE%80%800%EE%80%80IN%EE%80%80${projectId}:%EE%80%800%EE%80%80IN%EE%80%80${lead.id}`}
              style={{ border: 0 }}
              allowFullScreen></iframe>
          </div>
        </div>
      </Modal>
      <IconWithLabel
        iconName='equalizer'
        label='Analytics'
        onClick={() => setShowAnalyticsModal(true)}
      />
    </>
  );
};

export default LeadAnalyticsCell;
