import { useState } from 'react';

import { ICellRendererParams } from 'ag-grid-community';

import { ICpUser } from 'apps/cp/interfaces';
import S3ClientNew from 'utils/S3ClientNew';
import ImagePreviewModal from 'apps/admin/components/pages/ActiveCpListAndPendingCpApproval/ActiveCpListAndPendingCpApprovalBody/ExpandedCpDetailsSection/ImagePreviewModal';

import styles from './styles.module.css';

const ExpandedCpDetailsSection = (props: ICellRendererParams) => {
  const cpUser: ICpUser = props?.data;

  const [previewImageUrl, setPreviewImageUrl] = useState('');

  const cpDetailItems = !cpUser
    ? []
    : [
        {
          label: 'Name',
          value: cpUser.user.name,
        },
        {
          label: 'Email id',
          value: cpUser.user.email,
        },
        {
          label: 'Phone Number',
          value: cpUser.user.mobile,
        },
        {
          label: 'PAN Card Number',
          value: cpUser.panNumber,
        },
        {
          label: 'PAN Card Image',
          value: cpUser.panUrl,
        },

        // {
        //   label: 'Project Name',
        //   value: cpUser.projectName,
        // },
        {
          label: 'Company Name',
          value: cpUser.companyTemp?.name,
        },
        {
          label: 'Company Url',
          value: cpUser.companyTemp?.websiteUrl,
        },
        {
          label: 'Company Contact',
          value: cpUser.companyTemp?.pocMobile,
        },
        {
          label: 'RERA Number',
          value: cpUser.rera.number,
        },
        {
          label: 'GST Number',
          value: cpUser.gstNumber,
        },
        {
          label: 'GST Card Image',
          value: cpUser.gstUrl,
        },
      ];

  const isValueImageUrl = (value: string) => {
    return /\.(jpg|jpeg|png|webp|avif|gif|svg|pdf)$/.test(value);
  };

  const handleImagePreview = (value: string) => {
    setPreviewImageUrl(value);
  };

  return (
    <div className={styles.allDetailsContainer}>
      {cpDetailItems.map((cpDetailItem) => (
        <div
          key={cpDetailItem.label}
          className={styles.individualDetailContainer}>
          <div className={styles.detailLabel}>{cpDetailItem.label}</div>

          {isValueImageUrl(cpDetailItem.value) ? (
            <p
              className={styles.detailValue}
              onClick={() => handleImagePreview(cpDetailItem.value)}
              data-preview={true}>
              {S3ClientNew.fileName(cpDetailItem.value)}
            </p>
          ) : (
            <p className={styles.detailValue}>{cpDetailItem.value}</p>
          )}
        </div>
      ))}
      {previewImageUrl && (
        <ImagePreviewModal
          imageUrl={previewImageUrl}
          onModalClose={() => setPreviewImageUrl('')}
        />
      )}
    </div>
  );
};

export default ExpandedCpDetailsSection;
