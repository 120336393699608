import { IPartner } from 'apps/admin/components/pages/ProjectPartners/index';
import { TObjectId } from 'interfaces';
import { adminAPISlice } from 'apps/admin/services';

export const projectPartnerAPISlice = adminAPISlice.injectEndpoints({
  endpoints: (build) => ({
    getRegisteredProjectPartners: build.mutation({
      query: ({
        projectId,
        executiveId,
        pageNumber,
        pageCount,
        searchField = '',
        searchName = '',
        startDate = '',
        endDate = '',
      }: {
        projectId: TObjectId;
        executiveId: TObjectId;
        pageNumber: number;
        pageCount: number;
        searchField?: string;
        searchName?: string;
        startDate?: string;
        endDate?: string;
      }) => ({
        url: `api/projects/${projectId}/register-partners?executiveId=${executiveId}&searchField=${searchField}&searchName=${searchName}&pageCount=${pageCount}&pageNumber=${pageNumber}&startDate=${startDate}&endDate=${endDate}`,
        method: 'GET',
      }),
    }),
    getRegisteredProjectPartnersCount: build.query<number, any>({
      query: ({
        projectId,
        executiveId,
      }: {
        projectId: TObjectId;
        executiveId: TObjectId;
      }) =>
        `api/projects/${projectId}/register-partners/count?executiveId=${executiveId}`,
      transformResponse: (response: {
        data: number;
        code: String;
        error: Object;
        message: String;
      }) => {
        return response.data;
      },
    }),

    getUnassignedProjectPartners: build.mutation({
      query: ({
        projectId,
        executiveId,
        pageNumber,
        pageCount,
        searchField = '',
        searchName = '',
        startDate = '',
        endDate = '',
      }: {
        projectId: TObjectId;
        executiveId: TObjectId;
        pageNumber: number;
        pageCount: number;
        searchField?: string;
        searchName?: string;
        startDate?: string;
        endDate?: string;
      }) => ({
        url: `api/projects/${projectId}/unassigned-partners?executiveId=${executiveId}&searchField=${searchField}&searchName=${searchName}&pageCount=${pageCount}&pageNumber=${pageNumber}&startDate=${startDate}&endDate${endDate}`,
        method: 'GET',
      }),
    }),
    getUnassignedProjectPartnersCount: build.query<number, any>({
      query: ({
        projectId,
        executiveId,
      }: {
        projectId: TObjectId;
        executiveId: TObjectId;
      }) =>
        `api/projects/${projectId}/unassigned-partners/count?executiveId=${executiveId}`,
      transformResponse: (response: {
        data: number;
        code: String;
        error: Object;
        message: String;
      }) => {
        return response.data;
      },
    }),

    getActiveProjectPartners: build.mutation({
      query: ({
        projectId,
        executiveId,
        pageNumber,
        pageCount,
        searchField = '',
        searchName = '',
        startDate = '',
        endDate = '',
      }: {
        projectId: TObjectId;
        executiveId: TObjectId;
        pageNumber: number;
        pageCount: number;
        searchField?: string;
        searchName?: string;
        startDate?: string;
        endDate?: string;
      }) => ({
        url: `api/projects/${projectId}/active-partners?executiveId=${executiveId}&searchField=${searchField}&searchName=${searchName}&pageCount=${pageCount}&pageNumber=${pageNumber}&startDate=${startDate}&endDate=${endDate}`,
        method: 'GET',
      }),
    }),
    getActiveProjectPartnersCount: build.query<number, any>({
      query: ({
        projectId,
        executiveId,
      }: {
        projectId: TObjectId;
        executiveId: TObjectId;
      }) =>
        `api/projects/${projectId}/active-partners/count?executiveId=${executiveId}`,
      transformResponse: (response: {
        data: number;
        code: String;
        error: Object;
        message: String;
      }) => {
        return response.data;
      },
    }),

    getOrganisationPartners: build.query({
      query: ({ organisationId }: { organisationId?: TObjectId }) =>
        `api/companies/cp-list/${organisationId}`,
      transformResponse: (response: {
        data: IPartner[];
        code: String;
        error: Object;
        message: String;
      }) => {
        return response.data;
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetRegisteredProjectPartnersMutation,
  useGetRegisteredProjectPartnersCountQuery,

  useGetUnassignedProjectPartnersMutation,
  useGetUnassignedProjectPartnersCountQuery,

  useGetActiveProjectPartnersMutation,
  useGetActiveProjectPartnersCountQuery,

  useGetOrganisationPartnersQuery,
} = projectPartnerAPISlice;
