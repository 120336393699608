import { adminAPISlice } from 'apps/admin/services/index';
import { ISiteVisitDetails } from 'apps/admin/components/pages/forms/SiteVisit';
import { TObjectId } from 'interfaces';
import { ISiteVisit } from 'apps/cp/interfaces';
import { IAssignedCpPreviewReq } from '../components/pages/forms/SiteVisit/DetailsForm/AssingedCpInfo';

export const siteVisitAPI = adminAPISlice.injectEndpoints({
  endpoints: (build) => ({
    getSiteVisitsByCpLeadId: build.query<ISiteVisit[], any>({
      query: (cpLeadId: TObjectId) => `api/siteVisits/${cpLeadId}`,
      transformResponse: (response: {
        data: ISiteVisit[];
        code: String;
        error: Object;
        message: String;
      }) => {
        return response.data;
      },
      // providesTags: (result) =>
      //   result
      //     ? [
      //         ...result.map(({ id }) => ({ type: 'Projects' as const, id })),
      //         { type: 'Projects', id: 'LIST' },
      //       ]
      //     : [{ type: 'Projects', id: 'LIST' }],
    }),
    addSiteVisit: build.mutation({
      query: (siteVisitDetails: ISiteVisitDetails) => ({
        url: 'api/siteVisits/',
        method: 'POST',
        body: siteVisitDetails,
      }),
    }),
    qrcodeScanAnalytics: build.mutation({
      query: ({
        cpUserId,
        cpLeadId,
        projectId,
        eventName,
      }: {
        cpUserId: TObjectId;
        cpLeadId: TObjectId;
        projectId: TObjectId;
        eventName: TObjectId;
      }) => ({
        url: `api/analytics/qrCode/`,
        method: 'POST',
        body: {
          cpLeadId,
          cpUserId,
          projectId,
          eventName,
        },
      }),
    }),
    getAssignedCpPreview: build.mutation({
      query: (leadDetails: IAssignedCpPreviewReq) => ({
        url: 'api/siteVisits/cpAssignmentPreview',
        method: 'POST',
        body: leadDetails,
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetSiteVisitsByCpLeadIdQuery,
  useAddSiteVisitMutation,
  useQrcodeScanAnalyticsMutation,
  useGetAssignedCpPreviewMutation,
} = siteVisitAPI;
