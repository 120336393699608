import { useState } from 'react';

import Icon from 'apps/booking/components/common/Icon';
import { IApplicant } from 'apps/booking/interfaces';
import UserCkycModal from 'apps/admin/components/pages/AdminDashboard/AdminExecutiveList/modals/UserCkycModal';

interface IUserDetailsCell {
  applicants: IApplicant[];
}

const UserCkycDetailsCell = (props: IUserDetailsCell) => {
  const { applicants } = props;

  const [applicantsModalShown, setApplicantsModalShown] = useState(false);

  return (
    <>
      {applicantsModalShown && (
        <UserCkycModal
          onModalClose={() => setApplicantsModalShown(false)}
          applicants={applicants}
        />
      )}
      <Icon
        name='details'
        disabled={!applicants?.length}
        propStyles='actionIcon'
        onClick={() => setApplicantsModalShown(true)}
      />
    </>
  );
};

export default UserCkycDetailsCell;
