import { useState } from 'react';
import { useForm } from 'react-hook-form';

import Button from 'apps/booking/components/common/buttons/Button';
import DisplayInputs from 'apps/booking/components/common/inputs/DisplayInputs';
import DropDownInput, {
  DropDownOptionType,
} from 'apps/booking/components/common/inputs/DropDownInput';
import TextInput from 'apps/booking/components/common/inputs/TextInput';
import { TDistanceUnit } from 'apps/admin/components/pages/SuperAdminDashboard/SuperAdminProjectList';

import styles from './styles.module.css';

interface INearByAreasInputProps {
  onAddArea: (nearByArea: {
    name: string;
    distance: { value: string; unit: TDistanceUnit };
  }) => void;
  onRemoveArea: (areaName: string) => void;
  nearByAreaKeyValueStrings: string[];
}

const NearByAreasInput = (props: INearByAreasInputProps) => {
  const { onAddArea, nearByAreaKeyValueStrings, onRemoveArea } = props;

  const [areaName, setAreaName] = useState('');
  const [areaDistance, setDistance] = useState('');
  const [unit, setUnit] = useState<TDistanceUnit>('KM');

  const { setValue } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });

  const handleAreaNameChange = (e: any) => {
    setAreaName(e.target.value);
  };

  const handleAreaDistanceChange = (e: any) => {
    setDistance(e.target.value);
  };

  const handleUnitInputChange = (selectedOption: DropDownOptionType[]) => {
    setUnit(selectedOption[0].value as TDistanceUnit);
  };

  const handleAddArea = () => {
    setAreaName('');
    setDistance('');
    onAddArea({
      name: areaName,
      distance: {
        value: areaDistance,
        unit,
      },
    });
  };

  return (
    <>
      <div className={styles.bodyIndividualdRow}>
        <TextInput
          label='Near By Area'
          placeHolder='Add Near By Area'
          setValue={setValue}
          maxLength={49}
          resetValue={!areaName}
          onChange={handleAreaNameChange}
          propStyles={styles.inputsContainer}
        />
        <TextInput
          label='Distance'
          placeHolder='Add Distance'
          type='number'
          resetValue={!areaDistance}
          setValue={setValue}
          onChange={handleAreaDistanceChange}
          disabled={!areaName}
          propStyles={styles.inputsContainer}
        />
        <div className={styles.dropDownContainer}>
          <DropDownInput
            options={[
              { value: 'km', displayText: 'KILOMETERS' },
              { value: 'm', displayText: 'METERS' },
            ]}
            lable='Unit'
            disabled={!areaDistance}
            defaultSelectedOption={{
              value: 'km',
              displayText: 'KILOMETERS',
            }}
            onValueSelected={handleUnitInputChange}
          />
          <Button
            onClick={handleAddArea}
            disabled={!areaDistance}
            propStyles={styles.addBtn}>
            add
          </Button>
        </div>
      </div>
      <div className={styles.bodyIndividualdRow}>
        <DisplayInputs
          displayInputs={nearByAreaKeyValueStrings}
          onRemove={onRemoveArea}
          type='keyValue'
        />
      </div>
    </>
  );
};

export default NearByAreasInput;
