import { useState } from 'react';

import { ICellRendererParams } from 'ag-grid-community';

import { ICompany } from 'apps/admin/interfaces';
import ImagePreviewModal from 'apps/admin/components/pages/ActiveCpListAndPendingCpApproval/ActiveCpListAndPendingCpApprovalBody/ExpandedCpDetailsSection/ImagePreviewModal';

import styles from './styles.module.css';

const ExpandedLeadDetails = (props: ICellRendererParams) => {
  const secondaryCompany: ICompany = props?.data;

  const secondaryCompanyItems = !secondaryCompany
    ? []
    : [
        {
          label: 'Registered Name',
          value: secondaryCompany.displayName,
        },
        {
          label: 'Firm PoC Phone Number',
          value: secondaryCompany.pocMobile,
        },
        {
          label: 'Firm PoC Email Adress',
          value: secondaryCompany.pocEmail,
        },
        {
          label: 'Date of Firm Formation',
          value: '--',
        },
        {
          label: 'Date of Firm Registration',
          value: '--',
        },

        {
          label: 'Firm Registered Address',
          value: secondaryCompany.hqAddress,
        },
        {
          label: 'FIrm GST, PAN',
          value: secondaryCompany.panNumber,
        },
        {
          label: 'List of Firm RERA',
          value: '--',
        },
        {
          label: 'Firm URL',
          value: secondaryCompany.websiteUrl,
        },
        {
          label: 'Other company projects',
          value: '--',
        },
      ];

  return (
    <div className={styles.allDetailsContainer}>
      {secondaryCompanyItems.map((secondaryCompanyItem) => (
        <div
          key={secondaryCompanyItem.label}
          className={styles.individualDetailContainer}>
          <div className={styles.detailLabel}>{secondaryCompanyItem.label}</div>

          <p className={styles.detailValue}>{secondaryCompanyItem.value}</p>
        </div>
      ))}
    </div>
  );
};

export default ExpandedLeadDetails;
