import { useState } from 'react';

import { ICellRendererParams } from 'ag-grid-community';

import Icon from 'apps/booking/components/common/Icon';
import UpdateCpUserModal from 'apps/admin/components/common/UpdateCpUserModal';
import { ICpUser } from 'apps/cp/interfaces';

export interface IUpdateCpUserCellProps {
  cpUser: ICpUser;
  rowNode: ICellRendererParams['node'];
  onCpUserUpdate?: (updatedCpUser: ICpUser) => void;
  disabled: boolean;
}

const UpdateCpUserCell = (props: IUpdateCpUserCellProps) => {
  const { cpUser, rowNode, onCpUserUpdate, disabled } = props;

  const [updateCpUserModalShown, setUpdateCpUserModalShown] = useState(false);

  const handleUserUpdate = (updatedCpUser: ICpUser) => {
    rowNode.setData(updatedCpUser);
    onCpUserUpdate && onCpUserUpdate(updatedCpUser);
  };

  return (
    <>
      {updateCpUserModalShown && (
        <UpdateCpUserModal
          onModalClose={() => setUpdateCpUserModalShown(false)}
          cpUser={cpUser}
          onCpUserUpdate={handleUserUpdate}
        />
      )}
      <Icon
        name='person_outline'
        propStyles={`${disabled && 'click-disabled--icon'} `}
        onClick={() => setUpdateCpUserModalShown(true)}
      />
    </>
  );
};

export default UpdateCpUserCell;
